.filter {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  border-radius: 10px;
  margin: 10px 0;
  padding: 0 2em;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: var(--mb-4);
  margin-bottom: 4em;
  /* background: #fafafa;
  padding: 2%; */
}

.search {
  width: 32%;
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.search * button {
  background: unset !important;
  border: none !important;
}


.search * svg {
  position: relative;
  top: 2px;
  fill: var(--text-color);
  font-size: 22px;
}

.select {
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.___row {
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .left .subtitle {
    font-size: var(--h2-font-size);
    max-width: 100px;
  }

  .filter {
    padding: 0 0.3em;
    gap: 2%;
  }

  .search {
    width: 50%;
    height: 40px;
  }

  .search * button {
    font-size: 12px;
  }

  .select {
    width: 50%;
    height: 40px;
  }
}

@media only screen and (min-width: 390px) {}

@media only screen and (min-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.__reset__btn {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.__submit__hidden {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}