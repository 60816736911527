.card {
  height: 385px;
  width: 100%;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 2.5px solid #707070;
  border-bottom: 2.5px solid #707070;
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.card::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.__matiers {
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-1);
}

.__tag__topic {
  background: #d5d5d5;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
}

.card__header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0.2% 2%;
}

.round__user {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.round__user img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 100px;
}

.content h3 {
  width: 150px;
  text-transform: uppercase;
  color: #333;
  font-size: 1.2em;
  margin-top: var(--mb-1);
}

.tarif {
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
  margin-top: var(--mb-1);
  position: absolute;
  top: 66%;
}

.tarif span {
  font-weight: 300;
}

.tarifInsta {
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
  margin-top: var(--mb-1);
  position: relative;
  top: 5%;
}

.tarifInsta span {
  font-weight: 300;
}

.btn__profil__prof:hover {
  background: #5a8484 !important;
}

.__description {
  width: 80%;
  height: 35px;
  font-size: 12px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  top: 36%;
}

.__descriptionInsta {
  width: 80%;
  height: 35px;
  font-size: 12px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
  text-align: center;
  position: relative;
  top: 0%;
}

.__start {
  position: absolute;
  margin: auto;
  top: -4%;
  right: 8%;
}

.__row {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
}

.__row button {
  position: relative;
  border: unset;
  padding: 4px 11px;
  border-radius: 8px;
  background: var(--first-color);
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.calendrier {
  width: 1200px;
  height: 850px;
  margin-top: 2em;
  background: url("../../../../../assets/images/teacher/calendarScreen.svg") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 1s ease;
}

.calendar__content {
  width: 95%;
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: auto;
}

.__drawer__teacher {
  width: 100%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.__drawer__teacher__header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.__infos h3 {
  font-size: 1.6em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-1);
}

.__drawer__teacher__main {
  margin-top: 2em;
}

.__main__infos {
  font-size: 1.6em;
  margin-bottom: 1em;
  display: flex;
}

.__main__infos span {
  text-transform: uppercase;
}

.__drawer_matiere {
  position: relative;
  top: -0.6em;
  margin-left: 1.5em;
}

.__main__infos p {
  position: relative;
  top: 5px;
  font-size: 0.7em;
  margin-left: 1em;
}

.__plus {
  width: 100%;
  height: 400px;
  background: #fafafa;
}

.__reservation {
  /* background: var(--first-color); */
  width: 350px;
  height: 350px !important;
}

.calendarPopupContent * .ant-popover-inner-content {
  padding: 0 !important;
}

.__reservation_content {
  position: relative;
  width: 100%;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* padding-top: 2%; */
}

.__reservation__row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.__reservation_avatar {
  position: relative;
  width: 80px;
  height: 80px;
  top: -10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 100px;
}

.__reservation_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.__reservation_content h2 {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 1.5em;
}

.reservation__infos {
  width: 100%;
  padding-left: 3%;
}

.reservation__infos h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.reservation__infos span {
  font-size: 11px;
}

.tag {
  width: 100px;
  height: 32px;
  border-radius: 20px;
  background: #8ab0b1ca;
  display: flex;
  margin-bottom: 0.5em;
}

.tag span {
  margin: auto;
  text-transform: uppercase;
  font-size: 11px;
  color: #fff;
}

.__drawer_button {
  border-radius: 15px !important;
  height: 40px !important;
  color: var(--first-color);
  text-transform: uppercase;
  min-width: 200px;
}

.__custom__container {
  width: 100%;
  height: 100%;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.__liste__card {
  margin-top: var(--mb-4);
}

.__calendar__title,
.__calendar__title__creneau {
  font-size: 12px !important;
  line-height: 14px;
}

.__calendar__title {
  font-size: 11px !important;
}

/* .__open__className {
  min-width: 400px !important;
  max-width: 400px !important;
} */

.__solde_content h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.__prof__description__card__reservation {
  font-size: 12px;
  text-align: center;
}

.__matiersAds * span {
  padding-left: 2px;
}

.__matiersAds {
  margin-bottom: 1.5em;
}

/* ====== */
.month {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: -0.7em;
}

.month span {
  text-transform: uppercase;
  font-family: "CartoonFont";
}

.date {
  font-size: 2em;
}

.month span:nth-child(2) {
  position: relative;
  font-size: 1.2em;
}

.month span:nth-child(3) {
  position: relative;
  font-size: 1.2em;
}

.month span:nth-child(2)::after,
.month span:nth-child(3)::after {
  content: "";
  position: absolute;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  left: -18px;
  top: 10px;
  background: var(--first-color);
}

.month span:nth-child(3)::after {
  background: #faad13;
}

/* ======== */

.card__teacher__reservation {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid #fafafabe;
  border-radius: 5px;
  padding: 3% 2%;
}

.teacher__reservation__avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}

.teacher__reservation__avatar img {
  width: 100%;
  height: 100%;
}

.open__messagerie span {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s all;
}

.open__messagerie span:hover {
  color: var(--first-color);
}

.levelGroup span:last-child {
  display: none;
}

/* New Css Styles */

.teacherCard {
  height: 380px !important;
  width: 100% !important;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 2.5px solid #707070;
  border-bottom: 2.5px solid #707070;
}

.ribbon {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 0px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 0px;
  height: 25px;
  background: #60CD95;
}

.ribbonInsta {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10px;
  right: 0px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 0px;
  height: 25px;
  background: #60CD95;
}

.ribbon:after {
  height: 25px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12.5px 0 12.5px 15px;
  border-color: transparent transparent transparent white;
}

.ribbonInsta:after {
  height: 25px;
  content: '';
  position: relative;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12.5px 0 12.5px 15px;
  border-color: transparent transparent transparent white;
}

.credit {
  font-size: 8px;
  position: relative;
  bottom: 4px;
}

.creditInsta {
  font-size: 8px;
  position: relative;
  bottom: 4px;
}

.avatarNameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 0px;
  right: 0px;
  height: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.avatarNameBoxInsta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.avatarBox {
  height: 75px;
  width: 75px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFDCAB80;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nameRateBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.nameBox {
  display: flex;
  flex-direction: row;
  line-height: 1.3;
}

.nameRateBox span {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.rateNumber {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #393E3E;
  margin-top: 0px;
  cursor: pointer;
}

.matiers {
  height: 40px !important;
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  margin: 7px auto;
  position: absolute;
  top: 49%;
  gap: 5px;
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.matiersInsta {
  height: 40px !important;
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  margin: 7px auto;
  position: relative;
  top: 0%;
  gap: 5px;
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.levelGroup {
  color: #7FB1B2;
  font-weight: 500;
  position: absolute;
  top: 62%;
}

.levelGroupInsta {
  color: #7FB1B2;
  font-weight: 500;
  position: relative;
  top: 0%;
}

.levelGroup span:last-child {
  display: block;
}

.levelGroupInsta div:last-child {
  display: block;
}

.rowButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
  position: absolute;
  bottom: 10%;
}

.rowButtonsInsta {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
  position: relative;
  top: 10%;
}

.newInstaButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.newPlanningButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.newMessageButton {
  width: 32px !important;
  height: 32px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding: 0px !important;
}

@media only screen and (max-width: 1050px) {
  .avatarNameBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 20px;
    right: 0px;
    height: auto;
    width: 90%;
    padding-top: 25px;
    padding-bottom: 20px;
  }

  .avatarNameBoxInsta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    left: 20px;
    right: 0px;
    height: auto;
    width: 90%;
    padding-top: 25px;
    padding-bottom: 20px;
  }

  .avatarBox {
    height: 45px;
    width: 45px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 00px;
    background: #FFDCAB80;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nameRateBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    margin-top: 5px;
  }

  .nameBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.3;
  }

  .nameRateBox span {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .rateNumber {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #393E3E;
    margin-top: 0px;
    cursor: pointer;
  }

  .matiers {
    height: 30px !important;
    width: auto !important;
    text-transform: uppercase;
    font-size: 0.75em !important;
    margin: 7px auto;
    position: absolute;
    top: 58%;
    gap: 5px;
    overflow: auto;
    scrollbar-width: thin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .matiersInsta {
    height: 30px !important;
    width: auto !important;
    text-transform: uppercase;
    font-size: 0.75em !important;
    margin: 7px auto;
    position: relative;
    top: 0%;
    gap: 5px;
    overflow: auto;
    scrollbar-width: thin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .levelGroup {
    color: #7FB1B2;
    font-weight: 500;
    position: absolute;
    top: 70%;
    font-size: 0.75em;
  }

  .levelGroupInsta {
    color: #7FB1B2;
    font-weight: 500;
    position: relative;
    top: 0%;
    font-size: 0.75em;
  }

  .rowButtons {
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    margin-left: 10px;
    position: absolute;
    bottom: 5%;
  }

  .rowButtonsInsta {
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
    margin-left: 10px;
    position: relative;
    top: 5%;
  }

  .newInstaButton {
    width: auto !important;
    height: 25px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 0.65em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 4px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
  }

  .newPlanningButton {
    width: auto !important;
    height: 25px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 0.65em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .newMessageButton {
    width: 27px !important;
    height: 27px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 0.65em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding: 0px !important;
  }

  .__description {
    width: 80%;
    height: 35px;
    font-size: 0.75em !important;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    text-align: center;
    position: absolute;
    top: 46%;
  }

  .__descriptionInsta {
    width: 80%;
    height: 35px;
    font-size: 0.75em !important;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    text-align: center;
    position: relative;
    top: 0%;
  }

  .tarif {
    font-size: 0.75em;
    font-weight: 600;
    color: #333;
    margin-top: var(--mb-1);
    position: absolute;
    top: 75%;
  }

  .tarifInsta {
    font-size: 0.75em;
    font-weight: 600;
    color: #333;
    margin-top: var(--mb-1);
    position: relative;
    top: 0%;
  }

  .tarif span {
    font-weight: 300;
  }

  .card {
    height: 305px;
    width: 85%;
    background: #FAFAFA;
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 2.5px solid #707070;
    border-bottom: 2.5px solid #707070;
    margin: 0px auto;
  }

  .card:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 40px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .card::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 20px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .teacherCard {
    height: 345px !important;
    width: 100% !important;
    background: #FAFAFA;
    padding: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-right: 2.5px solid #707070;
    border-bottom: 2.5px solid #707070;
  }

  .__tag__topic {
    background: #d5d5d5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2.5px 5px;
    border-radius: 8px;
    font-size: 0.75em;
  }

  .ribbon {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 0px;
    padding-left: 30px;
    padding-right: 20px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    height: 20px;
    background: #60CD95;
  }

  .ribbonInsta {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 5px;
    right: 0px;
    padding-left: 30px;
    padding-right: 20px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    height: 20px;
    background: #60CD95;
  }

  .ribbon:after {
    height: 20px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 12px;
    border-color: transparent transparent transparent white;
  }

  .ribbonInsta:after {
    height: 20px;
    content: '';
    position: relative;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 12px;
    border-color: transparent transparent transparent white;
  }

  .credit {
    font-size: 0.65em;
    position: relative;
    bottom: 4px;
  }

  .creditInsta {
    font-size: 0.65em;
    position: relative;
    bottom: 4px;
  }
}