.header {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center;
  margin: 5em 0 1em 0;
}

.left {
  width: 25% !important;
}

.right {
  width: 25% !important;
  margin-left: 4%;
}

.__btn__challenge {
  width: 90% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.__btn__challengeX {
  opacity: 0.75;
  background-color: #7d7d7d74 !important;
  color: var(--text-color) !important;
  box-shadow: 3px 6px 1px -1px var(--text-color) !important;
  cursor: not-allowed !important;
}

.center {
  width: 45% !important;
  /* flex: 1 !important; */
  height: 2px;
  border: 1px dashed var(--text-color);
  /* margin: 0 var(--mb-4); */
}

.left .subtitle {
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 0em;
}

.main__row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--mb-2);
}

/* .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .main__left,
.main__right {
  width: 70%;
  height: 25%;
}
 */

.main__left {
  /* flex: 2; */
  width: 70%;
}

.main__right {
  /* flex: 0.8; */
  margin-top: 1.5em;
  width: 27%;
}

.mainLeftContentEmpty {
  width: 100% !important;
}

.main__left__content {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: var(--mb-6);
}

.graphe__right,
.graphe__left {
  height: 400px;
  gap: 20px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.graphe__left__header {
  margin-left: var(--mb-2);
  padding-top: var(--mb-2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.__garphe__right__header {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.4em;
  margin-top: 1.5em;
}

.select {
  position: relative;
  height: 45px;
  width: 100%;
  max-width: 320px;
  padding-top: 5px;
  text-align: left;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.__first__card,
.__second__card {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background: #fafafa;
  border: 2px solid #dbdbdb;
}

.__first__card h4 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: var(--text-color);
  margin-top: var(--mb-1);
}

.__second__card {
  margin-top: var(--mb-3);
  height: 255px;
}

.__second__card span {
  text-transform: uppercase;
  font-size: 1em;
}

.__second__card .code {
  width: 150px;
  height: 40px;
  background: #fff;
  border: 1px dashed var(--text-color);
  display: flex;
  margin: var(--mb-3) auto;
  border-radius: 50px;
}

.code span {
  margin: auto;
  font-size: 1.2em;
  font-weight: 500;
}

.__content__btn button:first-child {
  margin-bottom: var(--mb-2);
}

.__btn {
  min-width: 200px !important;
  max-width: 200px !important;
  padding-top: 10px;
  height: 38px !important;
}

.array__conent {
  width: 100%;
  margin-top: var(--mb-4);
  max-height: 700px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-2) var(--mb-3);
}

/* Array */

.array__conent__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--mb-2);
}

.filed__search {
  width: 300px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.array__conent__header * input,
.array__conent__header * button {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

.array__conent__header * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.array__conent__header * input::placeholder {
  color: var(--text-color);
}

.__array {
  width: 100% !important;
  max-height: 500px;
  margin-top: var(--mb-6);
  background: #fff;
}

/* Drawer */

.drawer {
  position: relative;
  width: 100%;
  margin-top: var(--mb-6);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.field__text,
.field div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.field div {
  padding-top: 10px !important;
}

.drawer___header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.drawer___header h3 {
  font-family: var(--title-font);
  font-size: 3.2em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
}

.__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-4);
  gap: 15px;
}

.faq {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5em;
  color: #707070;
}

.content {
  margin-bottom: var(--mb-6);
}

.__overflow__Y {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
}

.tableHeader {
  color: var(--first-color);
}

.filed__search {
  width: 300px;
  padding: 0;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding-bottom: 2px;
  overflow: hidden;
}

/*  */
.inputSearchContent * input,
.inputSearchContent * button {
  background: transparent !important;
  border: unset !important;
}

.inputSearchContent * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.inputSearchContent * input::placeholder {
  color: var(--text-color);
}

/* .inputSearchContent * button {
  background: rgba(255, 255, 255, 0) !important;
} */

.elt {
  margin-bottom: 50px !important;
}

@media only screen and (max-width: 767px) {
  .header {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
    margin: 0;
    text-align: center;
  }

  .left {
    width: 75% !important;
  }

  .right {
    width: 90% !important;
    margin-left: 0%;
    margin-top: 10px;
  }

  .array__conent {
    width: 100%;
    margin-top: var(--mb-4);
    max-height: 700px;
    background: #fafafa;
    border-radius: 10px;
    padding: 10px;
  }

  .array__conent__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0px;
  }

  .array__conent__header * input::placeholder {
    color: var(--text-color);
    font-size: 0.75em;
  }

  .filed__search {
    width: 100%;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 2px;
  }

  .__array {
    width: auto !important;
    max-height: 500px;
    margin-top: var(--mb-6);
    background: #fff;
  }

  .__btn {
    min-width: 150px !important;
    max-width: auto !important;
    padding-top: 4px;
    height: 30px !important;
  }

  .main {
    width: 100% !important;
    height: auto !important;
    margin-top: 0em;
  }

  .main__row {
    width: 100% !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    justify-content: flex-start !important;
    gap: 0px !important;
  }

  .main__left {
    width: 100%;
  }

  .main__right {
    width: 100%;
    margin-top: 1.5em;
  }

  .mainLeftContentEmpty {
    width: 100% !important;
    margin-top: 20px;
  }

  .drawer__content {
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

.publications {
  margin-top: 40px;
}