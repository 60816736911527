.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.tab {
  width: 180px;
  height: 50px;
  margin: 0 var(--mb-1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  background: #FAFAFA;
  border: 1px solid #B9B9B9;
  border-radius: 24px;
  opacity: 1;
  color: #868686;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.tab:hover {
  background: var(--first-color);
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.tabActive {
  background: #FFFFFF;
  box-shadow: 4px 8px 16px #7FB1B234;
  border: 1px solid #7FB1B2;
  opacity: 1;
  color: #7FB1B2;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

@media only screen and (max-width: 1050px) {
  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  .tab {
    width: 100px;
    height: 35px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    background: #FAFAFA;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    opacity: 1;
    color: #868686;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .tab:hover {
    background: var(--first-color);
    color: #FFF;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .tabActive {
    background: #FFFFFF;
    box-shadow: 4px 8px 16px #7FB1B234;
    border: 1px solid #7FB1B2;
    opacity: 1;
    color: #7FB1B2;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }
}