.paragraphe {
  width: 100%;
  height: 100%;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.title {
  color: var(--first-color);
}

.button {
  max-width: 100px;
  min-width: 100px;
  height: 32px !important;
}

.field {
  width: 100%;
  height: 40px;
  border: 1px solid #707070;
  border-radius: 8px;
}

.input ::placeholder {
  font-style: italic;
  font-size: 14px;
}

.modal {
  border-radius: 5px;
  box-shadow: 8px -9px 0px -1px #D6D6D6;
}