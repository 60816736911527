.customButtonStyle {
    height: auto;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: medium;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.customButtonStyle:hover {
    transform: scale(1.03) !important;
}

.customButtonStyle:active {
    transform: scale(0.99) !important;
}

@media only screen and (max-width: 680px) {
    .customButtonStyle {
        font-size: 12px;
        font-weight: 300;
    }
}