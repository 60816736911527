.card__content {
  width: 100%;
  min-height: 400px;
  padding: 10px;
  border-radius: 10px;
}

.card__content h3 {
  color: var(--title-color);
  text-transform: none;
  margin-bottom: var(--mb-1);
}

.grid {
  display: grid;
  row-gap: 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.3em;
  }
}

@media only screen and (min-width: 1050px) {
  .card__content h3 {
    color: var(--title-color);
    text-transform: none;
    margin-bottom: var(--mb-1);
    font-size: 1em;
  }
}