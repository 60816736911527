.exercice {
  margin-bottom: 4em;
  height: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5em;
  margin-bottom: 4em;
}

.content {
  overflow-y: scroll;
  padding: 5%;
  height: 600px;
}

.__button__back {
  min-width: 300px;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}


.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.title__main {
  text-align: center;
  margin-bottom: 2em;
}

.title {
  margin: 0.5em 0 1em 0;
  font-size: 2.8em;
}

.main {
  width: 100%;
  border-radius: 10px;
}

.__ennnoncer {
  margin-bottom: 1em;
}

.__icon_drawer {
  position: absolute;
  top: 5%;
  right: 5%;
}

.title__drawer {
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.3em;
  text-transform: uppercase;
}

.letter {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 800px;
  /* height: 70vh; */
  padding: 4em;
  width: 65%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em !important;
}

.letter:before,
.letter:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.letter:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}

.letter:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.__button__indice {
  margin-right: 1em;
}