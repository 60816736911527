.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.leftBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10%;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 40px;
}

.titleBox {
    display: flex;
    flex-direction: row;
}

.titleOne {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
    margin-right: 12px;
}

.titleTwo {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
}

.message {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

.infoBox {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.imagesBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.numbersBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    left: -30px;
    font-family: "Poppins";
    font-weight: bold;
    color: rgb(8, 7, 7);
    font-size: 15px;
}

.numberOne {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 14px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.numberTwo {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 12px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.studentOne {
    height: 70px;
    width: 70px;
}

.studentTwo {
    height: 70px;
    width: 70px;
    position: relative;
    left: -20px;
}

.studentThree {
    height: 70px;
    width: 70px;
    position: relative;
    left: -40px;
}

.rightImagesBox {
    width: 40%;
}

.studentGirl {
    height: 480px;
    margin-top: 20px;
}

.rateOne {
    height: 90px;
    position: relative;
    top: -63%;
}

.rateTwo {
    height: 90px;
    position: relative;
    top: -43%;
    right: -54%;
}

.rateThree {
    height: 90px;
    position: relative;
    top: -18%;
    right: -7%;
}

.lottieImage {
    height: 100px;
    position: relative;
    left: 9%;
    top: -20px;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .leftBox {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 10%;
        margin: 0px auto;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
    }

    .titleOne {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
        margin-right: 12px;
    }

    .titleTwo {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
    }

    .message {
        font-size: 14px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

    .rightImagesBox {
        height: 350px;
        width: 90%;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
    }

    .studentGirl {
        height: 300px;
        width: 90%;
        margin: 40px auto;
    }

    .rateOne {
        height: 46px;
        width: 90px;
        position: relative;
        top: -72%;
    }

    .rateTwo {
        height: 46px;
        width: 90px;
        position: relative;
        top: -62%;
        right: -74%;
    }

    .rateThree {
        height: 46px;
        width: 90px;
        position: relative;
        top: -52%;
        right: -64%;
    }

    .infoBox {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
    }

    .lottieImage {
        height: 100px;
        position: relative;
        left: -29%;
        top: -10px;
    }
}