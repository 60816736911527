.step_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    gap: 20px;
}

.formsteps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 10px;
    width: 70px;
}

.step_number {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    margin: 0px auto;
    color: #C0C0C0;
    border: 1px solid #C0C0C0;
}

.step_number_active {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #7FB1B2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    margin: 0px auto;
    color: white;
}

.step_title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    font-size: 8px;
}

.step_title_active {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    font-size: 8px;
    color: #000;
}

.spacer {
    height: 1px;
    width: 20px;
    background: #707070;
    position: relative;
    top: -10px;
    left: 0px;
}