.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    text-align: center;
    z-index: -1;
}

.contactBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
    width: 155%;
}

.contactMain {
    height: 831px;
    width: 80%;
    margin: 0px auto;
    background: rgb(255, 248, 242);
    display: flex;
    flex-direction: row;
    z-index: -1;
}

.formBox {
    width: 50%;
    margin: 0px auto;
    padding: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.nameInput {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.lastnameInput {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.emailInput {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.phoneInput {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.textareaInput {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    height: 250px;
    background: rgb(255, 248, 242);
}

.submitButton {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    padding: 1em 5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.contactTitle {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -145px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.contactSubtitle {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.checkboxTitle {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.checkboxStyle {
    color: #636363;
}

.inputDiv {
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .mainContainer {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 2%;
        position: relative;
        top: 20px;
    }

    .contactBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .contactMain {
        height: auto;
        width: 100%;
        margin: 0px auto;
        background: rgb(255, 248, 242);
        display: flex;
        flex-direction: column;
    }

    .contactContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
        text-align: center;
        z-index: -1;
    }

    .contactTitle {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 10px;
        margin-top: 10px;
        z-index: 1;
    }

    .contactSubtitle {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 0px;
        font-weight: 500;
        margin-top: 0px;
    }

    .ellipseTitle {
        position: relative;
        left: -95px;
        width: 25px;
        bottom: 20px;
        z-index: -1;
    }

    .formBox {
        width: 100%;
        margin: 0px auto;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .nameInput {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .lastnameInput {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .emailInput {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .phoneInput {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .textareaInput {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        height: 250px;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .submitButton {
        margin-top: 10px;
        cursor: pointer;
        border: none;
        padding: 1em 5em;
        background: var(--first-color);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        cursor: pointer;
        color: #fff;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .checkboxTitle {
        display: flex;
        flex-direction: row;
        text-align: left;
        font-size: 10px;
        font-family: "Poppins";
    }

    .checkboxStyle {
        color: #636363;
        font-size: 10px;
        font-family: "Poppins";
        font-weight: 500;
    }

    .inputDiv {
        text-align: left;
        width: 100%;
    }

}