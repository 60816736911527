.exercice {
  margin-bottom: 4em;
  height: 100%;
}

.title {
  width: 100%;
  margin: 0.5em auto 1em auto;
  max-width: 370px;
  text-align: center;
}

.main {
  width: 100%;
  border-radius: 10px;
}

.__ennnoncer {
  margin-bottom: 1em;
}

.__icon_drawer {
  position: absolute;
  top: 5%;
  right: 5%;
}

.title__drawer {
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.3em;
  text-transform: uppercase;
}

.letter {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 90vh;
  padding: 5em;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em !important;
}

.letter:before,
.letter:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.letter:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}

.letter:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.__button__indice {
  margin-right: 1em;
}