.card {
  position: relative;
  /* display: inline-block; */
  /* width: calc(100% * (1 / 4) - 10px - 1px); */
  /* width: 100%; */
  height: 260px;
  /* max-width: 25%; */
  flex-grow: 1;
  background: url("../../../assets/images/teacher/card.svg") center center no-repeat;
  background-size: contain;
  margin-bottom: 1em;
  padding-top: 2em;
  transform-origin: 0 0;
  transition: all 0.3s;
}

/* @media only screen and (max-width: 500px) {
  .card {
    max-width: initial;
  }
} */