.navbar {
    height: 80%;
    background: #80B1B2;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
    -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
}

.userNavInfoBox {
    height: auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.userNavBox {
    height: 48%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.infoBox {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.infoBoxHoverSchool {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.infoBoxHoverNat {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.info {
    height: 100%;
    width: 100%;
    background: #669E9D;
    border-radius: 4px;
    color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 2px;
    align-items: center;
    object-fit: contain;
}

.info .left {
    font-size: 10px;
    object-fit: contain;
}

.info .leftText {
    position: relative;
    top: -1px;
    object-fit: contain;
}


.info .leftTextHover {
    position: relative;
    top: -1px;
    object-fit: contain;
}

.infoBoxHoverSchool:hover::after {
    content: 'Etablissement';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.infoBoxHoverNat:hover::after {
    content: 'National';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.info .right {
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.coinsInfo {
    font-size: 7px;
    margin-left: 2px;
    position: relative;
    top: -2px;
}

.classementInfo {
    font-size: 7px;
    margin-left: 0px;
    position: relative;
    top: -2px;
}

.infoPic {
    margin-left: 7px;
}

@media only screen and (max-width: 767px) {
    .navbar {
        height: 80%;
        width: 100%;
        background: #80B1B2;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
        -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
    }

    .userNavInfoBox {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .userNavBox {
        height: 85%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 1px;
    }

    .infoBox {
        height: 100%;
        width: 54px;
        background: #A3E2E3;
        border-radius: 4px;
        padding: 2px;
        margin-left: 4px;
    }

    .info {
        height: 100%;
        width: 100%;
        background: #669E9D;
        border-radius: 4px;
        color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
        align-items: flex-start;
        object-fit: contain;
    }

    .info .left {
        font-size: 0.6em;
        object-fit: contain;
    }

    .info .leftText {
        position: relative;
        top: -1px;
        object-fit: contain;
    }

    .info .right {
        font-weight: 500;
        font-size: 0.6em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        object-fit: contain;
    }

    .coinsInfo {
        font-size: 7px;
        margin-left: 2px;
        position: relative;
        top: -2px;
    }

    .classementInfo {
        font-size: 7px;
        margin-left: 0px;
        position: relative;
        top: -2px;
    }

    .infoPic {
        margin-left: 4px;
        margin-bottom: 3px;
        margin-right: 4px;
    }
}