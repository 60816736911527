.content {
  margin-top: 2%;
  background: #fafafa;
  padding: 2% 3%;
  height: 700px;
  border: 1px solid rgba(204, 204, 204, 0.506);
  border-radius: 8px;
  overflow-y: scroll;
}

.header {
  width: 100%;
  background: #fafafa;
  border: 1px solid rgba(204, 204, 204, 0.506);
  border-radius: 8px;
  margin-bottom: var(--mb-3);
  padding: 1.5% 2%;
}

.title h2 {
  color: var(--first-color);
  text-transform: uppercase;
  font-size: 1em;
}

.title h2 span strong {
  color: var(--first-color);
  text-transform: initial;
}

@media only screen and (max-width: 767px) {
  .title h2 {
    color: var(--first-color);
    text-transform: uppercase;
    font-size: 0.7em;
  }
}
