.paragraphe {
  width: 100%;
  height: 100%;
  max-width: 80%;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

.title {
  color: var(--first-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1em;
  color: #FE3232;
}


.modal {
  border-radius: 5px;
  box-shadow: 8px -8px 0px -1px #D6D6D6;
}

.albert {
  text-align: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.timer {
  color: var(--first-color);
  font-size: 28px;
  font-weight: 500;
}


.main {
  width: 100%;
  background-color: #fafafa;
  border-radius: 5px;
  text-align: center;
  padding: 2%;
  margin-top: var(--mb-3);
}

.content {
  padding: 0 3%;
}

.main h3 {
  color: var(--first-color);
  text-transform: uppercase;
  margin: var(--mb-1) 0;
  font-weight: 500;
}