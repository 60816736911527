.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.materialsContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    height: 420px;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
    text-align: left;
    position: relative;
    left: -100px;
}

.ellipseTitle {
    position: relative;
    left: -100px;
    width: 35px;
    bottom: 115px;
    z-index: -1;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
    background: white;
    height: 100%;
    padding-top: 2%;
    padding-bottom: 5%;
    padding-left: 5%;
}

.subBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.circleOne {
    height: 80px;
    width: 80px;
    background: #FAE4C6;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleTwo {
    height: 80px;
    width: 80px;
    background: #DBE2DE;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleThree {
    height: 80px;
    width: 80px;
    background: #FFD5C9;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleOneNumber {
    color: white;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleTwoNumber {
    color: white;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleThreeNumber {
    color: white;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleTitle {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 0px;
}

.circleMessage {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(47, 47, 47);
    line-height: 20px;
}

.diploma {
    position: relative;
    left: 40%;
    width: 320px;
    bottom: 75px;
    z-index: -1;
}

@media only screen and (max-width: 680px) {

    .materialsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: auto;
        margin-top: 0px;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 2;
        text-align: center;
        position: relative;
        top: -70px;
        left: 0px;
    }

    .ellipseTitle {
        position: relative;
        left: -45px;
        width: 20px;
        bottom: 130px;
        z-index: 0;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: white;
        height: 100%;
        padding-top: 2%;
        padding-bottom: 5%;
        padding-left: 0%;
        position: relative;
        top: -50px;
    }

    .subBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .circleOne {
        height: 62px;
        width: 62px;
        background: #FAE4C6;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleTwo {
        height: 62px;
        width: 62px;
        background: #DBE2DE;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleThree {
        height: 62px;
        width: 62px;
        background: #FFD5C9;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleOneNumber {
        color: white;
        font-family: "Poppins";
        font-weight: bold;
        font-size: 45px;
        line-height: 68px;
    }

    .circleTwoNumber {
        color: white;
        font-family: "Poppins";
        font-weight: bold;
        font-size: 45px;
        line-height: 68px;
    }

    .circleThreeNumber {
        color: white;
        font-family: "Poppins";
        font-weight: bold;
        font-size: 45px;
        line-height: 68px;
    }

    .circleTitle {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 15px;
        color: rgb(47, 47, 47);
        line-height: 17px;
        margin-top: 15px;
    }

    .circleMessage {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .diploma {
        position: relative;
        left: -40%;
        height: 90px;
        width: 80px;
        bottom: 5px;
        z-index: -1;
        transform: rotate(20deg);
    }

}