.__topBlock {
  width: 100%;
  column-gap: 12px;
  height: calc(100% - 200px);
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.__topBlock>* {
  height: fit-content;
  font-size: 25px;
  font-family: "CartoonFont";
}

.__topBlock .__topBlockEntry {
  display: flex;
  width: 100%;
  column-gap: 12px;
  margin-top: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.__columnArrangement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.__title {
  font-size: 2em;
  margin-left: 15px;
}

.__header {
  display: flex;
}

.convertedAmount {
  font-size: 25px;
  text-align: center;
  font-family: var(--title-font);
}

.field {
  height: 45px !important;
  border-radius: 16px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
  font-size: 12px;
}

.field::placeholder {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
  opacity: 0.3;
}

/* New Css Styles */

.mainBox {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 4%;
  margin-top: 20px;
  overflow: hidden;
  background: #FFF;
}

.soldeBox {
  width: 65%;
  height: 80px;
  background: #FFF;
  box-shadow: 4px 8px 18px #8686862F;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
}

.soldeBoxTitle {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.amountBox {
  width: auto;
  height: 55px;
  background: #E5FFF2;
  border: 1px solid #4BB980;
  border-radius: 18px;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.detailsBox {
  background: #FAFAFA;
  border: 1px solid #BFBCBC;
  border-radius: 40px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 320px;
  width: 65% !important;
  padding: 50px;
  margin-top: 30px;
  z-index: 1;
}

.titleBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.detailsBoxTitle {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
}

.detailsBoxMessage {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.newButton {
  width: 150px !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
  -moz-box-shadow: 2px 4px 1px -1px #5a8484;
}

@media only screen and (max-width: 380px) {
  .soldeBox {
    width: 90%;
    height: 80px;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
  }
}

@media only screen and (min-width: 381px) and (max-width: 1050px) {
  .soldeBox {
    width: 65%;
    height: 80px;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
  }
}

@media only screen and (max-width: 1050px) {
  .mainBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 4%;
    margin-top: 20px;
    overflow: hidden;
    background: #FFF;
  }

  .soldeBoxTitle {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
  }

  .amountBox {
    width: auto;
    height: 55px;
    background: #E5FFF2;
    border: 1px solid #4BB980;
    border-radius: 18px;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .detailsBox {
    background: #FAFAFA;
    border: 1px solid #BFBCBC;
    border-radius: 40px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 220px;
    width: 90% !important;
    padding: 20px;
    margin-top: 30px;
    z-index: 1;
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .detailsBoxTitle {
    font-size: 1em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
  }

  .detailsBoxMessage {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .buttonBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .newButton {
    width: 150px !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
  }
}