.cardContainer {
  width: 100% !important;
}

.main_arrangment {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: -16px !important;
  min-height: 60vh;
  margin-top: 40px;
  min-width: 100%;
  background: #fafafa;
}

.main_arrangment_mobile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -16px !important;
  min-height: 60vh;
  margin-top: 40px;
  min-width: 100%;
  background: #fafafa;
}

.arrangment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monFormulaire {
  width: 80% !important;
  margin-left: 30px;
  margin-top: 70px !important;
  padding: 10px !important;
}

.retrait {
  margin-top: 80px !important;
  width: 50%;
}

.retrait_mobile {
  margin-top: 40px !important;
  width: 100%;
}

.retraitLabel {
  font-size: 27px !important;
  font-family: "CartoonFont" !important;
  font-weight: 200;
  text-transform: uppercase;
}

.demandeRetraitButton {
  height: 45px;
  width: auto;
  font-family: "Poppins";
  display: block;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-align: center;
  padding-top: 2px !important;
  font-weight: 400 !important;
  border: 2px solid #7FB1B2 !important;
}

/* New Css Styles */

.soldeBox {
  width: 100%;
  height: 80px;
  background: #FFF;
  box-shadow: 4px 8px 18px #8686862F;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
}

.soldeBoxTitle {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.amountBox {
  width: auto;
  height: 45px;
  background: #E5FFF2;
  border: 0px solid #4BB980;
  border-radius: 18px;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.amountBoxWaiting {
  width: auto;
  height: 45px;
  background: #FFE5C6;
  border: 0px solid #B9974B;
  border-radius: 18px;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.detailsBox {
  background: #FAFAFA;
  border: 1px solid #BFBCBC;
  border-radius: 40px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: 100% !important;
  padding-top: 20px;
  padding-bottom: 80px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 10px;
  z-index: 1;
}

.detailsBoxTitle {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.detailsBoxMessage {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.tabTitleBox {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.tabTitleBoxActive {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.informationBox {
  width: 140%;
  height: 180px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
}

.historyBox {
  height: 280px;
  width: 110%;
  border: 0px solid #BFBCBC;
  opacity: 1;
  /* border-radius: 18px; */
  opacity: 1;
  padding: 0px;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin-top: 20px;
}

.field {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid #BFBCBC !important;
  font-style: italic !important;
  color: #707070;
}

.texareaField {
  height: 80px !important;
  border-radius: 10px !important;
  border: 1px solid #BFBCBC !important;
  font-style: normal !important;
  font-size: 12px;
  color: #707070;
}

.field::placeholder {
  font-style: normal !important;
  font-size: 12px;
}

texareaFieldFilter {
  font-style: normal !important;
  font-size: 12px;
  color: #707070;
  height: 30px !important;
}

.texareaFieldFilter::placeholder {
  font-size: 12px;
}

.selectInput {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #BFBCBC;
  font-style: normal !important;
  width: 100%;
}

.bookingBox {
  height: 44px;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.studentAvatar {
  width: 30px;
  height: 30px;
  background: #FFF2E0;
  opacity: 1;
  border: 1px solid #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.studentName {
  color: #393E3E;
  font-size: 12px;
  font-weight: 500;
  font-family: "poppins";
  margin-left: 5px;
}

.dateBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  width: 200px;
}

.priceBox {
  width: 55px;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
}

.newButton {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
}

.custom-tabs .ant-tabs-ink-bar {
  display: none;
}

.custom-tabs .ant-tabs-tab .ant-tabs-tab-active {
  background: transparent;
  border: none;
}

.emptyClass {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.avatarNameBox {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  object-fit: contain;
}

.newInfoButton {
  width: 100px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #669e9d !important;
  -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
  -moz-box-shadow: 5px 7px 1px -1px #669e9d;
}

@media only screen and (max-width: 767px) {
  .cardContainer {
    min-height: 700px;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .soldeBox {
    width: 100%;
    height: 56px;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    z-index: 1;
  }

  .soldeBoxColumn {
    width: 100%;
    height: auto;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
    margin-top: 10px;
  }

  .soldeBoxTitle {
    font-size: 0.7em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
  }

  .amountBox {
    width: auto;
    height: 35px;
    background: #E5FFF2;
    border: 0px solid #4BB980;
    border-radius: 12px;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .amountBoxWaiting {
    width: auto;
    height: 35px;
    background: #FFE5C6;
    border: 0px solid #B9974B;
    border-radius: 12px;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  }

  .demandeRetraitButtonMobileBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
  }

  .demandeRetraitButtonMobile {
    height: 30px !important;
    width: auto !important;
    font-family: "Poppins";
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 10px !important;
    text-align: center !important;
    padding-top: 2px !important;
    font-weight: 400 !important;
    border: 2px solid #7FB1B2 !important;
  }

  .detailsBox {
    background: #FAFAFA;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: block;
    height: auto;
    width: 100% !important;
    padding-top: 20px;
    padding-bottom: 80px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: auto;
    z-index: 1;
    object-fit: contain;
  }

  .detailsBoxTitle {
    font-size: 10px;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .detailsBoxMessage {
    font-size: 8px;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .tabTitleBox {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 12px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.7em;
  }

  .tabTitleBoxActive {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 12px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.7em;
  }

  .informationBox {
    width: 100%;
    height: 180px;
    border: 1px solid #BFBCBC;
    border-radius: 18px;
    opacity: 1;
    padding: 20px;
  }

  .historyBox {
    height: 340px;
    width: 100% !important;
    border: 0px solid #BFBCBC;
    opacity: 1;
    /* border-radius: 18px; */
    opacity: 1;
    padding: 0px;
    overflow-y: scroll;
    scrollbar-width: thin;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
  }

  .field {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid #BFBCBC !important;
    font-style: italic !important;
    color: #707070;
  }

  .texareaField {
    height: 80px !important;
    border-radius: 10px !important;
    border: 1px solid #BFBCBC !important;
    font-style: normal !important;
    font-size: 0.8em !important;
    color: #707070;
  }

  .texareaField::placeholder {
    font-size: 0.8em !important;
  }

  texareaFieldFilter {
    font-style: normal !important;
    font-size: 0.8em !important;
    color: #707070;
    height: 30px !important;
  }

  .texareaFieldFilter::placeholder {
    font-size: 0.8em !important;
  }

  .field::placeholder {
    font-style: normal !important;
    font-size: 12px;
  }

  .selectInput {
    color: #707070;
    border-radius: 8px;
    border: 1px solid #BFBCBC;
    font-style: normal !important;
    width: 100%;
  }

  .newInfoButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 10px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #669e9d !important;
    -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
    -moz-box-shadow: 5px 7px 1px -1px #669e9d;
    margin: 0px auto;
  }

  .bookingBox {
    height: 65% !important;
    width: 100%;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .avatarNameBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    object-fit: contain;
  }

  .studentAvatar {
    width: 40px;
    height: 40px;
    background: #FFF2E0;
    opacity: 1;
    border: 1px solid #FFF;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .studentName {
    color: #393E3E;
    font-size: 10px;
    font-weight: 600;
    font-family: "poppins";
    text-transform: capitalize;
    margin-top: 5px;
  }

  .dateBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.65em;
    font-weight: 400;
    color: #393E3E;
    width: auto;
    text-transform: capitalize;
  }

  .priceBox {
    width: 45px;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    margin-top: 10px;
  }
}