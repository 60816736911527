.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.needHelpBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mainBox {
    display: flex;
    flex-direction: row;
    height: 540px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: rgb(255, 248, 242);
}

.leftBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 520px;
    width: 50%;
    padding-top: 4.5%;
    text-align: left;
}

.rightBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 45%;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 40px;
    margin-top: 0px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -1%;
    top: -50px;
    width: 37px;
    z-index: -1;
}

.message {
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.instaTitle {
    font-size: 22px;
    color: #2F2F2F;
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.instaTitle:hover {
    color: #2F2F2F;
}

.instaMessage {
    font-size: 18px;
    color: #8AB0B1;
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.instaMessage:hover {
    color: #8AB0B1;
}

.arrowNeedHelp {
    position: relative;
    left: 30%;
    top: -32px;
    width: 90px;
}

.instaProfImage {
    height: 420px;
    position: relative;
    right: 40px;
}

.callAction {
    height: 65px;
    width: 45.5%;
    z-index: 2;
    position: relative;
    bottom: 170px;
    left: 28.3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.buttonStyle {
    font-family: "Poppins";
    font-weight: bolder;
    cursor: pointer;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        top: 80px;
        padding-left: 0px;
        padding-right: 0px;
        background: rgb(255, 248, 242);
        text-align: left;
    }

    .leftBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding-top: 4.5%;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .rightBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 2;
        text-align: left;
    }

    .ellipseTitle {
        position: relative;
        left: -1%;
        top: -35px;
        width: 25px;
        z-index: 0;
    }

    .messageOne {
        font-size: 15px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: 500;
        text-align: left;
    }

    .message {
        font-size: 13px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        text-align: left;
    }

    .arrowNeedHelp {
        position: relative;
        left: 30%;
        top: -32px;
        height: 80px;
        transform: rotate(-40deg);
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .instaProfImage {
        height: auto;
        width: 100%;
        position: relative;
        right: 0px;
        z-index: 1;
    }

    .callAction {
        height: auto;
        width: 70%;
        z-index: 2;
        position: relative;
        bottom: 60px;
        left: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
    }

    .instaTitle {
        font-size: 9px;
        color: #2F2F2F;
        font-family: "Poppins";
        line-height: 15px;
        font-weight: normal;
        text-align: left;
    }

    .instaMessage {
        font-size: 8px;
        color: #8AB0B1;
        font-family: "Poppins";
        line-height: 15px;
        font-weight: normal;
        text-align: left;
    }

    .buttonStyle {
        font-size: 12px;
        font-family: "Poppins";
        font-weight: bolder;
        cursor: pointer;
    }

}