.__content__tabs {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: var(--mb-2);
  flex: 2;
}

.fullHeader {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.tabs {
  width: 85px;
  height: 85px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.fullTabs {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs:hover {
  background: var(--first-color);
}

.mobileTabs {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 0.3em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.chapitre__content {
  width: 100%;
  background: #fafafa;
  padding: 3em;
  min-height: 80vh;
  margin-bottom: 5em;
}

.subtitle {
  text-transform: uppercase;
  margin-bottom: var();
}

.row {
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  margin-top: 2em;
}

.__links {
  color: var(--text-color);
}

.__back {
  width: 220px;
  text-align: right;
}

.__back span {
  font-family: var(--title-font);
  font-size: 1.1em;
  color: #333;
  text-transform: uppercase;
}

.__back span:hover {
  color: var(--first-color);
  cursor: pointer;
}

.tabButtons {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
}

.fullscreenButton {
  width: 65px;
  margin-right: 12%;
}

.fullscreenButton span {
  font-size: 12px;
  margin-left: 5px;
  margin-top: 2px;
}

@media only screen and (max-width: 500px) {
  .__content__tabs {
    flex-direction: column-reverse;
    width: 100%;
  }

  .__back {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .fullscreenButton {
    width: auto;
    margin-right: 0%;
    margin-bottom: 20px;
  }

  .fullscreenButton span {
    font-size: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }

}

.mobileMenu {
  width: 105%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.mobileMenuFullscreen {
  width: 160%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .tabs {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    margin: 0 0.9em;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
}