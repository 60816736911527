.header {
  width: 100%;
  margin-bottom: 0.8em;
}

.filter {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-2);
  padding: 0 var(--mb-4);
}

.filed__search {
  width: 300px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.filter__left * input::placeholder,
.filter__right * input::placeholder {
  color: var(--text-color);
}

.filter__right {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.select {
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
}

.select {
  padding-top: 3px !important;
  text-align: left;
}

.__card {
  width: 400px;
  height: 350px;
}

.affix {
  position: relative;
  width: 170px;
  height: 50px;
  background: url("/src/assets/images/app/Instaprof_button.png") no-repeat
    center center;
  background-size: contain;
  cursor: pointer;
}

.instaprof__mobile {
  display: none;
}

.display__filter__mobile {
  display: none;
}

@media only screen and (min-width: 768px) {
  .grid {
    gap: 2em;
  }
}

@media only screen and (max-width: 700px) {
  .filter {
    height: auto;
    display: flex;
    flex-direction: column;

    padding: 3%;
  }

  .filed__search {
    width: 100%;
    padding: 0;

    margin-bottom: 0;
  }

  .filter__left * input::placeholder,
  .filter__right * input::placeholder {
    font-size: 13px;
  }

  .filter__left {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .filter__right {
    width: 100%;
    justify-content: space-between;
  }

  .display__filter__mobile {
    display: block;
  }

  /* .filter__right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .main {
    padding: 0 5%;
  }

  .select {
    width: 43vw;
    height: 38px;
    margin-top: 10px;
  }

  .affix {
    display: none;
  }

  .instaprof__mobile {
    position: fixed;
    display: block;
    width: 90%;
    height: 50px;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    border-radius: 5px;
  }

  .buttonInsta {
    background: #0ed290 !important;
    height: 30px !important;
    box-shadow: 3px 6px 1px -1px #0ed29198 !important;
    max-width: 70px !important;
  }
}
