.field {
  width: 100%;
  border: 1px solid #909090;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 3px 5px;
}

.form {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.checkbox {
  margin-bottom: var(--mb-2);
}

.customTextarea::placeholder {
  font-size: 16px;
}