.content {
  height: 100%;
  width: 100%;
}

.grid {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
}

@media only screen and (min-width: 768px) {
}
