.button {
    border: none;
    padding: 1em 2em;
    background: var(--first-color);
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.button:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.buttonRegister {
    border: 1px var(--first-color) solid;
    color: var(--first-color);
    padding: 1em 2em;
    background: white;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.buttonRegister:hover {
    border: none;
    background: var(--first-color);
    color: white;
}

.searchButton {
    border: none;
    padding: 1em 2em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.searchButton:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.submitButton {
    border: none;
    padding: 1em 5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    margin-top: 20px;
}

.submitButton:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.newsletterButton {
    height: 70px;
    border: none;
    padding: 0.5em 2.5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 0px;
    -o-border-radius: 50px;
}

.newsletterButton:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.scaleAnimation {
    transition: transform 0.3s ease-in-out;
}

.scaleAnimation:hover {
    transform: scale(1.1);
}

.scaleAnimationSmall {
    transition: transform 0.3s ease-in-out;
}

.scaleAnimationSmall:hover {
    transform: scale(1.05);
}

.animatedDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 50%;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding-left: 30px;
    padding-right: 30px;
    transition: transform 0.3s ease-in-out;
}

.animatedDiv:hover {
    transform: scale(1.1);
}

.animatedDivSeparator {
    height: 30px;
    width: 2px;
    background: var(--first-color);
}

.animatedDiv:hover .animatedDivSeparator {
    background-color: #ff9d00;
}

.animatedDivBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.animatedDivBoxTitle {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px;
    color: #080707;
    line-height: 15px;
}

.animatedDivBoxTitle:hover {
    color: #080707;
}

.animatedDivBoxSubtitle {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 12px;
    color: #080707;
    line-height: 15px;
}

.animatedDivBoxSubtitle:hover {
    color: #080707;
}

@keyframes float {
    0% {
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }

    50% {
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(-20px);
    }

    100% {
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

.floatAnimation {
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
}

.floatAnimationOne {
    transform: translatey(0px);
    animation: float 8s ease-in-out infinite;
}

.floatAnimationTwo {
    transform: translatey(0px);
    animation: float 7s ease-in-out infinite;
}

.floatAnimationDelayed1 {
    animation-delay: 3s;
}

.floatAnimationDelayed2 {
    animation-delay: 4s;
}

@keyframes underline {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.underline {
    position: relative;
    display: inline-block;
    z-index: -1;
}

.underline::after {
    content: '';
    position: absolute;
    width: 0;
    height: 24px;
    bottom: 7px;
    left: 0;
    background-color: #FFC8BA;
    transition: width 0.5s;
    animation: underline 3s forwards;
    z-index: -1;
}

.containerOneStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #F5CB93;
    position: relative;
    transition: all 0.3s ease;
}

.containerTwoStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #8AB0B1;
    position: relative;
    transition: all 0.3s ease;
}

.containerThreeStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #FFC8BB;
    position: relative;
    transition: all 0.3s ease;
}

.containerFourStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #FFDCAB;
    position: relative;
    transition: all 0.3s ease;
}

.cornerStyleUp {
    content: '""';
    position: absolute;
    width: 4px;
    height: 140px;
    background-color: white;
    transition: all 0.3s ease;
}

.cornerStyleDown {
    content: '""';
    position: absolute;
    width: 190px;
    height: 4px;
    background-color: white;
    transition: all 0.3s ease;
}

@keyframes borderColorRotationOne {
    0% {
        border-color: #F5CB93 transparent transparent transparent;
    }

    25% {
        border-color: #F5CB93 #F5CB93 transparent transparent;
    }

    50% {
        border-color: transparent #F5CB93 #F5CB93 transparent;
    }

    75% {
        border-color: transparent transparent #F5CB93 #F5CB93;
    }

    100% {
        border-color: #F5CB93 #F5CB93 #F5CB93 #F5CB93;
    }
}

@keyframes borderColorRotationTwo {
    0% {
        border-color: #8AB0B1 transparent transparent transparent;
    }

    25% {
        border-color: #8AB0B1 #8AB0B1 transparent transparent;
    }

    50% {
        border-color: transparent #8AB0B1 #8AB0B1 transparent;
    }

    75% {
        border-color: transparent transparent #8AB0B1 #8AB0B1;
    }

    100% {
        border-color: #8AB0B1 #8AB0B1 #8AB0B1 #8AB0B1;
    }
}

@keyframes borderColorRotationThree {
    0% {
        border-color: #FFC8BB transparent transparent transparent;
    }

    25% {
        border-color: #FFC8BB #FFC8BB transparent transparent;
    }

    50% {
        border-color: transparent #FFC8BB #FFC8BB transparent;
    }

    75% {
        border-color: transparent transparent #FFC8BB #FFC8BB;
    }

    100% {
        border-color: #FFC8BB #FFC8BB #FFC8BB #FFC8BB;
    }
}

@keyframes borderColorRotationFour {
    0% {
        border-color: #FFDCAB transparent transparent transparent;
    }

    25% {
        border-color: #FFDCAB #FFDCAB transparent transparent;
    }

    50% {
        border-color: transparent #FFDCAB #FFDCAB transparent;
    }

    75% {
        border-color: transparent transparent #FFDCAB #FFDCAB;
    }

    100% {
        border-color: #FFDCAB #FFDCAB #FFDCAB #FFDCAB;
    }
}

.containerOneStyle:hover {
    border-radius: 10px;
    animation: borderColorRotationOne 2s linear forwards;
}

.containerOneStyle:hover .cornerStyleUp {
    background-color: transparent;
}

.containerOneStyle:hover .cornerStyleDown {
    background-color: transparent;
}

.containerTwoStyle:hover {
    border-radius: 10px;
    animation: borderColorRotationTwo 2s linear forwards;
}

.containerTwoStyle:hover .cornerStyleUp {
    background-color: transparent;
}

.containerTwoStyle:hover .cornerStyleDown {
    background-color: transparent;
}

.containerThreeStyle:hover {
    border-radius: 10px;
    animation: borderColorRotationThree 2s linear forwards;
}

.containerThreeStyle:hover .cornerStyleUp {
    background-color: transparent;
}

.containerThreeStyle:hover .cornerStyleDown {
    background-color: transparent;
}

.containerFourStyle:hover {
    border-radius: 10px;
    animation: borderColorRotationFour 2s linear forwards;
}

.containerFourStyle:hover .cornerStyleUp {
    background-color: transparent;
}

.containerFourStyle:hover .cornerStyleDown {
    background-color: transparent;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 2s forwards;
}

.logoContainer {
    height: 90px;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.logoTitle {
    font-size: 28px;
    color: rgb(255, 255, 255);
    font-family: "Margin DEMO";
    line-height: 28px;
    font-weight: normal;
    margin-top: 0px;
}

.logoTitleHeader {
    font-size: 28px;
    color: #393E3E;
    font-family: "Margin DEMO";
    line-height: 28px;
    font-weight: normal;
    margin-top: 0px;
}

.containerBorderedOne {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 155px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #F5CB93;
    position: relative;
    transition: all 0.3s ease;
}

.containerBorderedTwo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 155px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #8AB0B1;
    position: relative;
    transition: all 0.3s ease;
}

.containerBorderedThree {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 155px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #FFC8BB;
    position: relative;
    transition: all 0.3s ease;
}

.cornerBorderedUp {
    content: '""';
    position: absolute;
    width: 4px;
    height: 60px;
    background-color: white;
    transition: all 0.3s ease;
}

.cornerBorderedDown {
    content: '""';
    position: absolute;
    width: 150px;
    height: 4px;
    background-color: white;
    transition: all 0.3s ease;
}

.containerBorderedOne:hover {
    border-radius: 10px;
    animation: borderColorRotationOne 2s linear forwards;
}

.containerBorderedOne:hover .cornerBorderedUp {
    background-color: transparent;
}

.containerBorderedOne:hover .cornerBorderedDown {
    background-color: transparent;
}

.containerBorderedTwo:hover {
    border-radius: 10px;
    animation: borderColorRotationTwo 2s linear forwards;
}

.containerBorderedTwo:hover .cornerBorderedUp {
    background-color: transparent;
}

.containerBorderedTwo:hover .cornerBorderedDown {
    background-color: transparent;
}

.containerBorderedThree:hover {
    border-radius: 10px;
    animation: borderColorRotationThree 2s linear forwards;
}

.containerBorderedThree:hover .cornerBorderedUp {
    background-color: transparent;
}

.containerBorderedThree:hover .cornerBorderedDown {
    background-color: transparent;
}

@media only screen and (max-width: 680px) {

    .animatedDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 420px;
        width: 70%;
        background: white;
        border-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding-top: 50px;
        padding-bottom: 50px;
        margin-top: 50px;
        transition: transform 0.3s ease-in-out;
    }

    .animatedDivBoxTitle {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        color: #393E3E;
        line-height: 15px;
        margin-bottom: 20px;
    }

    .animatedDivBoxSubtitle {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 20px;
        color: #080707;
        line-height: 15px;
    }

    .animatedDivSeparator {
        height: 2px;
        width: 40%;
        background: var(--first-color);
    }

    .buttonRegister {
        border: 1px var(--first-color) solid;
        color: var(--first-color);
        padding: 1em 2em;
        background: white;
        font-size: 8px;
        line-height: 15px;
        cursor: pointer;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .containerOneStyle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-bottom: 30px;
        border: 3px solid #F5CB93;
        position: relative;
        transition: all 0.3s ease;
    }

    .containerTwoStyle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-bottom: 30px;
        border: 3px solid #8AB0B1;
        position: relative;
        transition: all 0.3s ease;
    }

    .containerThreeStyle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-bottom: 30px;
        border: 3px solid #FFC8BB;
        position: relative;
        transition: all 0.3s ease;
    }

    .containerFourStyle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        border: 3px solid #FFDCAB;
        position: relative;
        transition: all 0.3s ease;
    }

    .containerBorderedOne {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 95px;
        width: 130%;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        border: 3px solid #F5CB93;
        position: relative;
        transition: all 0.3s ease;
    }

    .containerBorderedTwo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 95px;
        width: 130%;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-top: 40px;
        border: 3px solid #8AB0B1;
        position: relative;
        transition: all 0.3s ease;
    }

    .containerBorderedThree {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 95px;
        width: 130%;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-top: 40px;
        border: 3px solid #FFC8BB;
        position: relative;
        transition: all 0.3s ease;
    }

    .cornerBorderedUp {
        content: '""';
        position: absolute;
        width: 4px;
        height: 60px;
        background-color: white;
        transition: all 0.3s ease;
    }

    .cornerBorderedDown {
        content: '""';
        position: absolute;
        width: 150px;
        height: 4px;
        background-color: white;
        transition: all 0.3s ease;
    }

}

.newLoginButton {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 1em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
}