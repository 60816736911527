.full__content {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #f8f8f8;
  text-align: left;
  padding: 2%;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-bottom: var(--mb-3);
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.level h2 {
  font-size: 1.5em;
  text-align: center;
}

.__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start;
  gap: 1em;
}

.level {
  flex: 0.9;
}

.forms {
  flex: 1.1;
}

.forms {
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  gap: 0.5em;
  margin-bottom: 1em;
}

.field {
  background: #fff;
  padding: 0.35em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #707070;
  min-width: 200px;
}

.text__area {
  border: 1px solid #707070;
  padding: 0.35em;
  width: 100%;
  margin-top: 1em;
  background: #fff;
  border-radius: 5px;
}

.select *::placeholder {
  color: red !important;
}

.button {
  width: 100% !important;
  background: #636363 !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 1em;
}

.button:hover {
  background: var(--first-color) !important;
  color: #fff !important;
}

.__new__creneaux {
  width: 100% !important;
  background: var(--first-color) !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 0.5em;
}

.__new__creneaux:hover {
  background: #636363 !important;
  color: #fff !important;
}

.inputBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.levelBox {
  width: 70%;
}

.languageBox {
  width: 30%;
}

@media only screen and (max-width: 390px) {
  .full__content {
    max-width: 100%;
    padding: 4%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .forms {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .forms {
    flex: 1;
  }

  .__content {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1em;
  }

  .level {
    flex: 1;
    width: 100%;
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .levelBox {
    width: 100%;
  }

  .languageBox {
    width: 100%;
  }

}

@media only screen and (max-width: 1530px) {
  .__content {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1em;
  }
}

/* New Css Style */

.levelsBox {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-bottom: 30px;
}

.levelsBox span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  margin-top: 10px;
}

.levelsButtons {
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.levelButton {
  width: 156px;
  height: 50px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
}

.levelButtonActive {
  width: 156px;
  height: 50px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
}

.pricesBox {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-bottom: 30px;
}

.pricesBox span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
}

.priceBox {
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  position: relative;
  bottom: 7px;
}

.price {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  text-align: center;
}

.priceLevel {
  position: relative;
  bottom: 10px;
  width: 20%;
}

.HoursBox {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  margin-top: 30px;
}

.titleDescriptionBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  text-align: left;
}

.titleDescriptionBox span {
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  color: #868686;
}