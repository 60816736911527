.exo {
  position: relative;
  margin-top: 4em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.teacher__maa {
  position: absolute;
  width: 300px;
  height: 420px;
  top: -9%;
  left: 5%;
  background: url("../../../../assets/images/teacher/teacherMaa.svg") no-repeat
    center center;
  background-size: contain;
  z-index: 1;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.select {
  height: 40px;
  width: 500px;
  border-radius: 10px;
  padding-top: 4px;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.field {
  width: 100%;
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.field::placeholder {
  font-style: italic !important;
}

.main {
  width: 100%;
  height: 100%;
  margin: 2em 0;
  padding: var(--mb-4) var(--mb-3);
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  gap: 2em;
  border-radius: 10px;
}

.main__exo__edit {
  background: #fafafa;
  padding: 0 2%;
}

.form,
.preview {
  flex: 1;
}

.timline__exo__edit {
  padding-left: 2rem;
}

.label {
  margin-bottom: var(--mb-1);
}

.input {
  width: 100%;
  height: 40px;
  padding-top: 3px;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-3);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.main__title {
  text-align: center;
  font-size: 18px;
  margin-bottom: var(--mb-6);
  margin-top: var(--mb-5);
}

.__custom__ck_editor {
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 5px;
  min-height: 503px;
  margin-bottom: 26px;
}

.__submit__validation {
  min-width: 270px;
  max-width: 270px;
}
