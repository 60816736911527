.teacherEvents {
  width: 100%;
  height: auto;
  min-height: 300px;
  background: #fafafa;
  border-radius: 5px;
  overflow: hidden;
  padding: 0em 0em;
}

.bottom h2,
.top h2 {
  text-transform: unset;
}

.scheduled__course {
  margin-top: var(--mb-2);
}

.bottom {
  margin-bottom: 1em;
}

/* CARD */
.card {
  position: relative;
  width: 100%;
  min-height: 40px;
  background: #fff;
  border: 1px solid #bfbfbf;
  padding: 10px 5px 15px 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.tag {
  width: 120px;
  height: 28px;
  background: #d5d5d5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 8px;
  color: var(--text-color);
  margin: 0;
}

.tagIcon {
  width: 15px;
}

.challenge {
  position: absolute;
  width: 130px;
  height: 20px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  background: #0ed290;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  left: 10px;
  bottom: -10px;
}

.round {
  width: 10px;
  height: 10px;
  background: #0a9b6a;
  border-radius: 50px;
}

@media only screen and (max-width: 1050px) {
  .teacherEvents {
    display: none;
  }
}

/* New Css Styles */

.top {
  height: auto;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.title {
  font-size: 12px;
  font-family: "Poppins";
  color: #393E3E;
  font-weight: 600;
  margin-top: 0px;
  margin-left: 10px;
}

.titleIconBox {
  width: 40px;
  height: 40px;
  background: #FFF;
  box-shadow: 0px 3px 13px #69696929;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.emptyClass {
  width: 80%;
  height: 160px;
  background: #FAFAFA;
  border-radius: 34px;
  opacity: 1;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.newButton {
  width: auto !important;
  height: 25px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 2px !important;
  font-weight: 400 !important;
  border: none !important;
  margin: 0px auto;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.myClasses {
  height: 300px;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin-top: 20px;
}

.myChallengeClasses {
  height: auto;
  width: 100%;
  background: #FFF;
  border: 1px solid #0AD290;
  border-radius: 24px;
  opacity: 1;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin-top: 20px;
}

.classeCard {
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.challengeClasseCard {
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #0AD290;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.todayBox {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.challengeMatiers {
  width: auto;
  height: 30px;
  background: #e6faf4;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
  margin: var(--mb-1);
  padding-left: 0px;
  padding-right: 0px;
}

.avatarNameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  object-fit: contain;
}