.card {
  height: auto;
  width: 100%;
  position: relative;
  background: #fff;
  margin-bottom: 1em;
  box-shadow: 2px 4px 8px #CECECE29;
  border: 1px solid #BFBCBC81;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
}

.avatarBox {
  height: 100%;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.avatarPicture {
  height: 65px;
  width: 65px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.name {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.content {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.avatar {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
}

.student {
  background: #F2F2F2;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  text-transform: uppercase;
}

.tagAvis {
  background: #F2F2F2;
  padding: 10px 15px;
  border-radius: 20px;
  color: var(--title-color);
}


.tagAvis span strong {
  font-weight: 500;
}

.description {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 15px;
}

@media only screen and (max-width: 1050px) {
  .card {
    height: auto;
    width: 100%;
    position: relative;
    background: #fff;
    margin-bottom: 1em;
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .avatarBox {
    height: 100%;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .avatarPicture {
    height: 45px;
    width: 45px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1.5px solid #FFF;
    border-radius: 50%;
  }

  .name {
    font-size: 1em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    margin-top: 5px;
  }

  .content {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .avatar {
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
  }

  .student {
    background: #F2F2F2;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 20px;
    text-transform: uppercase;
  }

  .tagAvis {
    background: #F2F2F2;
    padding: 10px 15px;
    border-radius: 20px;
    color: var(--title-color);
  }

  .tagAvis span strong {
    font-weight: 500;
  }

  .description {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }
}