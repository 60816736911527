.title {
  color: #88b04b;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.paragraph {
  color: #404f5e;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-size: 20px;
  margin: 0;
}

.italic {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--title-font);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 120px;
}