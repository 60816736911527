.headerComponents {
  width: 100%;
  margin: 0px auto;
}

.__content__matiere {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 16px;
}

.__content__matiere img {
  width: 40px;
}

@media only screen and (max-width: 500px) {
  .__content__matiere {
    font-size: 14px;
  }

  .__content__matiere img {
    width: 30px;
  }
}