.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2em;
}

.app__container {
  width: 100%;
  max-width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.conversation,
.chat {
  height: 70vh;
}

.conversation {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #7FB1B2 !important;
  flex: 0.6;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 17px;
  scrollbar-width: none;
}

.conversation::-webkit-scrollbar {
  display: none;
}

.chat {
  flex: 1.4;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 16px;
}

.chat__area {
  height: auto;
  background: #fff;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  border-radius: 16px;
}

.chat__area__footer {
  margin-top: var(--mb-3);
}

@media only screen and (max-width: 500px) {}

/* New Css Style */

.conditionsBox {
  margin-top: 20px;
  font-size: 1em;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  width: 100%;
}

.conditionsBox a {
  text-decoration: underline;
  font-weight: 600;
}

.drawerTitle {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1050px) {
  .app__container {
    max-width: 100%;
  }

  .chat__area {
    height: 75vh;
    background: #FFF !important;
  }

  .row {
    flex-direction: column;
    gap: 0.4em;
  }

  .chat {
    background: #fff;
  }

  .chat.active {
    height: 85vh;
    visibility: visible;
    background: #fff !important;
    z-index: 78;
    border: 1px solid #707070;
    border-radius: 16px;
    padding-top: 0px;
  }

  .chat {
    position: absolute;
    width: 95%;
    left: 0;
    right: 0;
    margin: auto;
    flex: initial;
    visibility: hidden;
    transition: visibility 0.4s;
    transition: opacity 0.4s ease-out,
      transform 0.4s ease-out;
  }

  .chat__area__footer {
    margin-top: var(--mb-1);
  }

  .conditionsBox {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    width: 90%;
  }

  .conditionsBox a {
    text-decoration: underline;
    font-weight: 600;
  }

  .drawerTitle {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .conversation {
    width: 95%;
    flex: initial;
  }

  .conversation,
  .chat {
    height: 70vh;
  }
}