.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 40px auto;
    padding: 0 2%;
}

.plateformContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mainBox {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: white;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -24.5%;
    width: 35px;
    bottom: 45px;
    z-index: 0;
}

.videoBox {
    height: 575px;
    width: 95%;
    background-image: url("../../../../assets/images/app/teachers/video_bacground.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 83px;
    position: relative;
    top: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media only screen and (max-width: 680px) {

    .mainContainer {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0px auto;
        padding: 0 2%;
    }

    .mainBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        background: white;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -17.5%;
        width: 20px;
        bottom: 70px;
        z-index: 0;
    }

    .videoBox {
        height: 177px;
        width: 95%;
        background-image: url("../../../../assets/images/app/teachers/video_bacground.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 32px;
        position: relative;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

}