.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.advantageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mainBox {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: white;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -18.5%;
    width: 35px;
    bottom: 120px;
    z-index: 0;
}

.ellipseAdvantage {
    position: relative;
    left: 15%;
    height: 174px;
    bottom: 70px;
    z-index: 0;
}

.boxes {
    height: 423px;
    width: 95%;
    background: #FFF8F2;
    border-radius: 43px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    position: relative;
    top: -40px;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
}

.subBox {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.circleOne {
    height: 110px;
    width: 110px;
    background: rgba(245, 203, 147, 0.463);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleTwo {
    height: 110px;
    width: 110px;
    background: rgba(138, 176, 177, 0.314);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleThree {
    height: 110px;
    width: 110px;
    background: rgba(255, 200, 186, 0.718);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleOneNumber {
    color: rgb(224, 173, 106);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleTwoNumber {
    color: rgb(138, 176, 177);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleThreeNumber {
    color: rgb(250, 131, 131);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleTitle {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 40px;
}

.circleMessage {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 16px;
    color: rgb(47, 47, 47);
    line-height: 30px;
}

.aiBox {
    position: relative;
    bottom: 35px;
    left: 30px;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        background: white;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -43%;
        width: 25px;
        bottom: 65px;
        z-index: 0;
    }

    .ellipseAdvantage {
        position: relative;
        left: 38%;
        height: 84px;
        bottom: 20px;
        z-index: 2;
        transform: rotate(30deg);
    }

    .boxes {
        height: 773px;
        width: 95%;
        background: #FFF8F2;
        border-radius: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        position: relative;
        top: -40px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .subBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .circleOne {
        height: 65px;
        width: 65px;
        background: rgba(245, 203, 147, 0.463);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleTwo {
        height: 65px;
        width: 65px;
        background: rgba(138, 176, 177, 0.314);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleThree {
        height: 65px;
        width: 65px;
        background: rgba(255, 200, 186, 0.718);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleOneNumber {
        color: rgb(224, 173, 106);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .circleTwoNumber {
        color: rgb(138, 176, 177);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .circleThreeNumber {
        color: rgb(250, 131, 131);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .circleTitle {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 16px;
        color: rgb(47, 47, 47);
        line-height: 27px;
        margin-top: 20px;
    }

    .circleMessage {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
    }

    .aiBox {
        position: relative;
        bottom: 25px;
        left: 0px;
        height: 18px;
    }

}