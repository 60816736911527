.content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.user__group {
  position: absolute;
  left: 3.5em;
  top: -2.3em;
}

.__matieres {
  width: 150px;
  height: 30px;
  background: #d5d5d5;
  border-radius: 50px;
  margin-top: var(--mb-1);
}

.__top>div span {
  color: var(--text-color);
}

.__date {
  font-size: 10px;
}

.__top h3 {
  width: 100%;
  max-width: 160px;
  font-family: var(--title-font);
  font-size: 1.2em;
  margin-top: var(--mb-2);
  text-transform: uppercase;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 75px;
  font-weight: 500;
}

.__row {
  display: flex;
  gap: 2em;
  margin-bottom: 0.8em;
}

.__row>div span {
  color: var(--text-color);
  padding-left: 5px;
  font-size: 12px;
}

.__check {
  position: relative;
  top: 2px;
}

.button__pending {
  max-width: 160px;
  min-width: 160px;
  font-size: 10px !important;
  height: 30px !important;
  margin-bottom: 1em;
  background: #ea9610 !important;
  box-shadow: 3px 6px 1px -1px #c47b08 !important;
}

.matiers {
  position: relative;
  left: -10px;
  width: 140px;
  height: 25px;
  background: #d5d5d5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 9.5px;
  color: var(--text-color);
  margin: var(--mb-1);
}

.center {
  margin: 0 5px;
}

.__img {
  position: relative;
  left: -5px;
  top: 0px;
}

.appercue__continute {
  width: 100%;
  /* height: 100%; */
  height: 80vh;
  min-width: 600px;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 1.5em 2em;
}

.section__content,
.__big__title__content {
  width: 100%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.appercue__continute__content {
  margin-left: 5%;
}

.__big__title {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.section__content {
  background: var(--first-color);
}

.section__content h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.__badge {
  position: absolute;
  margin: auto;
  top: -30px;
  right: 10%;
  padding: 2px 10px;
  display: flex;
  border-radius: 15px;
}

.__badge span {
  margin: auto;
  font-size: 9px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

.__title__link {
  color: var(--first-color);
  font-size: 13px;
  z-index: 1;
}

.__title__link:hover {
  color: var(--first-color);
}

.__latext__content * strong em,
.__latext__content * strong {
  font-weight: 600 !important;
}

/*  */
.dropdown {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.__down {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.__down *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.__down {
  color: var(--text-color);
}

@media only screen and (max-width: 767px) {

  .user__group {
    position: relative;
    left: -5em;
    top: -2em;
  }

  .__badge {
    position: relative;
    top: -4em;
    right: -5em;
    padding: 2px 10px;
    display: flex;
    border-radius: 15px;
  }

  .__top {
    position: relative;
    top: -3em;
  }

  .__bottom {
    position: relative;
    top: 1em;
    display: flex;
    flex-direction: row;
  }

}


/* New Css Styles */

.card {
  height: 285px;
  width: 100%;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.userGroup {
  height: 30px !important;
  width: 30px !important;
  border: 1.5px solid #FFF;
  border-radius: 50%;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.publisherName {
  color: #393E3E;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  margin-top: 5px;
  line-height: 18px;
}

.rateBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.rateBox span {
  font-size: 12px;
  color: #707070;
  font-family: Rubik;
  font-weight: 500;
}

.courseTitle {
  height: auto;
  width: 200px;
  font-size: 15px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  text-align: center !important;
  line-height: 18px !important;
  margin: 5px auto;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.ribbon {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  top: 0px;
  right: -95px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 0px;
  height: 22px;
}

.ribbon:after {
  height: 22px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 10px 14px;
  border-color: transparent transparent transparent white;
}


@media only screen and (max-width: 403px) {
  .card {
    height: 160px !important;
    width: auto !important;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1.5px solid #707070;
    border-right: 1.5px solid #707070;
  }
}

@media only screen and (min-width: 404px) and (max-width: 1050px) {
  .card {
    height: 190px !important;
    width: 100%;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1050px) {
  .userGroup {
    height: 30px;
    width: 30px;
    border: 1.5px solid #FFF;
    border-radius: 50%;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .publisherName {
    color: #393E3E;
    font-size: 0.5em !important;
    font-family: Rubik;
    font-weight: 500;
    margin-top: 0px;
    line-height: 10px;
  }

  .rateBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.45em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .rateBox span {
    font-size: 0.8em;
    color: #707070;
    font-family: Rubik;
    font-weight: 500;
  }

  .courseTitle {
    height: auto;
    width: 100%;
    font-size: 0.65em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 10px !important;
    overflow: clip;
    margin: 5px auto;
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }

  .ribbon {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 0px;
    right: -50px;
    padding-left: 15px;
    padding-right: 10px;
    color: white;
    font-family: "Poppins";
    font-size: 0.6em;
    font-weight: 400;
    text-align: center;
    border-radius: 0px;
    height: 15px;
  }

  .ribbon:after {
    height: 15px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 6px 9px;
    border-color: transparent transparent transparent white;
  }

  .dropdown {
    width: 100px !important;
    height: 20px !important;
    background: var(--first-color) !important;
    border: unset !important;
    border-radius: 50px !important;
    box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    color: #fff !important;
    text-transform: capitalize;
    font-weight: 500 !important;
    font-size: 0.75em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .__date {
    font-size: 0.65em;
  }
}