.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 70px;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -28%;
    width: 52px;
    bottom: 58px;
    z-index: -1;
}

.lottieTitle {
    width: 180px;
    position: relative;
    right: -20%;
    bottom: 56px;
}

.message {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.boxesOne {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    width: 155%;
}

.boxesTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
    width: 155%;
}

.boxOneTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 18%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 15px;
    padding-right: 20px;
    margin-right: 30px;
}

.boxThree {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 18%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 15px;
    padding-right: 20px;
}

.boxTitle {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 18px;
    color: rgb(8, 7, 7);
    line-height: 20px;
    margin-left: 20px;
}

.seeMoreButton {
    height: 55px;
    width: 230px;
    border: 1px solid rgb(138, 176, 177);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 55px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    padding-left: 70px;
}

.seeMoreButtonArrow {
    height: 55px;
    width: 55px;
    border-radius: 30px;
    background: rgb(138, 176, 177);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 680px) {

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -35%;
        width: 20px;
        bottom: 58px;
        z-index: -1;
    }

    .lottieTitle {
        width: 110px;
        position: relative;
        right: 0%;
        bottom: 30px;
    }

    .message {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 30px;
        font-weight: normal;
        margin-top: 0px;
    }

    .boxesOne {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        width: 100%;
    }

    .boxesTwo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        width: 100%;
    }

    .boxOneTwo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 90px;
        width: 85%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 15px;
        padding-right: 20px;
        margin: 15px auto;
    }

    .boxThree {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 90px;
        width: 85%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 15px;
        padding-right: 20px;
        margin: 10px auto;
    }

}