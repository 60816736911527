.container__profile {
  width: 100%;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.__profile {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: relative;
  /* background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.headerBox {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: right;
  margin-top: 18px;
}

.back__icon {
  position: absolute;
  top: 3em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: auto;
}

.logoutButton {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 70px;
}

.pseudo {
  text-align: center;
  margin: var(--mb-2) 0;
  font-family: var(--title-font);
  font-size: 1.7em !important;
  text-transform: uppercase;
}

.info {
  width: 100%;
  text-align: center;
  margin: var(--mb-2) 0;
  text-transform: uppercase;
  color: var(--title-color);
  font-size: 1.5em;
  font-weight: 500;
}

.field {
  padding: 5px 10px;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
  margin-bottom: 15px;
  min-width: 165px;
}

.button {
  width: 100%;
  height: 35px !important;
  border-radius: 50px !important;
  background: var(--text-color) !important;
  border: none !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.passwordButton {
  width: 250px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: uppercase;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.__logout {
  height: 40px !important;
}

@media only screen and (max-width: 767px) {
  .back__icon {
    position: absolute;
    top: 1.7em;
    cursor: pointer;
  }

  .logoutButton {
    margin-right: 0px;
  }

  .buttonsFont {
    font-size: 0.8em !important;
    font-family: "Poppins" !important;
    font-weight: 600 !important;
    color: #FFF !important;
    text-transform: none !important;
  }

  .button {
    width: 100%;
    height: 35px !important;
    border-radius: 50px !important;
    background: var(--text-color) !important;
    border: none !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 0.8em !important;
  }

  .passwordButton {
    width: 250px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: uppercase;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
  }

  .headerBox {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 80%;
    align-items: center;
    justify-content: right;
    margin-top: 18px;
  }
}

/* New Css Styles */

.tabTitleBox {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.tabTitleBoxActive {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.buttonsFont {
  font-size: 14px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: #FFF !important;
  text-transform: none !important;
}

.sponsorButton {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background: white !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
  font-size: 13px;
  color: var(--first-color);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--first-color) !important;
  margin-left: 10px;
}

.sponsorButton:hover {
  background: var(--first-color);
  transform: scale(1.03) !important;
  color: white;
}