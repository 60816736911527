.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.chapitre__content {
  width: 100%;
  background: #fafafa;
  padding: 3em;
}

.subtitle {
  text-transform: uppercase;
}

.__title {
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-4);
}

.card__content {

  margin-top: 2em;
}

.__links {
  color: var(--text-color);
}

@media only screen and (max-width: 390px) {
  .chapitre__content {
    padding: 1em;
    min-height: initial !important;
  }

  .row {
    align-items: center;
    justify-content: center;
  }

  .center {
    display: none;
  }
}

.__content__level__matier {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--mb-3);
  font-family: var(--title-font);
  font-size: 1.7em;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: auto;
}

.__matier__bottom {
  color: #333333;
}

.content__left {
  display: flex;
  align-items: center;
}

.__back__level {
  float: right;
}

.__back__level {
  font-size: 15px;
  color: #333333;
}

.__back__level:hover {
  color: var(--first-color);
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .__content__level__matier {
    width: initial;
    flex-direction: column;
  }
}