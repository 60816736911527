.cours {
  position: relative;
  margin: 4em 0;
}

.appercue__continute {
  width: 100%;
  height: 600px;
  margin-top: var(--mb-1);
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.appercue__continute__bot {
  width: 100%;
  height: 100%;
  margin-top: var(--mb-1);
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.full_appercue__continute {
  width: 70%;
  margin: var(--mb-1) auto;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  position: fixed;
  bottom: 0px;
  top: 12%;
  left: 0%;
  right: 0%;
}

.__add__input:hover {
  color: #fff !important;
}

.appercue__continute h3 {
  font-size: 18px;
  margin-bottom: var(--mb-2);
}

.section__content,
.__big__title__content {
  width: 40%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.appercue__continute__content {
  margin-left: 5%;
  font-size: 0.8em;
}

.__big__title {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.section__content {
  background: var(--first-color);
}

.section__content h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.__content__matiere {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 16px;
  margin-bottom: var(--mb-2);
}

.header {
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.menu {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1050px) {
  .appercue__continute {
    min-width: 100%;
    padding: 1em;
  }

  .appercue__continute h3 {
    font-size: 17px;
  }

  .section__content,
  .__big__title__content {
    width: auto !important;
    padding-right: 0%;
    padding-top: 0em;
  }

  .__big__title,
  .section__content h4 {
    line-height: 30px !important;
    padding-left: 1em;
  }
}