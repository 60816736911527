.card {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #FFF;
  margin-bottom: var(--mb-4);
  -webkit-box-shadow: 2px 3px 0px 2px #cfd0d2;
  box-shadow: 3px 4px 0px 3px #cfd0d2;
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.card::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.container {
  width: 100%;
  min-height: 240px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-2);
  padding-left: var(--mb-1);
  padding-bottom: var(--mb-2);
}

.header {
  margin-bottom: var(--mb-2);
  display: flex;
  justify-content: space-between;
}

.user__infos h3 {
  text-transform: capitalize;
}

.body {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  cursor: pointer;
  object-fit: contain !important;
  border-radius: 24px;
}

.body img {
  width: 90%;
  height: fit-content;
  object-fit: cover;
  border-radius: 24px;
  margin-bottom: 1.5em;
}

.body video {
  width: 90%;
  height: fit-content;
  object-fit: cover;
  border-radius: 24px;
  margin-bottom: 1.5em;
}

.body video source {
  width: 90%;
  height: fit-content;
  object-fit: cover;
  border-radius: 24px !important;
  margin-bottom: 1.5em;
}

.button {
  padding: 5px auto;
  /* border: 1px dashed #707070; */
  border: none;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
}

.button:hover {
  /* border: 1px dashed #7FB1B2; */
  border: none;
  color: #7FB1B2;
}

.articleDrawer {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}

.cardArticle {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #fafafa;
  margin-bottom: var(--mb-4);
  -webkit-box-shadow: 2px 3px 0px 2px #cfd0d2;
  box-shadow: 3px 4px 0px 3px #cfd0d2;
}

.cardArticleImage {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #fafafa;
  margin-bottom: var(--mb-4);
}

.cardArticle:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardArticle::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: auto;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1050px) {

  .card {
    position: relative;
    width: 90%;
    min-height: 150px;
    background: #FFF;
    margin: 20px auto;
    -webkit-box-shadow: 1px 2px 0px 2px #B7BABC;
    box-shadow: 1px 2px 0px 1px #B7BABC;
  }

  .card:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 40px;
    background: radial-gradient(#B7BABC 4px, transparent 5px) repeat-y;
    background-size: 15px 15px;
    box-sizing: border-box;
  }

  .card::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 20px;
    background: radial-gradient(#fff 3px, transparent 4px) repeat-y;
    background-size: 15px 15px;
    box-sizing: border-box;
  }

  .user__infos h3 {
    text-transform: capitalize;
    font-size: 1em !important;
    color: #393E3E;
    line-height: 7px;
  }

  .user__infos span {
    font-size: 0.7em !important;
    color: #5D5D5D;
  }

  .postContent {
    font-size: 12px;
  }

  .body {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    margin-top: 0px;
  }

  .button {
    padding: 5px auto;
    /* border: 1px dashed #707070; */
    border: none;
    background: #fff;
    border-radius: 15px;
    cursor: pointer;
    font-size: 11px;
  }

  .buttonRow {
    margin-left: 10px;
  }

  .articleDrawer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    scrollbar-width: thin;
  }
}

/* New Css Styles */

.customPopover .ant-popover-inner {
  border-radius: 30px;
  /* Set desired border radius */
}