.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card span {
  margin-top: var(--mb-6);
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: 500;
}

.card img {
  cursor: pointer;
}
