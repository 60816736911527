.menu__modal {
  position: relative !important;
  border-radius: 20px !important;

}

.ant-modal-content {
  border-radius: 10px !important;
}


.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.container {
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;

}

.card {
  width: 230px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background: #fafafa;
  margin-bottom: var(--mb-2);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in;
}

.card span {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 1.2em;
  font-family: "CartoonFont";
  transition: all 0.5s ease-in;
  font-weight: 500;
}

.card:hover span {
  color: #fff !important;
}

.card:hover {
  background: #707070;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.icon {
  height: 55px;
  width: 55px;
  border: 1px solid #FFF;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@media only screen and (max-width: 500px) {
  .row {
    align-items: center;
    justify-content: space-around;
  }

  .card {
    width: 145px;
    height: 145px;
  }
}