.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.testimoniesBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTestimonies {
    position: relative;
    left: -10.5%;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.subtitle {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: Poppins;
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
}

.testimonies {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.currentTestimony {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
}

.currentTestimonyMessage {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.currentTestimonyName {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.currentTestimonyUser {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.testimonyOneImage {
    height: 92px;
    position: relative;
    top: -50px;
}

.testimonyTwoImage {
    height: 124px;
    position: relative;
    left: -30px;
    top: 80px;
}

.ellipseOne {
    width: 10px;
    position: relative;
    top: 160px;
    left: -60px;
}

.testimonyThreeImage {
    height: 147px;
    position: relative;
    left: -30px;
    top: 120px;
}

.currentTestimonyImage {
    height: 200px;
}

.ellipseTwo {
    position: relative;
    left: 30%;
    width: 15px;
    bottom: 405px;
}

.testimonyFourImage {
    height: 103px;
    position: relative;
    top: -120px;
}

.testimonyFiveImage {
    height: 109px;
    position: relative;
    top: 120px;
    right: -50px;
}

.ellipseThree {
    width: 15px;
    position: relative;
    top: 150px;
    right: 80px;
}

.testimonySixImage {
    height: 97px;
    position: relative;
    top: 150px;
    right: -30px;
}

@media only screen and (max-width: 680px) {

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTestimonies {
        position: relative;
        left: -14%;
        width: 25px;
        bottom: 125px;
        z-index: -1;
    }

    .subtitle {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: Poppins;
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
    }

    .testimonies {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 350px;
        width: 100%;
    }

    .currentTestimony {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        text-align: center;
        position: relative;
        top: -100%;
    }

    .currentTestimonyMessage {
        font-size: 12px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        position: relative;
        bottom: 20px;
    }

    .currentTestimonyName {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 20px;
    }

    .currentTestimonyUser {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 0px;
    }

    .testimonyOneImage {
        height: 102px;
        position: relative;
        top: 0px;
        left: -35%;
    }

    .testimonyTwoImage {
        height: 104px;
        position: relative;
        left: -40px;
        top: 300px;
    }

    .ellipseOne {
        width: 10px;
        position: relative;
        top: 30px;
        left: -35%;
    }

    .testimonyThreeImage {
        height: 127px;
        position: relative;
        left: -35%;
        top: 100px;
    }

    .currentTestimonyImage {
        height: 120px;
        margin: 20px auto;
    }

    .ellipseTwo {
        position: relative;
        left: 40%;
        width: 10px;
        bottom: 65px;
    }

    .testimonyFourImage {
        height: 113px;
        position: relative;
        top: -750px;
        left: 120px;
    }

    .testimonyFiveImage {
        height: 79px;
        position: relative;
        top: -780px;
        left: 150px;
    }

    .ellipseThree {
        width: 10px;
        position: relative;
        top: -820px;
        right: 70px;
    }

    .testimonySixImage {
        height: 97px;
        position: relative;
        top: -570px;
        left: 120px;
    }

}