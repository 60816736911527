.card {
  position: relative;
  width: 100%;
  height: 290px;
  /* min-height: 350px; */
  /* background: url('../../../../assets/images/teacher/cardAds.svg') no-repeat;
  background-size: contain; */
  background: #FAFAFA;
  margin-bottom: var(--mb-4);
  -webkit-box-shadow: 2px 3px 0px 2px #CFD0D2;
  box-shadow: 3px 4px 0px 3px #CFD0D2;
}

.card:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#CFD0D2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.card::after {
  content: '';
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-2);
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.code {
  width: 80px;
  height: 30px;
  border: #707070 1px dashed;
  text-align: center;
  border-radius: 50px;
  display: flex;
  background: #fff;
}

.code span {
  font-size: var(--normal-font-size);
  margin: auto;
  font-weight: 500;
}

.left {
  display: flex;
  align-items: center;
}

.date {
  margin-left: var(--mb-3);
}

.date span strong {
  color: #519259;
  font-weight: 500;
}

.date img {
  position: relative;
  top: -2px;
}

.text {
  margin-top: var(--mb-2);
  height: 140px;
  overflow-y: scroll;
  scrollbar-width: none;
}

/* .text::-webkit-scrollbar {
  display: none;
} */

.text p {
  margin-bottom: 10px;
}

.text .__range__date span:last-child {
  display: none;
}

.title__ads {
  font-size: 1.2em;
  text-transform: uppercase;
  margin-bottom: var(--mb-2);
  color: var(--title-color);
}

.text p {
  font-size: var(--small-font-size);
  color: #707070;
}

.footer {
  width: 100%;
  margin-top: var(--mb-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .button {
  min-width: 180px;
  height: 40px !important;
}

.reference span {
  font-size: var(--smaller-font-size);
}

.dropdown {
  width: 170px !important;
  height: 32px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.__down {
  border-radius: 10px !important;
}

.__down *:hover {
  color: var(--first-color) !important;
  background: #fff !important;
}



.__btn__footer {
  height: 33px !important;
  margin-left: 10px;
  max-width: 160px !important;
  min-width: 160px;
}

::-webkit-scrollbar {
  width: 8px;
}

/*  */

.__ennnoncer {
  margin-bottom: 1em;
}

.__icon_drawer {
  position: absolute;
  top: 5%;
  right: 5%;
}

.title__drawer {
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.3em;
  text-transform: uppercase;
}

.letter {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 85vh;
  padding: 5em;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em !important;
}

.letter:before,
.letter:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.letter:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}

.letter:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.__button__indice {
  margin-right: 1em;
}


@media only screen and (max-width: 500px) {
  .header {
    display: none;
  }

  .card {
    height: auto;
    padding-left: 5%;
  }

  .date span {
    font-size: 12px;
    text-align: left;
  }


  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header {
    flex-wrap: wrap;
  }

  .text {
    height: 250px;
  }

  .title__ads {
    font-size: 1em;
  }

  .__btn__footer {

    max-width: 110px !important;
    min-width: 110px;
  }

  .letter {
    position: relative;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 85vh;
    padding: 1em;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5em !important;
  }

}

.action {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {

  .action {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
  }

  .footerAction {
    width: 150px;
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    align-items: center;
    justify-content: center;
  }

}