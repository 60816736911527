.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  margin: 1em 0;
  border-radius: 10px;
  padding: 0 2em;
}

.center {
  text-align: right;
  width: 34%;
  font-size: 2em;
}

.center h3 {
  color: var(--text-color);
}

.select {
  width: 200px;
  height: 42px;
  padding-top: 5px;
  text-align: left;
  border: 1px solid var(--text-color);
  border-radius: 10px;
}

.grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.__row {
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.__app__btn {
  position: relative;
  min-width: 370px;
  font-family: var(--title-font);
  font-size: 1em !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding-right: 10px !important;
  border-radius: unset !important;
}

.__content__quiz {
  position: relative;
  top: -2px;
  margin-left: 20px;
}

.__check {
  color: #fff;
  border-radius: 30px !important;
}

@media only screen and (max-width: 767px) {

  .header {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    margin: 1em 0;
    border-radius: 10px;
    padding: 0 2em;
    margin-bottom: 70px;
  }

  .select {
    width: 100%;
    min-width: 320px;
    height: 42px;
    padding-top: 5px;
    text-align: left;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .formElement {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

/* New Css Styles */

.main {
  margin-top: 10px;
  margin-bottom: 80px;
}

.quizBox {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
  margin-top: 40px;
}