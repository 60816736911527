.register__teacher {
  width: 100%;
  text-align: left;
}

.step {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
}

/* JOFREY STYLE */

.accountFormBox {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  height: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.formBox {
  width: 100%;
  max-width: 100%;
  margin: 40px auto;
  border-radius: 5px;
  background-color: #fafafa;
  height: auto;
  padding: 30px 80px 120px 80px;
  display: flex;
  flex-direction: column;
}

.formElementBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.stepTitleBox {
  text-align: left;
  align-items: flex-start;
  align-content: start;
}

.boxTitle {
  font-size: 28px;
  font-weight: 600;
  color: #707070;
  font-family: "CartoonFont";
  text-align: left;
  text-transform: uppercase;
}

.boxSubtitle {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.boxMessage {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 35px;
}

.selectInput {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #707070;
  font-style: italic !important;
  width: 100%;
}

.placeholderStyle {
  color: #C0C0C0;
  font-size: 16px;
}

.nextButton {
  font-size: 18px;
  padding: 20px;
}

.hideElement {
  display: none;
}

.uploadButtonStyle {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
}

.uploadVignetteButton {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 130px;
}

.startRecordingVideo {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.cardInfoBox {
  background-image: url(assets/images/teacher/card.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 35px;
  margin-top: 15px;
  padding: 0;
}

.cardInfoBoxToDo {
  background-image: url(assets/images/teacher/cardLong.png);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 15px;
  padding: 0;
}

.cardInfoBoxWarning {
  background-image: url(assets/images/teacher/cardAds.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 0px;
  padding: 0;
}

.avatarExampleBox {
  background-image: url(assets/images/app/pictureExample.png);
  background-size: cover;
  height: 120px;
  width: 380px;
  border-radius: 8px;
  margin: 5px 7px 0px 35px;
}

/* Drag & Drop Input CSS */

#formFileUpload {
  height: 425px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#inputFileUpload {
  display: none;
}

#labelFileUpload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #707070;
  background-color: #fff;
}

#labelFileUpload.dragActive {
  background-color: #ffffff;
}

.uploadButton {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.uploadButton:hover {
  text-decoration-line: underline;
}

#dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* Drag & Drop Input CSS */

.uploadImageBox {
  display: flex; 
  flex-direction: column; 
  margin: 0px auto;
}

.uploadImageBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.uploadImageBoxMessage {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.calendarBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
}

.calendarBoxSubtitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin: 30px auto;
  padding-left: 210px;
}

.calendareBoxMessage {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.uploadVideoBoxSubMessage {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 5px;
  color: #707070;
}

.uploadVideoBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 45px;
  margin-left: 35px;
}

.uploadVideoBoxMessage {
  font-size: 14.5px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.uploadVideoBoxSubtitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.addPictureButton {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 20px;
}

.addVideoButton {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 20px;
}

.addVignetteButton {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 30px;
}

.previewBox {
  margin-left: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #707070;
  border-style: dashed;
}

.previewBoxImage {
  max-width: 200px;
  height: auto;
}

.uploadBox {
  display: flex;
  flex-direction: column;
}

.uploadBoxTitle {
  font-size: 20px;
  font-style: italic;
  color: #333;
}

.uploadBoxMessage {
  font-size: 20px;
  font-style: italic;
  color: #CBCACD;
}

.uploadBoxMessageError {
  font-size: 20px;
  font-style: italic;
  color: red;
}

.uploadBoxBorder {
  margin-left: 35px;
  margin-top: 25px;
  border: none;
  border-radius: 15px;
}

.uploadBoxBorderError {
  margin-left: 35px;
  margin-top: 25px;
  border: 1px solid red;
  border-radius: 15px;
}

.exampleBox {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.exampleBoxLong {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 30px;
}

.exampleBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.videoExampleBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 0px;
}

.instructionBox {
  padding-left: 65px;
  padding-right: 60px;
  margin-top: 42px;
}

.instructionBoxLong {
  padding: 60px 40px 10px 65px;
  margin-top: 42px;
}

.checkboxBox {
  display: flex;
  flex-direction: row;
}

.checkboxBoxItem {
  margin-left: 10px;
  color: #707070;
  font-size: 12.5px;
}

.checkboxBoxItemLong {
  margin-left: 5px;
  color: #707070;
  font-size: 12.5px;
}

.checkboxBoxLong {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.customTextarea {
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

/* Placeholder color */
.customTextarea::placeholder {
  color: #C0C0C0;
}

/* Focus color */
.customTextarea:focus {
  outline: none;
  border-color: #707070;
  box-shadow: 0 0 2px green;
}

#videoFileUpload {
  background-image: url(assets/images/app/videoExample.png);
  background-size: cover;
  height: 300px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-left: 35px;
  margin-top: 25px;
}

.vignetteBox {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0px 0px 35px;
  padding: 15px 20px 15px 20px;
  width: 540px;
  align-items: center;
  justify-content: left;
}

.stepsBox {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0px auto;
  width: 100%;
}

.addCertificateDiplomaButton {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 50px;
}

.customButtonStyle {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.customButtonStyle:hover {
  transform: scale(1.03) !important;
}

.customButtonStyle:active {
  transform: scale(0.99) !important;
}

.certificateDiplomaFormBox {
  margin: 0px auto;
  height: auto;
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.pictureBoxRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.picturePreviewBox {
  height: 186px;
  width: 147px;
  border: 1px solid #333;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
}

.titleBox {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 74%;
}

.videoPreviewBox {
  height: 420px;
  width: 560px;
  border-radius: 5px;
  margin-left: 35px;
  margin-top: 25px;
}

.videoForm {
  margin-left: 35px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: auto;
}

.videoFormatTitle {
  font-size: 13px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 10px;
}

.calendarTimezone {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: center;
}

.fillLaterButton {
  height: 50px;
  width: 65%;
  max-width: 40%;
  margin-left: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

/* JOFREY STYLE */

.teacher {
  display: flex;
  align-items: center;
  margin: 0px auto;
}

.teacher .avatar {
  padding-right: var(--mb-4);
}

.header__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.header__text p {
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.header__text p strong,
.text strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.text a {
  color: var(--text-color);
  text-decoration: underline;
}

.content__action {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0px auto;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.field {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.field::placeholder {
  font-style: italic !important;
}

.termes__legals {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.button {
  width: 100%;
  height: 40px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.text__legal {
  text-align: left;
  font-size: var(--small-font-size);
}

@media only screen and (max-width: 680px) {
  .register__teacher {
    padding: 0 3%;
  }

  .teacher .avatar {
    padding-right: 0;
  }

  .teacher .avatar img {
    position: relative;
    width: 30px;
    top: -3px;
    margin: 0;
  }

  .header__text p {
    font-size: var(--normal-font-size);
    margin-top: 3px;
  }

  .title__step {
    font-size: 12px;
    white-space: nowrap;
    display: none;
  }

  .step {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
  }

  .field {
    height: 40px !important;
  }

  .field::placeholder {
    font-size: 14px !important;
  }

  .policy {
    position: relative;
    font-size: 11px !important;
  }

  .policy br {
    display: none;
  }
}

@media only screen and (max-width: 680px) {
  .formBox {
    width: 100%;
    max-width: 100%;
    margin: 40px auto;
    border-radius: 5px;
    background-color: #fafafa;
    height: auto;
    padding: 30px 5px 120px 5px;
    display: flex;
    flex-direction: column;
  }

  .stepTitleBox {
    text-align: left;
    align-items: flex-start;
    align-content: start;
  }

  .boxTitle {
    font-size: 28px;
    font-weight: 600;
    color: #707070;
    font-family: "CartoonFont";
    text-align: left;
    text-transform: uppercase;
  }

  .uploadBoxBorder {
    margin: 20px auto;
    border: none;
    border-radius: 15px;
  }

  #formFileUpload {
    height: 225px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  .cardInfoBox {
    background-image: url(assets/images/teacher/card.svg);
    background-size: cover;
    height: 422px;
    width: 100%;
    margin-left: 0px;
    margin-top: 25px;
    padding: 0;
  }
  
  .exampleBoxTitle {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 0px;
  }
  
  .avatarExampleBox {
    background-image: url(assets/images/app/pictureExample.png);
    background-size: cover;
    height: 120px;
    width: 100%;
    border-radius: 8px;
    margin: 5px 5px 0px 0px;
  }

  .previewBox {
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-radius: 1rem;
    border-color: #707070;
    border-style: dashed;
  }

  .customButtonStyle {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-left: 30px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  }

  .certificateDiplomaFormBox {
    margin: 0px auto;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
 
  .pictureBoxRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .picturePreviewBox {
    height: 186px;
    width: 50%;
    border: 1px solid #333;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    display: flex;
  }
  
  .titleBox {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    margin-top: 20px;
  }

  .videoPreviewBox {
    height: 420px;
    width: auto;
    border-radius: 5px;
    margin-left: 5px;
    margin-top: 25px;
  }

  #videoFileUpload {
    background-image: url(assets/images/app/videoExample.png);
    background-size: contain;
    height: 300px;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 25px auto;
    background-repeat: no-repeat;
  }

  .exampleBoxLong {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .uploadImageBoxTitle {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 5px;
  }
  
  .uploadImageBoxMessage {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .vignetteBox {
    display: flex;
    flex-direction: row;
    border: 1px solid #333;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 20px auto;
    padding: 15px 20px 15px 20px;
    width: auto;
    align-items: center;
    justify-content: left;
  }

  .videoForm {
    margin-left: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: auto;
  }

  .uploadVideoBoxMessage {
    font-size: 14.5px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }
  
  .uploadVideoBoxSubtitle {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 5px;
  }

  .uploadVideoBoxTitle {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-left: 5px;
  }

  .addVideoButton {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 20px;
  }

  .addVignetteButton {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 30px;
  }

  .videoFormatTitle {
    font-size: 13px;
    color: #CBCACD;
    margin-left: 5px;
    margin-top: 10px;
  }

  .calendarBoxTitle {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 5px;
  }

  .calendareBoxMessage {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .calendarBoxSubtitle {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 30px auto;
    padding-left: 5px;
  }

  .calendarTimezone {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
  }

  .fillLaterButton {
    height: 50px;
    width: 150px;
    margin-left: 0px;
  }

  .title {
    font-size: 14px;
  }

  .nextButton {
    font-size: 14px;
    padding: 0px;
  }

}