.flex {
  width: 100%;
  display: flex;
  gap: 1.5em;
}

.left {
  flex: 0.5;
}

.main {
  flex: 1;
}

.right {
  flex: 0.5;
}

.mainBox {
  background-color: #FAFAFA;
  padding-top: 20px;
  padding-left: 0%;
  padding-right: 0%;
  margin-bottom: 80px;
  width: 60%;
  margin: 0px auto;
}

.containerBox {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
}

@media only screen and (max-width: 1050px) {
  .flex {
    width: 100%;
    display: flex;
    gap: 0;
  }

  .left {
    flex: 0;
    display: none;
  }

  .right {
    flex: 0;
    display: none;
  }

  .containerBox {
    height: 100%;
    width: 100%;
    background-color: #FAFAFA;
  }

  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 100%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1051px) and (max-width: 1151px) {
  .flex {
    width: 100%;
    display: flex;
    gap: 0;
  }

  .left {
    flex: 0;
    display: none;
  }

  .right {
    flex: 0;
    display: none;
  }

  .containerBox {
    height: 100%;
    width: 100%;
    background-color: #FAFAFA;
  }

  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 70%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1340px) {
  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 90%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1341px) and (max-width: 1429px) {
  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 85%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1430px) and (max-width: 1479px) {
  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 82%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1480px) and (max-width: 1599px) {
  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 75%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 70%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1850px) {
  .mainBox {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 66%;
    margin: 0px auto;
  }
}