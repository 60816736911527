.header {
  width: 100%;
  height: auto;
  margin-top: var(--mb-1);
  /* padding: 2%; */
  margin-bottom: var(--mb-3);
}

.content,
.__leftt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.__leftt {
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1em;
}

.avatar {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 3px;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.infos h1 {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 1.7em;
}

.__content__lang {
  display: inline-block;
  padding: 5px 20px;
  background: #F8F8F8;
  border-radius: 20px;
}

.__content__lang__row {
  width: 100%;
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.6em;
  white-space: nowrap;
}

.infos__content__top {
  width: 100%;
  display: flex;
  gap: 1em;
  margin-bottom: 2px;
}

.matiers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.3em;
}

.__tag__topic {
  background: #D5D5D5;
  display: flex;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

/*=====  __right ===== */

.__right {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1%;
}

.__right__content {
  text-align: center;
}

.button {
  display: block;
  border: none;
  margin-top: 10px;
  background: var(--first-color);
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}

.editProfileButton {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background: white !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
  font-size: 13px;
  color: var(--first-color);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--first-color) !important;
}

.editProfileButton:hover {
  background: var(--first-color);
  transform: scale(1.03) !important;
  color: white;
}

.sponsorButton {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background: white !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
  font-size: 13px;
  color: var(--first-color);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--first-color) !important;
  margin-left: 10px;
}

.sponsorButton:hover {
  background: var(--first-color);
  transform: scale(1.03) !important;
  color: white;
}

@media only screen and (max-width: 768px) {
  .infos h1 {
    font-size: 1.2em;
  }

  .avatar {
    width: 75px;
    height: 75px;
  }

  .header {
    margin-bottom: var(--mb-3);
  }

  .content {
    flex-direction: column;
  }

  .button {
    font-size: 10px;
  }

  .__leftt {
    width: 100%;
  }

  .__right {
    width: 100%;
  }

  .__content__lang {

    padding: 5px 10px;
    font-size: 12px;
  }

  .row__btn {
    display: flex;
    gap: 0.2em;
  }

  .__tag__topic {
    font-size: 11px;
    padding: 5px 10px;
  }

  .infos__content__footer {
    font-size: 12px;
  }

  .nameContent {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .topButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px;
    padding: 0px;
    position: relative;
    left: -10px;
  }

  .infos__content__top {
    max-width: 300px;
    display: flex;
    gap: 1em;
    margin-bottom: 2px;
    overflow: scroll;
  }

}

.nameContent {
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  text-align: center;
}

.topButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.lang__content span:last-child {
  display: none;
}

.follow img {
  position: relative;
  top: -2px;
  margin-left: 10px;
  cursor: pointer;
}

/* New Css styles */

.headerBox {
  height: 235px;
  min-height: 200px;
  width: 100%;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 38px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
  position: relative;
  top: 20px;
}

.lineSeparator {
  height: 1px;
  width: 92%;
  margin: 25px auto;
  background: #D8D8D8;
  position: relative;
  top: 20px;
}

.detailsBox {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avatarBox {
  height: 100%;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.nameBox {
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
}

.avatarPicture {
  height: 85px;
  width: 85px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.nameDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.nameFollowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.name {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  color: #8AB0B1;
}

.rateLanguages {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.title {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #393E3E;
  margin-top: 5px;
}

.levels {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #7FB1B2;
  margin-bottom: 10px;
}

.levels div:last-child {
  display: none;
}

.newMessageButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.newMessageButton:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1;
  color: #fff;
}

.newProfileButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.newProfileButton:hover {
  transform: scale(1.03) !important;
  background-color: #60CD95;
  color: #fff;
}

.coursesPicesBox {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.coursesBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto !important;
  max-width: 300px !important;
  overflow: scroll;
  scrollbar-width: thin;
}

.courses {
  width: auto;
  height: 35px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-left: 7px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
  gap: 7px;
}

.priceBox {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.priceTitle {
  font-size: 8px;
  font-family: "Poppins";
  font-weight: 600;
  color: #868686;
}

.price {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  color: #7FB1B2;
}

.newInstaButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
}

.newInstaButton:hover {
  transform: scale(1.03) !important;
  background-color: #F56666 !important;
  color: #fff;
}

.newPlanningButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
}

.newPlanningButton:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1 !important;
  color: #fff;
}

.reportButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #F56666 !important;
  font-weight: 600 !important;
  border: 1px solid #F56666 !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
  visibility: hidden;
}

.reportButton:hover {
  transform: scale(1.03) !important;
  background-color: #FFC8BA !important;
  color: #fff;
}

.separator {
  height: 90%;
  width: 1px;
  background: #707070;
  margin-left: 10px;
  margin-right: 0px;
}

.followButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #707070 !important;
  font-weight: 400 !important;
  border: 1px solid #FFF !important;
  box-shadow: 1px 3px 1px -1px #FFF !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFF;
  -moz-box-shadow: 2px 4px 1px -1px #FFF;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
}

.followButton:hover {
  transform: scale(1.03) !important;
  background-color: #FFF !important;
  color: #8AB0B1 !important;
}

.spinLoading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 140px;
  text-align: center;
}

@media only screen and (max-width: 1050px) {
  .headerBox {
    height: auto;
    min-height: auto;
    width: 100%;
    background: #FFF;
    box-shadow: 4px 8px 18px #98989829;
    border: 1px solid #DFDDDD;
    border-radius: 32px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    position: relative;
    top: 20px;
  }

  .lineSeparator {
    height: 1px;
    width: 92%;
    margin: 25px auto;
    background: #D8D8D8;
    position: relative;
    top: 20px;
  }

  .detailsBox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .avatarBox {
    height: 100%;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nameBox {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
  }

  .avatarPicture {
    height: 85px;
    width: 85px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1.5px solid #FFF;
    border-radius: 50%;
  }

  .nameDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .nameFollowBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .buttonsRow {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    padding: 0px;
  }

  .name {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
  }

  .rateLanguages {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .title {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #393E3E;
    margin-top: 5px;
  }

  .levels {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 600;
    color: #7FB1B2;
    margin-bottom: 10px;
  }

  .levels span:last-child {
    display: none;
  }

  .newMessageButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .newMessageButton:hover {
    transform: scale(1.03) !important;
    background-color: #8AB0B1;
    color: #fff;
  }

  .newProfileButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .newProfileButton:hover {
    transform: scale(1.03) !important;
    background-color: #60CD95;
    color: #fff;
  }

  .coursesPicesBox {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  .coursesBox {
    width: 250px !important;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: scroll;
    scrollbar-width: thin;
  }

  .courses {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-left: 7px;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    gap: 7px;
  }

  .courses span {
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .priceBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .priceTitle {
    font-size: 0.65em;
    font-family: "Poppins";
    font-weight: 600;
    color: #868686;
  }

  .price {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 700;
    color: #7FB1B2;
  }

  .newInstaButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 10px;
    cursor: pointer;
  }

  .newInstaButton:hover {
    transform: scale(1.03) !important;
    background-color: #F56666 !important;
    color: #fff;
  }

  .newPlanningButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 10px;
    cursor: pointer;
  }

  .newPlanningButton:hover {
    transform: scale(1.03) !important;
    background-color: #8AB0B1 !important;
    color: #fff;
  }

  .reportButton {
    width: auto !important;
    height: 25px !important;
    display: none !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #F56666 !important;
    font-weight: 600 !important;
    border: 1px solid #F56666 !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 10px;
    cursor: pointer;
    visibility: hidden;
  }

  .reportButton:hover {
    transform: scale(1.03) !important;
    background-color: #FFC8BA !important;
    color: #fff;
  }

  .separator {
    height: 90%;
    width: 1px;
    background: #707070;
    margin-left: 10px;
    margin-right: 0px;
    display: none;
  }

  .followButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #707070 !important;
    font-weight: 400 !important;
    border: 1px solid #8AB0B1 !important;
    box-shadow: 1px 3px 1px -1px #FFF !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFF;
    -moz-box-shadow: 2px 4px 1px -1px #FFF;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .followButton:hover {
    transform: scale(1.03) !important;
    background-color: #FFF !important;
    color: #8AB0B1 !important;
  }

  .spinLoading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 140px;
    text-align: center;
  }
}