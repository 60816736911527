.calendrier {
  width: 100%;
  margin: 3em 0;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.calendar__content {
  width: 100%;
}

/*  */

.calendar {
  height: 600px;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #FAFAFA;
}

.weekly {
  width: 100%;
  height: 100%;
  /*display: flex;
  justify-content: space-between;
  align-items: center;
  */
  padding: 2% 4%;
  margin-bottom: 2em;

  border: 1px solid #ccc;
}

.date {
  width: 100%;
  min-height: 35px;
  border-radius: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  padding-top: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.date:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.date.active {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.creneaux {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.creneau {
  margin-bottom: 0.5em;
}

.range {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.range span {
  margin: auto;
}

.matiere {
  font-size: 1em;
}

.bar {
  margin: 0 0.5em;
}

.angleIcon path {
  fill: #fe772e;
}

.slider>svg path {
  fill: #fe772e;
}

.card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: var(--mb-3);
}

@media only screen and (max-width: 500px) {
  .content__action__reservation {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.myReservation {
  margin-bottom: 1em;
}

.card_ {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}


/* New Css Styles */

.dashboard {
  margin-top: 1em;
}

.dashboardUserBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
}

.dashboardUserMessage {
  font-size: 1.8em;
  color: black;
  margin: 0px auto;
  padding-bottom: 30px;
}

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.titlePictureBox {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #E4F1F2;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.todayBox {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.classeCard {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.classeCardDeleted {
  position: relative;
  width: 100%;
  height: 70px;
  background: #EBEBEB;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.classeCardCoupon {
  position: relative;
  width: 100%;
  height: 70px;
  background: #E7F8F8;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.cancelBox {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
  text-align: center;
}

.historyClasseCard {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
}

.dateBox {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.horizontalLine {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.avatarNameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 250px;
  object-fit: contain;
}

.detailsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.historyDetailsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
  margin-right: 0px;
}

.timeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
  margin-right: 10px;
  width: 25%;
}

.times {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.time {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.timeDescription {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.amountBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  width: auto !important;
}

.priceBox {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
}

.historyPriceBox {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.newButton {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 30px;
  padding-right: 30px;
}

.meetButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.moreOptionsButton {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #000 !important;
  margin-left: 0px;
  border: 1px solid #FFF !important;
}

.meetButton:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.emptyClass {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
}

.historyCalendar {
  height: 650px;
  width: 95%;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #FFF;
  margin: 0px auto;
}

.cardDisplay {
  display: flex;
  flex-direction: column;
}

.cardClassBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.cardClassBoxCoupon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 10px;
  padding-right: 0px;
}

.scheduleButton {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #80b1b2 !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: 1px solid #80b1b2 !important;
  padding-left: 20px;
  padding-right: 20px;
}

.cancelButton {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #ea4f44 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #e62214 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #e62214;
  -moz-box-shadow: 5px 7px 1px -1px #e62214;
  padding-left: 20px;
  padding-right: 20px;
}

.scheduleButton:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.cancelButton:hover {
  transform: scale(1.03) !important;
  background-color: #ea4f44;
  color: #fff;
}

.horizontalLineDeleted {
  height: 1px;
  width: 85%;
  background: #393E3E;
}

@media only screen and (max-width: 1050px) {
  .dashboardUserMessage {
    font-size: 1.2em;
    color: black;
  }

  .headerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .titleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .titlePictureBox {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonBox {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .calendrier {
    width: 100%;
    margin: 2em 0;
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 16px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 60vh !important;
  }

  .newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .calendar {
    height: 60vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 16px !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FAFAFA;
  }

  .historyCalendar {
    height: 73vh !important;
    width: 95% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FFF;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 16px !important;
  }

  .dateBox {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .horizontalLine {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .todayBox {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .emptyClass {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    font-family: Rubik;
    color: #B9B9B9;
    text-align: center;
  }

  .cardDisplay {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .singleCourse {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
  }

  .classeCard {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .classeCardDeleted {
    position: relative;
    width: 100%;
    height: 220px;
    background: #EBEBEB;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .classeCardCoupon {
    position: relative;
    width: 100%;
    height: 220px;
    background: #E7F8F8;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .cancelBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
    position: absolute;
    top: 40px;
    opacity: 1;
    text-align: center;
  }

  .historyClasseCard {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
  }

  .cardClassBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .cardClassBoxCoupon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .avatarNameBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 90%;
    object-fit: contain;
  }

  .detailsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .historyDetailsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .timeBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    width: auto !important;
  }

  .times {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .time {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .timeDescription {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .amountBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    font-size: 0.7em;
    margin-top: 5px;
    width: auto !important;
  }

  .priceBox {
    width: auto !important;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .historyPriceBox {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .meetButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .moreOptionsButton {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #000 !important;
    margin-left: 0px;
    border: 1px solid #FFF !important;
    margin-top: 10px;
    margin-left: 5px;
  }

  .meetButton:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .scheduleButton {
    width: auto !important;
    height: 35px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #80b1b2 !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: 1px solid #80b1b2 !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .cancelButton {
    width: auto !important;
    height: 30px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #ea4f44 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #e62214 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #e62214;
    -moz-box-shadow: 5px 7px 1px -1px #e62214;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .horizontalLineDeleted {
    height: 150px;
    width: 1px;
    background: #393E3E;
  }
}