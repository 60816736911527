.cours {
  position: relative;
  margin: 4em 0;
}

.appercue__continute {
  width: 100%;
  height: 600px;
  margin-top: var(--mb-1);
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.full_appercue__continute {
  width: 70%;
  margin: var(--mb-1) auto;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  position: fixed;
  bottom: 0px;
  top: 12%;
  left: 0%;
  right: 0%;
}

.__add__input:hover {
  color: #fff !important;
}

.appercue__continute h3 {
  font-size: 18px;
  margin-bottom: var(--mb-2);
}

.section__content,
.__big__title__content {
  width: fit-content;
  padding-right: 3%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.appercue__continute__content {
  margin-left: 5%;
  font-size: 0.8em;
}

.__big__title {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.section__content {
  background: var(--first-color);
  width: 30%;
  padding-right: 3%;
}

.section__content h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.__content__matiere {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 16px;
  margin-bottom: var(--mb-2);
}

.header {
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.menu {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .appercue__continute {
    min-width: initial;
    padding: 1.5em 1em;
  }
}

.spaceButton {
  margin-top: 0px;
}

@media only screen and (max-width: 767px) {

  .logo {
    display: none;
  }

  .full_appercue__continute {
    width: 98%;
    margin: 40px auto;
    overflow-y: scroll;
    background: #fafafa;
    border-radius: 10px;
    padding: 0.5em 1em;
    position: fixed;
    bottom: 0px;
    top: 12%;
    left: 0%;
    right: 0%;
    font-size: 12px;
  }

  .__big__title {
    padding-left: 1em;
    font-size: 13px !important;
    color: #fff;
    line-height: 45px;
  }

  .__content__matiere {
    text-transform: uppercase;
    font-family: var(--title-font);
    font-size: 12px;
    margin-bottom: var(--mb-2);
  }

  .appercue__continute h3 {
    font-size: 14px;
    margin-bottom: var(--mb-2);
  }

  .spaceButton {
    margin-top: 20px;
  }

  .menu {
    width: auto;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .section__content {
    background: var(--first-color);
    width: auto;
    padding-right: 0%;
    padding-top: 0em;
  }

  .section__content h4 {
    color: #fff;
    line-height: 45px;
    padding-left: 1em;
  }

}

.newButton {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px !important;
  padding-right: 20px !important;
}