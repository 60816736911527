.__content__tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px auto;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

.fullHeader {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.tabs {
  width: 85px;
  height: 85px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.fullTabs {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs:hover {
  background: var(--first-color);
}

.mobileTabs {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 0.3em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.chapitre__content {
  width: 100%;
  background: #fafafa;
  padding: 3em;
  min-height: 80vh;
  margin-bottom: 5em;
}

.subtitle {
  text-transform: uppercase;
  margin-bottom: var();
}

.row {
  width: 100%;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  margin-top: 2em;
}

.__links {
  color: var(--text-color);
}

.tabButtons {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
}

.fullscreenButton {
  width: 65px;
  margin-right: 12%;
}

@media only screen and (max-width: 390px) {
  .tabs {
    width: 90px;
  }
}

.__back {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.__back span {
  font-family: var(--title-font);
  font-size: 1.1em;
  color: #333;
  text-transform: uppercase;
}

.__back span:hover {
  color: var(--first-color);
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .__content__tabs {
    flex-direction: column-reverse;
    width: 100%;
  }

  .__back {
    text-align: center;
  }
}

.fullscreenButton span {
  font-size: 12px;
  margin-left: 5px;
  margin-top: 2px;
}

@media only screen and (max-width: 767px) {

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .fullscreenButton {
    width: auto;
    margin-right: 0%;
    margin-bottom: 20px;
  }

  .fullscreenButton span {
    font-size: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }

  .newButton {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    font-size: 13px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .tab {
    width: 100px !important;
    height: 35px !important;
    margin: 5px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    background: #FAFAFA;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    opacity: 1;
    color: #868686;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
  }

  .tab:hover {
    background: var(--first-color);
    color: #FFF;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .tabActive {
    background: #FFFFFF;
    box-shadow: 4px 8px 16px #7FB1B234;
    border: 1px solid #7FB1B2;
    opacity: 1;
    color: #7FB1B2;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

}

.mobileMenu {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.mobileMenuFullscreen {
  width: 100% !important;
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .fullTabs {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    margin: 0 0.9em;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
}

/* New Css Styles */

.tab {
  width: 180px;
  height: 50px;
  margin: 0 var(--mb-1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  background: #FAFAFA;
  border: 1px solid #B9B9B9;
  border-radius: 24px;
  opacity: 1;
  color: #868686;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.tab:hover {
  background: var(--first-color);
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.tabActive {
  background: #FFFFFF;
  box-shadow: 4px 8px 16px #7FB1B234;
  border: 1px solid #7FB1B2;
  opacity: 1;
  color: #7FB1B2;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.newButton {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.newBackButton {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #000 !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: capitalize !important;
}