.main {
  width: 100%;
  min-height: 800px;
  background: #FAFAFA;
  overflow-y: hidden;
  scrollbar-width: 2px;
  border-radius: 24px;
  margin-top: 0em;
  margin-bottom: 200px;
  padding-top: 3em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  border: 1px solid #707070;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  z-index: 1;
}

::-webkit-scrollbar {
  height: 4px;
  margin-left: 2.5em;
}

.title {
  font-family: "DM Serif Display";
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  margin-top: 10px;
  z-index: 1;
}

.actorBox {
  text-align: center;
  margin-bottom: 20px;
  border: 1.5px solid #8AB0B1;
  border-radius: 28px;
  background-color: #FFF;
  height: 100px;
  width: 180px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}

.actorBox:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
  color: #8AB0B1;
}

.actorBoxTitle {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
}

.actorBoxTitle:hover {
  color: #8AB0B1;
}

.question {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 15px;
}

.answer {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-right: 40px;
}

.likeQuestion {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-right: 40px;
}

.collaps {
  width: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.__header__panel {
  background: #fff;
  border-radius: 15px;
  margin-bottom: 2em;
  min-height: 70px;
}

.panel {
  height: 100%;
  min-height: 100px;

  background: #fff;
  padding: 1em;
  border-radius: 15px;
}


.__header__panel h3 {
  font-size: 1.3em;
  font-weight: 500;
  color: var(--texte-font);
}

.__header__panel * div * svg {
  display: none;
}