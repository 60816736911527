.search {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4px;
  height: 42px;
  border: 1px solid #707070;
  margin-top: var(--mb-3);
  border-radius: 15px;
  /* position: sticky;
  top: 25px;
  z-index: 77;
  background: #FFF; */
}

.input {
  color: #707070;
}

.input ::placeholder {
  font-style: italic;
  font-size: 14px;
}

.content__conversation {
  margin-top: 2em;
  height: 100%;
  overflow-y: scroll;
}

.conversation__list {
  width: 100%;
  height: 65px;
  display: flex;
  gap: 1em;
  align-items: center;
  padding: 0 10%;
  cursor: pointer;
  transition: background-color 2s ease-out;
  margin-bottom: 0.5em;
}

/* .content__conversation:hover {
  background-color: #b0e0e1b5;
} */

.username {
  width: 100%;
  color: #333333;
  font-weight: 600;
}

.name__user>span {
  font-weight: 500;
  text-transform: uppercase;
  color: #333333;
  font-size: 1.1em;
}

.apercu__msg {
  color: #868686;
}

.list {
  transition: all 0.3s ease;
}

.list:hover {
  background: #80b1b26f;
}

.active {
  background: #F0FAFA;
  border-left: 5px solid #7FB1B2;
}

.unread {
  background: rgb(127 177 178);
}

.unreadCount {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  background: #7FB1B2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  margin-right: 5px;
}

.avatar {
  background: #FFF2E0;
  border-radius: 50%;
  border: 1.5px solid #FFF;
  box-shadow: 0px 3px 6px #00000029;
}

@media only screen and (max-width: 1050px) {
  .input ::placeholder {
    font-style: italic;
    font-size: 0.75em;
  }

  .input ::placeholder {
    font-style: italic;
    font-size: 0.75em;
  }

  .username {
    width: 100%;
    font-size: 0.8em;
    color: #333333;
    font-weight: 600;
  }

  .name__user>span {
    font-weight: 500;
    text-transform: uppercase;
    color: #333333;
    font-size: 0.8em;
  }

  .apercu__msg {
    font-size: 0.7em;
    color: #868686;
  }

  .unreadCount {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    text-align: center;
    background: #7FB1B2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    margin-right: 5px;
    font-size: 0.7em;
    position: relative;
    top: 3.5px;
  }
}