.lineBox {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  border-radius: 15px;
  padding: 1%;
  align-items: start;
  justify-content: start;
}

.card {
  height: auto;
  width: 265px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 60px;
  padding-right: 40px;
  padding-bottom: 15px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.cardSingle {
  position: relative;
  width: 32% !important;
  background: #fafafa;
  margin-bottom: var(--mb-2);
  -webkit-box-shadow: 2px 3px 0px 2px #cfd0d2;
  box-shadow: 3px 4px 0px 3px #cfd0d2;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 40px;
  padding-bottom: 0px;
  cursor: pointer;
}

.cardSingle:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardSingle::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.dashedBox {
  padding: 0px;
  background: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  color: #5C5858;
}

.rateBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rateBoxSpan {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
}

.rateBoxStar {
  position: relative;
  top: -1px;
  margin-left: 2.5px;
}

.rateBoxStarTooltip {
  position: relative;
  top: -2px;
  margin-left: 5px;
}

.defaultButton {
  width: 160px !important;
  position: relative;
  bottom: 12.5px;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  font-size: 14px;
  margin-top: 25px;
}

.defaultButton:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.defaultButton:active {
  transform: scale(0.99) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.studentbox {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.avatarBox {
  height: 60px;
  width: 60px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
}

.detailsAvatarBox {
  height: 70px;
  width: 70px;
  border: 1px solid #FFF;
  border-radius: 50%;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox {
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D5D5D5;
  border-radius: 24px;
  margin-top: 30px;
  position: relative;
  bottom: 12.5px;
  width: 30%;
  padding-right: 10px;
}

.placeholderStyle {
  font-size: 12px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 400;
  opacity: 0.3;
}

.placeholderStyleSelect {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.searchIconBox {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #7FB1B2;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterMainBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.selectInput {
  color: #707070;
  border-radius: 24px;
  border: 1px solid #E3E2E2;
  font-style: normal !important;
  width: 15%;
}

.studentName {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  margin-top: 10px;
}

.levelBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EBEAEA;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  border-radius: 8px;
  width: 100px;
  margin-top: 5px;
}

.studentAvgBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  color: #707070;
  margin-top: 5px;
}

.studentAvg {
  font-size: 13px;
  font-weight: 400;
  color: #707070;
  margin-left: 5px;
}

.tooltipBox {
  display: flex;
  flex-direction: column;
  width: 140px;
  background: #FFF;
  padding: 10px;
}

.emptyData {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  text-align: center;
}

.dashboard {
  margin-top: 1em;
}

.dashboardUserBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
}

.dashboardUserMessage {
  font-size: 1.8em;
  color: black;
  margin: 0px auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 1050px) {

  .dashboardUserMessage {
    font-size: 1.2em;
    color: black;
  }

  .lineBox {
    height: auto;
    width: 100% !important;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    border-radius: 15px;
    padding: 0%;
    align-items: start;
    justify-content: start;
  }

  .card {
    height: 170px;
    width: 100%;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .cardSingle {
    position: relative;
    width: 32% !important;
    background: #fafafa;
    margin-bottom: var(--mb-2);
    -webkit-box-shadow: 2px 3px 0px 2px #cfd0d2;
    box-shadow: 3px 4px 0px 3px #cfd0d2;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 40px;
    padding-bottom: 0px;
    cursor: pointer;
  }

  .cardSingle:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 70px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .cardSingle::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 50px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .dashedBox {
    padding: 0px;
    background: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    color: #5C5858;
  }

  .rateBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .rateBoxSpan {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
  }

  .rateBoxStar {
    position: relative;
    top: 0px;
    margin-left: 2.5px;
  }

  .rateBoxStarTooltip {
    position: relative;
    top: -2px;
    margin-left: 5px;
  }

  .defaultButton {
    height: 35px !important;
    width: auto !important;
    position: relative !important;
    bottom: 12px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    box-shadow: 1px 3px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 1px 3px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 1px 3px 1px -2px rgba(90, 132, 132, 1);
    font-size: 0.65em !important;
    margin-top: 10px !important;
  }

  .defaultButton:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .defaultButton:active {
    transform: scale(0.99) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .studentbox {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    width: 100% !important;
  }

  .titleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .avatarBox {
    height: 30px;
    width: 30px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
  }

  .detailsAvatarBox {
    height: 40px;
    width: 40px;
    border: 1px solid #FFF;
    border-radius: 50%;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchBox {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D5D5D5;
    border-radius: 24px;
    margin-top: 30px;
    position: relative;
    bottom: 12.5px;
    width: 52%;
    padding-right: 5px;
  }

  .placeholderStyle {
    font-size: 0.75em;
    color: #000000;
    font-family: "Poppins";
    font-weight: 400;
    opacity: 0.3;
  }

  .placeholderStyleSelect {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .searchIconBox {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #7FB1B2;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filterMainBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100% !important;
  }

  .selectInput {
    color: #707070;
    border-radius: 24px;
    border: 1px solid #E3E2E2;
    font-style: normal !important;
    width: 35%;
    height: 30px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .studentName {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: 500;
    color: var(--title-color);
    margin-top: 5px;
  }

  .levelBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBEAEA;
    padding-top: 2px;
    padding-bottom: 2.5px;
    border-radius: 5px;
    width: 70px;
    margin-top: 5px;
    font-size: 0.7em;
    font-weight: 400;
  }

  .studentAvgBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.7em;
    font-weight: 500;
    font-family: "Poppins";
    color: #707070;
    margin-top: 0px;
  }

  .studentAvg {
    font-size: 0.8em;
    font-weight: 500;
    font-family: "Poppins";
    color: #707070;
    margin-left: 5px;
    position: relative;
    top: 1px;
  }

  .tooltipBox {
    display: flex;
    flex-direction: column;
    width: 140px;
    background: #FFF;
    padding: 10px;
  }

  .emptyData {
    height: 480px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    text-align: center;
  }
}