.modal_container {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  transition: visibility 0.4s;
  visibility: hidden;
  z-index: 9;
}

.modal_container.active {
  visibility: visible;
}

.overlay {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #333333d3;
  transition: opacity 0.4s 0.2s ease-out;
}

.modal_container.active .overlay {
  opacity: 1;
  transition: opacity 0.4s ease-out;
}

.__header {
  text-align: center;
  color: #fff;
  font-size: 13px;
}

.__header h2 {
  color: #fff;
}

.modal {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0;
  width: 95%;
  max-width: 900px;
  min-width: 300px;
  height: 550px;
  padding: 30px;
  background: #7eaeaf;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 50px));
  transition: opacity 0.4s ease-out,
    transform 0.4s ease-out;
  overflow: hidden;
}

.modal_container.active .modal {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s 0.2s ease-out,
    transform 0.4s 0.2s ease-out;
}

.close_modal {
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}