.field {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
}

.field::placeholder {
    font-style: italic !important;
}

.nextButton {
    font-size: 18px;
    padding: 20px;
}

@media only screen and (max-width: 767px) {
    .field {
        height: 40px !important;
        border-radius: 10px !important;
        border: 1px solid var(--text-color) !important;
        font-style: italic !important;
        color: #707070;
        font-size: 12px;
    }

    .field::placeholder {
        font-size: 12px !important;
    }

    .nextButton {
        font-size: 12px;
        padding: 0px;
    }
}