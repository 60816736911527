.title {
  margin-bottom: var(--mb-2);
  cursor: pointer;
}

.titleLogin {
  color: var(--first-color);
}

.separator {
  position: relative;
  display: block;
  width: 1px;
  height: 40px;
  background: var(--text-color);
  top: 2px;
}

.info {
  text-align: center;
  margin: var(--mb-1) 0;
}

.info a {
  text-decoration: underline;
  color: var(--text-color);
}

.info strong {
  font-weight: 500;
  font-size: 14px;
}

.title:hover {
  color: var(--first-color);
}

.login {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
}

.content__action {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100% !important;
  max-width: 400px !important;
  margin: 10px auto !important;
}

.field {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.field::placeholder {
  font-style: italic !important;
}

.submit {
  width: 100%;
  height: 40px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 1em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

/*  */

@media only screen and (max-width: 500px) {
  .separator {
    height: 25px;
  }

  .field {
    height: 40px !important;
  }

  .submit {
    height: 35px !important;
    font-size: 0.9em !important;
  }

  .field::placeholder {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {

  .title {
    font-size: 1.45em;
  }

}

.forgotPasswordButton {
  font-size: 18px;
  color: #333;
}

.forgotPasswordButton:hover {
  color: rgba(90, 132, 132, 1);
}


/* New CSS Style */

.mainContainer {
  position: relative;
  top: 0px;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 2%;
}

.loginBox {
  width: auto;
  height: auto;
  background: #FAFAFA;
  box-shadow: 4px 8px 16px #80808029;
  border: 1px solid #BFBCBC;
  border-radius: 42px;
  opacity: 1;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
}

.avatarBox {
  margin-top: 25px;
  height: 100px;
  width: 100px;
  background-color: #ffedd5;
  border: 2px solid #FFF;
  border-radius: 50%;
  box-shadow: 4px 8px 16px #80808029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infos {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.infos a {
  text-decoration: underline;
  color: var(--text-color);
}

.infos strong {
  font-weight: 500;
  font-size: 14px;
}

.actionTitle {
  font-size: 30px;
  font-family: rubik;
  font-weight: 500;
  color: #8AB0B1;
  margin-top: 5px;
}

.arrowDown {
  position: relative;
  left: -10%;
  top: -20px;
}

.formContainer {
  width: 100% !important;
  max-width: 740px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  top: -15px;
}

.inoutField {
  height: 60px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.inoutField::placeholder {
  font-style: italic !important;
}

.submitButtonBox {
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButton {
  width: 264px !important;
  height: 60px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 18px !important;
  text-transform: none;
  text-align: center;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 0px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.newAccountBox {
  width: 950px;
  height: auto;
  background-color: #edfff6;
  border: 1px solid #4BB980;
  border-radius: 42px;
  opacity: 1;
  margin: 50px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 2;
}

.newAccountButton {
  width: 290px !important;
  height: 56px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 18px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  padding-top: 0px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
  -moz-box-shadow: 5px 7px 1px -1px #4BB980;
}

.noAccountMessage {
  font-size: 22px;
  font-family: rubik;
  font-weight: 500;
  color: #4BB980;
  margin-left: 15px;
}

.arrowRight {
  position: relative;
  top: 10px;
}

.lines {
  position: absolute;
  left: 20px;
  top: -7%;
  z-index: -1;
}

.ellipseOne {
  position: absolute;
  top: -30px;
  right: 2%;
  z-index: -1;
}

.ellipseTwo {
  position: absolute;
  top: 20%;
  left: 20px;
  z-index: -1;
}

.ellipseThree {
  position: absolute;
  top: 220px;
  right: 4%;
  z-index: -1;
}

.ellipseFour {
  position: absolute;
  top: 77%;
  right: 8%;
  z-index: -1;
}

.ellipseFive {
  position: absolute;
  top: 85%;
  left: 7%;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .mainContainer {
    padding: 0 4%;
    margin: 20px auto;
  }

  .loginBox {
    width: 95%;
    height: auto;
    background: #FAFAFA;
    box-shadow: 4px 8px 16px #80808029;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    z-index: 2;
  }

  .avatarBox {
    margin-top: 0px;
  }

  .infos {
    text-align: center;
    margin-top: 20px;
    font-size: 0.85em;
  }

  .infos a {
    text-decoration: underline;
    color: var(--text-color);
  }

  .infos strong {
    font-weight: 500;
    font-size: 0.85em;
  }

  .actionTitle {
    font-size: 14px;
    font-family: rubik;
    font-weight: 500;
    color: #8AB0B1;
    margin-top: 5px;
  }

  .formContainer {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    position: relative;
    top: 20px;
  }

  .inoutField {
    height: 38px !important;
    border-radius: 8px !important;
    border: 1px solid var(--text-color) !important;
    font-size: 12px !important;
  }

  .inoutField::placeholder {
    font-style: italic !important;
    font-size: 12px !important;
  }

  .submitButton {
    width: 160px !important;
    height: 35px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 12px !important;
    text-transform: none;
    text-align: center;
    background: var(--first-color) !important;
    color: #fff !important;
    padding-top: 0px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  }

  .newAccountBox {
    width: 80%;
    height: auto;
    background-color: #edfff6;
    border: 1px solid #4BB980;
    border-radius: 24px;
    opacity: 1;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    z-index: 2;
  }

  .newAccountButton {
    width: 150px !important;
    height: 35px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 12px !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    padding-top: 0px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
    -moz-box-shadow: 5px 7px 1px -1px #4BB980;
  }

  .noAccountMessage {
    font-size: 14px;
    font-family: rubik;
    font-weight: 500;
    color: #4BB980;
    margin-left: 15px;
  }

  .albertHead {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}