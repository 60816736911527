.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.testimoniesBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTestimonies {
    position: relative;
    left: -15%;
    width: 35px;
    bottom: 60px;
    z-index: 0;
}

.subtitle {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: Poppins;
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
}

.boxTitle {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 20px;
    color: rgb(8, 7, 7);
    line-height: 30px;
    margin-bottom: 5px;
}

.boxMessage {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(57, 62, 62);
    line-height: 20px;
}

.squareBoxOne {
    height: 84px;
    width: 84px;
    background: #FAE4C6;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.squareBoxTwo {
    height: 84px;
    width: 84px;
    background: #DBE2DE;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.squareBoxThree {
    height: 84px;
    width: 84px;
    background: #FFC8BB;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.animatedBoxes {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 680px) {

    .testimoniesBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 100px auto;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTestimonies {
        position: relative;
        left: -23%;
        width: 20px;
        bottom: 30px;
        z-index: 0;
    }

    .subtitle {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
    }

    .boxTitle {
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 15px;
        color: rgb(8, 7, 7);
        line-height: 20px;
        margin-bottom: 5px;
    }

    .boxMessage {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 12px;
        color: rgb(57, 62, 62);
        line-height: 16px;
    }

    .squareBoxOne {
        height: 45px;
        width: 45px;
        background: #FAE4C6;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
    }

    .squareBoxTwo {
        height: 45px;
        width: 45px;
        background: #DBE2DE;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
    }

    .squareBoxThree {
        height: 45px;
        width: 45px;
        background: #FFC8BB;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
    }

    .animatedBoxes {
        display: flex;
        flex-direction: column;
        margin: 20px auto;
        align-items: center;
        justify-content: center;
    }

}