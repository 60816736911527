.card {
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.card h3 {
  color: var(--title-color);
  text-transform: none;
}

.description {
  margin-top: var(--mb-2);
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.footer button {
  border: none;
  margin-top: 10px;
  background: var(--first-color);
  padding: 6px 40px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}

.aboutBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1050px) {
  .description p {
    font-size: 0.75em !important;
  }

  .aboutBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .card h3 {
    color: var(--title-color);
    text-transform: none;
    font-size: 0.75em !important;
  }

  .teacherName {
    color: var(--title-color);
    text-transform: capitalize;
    font-size: 1.2em !important;
  }
}