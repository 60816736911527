@import url(https://fonts.googleapis.com/css?family=Handlee);

.__contact {
  margin: 5em 2em;
}

.main__contact {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  margin-top: 1.5em;
  padding: 0 4em;
}

.main__contact_mobile {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  margin-top: 1.5em;
  padding: 0;
}

.__main_left,
.__right_main {
  width: 100%;

}

.__main_left {
  background: #FAFAFA;
  border-radius: 10px;
  padding: 2em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
}

/* Paper */
.paper {
  position: relative;
  width: 100%;
  min-width: 400px;
  height: 400px;
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
  margin: 1.5em 0;
  overflow: hidden;
}

.paper_mobile {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 400px;
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
  margin: 1.5em 0;
  overflow: hidden;
}

.paper:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  border-right: 3px solid #D44147;
  box-sizing: border-box;
}

.paper_mobile:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  border-right: 3px solid #D44147;
  box-sizing: border-box;
}

.paper_content {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 30px;
  left: 60px;
  background: linear-gradient(transparent, transparent 28px, #91D1D3 28px);
  background-size: 30px 30px;
}

.paper_content textarea {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  line-height: 30px;
  padding: 0 10px;
  border: 0;
  outline: 0;
  background: transparent;
  color: mediumblue;
  font-family: 'Handlee', cursive;
  font-weight: bold;
  font-size: 18px;
  box-sizing: border-box;
  z-index: 1;
}

.field {
  width: 95%;
  height: 40px;
  border: 1px solid var(--text-color);
  border-radius: 15px;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.__main_left label {
  font-size: 1.1em;
  margin-left: 5px;

}

.field input {
  width: 95%;
  height: 80%;
  border: none;
  outline: none;
  background: transparent;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 0.95em !important;
}

.__submit {
  margin-top: 3em;
}