.card {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #CBCACD;
  border-radius: 5px;
  /* margin-left: auto;
  margin-right: auto; */
  cursor: pointer;
  margin-top: var(--mb-3);
}



.card p {
  margin: auto 0;
  padding-left: var(--mb-3);
  font-size: 1.2em;
}

/* input[type=checkbox]+label {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type=checkbox]+label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 20px;
  border-bottom: 5px solid var(--text-color);
  border-left: 5px solid var(--text-color);
  top: 25%;
  left: 45%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

input[type=checkbox]:checked+label {
  border: 1px solid var(--text-color);
}

input[type=checkbox]:checked+label:after {
  opacity: 1;
  width: 35px;
}

.chk {
  display: none;
} */