.pageNumbers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--mb-6);
  margin-bottom: var(--mb-6);
  gap: 1em;
}

.pageNumbers li {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--first-color);
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.pageNumbers li button {
  background: unset;
  border: unset;
  outline: none;
  color: var(--first-color);
  cursor: pointer;
}

.pageNumbers li:hover button {
  color: #fff !important;
}

.pageNumbers li:hover {
  background: var(--first-color);
  color: #fff !important;
}

.active {
  background: var(--first-color);
  /* border: 2px solid var(--first-color); */
  color: #fff;
}

/* New Css Styles */

.currentPagebox {
  width: 60px;
  height: 30px;
  background: #FFF;
  border: 0.5px solid #707070;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.newButton {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
  -moz-box-shadow: 2px 4px 1px -1px #5a8484;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

@media only screen and (max-width: 1050px) {
  .currentPagebox {
    width: 30px;
    height: 20px;
    background: #FFF;
    border: 0.5px solid #707070;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .newButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}