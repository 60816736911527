.dashboard {
  margin-top: 1em;
}

.add-primary-btn {
  min-height: 150px;
  min-width: 100%;
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5em;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.__flex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.justify__center {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.main .annonces__btn {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin: 3.5em 0;
}

.drawer {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content {
  width: 100%;
}

.drawer__content * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.field {
  height: 45px;
  border: 1px solid var(--text-color);
  padding: 5px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.drawer___header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.drawer___header h3 {
  font-family: var(--title-font);
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

.back {
  position: absolute;
  left: 0;
  top: 30px;
  left: -10%;
  cursor: pointer;
}

/* Modal */

.__success__modal_container {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__card {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal__card .modal__card__title {
  font-family: var(--title-font);
  font-size: 3em;
  text-transform: uppercase;
}

.__matier {
  width: 200px;
  height: 40px;
  margin: var(--mb-3) 0;
  border-radius: 10px;
  background: #d5d5d5;
  font-size: 0.8rem;
}

.__icon_matiere {
  position: relative;
  top: 2px;
  left: -4px;
}

.__modal__img__success {
  margin-bottom: var(--mb-4);
}

.__code__access__title {
  text-transform: uppercase;
  font-size: 1em;
  color: #707070;
  margin-bottom: 10px;
  font-weight: 500;
}

.__code {
  width: 250px;
  height: 45px;
  border: 1px dashed #707070;
  display: flex;
  border-radius: 50px;
  color: #707070;
  margin-bottom: var(--mb-6);
}

.__code span {
  margin: auto;
  font-size: 1.5em;
  font-weight: 600;
}

.modal__card button:last-child {
  margin-left: 10px;
}

.__btn {
  max-width: 200px;
  min-width: 200px;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.dashboardUserBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
}

.dashboardUserMessage {
  font-size: 1.8em;
  color: black;
  margin: 0px auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {

  .dashboardUserMessage {
    font-size: 1.2em;
    color: black;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .center {
    width: 100%;
    flex: 2;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }

  .filed__search {
    width: 65%;
    height: 40px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
  }

  .select {
    height: 40px;
    width: 160px;
    border-radius: 10px;
    border: 1px solid var(--text-color);
  }

  .__submit__hidden {
    position: relative !important;
    top: 0px !important;
    margin: 0px auto !important;
  }

  .__reset__btn {
    position: relative !important;
    right: 0% !important;
    bottom: 0% !important;
    top: 10px !important;
  }

  .modal__card .modal__card__title {
    font-size: 2em;
  }

  .__btn {
    max-width: 150px;
    min-width: 150px;
  }

}
