.drawer {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content {
  width: 100%;
}

.drawer__content * label {
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;

  font-size: 1em;
  text-align: left;
}

.field {
  width: 42%;
  height: 45px;
  border: 1px solid var(--text-color);
  padding: 5px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.fieldSelect {
  width: 42%;
  height: 45px;
  border: 1px solid var(--text-color);
  padding: 5px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.drawer___header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.drawer___header h3 {
  font-family: var(--title-font);
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

.back {
  position: absolute;
  left: 0;
  top: 30px;
  left: -10%;
  cursor: pointer;
}

.fieldBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.newButton {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 30px;
  padding-right: 30px;
}

.selectPlaceholder {
  font-size: 14px !important;
}

@media only screen and (max-width: 1050px) {
  .fieldBox {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    text-align: center;
    width: 95% !important;
    margin-bottom: 1em;
    margin-left: 10px;
    margin-right: 10px;
  }

  .field {
    width: 52% !important;
    height: 40px;
    border: 1px solid var(--text-color);
    padding: 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .fieldSelect {
    width: 52% !important;
    height: 40px;
    border: 1px solid var(--text-color);
    padding: 2.5px 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 0.8em !important;
    margin-right: 0px;
  }

  .drawer___header {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--mb-6);
  }

  .drawer___header h3 {
    font-family: var(--title-font);
    font-size: 1.5em;
    text-transform: uppercase;
  }

  .drawer__content * label {
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    color: #707070;
    font-size: 0.8em !important;
    text-align: left;
  }

  .fieldInput::placeholder {
    font-size: 0.8em !important;
  }

  .newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .selectPlaceholder {
    font-size: 0.8em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }

  .appSelect::placeholder {
    font-size: 0.8em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
}