.card {
  width: 50%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #FAFAFA;
  border-radius: 10px;
}

.__matier {
  width: 230px;
  height: 40px;
  margin: var(--mb-2) 0 var(--mb-4) 0;
  border-radius: 10px;
  background: #D5D5D5;
}

.title__cours h3 {
  font-size: 4em;
  font-family: var(--title-font);
  text-transform: uppercase;
}

.title__cours {
  text-align: center;
}

.title__cours span {
  text-align: center;
  font-weight: 500;
}

.quizz {
  color: var(--first-color);
  font-size: 1.1em;
  font-weight: 500;
  text-transform: uppercase;
}

.quizz span {
  cursor: pointer;
  transition: all 0.3s;
}

.quizz span:hover {
  color: var(--first-color-alt);
}

.__btn {
  max-width: 230px !important;
  min-width: 230px !important;
  font-size: 10px !important;
  margin-left: var(--mb-3);
}