.content {
  height: 100%;
  width: 100%;
}

.__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 -10px;
}

.grid {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
  }
}
