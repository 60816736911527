.level {
  width: 100%;
  background: #FAFAFA;
  margin-bottom: 5em;
}

.header {
  margin-top: 2em;
  margin-bottom: 2em;
}

.level__content {
  padding: 3em;
}

.title__content h2 {
  text-align: center;
  font-size: 1.7em;
  color: var(--text-color);
}

.row {
  margin-top: 3em;
}

.card {
  width: 100%;
  max-width: 330px;
  height: 290px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition: all 0.3s;
  cursor: pointer;
  background: #fff;
}

.card h3 span {
  font-family: "CartoonFont";
  display: block;
  font-size: 2em;
  line-height: 50px;
  font-weight: 500;
  color: #CBCBCB;
  text-transform: uppercase;
}

.card:hover {
  border: 4px solid var(--first-color);
}