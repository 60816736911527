.register {
  margin-top: 2%;
  margin-bottom: 200px;
}

.header {
  text-align: center;
}

.__title_choice__avatar {
  text-transform: uppercase;
  margin: var(--mb-1) 0;
  font-size: 1.4em;
  color: #707070;
  font-weight: 600;
}

.avatar {
  position: relative;
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  border-radius: 100%;
}

.avatar .btn__Upload {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  background: transparent;
  /* visibility: hidden; */
  cursor: pointer;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.__input_choice_pseudo {
  width: 100%;
  height: 40px;
  max-width: 200px;
  border: 1px solid #707070;
  margin: var(--mb-1) auto var(--mb-2) auto;
  border-radius: 10px;
  padding-top: 3px;
}

.__input_choice_system {
  width: 100%;
  height: 40px;
  max-width: 240px;
  border: 1px solid #707070;
  margin: var(--mb-1) auto var(--mb-2) auto;
  border-radius: 10px;
  padding-top: 3px;
}

.__input_choice_school {
  width: 100%;
  height: 40px;
  max-width: 270px;
  border: 1px solid #707070;
  margin: var(--mb-1) auto var(--mb-2) auto;
  border-radius: 10px;
  padding-top: 3px;
}

.__input_choice_pseudo input {
  width: 95%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.__input_choice_system input {
  width: 95%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.__input_choice_school input {
  width: 95%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.text p strong {
  font-weight: 500;
}

.text p a {
  color: var(--text-color);
  text-decoration: underline;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid red;
}

.form__content {
  text-align: center;
  margin-top: var(--mb-2);
}

.form__content h3 {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3em;
  color: var(--text-color);
}

.form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--mb-2);
}

.field {
  width: 150px;
  margin: 0 var(--mb-1) !important;
  margin-bottom: var(--mb-2) !important;
  height: 45px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  padding-top: 0.4em !important;
  border-radius: 10px !important;
}

.radio__group {
  width: 100%;
  max-width: 700px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  flex-direction: column !important;
}

.button {
  width: 100%;
  height: 35px !important;
  min-width: 300px;
  margin-bottom: var(--mb-2);
  font-size: 1em !important;
  padding-top: 5px !important;
}

.content__action {
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.teacher__action {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--mb-6) 0;
}

.teacher__action .avatar {
  padding-right: var(--mb-2);
}

.text strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register__teacher * a {
  color: var(--text-color);
  text-decoration: underline;
}

.alreadyAccount {
  padding-top: var(--mb-6);
}

.button__signin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: var(--mb-2);
}

.youAreReadyAccount {
  margin-top: var(--mb-6);
  margin-bottom: var(--mb-6);
}

.youAreReadyAccount p a {
  color: var(--text-color);
  text-decoration: underline;
}

.title__level {
  font-weight: 500;
  margin-bottom: var(--mb-1);
}

@media only screen and (max-width: 500px) {
  .avatar {
    width: 70px;
    height: 70px;
  }

  .__title_choice__avatar {
    font-size: 1em;
  }

  .__input_choice_pseudo {
    height: 38px;
    font-size: 13px !important;
  }

  .__input_choice_system {
    height: 38px;
    font-size: 13px !important;
  }

  .__input_choice_school {
    height: 38px;
    font-size: 13px !important;
  }

  .form__content h3 {
    font-size: 1em;
  }

  .field {
    width: 85px;
    margin: 0 0.3em !important;
    margin-bottom: var(--mb-2) !important;
    height: 40px !important;
    font-size: 11px !important;
    padding-top: 0.3em !important;
  }

  .button {
    height: 30px !important;
    margin-bottom: var(--mb-1);
    font-size: 0.9em !important;
  }

  .__input_choice_pseudo input {
    font-size: 14px !important;
  }

  .__input_choice_system input {
    font-size: 14px !important;
  }

  .__input_choice_school input {
    font-size: 14px !important;
  }
}