.body {
  width: 80%;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.cardResult {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 0 1em;
  border: 1px solid #333333;
}

.cardResponse {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  background-color: #e1ede0;
  border-radius: 5px;
  padding: 0 1em;
  border: 0px solid #333333;
  margin-top: 10px;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
}

.body span {
  font-size: 18px;
}

.body .question {
  font-size: 20px;
  margin-bottom: 10px;
}

.isCorrect {
  border: 1px solid #449F01;
}

.isNoCorrect {
  border: 1px solid #FF0000;
}