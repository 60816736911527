.__upload__with__ck__content {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid #707070; */
  border-radius: 10px;
  padding: 0 15px;
}

.__round__copy,
.__ck__scaner {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  right: 4%;
  bottom: 1em;
  margin-left: 10px;
  cursor: pointer;
}


.__ck__math__content {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.__ck__math__content span {
  font-size: 13px;
}

.__copy {
  width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-radius: 5px;
  padding: 2%;
  line-height: 15px;
  overflow: hidden;
  overflow-y: scroll;
}

.__latext__content * strong {
  font-weight: 600;
}