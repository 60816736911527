.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.leftBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 55px;
}

.message {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

.titleLines {
    height: 110px;
    position: relative;
    left: 8%;
    bottom: 5px;
}

.parentOne {
    height: 220px;
    margin-top: 40px;
    position: relative;
    left: -30px;
}

.parentTwo {
    height: 225px;
    margin-top: 40px;
    z-index: 1;
}

.imagesBox {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.ellipseStudentTwo {
    height: 100px;
    position: relative;
    left: -25%;
    bottom: 0px;
    z-index: 0;
}

.parentThree {
    height: 220px;
    margin-top: 40px;
    position: relative;
    left: -30px;
    z-index: 1;
}

.parentFour {
    height: 225px;
    margin-top: 40px;
    z-index: 1;
}

.ellipseLines {
    height: 70px;
    position: relative;
    right: 105%;
    bottom: 0px;
}

.ellipseMaterial {
    height: 25px;
    position: relative;
    left: -31%;
    top: 130px;
}

.traceInstaProf {
    height: 100px;
    position: relative;
    left: -28%;
    bottom: 0px;
}

.ellipseStudentThree {
    height: 90px;
    position: relative;
    right: 107%;
    top: 210px;
    z-index: 0;
}

.ellipseStudentFour {
    height: 90px;
    position: relative;
    left: -27%;
    top: 130px;
    z-index: 0;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .leftBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 5%;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
    }

    .message {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

    .titleLines {
        height: 44px;
        position: relative;
        left: 0%;
        bottom: 5px;
    }

    .parentOne {
        height: 150px;
        margin-top: 40px;
        position: relative;
        left: -30px;
    }

    .parentTwo {
        height: 150px;
        margin-top: 40px;
        z-index: 1;
    }

    .imagesBox {
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
    }

    .ellipseStudentTwo {
        height: 100px;
        position: relative;
        left: -160%;
        bottom: 20px;
        z-index: 0;
    }

    .parentThree {
        height: 150px;
        margin-top: 40px;
        position: relative;
        left: -30px;
        z-index: 1;
    }

    .parentFour {
        height: 150px;
        margin-top: 40px;
        z-index: 1;
    }

    .ellipseLines {
        height: 65px;
        position: relative;
        right: 435%;
        bottom: 0px;
    }

    .ellipseMaterial {
        height: 25px;
        position: relative;
        left: -180%;
        top: 130px;
    }

    .traceInstaProf {
        height: 70px;
        position: relative;
        left: -238%;
        bottom: 40px;
    }

    .ellipseStudentThree {
        height: 90px;
        position: relative;
        right: 427%;
        top: 120px;
        z-index: 0;
    }

    .ellipseStudentFour {
        height: 90px;
        position: relative;
        left: -180%;
        top: 80px;
        z-index: 0;
    }

}