.register__teacher {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 1em; */
  text-align: center;
}

.button {
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 45px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: var(--normal-font-size) !important;
  text-transform: uppercase;
  background: #fff !important;
  color: var(--text) !important;
  border: 2px solid var(--text-color) !important;
}

.content__action {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 8px;
}

button.button__fb {
  background: #3E519A !important;
  color: #fff !important;
  border: unset !important;
}

.button__fb svg {
  position: absolute;
  width: 28px;
  left: 25px;
  top: 10px;
  height: 28px;
}

.button.gmail {
  background: var(--first-color) !important;
  height: 42px !important;
  border: unset !important;
  color: #fff !important;
  font-weight: 500 !important;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
}

.teacher {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--mb-4) 0;
}

.teacher .avatar {
  padding-right: var(--mb-4);
}

.text p {
  font-size: 1em;
}

.text strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register__teacher * a {
  color: var(--text-color);
  text-decoration: underline;
}

.alreadyAccount {
  padding-top: var(--mb-3);
}

.button.google {
  background: #fff !important;
}

@media only screen and (max-width: 500px) {
  .teacher {
    flex-direction: column;
    margin: 1.5em 0;
  }

  .teacher .avatar {
    width: 120px;
    height: 120px;
    border: 50px;
    padding-right: 0;
    border: 1px solid #70707054;
    display: flex;
    align-items: flex-end;
    border-radius: 100px;
    margin-bottom: var(--mb-4);
  }

  .teacher .avatar img {
    width: 100px;
    margin: auto;
  }

  .button {
    position: relative;
    width: 100%;
    max-width: 320px;
    height: 45px !important;
  }
}