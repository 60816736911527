.easyQuiz {
  margin: 1em 0;
  min-height: 60vh;
}

.header {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  border-radius: 10px;
  margin: 10px 0;
  margin-bottom: 1em;
  padding: 0 2em;
}

.center {
  text-align: right;
  width: 34%;
  font-size: 2em;
}

.center h3 {
  color: var(--text-color);
}

.select {
  width: 200px;
  height: 42px;
  border: 1px solid var(--text-color);
  padding-top: 4px;
  border-radius: 10px;
}


.__app__btn {
  position: relative;
  min-width: 370px;
  font-family: var(--title-font);
  font-size: 1em !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding-right: 10px !important;
  border-radius: unset !important;
}

.__content__quiz {
  position: relative;
  top: -2px;
  margin-left: 20px;
}

.__check {
  color: #fff;
  border-radius: 30px !important;
}

@media only screen and (max-width: 500px) {
  .header {
    padding: 0 0.5em;
  }

  .select {
    width: 100px;
    height: 40px;
    padding-top: 2.5px;
  }

  .left span {
    font-size: 11px;
  }

}