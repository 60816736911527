.register__teacher {
  width: 100%;
  margin-bottom: 4em;
  text-align: center;
}

.title {
  font-size: 3.8em;
}

.student__action {
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-2);
}

.avatar {
  position: relative;
  width: 100px;
  height: 100px;
  background: #f7f7f7;
  border-radius: 50%;
  margin-right: var(--mb-2);
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}


.student__action p a {
  color: var(--text-color);
  text-decoration: underline;
}

.content__action {
  width: 100%;
  /* min-height: 10vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100%;
  max-width: 600px;
  padding: 0 3%;
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;
}

.field {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.field::placeholder {
  font-style: italic !important;
}

.button {
  width: 100%;
  height: 35px !important;
  max-width: none !important;
  margin-bottom: var(--mb-2);
  font-size: 1em !important;
  padding-top: 5px !important;
}


.termes__legals {
  float: left;
  text-align: left;
  color: #636363;
}

.info {
  margin-top: var(--mb-2);
}

.info span {
  text-align: left;
  color: #9D9D9D;
  font-style: italic;
}

.info p {
  text-align: center;
  color: var(--text-color);
}

.info a {
  color: var(--first-color);
  text-decoration: underline;
}

.text p {
  text-align: left;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 1.3em;
  color: #9D9D9D;
}

@media only screen and (max-width: 390px) {
  .avatar {
    width: 80px;
    height: 80px;
  }

  .student__action p {
    line-height: 25px;
  }

  .field {
    height: 40px !important;
  }

  .info span,
  .policy {
    font-size: 12px !important;
  }

  .policy br {
    display: none;
  }

}