.stats {
    margin-top: 2em;
    margin-bottom: 5em;
}

.__select {
    margin-top: 15px;
}

.__select div {
    position: relative;
    text-align: center;
    font-size: 2.4em;
    margin-top: var(--mb-1);
    line-height: 0 !important;
    font-family: var(--title-font);
    text-transform: uppercase;
    border: 1px solid black !important;
}

.iconArrowClass {
    width: 20px;
    font-size: 12px !important;
    top: 3px !important;
    right: 30px !important;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    margin-bottom: 4em;
    margin-top: 0.5em;
}

.card__matier {
    position: relative;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin-top: var(--mb-2);
    transition: all 0.7s;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    padding-left: 0px;
    padding-right: 5px;
}

.card__matier.active,
.card__matier:hover {
    width: 200px;
    height: 76px;
    background: var(--first-color);
    box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.card__matier.active .topic_name,
.card__matier .topic_name {
    position: absolute;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform ease-out 0.7s;
    transform-origin: center right;
    transition: all 0.7s;
    -webkit-transition: transform ease-out 0.7s;
    -moz-transition: transform ease-out 0.7s;
    -ms-transition: transform ease-out 0.7s;
    -o-transition: transform ease-out 0.7s;
}

.card__matier.active .topic_name,
.card__matier:hover .topic_name {
    position: relative;
    visibility: visible;
    transform: translateX(0);
}

.topic_name h3 {
    color: #fff;
    font-family: var(--title-font);
    white-space: nowrap;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.icon__card__matiers {
    transform-origin: center left;
    transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.icon__card__matiers img {
    width: 50px;
}

.main {
    width: 100%;
}

.card__dashboard {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2em;
    padding: 1.5em;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.infos_top {
    margin-bottom: var(--mb-1);
}

.infos_top h2 {
    font-size: 1.3em;
}

.right h3 {
    text-transform: uppercase;
    font-size: 1em;
}

.content__propositions {
    width: 100%;
    padding: 1em 4em;
}

.propositions__card {
    margin-top: var(--mb-6);
}

.content_card {
    margin-top: var(--mb-2);
}

.card {
    width: 100%;
    min-height: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2em;
    padding: 0 2em;
    margin-bottom: 1em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.card h4 {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-color);
}

.isCorrect {
    border: 1.3px solid green;
}

.propositionSelected {
    border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
    .right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.stats__dashboard {
    width: 100%;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-top: 20px;
}

.backDiv {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    height: 35px;
    width: auto;
}

.backButton {
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.statsContainer {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.radarContainer {
    height: auto;
    width: 40%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.boxTitle {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    margin-left: 20px;
    margin-top: 10px;
    font-family: "CartoonFontBold";
    font-weight: 600;
}

.boxTitle:hover {
    color: #333;
}

.radarBox {
    height: auto;
    width: 85%;
    margin-left: 10%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
}

.radarTitle {
    color: #333;
    font-size: 1.5em;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 20px;
}

.radarTitle:hover {
    color: #333;
}

.radarMarkBox {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radarAVG {
    font-size: 35px;
    color: #333;
    font-weight: bold;
}

.radarAVG:hover {
    color: #333;
}

.radarMaxAVG {
    margin-top: 10px;
    font-weight: bold;
}

.radarMaxAVG:hover {
    color: #333;
}

.radarChartBox {
    display: flex;
    justify-content: center;
    padding: 0px;
}

.lineContainer {
    height: auto;
    width: 58%;
    display: flex;
    flex-direction: column;
}

.lineBox {
    height: 430px !important;
    width: 100%;
    display: block;
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    padding: 2.5%;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.lineChartCard {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
}

.lineChartCard:hover {
    background-color: #80b1b21a;
}

.lineChartCardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.lineChartCardAVG {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 20px;
    color: #333;
    font-weight: normal;
}

.lineTitle {
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.backHeader {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.backLeft .backTitle {
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.center {
    width: 100%;
    flex: 2;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.arrowRightButton {
    border-radius: 50px;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: #fff 0px solid;
}

.arrowRightButton:hover {
    background: #80B1B2;
    color: white;
}

@media only screen and (max-width: 767px) {
    .backLeft .backTitle {
        text-transform: uppercase;
        font-size: 1.2em;
        color: var(--text-color);
    }

    .lineContainer {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .lineContainer {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .lineChartCard {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        margin-top: 10px;
    }

    .radarContainer {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .statsContainer {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .card__dashboard {
        gap: 0.5em;
        padding: 0.5em;
    }

    .stats__dashboard {
        gap: 0.5em;
        padding: 0.5em;
    }

    .topic_name h3 {
        font-size: 0.7em;
    }

    .icon__card__matiers img {
        width: 30px;
    }

    .card__matier {
        width: auto;
        height: 60px;
        margin-top: var(--mb-1);
    }

    .card__matier.active,
    .card__matier:hover {
        width: auto;
        height: 50px;
    }

    .content__propositions {
        padding: 0;
    }

    .header {
        gap: 0.5em;
        margin-bottom: 2em;
        margin-top: 0;
    }

    .right {
        margin-top: 1em;
    }

    .card {
        padding: 0 1em;
    }

    .__select div {
        font-size: 1.5em;
        margin-bottom: var(--mb-1);
    }

    .iconArrowClass {
        width: 15px;
        top: -1px !important;
        right: 60px !important;
    }

    .propositions__card {
        margin-top: var(--mb-4);
    }

    .radarChartBox {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .radarBox {
        height: auto;
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
        background: white;
        border-radius: 15px;
    }
}

.dashedBox {
    /* padding: 10px;
    border: 1px dashed #707070;
    background: #fff;
    border-radius: 15px; */
    font-size: 1em;
    display: flex;
    flex-direction: row;
    font-weight: 600;
}

.rateBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.rateBoxDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 35%;
}

.rateBoxSpan {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    margin-left: 5px;
    color: #707070;
}

.rateBoxStar {
    position: relative;
    top: -1px;
    margin-left: 5px;
    margin-right: 5px;
}

.card {
    position: relative;
    width: 100%;
    background: #fafafa;
    margin-bottom: var(--mb-4);
    -webkit-box-shadow: 2px 3px 0px 2px #cfd0d2;
    box-shadow: 3px 4px 0px 3px #cfd0d2;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 80px;
    padding-right: 40px;
    padding-bottom: 40px;
}

.card:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 70px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.card::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 50px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.defaultButton {
    position: relative;
    bottom: 12.5px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.defaultButton:hover {
    transform: scale(1.03) !important;
}

.defaultButton:active {
    transform: scale(0.99) !important;
}

.currentTab {
    display: inline-block;
    padding: 8px 16px;
    border: 1px solid #7FB1B2;
    border-radius: 4px;
    cursor: pointer;
    background-color: #7FB1B2;
    color: #fff;
    font-weight: bold;
    font-family: "CartoonFont";
    font-size: 1.2em;
}

.defaultTab {
    display: inline-block;
    padding: 8px 16px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    cursor: pointer;
    color: var(--title-color);
    background-color: #D5D5D5;
    font-weight: bold;
    font-family: "CartoonFont";
    font-size: 1.2em;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 5em 0 1em 0;
}

.__btn__challenge {
    position: relative;
    max-width: 350px;
    min-width: 350px;
}

.__btn__challengeX {
    opacity: 0.75;
    background-color: #7d7d7d74 !important;
    color: var(--text-color) !important;
    box-shadow: 3px 6px 1px -1px var(--text-color) !important;
    cursor: not-allowed !important;
}

.main {
    width: 100%;
    height: 100%;
    margin-top: 4em;
}

.center {
    width: 100%;
    flex: 2;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.left .subtitle {
    position: relative;
    top: 15px;
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.main {
    width: 100%;
    height: 100%;
    margin-top: 4em;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main__left,
.main__right {
    width: 100%;
    height: 100%;
}

.main__left {
    flex: 2;
}

.main__right {
    flex: 0.8;
}

.main__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: var(--mb-2);
}

.main__left__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: var(--mb-6);
}

.graphe__right,
.graphe__left {
    height: 400px;
    gap: 20px;
    background: #fafafa;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.graphe__left__header {
    margin-left: var(--mb-2);
    padding-top: var(--mb-2);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__garphe__right__header {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.4em;
    margin-top: 1.5em;
}

.select {
    position: relative;
    height: 45px;
    width: 100%;
    max-width: 200px;
    padding-top: 5px;
    text-align: left;
    border-radius: 10px;
    border: 1px solid var(--text-color);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.__first__card,
.__second__card {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    background: #fafafa;
    border: 2px solid #dbdbdb;
}

.__first__card h4 {
    font-size: 1.5em;
    text-transform: uppercase;
    color: var(--text-color);
    margin-top: var(--mb-1);
}

.__second__card {
    margin-top: var(--mb-3);
    height: 255px;
}

.__second__card span {
    text-transform: uppercase;
    font-size: 1em;
}

.__second__card .code {
    width: 150px;
    height: 40px;
    background: #fff;
    border: 1px dashed var(--text-color);
    display: flex;
    margin: var(--mb-3) auto;
    border-radius: 50px;
}

.code span {
    margin: auto;
    font-size: 1.2em;
    font-weight: 500;
}

.__content__btn button:first-child {
    margin-bottom: var(--mb-2);
}

.__btn {
    min-width: 200px !important;
    max-width: 200px !important;
    padding-top: 10px;
    height: 38px !important;
}

.array__conent {
    width: 100%;
    margin-top: var(--mb-4);
    max-height: 700px;
    background: #fafafa;
    border-radius: 10px;
    padding: var(--mb-2) var(--mb-3);
}

/* Array */

.array__conent__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: var(--mb-2);
}

.filed__search {
    width: 300px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 2px;
}

.array__conent__header * input,
.array__conent__header * button {
    background: transparent !important;
    border: unset !important;
    box-shadow: unset !important;
}

.array__conent__header * input:focus {
    box-shadow: unset !important;
    border: unset !important;
    outline: none !important;
}

.array__conent__header * input::placeholder {
    color: var(--text-color);
}

.__array {
    width: 100% !important;
    max-height: 500px;
    margin-top: var(--mb-6);
    background: #fff;
}

/* Drawer */

.drawer {
    position: relative;
    width: 100%;
    margin-top: var(--mb-6);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.drawer__content {
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.drawer__content * label {
    position: relative;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    color: #707070;
    top: 10px;
    font-size: 1em;
    text-align: left;
}

.field__text,
.field div {
    height: 50px !important;
    border: 1px solid var(--text-color) !important;
    border-radius: 10px !important;
}

.field div {
    padding-top: 10px !important;
}

.drawer___header {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--mb-6);
}

.drawer___header h3 {
    font-family: var(--title-font);
    font-size: 3.2em;
    text-transform: uppercase;
    margin-top: var(--mb-2);
}

.__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--mb-4);
    gap: 15px;
}

.faq {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.5em;
    color: #707070;
}

.content {
    margin-bottom: var(--mb-6);
}

.__overflow__Y {
    width: 100%;
    height: 700px;
    overflow-y: scroll;
}

.tableHeader {
    color: var(--first-color);
}

.filed__search {
    width: 300px;
    padding: 0;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding-bottom: 2px;
    overflow: hidden;
}

/*  */
.inputSearchContent * input,
.inputSearchContent * button {
    background: transparent !important;
    border: unset !important;
}

.inputSearchContent * input:focus {
    box-shadow: unset !important;
    border: unset !important;
    outline: none !important;
}

.inputSearchContent * input::placeholder {
    color: var(--text-color);
}

/* .inputSearchContent * button {
    background: rgba(255, 255, 255, 0) !important;
  } */

.elt {
    margin-bottom: 50px !important;
}

/* New Css Styles */

.tabTitleBox {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.tabTitleBoxActive {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.classeCard {
    position: relative;
    width: 100% !important;
    height: 70px;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 10px 0px 10px 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cardClassBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.detailsBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 35% !important;
    margin-right: 20px;
}

.timeBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 30px;
}

.times {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.time {
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
}

.timeDescription {
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
}

.newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 13px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
}

.newButton:hover {
    transform: scale(1.03) !important;
    background-color: #80b1b2;
    color: #fff;
}

.cardDisplay {
    display: flex;
    flex-direction: column;
}

.studentDetailsDrawer {
    width: 90%;
    background: #FFF;
    box-shadow: 4px 9px 18px #FFF;
    border: 1px solid #DFDDDD;
    border-radius: 20px;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin: 40px auto;
    padding: 20px;
}

.formBox {
    width: 90%;
    background: #FFF;
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    margin: 40px auto;
    padding: 20px;
}

.formTitle {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    margin-bottom: 20px;
    margin-left: 20px;
}

.inputField {
    border-radius: 10px !important;
    font-size: 12px !important;
}

.inputField::placeholder {
    font-size: 12px !important;
}

.statsBox {
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    padding-top: 3%;
    background: #FAFAFA;
}

.drawerTimeBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
}

@media only screen and (max-width: 1050px) {

    .lineBox {
        height: 38vh !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        background: #fff;
        box-shadow: 2px 4px 8px #CECECE29;
        border: 1px solid #BFBCBC81;
        border-radius: 20px;
        padding: 1%;
    }

    .statsBox {
        margin-top: 3%;
        height: 38vh !important;
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: thin;
        background: #fff;
        box-shadow: 2px 4px 8px #CECECE29;
        border: 1px solid #BFBCBC81;
        border-radius: 20px;
        padding-top: 20px;
    }

    .tabTitleBox {
        height: 35px;
        background: #FFF;
        box-shadow: 6px 8px 14px #CECECE29;
        border: 2px solid #BFBCBC82;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 0.8em !important;
    }

    .tabTitleBoxActive {
        height: 35px;
        background: #FFF;
        box-shadow: 6px 8px 14px #7FB1B238;
        border: 2px solid #7FB1B2;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 0.8em !important;
    }

    .classeCard {
        position: relative;
        width: 100% !important;
        height: auto !important;
        background: #FAFAFA;
        box-shadow: 0px 3px 10px #BCBCBC29;
        border: 1px solid #BFBCBC;
        padding-top: 10px;
        padding-bottom: 20px;
        border-radius: 18px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .cardClassBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .detailsBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin-right: 0px;
    }

    .timeBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .times {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .time {
        font-size: 0.7em;
        font-family: "Poppins";
        font-weight: 500;
        color: #393E3E;
    }

    .timeDescription {
        font-size: 0.7em;
        font-family: "Poppins";
        font-weight: 400;
        color: #868686;
    }

    .newButton {
        width: auto !important;
        height: 30px !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 50px !important;
        font-size: 0.8em !important;
        text-transform: none;
        text-align: center;
        background: #80b1b2 !important;
        color: #fff !important;
        padding-top: 5px !important;
        font-weight: 400 !important;
        border: none !important;
        box-shadow: 3px 5px 1px -1px #5a8484 !important;
        -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
        -moz-box-shadow: 5px 7px 1px -1px #5a8484;
        padding-left: 20px;
        padding-right: 20px;
    }

    .cardDisplay {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 5px;
        width: 70%;
        margin: 0px auto;
    }

    .formTitle {
        font-size: 0.7em;
        font-family: "Poppins";
        font-weight: 500;
        color: #393E3E;
        margin-bottom: 20px;
    }

    .studentDetailsDrawer {
        width: 100%;
        background: none;
        box-shadow: 0px 9px 18px #FFF;
        border: 0px solid #DFDDDD;
        border-radius: 0px;
        display: flex !important;
        flex-direction: row !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin: 0px auto;
        padding: 0px;
    }

    .inputField {
        border-radius: 10px !important;
        font-size: 0.8em !important;
    }

    .inputField::placeholder {
        font-size: 0.8em !important;
    }

    .rateBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
    }

    .rateBoxDetails {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 10px;
        width: auto;
    }

    .dashedBox {
        font-size: 0.75em;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        font-weight: 600;
        width: 100%;
    }

    .drawerTimeBox {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-between !important;
        font-size: 0.8em;
        width: 100%;
    }

    .formBox {
        width: 90%;
        background: #FFF;
        box-shadow: 2px 4px 8px #CECECE29;
        border: 1px solid #BFBCBC81;
        border-radius: 20px;
        margin: 20px auto;
        padding: 20px;
    }
}