.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 2px;
}

.round__user {
  width: 65px;
  height: 65px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round__user img {
  margin: auto;
}

.content h3 {
  width: 100%;
  max-width: 130px;
  font-size: 14px;
  text-transform: uppercase;
  color: #333;
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.__start {
  position: absolute;
  margin: auto;
  width: 50px;
  height: 20px;
  background: #fff;
  border: 1px dashed #707070;
  display: flex;
  top: -5%;
  right: 12%;
  border-radius: 15px;
}

.__start span {
  margin: auto;
  font-size: 11px;
}

.__button {
  max-width: 150px;
  min-width: 150px;
  height: 30px !important;
  font-size: 10px !important;
  margin-top: 5px;
}

@media only screen and (max-width: 500px) {
  .__start {
    top: -5%;
    right: 22%;
  }
}
