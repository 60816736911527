.formContainer {
    height: auto;
    width: auto;
    margin-top: 20px;

}

.formBox {
    display: block;
    padding-left: 40px;
    padding-right: 40px;
    height: auto;
    width: 85% !important;
    background: #FAFAFA;
    margin: 0px auto;
    padding: 30px !important;
    border: 1px solid #707070;
    border-radius: 16px;
}

.title {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 600;
    color: #707070;
    margin-bottom: 20px;
}

.selectInput {
    color: #707070;
    border-radius: 8px;
    border: 1px solid #707070;
    font-style: italic !important;
    font-size: 1em;
    width: 100%;
}

.placeholderStyle {
    color: #C0C0C0;
    font-size: 16px;
    font-size: 1em;
}

.newMoveButton {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
}

.field {
    height: 100px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
}

.field::placeholder {
    font-style: italic !important;
    font-size: 1em !important;
}

.buttonBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    padding: auto;
    width: 100%;
}