.title {
  text-align: center;
}

.title h2 {
  color: var(--first-color);
  margin: var(--mb-1) 0;
}

/* .info svg {
  font-size: 17px;
  width: 50px;
  height: 50px;
} */

.arrow {
  cursor: pointer;
}
.info .arrow:hover svg path {
  fill: var(--first-color);
}
.info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.logo {
  margin-bottom: var(--mb-1);
}
