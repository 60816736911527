.message {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

.textArea {
  width: 100%;
  background: #fff;
  padding: 7px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 10px;
}

.BlockedmessageBox {
  height: 40px;
  width: 100%;
  border: 1px solid #F56666;
  border-radius: 16px;
  background: #FFF;
  color: #F56666;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}

.inputBox {
  padding: 15px;
}

.inputTextarea {
  height: 70px !important;
  width: 95%;
  border: 1px solid #B9B9B9;
  border-radius: 24px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 20px;
  padding-right: 15px;
}

@media only screen and (max-width: 1050px) {
  .message {
    gap: 0.5em;
  }

  .button {
    min-width: 50px;
    font-size: 10px !important;
  }

  .BlockedmessageBox {
    height: 40px;
    width: 100%;
    border: 1px solid #F56666;
    border-radius: 12px;
    background: #FFF;
    color: #F56666;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .inputTextarea {
    height: 45px !important;
    width: 95%;
    border: 1px solid #B9B9B9;
    border-radius: 32px;
    padding: 15px;
    margin: 0px auto;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .messageInput {
    font-size: 1em !important;
  }

  .messageInput::placeholder {
    font-size: 1em !important;
  }

}