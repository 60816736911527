.container {
  max-width: 650px;
  margin-top: 3.5em;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.__title {
  text-align: left;
  margin-left: 15%;
  margin-bottom: var(--mb-6);
}

.timeline_title {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: var(--h2-font-size);
}

.timeline {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 30px;
  color: var(--text-color);
}

.timeline:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: var(--first-color);
}

.timeline .timeline_container {
  position: relative;
  margin-bottom: 3.5rem;
}

.timeline .timeline_container .timeline_icon {
  position: absolute;
  left: -77px;
  top: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  /* background: #4f537b; */
}

.timeline .timeline_container .timeline_icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.timeline .timeline_container .timeline_icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.timeline .timeline_container .timeline_body {
  background: #fff;
  border-radius: 10px;
  padding: 20px 20px 15px;
  /* margin-top: 4em; */
  height: 505px;
  overflow-y: scroll;
  overflow-x: scroll;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
}

.timeline .timeline_container .timeline_body:before {
  content: "";
  background: inherit;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: -10px;
  transform: rotate(45deg);
  border-radius: 0 0 0 2px;
}

.timeline .timeline_container .timeline_body .timeline-title {
  margin-bottom: 1.4rem;
}

.timeline .timeline_container .timeline_body .timeline-title .badge {
  background: #4f537b;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
}

.timeline .timeline_container .timeline_body .timeline-subtitle {
  font-weight: 300;
  font-style: italic;
  opacity: 0.4;
  margin-top: 16px;
  font-size: 11px;
}

/* .timeline .timeline_container.primary .timeline_icon {
  background: var(--first-color) !important;
}

.timeline .timeline_container.info .timeline_icon {
  background: #11cdef !important;
}

.timeline .timeline_container.success .timeline_icon {
  background: #00bf9a !important;
}

.timeline .timeline_container.warning .timeline_icon {
  background: #ff8d72 !important;
}

.timeline .timeline_container.danger .timeline_icon {
  background: #fd5d93 !important;
} */
