.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -108px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.subtitle {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.animatedBoxes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 155%;
}

.boxTitle {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 20px;
    color: rgb(8, 7, 7);
    line-height: 30px;
    margin-bottom: 15px;
}

.boxMessage {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(57, 62, 62);
    line-height: 25px;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -60px;
        width: 25px;
        bottom: 35px;
        z-index: -1;
    }

    .subtitle {
        font-size: 14px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
    }

    .animatedBoxes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 25px auto;
        width: 90%;
    }

}