.calendrier {
  width: 100%;
  margin: 2em 0;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.calendar__content {
  width: 100%;
}

.profile {
  position: relative;
  width: 115px;
  height: 115px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  top: -3em;
}

.__avatar {
  width: 110px;
  height: 110px;
  border-radius: 100px;
  margin: auto;
}

.__drawer_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.__drawer_header h3 {
  font-size: 3.5em;
  font-family: var(--title-font);
  color: var(--first-color);
}

.name {
  position: relative;
  color: var(--text-color);
  font-size: 1.7em;
  text-transform: uppercase;
  top: -1em;
}

.__drawer__card {
  width: 65%;
  height: 400px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  margin-top: 6em;
}

.card__content {
  text-align: center;
  margin-top: 1em;
  font-size: 16px;
}

.countDown {
  font-size: 2em;
  color: var(--first-color);
  text-transform: uppercase;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

.__button {
  min-width: 300px;
}

.__button svg {
  position: relative;
  top: 2px;
  left: -10px;
}

/*  */

.calendar {
  width: 100%;
  overflow: hidden;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #fafafa;
}

.weekly {
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 2% 4%;
  margin-bottom: 2em;
  border: 1px solid #ccc;
}

.date {
  width: 100%;
  min-height: 35px;
  border-radius: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  padding-top: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.date:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}
.date.active {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.creneaux {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.creneau {
  margin-bottom: 0.5em;
}

.range {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.range span {
  margin: auto;
}

.matiere {
  font-size: 1em;
}

.bar {
  margin: 0 0.5em;
}

.angleIcon path {
  fill: #fe772e;
}

.slider > svg path {
  fill: #fe772e;
}

.card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .content__action__reservation {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.myReservation {
  margin-bottom: 1em;
}

.card_ {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}

.boxTitle {
  color: var(--title-color);
  font-size: var(--h1-font-size);
  margin-left: 20px;
  margin-top: 10px;
  font-family: "CartoonFontBold";
  font-weight: 600;
}

.boxTitle:hover {
  color: #333;
}
