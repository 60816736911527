.publications {
  margin: 4em 0;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin-left: var(--mb-4);
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.main {
  margin-top: 4em;
}

.grid {
  display: grid;
  row-gap: 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  
  .grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.3em;
  }

}

@media only screen and (max-width: 767px) {

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .center {
    width: 100%;
    flex: 2;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }
}
