.card {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #fafafa;
  margin-bottom: var(--mb-2);
  -webkit-box-shadow: 2px 3px 0px 2px #cfd0d2;
  box-shadow: 3px 4px 0px 3px #cfd0d2;
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.card::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.container {
  width: 100%;
  min-height: 240px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-2);
  padding-left: var(--mb-2);
  padding-bottom: var(--mb-2);
}

.header {
  margin-bottom: var(--mb-2);
  display: flex;
  justify-content: space-between;
}

.user__infos h3 {
  text-transform: uppercase;
}

.body {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mb-1);
}

.button {
  padding: 5px auto;
  border: 1px dashed #707070;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
}

.footer h4:hover {
  color: var(--first-color);
  cursor: pointer;
}

.cercle {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

@media only screen and (max-width: 1050px) {
  .user__infos h3 {
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .user__infos span {
    font-size: 0.75em;
  }

  .body {
    font-size: 0.75em !important;
  }

  .footer h4 {
    font-size: 0.75em !important;
  }

  .footer span {
    font-size: 0.75em !important;
    position: relative;
    top: -5px;
  }
}