.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 40px;
    margin-top: 0px;
    z-index: 1;
}

.ellipse_title {
    position: relative;
    left: -40%;
    width: 55px;
    bottom: 65px;
    z-index: -1;
}

.message {
    font-size: 18px;
    color: rgb(57, 62, 62);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    position: relative;
    top: -25px;
}

.teachersBox {
    display: flex;
    flex-direction: row;
    height: 420px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -40px;
    padding-left: 10%;
    padding-right: 10%;
}

.arrowLeftBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 56px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    cursor: pointer;
    margin-right: 2%;
}

.arrowRightBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 56px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    cursor: pointer;
    margin-left: 2%;
}

.carouselContent {
    width: 75%;
    height: 470px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 43px;
    overflow: hidden;
}

.slideTeacherBox {
    height: 420px;
    width: 315px;
    background: white;
    border-radius: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    flex: 0 0 23%;
    margin-left: 20px;
    margin-right: 0px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.teacherBoxImage {
    height: 155px;
    width: 155px;
    border-radius: 50%;
    background: black;
}

.teacherBoxName {
    font-size: 18px;
    font-family: "Poppins";
    font-weight: normal;
    line-height: 30px;
    color: #393E3E;
    margin-top: 20px;
}

.teacherBoxCountry {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: normal;
    line-height: 32px;
    color: #679091;
    margin-top: 0px;
}

.teacherBoxCourses {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: bold;
    line-height: 32px;
    color: #393E3E;
    margin-top: 0px;
}

.teacherBoxMark {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: normal;
    line-height: 32px;
    color: #393E3E;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 680px) {

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 1;
    }

    .ellipse_title {
        position: relative;
        left: -32%;
        width: 25px;
        bottom: 65px;
        z-index: -1;
    }

    .message {
        font-size: 13px;
        color: rgb(57, 62, 62);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        position: relative;
        top: -5px;
    }

    .teachersBox {
        display: flex;
        flex-direction: column;
        height: 520px;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0px;
        padding-left: 0%;
        padding-right: 0%;
        margin-top: 30px;
    }

    .carouselContent {
        width: 95%;
        height: 380px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 43px;
        overflow: hidden;
    }

    .slideTeacherBox {
        height: 380px;
        width: 75%;
        background: white;
        border-radius: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        flex: 0 0 75%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }

    .teacherBoxImage {
        height: 115px;
        width: 115px;
        border-radius: 50%;
    }

    .teacherBoxImageProfile {
        height: 115px;
        width: 115px;
        border-radius: 50%;
    }

}