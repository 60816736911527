.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.whyUsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -20.7%;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.mainBox {
    width: 155%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 21.5%;
    padding-right: 21%;
}

.subBox {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.circleOne {
    height: 110px;
    width: 110px;
    background: rgba(245, 203, 147, 0.463);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleTwo {
    height: 110px;
    width: 110px;
    background: rgba(138, 176, 177, 0.314);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleThree {
    height: 110px;
    width: 110px;
    background: rgba(255, 200, 186, 0.718);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleOneNumber {
    color: rgb(224, 173, 106);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleTwoNumber {
    color: rgb(138, 176, 177);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleThreeNumber {
    color: rgb(250, 131, 131);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.circleTitle {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 25px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 40px;
}

.circleMessage {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 18px;
    color: #2F2F2F;
    line-height: 30px;
}

.quote {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -320px;
    left: -37%;
}

.quote:hover {
    color: rgba(162, 159, 159, 0.08);
}

@media only screen and (max-width: 680px) {

    .whyUsBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -38%;
        width: 25px;
        bottom: 35px;
        z-index: -1;
    }

    .mainBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-left: 25px;
        padding-right: 25px;
    }

    .subBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .circleOne {
        height: 65px;
        width: 65px;
        background: rgba(245, 203, 147, 0.463);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleTwo {
        height: 65px;
        width: 65px;
        background: rgba(138, 176, 177, 0.314);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .circleThree {
        height: 65px;
        width: 65px;
        background: rgba(255, 200, 186, 0.718);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .circleOneNumber {
        color: rgb(224, 173, 106);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
    }

    .circleTwoNumber {
        color: rgb(138, 176, 177);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
    }

    .circleThreeNumber {
        color: rgb(250, 131, 131);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
    }

    .circleTitle {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 16px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 40px;
    }

    .circleMessage {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: #2F2F2F;
        line-height: 20px;
    }

    .quote {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -320px;
        left: -37%;
    }

}