.full__content {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #F8F8F8;
  text-align: left;
  padding: 2% 5% 2% 5%;
  border: 1px solid #707070;
  border-radius: 5px;
  /* margin-bottom: var(--mb-3); */
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.level h2 {
  font-size: 1.5em;
  text-align: center;
}

.__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start;
  gap: 1em;
}

.level {
  flex: 0.9;
}

.forms {
  flex: 1.1;
}

.forms {
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  gap: 0.5em;
  margin-bottom: 1em;
}

.field {
  background: #fff;
  padding: 0.35em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #707070;
}

.text__area {
  border: 1px solid #707070;
  padding: 0.35em;
  width: 100%;
  margin-top: 1em;
  background: #fff;
  border-radius: 5px;
}

.select *::placeholder {
  color: red !important;
}

.button {
  width: 100% !important;
  background: #636363 !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 1em;
}

.button:hover {
  background: var(--first-color) !important;
  color: #fff !important;
}

.__new__creneaux {
  width: 100% !important;
  background: var(--first-color) !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 0.5em;
}

.__new__creneaux:hover {
  background: #636363 !important;
  color: #fff !important;
}

@media only screen and (max-width: 390px) {
  .full__content {
    max-width: 100%;
    padding: 4%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .forms {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .forms {
    flex: 1;
  }

  .__content {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 1em;
  }

  .level {
    flex: 1;
    width: 100%;
  }

}