.__topBlock {
  width: 100%;
  column-gap: 12px;
  height: calc(100% - 200px);
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.__topBlock>* {
  height: fit-content;
  font-size: 25px;
  font-family: "CartoonFont";
}

.__topBlock .__topBlockEntry {
  display: flex;
  width: 100%;
  column-gap: 12px;
  margin-top: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.__columnArrangement {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.__title {
  font-size: 2em;
  margin-left: 15px;
}

.__header {
  display: flex;
}

.convertedAmount {
  font-size: 25px;
  text-align: center;
  font-family: var(--title-font);
}

.boxTitle {
  color: var(--title-color);
  font-size: var(--h1-font-size);
  margin-left: 20px;
  margin-top: 10px;
  font-family: "CartoonFontBold";
  font-weight: 600;
}

.boxTitle:hover {
  color: #333;
}

.field {
  height: 35px !important;
  border-radius: 16px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
  font-size: 12px;
}