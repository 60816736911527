.title {
  font-size: 2em;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.main {
  position: relative;
  max-width: 800px;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-1);
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.help {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 5%;
  top: 60%;
  width: 200px;
  height: 60px;
  background: #707070;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
}

.help h4,
.help p {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #fff;
}

.__help__images {
  margin-top: 5px;
}

/* .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
   flex-wrap: wrap;
  background: #000;
} */

.row {
  display: grid;
  row-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  .row {
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;
  }
}

.__skillbar {
  min-width: 200px;
}

.__links {
  color: #707070;
}

.__select {
  width: 500px;
}

.__select div {
  position: relative;
  text-align: center;
  font-size: 2.7em;
  line-height: 0 !important;
  font-family: var(--title-font);
  text-transform: uppercase;
  border: 1px solid black !important;
}

.__select div span :focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

@media only screen and (max-width: 500px) {
  .__select div {
    width: 300px !important;
    font-size: 1.8em;
  }

  .iconArrowClass {
    width: 20px;
    font-size: 12px !important;
    top: -1px !important;
    right: 20px !important;
  }

  .header {
    margin-top: var(--mb-1);
  }

  .row {
    justify-content: center;
  }
}