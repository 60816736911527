@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CartoonFont";
  src: url("./assets/fonts/cartoon.woff2") format("woff2");
}

@font-face {
  font-family: "CartoonFontBold";
  src: url("./assets/fonts/cartoonBold.woff2") format("woff2");
}

.ant-steps-item-title:after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 100vw !important;
  height: 5px;
  background: #f0f0f0;
  content: "";
  border-radius: 10px;
}

.ant-segmented-item-selected:hover {
  color: #fff;
}

.ant-segmented-item {
  border-radius: 15px;
  color: #fff;
}

.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px -2px rgb(0 0 0 / 5%), 0 1px 4px -1px rgb(0 0 0 / 7%),
    0 0 1px 0 rgb(0 0 0 / 8%);
  color: #fff;
  background: #023334;
}

.ant-segmented-item-selected * svg {
  color: #eec13e;
  stroke: #eec13e;
  fill: #eec13e !important;
}

.ant-segmented-item:hover {
  border-radius: 15px;
  color: #faea89;
}

.ant-select-item-group {
  font-size: 20px;
  font-family: var(--title-font) !important;
  text-transform: uppercase;
}

//temps
.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0 !important;
}

.ant-notification-notice {
  border-radius: 10px !important;
}

@media screen and (max-width: 500) {}

@media (max-width: 768px) {
  .ant-drawer-body {
    padding: 6px;
  }
}

//custom

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  position: relative;
  display: block;
  padding: 0;
  padding-right: 12px;
}

.ant-modal-footer {
  border-top: 1px solid transparent !important;
}

@font-face {
  font-family: 'Margin DEMO';
  src: url('./assets/fonts/MarginDemo.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.ant-tabs-ink-bar {
  background: none;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 12px;
}

.ant-tabs,
.ant-tabs-nav,
.ant-tabs-nav-wrap {
  border-bottom: 1px solid #FFF;
}

.drawer .ant-drawer-content {
  background-color: transparent;
  background-clip: padding-box;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 55%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-list {
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: 95%;
}

.searchTeacher .ant-modal-close {
  right: auto;
  left: 16px;
}

.searchTeacher .ant-modal-content {
  border-radius: 20px !important;
  padding: 40px;
  background-color: #FFF;
  text-align: center;
  position: absolute;
  left: -25%;
  top: -250px;
  width: auto !important;
  z-index: 1;
}

.ant-list-split .ant-list-item {
  border-bottom: 0px solid #f0f0f0;
}

@media only screen and (max-width: 1050px) {
  .ant-result {
    padding: 0px 32px;
  }

  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.2em;
    line-height: 1.8;
    text-align: center;
  }

  .datePickerInput .ant-picker-input>input::placeholder {
    font-size: 0.8em;
  }

  .datePickerInput .ant-picker-input>input {
    position: relative;
    top: -2px;
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 55%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-list {
    margin-left: 0px !important;
    margin-right: 10px !important;
    width: 100%;
  }

  .searchTeacher .ant-modal-content {
    border-radius: 20px !important;
    padding: 20px;
    background-color: #FFF;
    text-align: center;
    position: relative;
    left: 0%;
    right: 0%;
    top: 0px;
    bottom: 0px;
    height: auto;
    width: auto !important;
    z-index: 1;
  }

  .searchField,
  .ant-input,
  .ant-input-borderless::placeholder {
    font-size: 1.1em !important;
  }

  .searchField,
  .ant-input,
  .ant-input-borderless {
    font-size: 1.1em !important;
  }
}

.custom-collapse .ant-collapse-header {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 16px !important;
  height: 50px !important;
  font-size: 1.2em;
  font-weight: 600;
}

.custom-collapse .ant-collapse-header .ant-collapse-arrow {
  order: 2;
}

.custom-notification {
  background-color: #85b2b5 !important;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  min-width: 350px;
}

.custom-notification-content {
  display: flex;
  align-items: center;
}

.custom-notification-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.custom-notification-text {
  color: white;
  font-size: 14px;
}

.custom-notification-text p {
  margin: 0;
  line-height: 1.5;
}

.custom-notification .ant-notification-close-x {
  color: white !important;
}

.ant-input-number-handler-wrap {
  display: block !important;
}

.ant-input-number-handler {
  display: block !important;
  visibility: visible !important;
}

.notificationImageBox {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
}
@primary-color: #80b1b2;