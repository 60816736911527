.quiz {
  margin: 1em 0;
}

.quiz__content {
  width: 100%;
  background: #fafafa;
  padding: 3% 5% 7% 5%;
}

.left .__avatarBoy {
  width: 100px;
}

.right .__iconTrophy {
  width: 70px;
}

.center img {
  width: 35px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.center {
  flex: 2;
  padding: 0 1%;
}

.center span {
  font-size: 1em;
}

.__title {
  width: 100%;
  max-width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.main {
  margin-top: 2em;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.main h3 {
  font-size: 1.2em;
  margin-left: 2%;
  color: var(--text-color);
  margin-bottom: var(--mb-3);
  /* #CBCACD */
}

.card {
  position: relative;
  width: 95%;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #cbcacd;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-3);
  padding: 0 3%;
}

.card input[type="radio"] + label {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card input[type="radio"] + label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 20px;
  border-bottom: 5px solid var(--text-color);
  border-left: 5px solid var(--text-color);
  top: 25%;
  left: 45%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

.card input[type="radio"]:checked + label {
  border: 1px solid var(--text-color);
}

.card input[type="radio"]:checked + label:after {
  opacity: 1;
  width: 35px;
}

.chk {
  display: none;
}

.__btn__back {
  max-width: 80px !important;
  min-width: 100px !important;
  background: #f64d5c !important;
  box-shadow: 3px 6px 1px -1px #f64d5bab !important;
}

.___result__btn {
  border-radius: 15px;
  height: 40px;
}

/*  */
.show__counter {
  display: flex;
  min-width: 200px;
  gap: 0.5em;
  font-size: 1.2em;
}

.danger {
  color: red;
}

.expired {
  position: relative;
  color: red;
  text-align: center;
  font-size: 1.4em;
  left: -0.8em;
  animation: clignote 1.7s linear infinite;
}

@keyframes clignote {
  50% {
    opacity: 0;
  }
}

.__sound {
  position: absolute;
  left: -100%;
  visibility: hidden;
  width: 100%;
}

.__drawer__button {
  min-width: 350px;
  height: 45px !important;
}

.__result__title {
  text-align: center;
  margin-top: var(--mb-1);
}

/*  */

.proposition {
  margin-left: 10px;
}

.cb {
  border: 1px solid #555;
  display: inline-block;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.cb:before,
.cb:after {
  content: "";
  display: block;
  position: absolute;
}

.cb:before {
  animation: eraseA 0.5s steps(1) forwards;
  background: url("https://s22.postimg.cc/fi4blx4gx/checkmark.png") 0 100%/1.2em
    1.35em no-repeat;
  bottom: 0.2em;
  left: 0.3em;
  width: 0;
  height: 1.35em;
}

.cb:after {
  animation: eraseB 0.5s linear forwards;
  background: linear-gradient(
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 50%
      )
      11.2em 0,
    linear-gradient(#c8646e, #c8646e) 11.2em 0,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      9.8em 0/0.3em 100%,
    repeating-linear-gradient(
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em
      )
      10.2em 0/0.5em 100%,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      10.8em 0/0.3em 100%,
    linear-gradient(
        rgba(255, 255, 255, 0) 35%,
        rgba(255, 255, 255, 0.6) 35%,
        rgba(255, 255, 255, 0.6) 65%,
        rgba(255, 255, 255, 0) 65%
      )
      9.7em 0,
    linear-gradient(#b4b4b4, #b4b4b4) 9.7em 0,
    linear-gradient(
        rgba(0, 0, 0, 0.1) 27%,
        rgba(0, 0, 0, 0) 27%,
        rgba(0, 0, 0, 0) 73%,
        rgba(0, 0, 0, 0.2) 73%
      )
      2em 0,
    linear-gradient(#f0d000, #f0d000) 2em 0,
    linear-gradient(-14deg, #1e1e1e 18%, rgba(30, 30, 30, 0) 20%) 0 0/0.5em
      0.5em,
    linear-gradient(14deg, rgba(30, 30, 30, 0) 80%, #1e1e1e 83%) 0 0.5em/0.5em
      0.5em,
    linear-gradient(-14deg, #d2a078 48%, rgba(210, 160, 120, 0) 50%) 0 0/2em
      0.5em,
    linear-gradient(14deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.1) 50%) 0 0.5em/2em
      0.5em,
    linear-gradient(14deg, rgba(210, 160, 120, 0) 48%, #d2a078 50%) 0 0.5em/2em
      0.5em;
  background-repeat: no-repeat;
  border-radius: 0 0.1em 0.1em 0;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0 0.25em;
  width: 12em;
  height: 1em;
}

.cb:checked {
  background-color: transparent;
}

.cb:checked:before {
  animation: drawA 0.5s linear forwards;
}

.cb:checked:after {
  animation: drawB 0.5s linear;
}

.cb:focus {
  outline: transparent;
}

.cb:focus + span {
  color: rgba(0, 0, 0, 0.75);
  outline: transparent;
}

.pristine:before,
.pristine:after {
  animation: none;
}

@keyframes drawA {
  from,
  5% {
    width: 0;
    height: 1.35em;
  }

  90%,
  to {
    width: 1.2em;
    height: 1.35em;
  }
}

@keyframes drawB {
  from {
    opacity: 0;
    transform: translate(0em, -0.5em) rotate(-50deg);
    visibility: visible;
  }

  5% {
    opacity: 1;
    transform: translate(0em, 0.3em) rotate(-60deg);
  }

  17% {
    transform: translate(0.25em, 0.8em) rotate(-65deg);
  }

  79% {
    transform: translate(1.1em, -0.14em) rotate(-63deg);
  }

  90% {
    opacity: 1;
    transform: translate(1.2em, -0.15em) rotate(-63deg);
  }

  to {
    opacity: 0;
    transform: translate(1.25em, -1.7em) rotate(-63deg);
  }
}

@keyframes eraseA {
  from {
    width: 1.2em;
    height: 1.35em;
  }

  10% {
    width: 1.2em;
    height: 1.08em;
  }

  27% {
    width: 1.2em;
    height: 0.81em;
  }

  36% {
    width: 1.2em;
    height: 0.7em;
  }

  45% {
    width: 1.2em;
    height: 0.55em;
  }

  55% {
    width: 1.2em;
    height: 0.35em;
  }

  80%,
  to {
    width: 1.2em;
    height: 0;
  }
}

@keyframes eraseB {
  from {
    opacity: 0;
    transform: translate(0.6em, -12.2em) rotate(90deg);
    visibility: visible;
  }

  10% {
    opacity: 1;
    transform: translate(1.2em, -12.05em) rotate(90deg);
  }

  20% {
    transform: translate(0.6em, -11.9em) rotate(90deg);
  }

  30% {
    transform: translate(1.2em, -11.75em) rotate(90deg);
  }

  40% {
    transform: translate(0.6em, -11.6em) rotate(90deg);
  }

  50% {
    transform: translate(1.2em, -11.45em) rotate(90deg);
  }

  60% {
    transform: translate(0.6em, -11.3em) rotate(90deg);
  }

  70% {
    transform: translate(1.2em, -11.15em) rotate(90deg);
  }

  80% {
    transform: translate(0.6em, -11em) rotate(90deg);
  }

  90% {
    opacity: 1;
    transform: translate(2em, -12.5em) rotate(100deg);
  }

  to {
    opacity: 0;
    transform: translate(2em, -12.5em) rotate(100deg);
  }
}

@media only screen and (max-width: 500px) {
  .quiz__content {
    padding: 3% 2%;
  }

  .left .__avatarBoy {
    position: relative;
    width: 50px;
    top: -2px !important;
  }

  .right .__iconTrophy {
    position: relative;
    width: 30px;
    top: -7px !important;
  }

  .show__counter {
    font-size: 1em;
  }

  .center img {
    width: 20px;
  }

  .main h3 {
    font-size: 1.1em;
    margin-left: 2%;
    color: var(--text-color);
  }

  .proposition {
    font-size: 12px;
  }

  .count__down {
    position: relative;
    left: -1.1em;
  }
}
