.mainContent {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: 1050px) {
    .mainContent {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 70px;
    }
}