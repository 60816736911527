.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 3em;
}

.row {
  width: 100%;
  display: flex;
  gap: 0.5em;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 4em;
}

.__drawer__body p {
  margin-bottom: 10px;
}

.__drawer__body {
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.__join__class {
  position: relative;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.__join__class input {
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
}

.__join__class input::placeholder {
  font-style: italic;
}

.__drawer__btn {
  margin-top: 2em;
  max-width: 300px;
}

.__close {
  position: absolute;
  left: 5%;
  top: 3%;
  cursor: pointer;
}

/* .__key {
  position: absolute;
  top: -18px;
} */

.__error {
  position: absolute;
  top: 43px;
  color: #ff0000;
}

.__result {
  text-align: center;
}

.__result h3 {
  font-size: var(--h2-font-size);
  color: var(--text-color);
}

@media only screen and (max-width: 500px) {
  .__join__class {
    width: 250px;
  }

  .right {
    display: none;
  }
}

/* New Css Styles */

.tabTitleBox {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.tabTitleBoxActive {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.informationBox {
  width: 100%;
  height: 480px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  overflow-y: scroll;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  background: #FFF;
}

.classesBox {
  width: 100%;
  height: 640px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #FFF;
}

.classeCard {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avatarNameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 300px;
  object-fit: contain;
}

.detailsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.timeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 30px;
}

.times {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.time {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.timeDescription {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.amountBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  color: #707070;
}

.priceBox {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
}

.meetButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.newButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4bb980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
  -moz-box-shadow: 5px 7px 1px -1px #4bb980;
  padding-left: 20px;
  padding-right: 20px;
}

.newButton:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.meetButton:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.emptyClass {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dateBox {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.horizontalLine {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.todayBox {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newClassBox {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.cardDisplay {
  display: flex;
  flex-direction: column;
}

.cardClassBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.joinClassButtonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}

@media only screen and (max-width: 1050px) {
  .headerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .titleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .titlePictureBox {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonBox {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4bb980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
    -moz-box-shadow: 5px 7px 1px -1px #4bb980;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .informationBox {
    height: 73vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FFF;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 16px !important;
  }

  .classesBox {
    width: 100%;
    height: 50vh;
    border: 1px solid #BFBCBC;
    border-radius: 18px;
    opacity: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #FFF;
  }

  .dateBox {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .horizontalLine {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .todayBox {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .emptyClass {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    font-family: Rubik;
    color: #B9B9B9;
    text-align: center;
  }

  .cardDisplay {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .singleCourse {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
  }

  .classeCard {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .historyClasseCard {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
  }

  .cardClassBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .avatarNameBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 100%;
    object-fit: contain;
  }

  .detailsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .historyDetailsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .timeBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
  }

  .times {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .time {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .timeDescription {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .amountBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    font-size: 0.7em;
    margin-top: 5px;
  }

  .priceBox {
    width: auto !important;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .historyPriceBox {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .meetButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .meetButton:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .__first_drawer {
    position: relative;
    width: 100%;
    padding: 0px;
  }

  .tabTitleBox {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }

  .tabTitleBoxActive {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }
}