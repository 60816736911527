/* New Css Styles */

.drawerBody {
    height: 100% !important;
    width: 34vw !important;
    background: #FFF !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 40px !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    overflow: auto;
    scrollbar-width: thin;
}

.drawerCouponBody {
    height: 100% !important;
    width: 34vw !important;
    background: #FAFAFA !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 40px !important;
    margin-left: 7% !important;
    margin-right: 7% !important;
    overflow: auto;
    scrollbar-width: thin;
}

.newCouponButton {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 14px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #669e9d !important;
    -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
    -moz-box-shadow: 5px 7px 1px -1px #669e9d;
    margin: 0px auto;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ticket {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #FFF7E5;
    border: 1px dashed #CEAF7A;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #B88F4B;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.ticket::before,
.ticket::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #CEAF7A;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.ticket::before {
    left: -10px;
}

.ticket::after {
    right: -10px;
}

.ticketSessions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #E6FCFF;
    border: 1px dashed #4BA8B9;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #4BA8B9;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.ticketSessions::before,
.ticketSessions::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #4BA8B9;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.ticketSessions::before {
    left: -10px;
}

.ticketSessions::after {
    right: -10px;
}

.ticketDiscount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #F2FFF8;
    border: 1px dashed #4BB980;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #4BB980;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.ticketDiscount::before,
.ticketDiscount::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #4BB980;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.ticketDiscount::before {
    left: -10px;
}

.ticketDiscount::after {
    right: -10px;
}

.ticketDisabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #FAFAFA;
    border: 1px dashed #AFAFAF;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #AFAFAF;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.ticketDisabled::before,
.ticketDisabled::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #AFAFAF;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.ticketDisabled::before {
    left: -10px;
}

.ticketDisabled::after {
    right: -10px;
}

.type {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}

.details {
    font-size: 14px;
}

.discount {
    font-size: 18px;
    font-weight: bold;
}


@media only screen and (max-width: 767px) {
    .drawerBody {
        height: 80vh !important;
        width: 95% !important;
        background: #FFF !important;
        box-shadow: 4px 8px 16px #80808029 !important;
        border: 1px solid #BFBCBC !important;
        border-radius: 18px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: flex-start !important;
        padding: 10px !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
        overflow: auto;
        scrollbar-width: thin;
    }

    .drawerCouponBody {
        height: 80vh !important;
        width: 95% !important;
        background: #FAFAFA !important;
        box-shadow: 4px 8px 16px #80808029 !important;
        border: 1px solid #BFBCBC !important;
        border-radius: 18px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: flex-start !important;
        padding: 20px !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
        overflow: auto;
        scrollbar-width: thin;
    }

    .newCouponButton {
        width: auto !important;
        height: 30px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 50px !important;
        font-size: 0.8em !important;
        text-transform: none;
        text-align: center;
        background: #80b1b2 !important;
        color: #fff !important;
        padding-top: 5px !important;
        font-weight: 400 !important;
        border: none !important;
        box-shadow: 3px 5px 1px -1px #669e9d !important;
        -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
        -moz-box-shadow: 5px 7px 1px -1px #669e9d;
        margin: 0px auto;
        padding-left: 20px !important;
        padding-right: 20px !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .type {
        font-size: 0.7em;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
    }

    .details {
        font-size: 0.7em;
    }

    .discount {
        font-size: 0.8em;
        font-weight: bold;
    }

    .drawerBody p {
        font-size: 0.8em !important;
    }

    .drawerCouponBody p {
        font-size: 0.8em !important;
    }
}