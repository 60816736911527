.cours {
  position: relative;
  margin: 4em 0;
  /* min-height: 70vh; */
}

.teacher__maa {
  position: absolute;
  width: 350px;
  height: 450px;
  top: -5%;
  left: 0;
  background: url("../../../../assets/images/teacher/teacherMaa.svg") no-repeat
    center center;
  background-size: contain;
  z-index: 1;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.select {
  height: 40px;
  width: 550px;
  border-radius: 10px;
  padding-top: 3px;
  text-align: left;
  margin-bottom: var(--mb-2);
  font-size: 0.9em;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.temps {
  width: 300px !important;
}

.button {
  width: 100% !important;
  height: 38px !important;
  max-width: unset !important;
  font-size: 1em !important;
  padding-top: 10px !important;
}
