.grid {
  display: grid;
  row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5em;
  }
}