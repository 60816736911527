.stylo {
  position: fixed;
  right: -1%;
  top: 30%;
  width: 62px;
  height: 330px;
  background: url('../assets/stylo.svg') no-repeat center center;
  background-size: contain;
  z-index: 1;
  display: none;
}

.__content {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 110%;
}

.__subContent {
  position: relative;
  width: 100%;
  height: 87.9%;
}

.____width {
  position: absolute;
  width: 54%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 1px;
  right: 0;
}

.__progrosss {
  position: relative;
  width: 37px;
  transition: all 1s ease-in-out;
  background: #FED53A;
}