.register__teacher {
  width: 100%;
  text-align: center;
}

.title {
  font-size: 3.8em;
}

.student__action {
  display: flex;
  align-items: center;
  margin-top: var(--mb-3);
  margin-bottom: var(--mb-3);
}

.student__action p {
  font-size: 1em;
}

.student__action p a {
  color: var(--text-color);
  text-decoration: underline;
}

.content__action {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100%;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;
}

.field {
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.field::placeholder {
  font-style: italic !important;
}

.button {
  width: 100%;
  height: 50px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.5em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.select {
  width: 33.33% !important;

}

.select div {
  height: 53px !important;
  border: 1px solid var(--text-color) !important;
}

.select div span {
  padding-top: 10px !important;
  text-align: left;
}

.left div {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.right div {
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.termes__legals {
  margin-top: var(--mb-4);
  float: left;
  text-align: left;
  color: #636363;
  font-size: 1em !important;
}

.info {
  margin-top: var(--mb-4);
}

.info span {
  text-align: left;
  color: #9D9D9D;
  font-style: italic;
}

.info p {
  text-align: center;
  color: var(--text-color);
  font-size: 1.1em !important;
}

.info a {
  color: var(--text-color);
  text-decoration: underline;
}