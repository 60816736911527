.home {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
}

.home__row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
}

.title {
  text-transform: uppercase;
  font-size: var(--biggest-font-size);
  text-align: center;
  font-weight: 600;
}

.__or {
  text-align: center;
  font-size: 1.4em;
  font-family: var(--title-font);
  text-transform: uppercase;
}

.__subtitle {
  text-align: center;
  color: #535353;
  transition: all 0.3s ease-out;
  font-family: var(--title-font);
  font-size: 1.5em;
  text-transform: uppercase;
  background: #fafafa;
  border-radius: 15px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.2em 0.5em;
}

.__subtitle:hover {
  color: var(--first-color);
}
