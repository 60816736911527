.select {
  height: 45px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid #707070;
  margin-right: 8px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.select div span {
  padding-top: 10px !important;
  text-align: left;
  font-size: 1.1em;
}

.easyQuiz_grid__V_qkS {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.__card__body,
.__card__header {
  text-align: center;
}

.__card__body h3 {
  font-size: 20px;
  color: #000;
  font-family: var(--title-font);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
}

.Card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 330px;
  padding: 7% 0;
  overflow: hidden;
  background-color: #fafafa;
  border-radius: 10px;
}

.__matieres {
  margin-top: 1.5em;
}

.__card__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.mainX {
  margin-top: 56px;
  overflow-y: auto;
  height: 750px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.apercuQuiz {
  font-size: 14px;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
}
