@import url(https://fonts.googleapis.com/css?family=Handlee);
.style_container__2rcdm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.style___body__1svXM {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.style_book__2LyR8 {
    --color: var(--first-color);
    --duration: 6.8s;
    width: 32px;
    height: 12px;
    position: relative;
    margin: 32px 0 0 0;
    zoom: 1.5;
}

.style_book__2LyR8 .style_inner__3atm- {
    width: 32px;
    height: 12px;
    position: relative;
    transform-origin: 2px 2px;
    transform: rotateZ(-90deg);
    animation: style_book__2LyR8 var(--duration) ease infinite;
}

.style_book__2LyR8 .style_inner__3atm- .style_left__1yGu5,
.style_book__2LyR8 .style_inner__3atm- .style_right__zyBld {
    width: 60px;
    height: 4px;
    top: 0;
    border-radius: 2px;
    background: var(--color);
    position: absolute;
}

.style_book__2LyR8 .style_inner__3atm- .style_left__1yGu5:before,
.style_book__2LyR8 .style_inner__3atm- .style_right__zyBld:before {
    content: "";
    width: 48px;
    height: 4px;
    border-radius: 2px;
    background: inherit;
    position: absolute;
    top: -10px;
    left: 6px;
}

.style_book__2LyR8 .style_inner__3atm- .style_left__1yGu5 {
    right: 28px;
    transform-origin: 58px 2px;
    transform: rotateZ(90deg);
    animation: style_left__1yGu5 var(--duration) ease infinite;
}

.style_book__2LyR8 .style_inner__3atm- .style_right__zyBld {
    left: 28px;
    transform-origin: 2px 2px;
    transform: rotateZ(-90deg);
    animation: style_right__zyBld var(--duration) ease infinite;
}

.style_book__2LyR8 .style_inner__3atm- .style_middle__nzqwV {
    width: 32px;
    height: 12px;
    border: 4px solid var(--color);
    border-top: 0;
    border-radius: 0 0 9px 9px;
    transform: translateY(2px);
}

.style_book__2LyR8 ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 50%;
    top: 0;
}

.style_book__2LyR8 ul li {
    height: 4px;
    border-radius: 2px;
    transform-origin: 100% 2px;
    width: 48px;
    right: 0;
    top: -10px;
    position: absolute;
    background: var(--color);
    transform: rotateZ(0deg) translateX(-18px);
    animation-duration: var(--duration);
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

.style_book__2LyR8 ul li:nth-child(0) {
    animation-name: style_page-0__17OPJ;
}

.style_book__2LyR8 ul li:nth-child(1) {
    animation-name: style_page-1__1g0BF;
}

.style_book__2LyR8 ul li:nth-child(2) {
    animation-name: style_page-2__2-KPZ;
}

.style_book__2LyR8 ul li:nth-child(3) {
    animation-name: style_page-3__1l5Ej;
}

.style_book__2LyR8 ul li:nth-child(4) {
    animation-name: style_page-4__3odRo;
}

.style_book__2LyR8 ul li:nth-child(5) {
    animation-name: style_page-5__2WD_4;
}

.style_book__2LyR8 ul li:nth-child(6) {
    animation-name: style_page-6__ehCLM;
}

.style_book__2LyR8 ul li:nth-child(7) {
    animation-name: style_page-7__1rU3r;
}

.style_book__2LyR8 ul li:nth-child(8) {
    animation-name: style_page-8__2cBWB;
}

.style_book__2LyR8 ul li:nth-child(9) {
    animation-name: style_page-9__1nzxW;
}

.style_book__2LyR8 ul li:nth-child(10) {
    animation-name: style_page-10__1jLUX;
}

.style_book__2LyR8 ul li:nth-child(11) {
    animation-name: style_page-11__2Q2ES;
}

.style_book__2LyR8 ul li:nth-child(12) {
    animation-name: style_page-12__Z-C9i;
}

.style_book__2LyR8 ul li:nth-child(13) {
    animation-name: style_page-13__212AM;
}

.style_book__2LyR8 ul li:nth-child(14) {
    animation-name: style_page-14__dAtzG;
}

.style_book__2LyR8 ul li:nth-child(15) {
    animation-name: style_page-15__S-ts8;
}

.style_book__2LyR8 ul li:nth-child(16) {
    animation-name: style_page-16__1779O;
}

.style_book__2LyR8 ul li:nth-child(17) {
    animation-name: style_page-17__mTXuN;
}

.style_book__2LyR8 ul li:nth-child(18) {
    animation-name: style_page-18__3tu-2;
}

@keyframes style_page-0__17OPJ {
    4% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    13%,
    54% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    63% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-1__1g0BF {
    5.86% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    14.74%,
    55.86% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    64.74% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-2__2-KPZ {
    7.72% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    16.48%,
    57.72% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    66.48% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-3__1l5Ej {
    9.58% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    18.22%,
    59.58% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    68.22% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-4__3odRo {
    11.44% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    19.96%,
    61.44% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    69.96% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-5__2WD_4 {
    13.3% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    21.7%,
    63.3% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    71.7% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-6__ehCLM {
    15.16% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    23.44%,
    65.16% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    73.44% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-7__1rU3r {
    17.02% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    25.18%,
    67.02% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    75.18% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-8__2cBWB {
    18.88% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    26.92%,
    68.88% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    76.92% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-9__1nzxW {
    20.74% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    28.66%,
    70.74% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    78.66% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-10__1jLUX {
    22.6% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    30.4%,
    72.6% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    80.4% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-11__2Q2ES {
    24.46% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    32.14%,
    74.46% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    82.14% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-12__Z-C9i {
    26.32% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    33.88%,
    76.32% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    83.88% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-13__212AM {
    28.18% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    35.62%,
    78.18% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    85.62% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-14__dAtzG {
    30.04% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    37.36%,
    80.04% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    87.36% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-15__S-ts8 {
    31.9% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    39.1%,
    81.9% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    89.1% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-16__1779O {
    33.76% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    40.84%,
    83.76% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    90.84% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-17__mTXuN {
    35.62% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    42.58%,
    85.62% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    92.58% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_page-18__3tu-2 {
    37.48% {
        transform: rotateZ(0deg) translateX(-18px);
    }

    44.32%,
    87.48% {
        transform: rotateZ(180deg) translateX(-18px);
    }

    94.32% {
        transform: rotateZ(0deg) translateX(-18px);
    }
}

@keyframes style_left__1yGu5 {
    4% {
        transform: rotateZ(90deg);
    }

    10%,
    40% {
        transform: rotateZ(0deg);
    }

    46%,
    54% {
        transform: rotateZ(90deg);
    }

    60%,
    90% {
        transform: rotateZ(0deg);
    }

    96% {
        transform: rotateZ(90deg);
    }
}

@keyframes style_right__zyBld {
    4% {
        transform: rotateZ(-90deg);
    }

    10%,
    40% {
        transform: rotateZ(0deg);
    }

    46%,
    54% {
        transform: rotateZ(-90deg);
    }

    60%,
    90% {
        transform: rotateZ(0deg);
    }

    96% {
        transform: rotateZ(-90deg);
    }
}

@keyframes style_book__2LyR8 {
    4% {
        transform: rotateZ(-90deg);
    }

    10%,
    40% {
        transform: rotateZ(0deg);
        transform-origin: 2px 2px;
    }

    40.01%,
    59.99% {
        transform-origin: 30px 2px;
    }

    46%,
    54% {
        transform: rotateZ(90deg);
    }

    60%,
    90% {
        transform: rotateZ(0deg);
        transform-origin: 2px 2px;
    }

    96% {
        transform: rotateZ(-90deg);
    }
}
.style_container__mLpjI {
  position: relative;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 2%;
}

@media only screen and (max-width: 767px) {
  .style_container__mLpjI {
    padding: 0 4%;
    margin: 20px auto;
  }
}
.button_button__1iI9H {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 32px !important;
  color: #fff !important;
  text-transform: uppercase;
  border: unset !important;
  border-radius: 50px !important;
  background: var(--first-color) !important;
  font-weight: 500 !important;
  font-size: var(--smaller-font-size) !important;
  box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.button_button__1iI9H:hover {
  transform: scale(1.03) !important;
}

.button_button__1iI9H:active {
  transform: scale(0.99) !important;
}

@media only screen and (max-width: 390px) {
  .button_button__1iI9H {
    min-width: 150px;
    height: 28px !important;
  }
}
.password_field__Hy6mV {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
}

.password_field__Hy6mV::placeholder {
    font-style: italic !important;
}

.password_nextButton__RUkOq {
    font-size: 18px;
    padding: 20px;
}

.password_shareButton__zqp42 {
    max-width: 200px;
    min-width: 200px;
}

@media only screen and (max-width: 767px) {
    .password_shareButton__zqp42 {
        max-width: 150px;
        min-width: 150px;
    }

    .password_field__Hy6mV {
        height: 40px !important;
        border-radius: 10px !important;
        border: 1px solid var(--text-color) !important;
        font-style: italic !important;
        color: #707070;
        font-size: 12px;
    }

    .password_field__Hy6mV::placeholder {
        font-size: 12px !important;
    }

    .password_nextButton__RUkOq {
        font-size: 12px;
        padding: 0px;
    }
}
.breadcrumbs_breadcrumbs__34pJ9 {
  width: 100%;
  height: 40px;
  background: #fafafa;
  line-height: 40px;
  margin-bottom: var(--mb-3);
}

.breadcrumbs_arrow__NnlRL {
  color: var(--first-color);
  font-weight: 600;
  font-size: 16px;
}

.breadcrumbs___item__3AB9g {
  cursor: pointer;
  transition: all 1s ease-in-out;
  font-weight: 500;
  color: var(--text-color);
}

.breadcrumbs___item__3AB9g *:hover {
  color: var(--first-color);
}

.breadcrumbs___bread__content__ljhbz {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 6.5px;
}

@media only screen and (max-width: 500px) {
  .breadcrumbs___listes__3MV4M {
    overflow-x: scroll;
  }

  .breadcrumbs___item__3AB9g {
    font-size: 12px;
  }

  .breadcrumbs_breadcrumbs__34pJ9 {
    margin-top: 75px;
    margin-left: 7px;
    margin-bottom: 0px;
  }
}
.style_about__JbgUr {
  width: 100%;
  margin-bottom: 80px;
  position: relative;
  top: 7px;
  z-index: 1;
}

.style_tabs__19Nnm {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.style_tab__1CJgo {
  width: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1%;
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  text-transform: uppercase;
  font-family: var(--title-font);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.style_tab__1CJgo img {
  position: relative;
  width: 30px;
  left: -2em;
}

.style_redirect__20xW2 .style_tab__1CJgo span {
  font-size: 1.3em;
  color: var(--text-color);
}

.style_tab__1CJgo:hover {
  background: var(--first-color);
}

.style_tab__1CJgo:hover span {
  color: #fff;
}

.style_active__1kdc2 {
  background: var(--first-color);
}

.style_tab__1CJgo.style_active__1kdc2 span {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .style_tabs__19Nnm {
    grid-gap: 0.5em;
    gap: 0.5em;
    overflow: scroll;
    background: white;
  }

  .style_tab__1CJgo span {
    font-size: 1em;
  }

  .style_tab__1CJgo img {
    width: 22px;
    left: -0.5em;
  }

}

/* New Css Styles */

.style_tabTitleBox__15QZg {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.style_tabTitleBoxActive__1HPtD {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.style_informationBox__3sfNl {
  background: #FFF;
  width: 100%;
  height: auto;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  z-index: 1;
}

@media only screen and (max-width: 1050px) {
  .style_tabTitleBox__15QZg {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }

  .style_tabTitleBoxActive__1HPtD {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }
}
.style_readMore__3puK4 {
    text-decoration: underline;
    font-size: 14px;
    color: #707070;
}

.style_readMore__3puK4:hover {
    color: #7FB1B2;
}
.style_card__3Wgtw {
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.style_card__3Wgtw h3 {
  color: var(--title-color);
  text-transform: none;
}

.style_description__3n5Gt {
  margin-top: var(--mb-2);
}

.style_footer__4Cczb {
  display: flex;
  justify-content: flex-end;
}

.style_footer__4Cczb button {
  border: none;
  margin-top: 10px;
  background: var(--first-color);
  padding: 6px 40px;
  border-radius: 20px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}

.style_aboutBox__1DLmx {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1050px) {
  .style_description__3n5Gt p {
    font-size: 0.75em !important;
  }

  .style_aboutBox__1DLmx {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .style_card__3Wgtw h3 {
    color: var(--title-color);
    text-transform: none;
    font-size: 0.75em !important;
  }

  .style_teacherName__y7fJS {
    color: var(--title-color);
    text-transform: capitalize;
    font-size: 1.2em !important;
  }
}
.style_card__37j7Z {
  height: auto;
  width: 100%;
  position: relative;
  background: #fff;
  margin-bottom: 1em;
  box-shadow: 2px 4px 8px #CECECE29;
  border: 1px solid #BFBCBC81;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
}

.style_avatarBox__3q6HA {
  height: 100%;
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.style_avatarPicture__2eqXO {
  height: 65px;
  width: 65px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.style_name__3Z-m6 {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.style_content__2W55m {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.style_avatar__3DDsg {
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
}

.style_student__2Ik5O {
  background: #F2F2F2;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  text-transform: uppercase;
}

.style_tagAvis__3nH28 {
  background: #F2F2F2;
  padding: 10px 15px;
  border-radius: 20px;
  color: var(--title-color);
}


.style_tagAvis__3nH28 span strong {
  font-weight: 500;
}

.style_description__sYQUN {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 15px;
}

@media only screen and (max-width: 1050px) {
  .style_card__37j7Z {
    height: auto;
    width: 100%;
    position: relative;
    background: #fff;
    margin-bottom: 1em;
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .style_avatarBox__3q6HA {
    height: 100%;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .style_avatarPicture__2eqXO {
    height: 45px;
    width: 45px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1.5px solid #FFF;
    border-radius: 50%;
  }

  .style_name__3Z-m6 {
    font-size: 1em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    margin-top: 5px;
  }

  .style_content__2W55m {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .style_avatar__3DDsg {
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
  }

  .style_student__2Ik5O {
    background: #F2F2F2;
    display: inline-block;
    padding: 5px 15px;
    border-radius: 20px;
    text-transform: uppercase;
  }

  .style_tagAvis__3nH28 {
    background: #F2F2F2;
    padding: 10px 15px;
    border-radius: 20px;
    color: var(--title-color);
  }

  .style_tagAvis__3nH28 span strong {
    font-weight: 500;
  }

  .style_description__sYQUN {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }
}
.style_card__3jgBh {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.style_titleBox__tCr6M h3 {
  color: var(--title-color);
  font-weight: 400;
}

.style_tagAvis__F0R_z {
  background: #FFF;
  box-shadow: 2px 4px 8px #CECECE29;
  border: 1px solid #BFBCBC81;
  padding: 5px 15px;
  border-radius: 10px;
  color: var(--title-color);
  margin-left: 10px;
}


.style_tagAvis__F0R_z span strong {
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .style_titleBox__tCr6M h3 {
    font-size: 13px;
  }

  .style_tagAvis__F0R_z {
    padding: 5px 10px;
    font-size: 12px;
  }

  .style_tagAvis__F0R_z img {
    width: 15px;
  }

  .style_btn__3UWht {
    font-size: 10px;
    padding: 1px 8px;
    display: none !important;
  }
}

/* New Css Styles */

.style_titleBox__tCr6M {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_content__1UKSc {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 40px;
}

.style_emptyContent__1XFc_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
  width: 100%;
}

@media only screen and (max-width: 1050px) {
  .style_content__1UKSc {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    margin-top: 20px;
  }
}
.style_card__content__2ex1J {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 10px;

}

.style_card__content__2ex1J h3 {
  color: var(--title-color);
  text-transform: none;
  margin-bottom: var(--mb-1);
}

.style_row__Mpy6X {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  grid-gap: 1em;
  gap: 1em;
}

.style_card__2AAjc {
  width: 240px;
  height: 250px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  padding-top: 2em;
  padding-bottom: var(--mb-4);
  background: url(/static/media/card.b30b4ea7.svg) center center no-repeat;
  background-size: contain;
}

.style_card__2AAjc {
  font-size: var(--h3-font-size);
  color: #707070;
  font-weight: 500;
}

.style_card__2AAjc img {
  margin: auto;
}

.style_type__content__2pYSk {
  display: flex;
  margin-top: 20px;
}

.style_cours__number__3k9tt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
}

.style_cours__number__3k9tt span {
  font-size: 3em;
  line-height: 30px;
}


.style_card__2AAjc .style_button__3UC3M {
  max-width: 170px;
  min-width: 170px;
  height: 30px !important;
  font-size: 10px !important;
}

@media only screen and (max-width: 768px) {
  .style_row__Mpy6X {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 1050px) {
  .style_card__content__2ex1J h3 {
    color: var(--title-color);
    text-transform: none;
    margin-bottom: var(--mb-1);
    font-size: 1em;
  }

  .style_card__content__2ex1J p {
    font-size: 0.8em;
  }
}
.matiers_matiers__1E-_R {
  width: auto;
  height: 30px;
  background: #EBEAEA;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
  margin: var(--mb-1);
  padding-left: 0px;
  padding-right: 0px;
}

.matiers_center__3r4O0 {
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.matiers_left__2Zvb3 {
  margin-left: 10px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.matiers_right__2CjQ- {
  margin-left: 0px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .matiers_matiers__1E-_R {
    width: auto;
    height: 20px;
    background: #EBEAEA;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 8px;
    color: var(--text-color);
    margin: var(--mb-1);
    padding-left: 0px;
    padding-right: 0px;
  }

  .matiers_left__2Zvb3 {
    margin-left: 5px;
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .matiers_right__2CjQ- {
    margin-left: 0px;
    margin-right: 5px;
  }
}
.style_card__17wQ2 {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #fafafa;
  margin-bottom: var(--mb-2);
  box-shadow: 3px 4px 0px 3px #cfd0d2;
}

.style_card__17wQ2:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.style_card__17wQ2::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.style_container__14OnD {
  width: 100%;
  min-height: 240px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-2);
  padding-left: var(--mb-2);
  padding-bottom: var(--mb-2);
}

.style_header__1bINs {
  margin-bottom: var(--mb-2);
  display: flex;
  justify-content: space-between;
}

.style_user__infos__6FYy7 h3 {
  text-transform: uppercase;
}

.style_body__vW28m {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mb-1);
}

.style_button__3_eEE {
  padding: 5px auto;
  border: 1px dashed #707070;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
}

.style_footer__1Kybt h4:hover {
  color: var(--first-color);
  cursor: pointer;
}

.style_cercle__1vCaF {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
}

@media only screen and (max-width: 1050px) {
  .style_user__infos__6FYy7 h3 {
    text-transform: uppercase;
    font-size: 0.8em;
  }

  .style_user__infos__6FYy7 span {
    font-size: 0.75em;
  }

  .style_body__vW28m {
    font-size: 0.75em !important;
  }

  .style_footer__1Kybt h4 {
    font-size: 0.75em !important;
  }

  .style_footer__1Kybt span {
    font-size: 0.75em !important;
    position: relative;
    top: -5px;
  }
}
.style_card__content__2UXR6 {
  width: 100%;
  min-height: 400px;
  padding: 10px;
  border-radius: 10px;
}

.style_card__content__2UXR6 h3 {
  color: var(--title-color);
  text-transform: none;
  margin-bottom: var(--mb-1);
}

.style_grid__jGm0X {
  display: grid;
  grid-row-gap: 0.8rem;
  row-gap: 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 10px;
}

@media only screen and (min-width: 768px) {
  .style_grid__jGm0X {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.3em;
    gap: 1.3em;
  }
}

@media only screen and (min-width: 1050px) {
  .style_card__content__2UXR6 h3 {
    color: var(--title-color);
    text-transform: none;
    margin-bottom: var(--mb-1);
    font-size: 1em;
  }
}
.style_header__2GyUC {
  width: 100%;
  height: auto;
  margin-top: var(--mb-1);
  /* padding: 2%; */
  margin-bottom: var(--mb-3);
}

.style_content__3od0h,
.style___leftt__EdJs8 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.style___leftt__EdJs8 {
  width: 75%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
}

.style_avatar__pDSiD {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 3px;
}

.style_avatar__pDSiD img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.style_infos__2F3tu h1 {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 1.7em;
}

.style___content__lang__8tBJZ {
  display: inline-block;
  padding: 5px 20px;
  background: #F8F8F8;
  border-radius: 20px;
}

.style___content__lang__row__qf_n6 {
  width: 100%;
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  align-items: center;
  grid-gap: 0.6em;
  gap: 0.6em;
  white-space: nowrap;
}

.style_infos__content__top__1W03S {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  margin-bottom: 2px;
}

.style_matiers__2PkDY {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 0.3em;
  gap: 0.3em;
}

.style___tag__topic__1up6d {
  background: #D5D5D5;
  display: flex;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

/*=====  __right ===== */

.style___right__3Gn40 {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1%;
}

.style___right__content__2Q65a {
  text-align: center;
}

.style_button__2aMim {
  display: block;
  border: none;
  margin-top: 10px;
  background: var(--first-color);
  padding: 6px 15px;
  border-radius: 20px;
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}

.style_editProfileButton__oZ_EW {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background: white !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
  font-size: 13px;
  color: var(--first-color);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--first-color) !important;
}

.style_editProfileButton__oZ_EW:hover {
  background: var(--first-color);
  transform: scale(1.03) !important;
  color: white;
}

.style_sponsorButton__3e3QR {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background: white !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
  font-size: 13px;
  color: var(--first-color);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--first-color) !important;
  margin-left: 10px;
}

.style_sponsorButton__3e3QR:hover {
  background: var(--first-color);
  transform: scale(1.03) !important;
  color: white;
}

@media only screen and (max-width: 768px) {
  .style_infos__2F3tu h1 {
    font-size: 1.2em;
  }

  .style_avatar__pDSiD {
    width: 75px;
    height: 75px;
  }

  .style_header__2GyUC {
    margin-bottom: var(--mb-3);
  }

  .style_content__3od0h {
    flex-direction: column;
  }

  .style_button__2aMim {
    font-size: 10px;
  }

  .style___leftt__EdJs8 {
    width: 100%;
  }

  .style___right__3Gn40 {
    width: 100%;
  }

  .style___content__lang__8tBJZ {

    padding: 5px 10px;
    font-size: 12px;
  }

  .style_row__btn__2fqFj {
    display: flex;
    grid-gap: 0.2em;
    gap: 0.2em;
  }

  .style___tag__topic__1up6d {
    font-size: 11px;
    padding: 5px 10px;
  }

  .style_infos__content__footer__1oUiT {
    font-size: 12px;
  }

  .style_nameContent__2guwj {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .style_topButtons__1leIu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px;
    padding: 0px;
    position: relative;
    left: -10px;
  }

  .style_infos__content__top__1W03S {
    max-width: 300px;
    display: flex;
    grid-gap: 1em;
    gap: 1em;
    margin-bottom: 2px;
    overflow: scroll;
  }

}

.style_nameContent__2guwj {
  margin-bottom: 10px;
  display: flex;
  align-content: center;
  text-align: center;
}

.style_topButtons__1leIu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.style_lang__content__35mQl span:last-child {
  display: none;
}

.style_follow__mgLBm img {
  position: relative;
  top: -2px;
  margin-left: 10px;
  cursor: pointer;
}

/* New Css styles */

.style_headerBox__2rusz {
  height: 235px;
  min-height: 200px;
  width: 100%;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 38px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
  position: relative;
  top: 20px;
}

.style_lineSeparator__3UP2A {
  height: 1px;
  width: 92%;
  margin: 25px auto;
  background: #D8D8D8;
  position: relative;
  top: 20px;
}

.style_detailsBox__2-x9j {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_avatarBox__g6c02 {
  height: 100%;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.style_nameBox__931_c {
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
}

.style_avatarPicture__jhHrS {
  height: 85px;
  width: 85px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.style_nameDetails__3drj3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.style_nameFollowBox__1UymL {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.style_buttonsRow__1HENG {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.style_name__12iIy {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  color: #8AB0B1;
}

.style_rateLanguages__3dlAl {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.style_title__341CO {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #393E3E;
  margin-top: 5px;
}

.style_levels__1Ldva {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #7FB1B2;
  margin-bottom: 10px;
}

.style_levels__1Ldva div:last-child {
  display: none;
}

.style_newMessageButton__2IU73 {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.style_newMessageButton__2IU73:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1;
  color: #fff;
}

.style_newProfileButton__3Q30O {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.style_newProfileButton__3Q30O:hover {
  transform: scale(1.03) !important;
  background-color: #60CD95;
  color: #fff;
}

.style_coursesPicesBox__3JJ6j {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.style_coursesBox__2UPyn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto !important;
  max-width: 300px !important;
  overflow: scroll;
  scrollbar-width: thin;
}

.style_courses__k7p_H {
  width: auto;
  height: 35px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-left: 7px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
  grid-gap: 7px;
  gap: 7px;
}

.style_priceBox__3pJri {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.style_priceTitle__18mp_ {
  font-size: 8px;
  font-family: "Poppins";
  font-weight: 600;
  color: #868686;
}

.style_price__mbBMv {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  color: #7FB1B2;
}

.style_newInstaButton__2iMXY {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
}

.style_newInstaButton__2iMXY:hover {
  transform: scale(1.03) !important;
  background-color: #F56666 !important;
  color: #fff;
}

.style_newPlanningButton__2L1jm {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
}

.style_newPlanningButton__2L1jm:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1 !important;
  color: #fff;
}

.style_reportButton__aTQDl {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #F56666 !important;
  font-weight: 600 !important;
  border: 1px solid #F56666 !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
  visibility: hidden;
}

.style_reportButton__aTQDl:hover {
  transform: scale(1.03) !important;
  background-color: #FFC8BA !important;
  color: #fff;
}

.style_separator__1k-6h {
  height: 90%;
  width: 1px;
  background: #707070;
  margin-left: 10px;
  margin-right: 0px;
}

.style_followButton__3C_S7 {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #707070 !important;
  font-weight: 400 !important;
  border: 1px solid #FFF !important;
  box-shadow: 1px 3px 1px -1px #FFF !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFF;
  -moz-box-shadow: 2px 4px 1px -1px #FFF;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 10px;
  cursor: pointer;
}

.style_followButton__3C_S7:hover {
  transform: scale(1.03) !important;
  background-color: #FFF !important;
  color: #8AB0B1 !important;
}

.style_spinLoading__2f-bM {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 140px;
  text-align: center;
}

@media only screen and (max-width: 1050px) {
  .style_headerBox__2rusz {
    height: auto;
    min-height: auto;
    width: 100%;
    background: #FFF;
    box-shadow: 4px 8px 18px #98989829;
    border: 1px solid #DFDDDD;
    border-radius: 32px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    position: relative;
    top: 20px;
  }

  .style_lineSeparator__3UP2A {
    height: 1px;
    width: 92%;
    margin: 25px auto;
    background: #D8D8D8;
    position: relative;
    top: 20px;
  }

  .style_detailsBox__2-x9j {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .style_avatarBox__g6c02 {
    height: 100%;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .style_nameBox__931_c {
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 0px;
  }

  .style_avatarPicture__jhHrS {
    height: 85px;
    width: 85px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1.5px solid #FFF;
    border-radius: 50%;
  }

  .style_nameDetails__3drj3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .style_nameFollowBox__1UymL {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .style_buttonsRow__1HENG {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    padding: 0px;
  }

  .style_name__12iIy {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
  }

  .style_rateLanguages__3dlAl {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .style_title__341CO {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #393E3E;
    margin-top: 5px;
  }

  .style_levels__1Ldva {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 600;
    color: #7FB1B2;
    margin-bottom: 10px;
  }

  .style_levels__1Ldva span:last-child {
    display: none;
  }

  .style_newMessageButton__2IU73 {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .style_newMessageButton__2IU73:hover {
    transform: scale(1.03) !important;
    background-color: #8AB0B1;
    color: #fff;
  }

  .style_newProfileButton__3Q30O {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .style_newProfileButton__3Q30O:hover {
    transform: scale(1.03) !important;
    background-color: #60CD95;
    color: #fff;
  }

  .style_coursesPicesBox__3JJ6j {
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  .style_coursesBox__2UPyn {
    width: 250px !important;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: scroll;
    scrollbar-width: thin;
  }

  .style_courses__k7p_H {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-left: 7px;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    grid-gap: 7px;
    gap: 7px;
  }

  .style_courses__k7p_H span {
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .style_priceBox__3pJri {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .style_priceTitle__18mp_ {
    font-size: 0.65em;
    font-family: "Poppins";
    font-weight: 600;
    color: #868686;
  }

  .style_price__mbBMv {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 700;
    color: #7FB1B2;
  }

  .style_newInstaButton__2iMXY {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 10px;
    cursor: pointer;
  }

  .style_newInstaButton__2iMXY:hover {
    transform: scale(1.03) !important;
    background-color: #F56666 !important;
    color: #fff;
  }

  .style_newPlanningButton__2L1jm {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 10px;
    cursor: pointer;
  }

  .style_newPlanningButton__2L1jm:hover {
    transform: scale(1.03) !important;
    background-color: #8AB0B1 !important;
    color: #fff;
  }

  .style_reportButton__aTQDl {
    width: auto !important;
    height: 25px !important;
    display: none !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #F56666 !important;
    font-weight: 600 !important;
    border: 1px solid #F56666 !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 10px;
    cursor: pointer;
    visibility: hidden;
  }

  .style_reportButton__aTQDl:hover {
    transform: scale(1.03) !important;
    background-color: #FFC8BA !important;
    color: #fff;
  }

  .style_separator__1k-6h {
    height: 90%;
    width: 1px;
    background: #707070;
    margin-left: 10px;
    margin-right: 0px;
    display: none;
  }

  .style_followButton__3C_S7 {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #707070 !important;
    font-weight: 400 !important;
    border: 1px solid #8AB0B1 !important;
    box-shadow: 1px 3px 1px -1px #FFF !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFF;
    -moz-box-shadow: 2px 4px 1px -1px #FFF;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .style_followButton__3C_S7:hover {
    transform: scale(1.03) !important;
    background-color: #FFF !important;
    color: #8AB0B1 !important;
  }

  .style_spinLoading__2f-bM {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 140px;
    text-align: center;
  }
}
.subtitle_subtitle__2_p37 {
  font-family: "CartoonFont";
  font-size: var(--h1-font-size);
  color: var(--title-color);
  text-transform: uppercase;
}
.title_title__3HB_G {
  font-family: "CartoonFont";
  font-size: var(--biggest-font-size);
  color: var(--title-color);
  text-transform: uppercase;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .title_title__3HB_G {
    font-size: 1.7em;
  }
}

.card_card__2-n0- {
  position: relative;
  /* display: inline-block; */
  /* width: calc(100% * (1 / 4) - 10px - 1px); */
  /* width: 100%; */
  height: 260px;
  /* max-width: 25%; */
  flex-grow: 1;
  background: url(/static/media/card.b30b4ea7.svg) center center no-repeat;
  background-size: contain;
  margin-bottom: 1em;
  padding-top: 2em;
  transform-origin: 0 0;
  transition: all 0.3s;
}

/* @media only screen and (max-width: 500px) {
  .card {
    max-width: initial;
  }
} */
.square {
  opacity: 0;
  transform-origin: 0 0;
}
.cardHistory_content__2X2Kt {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 2px;
}

.cardHistory_round__user__Bqu4v {
  width: 65px;
  height: 65px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardHistory_round__user__Bqu4v img {
  margin: auto;
}

.cardHistory_content__2X2Kt h3 {
  width: 100%;
  max-width: 130px;
  font-size: 14px;
  text-transform: uppercase;
  color: #333;
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-1);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.cardHistory___start__2J9jy {
  position: absolute;
  margin: auto;
  width: 50px;
  height: 20px;
  background: #fff;
  border: 1px dashed #707070;
  display: flex;
  top: -5%;
  right: 12%;
  border-radius: 15px;
}

.cardHistory___start__2J9jy span {
  margin: auto;
  font-size: 11px;
}

.cardHistory___button__2XzZF {
  max-width: 150px;
  min-width: 150px;
  height: 30px !important;
  font-size: 10px !important;
  margin-top: 5px;
}

@media only screen and (max-width: 500px) {
  .cardHistory___start__2J9jy {
    top: -5%;
    right: 22%;
  }
}

.row_content__2ETlW {
  height: 100%;
  width: 100%;
}

.row_grid__1oblX {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
}

@media only screen and (min-width: 768px) {
}

.style_calendar__2plvm {
  width: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #fafafa;
}

.style_weekly__3eMQ8 {
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 2% 4%;
  margin-bottom: 2em;
  border: 1px solid #ccc;
}

.style_date__3Sh1C {
  width: auto !important;
  height: 45px !important;
  border-radius: 12px !important;
  text-align: center !important;
  border: 1px solid #ccc !important;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  transition: 0.3s !important;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  font-size: 13px !important;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  text-transform: capitalize;
  background: #FFF;
}

.style_date__3Sh1C:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.style_date__3Sh1C.style_active__2fpsU {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.style_creneaux__1-8o- {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.style_creneau__LVduQ {
  margin-bottom: 0.5em;
}

.style_range__1LA_t {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.style_range__1LA_t span {
  margin: auto;
}

.style_matiere__1jtC4 {
  font-size: 1em;
}

.style_bar__1JDkp {
  margin: 0 0.5em;
}

.style_angleIcon__3JLrB path {
  fill: #fe772e;
}

.style_slider__1u0H->svg path {
  fill: #fe772e;
}

.style_card__9FQ0N {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .style_content__action__reservation__Vp4bS {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.style_myReservation__268i1 {
  margin-bottom: 1em;
}

.style_card___2OW_R {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}

/* New Css Style */

.style_drawerBody__dUdo3 {
  height: 100% !important;
  width: 34vw !important;
  background: #FFF !important;
  box-shadow: 4px 8px 16px #80808029 !important;
  border: 1px solid #BFBCBC !important;
  border-radius: 24px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 40px !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  overflow: auto;
  scrollbar-width: thin;
}

.style_drawerTitle__1Ef3R {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_calendarBox__35mYH {
  height: 800px;
  width: 100%;
  overflow: scroll;
  border: 1px solid #707070;
  padding: 2%;
  border-radius: 30px 0px 0px 30px;
  -webkit-border-radius: 30px 0px 0px 30px;
  -moz-border-radius: 30px 0px 0px 30px;
  -ms-border-radius: 30px 0px 0px 30px;
  -o-border-radius: 30px 0px 0px 30px;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-left: 40px;
}

.style_mainContainer__1RgYy {
  height: 100%;
  width: 95%;
  overflow: auto;
  border: 1px solid #707070;
  padding: 2%;
  border-radius: 30px 0px 0px 30px;
  -webkit-border-radius: 30px 0px 0px 30px;
  -moz-border-radius: 30px 0px 0px 30px;
  -ms-border-radius: 30px 0px 0px 30px;
  -o-border-radius: 30px 0px 0px 30px;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-left: 40px;
}

.style_headerBox__1oZ36 {
  height: 380px;
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: -25px;
  right: 0;
  z-index: 77;
  background: #FAFAFA;
}

.style_headerBoxFull__25HXK {
  height: 220px !important;
  width: 100% !important;
  opacity: 1;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: -25px;
  right: 0;
  z-index: 77;
  background: #FAFAFA;
}

.style_headerBoxFullCouponExist__1csXj {
  height: 320px !important;
  width: 100% !important;
  opacity: 1;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: -25px;
  right: 0;
  z-index: 77;
  background: #FAFAFA;
}

.style_lineSeparator__1bBky {
  height: 1px;
  width: 92%;
  margin: 7px auto;
  background: #D8D8D8;
}

.style_detailsBox__1ZEdH {
  height: 100%;
  width: 37% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 24px;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 25px;
  padding-right: 40px;
}

.style_detailsBoxFull__2ksc- {
  height: 70px !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 24px;
  padding: 15px;
  position: absolute;
  left: 0;
  top: 7px;
  right: 0;
  z-index: 77;
}

.style_descriptionPriceBox__3rN_m {
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 24px;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
  margin-left: 7px;
}

.style_avatarBox__3GGzd {
  height: 100%;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15%;
}

.style_nameBox__XHyhq {
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
}

.style_avatarPicture__3cI5c {
  height: 85px;
  width: 85px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.style_nameDetails__28cMs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}

.style_buttonsRow__35hb2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.style_name__1eido {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  color: #8AB0B1;
}

.style_rateLanguages__3un-H {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.style_title__UhnaI {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #393E3E;
  margin-top: 5px;
}

.style_levels__2PbxU {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #7FB1B2;
  margin-bottom: 10px;
}

.style_levels__2PbxU div:last-child {
  display: none;
}

.style_newMessageButton__21hU0 {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.style_newMessageButton__21hU0:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1;
  color: #fff;
}

.style_newProfileButton__2AT2B {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.style_newMoveButton__2Dfe3 {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.style_newProfileButton__2AT2B:hover {
  transform: scale(1.03) !important;
  background-color: #60CD95;
  color: #fff;
}

.style_newMoveButton__2Dfe3:hover {
  transform: scale(1.03) !important;
  background-color: #80b1b2;
  color: #fff;
}

.style_descriptionBox__w_xzp {
  height: 100%;
  width: 60% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.style_coursesPicesBox__1jon8 {
  height: 100% !important;
  width: 40% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.style_coursesBox__3W6lJ {
  width: auto !important;
  max-width: 100% !important;
  display: flex;
  flex-direction: row;
  /* align-items: flex-start;
  justify-content: flex-start; */
  overflow: scroll;
  scrollbar-width: thin;
}

.style_coursesBoxSticky__12B1- {
  width: auto !important;
  max-width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  /* align-items: flex-end !important;
  justify-content: flex-end !important; */
  overflow-y: auto !important;
  scrollbar-width: thin;
  margin-left: 20px;
  padding-right: 10px;
}

.style_courses__1U4WB {
  width: auto;
  height: 35px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
  grid-gap: 7px;
  gap: 7px;
}

.style_priceBox__1PLte {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.style_priceTitle__3GyEN {
  font-size: 8px;
  font-family: "Poppins";
  font-weight: 600;
  color: #868686;
}

.style_price__r141i {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  color: #7FB1B2;
}

.style_textStats__XCAi6 {
  min-width: 150px;
  text-align: left;
  text-decoration: underline;
}

.style_docsBox__2zMRY {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.style_description__31z0L {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
}

.style_weekBox__3hX2g {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  background: #FFF;
}

.style_weekBoxSticky__3zdZz {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 85px;
  right: 0;
  z-index: 77;
}

.style_weekBoxStickyCouponExist__3Ze3G {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 150px;
  right: 0;
  z-index: 77;
}

.style_daysBox__2nOMJ {
  width: 100%;
  height: 470px;
  background: transparent;
  margin-top: 30px;
}

.style_daysBoxCouponExist__3EvL7 {
  width: 100%;
  height: 400px;
  background: transparent;
  margin-top: 70px;
}

.style_dayBox__39KPH {
  width: 100%;
  height: auto;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 23px;
  padding: 30px;
  margin-bottom: 7px;
}

.style_calendarDay__1pIc6 {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
  text-transform: capitalize;
}

.style_reservationBox__SnHNg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.style_reservationMessage__3_hL8 {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  margin-right: 20px;
}

.style_reservationMessageCoupon__znm8h {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  margin-right: 20px;
  text-decoration: underline !important;
}

.style_dayTimeBox__2I4_x {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.style_momentBox__FTKiF {
  height: auto;
  width: 10% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #868686;
}

.style_momentBoxCoupon__26V24 {
  height: auto;
  width: 10% !important;
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #868686;
}

.style_timeBox__1Hh_V {
  height: auto;
  width: auto;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.style_timeBoxCoupon__Wwd-A {
  height: auto;
  width: 85% !important;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.style_timeButton__3bvDY {
  width: 80px;
  height: 34px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 8px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.style_timeButtonDisabled__3te4N {
  width: 80px;
  height: 34px;
  background: #E5E5E5;
  border: 1px solid #707070;
  border-radius: 8px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.style_timeButtonActive__1MZJr {
  width: 80px;
  height: 34px;
  background: #7FB1B2;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 600;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.style_timeButtonNextTime__jHDBl {
  font-size: 10px;
  font-family: Rubik;
  font-weight: 400;
  color: #707070;
}

.style_timeButtonNextTimeActive__15wOS {
  font-size: 10px;
  font-family: Rubik;
  font-weight: 400;
  color: #FFF;
}

.style_ribbon__xi0gL {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 0px;
  height: 20px;
  background: #60CD95;
}

.style_ribbon__xi0gL:after {
  height: 20px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent white;
}

.style_credit__1U-XW {
  font-size: 8px;
  position: relative;
  bottom: 4px;
}

.style_confirmButtonBox__3QWpY {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

.style_confirmButtonBoxSticky__YBiBZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  width: 100%;
  position: absolute;
  top: 235px;
}

.style_couponBox__s4dXV {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  border: 1px solid #707070;
  border-radius: 12px;
  height: 34px;
  width: auto;
}

.style_couponInput__46oC1 {
  font-size: 12px !important;
}

.style_couponInput__46oC1::placeholder {
  font-size: 12px !important;
}

.style_couponButton__rYEqv {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 12px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 2%;
  margin-bottom: 2px !important;
}

.style_cancelcouponButton__2lMwP {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 12px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 14%;
  margin-bottom: 2px !important;
}

.style_confirmButton__2KljZ {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.style_reservationAddedBox__zng4y {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 13px !important;
  color: #60CD95 !important;
  font-weight: 500 !important;
  font-family: "Poppins";
}

.style_verticalSeparator__3sc1B {
  height: 25px;
  width: 1px;
  background: #707070;
  margin-left: 10px;
  margin-right: 5px;
}

.style_headerFixed__3VVSt {
  height: 180px !important;
  width: 100% !important;
  background: blueviolet;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  /* display: flex !important; */
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0px;
  margin-bottom: 0px;
  position: absolute;
  top: -10px !important;
  left: -20px !important;
  right: -20px !important;
  z-index: 77;
  display: none;
}

.style_smallHeader__pyfJ1 {
  height: 70px !important;
  width: 100% !important;
  background: #FFF;
  border-radius: 16px;
  border: 1px solid #DFDDDD;
  /* display: flex !important; */
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 10px;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 77;
  display: none;
}

.style_smallHeaderleft__bQpp- {
  width: 50% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.style_smallHeaderRight__1uvs_ {
  width: 50% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.style_smallHeaderNameBox__GO8FL {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-left: 10px;
}

.style_avatarImage__2aEac {
  height: 40px;
  width: 40px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.style_couponSection__3HEP- {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  height: 50px !important;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px !important;
  color: #707070;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.style_BackCalendarBox__kgI4t {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 20%;
  margin-top: 0px;
  position: absolute;
  left: 0;
}

.style_BackCalendarBoxSticky__dIbV7 {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 20%;
  position: absolute;
  left: 0;
  top: 7px !important;
}

.style_couponSectionSticky__2qdpz {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  margin-top: 0px;
  color: #707070;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  position: absolute;
  top: 90px !important;
}

.style_couponSectionBox__1Ns5l {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 30% !important;
  background: "#F1FFF8";
  border: 0.5px dashed #4BB980;
  border-radius: 12px;
  padding-left: 20px;
}

.style_couponSectionMessage__3P6AP {
  width: 70% !important;
  color: #339F67;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
}

.style_couponDetailsBox__1vtMg {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  background: "#FFFFFF";
  border: 0.5px dashed #7EB0B1;
  border-radius: 12px;
  padding-left: 20px;
  margin-top: 10px;
}

.style_couponDetailsBoxMessage__3jyZk {
  width: 100% !important;
  color: #3A5353;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
}

@media only screen and (max-width: 1340px) {
  .style_newProfileButton__2AT2B {
    font-size: 0.8em !important;
  }

  .style_newMoveButton__2Dfe3 {
    font-size: 0.8em !important;
  }
}

@media only screen and (max-width: 1050px) {
  .style_couponSection__3HEP- {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: auto !important;
    width: 100% !important;
    margin: 0px auto;
    color: #707070;
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .style_couponSectionSticky__2qdpz {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    margin-top: 0px;
    color: #707070;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    position: absolute;
    top: 90px !important;
    margin: 0px auto;
  }

  .style_couponSectionBox__1Ns5l {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 85% !important;
    background: "#F1FFF8";
    border: 0.5px dashed #4BB980;
    border-radius: 12px;
    padding-left: 20px;
    margin: 20px auto !important;
  }

  .style_couponSectionMessage__3P6AP {
    width: 70% !important;
    color: #339F67;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .style_BackCalendarBox__kgI4t {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    margin: 15px auto;
    position: relative;

  }

  .style_BackCalendarBoxSticky__dIbV7 {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    position: absolute;
    top: 90px !important;
    margin: 0px auto;
  }

  .style_confirmButtonBox__3QWpY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-right: 0px;
    width: 100%;
  }

  .style_confirmButtonBoxSticky__YBiBZ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .style_verticalSeparator__3sc1B {
    height: 15px;
    width: 1px;
    background: #707070;
    margin-left: 10px;
    margin-right: 5px;
  }

  .style_confirmButton__2KljZ {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin: 10px auto;
  }

  .style_couponBox__s4dXV {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    border: 1px solid #707070;
    border-radius: 16px;
    height: 34px;
    width: 90% !important;
    margin: 0px auto !important;
  }

  .style_reservationAddedBox__zng4y {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1em !important;
    color: #60CD95 !important;
    font-weight: 500 !important;
    font-family: "Poppins";
  }

  .style_reservationBox__SnHNg {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }

  .style_drawerTitle__1Ef3R {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .style_mainContainer__1RgYy {
    height: auto;
    width: 95%;
    overflow: hidden;
    border: 1px solid #707070;
    padding: 0px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0px auto;
    margin-bottom: 40px;
  }

  .style_headerBox__1oZ36 {
    height: auto;
    min-height: auto;
    width: 95%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    background: #FFF;
    box-shadow: 4px 8px 18px #98989829;
    border: 1px solid #DFDDDD;
    border-radius: 24px;
  }

  .style_detailsBox__1ZEdH {
    height: auto;
    width: 95% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    box-shadow: none;
    border: 0px solid #DFDDDD;
    border-radius: 24px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    text-align: center;
    margin: 0px auto;
  }

  .style_detailsBoxScroll__1QtXN {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #FFF;
    box-shadow: 4px 8px 18px #98989829;
    border: 1px solid #DFDDDD;
    border-radius: 24px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    text-align: center;
  }

  .style_avatarBox__3GGzd {
    height: auto;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5%;
  }

  .style_nameBox__XHyhq {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    margin-top: 0px;
  }

  .style_avatarPicture__3cI5c {
    height: 85px;
    width: 85px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1.5px solid #FFF;
    border-radius: 50%;
  }

  .style_nameDetails__28cMs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .style_buttonsRow__35hb2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .style_name__1eido {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
  }

  .style_rateLanguages__3un-H {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .style_title__UhnaI {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #393E3E;
    margin-top: 5px;
  }

  .style_levels__2PbxU {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 600;
    color: #7FB1B2;
    margin-bottom: 10px;
  }

  .style_descriptionPriceBox__3rN_m {
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: transparent;
    box-shadow: none;
    border: 0px solid #DFDDDD;
    border-radius: 24px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    margin-left: 0px;
    text-align: left;
  }

  .style_descriptionBox__w_xzp {
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .style_description__31z0L {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 600;
    color: #707070;
  }

  .style_docsBox__2zMRY {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0px;
  }

  .style_docsBox__2zMRY span {
    font-size: 0.75em;
  }

  .style_coursesPicesBox__1jon8 {
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .style_coursesBox__3W6lJ {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .style_priceBox__1PLte {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .style_courses__1U4WB {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-left: 0px;
    margin-right: 7px;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    grid-gap: 7px;
    gap: 7px;
  }

  .style_price__r141i {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 700;
    color: #7FB1B2;
  }

  .style_descriptionLineSeparator__naXGZ {
    height: 1px;
    width: 92% !important;
    margin: 10px auto !important;
    background: #D8D8D8;
  }

  .style_newProfileButton__2AT2B {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .style_newMoveButton__2Dfe3 {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
  }

  .style_couponButton__rYEqv {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 12px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 0px;
    margin-bottom: 1px;
    margin-top: 5px;
  }

  .style_cancelcouponButton__2lMwP {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 12px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 14%;
    margin-bottom: 1px;
  }

  .style_lineSeparator__1bBky {
    height: 1px;
    width: 92%;
    margin: 10px auto;
    background: #D8D8D8;
  }

  .style_weekBox__3hX2g {
    width: 95%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    margin: 0px auto;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    background: #FFF;
    position: relative;
    top: 10px;
    left: 0%;
    z-index: 2;
  }

  .style_date__3Sh1C {
    width: auto !important;
    height: 25px !important;
    border-radius: 0px !important;
    text-align: center !important;
    border: 1px solid #ccc !important;
    cursor: pointer !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;
    transition: 0.3s !important;
    -webkit-transition: 0.3s !important;
    -moz-transition: 0.3s !important;
    -ms-transition: 0.3s !important;
    -o-transition: 0.3s !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    font-size: 0.55em !important;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    text-transform: capitalize;
    background: #FFF;
  }

  .style_dateBox__LsDOG {
    font-size: 0.7em !important;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .style_todayBox__1ngfG {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .style_horizontalLine__1Iwvn {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .style_daysBox__2nOMJ {
    width: 95%;
    height: auto;
    background: transparent;
    margin: 30px auto;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .style_dayBox__39KPH {
    width: 100%;
    height: auto;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 7px;
  }

  .style_calendarDay__1pIc6 {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    text-transform: capitalize;
  }

  .style_reservationMessage__3_hL8 {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-right: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0%;
  }

  .style_reservationMessageCoupon__znm8h {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-right: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0%;
    text-decoration: underline;
  }

  .style_reservationMessageConfirm__Y7WvD {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-right: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: -10%;
  }

  .style_dayTimeBox__2I4_x {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .style_momentBox__FTKiF {
    height: auto;
    width: auto !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #868686;
    margin-bottom: 10px;
  }

  .style_momentBoxCoupon__26V24 {
    height: auto;
    width: auto !important;
    display: flex;
    flex: auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #868686;
  }

  .style_timeBox__1Hh_V {
    height: auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .style_timeBoxCoupon__Wwd-A {
    height: auto;
    width: 90% !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }

  .style_ribbon__xi0gL {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 20px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    height: 20px;
    background: #60CD95;
  }

  .style_ribbon__xi0gL:after {
    height: 20px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 12px;
    border-color: transparent transparent transparent white;
  }

  .style_credit__1U-XW {
    font-size: 0.65em;
    position: relative;
    bottom: 4px;
  }

  .style_couponInput__46oC1 {
    font-size: 0.8em !important;
  }

  .style_couponInput__46oC1::placeholder {
    font-size: 0.8em !important;
  }

  .style_drawerBody__dUdo3 {
    height: 100% !important;
    width: 95% !important;
    background: #FFF !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 10px !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    overflow: auto;
    scrollbar-width: thin;
  }

  .style_couponDetailsBox__1vtMg {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    background: "#FFFFFF";
    border: 0.5px dashed #7EB0B1;
    border-radius: 12px;
    padding-left: 0px;
    margin-top: 10px;
    text-align: center;
  }
}
.cardTeacher_card__2U4Jd {
  height: 385px;
  width: 100%;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 2.5px solid #707070;
  border-bottom: 2.5px solid #707070;
}

.cardTeacher_card__2U4Jd:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardTeacher_card__2U4Jd::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardTeacher___matiers__19dpE {
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.3em;
  gap: 0.3em;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-1);
}

.cardTeacher___tag__topic__1fg2r {
  background: #d5d5d5;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
}

.cardTeacher_card__header__2zXvo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.cardTeacher_content__V9pHV {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0.2% 2%;
}

.cardTeacher_round__user__1mHiw {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cardTeacher_round__user__1mHiw img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 100px;
}

.cardTeacher_content__V9pHV h3 {
  width: 150px;
  text-transform: uppercase;
  color: #333;
  font-size: 1.2em;
  margin-top: var(--mb-1);
}

.cardTeacher_tarif__OELPJ {
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
  margin-top: var(--mb-1);
  position: absolute;
  top: 66%;
}

.cardTeacher_tarif__OELPJ span {
  font-weight: 300;
}

.cardTeacher_tarifInsta__1Zrnu {
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
  margin-top: var(--mb-1);
  position: relative;
  top: 5%;
}

.cardTeacher_tarifInsta__1Zrnu span {
  font-weight: 300;
}

.cardTeacher_btn__profil__prof__1YsoC:hover {
  background: #5a8484 !important;
}

.cardTeacher___description__2Jvqh {
  width: 80%;
  height: 35px;
  font-size: 12px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
  text-align: center;
  position: absolute;
  top: 36%;
}

.cardTeacher___descriptionInsta__1g8x0 {
  width: 80%;
  height: 35px;
  font-size: 12px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 5px;
  text-align: center;
  position: relative;
  top: 0%;
}

.cardTeacher___start__2zb3G {
  position: absolute;
  margin: auto;
  top: -4%;
  right: 8%;
}

.cardTeacher___row__fxi6- {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
}

.cardTeacher___row__fxi6- button {
  position: relative;
  border: unset;
  padding: 4px 11px;
  border-radius: 8px;
  background: var(--first-color);
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.cardTeacher_calendrier__25vIl {
  width: 1200px;
  height: 850px;
  margin-top: 2em;
  background: url(/static/media/calendarScreen.284fd573.svg) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 1s ease;
}

.cardTeacher_calendar__content__3MLMM {
  width: 95%;
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: auto;
}

.cardTeacher___drawer__teacher__38eqw {
  width: 100%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.cardTeacher___drawer__teacher__header__34L7S {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cardTeacher___infos__2v7Bg h3 {
  font-size: 1.6em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-1);
}

.cardTeacher___drawer__teacher__main__3X0dj {
  margin-top: 2em;
}

.cardTeacher___main__infos__3lAm9 {
  font-size: 1.6em;
  margin-bottom: 1em;
  display: flex;
}

.cardTeacher___main__infos__3lAm9 span {
  text-transform: uppercase;
}

.cardTeacher___drawer_matiere__2YR-V {
  position: relative;
  top: -0.6em;
  margin-left: 1.5em;
}

.cardTeacher___main__infos__3lAm9 p {
  position: relative;
  top: 5px;
  font-size: 0.7em;
  margin-left: 1em;
}

.cardTeacher___plus__bSGWN {
  width: 100%;
  height: 400px;
  background: #fafafa;
}

.cardTeacher___reservation__2DXUZ {
  /* background: var(--first-color); */
  width: 350px;
  height: 350px !important;
}

.cardTeacher_calendarPopupContent__2iuKa * .cardTeacher_ant-popover-inner-content__GaU4U {
  padding: 0 !important;
}

.cardTeacher___reservation_content__1pyb6 {
  position: relative;
  width: 100%;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* padding-top: 2%; */
}

.cardTeacher___reservation__row__36489 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cardTeacher___reservation_avatar__2LszQ {
  position: relative;
  width: 80px;
  height: 80px;
  top: -10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 100px;
}

.cardTeacher___reservation_avatar__2LszQ img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.cardTeacher___reservation_content__1pyb6 h2 {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 1.5em;
}

.cardTeacher_reservation__infos__2EGIF {
  width: 100%;
  padding-left: 3%;
}

.cardTeacher_reservation__infos__2EGIF h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.cardTeacher_reservation__infos__2EGIF span {
  font-size: 11px;
}

.cardTeacher_tag__2l5Ft {
  width: 100px;
  height: 32px;
  border-radius: 20px;
  background: #8ab0b1ca;
  display: flex;
  margin-bottom: 0.5em;
}

.cardTeacher_tag__2l5Ft span {
  margin: auto;
  text-transform: uppercase;
  font-size: 11px;
  color: #fff;
}

.cardTeacher___drawer_button__2_c0p {
  border-radius: 15px !important;
  height: 40px !important;
  color: var(--first-color);
  text-transform: uppercase;
  min-width: 200px;
}

.cardTeacher___custom__container__3M_kK {
  width: 100%;
  height: 100%;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.cardTeacher___liste__card__1X0wV {
  margin-top: var(--mb-4);
}

.cardTeacher___calendar__title__3WwyK,
.cardTeacher___calendar__title__creneau__LiKh4 {
  font-size: 12px !important;
  line-height: 14px;
}

.cardTeacher___calendar__title__3WwyK {
  font-size: 11px !important;
}

/* .__open__className {
  min-width: 400px !important;
  max-width: 400px !important;
} */

.cardTeacher___solde_content__1__jS h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.cardTeacher___prof__description__card__reservation__100XF {
  font-size: 12px;
  text-align: center;
}

.cardTeacher___matiersAds__NBB2o * span {
  padding-left: 2px;
}

.cardTeacher___matiersAds__NBB2o {
  margin-bottom: 1.5em;
}

/* ====== */
.cardTeacher_month__1HpW0 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: -0.7em;
}

.cardTeacher_month__1HpW0 span {
  text-transform: uppercase;
  font-family: "CartoonFont";
}

.cardTeacher_date__2SrAD {
  font-size: 2em;
}

.cardTeacher_month__1HpW0 span:nth-child(2) {
  position: relative;
  font-size: 1.2em;
}

.cardTeacher_month__1HpW0 span:nth-child(3) {
  position: relative;
  font-size: 1.2em;
}

.cardTeacher_month__1HpW0 span:nth-child(2)::after,
.cardTeacher_month__1HpW0 span:nth-child(3)::after {
  content: "";
  position: absolute;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  left: -18px;
  top: 10px;
  background: var(--first-color);
}

.cardTeacher_month__1HpW0 span:nth-child(3)::after {
  background: #faad13;
}

/* ======== */

.cardTeacher_card__teacher__reservation__2Cl_i {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid #fafafabe;
  border-radius: 5px;
  padding: 3% 2%;
}

.cardTeacher_teacher__reservation__avatar__2iem5 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}

.cardTeacher_teacher__reservation__avatar__2iem5 img {
  width: 100%;
  height: 100%;
}

.cardTeacher_open__messagerie__2gfRO span {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s all;
}

.cardTeacher_open__messagerie__2gfRO span:hover {
  color: var(--first-color);
}

.cardTeacher_levelGroup__Xzx_N span:last-child {
  display: none;
}

/* New Css Styles */

.cardTeacher_teacherCard__2Byp0 {
  height: 380px !important;
  width: 100% !important;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: 2.5px solid #707070;
  border-bottom: 2.5px solid #707070;
}

.cardTeacher_ribbon__1GGg7 {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 0px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 0px;
  height: 25px;
  background: #60CD95;
}

.cardTeacher_ribbonInsta__3P5vx {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10px;
  right: 0px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 0px;
  height: 25px;
  background: #60CD95;
}

.cardTeacher_ribbon__1GGg7:after {
  height: 25px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12.5px 0 12.5px 15px;
  border-color: transparent transparent transparent white;
}

.cardTeacher_ribbonInsta__3P5vx:after {
  height: 25px;
  content: '';
  position: relative;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12.5px 0 12.5px 15px;
  border-color: transparent transparent transparent white;
}

.cardTeacher_credit__dysVA {
  font-size: 8px;
  position: relative;
  bottom: 4px;
}

.cardTeacher_creditInsta__29vZX {
  font-size: 8px;
  position: relative;
  bottom: 4px;
}

.cardTeacher_avatarNameBox__3mN5u {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 0px;
  right: 0px;
  height: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cardTeacher_avatarNameBoxInsta__1ug-s {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  height: auto;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cardTeacher_avatarBox__1Rmu1 {
  height: 75px;
  width: 75px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFDCAB80;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTeacher_nameRateBox__3B03T {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.cardTeacher_nameBox__1VFl9 {
  display: flex;
  flex-direction: row;
  line-height: 1.3;
}

.cardTeacher_nameRateBox__3B03T span {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.cardTeacher_rateNumber__3bfz9 {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #393E3E;
  margin-top: 0px;
  cursor: pointer;
}

.cardTeacher_matiers__3tzmI {
  height: 40px !important;
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  margin: 7px auto;
  position: absolute;
  top: 49%;
  grid-gap: 5px;
  gap: 5px;
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.cardTeacher_matiersInsta__2kWJ- {
  height: 40px !important;
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  margin: 7px auto;
  position: relative;
  top: 0%;
  grid-gap: 5px;
  gap: 5px;
  overflow: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}

.cardTeacher_levelGroup__Xzx_N {
  color: #7FB1B2;
  font-weight: 500;
  position: absolute;
  top: 62%;
}

.cardTeacher_levelGroupInsta__2durt {
  color: #7FB1B2;
  font-weight: 500;
  position: relative;
  top: 0%;
}

.cardTeacher_levelGroup__Xzx_N span:last-child {
  display: block;
}

.cardTeacher_levelGroupInsta__2durt div:last-child {
  display: block;
}

.cardTeacher_rowButtons__2W0Ot {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
  position: absolute;
  bottom: 10%;
}

.cardTeacher_rowButtonsInsta__2pP8w {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
  position: relative;
  top: 10%;
}

.cardTeacher_newInstaButton__1VIVN {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.cardTeacher_newPlanningButton__1Wywt {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cardTeacher_newMessageButton__MoUbz {
  width: 32px !important;
  height: 32px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding: 0px !important;
}

@media only screen and (max-width: 1050px) {
  .cardTeacher_avatarNameBox__3mN5u {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 20px;
    right: 0px;
    height: auto;
    width: 90%;
    padding-top: 25px;
    padding-bottom: 20px;
  }

  .cardTeacher_avatarNameBoxInsta__1ug-s {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;
    left: 20px;
    right: 0px;
    height: auto;
    width: 90%;
    padding-top: 25px;
    padding-bottom: 20px;
  }

  .cardTeacher_avatarBox__1Rmu1 {
    height: 45px;
    width: 45px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 00px;
    background: #FFDCAB80;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardTeacher_nameRateBox__3B03T {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    margin-top: 5px;
  }

  .cardTeacher_nameBox__1VFl9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.3;
  }

  .cardTeacher_nameRateBox__3B03T span {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .cardTeacher_rateNumber__3bfz9 {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #393E3E;
    margin-top: 0px;
    cursor: pointer;
  }

  .cardTeacher_matiers__3tzmI {
    height: 30px !important;
    width: auto !important;
    text-transform: uppercase;
    font-size: 0.75em !important;
    margin: 7px auto;
    position: absolute;
    top: 58%;
    grid-gap: 5px;
    gap: 5px;
    overflow: auto;
    scrollbar-width: thin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .cardTeacher_matiersInsta__2kWJ- {
    height: 30px !important;
    width: auto !important;
    text-transform: uppercase;
    font-size: 0.75em !important;
    margin: 7px auto;
    position: relative;
    top: 0%;
    grid-gap: 5px;
    gap: 5px;
    overflow: auto;
    scrollbar-width: thin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .cardTeacher_levelGroup__Xzx_N {
    color: #7FB1B2;
    font-weight: 500;
    position: absolute;
    top: 70%;
    font-size: 0.75em;
  }

  .cardTeacher_levelGroupInsta__2durt {
    color: #7FB1B2;
    font-weight: 500;
    position: relative;
    top: 0%;
    font-size: 0.75em;
  }

  .cardTeacher_rowButtons__2W0Ot {
    width: 90%;
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    margin-left: 10px;
    position: absolute;
    bottom: 5%;
  }

  .cardTeacher_rowButtonsInsta__2pP8w {
    width: 90%;
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
    margin-left: 10px;
    position: relative;
    top: 5%;
  }

  .cardTeacher_newInstaButton__1VIVN {
    width: auto !important;
    height: 25px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 0.65em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 4px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
  }

  .cardTeacher_newPlanningButton__1Wywt {
    width: auto !important;
    height: 25px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 0.65em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .cardTeacher_newMessageButton__MoUbz {
    width: 27px !important;
    height: 27px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 0.65em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding: 0px !important;
  }

  .cardTeacher___description__2Jvqh {
    width: 80%;
    height: 35px;
    font-size: 0.75em !important;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    text-align: center;
    position: absolute;
    top: 46%;
  }

  .cardTeacher___descriptionInsta__1g8x0 {
    width: 80%;
    height: 35px;
    font-size: 0.75em !important;
    white-space: wrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
    text-align: center;
    position: relative;
    top: 0%;
  }

  .cardTeacher_tarif__OELPJ {
    font-size: 0.75em;
    font-weight: 600;
    color: #333;
    margin-top: var(--mb-1);
    position: absolute;
    top: 75%;
  }

  .cardTeacher_tarifInsta__1Zrnu {
    font-size: 0.75em;
    font-weight: 600;
    color: #333;
    margin-top: var(--mb-1);
    position: relative;
    top: 0%;
  }

  .cardTeacher_tarif__OELPJ span {
    font-weight: 300;
  }

  .cardTeacher_card__2U4Jd {
    height: 305px;
    width: 85%;
    background: #FAFAFA;
    padding-top: 15px;
    padding-left: 25px;
    padding-right: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 2.5px solid #707070;
    border-bottom: 2.5px solid #707070;
    margin: 0px auto;
  }

  .cardTeacher_card__2U4Jd:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 40px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .cardTeacher_card__2U4Jd::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 20px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .cardTeacher_teacherCard__2Byp0 {
    height: 345px !important;
    width: 100% !important;
    background: #FAFAFA;
    padding: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-right: 2.5px solid #707070;
    border-bottom: 2.5px solid #707070;
  }

  .cardTeacher___tag__topic__1fg2r {
    background: #d5d5d5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2.5px 5px;
    border-radius: 8px;
    font-size: 0.75em;
  }

  .cardTeacher_ribbon__1GGg7 {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 0px;
    padding-left: 30px;
    padding-right: 20px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    height: 20px;
    background: #60CD95;
  }

  .cardTeacher_ribbonInsta__3P5vx {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 5px;
    right: 0px;
    padding-left: 30px;
    padding-right: 20px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    height: 20px;
    background: #60CD95;
  }

  .cardTeacher_ribbon__1GGg7:after {
    height: 20px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 12px;
    border-color: transparent transparent transparent white;
  }

  .cardTeacher_ribbonInsta__3P5vx:after {
    height: 20px;
    content: '';
    position: relative;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 12px;
    border-color: transparent transparent transparent white;
  }

  .cardTeacher_credit__dysVA {
    font-size: 0.65em;
    position: relative;
    bottom: 4px;
  }

  .cardTeacher_creditInsta__29vZX {
    font-size: 0.65em;
    position: relative;
    bottom: 4px;
  }
}
main {
    width: 70%;
    margin: auto;
    text-align: center;
}

main button {
    margin-top: 20px;
    background-color: #2D8CFF;
    color: #ffffff;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    outline: none;
}

main button:hover {
    background-color: #2681F2;
}
.style_countdown__24cjQ {
  color: var(--first-color);
  font-size: 25px;
  font-weight: 500;
}

.style_countdown__24cjQ.style_danger__3QoWQ {
  color: #ff0000 !important;
  font-size: 25px;
  font-weight: 500;
}

.grid_grid__2Po25 {
  display: grid;
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  .grid_grid__2Po25 {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5em;
    gap: 2.5em;
  }
}
.style_drawer__title__KKm2Z {
  margin-top: 5px;
}

.style_drawer__search__Xx1Pw {
  width: 85%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-bottom: 30px;
  padding-right: 50px;
  padding-top: 30px;
  background: #FFF8F7;
  border: 1px solid #F56666;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.style_drawer__title__KKm2Z h1 {
  font-size: 1.4em;
}

.style_drawer__title__KKm2Z p {
  font-weight: 400;
  text-align: center;
  font-family: var(--body-font);
  text-transform: initial;
  margin-bottom: 0;
  font-size: 13px;
}

.style_field__3NVNj {
  background: #fff;
  padding: 0.2em;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #707070;
  margin-bottom: 1em;
}

.style_fullField__11dS1 {
  background: #fff;
  padding: 0.2em;
  width: 100%;
  border-radius: 24px;
  border: 1px solid #707070;
  margin-bottom: 1em;
}

.style_button__38L8e {
  background: #0ed290 !important;
  height: 35px !important;
  box-shadow: 3px 6px 1px -1px #0ed29198 !important;
}

.style_instaButton__24Gvj {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
  -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 30px !important;
}

.style_instaTeacherButton__1tXgy {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
  -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  text-align: center !important;
}

.style_main__1alFO {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
  background: #fafafa;
  padding: 2%;
  border-radius: 10px;
}

.style_title__alert__3Q_iQ {
  color: #0ed290;
  font-size: 20px;
}

.style_wait__3gQvv {
  text-align: center;
}

.style_wait__3gQvv h3 {
  font-size: 25px;
  font-family: var(--title-font);
  margin-top: 0.5em;
}

@media only screen and (min-width: 768px) {
  .style_grid__3TtrQ {
    grid-gap: 1em;
    gap: 1em;
  }
}

@media only screen and (max-width: 767px) {
  .style_drawer__title__KKm2Z h1 {
    font-size: 1em;
  }

  .style_drawer__title__KKm2Z p {
    font-size: 11px;
  }

  .style_drawer__title__KKm2Z p br {
    display: none;
  }

  .style_field__3NVNj {
    padding: 0.2em;
    width: 150px;
  }

  .style_fifullFieldeld__VUF-4 {
    padding: 0.2em;
    width: 100%;
  }

  .style_button__38L8e {
    margin-top: 1em;
  }

  .style_wait__3gQvv h3 {
    font-size: 20px;
  }

  .style_main__1alFO {
    margin-top: 2%;
  }
}

/* New Css Styles */

.style_titleBox__8i09L {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.style_detailsBoxTitle__1GXg_ {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
}

.style_detailsBoxMessage__1jf-v {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.style_searchBox__3tOgM {
  width: 100%;
  height: 65px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.style_selectBox__3xvW4 {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.style_separation__23_z2 {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.style_amountField__3bpCp {
  width: 205px;
  position: relative;
  left: -12px;
}

.style_amountField__3bpCp input::placeholder {
  font-size: 12px !important;
  color: #000 !important;
  opacity: 0.8 !important;
}

.style_amountField__3bpCp::-webkit-input-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

.style_amountField__3bpCp:-moz-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

.style_amountField__3bpCp::-moz-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

.style_amountField__3bpCp:-ms-input-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

@media only screen and (max-width: 1050px) {
  .style_drawer__search__Xx1Pw {
    width: 90%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background: #FFF8F7;
    border: 1px solid #F56666;
    border-radius: 24px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .style_titleBox__8i09L {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .style_detailsBoxTitle__1GXg_ {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
  }

  .style_detailsBoxMessage__1jf-v {
    font-size: 0.65em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .style_searchBox__3tOgM {
    width: 100%;
    height: auto;
    background: #FFF8F7;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 0px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 15px auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .style_selectBox__3xvW4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 0.75em !important;
    text-align: left;
    opacity: 1;
    background: #FFF;
    border: 0.5px solid #BFBCBC;
    border-radius: 12px;
    height: 35px !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .style_amountBox__1jVXc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 0.75em !important;
    text-align: left;
    opacity: 1;
    background: #FFF;
    border: 0.5px solid #BFBCBC;
    border-radius: 12px;
    height: 35px !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .style_separation__23_z2 {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 20px;
    display: none;
  }

  .style_amountField__3bpCp {
    width: 100%;
    position: relative;
    left: 0px;
  }

  .style_textareaField__3xbHH::placeholder {
    font-size: 0.65em !important;
  }

  .style_amountField__3bpCp input::placeholder {
    font-size: 0.65em !important;
    color: #000 !important;
    opacity: 0.8 !important;
  }

  .style_amountField__3bpCp::-webkit-input-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .style_amountField__3bpCp:-moz-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .style_amountField__3bpCp::-moz-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .style_amountField__3bpCp:-ms-input-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .style_instaButton__24Gvj {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
    -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 30px !important;
  }

  .style_instaTeacherButton__1tXgy {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
    -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    text-align: center !important;
  }
}
.drawerProfile_container__profile__3AcAf {
  width: 100%;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.drawerProfile___profile__JtLHj {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: relative;
  /* background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.drawerProfile_headerBox__2bcJY {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: right;
  margin-top: 18px;
}

.drawerProfile_back__icon__1Vt5E {
  position: absolute;
  top: 3em;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: auto;
}

.drawerProfile_logoutButton__1A_uc {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 70px;
}

.drawerProfile_pseudo__35Z8S {
  text-align: center;
  margin: var(--mb-2) 0;
  font-family: var(--title-font);
  font-size: 1.7em !important;
  text-transform: uppercase;
}

.drawerProfile_info__1vO6Q {
  width: 100%;
  text-align: center;
  margin: var(--mb-2) 0;
  text-transform: uppercase;
  color: var(--title-color);
  font-size: 1.5em;
  font-weight: 500;
}

.drawerProfile_field__3SoJv {
  padding: 5px 10px;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
  margin-bottom: 15px;
  min-width: 165px;
}

.drawerProfile_button__2Zrl5 {
  width: 100%;
  height: 35px !important;
  border-radius: 50px !important;
  background: var(--text-color) !important;
  border: none !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.drawerProfile_passwordButton__2Qnxs {
  width: 250px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: uppercase;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.drawerProfile___logout__3hzPl {
  height: 40px !important;
}

@media only screen and (max-width: 767px) {
  .drawerProfile_back__icon__1Vt5E {
    position: absolute;
    top: 1.7em;
    cursor: pointer;
  }

  .drawerProfile_logoutButton__1A_uc {
    margin-right: 0px;
  }

  .drawerProfile_buttonsFont__3-YlW {
    font-size: 0.8em !important;
    font-family: "Poppins" !important;
    font-weight: 600 !important;
    color: #FFF !important;
    text-transform: none !important;
  }

  .drawerProfile_button__2Zrl5 {
    width: 100%;
    height: 35px !important;
    border-radius: 50px !important;
    background: var(--text-color) !important;
    border: none !important;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 0.8em !important;
  }

  .drawerProfile_passwordButton__2Qnxs {
    width: 250px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: uppercase;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
  }

  .drawerProfile_headerBox__2bcJY {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 80%;
    align-items: center;
    justify-content: right;
    margin-top: 18px;
  }
}

/* New Css Styles */

.drawerProfile_tabTitleBox__-IiCq {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.drawerProfile_tabTitleBoxActive__38Cez {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.drawerProfile_buttonsFont__3-YlW {
  font-size: 14px !important;
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  color: #FFF !important;
  text-transform: none !important;
}

.drawerProfile_sponsorButton__3nrsz {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  background: white !important;
  padding: 5px 15px !important;
  border-radius: 20px !important;
  font-size: 13px;
  color: var(--first-color);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid var(--first-color) !important;
  margin-left: 10px;
}

.drawerProfile_sponsorButton__3nrsz:hover {
  background: var(--first-color);
  transform: scale(1.03) !important;
  color: white;
}
.stepTwo_full__content__2FH5d {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #f8f8f8;
  text-align: left;
  padding: 2%;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-bottom: var(--mb-3);
  margin-left: auto;
  margin-right: auto;
}

.stepTwo_title__Oejvw {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.stepTwo_level__Fh4q3 h2 {
  font-size: 1.5em;
  text-align: center;
}

.stepTwo___content__iaA74 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.stepTwo_level__Fh4q3 {
  flex: 0.9 1;
}

.stepTwo_forms__mg3q2 {
  flex: 1.1 1;
}

.stepTwo_forms__mg3q2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin-bottom: 1em;
}

.stepTwo_field__3biX5 {
  background: #fff;
  padding: 0.35em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #707070;
  min-width: 200px;
}

.stepTwo_text__area__189K- {
  border: 1px solid #707070;
  padding: 0.35em;
  width: 100%;
  margin-top: 1em;
  background: #fff;
  border-radius: 5px;
}

.stepTwo_select__1B4GT *::placeholder {
  color: red !important;
}

.stepTwo_button__1ZDxP {
  width: 100% !important;
  background: #636363 !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 1em;
}

.stepTwo_button__1ZDxP:hover {
  background: var(--first-color) !important;
  color: #fff !important;
}

.stepTwo___new__creneaux__os1PI {
  width: 100% !important;
  background: var(--first-color) !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 0.5em;
}

.stepTwo___new__creneaux__os1PI:hover {
  background: #636363 !important;
  color: #fff !important;
}

.stepTwo_inputBox__bVnzo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.stepTwo_levelBox__3Yan2 {
  width: 70%;
}

.stepTwo_languageBox__DM96y {
  width: 30%;
}

@media only screen and (max-width: 390px) {
  .stepTwo_full__content__2FH5d {
    max-width: 100%;
    padding: 4%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .stepTwo_forms__mg3q2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .stepTwo_forms__mg3q2 {
    flex: 1 1;
  }

  .stepTwo___content__iaA74 {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }

  .stepTwo_level__Fh4q3 {
    flex: 1 1;
    width: 100%;
  }

  .stepTwo_inputBox__bVnzo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .stepTwo_levelBox__3Yan2 {
    width: 100%;
  }

  .stepTwo_languageBox__DM96y {
    width: 100%;
  }

}

@media only screen and (max-width: 1530px) {
  .stepTwo___content__iaA74 {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }
}

/* New Css Style */

.stepTwo_levelsBox__2ZZKQ {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-bottom: 30px;
}

.stepTwo_levelsBox__2ZZKQ span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  margin-top: 10px;
}

.stepTwo_levelsButtons__1qKe6 {
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.stepTwo_levelButton__28KIB {
  width: 156px;
  height: 50px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
}

.stepTwo_levelButtonActive__Q8FBi {
  width: 156px;
  height: 50px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
}

.stepTwo_pricesBox__24CIB {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-bottom: 30px;
}

.stepTwo_pricesBox__24CIB span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
}

.stepTwo_priceBox__3hgpL {
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  gap: 20px;
  position: relative;
  bottom: 7px;
}

.stepTwo_price__jA1Ef {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  text-align: center;
}

.stepTwo_priceLevel__Bx99L {
  position: relative;
  bottom: 10px;
  width: 20%;
}

.stepTwo_HoursBox__2Wsuk {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  margin-top: 30px;
}

.stepTwo_titleDescriptionBox__oHkVj {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  text-align: left;
}

.stepTwo_titleDescriptionBox__oHkVj span {
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  color: #868686;
}
.footerFooter_footerFooter__2BHy8 {
  width: 100%;
  height: 100%;
  min-height: 150px;
  background: rgb(47, 47, 47);
  margin-top: 0em;
  z-index: 99 !important;
}

.footerFooter_row__eqZY9 {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-3);
  padding-bottom: var(--mb-3);
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  grid-gap: 0;
  gap: 0;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  text-transform: uppercase;
  align-items: center;
}

.footerFooter_row__eqZY9 a {
  color: #fff;
  text-align: center;
  font-size: 12px;
  width: 100%;
  min-width: 100px;
}

.footerFooter_socials__media__1nlGO {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.footerFooter_socials__media__1nlGO img {
  cursor: pointer;
}

.footerFooter_topic-button__2I514 {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footerFooter_topic-button__2I514.footerFooter_selected__2wJmU {
  background-color: #1890ff;
  color: white;
}

.footerFooter_footerBox__iHqwa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  text-align: center;
  z-index: 0;
}

.footerFooter_footerBoxTop__3C4F5 {
  height: 398px;
  width: 100%;
  background: rgb(47, 47, 47);
  padding-left: 0%;
  padding-right: 0%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.footerFooter_footerBoxBottom__1q8K0 {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0%;
  padding-right: 0%;
  background: rgb(47, 47, 47);
  align-items: flex-start;
  justify-content: flex-start;
}

.footerFooter_footerBoxTopMain__yzNxq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
}

.footerFooter_logoContainer__1MmXx {
  height: 90px;
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.footerFooter_logoBox__3DaQ6 {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-right: 10px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerFooter_logoTitle__1EKDM {
  font-size: 28px;
  color: rgb(255, 255, 255);
  font-family: "Margin DEMO";
  line-height: 28px;
  font-weight: normal;
  margin-top: 0px;
}

.footerFooter_footerMessage__T0tP_ {
  font-size: 1.2em;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 30px;
  font-weight: normal;
  margin-top: 30px;
}

.footerFooter_followUsTitle__Kz7DG {
  font-size: 1.3em;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 30px;
  font-weight: normal;
  margin-top: 30px;
}

.footerFooter_socialBox__1P8Kw {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  align-items: flex-start;
  justify-content: flex-start;
}

.footerFooter_menuTitle__O1_TC {
  font-size: 1.4em;
  color: rgb(255, 255, 255);
  font-family: "Poppins";
  line-height: 40px;
  font-weight: normal;
  margin-top: 10px;
}

.footerFooter_menuBox__1AQ29 {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: rgb(204, 204, 204);
  font-size: 1em;
  font-family: "Poppins";
  line-height: 34px;
  font-weight: normal;
}

.footerFooter_formBox__iVJ21 {
  height: auto;
  width: 100%;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50px;
  margin-top: 10px;
}

.footerFooter_newslettersForm__1e6bQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footerFooter_newslettersFormEmail__3wLp- {
  border: none;
  padding-left: 20px;
  width: 60%;
  background: transparent;
  border-radius: 50px;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
}

.footerFooter_newslettersButton__3K93S {
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  bottom: 0px;
  right: -1px;
  height: 50px;
  border: none;
  padding: 1em;
  background: var(--first-color);
  font-size: 1em;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 0;
  -o-border-radius: 50px;
  width: auto;
  display: inline-flex;
  align-items: center;
}

.footerFooter_footerSeparator__2PzZT {
  height: 2px;
  width: 100%;
  background: rgb(133, 133, 132);
}

.footerFooter_footerBottomBox__2uZS4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footerFooter_footerCopyrights__1BZf5 {
  font-size: 15px;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 25px;
  font-weight: normal;
  margin-top: 20px;
}

.footerFooter_copyrightsSeparator__2PeM9 {
  height: 15px;
  width: 1px;
  background: rgb(204, 204, 204);
  position: relative;
  top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.footerFooter_legalMention__1hm-W {
  font-size: 15px;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 25px;
  font-weight: normal;
  margin-top: 20px;
}

@media only screen and (max-width: 680px) {

  .footerFooter_footerBoxTop__3C4F5 {
    height: 598px;
    width: 100%;
    background: rgb(47, 47, 47);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footerFooter_footerBoxBottom__1q8K0 {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    background: rgb(47, 47, 47);
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footerFooter_footerBoxTopMain__yzNxq {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: left;
  }

  .footerFooter_logoContainer__1MmXx {
    height: 90px;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .footerFooter_logoBox__3DaQ6 {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footerFooter_logoTitle__1EKDM {
    font-size: 19px;
    color: rgb(255, 255, 255);
    font-family: "Margin DEMO";
    line-height: 20px;
    font-weight: normal;
    margin-top: 0px;
  }

  .footerFooter_footerMessage__T0tP_ {
    font-size: 13px;
    color: #CCCCCC;
    font-family: "Poppins";
    line-height: 20px;
    font-weight: normal;
    margin-top: 0px;
  }

  .footerFooter_followUsTitle__Kz7DG {
    font-size: 16px;
    color: rgb(204, 204, 204);
    font-family: "Poppins";
    line-height: 25px;
    font-weight: normal;
    margin-top: 10px;
  }

  .footerFooter_socialBox__1P8Kw {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footerFooter_menuTitle__O1_TC {
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-family: "Poppins";
    line-height: 20px;
    font-weight: normal;
    margin-top: 0px;
  }

  .footerFooter_menuBox__1AQ29 {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    color: rgb(204, 204, 204);
    font-size: 13px;
    font-family: "Poppins";
    line-height: 20px;
    font-weight: normal;
    width: 100%;
    justify-content: space-between;
  }

  .footerFooter_formBox__iVJ21 {
    height: 40px;
    width: 90%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50px;
    margin-top: 10px;
    font-size: 8px;
  }

  .footerFooter_newslettersForm__1e6bQ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 8px;
  }

  .footerFooter_newslettersFormEmail__3wLp- {
    border: none;
    padding: 20px;
    width: 55%;
    background: transparent;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    font-size: 8px;
    height: 40px;
  }

  .footerFooter_newslettersButton__3K93S {
    margin-bottom: 0px;
    cursor: pointer;
    position: relative;
    bottom: 1px;
    right: -1px;
    height: 40px;
    border: none;
    padding: .5em 2.5em;
    background: var(--first-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 0;
    -o-border-radius: 50px;
  }

  .footerFooter_footerSeparator__2PzZT {
    height: 2px;
    width: 100%;
    background: rgb(133, 133, 132);
  }

  .footerFooter_footerBottomBox__2uZS4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .footerFooter_footerCopyrights__1BZf5 {
    font-size: 15px;
    color: rgb(204, 204, 204);
    font-family: "Poppins";
    line-height: 25px;
    font-weight: normal;
    margin-top: 20px;
  }

  .footerFooter_copyrightsSeparator__2PeM9 {
    height: 15px;
    width: 1px;
    background: rgb(204, 204, 204);
    position: relative;
    top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .footerFooter_legalMention__1hm-W {
    font-size: 15px;
    color: rgb(204, 204, 204);
    font-family: "Poppins";
    line-height: 25px;
    font-weight: normal;
    margin-top: 20px;
  }

  .footerFooter_newslettersFormEmail__3wLp-::placeholder {
    font-size: 10px;
    position: relative;
    bottom: 5px;
    color: white;
  }

}

@media only screen and (max-width: 1050px) {
  .footerFooter_footerFooter__2BHy8 {
    display: none;
  }
}
.skillbar_row__10e4i {
  width: 100%;
  display: flex;
  align-items: center;
}

.skillbar_skillbar__1pjMk {
  width: 100%;
  height: 5px;
  border-radius: 50px;
  border: 1px solid #ccc;
  min-width: 250px;
}

.skillbar_skillbar__1pjMk .skillbar_bar__1wf2N {
  display: block;
  width: 0%;
  background: var(--first-color);
  height: 3px;
  border-radius: 50px;
  transition: 1s ease-out;
}

.skillbar_count__3pDqb {
  margin-left: 1em;
}

.skillbar_count__3pDqb span {
  font-size: 1.3em;
}

@media only screen and (max-width: 500px) {
  .skillbar_skillbar__1pjMk {
    min-width: 175px;
    height: initial;
  }

  .skillbar_count__3pDqb span {
    font-size: 1.1em;
  }
}

@media only screen and (min-width: 500px) {}

@media only screen and (min-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}
.quiz_quiz__1Kh-8 {
  margin: 1em 0;
}

.quiz_quiz__content__1zKrk {
  width: 100%;
  background: #fafafa;
  padding: 3% 5% 7% 5%;
}

.quiz_left__11TCV .quiz___avatarBoy__17ZGH {
  width: 100px;
}

.quiz_right__2Zyu6 .quiz___iconTrophy__1PzmT {
  width: 70px;
}

.quiz_center__2Ruyy img {
  width: 35px;
}

.quiz_header__3EarE {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.quiz_center__2Ruyy {
  flex: 2 1;
  padding: 0 1%;
}

.quiz_center__2Ruyy span {
  font-size: 1em;
}

.quiz___title__3f3tP {
  width: 100%;
  max-width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.quiz_main__1pDC2 {
  margin-top: 2em;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.quiz_main__1pDC2 h3 {
  font-size: 1.2em;
  margin-left: 2%;
  color: var(--text-color);
  margin-bottom: var(--mb-3);
  /* #CBCACD */
}

.quiz_card__ccYJ4 {
  position: relative;
  width: 95%;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #cbcacd;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-3);
  padding: 0 3%;
}

.quiz_card__ccYJ4 input[type="radio"] + label {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  user-select: none;
}

.quiz_card__ccYJ4 input[type="radio"] + label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 20px;
  border-bottom: 5px solid var(--text-color);
  border-left: 5px solid var(--text-color);
  top: 25%;
  left: 45%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

.quiz_card__ccYJ4 input[type="radio"]:checked + label {
  border: 1px solid var(--text-color);
}

.quiz_card__ccYJ4 input[type="radio"]:checked + label:after {
  opacity: 1;
  width: 35px;
}

.quiz_chk__3nXPH {
  display: none;
}

.quiz___btn__back__27EVi {
  max-width: 80px !important;
  min-width: 100px !important;
  background: #f64d5c !important;
  box-shadow: 3px 6px 1px -1px #f64d5bab !important;
}

.quiz____result__btn__3HI85 {
  border-radius: 15px;
  height: 40px;
}

/*  */
.quiz_show__counter__3h3HE {
  display: flex;
  min-width: 200px;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 1.2em;
}

.quiz_danger__3BH4L {
  color: red;
}

.quiz_expired__3My1i {
  position: relative;
  color: red;
  text-align: center;
  font-size: 1.4em;
  left: -0.8em;
  animation: quiz_clignote__1zKNl 1.7s linear infinite;
}

@keyframes quiz_clignote__1zKNl {
  50% {
    opacity: 0;
  }
}

.quiz___sound__2IMBO {
  position: absolute;
  left: -100%;
  visibility: hidden;
  width: 100%;
}

.quiz___drawer__button__2R0X5 {
  min-width: 350px;
  height: 45px !important;
}

.quiz___result__title__3MwCK {
  text-align: center;
  margin-top: var(--mb-1);
}

/*  */

.quiz_proposition__88VHo {
  margin-left: 10px;
}

.quiz_cb__39qet {
  border: 1px solid #555;
  display: inline-block;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.quiz_cb__39qet:before,
.quiz_cb__39qet:after {
  content: "";
  display: block;
  position: absolute;
}

.quiz_cb__39qet:before {
  animation: quiz_eraseA__3wfJN 0.5s steps(1) forwards;
  background: url("https://s22.postimg.cc/fi4blx4gx/checkmark.png") 0 100%/1.2em
    1.35em no-repeat;
  bottom: 0.2em;
  left: 0.3em;
  width: 0;
  height: 1.35em;
}

.quiz_cb__39qet:after {
  animation: quiz_eraseB__1LOBa 0.5s linear forwards;
  background: linear-gradient(
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 50%
      )
      11.2em 0,
    linear-gradient(#c8646e, #c8646e) 11.2em 0,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      9.8em 0/0.3em 100%,
    repeating-linear-gradient(
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em
      )
      10.2em 0/0.5em 100%,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      10.8em 0/0.3em 100%,
    linear-gradient(
        rgba(255, 255, 255, 0) 35%,
        rgba(255, 255, 255, 0.6) 35%,
        rgba(255, 255, 255, 0.6) 65%,
        rgba(255, 255, 255, 0) 65%
      )
      9.7em 0,
    linear-gradient(#b4b4b4, #b4b4b4) 9.7em 0,
    linear-gradient(
        rgba(0, 0, 0, 0.1) 27%,
        rgba(0, 0, 0, 0) 27%,
        rgba(0, 0, 0, 0) 73%,
        rgba(0, 0, 0, 0.2) 73%
      )
      2em 0,
    linear-gradient(#f0d000, #f0d000) 2em 0,
    linear-gradient(-14deg, #1e1e1e 18%, rgba(30, 30, 30, 0) 20%) 0 0/0.5em
      0.5em,
    linear-gradient(14deg, rgba(30, 30, 30, 0) 80%, #1e1e1e 83%) 0 0.5em/0.5em
      0.5em,
    linear-gradient(-14deg, #d2a078 48%, rgba(210, 160, 120, 0) 50%) 0 0/2em
      0.5em,
    linear-gradient(14deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.1) 50%) 0 0.5em/2em
      0.5em,
    linear-gradient(14deg, rgba(210, 160, 120, 0) 48%, #d2a078 50%) 0 0.5em/2em
      0.5em;
  background-repeat: no-repeat;
  border-radius: 0 0.1em 0.1em 0;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0 0.25em;
  width: 12em;
  height: 1em;
}

.quiz_cb__39qet:checked {
  background-color: transparent;
}

.quiz_cb__39qet:checked:before {
  animation: quiz_drawA__3V3A1 0.5s linear forwards;
}

.quiz_cb__39qet:checked:after {
  animation: quiz_drawB__Ab9T0 0.5s linear;
}

.quiz_cb__39qet:focus {
  outline: transparent;
}

.quiz_cb__39qet:focus + span {
  color: rgba(0, 0, 0, 0.75);
  outline: transparent;
}

.quiz_pristine__1-tNu:before,
.quiz_pristine__1-tNu:after {
  animation: none;
}

@keyframes quiz_drawA__3V3A1 {
  from,
  5% {
    width: 0;
    height: 1.35em;
  }

  90%,
  to {
    width: 1.2em;
    height: 1.35em;
  }
}

@keyframes quiz_drawB__Ab9T0 {
  from {
    opacity: 0;
    transform: translate(0em, -0.5em) rotate(-50deg);
    visibility: visible;
  }

  5% {
    opacity: 1;
    transform: translate(0em, 0.3em) rotate(-60deg);
  }

  17% {
    transform: translate(0.25em, 0.8em) rotate(-65deg);
  }

  79% {
    transform: translate(1.1em, -0.14em) rotate(-63deg);
  }

  90% {
    opacity: 1;
    transform: translate(1.2em, -0.15em) rotate(-63deg);
  }

  to {
    opacity: 0;
    transform: translate(1.25em, -1.7em) rotate(-63deg);
  }
}

@keyframes quiz_eraseA__3wfJN {
  from {
    width: 1.2em;
    height: 1.35em;
  }

  10% {
    width: 1.2em;
    height: 1.08em;
  }

  27% {
    width: 1.2em;
    height: 0.81em;
  }

  36% {
    width: 1.2em;
    height: 0.7em;
  }

  45% {
    width: 1.2em;
    height: 0.55em;
  }

  55% {
    width: 1.2em;
    height: 0.35em;
  }

  80%,
  to {
    width: 1.2em;
    height: 0;
  }
}

@keyframes quiz_eraseB__1LOBa {
  from {
    opacity: 0;
    transform: translate(0.6em, -12.2em) rotate(90deg);
    visibility: visible;
  }

  10% {
    opacity: 1;
    transform: translate(1.2em, -12.05em) rotate(90deg);
  }

  20% {
    transform: translate(0.6em, -11.9em) rotate(90deg);
  }

  30% {
    transform: translate(1.2em, -11.75em) rotate(90deg);
  }

  40% {
    transform: translate(0.6em, -11.6em) rotate(90deg);
  }

  50% {
    transform: translate(1.2em, -11.45em) rotate(90deg);
  }

  60% {
    transform: translate(0.6em, -11.3em) rotate(90deg);
  }

  70% {
    transform: translate(1.2em, -11.15em) rotate(90deg);
  }

  80% {
    transform: translate(0.6em, -11em) rotate(90deg);
  }

  90% {
    opacity: 1;
    transform: translate(2em, -12.5em) rotate(100deg);
  }

  to {
    opacity: 0;
    transform: translate(2em, -12.5em) rotate(100deg);
  }
}

@media only screen and (max-width: 500px) {
  .quiz_quiz__content__1zKrk {
    padding: 3% 2%;
  }

  .quiz_left__11TCV .quiz___avatarBoy__17ZGH {
    position: relative;
    width: 50px;
    top: -2px !important;
  }

  .quiz_right__2Zyu6 .quiz___iconTrophy__1PzmT {
    position: relative;
    width: 30px;
    top: -7px !important;
  }

  .quiz_show__counter__3h3HE {
    font-size: 1em;
  }

  .quiz_center__2Ruyy img {
    width: 20px;
  }

  .quiz_main__1pDC2 h3 {
    font-size: 1.1em;
    margin-left: 2%;
    color: var(--text-color);
  }

  .quiz_proposition__88VHo {
    font-size: 12px;
  }

  .quiz_count__down__2UxAF {
    position: relative;
    left: -1.1em;
  }
}

.cardResult_body__2-L94 {
  width: 80%;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.cardResult_cardResult__3vBLF {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 0 1em;
  border: 1px solid #333333;
}

.cardResult_body__2-L94 span {
  font-size: 18px;
}

.cardResult_body__2-L94 .cardResult_question__1RCof {
  font-size: 20px;
  margin-bottom: 10px;
}

.cardResult_isCorrect__4oBe1 {
  border: 1px solid #449F01;
}

.cardResult_isNoCorrect__3Id2p {
  border: 1px solid #FF0000;
}
.style___upload__with__ck__content__155F- {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid #707070; */
  border-radius: 10px;
  padding: 0 15px;
}

.style___round__copy__2HxgA,
.style___ck__scaner__1zMT4 {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  right: 4%;
  bottom: 1em;
  margin-left: 10px;
  cursor: pointer;
}


.style___ck__math__content__18pjO {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.style___ck__math__content__18pjO span {
  font-size: 13px;
}

.style___copy__3K_Np {
  width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-radius: 5px;
  padding: 2%;
  line-height: 15px;
  overflow: hidden;
  overflow-y: scroll;
}

.style___latext__content__2q5JZ * strong {
  font-weight: 600;
}
.style___upload__with__ck__content__3Bisu {
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid #707070; */
  border-radius: 10px;
  padding: 0 15px;
}

.style___round__copy__2hDut,
.style___ck__scaner__1g0xc {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  right: 4%;
  bottom: 1em;
  margin-left: 10px;
  cursor: pointer;
}


.style___ck__math__content__1QjB4 {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.style___ck__math__content__1QjB4 span {
  font-size: 13px;
}

.style___copy__1nhfD {
  width: 250px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-radius: 5px;
  padding: 2%;
  line-height: 15px;
  overflow: hidden;
  overflow-y: scroll;
}

.style___latext__content__MS0nC * strong {
  font-weight: 600;
}
.style_articleContainer__7ujOW {
    position: relative;
}

.style_articleContent__2_zqo {
    margin: 0;
    padding: 10px;
    border: 1px solid #ddd;
    position: relative;
}

.style_articleContent__2_zqo.style_hovered__3gYug {
    background-color: #f5f5f5;
}

.style_editButton__1nD0I {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
    color: var(--first-color);
    border: 1px solid var(--first-color);
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50px;
    text-align: center;
}

.style_editButton__1nD0I:hover {
    background-color: var(--first-color);
    border: 1px solid white;
    color: white;
}

.style_buttonIcon__kxvBk {
    position: relative;
    top: -2px;
    margin-right: 5px;
}

.style___custom__ck_editor__2bk6y {
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: var(--mb-4);
}

.style_appercue__continute__3KfD_ {
    width: 100%;
    height: 80vh;
    min-width: 600px;
    overflow-y: scroll;
    overflow-x: scroll;
    background: #fafafa;
    border-radius: 10px;
    padding: 1.5em 2em;
}

.style_appercue__continute__3KfD_ h3 {
    font-size: 18px;
    margin-bottom: var(--mb-2);
}

.style___latext__content__2RuLv * strong {
    font-weight: 600;
}
.matiers_matiers__3JG_g {
  width: auto;
  height: 32px;
  padding: 0 20px;
  background: #D5D5D5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
  margin: var(--mb-1);
}

.matiers_center__3XsZ9 {
  margin: 0 5px;
}

.matiers___cours__1lqLF *:last-child span {
  display: none;
}

.matiers_right__suujz span *:last-child span {
  display: none;
}
.exoV2_card__7fRy- {
  position: relative;
  width: 100%;
  height: 290px;
  /* min-height: 350px; */
  /* background: url('../../../../assets/images/teacher/cardAds.svg') no-repeat;
  background-size: contain; */
  background: #FAFAFA;
  margin-bottom: var(--mb-4);
  box-shadow: 3px 4px 0px 3px #CFD0D2;
}

.exoV2_card__7fRy-:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#CFD0D2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.exoV2_card__7fRy-::after {
  content: '';
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.exoV2_container__3z92C {
  width: 100%;
  height: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-2);
}

.exoV2_header__2jDZT {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.exoV2_code__19sOY {
  width: 80px;
  height: 30px;
  border: #707070 1px dashed;
  text-align: center;
  border-radius: 50px;
  display: flex;
  background: #fff;
}

.exoV2_code__19sOY span {
  font-size: var(--normal-font-size);
  margin: auto;
  font-weight: 500;
}

.exoV2_left__2JBxJ {
  display: flex;
  align-items: center;
}

.exoV2_date__2E5bQ {
  margin-left: var(--mb-3);
}

.exoV2_date__2E5bQ span strong {
  color: #519259;
  font-weight: 500;
}

.exoV2_date__2E5bQ img {
  position: relative;
  top: -2px;
}

.exoV2_text__3K0lQ {
  margin-top: var(--mb-2);
  height: 140px;
  overflow-y: scroll;
  scrollbar-width: none;
}

/* .text::-webkit-scrollbar {
  display: none;
} */

.exoV2_text__3K0lQ p {
  margin-bottom: 10px;
}

.exoV2_text__3K0lQ .exoV2___range__date__1NU0g span:last-child {
  display: none;
}

.exoV2_title__ads__17464 {
  font-size: 1.2em;
  text-transform: uppercase;
  margin-bottom: var(--mb-2);
  color: var(--title-color);
}

.exoV2_text__3K0lQ p {
  font-size: var(--small-font-size);
  color: #707070;
}

.exoV2_footer__2S4qX {
  width: 100%;
  margin-top: var(--mb-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.exoV2_footer__2S4qX .exoV2_button__L4siJ {
  min-width: 180px;
  height: 40px !important;
}

.exoV2_reference__4GnDt span {
  font-size: var(--smaller-font-size);
}

.exoV2_dropdown__3_AhO {
  width: 170px !important;
  height: 32px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.exoV2___down__1B-wW {
  border-radius: 10px !important;
}

.exoV2___down__1B-wW *:hover {
  color: var(--first-color) !important;
  background: #fff !important;
}



.exoV2___btn__footer__1zXAI {
  height: 33px !important;
  margin-left: 10px;
  max-width: 160px !important;
  min-width: 160px;
}

::-webkit-scrollbar {
  width: 8px;
}

/*  */

.exoV2___ennnoncer__RwhZ0 {
  margin-bottom: 1em;
}

.exoV2___icon_drawer__v4Ags {
  position: absolute;
  top: 5%;
  right: 5%;
}

.exoV2_title__drawer__2sB5H {
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.3em;
  text-transform: uppercase;
}

.exoV2_letter__2MImh {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 85vh;
  padding: 5em;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em !important;
}

.exoV2_letter__2MImh:before,
.exoV2_letter__2MImh:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.exoV2_letter__2MImh:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}

.exoV2_letter__2MImh:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.exoV2___button__indice__1r0rO {
  margin-right: 1em;
}


@media only screen and (max-width: 500px) {
  .exoV2_header__2jDZT {
    display: none;
  }

  .exoV2_card__7fRy- {
    height: auto;
    padding-left: 5%;
  }

  .exoV2_date__2E5bQ span {
    font-size: 12px;
    text-align: left;
  }


  .exoV2_header__2jDZT {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .exoV2_header__2jDZT {
    flex-wrap: wrap;
  }

  .exoV2_text__3K0lQ {
    height: 250px;
  }

  .exoV2_title__ads__17464 {
    font-size: 1em;
  }

  .exoV2___btn__footer__1zXAI {

    max-width: 110px !important;
    min-width: 110px;
  }

  .exoV2_letter__2MImh {
    position: relative;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 85vh;
    padding: 1em;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5em !important;
  }

}

.exoV2_action__17NfU {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {

  .exoV2_action__17NfU {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
  }

  .exoV2_footerAction__27Scu {
    width: 150px;
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    align-items: center;
    justify-content: center;
  }

}
.styles_filter__3fU-S {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  border-radius: 10px;
  margin: 10px 0;
  padding: 0 2em;
}

.styles_main__3Po-H {
  width: 100%;
  height: 100%;
  margin-top: var(--mb-4);
  margin-bottom: 4em;
  /* background: #fafafa;
  padding: 2%; */
}

.styles_search__12vfW {
  width: 32%;
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.styles_search__12vfW * button {
  background: unset !important;
  border: none !important;
}


.styles_search__12vfW * svg {
  position: relative;
  top: 2px;
  fill: var(--text-color);
  font-size: 22px;
}

.styles_select__1xtoL {
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.styles____row__iNh1p {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .styles_left__I8yL0 .styles_subtitle__2nyAC {
    font-size: var(--h2-font-size);
    max-width: 100px;
  }

  .styles_filter__3fU-S {
    padding: 0 0.3em;
    grid-gap: 2%;
    gap: 2%;
  }

  .styles_search__12vfW {
    width: 50%;
    height: 40px;
  }

  .styles_search__12vfW * button {
    font-size: 12px;
  }

  .styles_select__1xtoL {
    width: 50%;
    height: 40px;
  }
}

@media only screen and (min-width: 390px) {}

@media only screen and (min-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.styles___reset__btn__ofdiY {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.styles___submit__hidden__1hsbR {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}
.cours_cours__2VSFZ {
  position: relative;
  margin: 4em 0;
}

.cours_appercue__continute__6c0Vw {
  width: 100%;
  height: 600px;
  margin-top: var(--mb-1);
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.cours_appercue__continute__bot__1y88b {
  width: 100%;
  height: 100%;
  margin-top: var(--mb-1);
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.cours_full_appercue__continute__hy-gX {
  width: 70%;
  margin: var(--mb-1) auto;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  position: fixed;
  bottom: 0px;
  top: 12%;
  left: 0%;
  right: 0%;
}

.cours___add__input__21rxO:hover {
  color: #fff !important;
}

.cours_appercue__continute__6c0Vw h3 {
  font-size: 18px;
  margin-bottom: var(--mb-2);
}

.cours_section__content__1j3_p,
.cours___big__title__content__3SIQB {
  width: 40%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.cours_appercue__continute__content__19_Vv {
  margin-left: 5%;
  font-size: 0.8em;
}

.cours___big__title__Fj4Fm {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.cours_section__content__1j3_p {
  background: var(--first-color);
}

.cours_section__content__1j3_p h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.cours___content__matiere__Yl1HK {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 16px;
  margin-bottom: var(--mb-2);
}

.cours_header__21HGk {
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.cours_menu__37hOg {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cours_logo__17jvy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1050px) {
  .cours_appercue__continute__6c0Vw {
    min-width: 100%;
    padding: 1em;
  }

  .cours_appercue__continute__6c0Vw h3 {
    font-size: 17px;
  }

  .cours_section__content__1j3_p,
  .cours___big__title__content__3SIQB {
    width: auto !important;
    padding-right: 0%;
    padding-top: 0em;
  }

  .cours___big__title__Fj4Fm,
  .cours_section__content__1j3_p h4 {
    line-height: 30px !important;
    padding-left: 1em;
  }
}
.chat_menuButton__27qbZ {
    background: #FAFAFA !important;
    margin-bottom: 25px;
    border: 1px solid #FAFAFA !important;
    padding-left: 0px !important;
}

.chat_newConversationButton__1td-3 {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 1em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-bottom: 10px !important;
    cursor: pointer;
}

.chat_actionButton__nqS79 {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 50px !important;
    font-size: 1em !important;
    text-transform: none;
    text-align: center;
    background: #FAFAFA !important;
    color: #707070 !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FAFAFA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FAFAFA;
    -moz-box-shadow: 2px 4px 1px -1px #FAFAFA;
    padding-left: 0px !important;
    padding-right: 20px !important;
    margin-bottom: 10px !important;
    cursor: pointer;
}

.chat_actionButton__nqS79:hover {
    color: #80b1b2;
}

.chat_lineSeparator__2fCDj {
    height: 1px;
    width: 100%;
    background: #707070;
    margin-top: 15px;
    margin-bottom: 10px;
    opacity: 0.7;
}

.chat_userAvatarBox__3L13A {
    width: 100%;
    height: 65px;
    background: transparent;
    border: 1px solid #868686;
    border-radius: 20px;
    padding: 10px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    margin-top: 40px;
}

.chat_userAvatarBoxMenuClosed__3gsFZ {
    width: 65px;
    height: 65px;
    background: transparent;
    border: 1px solid #868686;
    border-radius: 50%;
    padding: 10px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 30px;
}

.chat_userNameBox__3A5dL {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    color: #393E3E;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}

.chat_userNameBox__3A5dL span {
    color: #393E3E;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 400;
}

.chat_chatBox__2iViS {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B9B9B9;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    height: 89vh;
    width: 75%
}

.chat_chatBoxMenuClosed__2R2uP {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B9B9B9;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    height: 89vh;
    width: 110%
}

.chat_emptyChat__35e98 {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-bottom: 7%;
    margin-top: 7%;
    padding-left: 7%;
    padding-right: 7%;
    width: 100% !important;
}

.chat_emptyChatAlbert__Gdk1n {
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
}

.chat_emptyChatAlbertMessage__3p37M {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-left: 5%;
    font-size: 30px;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
    width: 100%;
}

.chat_messageSubtitle__1_Icl {
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    width: 40vw !important;
}

.chat_topicsBox__PgXZY {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
    gap: 25px;
    width: 100%;
    margin-top: 5%;
}

.chat_topicButton__3ErOS {
    height: 65px;
    width: 210px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    box-shadow: 0px 3px 10px #BFBCBC3D;
    border: 1px solid #868686;
    border-radius: 18px;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
}

.chat_messagesBox__1jfVG {
    padding: 10px;
    overflow-y: scroll;
    scrollbar-width: thin;
    height: 73vh !important;
}

.chat_messagesBoxImagePreview__1iaTp {
    padding: 10px;
    overflow-y: scroll;
    scrollbar-width: thin;
    height: 60vh !important;
}

.chat_inputBox__2kfn4 {
    padding: 10px;
    position: absolute;
    bottom: 100px;
    left: 27%;
    right: 3%;
    z-index: 77;
}

.chat_inputBoxClosedMenu__116Xa {
    padding: 10px;
    position: absolute;
    bottom: 120px;
    left: 12%;
    right: 3%;
    z-index: 77;
}

.chat_inputTextarea__A_FhJ {
    /* height: 100px !important; */
    width: 100%;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    padding: 10px;
    z-index: 77;
}

.chat_inputTextareaClosedMenu__1ZQbd {
    /* height: 100px !important; */
    width: 100%;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    padding: 10px;
    z-index: 77;
}

.chat_messageButtonRow__3BIpV {
    width: 24vw;
    height: 40px;
    position: relative;
    top: -20px;
    left: 10%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.chat_messageButtonRowAI__3rusr {
    width: 24vw;
    height: 40px;
    position: relative;
    top: -20px;
    left: 10%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.chat_albertAvatar__3e86q {
    height: 52px;
    width: 52px;
    border: 1.5px solid #FFDCAB;
    background: #FFF;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 5px;
}

.chat_userAvatar__2CK-v {
    height: 52px;
    width: 52px;
    border: 1.5px solid #FFDCAB;
    background: #FFF;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute;
    right: 1.5%;
    margin-top: 5.5%;
}

.chat_messageActionButton__3_qBD {
    height: 25px;
    width: auto;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border: 0.5px solid #B9B9B9;
    border-radius: 5px;
    font-size: 10px;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    background: #FFF;
    margin-left: 10px;
}

.chat_footer-content__3xLJT {
    white-space: pre-wrap;
    line-height: 1.5;
    padding: 1rem;
}
.footer_footer__1wBRH {
  width: 100%;
  height: 100%;
  /* background-color: #FAFAFA; */
  /* margin-top: var(--mb-6); */
  /* padding-top: 3em; */
}

.footer_title__footer__oAw2G {
  text-transform: uppercase;
  text-align: center;
  font-size: var(--biggest-font-size);
}

.menu_menu__modal__1eWWs {
  position: relative !important;
  border-radius: 20px !important;

}

.menu_ant-modal-content__RvR_s {
  border-radius: 10px !important;
}


.menu_row__28Ckb {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.menu_container__2zWL7 {
  width: 100%;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;

}

.menu_card__16pHx {
  width: 230px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background: #fafafa;
  margin-bottom: var(--mb-2);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease-in;
}

.menu_card__16pHx span {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 1.2em;
  font-family: "CartoonFont";
  transition: all 0.5s ease-in;
  font-weight: 500;
}

.menu_card__16pHx:hover span {
  color: #fff !important;
}

.menu_card__16pHx:hover {
  background: #707070;
}

.menu_close__398f9 {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.menu_icon__2fjYC {
  height: 55px;
  width: 55px;
  border: 1px solid #FFF;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@media only screen and (max-width: 500px) {
  .menu_row__28Ckb {
    align-items: center;
    justify-content: space-around;
  }

  .menu_card__16pHx {
    width: 145px;
    height: 145px;
  }
}
.user_header_header__3WtUd {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 14px #70707038;
}

.user_header_headerTeacher__2feqx {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 14px #70707038;
}

.user_header_menu__1hdxS {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user_header_menuTeacher__3IG05 {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user_header_nav__bar__20xgC .user_header_nav__item__2h7Dz {
  display: flex;
  align-items: center;
}

.user_header_nav__bar__20xgC .user_header_nav__item__2h7Dz li {
  text-align: center;
  padding: 0 0.5em;
  line-height: 20px;
  cursor: pointer;
}

.user_header_nav__bar__20xgC .user_header_nav__item__2h7Dz li a {
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 500 !important;
  transition: all 0.3s;
  font-size: 0.85em;
}

.user_header_nav__bar__20xgC .user_header_nav__item__2h7Dz li a:hover {
  color: var(--first-color);
}

.user_header_user__1chNS {
  display: flex;
  padding: 0.5em 1em !important;
  height: 55px;
  align-items: center;
  background: #f7f6f2;
  margin-right: 8px;
  border-radius: 50px;
}

.user_header_user__1chNS .user_header_user__info__2Pyb1 {
  text-align: left;
}

.user_header_user__1chNS .user_header_user__info__2Pyb1 span {
  margin-left: 6px;
  font-weight: 400;
  font-size: 12px;
}

.user_header_user__1chNS .user_header_user__info__2Pyb1 span:nth-child(2) {
  color: var(--first-color);
}

.user_header_span__list__1pBtE {
  width: 200px;
  /* overflow-y: scroll; */
  display: block;
}

/* .__header__notif {
  position: relative;
  top: -55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.user_header___header__notif__2cf23 {
  position: absolute;
  top: -16px;
  right: 0;
  font-size: 8px !important;
}

.user_header_nav__bar_mobile__Gr8a1,
.user_header_mobile_menu__1BX9H {
  display: none;
}

/* Prof */

.user_header___prof__2ValG {
  background: var(--first-color);
  padding-left: 10px !important;
  padding-right: 15px !important;
  line-height: 15px !important;
  box-shadow: 3px 6px 1px -1px #b0e0e1;
}

.user_header___prof__2ValG * {
  color: #fff !important;
}

.user_header___bar__3vc2W {
  width: 100%;
  height: 10px;
  border-radius: 15px;
  background: #b0e0e1fa;
  box-shadow: 2px 3px 0px -1px #356672;
  /* box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3); */
}

.user_header_progress__2g-1T {
  height: 10px;
  border: 2px solid #b0e0e1fa;
  background: #c5101f;
  border-radius: 15px;
  margin-left: 2%;
}

.user_header___user__right__34pr3 {
  line-height: 5px;
  text-align: right;
}

.user_header___frend__17_kh {
  font-size: 10px;
  padding-left: 5px;
  text-transform: uppercase;
  /* fontStyle: "11px" InfoIcon, */
}

.user_header_cercle__2S_uO {
  position: relative;
  width: 22px;
  height: 22px;
  background: url(/static/media/cercle.f78b2af8.svg) center center no-repeat;
  background-size: cover;
  left: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.user_header_header__menu__3lkLe {
  display: none;
}

.user_header_userPointSmall__13_d3 {
  position: relative;
  top: 2px;
  display: block;
  padding-left: 6px;
  text-align: right;
  font-style: 8px;
}

.user_header_topBar__2iICR {
  height: 50px;
  width: 100%;
  background: #00AAB4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 400;
}

.user_header_topBarStudent__2ogJf {
  height: 50px;
  width: 100%;
  background: #00AAB4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 400;
}

.user_header_parentOrganismeName__10d98 {
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
}

.user_header_parentOrganismeName__10d98 {
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
}

.user_header_lottieBox__33tr1 {
  height: 50px;
  width: 50px;
}

.user_header___logout__10LS9 {
  position: absolute;
  top: 1em;
  right: 10%;
  cursor: pointer;
}

.user_header_albertBox__1cmas {
  background: #FFF;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
}

@media only screen and (max-width: 408px) {

  .user_header_user__1chNS {
    width: 100% !important;
    margin: 0px auto;
  }

  .user_header_user__1chNS .user_header_user__info__2Pyb1 {
    display: none;
  }

}

.user_header_newNavBar__2vDG8 {
  display: flex;
  height: 75px;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}

.user_header_navbarItemBox__Amr97 {
  height: 36px;
  width: 36px;
  background: #F7F6F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.user_header_navbarItemMenu__2jAtr {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.user_header_menuItem__CQujh {
  width: 19% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_header_menuItemStudent__2oXrm {
  width: 19% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {

  .user_header_user__1chNS {
    width: 100%;
    height: 50px;
    padding: 0.5em !important;
    position: relative;
    top: -6px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .user_header_userPointSmall__13_d3 {
    position: relative;
    top: 2px;
    left: -15px;
    display: block;
    padding-left: 6px;
    text-align: right;
    font-style: 8px;
  }

  .user_header___frend__17_kh {
    font-size: 10px;
    padding-left: 5px;
    text-transform: uppercase;
    position: relative;
    left: -15px;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz li {
    text-align: center;
    padding: 0 0.45em;
    line-height: 10px;
    cursor: pointer;
    position: relative !important;
    bottom: 0px;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz li a {
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 500 !important;
    transition: all 0.3s;
    font-size: 0.55em;
    white-space: wrap;
    text-overflow: ellipsis;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz li a:hover {
    color: var(--first-color);
  }

  .user_header___logout__10LS9 {
    position: absolute;
    top: 1em;
    right: 10%;
    cursor: pointer;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1017px) {

  .user_header_user__1chNS {
    width: 185px;
    height: 50px;
    padding: 0.5em !important;
    position: relative;
    top: -6px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz li {
    text-align: center;
    padding: 0 0.5em;
    line-height: 20px;
    cursor: pointer;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz li a {
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 500 !important;
    transition: all 0.3s;
    font-size: 0.6em;
    white-space: wrap;
    text-overflow: ellipsis;
  }

  .user_header_nav__bar_mobile__Gr8a1 .user_header_nav__item__2h7Dz li a:hover {
    color: var(--first-color);
  }

}

@media only screen and (max-width: 1050px) {
  .user_header_nav__bar_mobile__Gr8a1 {
    height: 65px !important;
    width: 100% !important;
    background-color: white;
    display: block !important;
    box-sizing: border-box;
    position: fixed;
    padding: 0;
    padding-top: 0.5em;
    bottom: 0;
    border-radius: 4px;
    left: 0;
    box-shadow: 0px 3px 14px #70707038 !important;
    z-index: 30000;
  }
}

@media only screen and (max-width: 1151px) {

  .user_header_header__3WtUd {
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 14px #70707038;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    background: #FFF;
  }

  .user_header_headerTeacher__2feqx {
    width: 100% !important;
    height: 65px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 14px #70707038;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    background: #FFF;
  }

  .user_header_topBar__2iICR {
    height: 50px;
    width: 100%;
    background: #00AAB4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    font-family: "Poppins";
  }

  .user_header_topBarStudent__2ogJf {
    height: 50px;
    width: 100%;
    background: #00AAB4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
    color: white;
    font-family: "Poppins";
  }

  .user_header_menu__1hdxS {
    width: 100%;
    height: 40px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .user_header_header__menu__3lkLe {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-top: 0em;
  }

  .user_header_pseudo__student__3ahQI {
    line-height: 10px;
  }

  .user_header_mobile_menu__1BX9H {
    display: block;
  }

  .user_header_nav__bar__20xgC .user_header___isMobile__2yvUE {
    display: none;
  }

  .user_header_user__1chNS .user_header_user__info__2Pyb1 span {
    font-size: 11px;
  }

  .user_header_avatar__2B3BJ {
    padding-left: 5px;
  }

  .user_header_albertBox__1cmas {
    background: #FFF;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
  }

  .user_header_nav__bar__20xgC .user_header_nav__item__2h7Dz li a {
    font-size: 0.55em;
  }

  .user_header_nav__bar__20xgC .user_header_nav__item__2h7Dz {
    justify-content: space-between;
    transform: translateY(5px);
  }

  .user_header_nav__bar__20xgC .user_header_nav__item__2h7Dz li {
    padding: 0 0.2em;
  }

  .user_header_notifIcon__HZb6C {
    margin-right: 20px;
  }

  .user_header_logoContainer__1Gokw {
    height: 40px !important;
    width: auto !important;
    display: block;
  }

}

.user_header_menuButton__28Wmf {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #00AAB4 !important;
  color: #FFF !important;
  font-weight: 400 !important;
  border: 1px solid #FFF !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-left: 10px;
  cursor: pointer;
}

.user_header_logoContainer__1Gokw {
  height: 90px;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.user_header_logoTitleHeader__2w3KY {
  font-size: 18px;
  color: #393E3E;
  font-family: "Margin DEMO";
  line-height: 18px;
  font-weight: normal;
  margin-top: 0px;
}
.style_navbar__1LMuf {
    height: 80%;
    background: #80B1B2;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
    -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
}

.style_userNavInfoBox__16V-r {
    height: auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.style_userNavBox__zYq6u {
    height: 48%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_infoBox__UV9KW {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_infoBoxHoverSchool__-4W-9 {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_infoBoxHoverNat__GuLGl {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_info__2NBkI {
    height: 100%;
    width: 100%;
    background: #669E9D;
    border-radius: 4px;
    color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 2px;
    align-items: center;
    object-fit: contain;
}

.style_info__2NBkI .style_left__3Nuor {
    font-size: 10px;
    object-fit: contain;
}

.style_info__2NBkI .style_leftText__2h2Dl {
    position: relative;
    top: -1px;
    object-fit: contain;
}


.style_info__2NBkI .style_leftTextHover__22afZ {
    position: relative;
    top: -1px;
    object-fit: contain;
}

.style_infoBoxHoverSchool__-4W-9:hover::after {
    content: 'Etablissement';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.style_infoBoxHoverNat__GuLGl:hover::after {
    content: 'National';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.style_info__2NBkI .style_right__1gwAM {
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.style_coinsInfo__KMNOt {
    font-size: 7px;
    margin-left: 2px;
    position: relative;
    top: -2px;
}

.style_classementInfo__uSlD4 {
    font-size: 7px;
    margin-left: 0px;
    position: relative;
    top: -2px;
}

.style_infoPic__c7W7w {
    margin-left: 7px;
}

@media only screen and (max-width: 767px) {
    .style_navbar__1LMuf {
        height: 80%;
        width: 100%;
        background: #80B1B2;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
        -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
    }

    .style_userNavInfoBox__16V-r {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .style_userNavBox__zYq6u {
        height: 85%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 1px;
    }

    .style_infoBox__UV9KW {
        height: 100%;
        width: 54px;
        background: #A3E2E3;
        border-radius: 4px;
        padding: 2px;
        margin-left: 4px;
    }

    .style_info__2NBkI {
        height: 100%;
        width: 100%;
        background: #669E9D;
        border-radius: 4px;
        color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
        align-items: flex-start;
        object-fit: contain;
    }

    .style_info__2NBkI .style_left__3Nuor {
        font-size: 0.6em;
        object-fit: contain;
    }

    .style_info__2NBkI .style_leftText__2h2Dl {
        position: relative;
        top: -1px;
        object-fit: contain;
    }

    .style_info__2NBkI .style_right__1gwAM {
        font-weight: 500;
        font-size: 0.6em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        object-fit: contain;
    }

    .style_coinsInfo__KMNOt {
        font-size: 7px;
        margin-left: 2px;
        position: relative;
        top: -2px;
    }

    .style_classementInfo__uSlD4 {
        font-size: 7px;
        margin-left: 0px;
        position: relative;
        top: -2px;
    }

    .style_infoPic__c7W7w {
        margin-left: 4px;
        margin-bottom: 3px;
        margin-right: 4px;
    }
}
.style_menu__3FYIM [type="checkbox"]:checked,
.style_menu__3FYIM [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.style_menu_icon__34UNP:checked + label,
.style_menu_icon__34UNP:not(:checked) + label {
  position: fixed;
  top: 35px;
  right: 35px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;
}
.style_menu_icon__34UNP:checked + label:before,
.style_menu_icon__34UNP:not(:checked) + label:before {
  position: absolute;
  content: "";
  display: block;
  width: 30px;
  height: 20px;
  z-index: 20;
  top: 0;
  left: 0;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: border-width 100ms 1500ms ease,
    top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1),
    height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1),
    background-color 200ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.style_menu_icon__34UNP:checked + label:after,
.style_menu_icon__34UNP:not(:checked) + label:after {
  position: absolute;
  content: "";
  display: block;
  width: 22px;
  height: 2px;
  z-index: 20;
  top: 10px;
  right: 4px;
  background-color: #fff;
  margin-top: -1px;
  transition: width 100ms 1750ms ease, right 100ms 1750ms ease,
    margin-top 100ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.style_menu_icon__34UNP:checked + label:before {
  top: 10px;
  transform: rotate(45deg);
  height: 2px;
  background-color: #fff;
  border-width: 0;
  transition: border-width 100ms 340ms ease,
    top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    background-color 200ms 500ms ease,
    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}
.style_menu_icon__34UNP:checked + label:after {
  width: 30px;
  margin-top: 0;
  right: 0;
  transform: rotate(-45deg);
  transition: width 100ms ease, right 100ms ease, margin-top 100ms 500ms ease,
    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}

.style_nav__2TQAC {
  position: fixed;
  top: 14px;
  right: 20px;
  display: block;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  z-index: 9;
  overflow: hidden;
  background-color: var(--first-color);
  opacity: 0.9;
  animation: style_border-transform__2d0v4 7s linear infinite;
  transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),
    right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 250ms 1100ms ease,
    width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1),
    height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
@keyframes style_border-transform__2d0v4 {
  0%,
  100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.style_menu_icon__34UNP:checked ~ .style_nav__2TQAC {
  animation-play-state: paused;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 200%;
  height: 200%;
  transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1),
    right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 700ms ease,
    width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1),
    height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1);
}

.style_nav__2TQAC ul {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 6;
  text-align: center;
  transform: translateY(-50%);
  list-style: none;
}
.style_nav__2TQAC ul li {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 10px 0;
  text-align: center;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: all 250ms linear;
}
.style_nav__2TQAC ul li:nth-child(1) {
  transition-delay: 200ms;
}
.style_nav__2TQAC ul li:nth-child(2) {
  transition-delay: 150ms;
}
.style_nav__2TQAC ul li:nth-child(3) {
  transition-delay: 100ms;
}
.style_nav__2TQAC ul li:nth-child(4) {
  transition-delay: 50ms;
}
.style_nav__2TQAC ul li a {
  font-size: 3vh;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 0.7em;
  font-weight: 600 !important;
  display: inline-block;
  position: relative;
  color: #fff;
  font-family: var(--title-font);
  transition: all 250ms linear;
}

.style_menu_icon__34UNP:checked ~ .style_nav__2TQAC ul li {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 350ms ease, transform 250ms ease;
}
.style_menu_icon__34UNP:checked ~ .style_nav__2TQAC ul li:nth-child(1) {
  transition-delay: 1400ms;
}
.style_menu_icon__34UNP:checked ~ .style_nav__2TQAC ul li:nth-child(2) {
  transition-delay: 1480ms;
}
.style_menu_icon__34UNP:checked ~ .style_nav__2TQAC ul li:nth-child(3) {
  transition-delay: 1560ms;
}
.style_menu_icon__34UNP:checked ~ .style_nav__2TQAC ul li:nth-child(4) {
  transition-delay: 1640ms;
}
.style_menu_icon__34UNP:checked ~ .style_nav__2TQAC ul li:nth-child(5) {
  transition-delay: 1740ms;
}

@media only screen and (min-width: 974px) {
  .style_menu__3FYIM {
    display: none;
  }
}

.headers_app__header__1Wrv1 {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headers_nav__2-h-g {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headers_nav__links__3cNoi {
  display: flex;
  align-items: center;
}

.headers_nav__links__3cNoi .headers_nav__item__1AIAF a span {
  text-align: center;
}

.headers_nav__links__3cNoi .headers_nav__item__1AIAF {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
}

.headers_nav__links__3cNoi .headers_nav__item__1AIAF a {
  padding: 0 var(--mb-3);
  color: #455a64;
  font-size: 1.5em;
  font-family: var(--title-font);
  font-weight: 500 !important;
  line-height: 18px;
}

.headers_nav__links__3cNoi .headers_nav__item__1AIAF a:hover {
  color: var(--first-color);
}

.headers_nav__links__3cNoi .headers_nav__item__1AIAF a img {
  position: relative;
  width: 15px;
  left: -15px;
  top: 1px;
}

.headers_button__2EqQN {
  border: none;
  padding: 0.5em 2em;
  background: var(--first-color);
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

@media only screen and (max-width: 974px) {
  .headers_nav__links__3cNoi {
    display: none;
  }

  .headers_app__header__1Wrv1 {
    position: relative;
    padding: 0;
    height: 60px;
    margin-bottom: var(--mb-2);
  }
}

@media only screen and (max-width: 500px) {

  .headers_nav__links__3cNoi li:nth-child(3),
  .headers_nav__links__3cNoi li:first-child {
    display: none;
  }

  .headers_nav__links__3cNoi .headers_nav__item__1AIAF a {
    font-size: var(--smaller-font-size);
    padding: 0 6px;
  }

  .headers_nav__2-h-g .headers_logo__3gfxh a img {
    width: 150px;
  }
}

.headers_field__QOgix {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
}

.headers_field__QOgix::placeholder {
  font-style: italic !important;
}
.style_bodyDropDow__1buBL {
  width: 400px;
  height: 450px;
  padding: 1.5em 1em !important;
  background: #faeb89 !important;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px !important;
}

.style_dropdow__item__3VmRe {
  width: 100%;
  padding: 10px !important;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 0.9em !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* overflow-y: scroll; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.style_dropdown__row__2Habs {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.style_title__2VTav h3 {
  font-size: 12px;
}

.style_msg__tmFJU {
  width: auto;
  margin-bottom: 0;
  white-space: normal;
  /* Ensure the text can wrap */
  word-wrap: break-word;
  /* Break long words if necessary */
  overflow: hidden;
  /* Hide overflow if text is too long */
  text-overflow: ellipsis;
  /* Add ellipsis if the text overflows */
}

.style_notif__btn__32pqF button {
  border: unset;
  font-size: 11px;
  /* margin-top: 10px; */
  padding: 3px 7px;
  border-radius: 5px;
  cursor: pointer;
  background: #439f017d;
  color: #fff;
  font-weight: 500;
}

.style_menu__dropdow__list__2Vtcj:hover {
  background: #dedede !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.style_seen__1qUFc {
  background: #dedede;
  z-index: 1;
}

.style_dropdown__text__3c6ue {
  margin-left: 10px;
}

.style_dropdown__text__3c6ue span {
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.style_dropdown__text__3c6ue .style_span__list__27Kxw {
  font-weight: 500;
}

.style_dropdown__text__3c6ue .style___notif__description__2fb9- {
  font-weight: 400;
}

.style_buttonRow__MqzWa {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.style_navbar__zXYNB {
    height: 80%;
    width: 380px;
    background: #80B1B2;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
    -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
}

.style_userNavInfoBox__2k0tg {
    height: auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.style_userNavBox__1JO5h {
    height: 48%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_infoBox__3d3aU {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_infoBoxHoverSchool__3vPEz {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_infoBoxHoverNat__2Pvkv {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_info__32OF4 {
    height: 100%;
    width: 100%;
    background: #669E9D;
    border-radius: 4px;
    color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 2px;
    align-items: center;
    object-fit: contain;
}

.style_info__32OF4 .style_left__2I2KB {
    font-size: 10px;
    object-fit: contain;
}

.style_info__32OF4 .style_leftText__38Dcx {
    position: relative;
    top: -1px;
    object-fit: contain;
}


.style_info__32OF4 .style_leftTextHover__2430q {
    position: relative;
    top: -1px;
    object-fit: contain;
}

.style_infoBoxHoverSchool__3vPEz:hover::after {
    content: 'Etablissement';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.style_infoBoxHoverNat__2Pvkv:hover::after {
    content: 'National';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
}

.style_info__32OF4 .style_right__2GKq5 {
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.style_coinsInfo__1BFs3 {
    font-size: 7px;
    margin-left: 2px;
    position: relative;
    top: -2px;
}

.style_classementInfo__1HYjt {
    font-size: 7px;
    margin-left: 0px;
    position: relative;
    top: -2px;
}

.style_infoPic__1U-Uq {
    margin-left: 7px;
}

@media only screen and (max-width: 767px) {
    .style_navbar__zXYNB {
        height: 80%;
        width: 100%;
        background: #80B1B2;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
        -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
    }

    .style_userNavInfoBox__2k0tg {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .style_userNavBox__1JO5h {
        height: 85%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 1px;
    }

    .style_infoBox__3d3aU {
        height: 100%;
        width: 54px;
        background: #A3E2E3;
        border-radius: 4px;
        padding: 2px;
        margin-left: 4px;
    }

    .style_info__32OF4 {
        height: 100%;
        width: 100%;
        background: #669E9D;
        border-radius: 4px;
        color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
        align-items: flex-start;
        object-fit: contain;
    }

    .style_info__32OF4 .style_left__2I2KB {
        font-size: 0.6em;
        object-fit: contain;
    }

    .style_info__32OF4 .style_leftText__38Dcx {
        position: relative;
        top: -1px;
        object-fit: contain;
    }

    .style_info__32OF4 .style_right__2GKq5 {
        font-weight: 500;
        font-size: 0.6em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        object-fit: contain;
    }

    .style_coinsInfo__1BFs3 {
        font-size: 7px;
        margin-left: 2px;
        position: relative;
        top: -2px;
    }

    .style_classementInfo__1HYjt {
        font-size: 7px;
        margin-left: 0px;
        position: relative;
        top: -2px;
    }

    .style_infoPic__1U-Uq {
        margin-left: 4px;
        margin-bottom: 3px;
        margin-right: 4px;
    }
}
.style_userBox__2qNpr {
    height: 55px;
    width: 90%;
    background: #A3E2E3;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #80B1B2 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #80B1B2;
    -moz-box-shadow: 3px 3px 1px 3px #80B1B2;
    margin: 10px auto;
}

.style_currentUserBox__r87ba {
    height: 55px;
    width: 90%;
    background: #80B1B2;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
    -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
    margin: 10px auto;
}

.style_userNavInfoLeft__2AaDY {
    height: auto;
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 5px;
}

.style_userNavInfoRight__1DumY {
    height: auto;
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.style_currentUserRight__3NHZa {
    font-weight: 500;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
}

.style_right__1N1WL {
    font-weight: 500;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #707070;
}

.style_currentUserClassementInfo__3enWd {
    font-size: 0.4em;
    margin-left: 0px;
    position: relative;
    top: -5px;
    color: white;
}

.style_classementInfo__3M8NJ {
    font-size: 0.4em;
    margin-left: 0px;
    position: relative;
    top: -5px;
    color: #707070;
}

.style_currentUserName__25rwM {
    font-size: 0.85em;
    font-weight: 500;
}

.style_userName__2p_vJ {
    font-size: 0.85em;
    font-weight: 500;
    color: #707070;
}

.style_currentUserLastname__3z7CQ {
    font-size: 0.9em;
    font-weight: 600;
}

.style_userLastname__1SOVN {
    font-size: 0.9em;
    font-weight: 600;
    color: #707070;
}

.style_schoolUserName__2BtJ3 {
    width: 250px;
    height: 35px;
    overflow: hidden;
    font-size: 1em;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.style_schoolUserName__2BtJ3:hover {
    overflow: visible;
}

@media only screen and (max-width: 767px) {

    .style_userBox__2qNpr {
        height: 55px;
        width: 90%;
        background: #A3E2E3;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #80B1B2 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #80B1B2;
        -moz-box-shadow: 3px 3px 1px 3px #80B1B2;
        margin: 10px auto;
    }

    .style_currentUserBox__r87ba {
        height: 55px;
        width: 90%;
        background: #80B1B2;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
        -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
        margin: 10px auto;
    }

    .style_userNavInfoLeft__2AaDY {
        height: auto;
        width: 15%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .style_userNavInfoRight__1DumY {
        height: auto;
        width: 35%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .style_currentUserRight__3NHZa {
        font-weight: 500;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
    }

    .style_right__1N1WL {
        font-weight: 500;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #707070;
    }

    .style_currentUserClassementInfo__3enWd {
        font-size: 0.4em;
        margin-left: 0px;
        position: relative;
        top: -5px;
        color: white;
    }

    .style_classementInfo__3M8NJ {
        font-size: 0.4em;
        margin-left: 0px;
        position: relative;
        top: -5px;
        color: #707070;
    }

    .style_schoolUserName__2BtJ3 {
        width: 250px;
        height: 35px;
        overflow: hidden;
        font-size: 1.2em;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .style_schoolUserName__2BtJ3:hover {
        overflow: visible;
    }
}
.style_top_righ__3YO-d {
  position: absolute;
  width: 550px;
  height: 550px;
  -webkit-user-select: none;
  user-select: none;
  transform: translate3d(25px, -25px, 0);
  color: #fff;
  top: 12em;
  transition: transform 0.5s cubic-bezier(0.3, 1.4, 0.5, 0.9);
}

.style_top_righ__3YO-d.style_open__3lgD9 {
  transform: translate3d(0, 0, 0);
}

.style_avatar__1v-NF {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  background: #FFF;
  padding: 2.5px;
}

.style_disc__2bvIf {
  position: absolute;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font: 25px Alegreya Sans, sans-serif;
  line-height: 40px;
  padding-top: 10px;
  border-radius: 285px;
  transform: scale3d(0.5, 0.5, 0.5) rotate3d(0, 0, 1, 0deg);
  pointer-events: none;
  opacity: 0;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.3, 1.4, 0.5, 0.9), opacity 0.5s;
}

.style_disc__2bvIf div {
  transform: rotate(0deg);
}

.style_open__3lgD9 .style_disc__2bvIf {
  pointer-events: auto;
  opacity: 1;
  color: #fff;
}

.style_l1__gtbYq {
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: #5f9b9cae;
  transition-delay: 0s;
}

.style_open__3lgD9 .style_l1__gtbYq {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.style_open__3lgD9 .style_l1__gtbYq:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.style_open__3lgD9 .style_l1__gtbYq:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.style_open__3lgD9 .style_l1__gtbYq.style_toggle__Rh2HK {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.style_l2__2mOUn {
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background: #337e7f70;
  transition-delay: 0.1s;
}

.style_open__3lgD9 .style_l2__2mOUn {
  transition-delay: 0.1s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.style_open__3lgD9 .style_l2__2mOUn:hover {
  background: shade(#5f9b9c, 90%);
  color: #fff;
  transition-delay: 0s;
}

.style_open__3lgD9 .style_l2__2mOUn:active {
  background: shade(#5F9B9C, 50%);
  color: #fff;
}

.style_open__3lgD9 .style_l2__2mOUn.style_toggle__Rh2HK {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.style_l3__a0fm9 {
  top: 115px;
  left: 115px;
  right: 115px;
  bottom: 115px;
  background: #337e7f8a;
  transition-delay: 0.2s;
}

.style_open__3lgD9 .style_l3__a0fm9 {
  transition-delay: 0.2s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.style_open__3lgD9 .style_l3__a0fm9:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.style_open__3lgD9 .style_l3__a0fm9:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.style_open__3lgD9 .style_l3__a0fm9.style_toggle__Rh2HK {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.style_l4__AHG9Y {
  top: 175px;
  left: 175px;
  right: 175px;
  bottom: 175px;
  background: #337e7f9c;
  transition-delay: 0.3s;
  /* border: 2px solid red; */
}

.style_open__3lgD9 .style_l4__AHG9Y {
  transition-delay: 0.3s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.style_open__3lgD9 .style_l4__AHG9Y:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.style_open__3lgD9 .style_l4__AHG9Y:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.style_open__3lgD9 .style_l4__AHG9Y.style_toggle__Rh2HK {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.style_l5__3M1oA {
  top: 225px;
  left: 225px;
  right: 225px;
  bottom: 225px;
  background: #337E7F;
  transition-delay: 0.4s;
}

.style_open__3lgD9 .style_l5__3M1oA {
  transition-delay: 0.4s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.style_open__3lgD9 .style_l5__3M1oA:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.style_open__3lgD9 .style_l5__3M1oA:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.style_open__3lgD9 .style_l5__3M1oA.style_toggle__Rh2HK {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.style_toggle__Rh2HK {
  line-height: 100px;
  padding: 0;
  width: 100px;
  background: #337e7fed;
  opacity: 1;
  transform: none;
  pointer-events: auto;
  transition-delay: 0s;
}

.style_open__3lgD9 .style_toggle__Rh2HK {
  transform: rotate3d(0, 0, 1, 0deg);
}

.style_toggle__Rh2HK:hover {
  background: shade(#fff, 90%);
  /* text-decoration: underline; */
}

.style_toggle__Rh2HK:active {
  background: shade(#fff, 50%);
  color: rgba(255, 215, 0, 0.5);
  transform: scale(0.9);
  transition-duration: 0s;
}

.style_content__3B2Ko {
  position: relative;
  width: auto;
  text-align: left;
  right: -60%;
  line-height: 8px;
  z-index: 99 !important;
}

.style_content__3B2Ko h3 {
  font-family: var(--title-font);
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  padding: 0;
  margin: 0;
  line-height: 30px;
}



.style_content__3B2Ko span {
  font-size: 13px;
  font-weight: 600;
}

.style___tag__jeckD {
  padding: 6px 12px;
  background: #FAEB89;
  margin-left: 10px;
  border-radius: 15px;
  color: #337E7F;
  display: flex;
}

.style___tag__jeckD span {
  margin: auto;
  font-size: 12px;
}

.style___info__34CIn {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #337E7F;
  margin-left: 4px;
  display: flex;
}

.style___info__34CIn span {
  margin: auto;
  font-size: 7px;
  color: #fff;
}

.style___row__2Rb0g {
  display: flex;
  width: 300px;
  height: 25px;
}

/*  */
.style_l4__AHG9Y .style_content__3B2Ko {
  position: relative;
  top: 65px;
  margin-left: 55px;
}

.style_l3__a0fm9 .style_content__3B2Ko {
  position: relative;
  top: 40px;
  margin-left: 40px;
}

.style_l2__2mOUn .style_content__3B2Ko {
  position: relative;
  top: 20px;
  margin-left: 25px;
}

.style_l1__gtbYq .style_content__3B2Ko {
  position: relative;
  top: 5px;
  margin-left: 15px;
}
.style_modal_container__3qG1m {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  transition: visibility 0.4s;
  visibility: hidden;
  z-index: 9;
}

.style_modal_container__3qG1m.style_active__14oNN {
  visibility: visible;
}

.style_overlay__3HlKi {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #333333d3;
  transition: opacity 0.4s 0.2s ease-out;
}

.style_modal_container__3qG1m.style_active__14oNN .style_overlay__3HlKi {
  opacity: 1;
  transition: opacity 0.4s ease-out;
}

.style___header__3XENC {
  text-align: center;
  color: #fff;
  font-size: 13px;
}

.style___header__3XENC h2 {
  color: #fff;
}

.style_modal__5VlvT {
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0;
  width: 95%;
  max-width: 900px;
  min-width: 300px;
  height: 550px;
  padding: 30px;
  background: #7eaeaf;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 50px));
  transition: opacity 0.4s ease-out,
    transform 0.4s ease-out;
  overflow: hidden;
}

.style_modal_container__3qG1m.style_active__14oNN .style_modal__5VlvT {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s 0.2s ease-out,
    transform 0.4s 0.2s ease-out;
}

.style_close_modal__3ZkNr {
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}
.style_paragraphe__29RmR {
  width: 100%;
  height: 100%;
  max-width: 80%;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

.style_title__36oD8 {
  color: var(--first-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1em;
  color: #FE3232;
}


.style_field__10J3b {
  width: 100%;
  height: 40px;
  border: 1px solid #707070;
  border-radius: 8px;
}

.style_input__1STNB ::placeholder {
  font-style: italic;
  font-size: 14px;
}

.style_modal__WRGik {
  border-radius: 5px;
  box-shadow: 8px -8px 0px -1px #D6D6D6;
}

.style_button__3Tux7 {
  border: none;
  width: 150px;
  padding: 0.7em;
  text-transform: uppercase;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
}

.style_ignorer__LOrJs {
  background: #FF4846;
  box-shadow: 3px 6px 1px -1px #b40502;
  /* -webkit-box-shadow: 3px 6px 1px -1px #059c67;
   -moz-box-shadow: 3px 6px 1px -1px #059c67; */
}

.style_accepter__2H3UK {
  background: #10E29B;
  box-shadow: 3px 6px 1px -1px #059c67;
  -webkit-box-shadow: 3px 6px 1px -1px #059c67;
  -moz-box-shadow: 3px 6px 1px -1px #059c67;
}


.style_img__albert__awdpL {
  width: 50px;
  height: 50px;
}

.style_img__albert__awdpL img {
  width: 100%;
  height: 100%;
}
.style_paragraphe__VtGKP {
  width: 100%;
  height: 100%;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.style_title__2taYt {
  color: var(--first-color);
}

.style_button__RfWJA {
  max-width: 100px;
  min-width: 100px;
  height: 32px !important;
}

.style_field__3YCmV {
  width: 100%;
  height: 40px;
  border: 1px solid #707070;
  border-radius: 8px;
}

.style_input__3Akjc ::placeholder {
  font-style: italic;
  font-size: 14px;
}

.style_modal__wi5lK {
  border-radius: 5px;
  box-shadow: 8px -9px 0px -1px #D6D6D6;
}
.style_paragraphe__1Z_lh {
  width: 100%;
  height: 100%;
  max-width: 80%;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

.style_title__3q_4Y {
  color: var(--first-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1em;
  color: #FE3232;
}


.style_modal__1s_73 {
  border-radius: 5px;
  box-shadow: 8px -8px 0px -1px #D6D6D6;
}

.style_albert__b3wHq {
  text-align: center;
}

.style_avatar__3_lyX {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.style_avatar__3_lyX img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.style_timer__11SfZ {
  color: var(--first-color);
  font-size: 28px;
  font-weight: 500;
}


.style_main__1MXZU {
  width: 100%;
  background-color: #fafafa;
  border-radius: 5px;
  text-align: center;
  padding: 2%;
  margin-top: var(--mb-3);
}

.style_content__1tyd8 {
  padding: 0 3%;
}

.style_main__1MXZU h3 {
  color: var(--first-color);
  text-transform: uppercase;
  margin: var(--mb-1) 0;
  font-weight: 500;
}
.style_paragraphe__RHRC9 {
  width: 100%;
  height: 100%;
  max-width: 80%;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

.style_title__3FuLy {
  color: var(--first-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1em;
  color: #FE3232;
}


.style_modal__26RUj {
  border-radius: 5px;
  box-shadow: 8px -8px 0px -1px #D6D6D6;
}

.style_lottie__1Qs7_ {
  width: 250px;
}

.style_avatar__3nDcf {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.style_avatar__3nDcf img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.style_timer__Szth2 * {
  color: var(--first-color);
  font-size: 28px;
  font-weight: 500;
}
.style_card__OqU4Q {
  width: 100%;
  position: relative;
  margin-bottom: 2em;
}

.style_avatar__1l9vk {
  width: 35px;
  height: 35px;
  background: #fff;

  border-radius: 50%;
}

.style_student__I0m1Y {
  background: #f2f2f2;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  text-transform: uppercase;
}

.style_tagAvis__1isqH {
  background: #f2f2f2;
  padding: 10px 15px;
  border-radius: 20px;
  color: var(--title-color);
}

.style_tagAvis__1isqH span strong {
  font-weight: 500;
}

.style_description__2Gmt_ {
  margin-top: 1em;
}

.style_button__1KpJm {
  border: none;
  min-width: 150px;
  padding: 0.3em 1em;
  text-transform: uppercase;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
}

.style_ignorer__2iEz- {
  background: #ff4846;
  box-shadow: 3px 6px 1px -1px #b40502;
  /* -webkit-box-shadow: 3px 6px 1px -1px #059c67;
   -moz-box-shadow: 3px 6px 1px -1px #059c67; */
}

.style_accepter__2U-Br {
  background: #10e29b;
  box-shadow: 3px 6px 1px -1px #059c67;
  -webkit-box-shadow: 3px 6px 1px -1px #059c67;
  -moz-box-shadow: 3px 6px 1px -1px #059c67;
}

.style_content__2Qbce {
  margin-top: 2%;
  background: #fafafa;
  padding: 2% 3%;
  height: 700px;
  border: 1px solid rgba(204, 204, 204, 0.506);
  border-radius: 8px;
  overflow-y: scroll;
}

.style_header__f5Wll {
  width: 100%;
  background: #fafafa;
  border: 1px solid rgba(204, 204, 204, 0.506);
  border-radius: 8px;
  margin-bottom: var(--mb-3);
  padding: 1.5% 2%;
}

.style_title__ve2m_ h2 {
  color: var(--first-color);
  text-transform: uppercase;
  font-size: 1em;
}

.style_title__ve2m_ h2 span strong {
  color: var(--first-color);
  text-transform: initial;
}

@media only screen and (max-width: 767px) {
  .style_title__ve2m_ h2 {
    color: var(--first-color);
    text-transform: uppercase;
    font-size: 0.7em;
  }
}

.style_paragraphe__29p0N {
  width: 100%;
  height: 100%;
  max-width: 80%;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

.style_title__3eXkr {
  color: var(--first-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1em;
  color: #FE3232;
}


.style_modal__3bELg {
  border-radius: 5px;
  box-shadow: 8px -8px 0px -1px #D6D6D6;
}

.style_albert__hG49V {
  text-align: center;
}

.style_avatar__3RHXj {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.style_avatar__3RHXj img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.style_timer__2oVKX {
  color: var(--first-color);
  font-size: 28px;
  font-weight: 500;
}


.style_main__G5Wo8 {
  width: 100%;
  background-color: #fafafa;
  border-radius: 5px;
  text-align: center;
  padding: 2%;
  margin-top: var(--mb-3);
}

.style_content__2tKPl {
  padding: 0 3%;

  overflow-x: hidden;
}

.style_main__G5Wo8 h3 {
  color: var(--first-color);
  text-transform: uppercase;
  margin: var(--mb-1) 0;
  font-weight: 500;
}

.style_msg__3_jgS {
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer
}
.common_button__1YCEq {
    border: none;
    padding: 1em 2em;
    background: var(--first-color);
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.common_button__1YCEq:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.common_buttonRegister__2Bcpy {
    border: 1px var(--first-color) solid;
    color: var(--first-color);
    padding: 1em 2em;
    background: white;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.common_buttonRegister__2Bcpy:hover {
    border: none;
    background: var(--first-color);
    color: white;
}

.common_searchButton__3lbDE {
    border: none;
    padding: 1em 2em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.common_searchButton__3lbDE:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.common_submitButton__35h9u {
    border: none;
    padding: 1em 5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    margin-top: 20px;
}

.common_submitButton__35h9u:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.common_newsletterButton__36H7g {
    height: 70px;
    border: none;
    padding: 0.5em 2.5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 0px;
    -o-border-radius: 50px;
}

.common_newsletterButton__36H7g:hover {
    border: 1px var(--first-color) solid;
    background: white;
    color: var(--first-color);
}

.common_scaleAnimation__3s_U6 {
    transition: transform 0.3s ease-in-out;
}

.common_scaleAnimation__3s_U6:hover {
    transform: scale(1.1);
}

.common_scaleAnimationSmall__3E-gd {
    transition: transform 0.3s ease-in-out;
}

.common_scaleAnimationSmall__3E-gd:hover {
    transform: scale(1.05);
}

.common_animatedDiv__3TzGC {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    width: 50%;
    background: white;
    border-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding-left: 30px;
    padding-right: 30px;
    transition: transform 0.3s ease-in-out;
}

.common_animatedDiv__3TzGC:hover {
    transform: scale(1.1);
}

.common_animatedDivSeparator__2I8Nr {
    height: 30px;
    width: 2px;
    background: var(--first-color);
}

.common_animatedDiv__3TzGC:hover .common_animatedDivSeparator__2I8Nr {
    background-color: #ff9d00;
}

.common_animatedDivBox__2vkBE {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.common_animatedDivBoxTitle__IibHr {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 16px;
    color: #080707;
    line-height: 15px;
}

.common_animatedDivBoxTitle__IibHr:hover {
    color: #080707;
}

.common_animatedDivBoxSubtitle__A-4vA {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 12px;
    color: #080707;
    line-height: 15px;
}

.common_animatedDivBoxSubtitle__A-4vA:hover {
    color: #080707;
}

@keyframes common_float__wVDOc {
    0% {
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }

    50% {
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2);
        transform: translatey(-20px);
    }

    100% {
        box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.6);
        transform: translatey(0px);
    }
}

.common_floatAnimation__1whgA {
    transform: translatey(0px);
    animation: common_float__wVDOc 6s ease-in-out infinite;
}

.common_floatAnimationOne__21lkZ {
    transform: translatey(0px);
    animation: common_float__wVDOc 8s ease-in-out infinite;
}

.common_floatAnimationTwo__3vL7F {
    transform: translatey(0px);
    animation: common_float__wVDOc 7s ease-in-out infinite;
}

.common_floatAnimationDelayed1__21v-h {
    animation-delay: 3s;
}

.common_floatAnimationDelayed2__o4OAv {
    animation-delay: 4s;
}

@keyframes common_underline__1zi_J {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.common_underline__1zi_J {
    position: relative;
    display: inline-block;
    z-index: -1;
}

.common_underline__1zi_J::after {
    content: '';
    position: absolute;
    width: 0;
    height: 24px;
    bottom: 7px;
    left: 0;
    background-color: #FFC8BA;
    transition: width 0.5s;
    animation: common_underline__1zi_J 3s forwards;
    z-index: -1;
}

.common_containerOneStyle__28Tu5 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #F5CB93;
    position: relative;
    transition: all 0.3s ease;
}

.common_containerTwoStyle__1wd36 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #8AB0B1;
    position: relative;
    transition: all 0.3s ease;
}

.common_containerThreeStyle__2S1sK {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #FFC8BB;
    position: relative;
    transition: all 0.3s ease;
}

.common_containerFourStyle__R9Aud {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 240px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #FFDCAB;
    position: relative;
    transition: all 0.3s ease;
}

.common_cornerStyleUp__2RjMU {
    content: '""';
    position: absolute;
    width: 4px;
    height: 140px;
    background-color: white;
    transition: all 0.3s ease;
}

.common_cornerStyleDown__3PClw {
    content: '""';
    position: absolute;
    width: 190px;
    height: 4px;
    background-color: white;
    transition: all 0.3s ease;
}

@keyframes common_borderColorRotationOne__1juDl {
    0% {
        border-color: #F5CB93 transparent transparent transparent;
    }

    25% {
        border-color: #F5CB93 #F5CB93 transparent transparent;
    }

    50% {
        border-color: transparent #F5CB93 #F5CB93 transparent;
    }

    75% {
        border-color: transparent transparent #F5CB93 #F5CB93;
    }

    100% {
        border-color: #F5CB93 #F5CB93 #F5CB93 #F5CB93;
    }
}

@keyframes common_borderColorRotationTwo__3O89G {
    0% {
        border-color: #8AB0B1 transparent transparent transparent;
    }

    25% {
        border-color: #8AB0B1 #8AB0B1 transparent transparent;
    }

    50% {
        border-color: transparent #8AB0B1 #8AB0B1 transparent;
    }

    75% {
        border-color: transparent transparent #8AB0B1 #8AB0B1;
    }

    100% {
        border-color: #8AB0B1 #8AB0B1 #8AB0B1 #8AB0B1;
    }
}

@keyframes common_borderColorRotationThree__3Qrf9 {
    0% {
        border-color: #FFC8BB transparent transparent transparent;
    }

    25% {
        border-color: #FFC8BB #FFC8BB transparent transparent;
    }

    50% {
        border-color: transparent #FFC8BB #FFC8BB transparent;
    }

    75% {
        border-color: transparent transparent #FFC8BB #FFC8BB;
    }

    100% {
        border-color: #FFC8BB #FFC8BB #FFC8BB #FFC8BB;
    }
}

@keyframes common_borderColorRotationFour__239EI {
    0% {
        border-color: #FFDCAB transparent transparent transparent;
    }

    25% {
        border-color: #FFDCAB #FFDCAB transparent transparent;
    }

    50% {
        border-color: transparent #FFDCAB #FFDCAB transparent;
    }

    75% {
        border-color: transparent transparent #FFDCAB #FFDCAB;
    }

    100% {
        border-color: #FFDCAB #FFDCAB #FFDCAB #FFDCAB;
    }
}

.common_containerOneStyle__28Tu5:hover {
    border-radius: 10px;
    animation: common_borderColorRotationOne__1juDl 2s linear forwards;
}

.common_containerOneStyle__28Tu5:hover .common_cornerStyleUp__2RjMU {
    background-color: transparent;
}

.common_containerOneStyle__28Tu5:hover .common_cornerStyleDown__3PClw {
    background-color: transparent;
}

.common_containerTwoStyle__1wd36:hover {
    border-radius: 10px;
    animation: common_borderColorRotationTwo__3O89G 2s linear forwards;
}

.common_containerTwoStyle__1wd36:hover .common_cornerStyleUp__2RjMU {
    background-color: transparent;
}

.common_containerTwoStyle__1wd36:hover .common_cornerStyleDown__3PClw {
    background-color: transparent;
}

.common_containerThreeStyle__2S1sK:hover {
    border-radius: 10px;
    animation: common_borderColorRotationThree__3Qrf9 2s linear forwards;
}

.common_containerThreeStyle__2S1sK:hover .common_cornerStyleUp__2RjMU {
    background-color: transparent;
}

.common_containerThreeStyle__2S1sK:hover .common_cornerStyleDown__3PClw {
    background-color: transparent;
}

.common_containerFourStyle__R9Aud:hover {
    border-radius: 10px;
    animation: common_borderColorRotationFour__239EI 2s linear forwards;
}

.common_containerFourStyle__R9Aud:hover .common_cornerStyleUp__2RjMU {
    background-color: transparent;
}

.common_containerFourStyle__R9Aud:hover .common_cornerStyleDown__3PClw {
    background-color: transparent;
}

@keyframes common_fadeInUp__XHzIP {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.common_fadeInUp__XHzIP {
    opacity: 0;
    transform: translateY(20px);
    animation: common_fadeInUp__XHzIP 2s forwards;
}

.common_logoContainer__2BStu {
    height: 90px;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.common_logoTitle__r00an {
    font-size: 28px;
    color: rgb(255, 255, 255);
    font-family: "Margin DEMO";
    line-height: 28px;
    font-weight: normal;
    margin-top: 0px;
}

.common_logoTitleHeader__3hKBo {
    font-size: 28px;
    color: #393E3E;
    font-family: "Margin DEMO";
    line-height: 28px;
    font-weight: normal;
    margin-top: 0px;
}

.common_containerBorderedOne__3ZUzy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 155px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #F5CB93;
    position: relative;
    transition: all 0.3s ease;
}

.common_containerBorderedTwo__14Yr2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 155px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #8AB0B1;
    position: relative;
    transition: all 0.3s ease;
}

.common_containerBorderedThree__alT9H {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 155px;
    width: 320px;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
    padding: 30px;
    margin-right: 20px;
    border: 3px solid #FFC8BB;
    position: relative;
    transition: all 0.3s ease;
}

.common_cornerBorderedUp__21MKp {
    content: '""';
    position: absolute;
    width: 4px;
    height: 60px;
    background-color: white;
    transition: all 0.3s ease;
}

.common_cornerBorderedDown__3jEU7 {
    content: '""';
    position: absolute;
    width: 150px;
    height: 4px;
    background-color: white;
    transition: all 0.3s ease;
}

.common_containerBorderedOne__3ZUzy:hover {
    border-radius: 10px;
    animation: common_borderColorRotationOne__1juDl 2s linear forwards;
}

.common_containerBorderedOne__3ZUzy:hover .common_cornerBorderedUp__21MKp {
    background-color: transparent;
}

.common_containerBorderedOne__3ZUzy:hover .common_cornerBorderedDown__3jEU7 {
    background-color: transparent;
}

.common_containerBorderedTwo__14Yr2:hover {
    border-radius: 10px;
    animation: common_borderColorRotationTwo__3O89G 2s linear forwards;
}

.common_containerBorderedTwo__14Yr2:hover .common_cornerBorderedUp__21MKp {
    background-color: transparent;
}

.common_containerBorderedTwo__14Yr2:hover .common_cornerBorderedDown__3jEU7 {
    background-color: transparent;
}

.common_containerBorderedThree__alT9H:hover {
    border-radius: 10px;
    animation: common_borderColorRotationThree__3Qrf9 2s linear forwards;
}

.common_containerBorderedThree__alT9H:hover .common_cornerBorderedUp__21MKp {
    background-color: transparent;
}

.common_containerBorderedThree__alT9H:hover .common_cornerBorderedDown__3jEU7 {
    background-color: transparent;
}

@media only screen and (max-width: 680px) {

    .common_animatedDiv__3TzGC {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 420px;
        width: 70%;
        background: white;
        border-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding-top: 50px;
        padding-bottom: 50px;
        margin-top: 50px;
        transition: transform 0.3s ease-in-out;
    }

    .common_animatedDivBoxTitle__IibHr {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        color: #393E3E;
        line-height: 15px;
        margin-bottom: 20px;
    }

    .common_animatedDivBoxSubtitle__A-4vA {
        font-family: "Poppins";
        font-weight: 300;
        font-size: 20px;
        color: #080707;
        line-height: 15px;
    }

    .common_animatedDivSeparator__2I8Nr {
        height: 2px;
        width: 40%;
        background: var(--first-color);
    }

    .common_buttonRegister__2Bcpy {
        border: 1px var(--first-color) solid;
        color: var(--first-color);
        padding: 1em 2em;
        background: white;
        font-size: 8px;
        line-height: 15px;
        cursor: pointer;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .common_containerOneStyle__28Tu5 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-bottom: 30px;
        border: 3px solid #F5CB93;
        position: relative;
        transition: all 0.3s ease;
    }

    .common_containerTwoStyle__1wd36 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-bottom: 30px;
        border: 3px solid #8AB0B1;
        position: relative;
        transition: all 0.3s ease;
    }

    .common_containerThreeStyle__2S1sK {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-bottom: 30px;
        border: 3px solid #FFC8BB;
        position: relative;
        transition: all 0.3s ease;
    }

    .common_containerFourStyle__R9Aud {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 240px;
        width: 320px;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        border: 3px solid #FFDCAB;
        position: relative;
        transition: all 0.3s ease;
    }

    .common_containerBorderedOne__3ZUzy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 95px;
        width: 130%;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        border: 3px solid #F5CB93;
        position: relative;
        transition: all 0.3s ease;
    }

    .common_containerBorderedTwo__14Yr2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 95px;
        width: 130%;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-top: 40px;
        border: 3px solid #8AB0B1;
        position: relative;
        transition: all 0.3s ease;
    }

    .common_containerBorderedThree__alT9H {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 95px;
        width: 130%;
        background: white;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 5px 9px 10px rgba(0, 0, 0, 0.2);
        padding: 30px;
        margin-right: 0px;
        margin-top: 40px;
        border: 3px solid #FFC8BB;
        position: relative;
        transition: all 0.3s ease;
    }

    .common_cornerBorderedUp__21MKp {
        content: '""';
        position: absolute;
        width: 4px;
        height: 60px;
        background-color: white;
        transition: all 0.3s ease;
    }

    .common_cornerBorderedDown__3jEU7 {
        content: '""';
        position: absolute;
        width: 150px;
        height: 4px;
        background-color: white;
        transition: all 0.3s ease;
    }

}

.common_newLoginButton__2P0qJ {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 1em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
}
.custom_tab_cardContainer__2tq49 {
  width: 100% !important;
}

.custom_tab_main_arrangment__oyyzC {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: -16px !important;
  min-height: 60vh;
  margin-top: 40px;
  min-width: 100%;
  background: #fafafa;
}

.custom_tab_main_arrangment_mobile__3Ze2a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -16px !important;
  min-height: 60vh;
  margin-top: 40px;
  min-width: 100%;
  background: #fafafa;
}

.custom_tab_arrangment__J-0FE {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom_tab_monFormulaire__qcqvG {
  width: 80% !important;
  margin-left: 30px;
  margin-top: 70px !important;
  padding: 10px !important;
}

.custom_tab_retrait__1g6t0 {
  margin-top: 80px !important;
  width: 50%;
}

.custom_tab_retrait_mobile__21lfM {
  margin-top: 40px !important;
  width: 100%;
}

.custom_tab_retraitLabel__YXJjk {
  font-size: 27px !important;
  font-family: "CartoonFont" !important;
  font-weight: 200;
  text-transform: uppercase;
}

.custom_tab_demandeRetraitButton__4QmZy {
  height: 45px;
  width: auto;
  font-family: "Poppins";
  display: block;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-align: center;
  padding-top: 2px !important;
  font-weight: 400 !important;
  border: 2px solid #7FB1B2 !important;
}

/* New Css Styles */

.custom_tab_soldeBox__2IAcX {
  width: 100%;
  height: 80px;
  background: #FFF;
  box-shadow: 4px 8px 18px #8686862F;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
}

.custom_tab_soldeBoxTitle__2zggn {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.custom_tab_amountBox__yudVO {
  width: auto;
  height: 45px;
  background: #E5FFF2;
  border: 0px solid #4BB980;
  border-radius: 18px;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.custom_tab_amountBoxWaiting__2ylp4 {
  width: auto;
  height: 45px;
  background: #FFE5C6;
  border: 0px solid #B9974B;
  border-radius: 18px;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.custom_tab_detailsBox__14ViY {
  background: #FAFAFA;
  border: 1px solid #BFBCBC;
  border-radius: 40px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  width: 100% !important;
  padding-top: 20px;
  padding-bottom: 80px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 10px;
  z-index: 1;
}

.custom_tab_detailsBoxTitle__QGXAw {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.custom_tab_detailsBoxMessage__ln9Yw {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.custom_tab_tabTitleBox__1rAgi {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.custom_tab_tabTitleBoxActive__17bXS {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.custom_tab_informationBox__2GV4g {
  width: 140%;
  height: 180px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
}

.custom_tab_historyBox__10rMf {
  height: 280px;
  width: 110%;
  border: 0px solid #BFBCBC;
  opacity: 1;
  /* border-radius: 18px; */
  opacity: 1;
  padding: 0px;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin-top: 20px;
}

.custom_tab_field__1j0hI {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid #BFBCBC !important;
  font-style: italic !important;
  color: #707070;
}

.custom_tab_texareaField__1tTW5 {
  height: 80px !important;
  border-radius: 10px !important;
  border: 1px solid #BFBCBC !important;
  font-style: normal !important;
  font-size: 12px;
  color: #707070;
}

.custom_tab_field__1j0hI::placeholder {
  font-style: normal !important;
  font-size: 12px;
}

texareaFieldFilter {
  font-style: normal !important;
  font-size: 12px;
  color: #707070;
  height: 30px !important;
}

.custom_tab_texareaFieldFilter__1fNOJ::placeholder {
  font-size: 12px;
}

.custom_tab_selectInput__3IIwh {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #BFBCBC;
  font-style: normal !important;
  width: 100%;
}

.custom_tab_bookingBox__2wMAW {
  height: 44px;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.custom_tab_studentAvatar__3Tl1E {
  width: 30px;
  height: 30px;
  background: #FFF2E0;
  opacity: 1;
  border: 1px solid #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_tab_studentName__31C9k {
  color: #393E3E;
  font-size: 12px;
  font-weight: 500;
  font-family: "poppins";
  margin-left: 5px;
}

.custom_tab_dateBox__3adim {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  width: 200px;
}

.custom_tab_priceBox__ZjdPH {
  width: 55px;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
}

.custom_tab_newButton__3hRBS {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
}

.custom_tab_custom-tabs__3DSlA .custom_tab_ant-tabs-ink-bar__3blUw {
  display: none;
}

.custom_tab_custom-tabs__3DSlA .custom_tab_ant-tabs-tab__2wQ-6 .custom_tab_ant-tabs-tab-active__2i3yr {
  background: transparent;
  border: none;
}

.custom_tab_emptyClass__FGlSC {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.custom_tab_avatarNameBox__2baMZ {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  object-fit: contain;
}

.custom_tab_newInfoButton__o6ec- {
  width: 100px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #669e9d !important;
  -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
  -moz-box-shadow: 5px 7px 1px -1px #669e9d;
}

@media only screen and (max-width: 767px) {
  .custom_tab_cardContainer__2tq49 {
    min-height: 700px;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .custom_tab_soldeBox__2IAcX {
    width: 100%;
    height: 56px;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    z-index: 1;
  }

  .custom_tab_soldeBoxColumn__3vA6p {
    width: 100%;
    height: auto;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
    margin-top: 10px;
  }

  .custom_tab_soldeBoxTitle__2zggn {
    font-size: 0.7em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
  }

  .custom_tab_amountBox__yudVO {
    width: auto;
    height: 35px;
    background: #E5FFF2;
    border: 0px solid #4BB980;
    border-radius: 12px;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .custom_tab_amountBoxWaiting__2ylp4 {
    width: auto;
    height: 35px;
    background: #FFE5C6;
    border: 0px solid #B9974B;
    border-radius: 12px;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .custom_tab_newButton__3hRBS {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  }

  .custom_tab_demandeRetraitButtonMobileBox__2dk0p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
  }

  .custom_tab_demandeRetraitButtonMobile__3wTo1 {
    height: 30px !important;
    width: auto !important;
    font-family: "Poppins";
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 10px !important;
    text-align: center !important;
    padding-top: 2px !important;
    font-weight: 400 !important;
    border: 2px solid #7FB1B2 !important;
  }

  .custom_tab_detailsBox__14ViY {
    background: #FAFAFA;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: block;
    height: auto;
    width: 100% !important;
    padding-top: 20px;
    padding-bottom: 80px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: auto;
    z-index: 1;
    object-fit: contain;
  }

  .custom_tab_detailsBoxTitle__QGXAw {
    font-size: 10px;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .custom_tab_detailsBoxMessage__ln9Yw {
    font-size: 8px;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .custom_tab_tabTitleBox__1rAgi {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 12px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.7em;
  }

  .custom_tab_tabTitleBoxActive__17bXS {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 12px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.7em;
  }

  .custom_tab_informationBox__2GV4g {
    width: 100%;
    height: 180px;
    border: 1px solid #BFBCBC;
    border-radius: 18px;
    opacity: 1;
    padding: 20px;
  }

  .custom_tab_historyBox__10rMf {
    height: 340px;
    width: 100% !important;
    border: 0px solid #BFBCBC;
    opacity: 1;
    /* border-radius: 18px; */
    opacity: 1;
    padding: 0px;
    overflow-y: scroll;
    scrollbar-width: thin;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
    margin-top: 20px;
  }

  .custom_tab_field__1j0hI {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid #BFBCBC !important;
    font-style: italic !important;
    color: #707070;
  }

  .custom_tab_texareaField__1tTW5 {
    height: 80px !important;
    border-radius: 10px !important;
    border: 1px solid #BFBCBC !important;
    font-style: normal !important;
    font-size: 0.8em !important;
    color: #707070;
  }

  .custom_tab_texareaField__1tTW5::placeholder {
    font-size: 0.8em !important;
  }

  texareaFieldFilter {
    font-style: normal !important;
    font-size: 0.8em !important;
    color: #707070;
    height: 30px !important;
  }

  .custom_tab_texareaFieldFilter__1fNOJ::placeholder {
    font-size: 0.8em !important;
  }

  .custom_tab_field__1j0hI::placeholder {
    font-style: normal !important;
    font-size: 12px;
  }

  .custom_tab_selectInput__3IIwh {
    color: #707070;
    border-radius: 8px;
    border: 1px solid #BFBCBC;
    font-style: normal !important;
    width: 100%;
  }

  .custom_tab_newInfoButton__o6ec- {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 10px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #669e9d !important;
    -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
    -moz-box-shadow: 5px 7px 1px -1px #669e9d;
    margin: 0px auto;
  }

  .custom_tab_bookingBox__2wMAW {
    height: 65% !important;
    width: 100%;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .custom_tab_avatarNameBox__2baMZ {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    object-fit: contain;
  }

  .custom_tab_studentAvatar__3Tl1E {
    width: 40px;
    height: 40px;
    background: #FFF2E0;
    opacity: 1;
    border: 1px solid #FFF;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom_tab_studentName__31C9k {
    color: #393E3E;
    font-size: 10px;
    font-weight: 600;
    font-family: "poppins";
    text-transform: capitalize;
    margin-top: 5px;
  }

  .custom_tab_dateBox__3adim {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.65em;
    font-weight: 400;
    color: #393E3E;
    width: auto;
    text-transform: capitalize;
  }

  .custom_tab_priceBox__ZjdPH {
    width: 45px;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    margin-top: 10px;
  }
}
.simpleStyling_font__aTKwS {
  font-family: "Rubik";
}
.simpleStyling_time__KyAU6 {
  font-size: 20px;
  font-weight: 600;
}
.simpleStyling_label__50znC {
  font-size: 17px;
  font-weight: 200;
}

.chat_chat__space__knsiN {
  padding: 2%;
  height: 58vh;
  background: #FFF;
  border-radius: 16px;
}

.chat_chat__content__v9vsL {
  height: 85%;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-top: 25px;
}

.chat_isInsta__3vMCW {
  height: 50vh;
}

.chat_header__3KxaJ {
  position: relative;
  width: 100%;
  text-align: center;
  height: auto;
  background: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  padding-bottom: 20px;
}

.chat_header__3KxaJ>span {
  font-size: 1.3em;
  text-transform: uppercase;
  color: #333333;
  font-weight: 500;
}

.chat_chat__msg__profile__2-FCV {
  height: 45px;
  width: 45px;
  margin-bottom: 0px;
  position: relative;
  background: #FFF2E0;
  border: 1.5px solid #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 40px;
  box-shadow: 0px 3px 6px #00000029;
}

.chat_chat__msg__img__2Vy-V {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.chat_chat__msg__date__2MoMH {
  font-size: 12px;
  font-weight: 600;
  color: #868686;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat_chat__msg__1SWHU {
  display: flex;
  flex-direction: row;
  padding: 0 20px 45px;
  margin-top: 20px;
}

.chat_chat__msg__content__3v24j {
  margin-left: 12px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat_chat__msg__text__dNOwe {
  background-color: #FAFAFA;
  padding: 15px;
  border-radius: 4px 20px 20px 20px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  color: #393E3E;
}

.chat_chat__msg__text_search__2KQHP {
  background-color: #FAFAFA;
  padding: 15px;
  border-radius: 4px 20px 20px 20px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  color: #393E3E;
  border: 1px solid #EAEAEA;
}

.chat_chat__msg__text__dNOwe+.chat_chat__msg__text__dNOwe {
  margin-top: 10px;
}

.chat_chat__msg__text__dNOwe {
  /* color: var(--chat-text-color); */
}

.chat_owner__2sdco {
  flex-direction: row-reverse;

}

.chat_owner__2sdco .chat_chat__msg__content__3v24j {
  margin-left: 0;
  margin-right: 12px;
  align-items: flex-start;
}

.chat_owner__2sdco .chat_chat__msg__text__dNOwe {
  background-color: #FAFAFA;
  color: #393E3E;
  border-radius: 20px 5px 20px 20px;
  border: 1px solid #EAEAEA;
}

.chat_owner__2sdco .chat_chat__msg__date__2MoMH {
  color: #868686;
}

.chat_chat__msg__text__dNOwe img {
  max-width: 300px;
  width: 100%;
}

.chat_chat__msg__1SWHU.chat_owner__2sdco .chat_chat__msg__content__3v24j .chat_chat__msg__text__dNOwe a {
  color: #fff;
  text-decoration: underline;
}

.chat_chat__msg__1SWHU .chat_chat__msg__content__3v24j .chat_chat__msg__text__dNOwe a {
  color: var(--first-color);
  text-decoration: underline;
}

/* --- */
.chat_back__36-c2 {
  display: none;
}

.chat_Albert__17agT {
  width: 300px;
  height: 300px;
}

@media only screen and (max-width: 700px) {
  /*
  .chat__space {

    height: 80vh;
  } */

  .chat_chat__msg__1SWHU {
    padding: 0 20px 30px;
  }

  .chat_chat__msg__text__dNOwe {
    background-color: #F4F4F4;
    padding: 8px;
    line-height: 1.3;
    font-size: 0.8em;
  }

  .chat_header__3KxaJ>span {
    font-size: 0.9em;
  }

  .chat_back__36-c2 {
    position: absolute;
    display: block;
    left: 10px;
    top: 10px;
  }

  .chat_Albert__17agT {
    width: 200px;
    height: 200px;
  }

}

/* New Css Style */

.chat_newPlanningButton__2jDWj {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.chat_newInstaButton__2xPto {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.chat_reportButton__28caz {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.chat_receiverDetails__2hJQg {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}

.chat_receiverNameAvatarBox__3XrnB {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1em;
  width: 40%;
}

.chat_receiverButtonsBox__2oS6z {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat_optionButton__2lCKU {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #fff !important;
  padding-top: 0px !important;
  font-weight: 400 !important;
  border: none !important;
  margin-left: 10px;
  margin-right: 0px;
}

.chat_emptyChatTitle__2bMgy {
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #707070 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0% auto;
}

.chat_emptyChatSubtitle__25gTT {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #AFAFAF !important;
}

.chat_nameStatusBox__3_NJ1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #393E3E !important;
  margin-left: 10px;
  width: auto;
}

.chat_activeUser__2DzJg {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #4BB980 !important;
}

.chat_inactiveUser__7e808 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #cd301f !important;
}

.chat_searchBoxButtons__3b2S1 {
  height: 45px;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.chat_searchBox__2MyU3 {
  height: 40px;
  width: 80%;
  background: #FFF;
  border: 1px solid #DEDEDE;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.chat_searchAnswersBox__2dm3b {
  height: 300px;
  width: 28%;
  background: #FAFAFA;
  border: 1px solid #707070;
  position: absolute;
  top: 19%;
  left: 63%;
  border-radius: 16px;
  overflow: scroll;
  scrollbar-width: thin;
  padding: 25px 10px;
  z-index: 77;
}

@media only screen and (max-width: 1050px) {
  .chat_chat__content__v9vsL {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .chat_chat__space__knsiN {
    padding: 2%;
    height: 60vh;
    background: #FFF;
    border-radius: 16px;
  }

  .chat_receiverName__25eJS {
    display: block;
  }

  .chat_emptyChatTitle__2bMgy {
    font-size: 1em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #707070 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0% auto;
  }

  .chat_emptyChatSubtitle__25gTT {
    font-size: 0.65em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #AFAFAF !important;
    margin-bottom: 20px;
  }

  .chat_nameStatusBox__3_NJ1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 0.8em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #393E3E !important;
    margin-left: 10px;
    width: auto;
  }

  .chat_activeUser__2DzJg {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 0.65em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #4BB980 !important;
  }

  .chat_inactiveUser__7e808 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 0.65em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #cd301f !important;
  }

  .chat_receiverDetails__2hJQg {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    width: 100%;
  }

  .chat_receiverNameAvatarBox__3XrnB {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1em;
    width: 80%;
  }

  .chat_searchBoxButtons__3b2S1 {
    height: 45px;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .chat_searchBox__2MyU3 {
    height: 35px;
    width: 95%;
    background: #FFF;
    border: 1px solid #DEDEDE;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    font-size: 0.8em !important;
  }

  .chat_searchField__2lZv9 {
    font-size: 0.8em !important;
  }

  .chat_searchField__2lZv9::placeholder {
    font-size: 0.8em !important;
  }

  .chat_chat__msg__profile__2-FCV {
    height: 35px;
    width: 35px;
    margin-bottom: 0px;
    position: relative;
    background: #FFF2E0;
    border: 1.5px solid #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 5px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .chat_chat__msg__img__2Vy-V {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .chat_chat__msg__1SWHU {
    display: flex;
    flex-direction: column;
    padding: 0 10px 20px;
    margin-top: 10px;
  }

  .chat_chat__msg__content__3v24j {
    margin-left: 2px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .chat_chat__msg__text__dNOwe {
    background-color: #FAFAFA;
    padding: 7px;
    border-radius: 2px 10px 10px 10px;
    line-height: 1.5;
    font-size: 0.8em;
    font-weight: 500;
    color: #393E3E;
    border: 1px solid #EAEAEA;
  }

  .chat_chat__msg__text_search__2KQHP {
    background-color: #FAFAFA;
    padding: 7px;
    border-radius: 2px 10px 10px 10px;
    line-height: 1.5;
    font-size: 0.8em;
    font-weight: 500;
    color: #393E3E;
    border: 1px solid #EAEAEA;
  }

  .chat_owner__2sdco .chat_chat__msg__text__dNOwe {
    background-color: #FAFAFA;
    color: #393E3E;
    border-radius: 10px 2px 10px 10px;
    border: 1px solid #EAEAEA;
  }

  .chat_owner__2sdco {
    flex-direction: column;
    align-items: flex-end;

  }

  .chat_owner__2sdco .chat_chat__msg__content__3v24j {
    margin-left: 0;
    margin-right: 2px;
    align-items: flex-start;
  }

  .chat_chat__msg__date__2MoMH {
    font-size: 0.65em;
    font-weight: 600;
    color: #868686;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .chat_searchAnswersBox__2dm3b {
    height: 300px;
    width: 90%;
    background: #FAFAFA;
    border: 1px solid #707070;
    position: absolute;
    top: 15%;
    left: 5%;
    border-radius: 16px;
    overflow: scroll;
    scrollbar-width: thin;
    padding: 25px 10px;
    z-index: 77;
  }
}
.conversation_search__3jKeW {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4px;
  height: 42px;
  border: 1px solid #707070;
  margin-top: var(--mb-3);
  border-radius: 15px;
  /* position: sticky;
  top: 25px;
  z-index: 77;
  background: #FFF; */
}

.conversation_input__iUM31 {
  color: #707070;
}

.conversation_input__iUM31 ::placeholder {
  font-style: italic;
  font-size: 14px;
}

.conversation_content__conversation__10pZu {
  margin-top: 2em;
  height: 100%;
  overflow-y: scroll;
}

.conversation_conversation__list__1HsZL {
  width: 100%;
  height: 65px;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  align-items: center;
  padding: 0 10%;
  cursor: pointer;
  transition: background-color 2s ease-out;
  margin-bottom: 0.5em;
}

/* .content__conversation:hover {
  background-color: #b0e0e1b5;
} */

.conversation_username__KhjF0 {
  width: 100%;
  color: #333333;
  font-weight: 600;
}

.conversation_name__user__3G-8L>span {
  font-weight: 500;
  text-transform: uppercase;
  color: #333333;
  font-size: 1.1em;
}

.conversation_apercu__msg__emEj0 {
  color: #868686;
}

.conversation_list__3whqd {
  transition: all 0.3s ease;
}

.conversation_list__3whqd:hover {
  background: #80b1b26f;
}

.conversation_active__1Nrhx {
  background: #F0FAFA;
  border-left: 5px solid #7FB1B2;
}

.conversation_unread__2-8vy {
  background: rgb(127 177 178);
}

.conversation_unreadCount__1h7zS {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  background: #7FB1B2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  margin-right: 5px;
}

.conversation_avatar__14AlV {
  background: #FFF2E0;
  border-radius: 50%;
  border: 1.5px solid #FFF;
  box-shadow: 0px 3px 6px #00000029;
}

@media only screen and (max-width: 1050px) {
  .conversation_input__iUM31 ::placeholder {
    font-style: italic;
    font-size: 0.75em;
  }

  .conversation_input__iUM31 ::placeholder {
    font-style: italic;
    font-size: 0.75em;
  }

  .conversation_username__KhjF0 {
    width: 100%;
    font-size: 0.8em;
    color: #333333;
    font-weight: 600;
  }

  .conversation_name__user__3G-8L>span {
    font-weight: 500;
    text-transform: uppercase;
    color: #333333;
    font-size: 0.8em;
  }

  .conversation_apercu__msg__emEj0 {
    font-size: 0.7em;
    color: #868686;
  }

  .conversation_unreadCount__1h7zS {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    text-align: center;
    background: #7FB1B2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    margin-right: 5px;
    font-size: 0.7em;
    position: relative;
    top: 3.5px;
  }
}
.message_message__2EEmG {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
}

.message_textArea__7ltTP {
  width: 100%;
  background: #fff;
  padding: 7px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 10px;
}

.message_BlockedmessageBox__28uoB {
  height: 40px;
  width: 100%;
  border: 1px solid #F56666;
  border-radius: 16px;
  background: #FFF;
  color: #F56666;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
}

.message_inputBox__3-mFI {
  padding: 15px;
}

.message_inputTextarea__BxjgR {
  height: 70px !important;
  width: 95%;
  border: 1px solid #B9B9B9;
  border-radius: 24px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 20px;
  padding-right: 15px;
}

@media only screen and (max-width: 1050px) {
  .message_message__2EEmG {
    grid-gap: 0.5em;
    gap: 0.5em;
  }

  .message_button__1O0ES {
    min-width: 50px;
    font-size: 10px !important;
  }

  .message_BlockedmessageBox__28uoB {
    height: 40px;
    width: 100%;
    border: 1px solid #F56666;
    border-radius: 12px;
    background: #FFF;
    color: #F56666;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .message_inputTextarea__BxjgR {
    height: 45px !important;
    width: 95%;
    border: 1px solid #B9B9B9;
    border-radius: 32px;
    padding: 15px;
    margin: 0px auto;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .message_messageInput__3sG-O {
    font-size: 1em !important;
  }

  .message_messageInput__3sG-O::placeholder {
    font-size: 1em !important;
  }

}
.appChat_row__BVXwy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1.2em;
  gap: 1.2em;
}

.appChat_app__container__k3WU6 {
  width: 100%;
  max-width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.appChat_conversation__1Xb6K,
.appChat_chat__3J0DF {
  height: 70vh;
}

.appChat_conversation__1Xb6K {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #7FB1B2 !important;
  flex: 0.6 1;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 17px;
  scrollbar-width: none;
}

.appChat_conversation__1Xb6K::-webkit-scrollbar {
  display: none;
}

.appChat_chat__3J0DF {
  flex: 1.4 1;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 16px;
}

.appChat_chat__area__2yu6l {
  height: auto;
  background: #fff;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  border-radius: 16px;
}

.appChat_chat__area__footer__3olXI {
  margin-top: var(--mb-3);
}

@media only screen and (max-width: 500px) {}

/* New Css Style */

.appChat_conditionsBox__zXQcE {
  margin-top: 20px;
  font-size: 1em;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  width: 100%;
}

.appChat_conditionsBox__zXQcE a {
  text-decoration: underline;
  font-weight: 600;
}

.appChat_drawerTitle__StJIV {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1050px) {
  .appChat_app__container__k3WU6 {
    max-width: 100%;
  }

  .appChat_chat__area__2yu6l {
    height: 75vh;
    background: #FFF !important;
  }

  .appChat_row__BVXwy {
    flex-direction: column;
    grid-gap: 0.4em;
    gap: 0.4em;
  }

  .appChat_chat__3J0DF {
    background: #fff;
  }

  .appChat_chat__3J0DF.appChat_active__3jjhs {
    height: 85vh;
    visibility: visible;
    background: #fff !important;
    z-index: 78;
    border: 1px solid #707070;
    border-radius: 16px;
    padding-top: 0px;
  }

  .appChat_chat__3J0DF {
    position: absolute;
    width: 95%;
    left: 0;
    right: 0;
    margin: auto;
    flex: initial;
    visibility: hidden;
    transition: visibility 0.4s;
    transition: opacity 0.4s ease-out,
      transform 0.4s ease-out;
  }

  .appChat_chat__area__footer__3olXI {
    margin-top: var(--mb-1);
  }

  .appChat_conditionsBox__zXQcE {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    width: 90%;
  }

  .appChat_conditionsBox__zXQcE a {
    text-decoration: underline;
    font-weight: 600;
  }

  .appChat_drawerTitle__StJIV {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .appChat_conversation__1Xb6K {
    width: 95%;
    flex: initial;
  }

  .appChat_conversation__1Xb6K,
  .appChat_chat__3J0DF {
    height: 70vh;
  }
}
.style___topBlock__a49Il {
  width: 100%;
  grid-column-gap: 12px;
  column-gap: 12px;
  height: calc(100% - 200px);
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style___topBlock__a49Il>* {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 25px;
  font-family: "CartoonFont";
}

.style___topBlock__a49Il .style___topBlockEntry__32lki {
  display: flex;
  width: 100%;
  grid-column-gap: 12px;
  column-gap: 12px;
  margin-top: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.style___columnArrangement__1jrJ7 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.style___title__1oXWN {
  font-size: 2em;
  margin-left: 15px;
}

.style___header__1E0YR {
  display: flex;
}

.style_convertedAmount__3Iqnd {
  font-size: 25px;
  text-align: center;
  font-family: var(--title-font);
}

.style_field__Zpeup {
  height: 45px !important;
  border-radius: 16px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
  font-size: 12px;
}

.style_field__Zpeup::placeholder {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
  opacity: 0.3;
}

/* New Css Styles */

.style_mainBox__12q58 {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 4%;
  margin-top: 20px;
  overflow: hidden;
  background: #FFF;
}

.style_soldeBox__3wLJn {
  width: 65%;
  height: 80px;
  background: #FFF;
  box-shadow: 4px 8px 18px #8686862F;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
}

.style_soldeBoxTitle__3wTPL {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
}

.style_amountBox__38G5g {
  width: auto;
  height: 55px;
  background: #E5FFF2;
  border: 1px solid #4BB980;
  border-radius: 18px;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.style_detailsBox__3AzFz {
  background: #FAFAFA;
  border: 1px solid #BFBCBC;
  border-radius: 40px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 320px;
  width: 65% !important;
  padding: 50px;
  margin-top: 30px;
  z-index: 1;
}

.style_titleBox__2am7X {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.style_detailsBoxTitle__NrkYY {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
}

.style_detailsBoxMessage__8aKOb {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.style_buttonBox__DvBgU {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.style_newButton__1S-a_ {
  width: 150px !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
  -moz-box-shadow: 2px 4px 1px -1px #5a8484;
}

@media only screen and (max-width: 380px) {
  .style_soldeBox__3wLJn {
    width: 90%;
    height: 80px;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
  }
}

@media only screen and (min-width: 381px) and (max-width: 1050px) {
  .style_soldeBox__3wLJn {
    width: 65%;
    height: 80px;
    background: #FFF;
    box-shadow: 4px 8px 18px #8686862F;
    border: 1px solid #BFBCBC;
    border-radius: 20px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
  }
}

@media only screen and (max-width: 1050px) {
  .style_mainBox__12q58 {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 4%;
    margin-top: 20px;
    overflow: hidden;
    background: #FFF;
  }

  .style_soldeBoxTitle__3wTPL {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
  }

  .style_amountBox__38G5g {
    width: auto;
    height: 55px;
    background: #E5FFF2;
    border: 1px solid #4BB980;
    border-radius: 18px;
    opacity: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .style_detailsBox__3AzFz {
    background: #FAFAFA;
    border: 1px solid #BFBCBC;
    border-radius: 40px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 220px;
    width: 90% !important;
    padding: 20px;
    margin-top: 30px;
    z-index: 1;
  }

  .style_titleBox__2am7X {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .style_detailsBoxTitle__NrkYY {
    font-size: 1em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
  }

  .style_detailsBoxMessage__8aKOb {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .style_buttonBox__DvBgU {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .style_newButton__1S-a_ {
    width: 150px !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
  }
}
.style_details__10Squ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* Badge style */
.style_badge__1Lga0 {
  background-color: #40a9ff;
  color: #fff;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: 0;
}

/* Name and details */
.style_name__29EUM {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
}

.style_rate__2qeKK {
  font-size: 16px;
  color: #555;
  font-weight: 700;
}

.style_subjects__1SbVZ,
.style_languages__ZJcXd {
  font-size: 14px;
  color: #777;
  text-transform: uppercase;
}

/* Description text */
.style_description__-_PL6 {
  font-size: 16px;
  color: #888;
  line-height: 1.4;
  max-height: 45px;
  /* 3 lines limit */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
}

/* Buttons section */
.style_actions__pjBde {
  margin-top: 15px;
}

.style_actions__pjBde .style_antBtn__3qKFP {
  margin-right: 10px;
  border-radius: 20px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .style_back__icon__17SkR {
    position: absolute;
    top: 1.7em;
    cursor: pointer;
  }

  .style_logoutButton__3nFV2 {
    margin-right: 0px;
  }
}

.style_rowButtons__1y1XE {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}

.style_newMessageButton__1O7pn {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.style_newMessageButton__1O7pn:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1;
  color: #fff;
}

.style_newInstaButton__3GoXZ {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 0px;
  cursor: pointer;
}

.style_newInstaButton__3GoXZ:hover {
  transform: scale(1.03) !important;
  background-color: #F56666 !important;
  color: #fff;
}

.style_newPlanningButton__1YK3w {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 0px;
  cursor: pointer;
}

.style_newPlanningButton__1YK3w:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1 !important;
  color: #fff;
}

@media only screen and (max-width: 1050px) {
  .style_name__29EUM {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .style_rate__2qeKK {
    font-size: 0.8em;
    color: #555;
    font-weight: 700;
  }

  .style_subjects__1SbVZ,
  .style_languages__ZJcXd {
    font-size: 0.75em;
    color: #777;
    text-transform: uppercase;
  }

  /* Description text */
  .style_description__-_PL6 {
    font-size: 0.8em;
    color: #888;
    line-height: 1.2;
    max-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
  }

  /* Buttons section */
  .style_actions__pjBde {
    margin-top: 15px;
  }

  .style_actions__pjBde .style_antBtn__3qKFP {
    margin-right: 10px;
    border-radius: 20px;
    font-size: 0.75em;
  }

  .style_rowButtons__1y1XE {
    width: 90%;
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    gap: 5px;
    align-items: center;
  }

  .style_newMessageButton__1O7pn {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .style_newMessageButton__1O7pn:hover {
    transform: scale(1.03) !important;
    background-color: #8AB0B1;
    color: #fff;
  }

  .style_newInstaButton__3GoXZ {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    cursor: pointer;
  }

  .style_newInstaButton__3GoXZ:hover {
    transform: scale(1.03) !important;
    background-color: #F56666 !important;
    color: #fff;
  }

  .style_newPlanningButton__1YK3w {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    cursor: pointer;
  }
}

.style_levels__FbqmC div:last-child {
  display: none;
}

.style_cardDisplay__B-MWR {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  gap: 5px;
}

.style_singleCourse__26fm4 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-template-columns: none;
}
.style_formContainer__8b1iq {
    height: auto;
    width: auto;
    margin-top: 20px;

}

.style_formBox__XUHGI {
    display: block;
    padding-left: 40px;
    padding-right: 40px;
    height: auto;
    width: 85% !important;
    background: #FAFAFA;
    margin: 0px auto;
    padding: 30px !important;
    border: 1px solid #707070;
    border-radius: 16px;
}

.style_title__2ufFZ {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
}

.style_subtitle__3m0XF {
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 600;
    color: #707070;
    margin-bottom: 20px;
}

.style_selectInput__1RZvd {
    color: #707070;
    border-radius: 8px;
    border: 1px solid #707070;
    font-style: italic !important;
    font-size: 1em;
    width: 100%;
}

.style_placeholderStyle__3Rnl5 {
    color: #C0C0C0;
    font-size: 16px;
    font-size: 1em;
}

.style_newMoveButton__28O_j {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 16px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
}

.style_field__3WYUC {
    height: 100px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
}

.style_field__3WYUC::placeholder {
    font-style: italic !important;
    font-size: 1em !important;
}

.style_buttonBox__3TfIt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    padding: auto;
    width: 100%;
}
.stylo_stylo__rTjq7 {
  position: fixed;
  right: -1%;
  top: 30%;
  width: 62px;
  height: 330px;
  background: url(/static/media/stylo.590f0fd0.svg) no-repeat center center;
  background-size: contain;
  z-index: 1;
  display: none;
}

.stylo___content__2c9Lm {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 110%;
}

.stylo___subContent__1nmwz {
  position: relative;
  width: 100%;
  height: 87.9%;
}

.stylo_____width__q2TnN {
  position: absolute;
  width: 54%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 1px;
  right: 0;
}

.stylo___progrosss__eywH3 {
  position: relative;
  width: 37px;
  transition: all 1s ease-in-out;
  background: #FED53A;
}
.style_home__24Eih {
  width: 100%;
  height: 100%;
}
.style_home__2bz-M {
  width: 100%;
  height: 100%;
  min-height: 60vh;
}
@keyframes style_fadeIn__1CAqX {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.style_fadeIn__1CAqX {
    animation: style_fadeIn__1CAqX 2s ease-in-out forwards;
    opacity: 0;
}
@keyframes style_fadeInUp__1zJc6 {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.style_fadeInUp__1zJc6 {
    animation: style_fadeInUp__1zJc6 1s ease-in-out forwards;
    opacity: 0;
}
.style_mainContainer__vo0pi {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__1SP6u {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_title__1Qy2D {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 40px;
    margin-top: 0px;
    z-index: 1;
}

.style_ellipse_title__1Coos {
    position: relative;
    left: -40%;
    width: 55px;
    bottom: 65px;
    z-index: -1;
}

.style_message__3E2HB {
    font-size: 18px;
    color: rgb(57, 62, 62);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    position: relative;
    top: -25px;
}

.style_teachersBox__2P-04 {
    display: flex;
    flex-direction: row;
    height: 420px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -40px;
    padding-left: 10%;
    padding-right: 10%;
}

.style_arrowLeftBox__ZFGwd {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 56px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    cursor: pointer;
    margin-right: 2%;
}

.style_arrowRightBox__2PbaM {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 56px;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    cursor: pointer;
    margin-left: 2%;
}

.style_carouselContent__1u4CF {
    width: 75%;
    height: 470px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 43px;
    overflow: hidden;
}

.style_slideTeacherBox__3D4hu {
    height: 420px;
    width: 315px;
    background: white;
    border-radius: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    flex: 0 0 23%;
    margin-left: 20px;
    margin-right: 0px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.style_teacherBoxImage__3XjOD {
    height: 155px;
    width: 155px;
    border-radius: 50%;
    background: black;
}

.style_teacherBoxName__1tkR6 {
    font-size: 18px;
    font-family: "Poppins";
    font-weight: normal;
    line-height: 30px;
    color: #393E3E;
    margin-top: 20px;
}

.style_teacherBoxCountry__1YJPW {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: normal;
    line-height: 32px;
    color: #679091;
    margin-top: 0px;
}

.style_teacherBoxCourses__E52-T {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: bold;
    line-height: 32px;
    color: #393E3E;
    margin-top: 0px;
}

.style_teacherBoxMark__1t7r6 {
    font-size: 16px;
    font-family: "Poppins";
    font-weight: normal;
    line-height: 32px;
    color: #393E3E;
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 680px) {

    .style_title__1Qy2D {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 1;
    }

    .style_ellipse_title__1Coos {
        position: relative;
        left: -32%;
        width: 25px;
        bottom: 65px;
        z-index: -1;
    }

    .style_message__3E2HB {
        font-size: 13px;
        color: rgb(57, 62, 62);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        position: relative;
        top: -5px;
    }

    .style_teachersBox__2P-04 {
        display: flex;
        flex-direction: column;
        height: 520px;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 0px;
        padding-left: 0%;
        padding-right: 0%;
        margin-top: 30px;
    }

    .style_carouselContent__1u4CF {
        width: 95%;
        height: 380px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 43px;
        overflow: hidden;
    }

    .style_slideTeacherBox__3D4hu {
        height: 380px;
        width: 75%;
        background: white;
        border-radius: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        flex: 0 0 75%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    }

    .style_teacherBoxImage__3XjOD {
        height: 115px;
        width: 115px;
        border-radius: 50%;
    }

    .style_teacherBoxImageProfile__3_k1b {
        height: 115px;
        width: 115px;
        border-radius: 50%;
    }

}
.style_mainContainer__1UFQu {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_contactContainer__3KCZn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    text-align: center;
    z-index: -1;
}

.style_contactBox__2mIJB {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
    width: 155%;
}

.style_contactMain__53oaQ {
    height: 831px;
    width: 80%;
    margin: 0px auto;
    background: rgb(255, 248, 242);
    display: flex;
    flex-direction: row;
    z-index: -1;
}

.style_formBox__3vxMR {
    width: 50%;
    margin: 0px auto;
    padding: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.style_nameInput__26kX1 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_lastnameInput__ghI6O {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_emailInput__poO05 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_phoneInput__8coDj {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_textareaInput__3U0k6 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    height: 250px;
    background: rgb(255, 248, 242);
}

.style_submitButton__1pVMa {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    padding: 1em 5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.style_contactTitle__10j0V {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__2ZMM6 {
    position: relative;
    left: -145px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.style_contactSubtitle__1Jjqs {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_checkboxTitle__3URjf {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.style_checkboxStyle__Z-B47 {
    color: #636363;
}

.style_inputDiv__1OSN_ {
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__1UFQu {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 2%;
        position: relative;
        top: 20px;
    }

    .style_contactBox__2mIJB {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_contactMain__53oaQ {
        height: auto;
        width: 100%;
        margin: 0px auto;
        background: rgb(255, 248, 242);
        display: flex;
        flex-direction: column;
    }

    .style_contactContainer__3KCZn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
        text-align: center;
        z-index: -1;
    }

    .style_contactTitle__10j0V {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 10px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_contactSubtitle__1Jjqs {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 0px;
        font-weight: 500;
        margin-top: 0px;
    }

    .style_ellipseTitle__2ZMM6 {
        position: relative;
        left: -95px;
        width: 25px;
        bottom: 20px;
        z-index: -1;
    }

    .style_formBox__3vxMR {
        width: 100%;
        margin: 0px auto;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .style_nameInput__26kX1 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_lastnameInput__ghI6O {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_emailInput__poO05 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_phoneInput__8coDj {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_textareaInput__3U0k6 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        height: 250px;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_submitButton__1pVMa {
        margin-top: 10px;
        cursor: pointer;
        border: none;
        padding: 1em 5em;
        background: var(--first-color);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        cursor: pointer;
        color: #fff;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .style_checkboxTitle__3URjf {
        display: flex;
        flex-direction: row;
        text-align: left;
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_checkboxStyle__Z-B47 {
        color: #636363;
        font-size: 10px;
        font-family: "Poppins";
        font-weight: 500;
    }

    .style_inputDiv__1OSN_ {
        text-align: left;
        width: 100%;
    }

}
.style_mainContainer__1N62H {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_needHelpBox__ndfyg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__3Ft5j {
    display: flex;
    flex-direction: row;
    height: 640px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: rgb(255, 248, 242);
}

.style_leftBox__2hdfb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 520px;
    width: 50%;
    padding-top: 4.5%;
    text-align: left;
}

.style_rightBox__1mDRW {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 45%;
}

.style_title__28Gkv {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 40px;
    margin-top: 0px;
    z-index: 1;
}

.style_ellipseTitle__2pID0 {
    position: relative;
    left: -1%;
    top: -50px;
    width: 37px;
    z-index: -1;
}

.style_message__1ajaW {
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_instaTitle__16mIb {
    font-size: 22px;
    color: #2F2F2F;
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_instaTitle__16mIb:hover {
    color: #2F2F2F;
}

.style_instaMessage__2Aa-w {
    font-size: 18px;
    color: #8AB0B1;
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_instaMessage__2Aa-w:hover {
    color: #8AB0B1;
}

.style_arrowNeedHelp__1DZh- {
    position: relative;
    left: 30%;
    top: -32px;
    width: 90px;
}

.style_instaProfImage__34E-J {
    height: 420px;
    position: relative;
    right: 40px;
}

.style_callAction__37Xn_ {
    height: 65px;
    width: 45.5%;
    z-index: 2;
    position: relative;
    bottom: 220px;
    left: 28.3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__3Ft5j {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 0px;
        padding-right: 0px;
        background: rgb(255, 248, 242);
        text-align: left;
    }

    .style_leftBox__2hdfb {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding-top: 4.5%;
        padding-left: 25px;
        padding-right: 25px;
        text-align: left;
    }

    .style_rightBox__1mDRW {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
    }

    .style_title__28Gkv {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 2;
        text-align: left;
    }

    .style_ellipseTitle__2pID0 {
        position: relative;
        left: -1%;
        top: -35px;
        width: 25px;
        z-index: 0;
    }

    .style_messageOne__1m_fd {
        font-size: 15px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 30px;
        font-weight: 500;
        text-align: left;
    }

    .style_message__1ajaW {
        font-size: 13px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 30px;
        font-weight: normal;
        text-align: left;
    }

    .style_arrowNeedHelp__1DZh- {
        position: relative;
        left: 30%;
        top: -32px;
        height: 80px;
        transform: rotate(-40deg);
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .style_instaProfImage__34E-J {
        height: auto;
        width: 100%;
        position: relative;
        right: 0px;
        z-index: 1;
    }

    .style_callAction__37Xn_ {
        height: auto;
        width: 80%;
        z-index: 2;
        position: relative;
        bottom: 115px;
        left: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
    }

    .style_instaTitle__16mIb {
        font-size: 9px;
        color: #2F2F2F;
        font-family: "Poppins";
        line-height: 15px;
        font-weight: normal;
        text-align: left;
    }

    .style_instaMessage__2Aa-w {
        font-size: 8px;
        color: #8AB0B1;
        font-family: "Poppins";
        line-height: 15px;
        font-weight: normal;
        text-align: left;
    }

}
.style_mainContainer__38aoO {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_ourHistoryBox__12H_4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.style_mainBox__1XXwy {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 100px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 40px;
    background: rgb(255, 248, 242);
    text-align: left;
}

.style_title__1oWpF {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__2FtM3 {
    position: relative;
    left: -5px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.style_subtitle__1d5NX {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
    text-align: left;
}

.style_secondaryBox__3dbDV {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
}

.style_studentHistoryImage__3PNKZ {
    height: 627px;
    width: 620px;
}

.style_directorImageBox__1JnOv {
    height: 186px;
    width: 186px;
    background: white;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
    position: relative;
    left: -6%;
    top: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_directorImage__8YFuj {
    height: 166px;
}

.style_directorMessageBox__1c2HB {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 420px;
    width: 620px;
    background: white;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    position: relative;
    top: 170px;
    left: -19%;
}

.style_directorName__3zXuB {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 25px;
    color: rgb(8, 7, 7);
    line-height: 40px;
    position: relative;
    left: 37%;
}

.style_directorFunction__21Gms {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 25px;
    color: rgb(138, 176, 177);
    line-height: 35px;
    position: relative;
    left: 37%;
}

.style_directorMessage__2oSWF {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 20px;
}

.style_quote__17jJh {
    font-size: 280px;
    color: rgba(162, 159, 159, 0.08);
    font-family: Helvetica;
    line-height: 160px;
    font-weight: normal;
    position: relative;
    left: 65%;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__1XXwy {
        display: flex;
        flex-direction: column;
        height: 1042px;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 100px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        background: rgb(255, 248, 242);
        text-align: left;
    }

    .style_title__1oWpF {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__2FtM3 {
        position: relative;
        left: -5px;
        width: 25px;
        bottom: 30px;
        z-index: 0;
    }

    .style_subtitle__1d5NX {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
    }

    .style_studentHistoryImage__3PNKZ {
        height: auto;
        width: 90%;
        position: relative;
        top: -40px;
    }

    .style_secondaryBox__3dbDV {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
    }

    .style_directorImageBox__1JnOv {
        height: 80px;
        width: 80px;
        background: white;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
        position: relative;
        left: 4%;
        top: 0px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_directorImage__8YFuj {
        height: 65px;
    }

    .style_directorMessageBox__1c2HB {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 320px;
        width: 100%;
        background: white;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 15px;
        position: relative;
        top: -50px;
        left: 0%;
    }

    .style_directorName__3zXuB {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 13px;
        color: rgb(8, 7, 7);
        line-height: 20px;
        position: relative;
        left: 37%;
    }

    .style_directorFunction__21Gms {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 11px;
        color: rgb(138, 176, 177);
        line-height: 10px;
        position: relative;
        left: 37%;
    }

    .style_directorMessage__2oSWF {
        font-size: 11px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 20px;
    }

    .style_quote__17jJh {
        font-size: 160px;
        color: rgba(162, 159, 159, 0.08);
        font-family: Helvetica;
        line-height: 140px;
        font-weight: normal;
        position: relative;
        left: 65%;
        position: relative;
        bottom: 30px;
    }

}
.style_mainContainer__15kmQ {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__20vc2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_title__SbPhA {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 0px;
    z-index: 1;
}

.style_subtitle__179dX {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_titleEllipse__VSUq3 {
    position: relative;
    left: -128px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.style_boxes__xenNv {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.style_boxOneTwo__2-ZIq {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 32%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 30px;
}

.style_boxThree__30v9w {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 32%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.style_box__2Cc01 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 15px;
}

.style_boxTitle__2YoRy {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 20px;
    color: rgb(8, 7, 7);
    line-height: 20px;
}

.style_boxSubtitle__IX-Aj {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(8, 7, 7);
    line-height: 20px;
}

.style_messageBox__14nlX {
    display: flex;
    flex-direction: row;
    height: 580px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -55px;
    left: 0px;
    right: 0px;
}

.style_secondBox__25YuH {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 380px;
    width: 100%;
    background: rgb(255, 248, 242);
    position: relative;
    top: -50px;
    text-align: left;
}

.style_textBox__1vrXd {
    width: 27.5%;
    font-family: "Poppins";
    font-weight: normal;
    font-size: 20px;
    color: rgb(8, 7, 7);
    padding-top: 5%;
}

.style_textBoxDescription__H8msb {
    line-height: 44px;
    position: relative;
    left: 72%;
}

.style_imagesBox__3IQEo {
    height: 560px;
    width: 470px;
    position: relative;
    top: -15%;
    right: 18%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.style_mainImageBox__33W_g {
    height: 275px;
    width: 470px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_secondaryImageBox__1LJFT {
    height: 275px;
    width: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.style_studentOneBox__3C1kN {
    height: 275px;
    width: 470px;
}

.style_studentOneBoxImage__32sFh {
    height: 275px;
    width: 470px;
}

.style_studentTwoBox__2QoDP {
    height: 155px;
    width: 215px;
}

.style_studentTwoBoxImage__22R-A {
    height: 155px;
    width: 215px;
}

.style_studentThreeBox__f9ZqE {
    height: 110px;
    width: 215px;
}

.style_studentThreeBoxImage__1EDzp {
    height: 110px;
    width: 215px;
}

.style_studentFourBox__1Bhqi {
    height: 275px;
    width: 245px;
}

.style_studentFourBoxImage__2Pran {
    height: 275px;
    width: 245px;
}

.style_flecheVerte__1xCuR {
    position: relative;
    left: -32%;
    width: 150px;
    top: 80px;
    z-index: 1;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__20vc2 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
    }

    .style_title__SbPhA {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        margin-left: 30px;
        z-index: 1;
    }

    .style_subtitle__179dX {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        margin-left: 30px;
        text-align: left;
    }

    .style_titleEllipse__VSUq3 {
        position: relative;
        left: 30px;
        width: 18px;
        bottom: 30px;
        z-index: -1;
    }

    .style_boxes__xenNv {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        padding-left: 30px;
        padding-right: 30px;
    }

    .style_boxOneTwo__2-ZIq {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 104px;
        width: 100%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 15px auto;
    }

    .style_boxThree__30v9w {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 104px;
        width: 100%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 15px auto;
    }

    .style_messageBox__14nlX {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .style_secondBox__25YuH {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 250px;
        width: 100%;
        background: rgb(255, 248, 242);
        text-align: left;
        padding: 20px;
        margin-top: 100px;
        margin-bottom: 370px;
    }

    .style_textBox__1vrXd {
        width: 100%;
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(8, 7, 7);
        padding-top: 5%;
    }

    .style_textBoxDescription__H8msb {
        line-height: 24px;
        position: relative;
        left: 0%;
    }

    .style_imagesBox__3IQEo {
        height: 560px;
        width: 100%;
        position: relative;
        top: 20px;
        right: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .style_studentOneBox__3C1kN {
        height: 225px;
        width: 100%;
        margin-bottom: 10px;
    }

    .style_studentOneBoxImage__32sFh {
        height: 225px;
        width: 100%;
    }

    .style_mainImageBox__33W_g {
        height: 275px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .style_secondaryImageBox__1LJFT {
        height: 275px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 10px;
    }

    .style_studentTwoBox__2QoDP {
        height: 115px;
        width: 100%;
        margin-bottom: 10px;
    }

    .style_studentTwoBoxImage__22R-A {
        height: 115px;
        width: 100%;
    }

    .style_studentThreeBox__f9ZqE {
        height: 110px;
        width: 100%;
    }

    .style_studentThreeBoxImage__1EDzp {
        height: 110px;
        width: 100%;
    }

    .style_studentFourBox__1Bhqi {
        height: 235px;
        width: 100%;
    }

    .style_studentFourBoxImage__2Pran {
        height: 235px;
        width: 100%;
    }

    .style_flecheVerte__1xCuR {
        position: relative;
        left: -2%;
        height: 50px;
        top: 20px;
        z-index: 1;
        transform: rotate(20deg);
    }

}
.style_mainContainer__bEN5F {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__3YouX {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 70px;
}

.style_title__2DQUp {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__341Fp {
    position: relative;
    left: -28%;
    width: 52px;
    bottom: 58px;
    z-index: -1;
}

.style_lottieTitle__HcPMM {
    width: 180px;
    position: relative;
    right: -20%;
    bottom: 56px;
}

.style_message__3B0m- {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_boxesOne__2h3mU {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    width: 155%;
}

.style_boxesTwo__1OBGt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
    width: 155%;
}

.style_boxOneTwo__3eccH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 18%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 15px;
    padding-right: 20px;
    margin-right: 30px;
}

.style_boxThree__3fJxf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 18%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 15px;
    padding-right: 20px;
}

.style_boxTitle__1Y9hM {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 18px;
    color: rgb(8, 7, 7);
    line-height: 20px;
    margin-left: 20px;
}

.style_seeMoreButton__1eOSC {
    height: 55px;
    width: 230px;
    border: 1px solid rgb(138, 176, 177);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 55px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    padding-left: 70px;
}

.style_seeMoreButtonArrow__2rh8h {
    height: 55px;
    width: 55px;
    border-radius: 30px;
    background: rgb(138, 176, 177);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 680px) {

    .style_title__2DQUp {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__341Fp {
        position: relative;
        left: -35%;
        width: 20px;
        bottom: 58px;
        z-index: -1;
    }

    .style_lottieTitle__HcPMM {
        width: 110px;
        position: relative;
        right: 0%;
        bottom: 30px;
    }

    .style_message__3B0m- {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 30px;
        font-weight: normal;
        margin-top: 0px;
    }

    .style_boxesOne__2h3mU {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        width: 100%;
    }

    .style_boxesTwo__1OBGt {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        width: 100%;
    }

    .style_boxOneTwo__3eccH {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 90px;
        width: 85%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 15px;
        padding-right: 20px;
        margin: 15px auto;
    }

    .style_boxThree__3fJxf {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 90px;
        width: 85%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 15px;
        padding-right: 20px;
        margin: 10px auto;
    }

}
.style_mainContainer__2h5nA {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__3WlgK {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.style_title__2WYNA {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__EgM0f {
    position: relative;
    left: -108px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.style_subtitle__1Vpw9 {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_animatedBoxes__1QoxC {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 155%;
}

.style_boxTitle__1ZFvY {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 20px;
    color: rgb(8, 7, 7);
    line-height: 30px;
    margin-bottom: 15px;
}

.style_boxMessage__1Dxyx {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(57, 62, 62);
    line-height: 25px;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__3WlgK {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }

    .style_title__2WYNA {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 1;
    }

    .style_ellipseTitle__EgM0f {
        position: relative;
        left: -60px;
        width: 25px;
        bottom: 35px;
        z-index: -1;
    }

    .style_subtitle__1Vpw9 {
        font-size: 14px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
    }

    .style_animatedBoxes__1QoxC {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 25px auto;
        width: 90%;
    }

}
.style_mainContainer__3Z_yX {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__5vpkD {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.style_leftBox__2cqiN {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10%;
}

.style_title__WI50A {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 40px;
}

.style_titleBox__2g-Mj {
    display: flex;
    flex-direction: row;
}

.style_titleOne__1j_fW {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
    margin-right: 12px;
}

.style_titleTwo__3YGuU {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
}

.style_message__36s5A {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

.style_infoBox__b5GnI {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.style_imagesBox__4liyi {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.style_numbersBox__3VrCX {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    left: -30px;
    font-family: "Poppins";
    font-weight: bold;
    color: rgb(8, 7, 7);
    font-size: 15px;
}

.style_numberOne__2-TnT {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 14px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.style_numberTwo__1yWlV {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 12px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.style_studentOne__3MLh6 {
    height: 70px;
    width: 70px;
}

.style_studentTwo__1hzNk {
    height: 70px;
    width: 70px;
    position: relative;
    left: -20px;
}

.style_studentThree__3inaW {
    height: 70px;
    width: 70px;
    position: relative;
    left: -40px;
}

.style_rightImagesBox__2h_XA {
    width: 40%;
}

.style_studentGirl__3QSck {
    height: 480px;
    margin-top: 20px;
}

.style_rateOne__135Gp {
    height: 90px;
    position: relative;
    top: -63%;
}

.style_rateTwo__1gen1 {
    height: 90px;
    position: relative;
    top: -43%;
    right: -54%;
}

.style_rateThree__2y0WQ {
    height: 90px;
    position: relative;
    top: -18%;
    right: -7%;
}

.style_lottieImage__14rEW {
    height: 100px;
    position: relative;
    left: 9%;
    top: -20px;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__5vpkD {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_leftBox__2cqiN {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 10%;
        margin: 0px auto;
    }

    .style_title__WI50A {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
    }

    .style_titleOne__1j_fW {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
        margin-right: 12px;
    }

    .style_titleTwo__3YGuU {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
    }

    .style_message__36s5A {
        font-size: 14px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

    .style_rightImagesBox__2h_XA {
        height: 350px;
        width: 90%;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
    }

    .style_studentGirl__3QSck {
        height: 300px;
        width: 90%;
        margin: 40px auto;
    }

    .style_rateOne__135Gp {
        height: 46px;
        width: 90px;
        position: relative;
        top: -72%;
    }

    .style_rateTwo__1gen1 {
        height: 46px;
        width: 90px;
        position: relative;
        top: -62%;
        right: -74%;
    }

    .style_rateThree__2y0WQ {
        height: 46px;
        width: 90px;
        position: relative;
        top: -52%;
        right: -64%;
    }

    .style_infoBox__b5GnI {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
    }

    .style_lottieImage__14rEW {
        height: 100px;
        position: relative;
        left: -29%;
        top: -10px;
    }
}
.style_mainContainer__2RPLO {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_testimoniesBox__1YbCF {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.style_title__-Z7yF {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTestimonies__2rTyP {
    position: relative;
    left: -10.5%;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.style_subtitle__2e1mc {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: Poppins;
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
}

.style_testimonies__3ndfE {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.style_currentTestimony__1A6fD {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
}

.style_currentTestimonyMessage__3ZXxw {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_currentTestimonyName__2zRjm {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_currentTestimonyUser__3cxdi {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_testimonyOneImage__sneYH {
    height: 92px;
    position: relative;
    top: -50px;
}

.style_testimonyTwoImage__o64bS {
    height: 124px;
    position: relative;
    left: -30px;
    top: 80px;
}

.style_ellipseOne__PsmTp {
    width: 10px;
    position: relative;
    top: 160px;
    left: -60px;
}

.style_testimonyThreeImage__fnHAR {
    height: 147px;
    position: relative;
    left: -30px;
    top: 120px;
}

.style_currentTestimonyImage__3dlpR {
    height: 200px;
}

.style_ellipseTwo__26Cja {
    position: relative;
    left: 30%;
    width: 15px;
    bottom: 305px;
}

.style_testimonyFourImage__34miO {
    height: 103px;
    position: relative;
    top: -120px;
}

.style_testimonyFiveImage__2u-OR {
    height: 109px;
    position: relative;
    top: 120px;
    right: -50px;
}

.style_ellipseThree__2EGIP {
    width: 15px;
    position: relative;
    top: 150px;
    right: 80px;
}

.style_testimonySixImage__3KwiM {
    height: 97px;
    position: relative;
    top: 150px;
    right: -30px;
}

@media only screen and (max-width: 680px) {

    .style_title__-Z7yF {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTestimonies__2rTyP {
        position: relative;
        left: -18%;
        width: 25px;
        bottom: 35px;
        z-index: -1;
    }

    .style_subtitle__2e1mc {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: Poppins;
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
    }

    .style_testimonies__3ndfE {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 350px;
        width: 100%;
    }

    .style_currentTestimony__1A6fD {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        text-align: center;
        position: relative;
        top: -100%;
    }

    .style_currentTestimonyMessage__3ZXxw {
        font-size: 12px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        position: relative;
        bottom: 20px;
    }

    .style_currentTestimonyName__2zRjm {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 20px;
    }

    .style_currentTestimonyUser__3cxdi {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 0px;
    }

    .style_testimonyOneImage__sneYH {
        height: 102px;
        position: relative;
        top: 0px;
        left: -35%;
    }

    .style_testimonyTwoImage__o64bS {
        height: 104px;
        position: relative;
        left: -40px;
        top: 270px;
    }

    .style_ellipseOne__PsmTp {
        width: 10px;
        position: relative;
        top: 30px;
        left: -35%;
    }

    .style_testimonyThreeImage__fnHAR {
        height: 127px;
        position: relative;
        left: -35%;
        top: 80px;
    }

    .style_currentTestimonyImage__3dlpR {
        height: 120px;
        margin: 20px auto;
    }

    .style_ellipseTwo__26Cja {
        position: relative;
        left: 40%;
        width: 10px;
        bottom: 65px;
    }

    .style_testimonyFourImage__34miO {
        height: 113px;
        position: relative;
        top: -750px;
        left: 120px;
    }

    .style_testimonyFiveImage__2u-OR {
        height: 79px;
        position: relative;
        top: -730px;
        left: 150px;
    }

    .style_ellipseThree__2EGIP {
        width: 10px;
        position: relative;
        top: -820px;
        right: 70px;
    }

    .style_testimonySixImage__3KwiM {
        height: 97px;
        position: relative;
        top: -570px;
        left: 120px;
    }

}
.style_mainContainer__23SSc {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_whyUsBox__3zVFX {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.style_title__3ItwO {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__vpw0R {
    position: relative;
    left: -20.7%;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.style_mainBox__1vUDb {
    width: 155%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-left: 21.5%;
    padding-right: 21%;
}

.style_subBox__3mC2O {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_circleOne__3iafh {
    height: 110px;
    width: 110px;
    background: rgba(245, 203, 147, 0.463);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleTwo__3tlob {
    height: 110px;
    width: 110px;
    background: rgba(138, 176, 177, 0.314);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleThree__3pGLf {
    height: 110px;
    width: 110px;
    background: rgba(255, 200, 186, 0.718);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleOneNumber__2noFn {
    color: rgb(224, 173, 106);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTwoNumber__1TjkY {
    color: rgb(138, 176, 177);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleThreeNumber__2gTZH {
    color: rgb(250, 131, 131);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTitle__2908q {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 25px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 40px;
}

.style_circleMessage__3q3-c {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 18px;
    color: #2F2F2F;
    line-height: 30px;
}

.style_quote__1a36l {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -320px;
    left: -37%;
}

.style_quote__1a36l:hover {
    color: rgba(162, 159, 159, 0.08);
}

@media only screen and (max-width: 680px) {

    .style_whyUsBox__3zVFX {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }

    .style_title__3ItwO {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__vpw0R {
        position: relative;
        left: -38%;
        width: 25px;
        bottom: 35px;
        z-index: -1;
    }

    .style_mainBox__1vUDb {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-left: 25px;
        padding-right: 25px;
    }

    .style_subBox__3mC2O {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .style_circleOne__3iafh {
        height: 65px;
        width: 65px;
        background: rgba(245, 203, 147, 0.463);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleTwo__3tlob {
        height: 65px;
        width: 65px;
        background: rgba(138, 176, 177, 0.314);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .style_circleThree__3pGLf {
        height: 65px;
        width: 65px;
        background: rgba(255, 200, 186, 0.718);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }

    .style_circleOneNumber__2noFn {
        color: rgb(224, 173, 106);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
    }

    .style_circleTwoNumber__1TjkY {
        color: rgb(138, 176, 177);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
    }

    .style_circleThreeNumber__2gTZH {
        color: rgb(250, 131, 131);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
    }

    .style_circleTitle__2908q {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 16px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 40px;
    }

    .style_circleMessage__3q3-c {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: #2F2F2F;
        line-height: 20px;
    }

    .style_quote__1a36l {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -320px;
        left: -37%;
    }

}
.style_faqBox__24ZqH {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    width: 45%;
    border-radius: 45px;
}

.style_faqFullBox__3TIdd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    width: 75%;
    border-radius: 45px;
}

.style_faqBox__24ZqH:hover {
    box-shadow: 14px 30px 34px #BFBCBC2F;
}

.style_arrowBox__3cwmb {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    height: 40px;
    width: 10%;
    cursor: pointer;
}

.style_arrowTitleBox__KPr_- {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    font-family: "Poppins";
    font-weight: bold;
    color: #393E3E;
    line-height: 30px;
    text-align: left;
    cursor: pointer;
}

.style_faqTitle__GTSoy {
    font-size: 20px;
    font-family: "Poppins";
    font-weight: bold;
    color: #393E3E;
    line-height: 30px;
    text-align: left;
    width: 85%;
}

.style_faqContent__296bn {
    font-size: 15px;
    font-family: "Poppins";
    font-weight: bold;
    color: #6A6500;
    line-height: 27px;
    text-align: left;
    margin-top: 20px;
}

@media only screen and (max-width: 680px) {

    .style_faqBox__24ZqH {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px;
        width: 100%;
        border-radius: 28px;
    }

    .style_faqFullBox__3TIdd {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px;
        width: 100%;
        border-radius: 28px;
    }

    .style_faqBox__24ZqH:hover {
        box-shadow: 14px 30px 34px #BFBCBC2F;
    }

    .style_arrowBox__3cwmb {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        height: 20px;
        width: 5%;
        cursor: pointer;
    }

    .style_arrowBox__3cwmb img {
        height: 10px;
    }

    .style_arrowTitleBox__KPr_- {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        font-size: 15px;
        font-family: "Poppins";
        font-weight: bold;
        color: #393E3E;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
    }

    .style_faqTitle__GTSoy {
        font-size: 15px;
        font-family: "Poppins";
        font-weight: bold;
        color: #393E3E;
        line-height: 20px;
        text-align: left;
        width: 85%;
    }

    .style_faqContent__296bn {
        font-size: 13px;
        font-family: "Poppins";
        font-weight: bold;
        color: #6A6564;
        line-height: 20px;
        text-align: left;
        margin-top: 20px;
    }

}
.style_mainContainer__3Zkhh {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_faqContainer__VwG3w {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    text-align: center;
    z-index: -1;
}

.style_title__1eKg1 {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__3pQSp {
    position: relative;
    left: -50px;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.style_mainBox__3ZStE {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 10px;
    padding-left: 20%;
    padding-right: 20%;
    background: white;
    z-index: 1;
}

.style_faqBox__1jXtn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    width: 45%;
    border-radius: 45px;
}

.style_faqBox__1jXtn:hover {
    box-shadow: 14px 30px 34px #BFBCBC2F;
}

.style_faqMainBox__JNRjQ {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 95%;
    margin-top: 30px;
}

.style_arrowBox__11fZy {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}

.style_faqTitle__25Qye {
    font-size: 20px;
    font-family: "Poppins";
    font-weight: bold;
    color: #393E3E;
    line-height: 30px;
    text-align: left;
    position: relative;
    top: -10px;
}

.style_faqContent__3JXf- {
    font-size: 15px;
    font-family: "Poppins";
    font-weight: bold;
    color: #6A6564;
    line-height: 27px;
    text-align: left;
}

.style_quote__3SMbK {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -375px;
    left: -34.5%;
}

.style_quote__3SMbK:hover {
    color: rgba(162, 159, 159, 0.08);
}

@media only screen and (max-width: 680px) {

    .style_title__1eKg1 {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__3pQSp {
        position: relative;
        left: -30px;
        width: 25px;
        bottom: 35px;
        z-index: -1;
    }

    .style_mainBox__3ZStE {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 40px;
        padding-left: 25px;
        padding-right: 25px;
        background: white;
        z-index: 1;
    }

    .style_faqBox__1jXtn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px;
        width: 45%;
        border-radius: 45px;
    }

    .style_faqBox__1jXtn:hover {
        box-shadow: 14px 30px 34px #BFBCBC2F;
    }

    .style_faqMainBox__JNRjQ {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 0px;
        height: auto;
    }

    .style_arrowBox__11fZy {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
    }

    .style_faqTitle__25Qye {
        font-size: 15px;
        font-family: "Poppins";
        font-weight: bold;
        color: #393E3E;
        line-height: 20px;
        text-align: left;
        position: relative;
        top: -10px;
    }

    .style_faqContent__3JXf- {
        font-size: 13px;
        font-family: "Poppins";
        font-weight: bold;
        color: #6A6564;
        line-height: 20px;
        text-align: left;
    }

    .style_quote__3SMbK {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -375px;
        left: -34.5%;
    }

}
.style_mainContainer__1inXZ {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0%;
}

.style_advantageContainer__23aZ1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__cWGA7 {
    display: flex;
    flex-direction: column;
    width: 120%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 10px;
    padding-left: 0%;
    padding-right: 0%;
    background: white;
    z-index: 1;
}

.style_title__3r44S {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__2v7VU {
    position: relative;
    left: -25.5%;
    width: 35px;
    bottom: 120px;
    z-index: 0;
}

.style_advantageBox__3YLxK {
    min-width: 304px;
    height: 304px;
    background: white;
    border: 0.5px solid #707070;
    border-radius: 43px;
    opacity: 1;
    margin-right: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.style_advantageBox__3YLxK:hover {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
}

.style_sildeBox__1a_rR {
    height: 383px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    position: relative;
    top: -120px;
    padding-left: 10%;
    padding-right: 20px;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.style_sildeBox__1a_rR::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__cWGA7 {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 10px;
        padding-left: 0%;
        padding-right: 0%;
        background: white;
        z-index: 1;
    }

    .style_title__3r44S {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__2v7VU {
        position: relative;
        left: -17%;
        width: 25px;
        bottom: 90px;
        z-index: 0;
    }

    .style_advantageBox__3YLxK {
        min-width: 340px;
        height: 304px;
        background: white;
        border: none;
        border-radius: 43px;
        opacity: 1;
        margin-right: 40px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    }

    .style_advantageBox__3YLxK:hover {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    }

    .style_sildeBox__1a_rR {
        height: 383px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        position: relative;
        top: -20px;
        padding-left: 14%;
        padding-right: 20px;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

}
.style_mainContainer__31FoQ {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_contactContainer__3HtsY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    text-align: center;
    z-index: -1;
}

.style_contactBox__38k0- {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
    width: 155%;
}

.style_contactMain__29Jil {
    height: 831px;
    width: 80%;
    margin: 0px auto;
    background: rgb(255, 248, 242);
    display: flex;
    flex-direction: row;
    z-index: -1;
}

.style_formBox__3rDfs {
    width: 50%;
    margin: 0px auto;
    padding: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.style_nameInput__HtVVP {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_lastnameInput__1kdq2 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_emailInput__3weVs {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_phoneInput__1ahqP {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_textareaInput__1wzDs {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    height: 250px;
    background: rgb(255, 248, 242);
}

.style_submitButton__1lE86 {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    padding: 1em 5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.style_contactTitle__1miFL {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__3gOaJ {
    position: relative;
    left: -145px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.style_contactSubtitle__396yq {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_checkboxTitle__2IFR- {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.style_checkboxStyle__2J9ph {
    color: #636363;
}

.style_inputDiv__qVeC6 {
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__31FoQ {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 2%;
        position: relative;
        top: 20px;
    }

    .style_contactBox__38k0- {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_contactMain__29Jil {
        height: auto;
        width: 100%;
        margin: 0px auto;
        background: rgb(255, 248, 242);
        display: flex;
        flex-direction: column;
    }

    .style_contactContainer__3HtsY {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
        text-align: center;
        z-index: -1;
    }

    .style_contactTitle__1miFL {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 10px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_contactSubtitle__396yq {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 0px;
        font-weight: 500;
        margin-top: 0px;
    }

    .style_ellipseTitle__3gOaJ {
        position: relative;
        left: -95px;
        width: 25px;
        bottom: 20px;
        z-index: -1;
    }

    .style_formBox__3rDfs {
        width: 100%;
        margin: 0px auto;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .style_nameInput__HtVVP {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_lastnameInput__1kdq2 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_emailInput__3weVs {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_phoneInput__1ahqP {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_textareaInput__1wzDs {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        height: 250px;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_submitButton__1lE86 {
        margin-top: 10px;
        cursor: pointer;
        border: none;
        padding: 1em 5em;
        background: var(--first-color);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        cursor: pointer;
        color: #fff;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .style_checkboxTitle__2IFR- {
        display: flex;
        flex-direction: row;
        text-align: left;
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_checkboxStyle__2J9ph {
        color: #636363;
        font-size: 10px;
        font-family: "Poppins";
        font-weight: 500;
    }

    .style_inputDiv__qVeC6 {
        text-align: left;
        width: 100%;
    }

}
.style_mainContainer__2sTdN {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 2%;
}

.style_needHelpBox__DKSBr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__1TQtK {
    display: flex;
    flex-direction: row;
    height: 670px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: rgb(255, 248, 242);
}

.style_leftBox__2jiVI {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 520px;
    width: 50%;
    padding-top: 2.5%;
    text-align: left;
}

.style_rightBox__1emcZ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 45%;
}

.style_title__1B29x {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 60px;
    margin-top: 0px;
    z-index: 1;
    text-align: left;
}

.style_ellipseTitle__1KhQH {
    position: relative;
    left: -1%;
    top: -180px;
    width: 37px;
    z-index: 0;
}

.style_buttonArrow__2howb {
    position: relative;
    left: 27%;
    top: -22px;
    width: 90px;
}

.style_ellipseLines__2XKMe {
    position: relative;
    left: -4%;
    top: -27%;
    width: 30px;
}

.style_ellipseImage__1PbQf {
    position: relative;
    right: 12%;
    top: -40%;
    width: 25px;
}

.style_backgroundParent__1zVjA {
    height: 440px;
    position: relative;
    right: 12%;
    top: 50px;
    z-index: 0;
}

.style_parentImage__15jJd {
    height: 640px;
    cursor: pointer;
    z-index: 1;
    position: relative;
    bottom: -16px;
    left: 30px;
}

.style_ellipseCommunity__1epPq {
    position: relative;
    right: 12%;
    top: -25%;
    width: 15px;
}

.style_ellipseInstaProf__3R1AS {
    position: relative;
    left: 24%;
    top: -28%;
    width: 25px;
}

.style_message__21gDY {
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_quote__yWpBl {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -455px;
    left: -34.5%;
}

.style_quote__yWpBl:hover {
    color: transparent;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__2sTdN {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 20px auto;
        padding: 0 2%;
    }

    .style_needHelpBox__DKSBr {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .style_mainBox__1TQtK {
        display: flex;
        flex-direction: column;
        height: 670px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        background: rgb(255, 248, 242);
    }

    .style_leftBox__2jiVI {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .style_rightBox__1emcZ {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 0px;
    }

    .style_title__1B29x {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 2;
        text-align: left;
    }

    .style_ellipseTitle__1KhQH {
        position: relative;
        left: -2%;
        top: -95px;
        width: 25px;
        z-index: 0;
    }

    .style_buttonArrow__2howb {
        position: relative;
        left: 40%;
        top: -10px;
        width: 70px;
        transform: rotate(-28deg);
    }

    .style_ellipseLines__2XKMe {
        position: relative;
        left: -5%;
        top: -22%;
        width: 25px;
    }

    .style_ellipseImage__1PbQf {
        position: relative;
        left: 25%;
        top: -40%;
        width: 20px;
        z-index: 1;
    }

    .style_backgroundParent__1zVjA {
        height: 200px;
        position: relative;
        right: 24%;
        top: -60px;
        z-index: 0;
    }

    .style_parentImage__15jJd {
        height: 260px;
        cursor: pointer;
        z-index: 1;
        position: relative;
        left: 30%;
        top: -90px;
    }

    .style_ellipseCommunity__1epPq {
        position: relative;
        right: -82%;
        top: -190%;
        width: 15px;
    }

    .style_ellipseInstaProf__3R1AS {
        position: relative;
        left: 84%;
        top: -175%;
        width: 15px;
        z-index: 1;
    }

    .style_message__21gDY {
        font-size: 13px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        text-align: left;
    }

    .style_quote__yWpBl {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -455px;
        left: -34.5%;
    }

}
.style_mainContainer__1T8Y5 {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_materialsContainer__3T60v {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    height: 420px;
}

.style_title__2BRib {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
    text-align: left;
    position: relative;
    left: -100px;
}

.style_ellipseTitle__2U8t0 {
    position: relative;
    left: -100px;
    width: 35px;
    bottom: 115px;
    z-index: -1;
}

.style_box__2Cgc4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
    background: white;
    height: 100%;
    padding-top: 2%;
    padding-bottom: 5%;
    padding-left: 5%;
}

.style_subBox__2Mjgz {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.style_circleOne__8uYHu {
    height: 80px;
    width: 80px;
    background: #FAE4C6;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleTwo__2Apsa {
    height: 80px;
    width: 80px;
    background: #DBE2DE;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleThree__1tIec {
    height: 80px;
    width: 80px;
    background: #FFD5C9;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleOneNumber__gbmuc {
    color: white;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTwoNumber__2ef7N {
    color: white;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleThreeNumber__1---R {
    color: white;
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTitle__3yEju {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 0px;
}

.style_circleMessage__3xOz0 {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(47, 47, 47);
    line-height: 20px;
}

.style_diploma__3sHso {
    position: relative;
    left: 40%;
    width: 320px;
    bottom: 75px;
    z-index: -1;
}

@media only screen and (max-width: 680px) {

    .style_materialsContainer__3T60v {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: auto;
        margin-top: 0px;
    }

    .style_title__2BRib {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 2;
        text-align: center;
        position: relative;
        top: -70px;
        left: 0px;
    }

    .style_ellipseTitle__2U8t0 {
        position: relative;
        left: -45px;
        width: 20px;
        bottom: 130px;
        z-index: 0;
    }

    .style_box__2Cgc4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: white;
        height: 100%;
        padding-top: 2%;
        padding-bottom: 5%;
        padding-left: 0%;
        position: relative;
        top: -50px;
    }

    .style_subBox__2Mjgz {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .style_circleOne__8uYHu {
        height: 62px;
        width: 62px;
        background: #FAE4C6;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleTwo__2Apsa {
        height: 62px;
        width: 62px;
        background: #DBE2DE;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleThree__1tIec {
        height: 62px;
        width: 62px;
        background: #FFD5C9;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleOneNumber__gbmuc {
        color: white;
        font-family: "Poppins";
        font-weight: bold;
        font-size: 45px;
        line-height: 68px;
    }

    .style_circleTwoNumber__2ef7N {
        color: white;
        font-family: "Poppins";
        font-weight: bold;
        font-size: 45px;
        line-height: 68px;
    }

    .style_circleThreeNumber__1---R {
        color: white;
        font-family: "Poppins";
        font-weight: bold;
        font-size: 45px;
        line-height: 68px;
    }

    .style_circleTitle__3yEju {
        font-family: "Poppins";
        font-weight: 600;
        font-size: 15px;
        color: rgb(47, 47, 47);
        line-height: 17px;
        margin-top: 15px;
    }

    .style_circleMessage__3xOz0 {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_diploma__3sHso {
        position: relative;
        left: -40%;
        height: 90px;
        width: 80px;
        bottom: 5px;
        z-index: -1;
        transform: rotate(20deg);
    }

}
.style_mainContainer__3wD6_ {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 40px auto;
    padding: 0 2%;
}

.style_plateformContainer__1fvat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__6Xylz {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: white;
}

.style_title__21MrI {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__28x_h {
    position: relative;
    left: -24.5%;
    width: 35px;
    bottom: 45px;
    z-index: 0;
}

.style_videoBox__1-ZZv {
    height: 575px;
    width: 95%;
    background-image: url(/static/media/video_bacground.d9687893.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 83px;
    position: relative;
    top: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__3wD6_ {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0px auto;
        padding: 0 2%;
    }

    .style_mainBox__6Xylz {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        background: white;
    }

    .style_title__21MrI {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__28x_h {
        position: relative;
        left: -17.5%;
        width: 20px;
        bottom: 70px;
        z-index: 0;
    }

    .style_videoBox__1-ZZv {
        height: 177px;
        width: 95%;
        background-image: url(/static/media/video_bacground.d9687893.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 32px;
        position: relative;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

}
.style_mainContainer__1jO95 {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_testimoniesBox__3fym- {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.style_title__2R8S0 {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTestimonies__143ze {
    position: relative;
    left: -15%;
    width: 35px;
    bottom: 60px;
    z-index: 0;
}

.style_subtitle__Fwup9 {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: Poppins;
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
}

.style_boxTitle__qXGLo {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 20px;
    color: rgb(8, 7, 7);
    line-height: 30px;
    margin-bottom: 5px;
}

.style_boxMessage__Rq7vF {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(57, 62, 62);
    line-height: 20px;
}

.style_squareBoxOne__JLKum {
    height: 84px;
    width: 84px;
    background: #FAE4C6;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.style_squareBoxTwo__571gr {
    height: 84px;
    width: 84px;
    background: #DBE2DE;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.style_squareBoxThree__T5jDZ {
    height: 84px;
    width: 84px;
    background: #FFC8BB;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.style_animatedBoxes__2iBzR {
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 680px) {

    .style_testimoniesBox__3fym- {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 100px auto;
    }

    .style_title__2R8S0 {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTestimonies__143ze {
        position: relative;
        left: -23%;
        width: 20px;
        bottom: 30px;
        z-index: 0;
    }

    .style_subtitle__Fwup9 {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
    }

    .style_boxTitle__qXGLo {
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 15px;
        color: rgb(8, 7, 7);
        line-height: 20px;
        margin-bottom: 5px;
    }

    .style_boxMessage__Rq7vF {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 12px;
        color: rgb(57, 62, 62);
        line-height: 16px;
    }

    .style_squareBoxOne__JLKum {
        height: 45px;
        width: 45px;
        background: #FAE4C6;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
    }

    .style_squareBoxTwo__571gr {
        height: 45px;
        width: 45px;
        background: #DBE2DE;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
    }

    .style_squareBoxThree__T5jDZ {
        height: 45px;
        width: 45px;
        background: #FFC8BB;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0px;
    }

    .style_animatedBoxes__2iBzR {
        display: flex;
        flex-direction: column;
        margin: 20px auto;
        align-items: center;
        justify-content: center;
    }

}
.style_mainContainer__dK1mK {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__DXqug {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.style_leftBox__2wOee {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.style_title__240gt {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 55px;
}

.style_ellipseTitle__14iHH {
    height: 110px;
    position: relative;
    left: 8%;
    bottom: 5px;
}

.style_message__3SVmt {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__DXqug {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_leftBox__2wOee {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 5%;
    }

    .style_title__240gt {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
    }

    .style_ellipseTitle__14iHH {
        height: 40px;
        position: relative;
        left: 0%;
        bottom: 5px;
    }

    .style_message__3SVmt {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

}
.style_mainContainer__3u-Ol {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_testimoniesBox__a5VlH {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.style_title__IWpMr {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTestimonies__3VOfP {
    position: relative;
    left: -10.5%;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.style_subtitle__2xR9B {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: Poppins;
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
}

.style_testimonies__1wZxB {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.style_currentTestimony__2RKXN {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
}

.style_currentTestimonyMessage__3ynzT {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_currentTestimonyName___3vjP {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_currentTestimonyUser__NiAIb {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_testimonyOneImage__2lORg {
    height: 92px;
    position: relative;
    top: -50px;
}

.style_testimonyTwoImage__2og1j {
    height: 124px;
    position: relative;
    left: -30px;
    top: 80px;
}

.style_ellipseOne__2j21j {
    width: 10px;
    position: relative;
    top: 160px;
    left: -60px;
}

.style_testimonyThreeImage__1wew5 {
    height: 147px;
    position: relative;
    left: -30px;
    top: 120px;
}

.style_currentTestimonyImage__2Nvlj {
    height: 200px;
}

.style_ellipseTwo__2Q_cG {
    position: relative;
    left: 30%;
    width: 15px;
    bottom: 305px;
}

.style_testimonyFourImage__27LED {
    height: 103px;
    position: relative;
    top: -120px;
}

.style_testimonyFiveImage__1b1CY {
    height: 109px;
    position: relative;
    top: 120px;
    right: -50px;
}

.style_ellipseThree__2Fov3 {
    width: 15px;
    position: relative;
    top: 150px;
    right: 80px;
}

.style_testimonySixImage__2UDye {
    height: 97px;
    position: relative;
    top: 150px;
    right: -30px;
}

@media only screen and (max-width: 680px) {

    .style_title__IWpMr {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTestimonies__3VOfP {
        position: relative;
        left: -14%;
        width: 25px;
        bottom: 125px;
        z-index: -1;
    }

    .style_subtitle__2xR9B {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
    }

    .style_testimonies__1wZxB {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 350px;
        width: 100%;
    }

    .style_currentTestimony__2RKXN {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        text-align: center;
        position: relative;
        top: -100%;
    }

    .style_currentTestimonyMessage__3ynzT {
        font-size: 12px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        position: relative;
        bottom: 20px;
    }

    .style_currentTestimonyName___3vjP {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 20px;
    }

    .style_currentTestimonyUser__NiAIb {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 0px;
    }

    .style_testimonyOneImage__2lORg {
        height: 102px;
        position: relative;
        top: 0px;
        left: -35%;
    }

    .style_testimonyTwoImage__2og1j {
        height: 104px;
        position: relative;
        left: -40px;
        top: 300px;
    }

    .style_ellipseOne__2j21j {
        width: 10px;
        position: relative;
        top: 30px;
        left: -35%;
    }

    .style_testimonyThreeImage__1wew5 {
        height: 127px;
        position: relative;
        left: -35%;
        top: 100px;
    }

    .style_currentTestimonyImage__2Nvlj {
        height: 120px;
        margin: 20px auto;
    }

    .style_ellipseTwo__2Q_cG {
        position: relative;
        left: 40%;
        width: 10px;
        bottom: 65px;
    }

    .style_testimonyFourImage__27LED {
        height: 113px;
        position: relative;
        top: -750px;
        left: 120px;
    }

    .style_testimonyFiveImage__1b1CY {
        height: 79px;
        position: relative;
        top: -780px;
        left: 150px;
    }

    .style_ellipseThree__2Fov3 {
        width: 10px;
        position: relative;
        top: -820px;
        right: 70px;
    }

    .style_testimonySixImage__2UDye {
        height: 97px;
        position: relative;
        top: -570px;
        left: 120px;
    }

}
.style_mainContainer__2XXR_ {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_advantageContainer__1-qjQ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__rnjQ_ {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: white;
}

.style_title__3_-mD {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__UHHaW {
    position: relative;
    left: -18.5%;
    width: 35px;
    bottom: 120px;
    z-index: 0;
}

.style_ellipseAdvantage__dMP6u {
    position: relative;
    left: 15%;
    height: 174px;
    bottom: 70px;
    z-index: 0;
}

.style_boxes__2_i5Z {
    height: 423px;
    width: 95%;
    background: #FFF8F2;
    border-radius: 43px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    position: relative;
    top: -40px;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
}

.style_subBox__26IZs {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_circleOne__2xHb4 {
    height: 110px;
    width: 110px;
    background: rgba(245, 203, 147, 0.463);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleTwo__1XkQ7 {
    height: 110px;
    width: 110px;
    background: rgba(138, 176, 177, 0.314);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleThree__1fB1X {
    height: 110px;
    width: 110px;
    background: rgba(255, 200, 186, 0.718);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleOneNumber__2YeKJ {
    color: rgb(224, 173, 106);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTwoNumber__3kDMx {
    color: rgb(138, 176, 177);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleThreeNumber__17fuz {
    color: rgb(250, 131, 131);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTitle__QqNP0 {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 40px;
}

.style_circleMessage__1OHZp {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 16px;
    color: rgb(47, 47, 47);
    line-height: 30px;
}

.style_aiBox__pOZDp {
    position: relative;
    bottom: 35px;
    left: 30px;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__rnjQ_ {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        background: white;
    }

    .style_title__3_-mD {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__UHHaW {
        position: relative;
        left: -43%;
        width: 25px;
        bottom: 65px;
        z-index: 0;
    }

    .style_ellipseAdvantage__dMP6u {
        position: relative;
        left: 38%;
        height: 84px;
        bottom: 20px;
        z-index: 2;
        transform: rotate(30deg);
    }

    .style_boxes__2_i5Z {
        height: 773px;
        width: 95%;
        background: #FFF8F2;
        border-radius: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        position: relative;
        top: -40px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .style_subBox__26IZs {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .style_circleOne__2xHb4 {
        height: 65px;
        width: 65px;
        background: rgba(245, 203, 147, 0.463);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleTwo__1XkQ7 {
        height: 65px;
        width: 65px;
        background: rgba(138, 176, 177, 0.314);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleThree__1fB1X {
        height: 65px;
        width: 65px;
        background: rgba(255, 200, 186, 0.718);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleOneNumber__2YeKJ {
        color: rgb(224, 173, 106);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .style_circleTwoNumber__3kDMx {
        color: rgb(138, 176, 177);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .style_circleThreeNumber__17fuz {
        color: rgb(250, 131, 131);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .style_circleTitle__QqNP0 {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 16px;
        color: rgb(47, 47, 47);
        line-height: 27px;
        margin-top: 20px;
    }

    .style_circleMessage__1OHZp {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
    }

    .style_aiBox__pOZDp {
        position: relative;
        bottom: 25px;
        left: 0px;
        height: 18px;
    }

}
.style_mainContainer__1erWM {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_contactContainer__30SDQ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    text-align: center;
    z-index: -1;
}

.style_contactBox__u4Lv1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
    width: 155%;
}

.style_contactMain__wwdLM {
    height: 831px;
    width: 80%;
    margin: 0px auto;
    background: rgb(255, 248, 242);
    display: flex;
    flex-direction: row;
    z-index: -1;
}

.style_formBox__3jC3x {
    width: 50%;
    margin: 0px auto;
    padding: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.style_nameInput__2HkE4 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_lastnameInput__1K6i3 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_emailInput__2B5w7 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_phoneInput__U07tR {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_textareaInput__B5fQ3 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    height: 250px;
    background: rgb(255, 248, 242);
}

.style_submitButton__3mWXr {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    padding: 1em 5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.style_contactTitle__3XEge {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__2j9WD {
    position: relative;
    left: -145px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.style_contactSubtitle__2gOyD {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_checkboxTitle__mb8tW {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.style_checkboxStyle__2Qos5 {
    color: #636363;
}

.style_inputDiv__1hCC3 {
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__1erWM {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 2%;
        position: relative;
        top: 20px;
    }

    .style_contactBox__u4Lv1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_contactMain__wwdLM {
        height: auto;
        width: 100%;
        margin: 0px auto;
        background: rgb(255, 248, 242);
        display: flex;
        flex-direction: column;
    }

    .style_contactContainer__30SDQ {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
        text-align: center;
        z-index: -1;
    }

    .style_contactTitle__3XEge {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 10px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_contactSubtitle__2gOyD {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 0px;
        font-weight: 500;
        margin-top: 0px;
    }

    .style_ellipseTitle__2j9WD {
        position: relative;
        left: -95px;
        width: 25px;
        bottom: 20px;
        z-index: -1;
    }

    .style_formBox__3jC3x {
        width: 100%;
        margin: 0px auto;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .style_nameInput__2HkE4 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_lastnameInput__1K6i3 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_emailInput__2B5w7 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_phoneInput__U07tR {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_textareaInput__B5fQ3 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        height: 250px;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_submitButton__3mWXr {
        margin-top: 10px;
        cursor: pointer;
        border: none;
        padding: 1em 5em;
        background: var(--first-color);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        cursor: pointer;
        color: #fff;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .style_checkboxTitle__mb8tW {
        display: flex;
        flex-direction: row;
        text-align: left;
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_checkboxStyle__2Qos5 {
        color: #636363;
        font-size: 10px;
        font-family: "Poppins";
        font-weight: 500;
    }

    .style_inputDiv__1hCC3 {
        text-align: left;
        width: 100%;
    }

}
.style_mainContainer__26BoQ {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 2%;
}

.style_needHelpBox__1cTL4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__1BwO2 {
    display: flex;
    flex-direction: row;
    height: 640px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: rgb(255, 248, 242);
}

.style_leftBox__1r0Ez {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 520px;
    width: 50%;
    padding-top: 2.5%;
    text-align: left;
}

.style_rightBox__1hyHQ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 45%;
}

.style_title__1mE5_ {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 60px;
    margin-top: 0px;
    z-index: 1;
    text-align: left;
}

.style_ellipseTitle__1NlwF {
    position: relative;
    left: -1%;
    top: -180px;
    width: 37px;
    z-index: 0;
}

.style_buttonArrow__1cSAW {
    position: relative;
    left: 30%;
    top: -32px;
    width: 90px;
}

.style_ellipseLines__srxKh {
    position: relative;
    left: -4%;
    top: -27%;
    width: 30px;
}

.style_ellipseImage__2FswU {
    position: relative;
    right: 12%;
    top: -40%;
    width: 25px;
}

.style_backgroundParent__2RKcW {
    height: 440px;
    position: relative;
    right: 35%;
    top: 100px;
    z-index: 0;
}

.style_parentImage__1CF3x {
    height: 540px;
    cursor: pointer;
    z-index: 1;
    position: relative;
    left: 30%;
    top: 50px;
}

.style_ellipseInstaProf__3a_BG {
    position: relative;
    left: 24%;
    top: -28%;
    width: 25px;
}

.style_message__1wW3K {
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_quote__-TQq4 {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -455px;
    left: -34.5%;
}

.style_quote__-TQq4:hover {
    color: transparent;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__26BoQ {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 70px auto;
        padding: 0 2%;
    }

    .style_needHelpBox__1cTL4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .style_mainBox__1BwO2 {
        display: flex;
        flex-direction: column;
        height: 670px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        top: 30px;
        padding-left: 0px;
        padding-right: 0px;
        background: rgb(255, 248, 242);
    }

    .style_leftBox__1r0Ez {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .style_rightBox__1hyHQ {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 0px;
    }

    .style_title__1mE5_ {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 2;
        text-align: left;
    }

    .style_ellipseTitle__1NlwF {
        position: relative;
        left: -2%;
        top: -95px;
        width: 25px;
        z-index: 0;
    }

    .style_buttonArrow__1cSAW {
        position: relative;
        left: 40%;
        top: -10px;
        width: 70px;
        transform: rotate(-28deg);
    }

    .style_ellipseLines__srxKh {
        position: relative;
        left: -5%;
        top: -22%;
        width: 25px;
    }

    .style_ellipseImage__2FswU {
        position: relative;
        left: 20%;
        top: -50%;
        width: 20px;
        z-index: 1;
    }

    .style_backgroundParent__2RKcW {
        height: 200px;
        position: relative;
        right: 25%;
        top: -40px;
        z-index: 0;
    }

    .style_parentImage__1CF3x {
        height: 260px;
        cursor: pointer;
        z-index: 1;
        position: relative;
        left: 30%;
        top: -70px;
    }

    .style_ellipseInstaProf__3a_BG {
        position: relative;
        left: 84%;
        top: -185%;
        width: 15px;
        z-index: 1;
    }

    .style_message__1wW3K {
        font-size: 13px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        text-align: left;
    }

    .style_quote__-TQq4 {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -455px;
        left: -34.5%;
    }

}
.style_mainContainer__Ir8rY {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 40px auto;
    padding: 0 2%;
}

.style_plateformContainer__15OsS {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__Er6SO {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: white;
}

.style_title__3e5MV {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__1KtIv {
    position: relative;
    left: -24.5%;
    width: 35px;
    bottom: 45px;
    z-index: 0;
}

.style_videoBox__8HPBo {
    height: 575px;
    width: 95%;
    background-image: url(/static/media/video_bacground.d9687893.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 83px;
    position: relative;
    top: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__Ir8rY {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0px auto;
        padding: 0 2%;
    }

    .style_mainBox__Er6SO {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        background: white;
    }

    .style_title__3e5MV {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__1KtIv {
        position: relative;
        left: -17.5%;
        width: 20px;
        bottom: 70px;
        z-index: 0;
    }

    .style_videoBox__8HPBo {
        height: 177px;
        width: 95%;
        background-image: url(/static/media/video_bacground.d9687893.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 32px;
        position: relative;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

}
.style_mainContainer__1PNHB {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__gUPec {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.style_leftBox__pgfd3 {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.style_title__1ioZf {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 55px;
}

.style_message__2SXT9 {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

.style_titleLines__KoNSe {
    height: 110px;
    position: relative;
    left: 8%;
    bottom: 5px;
}

.style_parentOne__1l42h {
    height: 220px;
    margin-top: 40px;
    position: relative;
    left: -30px;
}

.style_parentTwo__2nsFX {
    height: 225px;
    margin-top: 40px;
    z-index: 1;
}

.style_imagesBox__23mck {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.style_ellipseStudentTwo__3ztcV {
    height: 100px;
    position: relative;
    left: -25%;
    bottom: 0px;
    z-index: 0;
}

.style_parentThree__gMhEm {
    height: 220px;
    margin-top: 40px;
    position: relative;
    left: -30px;
    z-index: 1;
}

.style_parentFour__35H8D {
    height: 225px;
    margin-top: 40px;
    z-index: 1;
}

.style_ellipseLines__4DDdV {
    height: 70px;
    position: relative;
    right: 105%;
    bottom: 0px;
}

.style_ellipseMaterial__2w7H5 {
    height: 25px;
    position: relative;
    left: -31%;
    top: 130px;
}

.style_traceInstaProf__3UZAY {
    height: 100px;
    position: relative;
    left: -28%;
    bottom: 0px;
}

.style_ellipseStudentThree__3AmmD {
    height: 90px;
    position: relative;
    right: 107%;
    top: 210px;
    z-index: 0;
}

.style_ellipseStudentFour__3X8YH {
    height: 90px;
    position: relative;
    left: -27%;
    top: 130px;
    z-index: 0;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__gUPec {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_leftBox__pgfd3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 5%;
    }

    .style_title__1ioZf {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
    }

    .style_message__2SXT9 {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

    .style_titleLines__KoNSe {
        height: 44px;
        position: relative;
        left: 0%;
        bottom: 5px;
    }

    .style_parentOne__1l42h {
        height: 150px;
        margin-top: 40px;
        position: relative;
        left: -30px;
    }

    .style_parentTwo__2nsFX {
        height: 150px;
        margin-top: 40px;
        z-index: 1;
    }

    .style_imagesBox__23mck {
        width: 40%;
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
    }

    .style_ellipseStudentTwo__3ztcV {
        height: 100px;
        position: relative;
        left: -160%;
        bottom: 20px;
        z-index: 0;
    }

    .style_parentThree__gMhEm {
        height: 150px;
        margin-top: 40px;
        position: relative;
        left: -30px;
        z-index: 1;
    }

    .style_parentFour__35H8D {
        height: 150px;
        margin-top: 40px;
        z-index: 1;
    }

    .style_ellipseLines__4DDdV {
        height: 65px;
        position: relative;
        right: 435%;
        bottom: 0px;
    }

    .style_ellipseMaterial__2w7H5 {
        height: 25px;
        position: relative;
        left: -180%;
        top: 130px;
    }

    .style_traceInstaProf__3UZAY {
        height: 70px;
        position: relative;
        left: -238%;
        bottom: 40px;
    }

    .style_ellipseStudentThree__3AmmD {
        height: 90px;
        position: relative;
        right: 427%;
        top: 120px;
        z-index: 0;
    }

    .style_ellipseStudentFour__3X8YH {
        height: 90px;
        position: relative;
        left: -180%;
        top: 80px;
        z-index: 0;
    }

}
.style_mainContainer__3-kTw {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_testimoniesBox__30_xu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.style_title__T5XsX {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTestimonies__29m5p {
    position: relative;
    left: -10.5%;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.style_subtitle__27Ppm {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: Poppins;
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
}

.style_testimonies__1g3It {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.style_currentTestimony__2nTla {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
}

.style_currentTestimonyMessage__3Gi5I {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_currentTestimonyName__3JfpL {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_currentTestimonyUser__13iDJ {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_testimonyOneImage__2ct2f {
    height: 92px;
    position: relative;
    top: -50px;
}

.style_testimonyTwoImage__2nCqb {
    height: 124px;
    position: relative;
    left: -30px;
    top: 80px;
}

.style_ellipseOne__2CMc_ {
    width: 10px;
    position: relative;
    top: 160px;
    left: -60px;
}

.style_testimonyThreeImage__fQDei {
    height: 147px;
    position: relative;
    left: -30px;
    top: 120px;
}

.style_currentTestimonyImage__A8Zbk {
    height: 200px;
}

.style_ellipseTwo__31Aiu {
    position: relative;
    left: 30%;
    width: 15px;
    bottom: 405px;
}

.style_testimonyFourImage__14mAM {
    height: 103px;
    position: relative;
    top: -120px;
}

.style_testimonyFiveImage__N89rL {
    height: 109px;
    position: relative;
    top: 120px;
    right: -50px;
}

.style_ellipseThree__2fnzm {
    width: 15px;
    position: relative;
    top: 150px;
    right: 80px;
}

.style_testimonySixImage__2We_x {
    height: 97px;
    position: relative;
    top: 150px;
    right: -30px;
}

@media only screen and (max-width: 680px) {

    .style_title__T5XsX {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTestimonies__29m5p {
        position: relative;
        left: -14%;
        width: 25px;
        bottom: 125px;
        z-index: -1;
    }

    .style_subtitle__27Ppm {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: Poppins;
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
    }

    .style_testimonies__1g3It {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 350px;
        width: 100%;
    }

    .style_currentTestimony__2nTla {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        text-align: center;
        position: relative;
        top: -100%;
    }

    .style_currentTestimonyMessage__3Gi5I {
        font-size: 12px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        position: relative;
        bottom: 20px;
    }

    .style_currentTestimonyName__3JfpL {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 20px;
    }

    .style_currentTestimonyUser__13iDJ {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 0px;
    }

    .style_testimonyOneImage__2ct2f {
        height: 102px;
        position: relative;
        top: 0px;
        left: -35%;
    }

    .style_testimonyTwoImage__2nCqb {
        height: 104px;
        position: relative;
        left: -40px;
        top: 300px;
    }

    .style_ellipseOne__2CMc_ {
        width: 10px;
        position: relative;
        top: 30px;
        left: -35%;
    }

    .style_testimonyThreeImage__fQDei {
        height: 127px;
        position: relative;
        left: -35%;
        top: 100px;
    }

    .style_currentTestimonyImage__A8Zbk {
        height: 120px;
        margin: 20px auto;
    }

    .style_ellipseTwo__31Aiu {
        position: relative;
        left: 40%;
        width: 10px;
        bottom: 65px;
    }

    .style_testimonyFourImage__14mAM {
        height: 113px;
        position: relative;
        top: -750px;
        left: 120px;
    }

    .style_testimonyFiveImage__N89rL {
        height: 79px;
        position: relative;
        top: -780px;
        left: 150px;
    }

    .style_ellipseThree__2fnzm {
        width: 10px;
        position: relative;
        top: -820px;
        right: 70px;
    }

    .style_testimonySixImage__2We_x {
        height: 97px;
        position: relative;
        top: -570px;
        left: 120px;
    }

}
.style_mainContainer__1E5VD {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_advantageContainer__3id0M {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__WFKUP {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: white;
}

.style_title__2wQka {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__f1zWO {
    position: relative;
    left: -31.5%;
    width: 35px;
    bottom: 120px;
    z-index: 0;
}

.style_ellipseAdvantage__3EU0P {
    position: relative;
    left: 19%;
    height: 174px;
    bottom: 70px;
    z-index: 0;
}

.style_boxes__hPuAa {
    height: 423px;
    width: 95%;
    background: #FFF8F2;
    border-radius: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    position: relative;
    top: -40px;
    padding-left: 30px;
    padding-right: 30px;
}

.style_subBox__3iDzA {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_circleOne__3OeaI {
    height: 110px;
    width: 110px;
    background: rgba(245, 203, 147, 0.463);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleTwo__2MA3q {
    height: 110px;
    width: 110px;
    background: rgba(138, 176, 177, 0.314);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleThree__32yDz {
    height: 110px;
    width: 110px;
    background: rgba(255, 200, 186, 0.718);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleOneNumber__d07oT {
    color: rgb(224, 173, 106);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTwoNumber__193sU {
    color: rgb(138, 176, 177);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleThreeNumber__5cwoO {
    color: rgb(250, 131, 131);
    font-family: "Poppins";
    font-weight: bold;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTitle__3zVtc {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 40px;
}

.style_circleMessage__2uBQ9 {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 16px;
    color: rgb(47, 47, 47);
    line-height: 30px;
}

.style_aiBox__1F66J {
    position: relative;
    bottom: 35px;
    left: 30px;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__WFKUP {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 30px;
        padding-right: 30px;
        background: white;
    }

    .style_title__2wQka {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__f1zWO {
        position: relative;
        left: -25%;
        width: 25px;
        bottom: 95px;
        z-index: 0;
    }

    .style_ellipseAdvantage__3EU0P {
        position: relative;
        left: 38%;
        height: 84px;
        bottom: 20px;
        z-index: 2;
        transform: rotate(30deg);
    }

    .style_boxes__hPuAa {
        height: 773px;
        width: 95%;
        background: #FFF8F2;
        border-radius: 43px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        position: relative;
        top: -40px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .style_subBox__3iDzA {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .style_circleOne__3OeaI {
        height: 65px;
        width: 65px;
        background: rgba(245, 203, 147, 0.463);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleTwo__2MA3q {
        height: 65px;
        width: 65px;
        background: rgba(138, 176, 177, 0.314);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleThree__32yDz {
        height: 65px;
        width: 65px;
        background: rgba(255, 200, 186, 0.718);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleOneNumber__d07oT {
        color: rgb(224, 173, 106);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .style_circleTwoNumber__193sU {
        color: rgb(138, 176, 177);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .style_circleThreeNumber__5cwoO {
        color: rgb(250, 131, 131);
        font-family: "Poppins";
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
    }

    .style_circleTitle__3zVtc {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 16px;
        color: rgb(47, 47, 47);
        line-height: 27px;
        margin-top: 20px;
    }

    .style_circleMessage__2uBQ9 {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
    }

    .style_aiBox__1F66J {
        position: relative;
        bottom: 25px;
        left: 0px;
        height: 18px;
    }

}
.style_mainContainer__25sCB {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_contactContainer__9d1_H {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    text-align: center;
    z-index: -1;
}

.style_contactBox__3qoEy {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
    width: 155%;
}

.style_contactMain__bYAMd {
    height: 831px;
    width: 80%;
    margin: 0px auto;
    background: rgb(255, 248, 242);
    display: flex;
    flex-direction: row;
}

.style_formBox__19QWn {
    width: 50%;
    margin: 0px auto;
    padding: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.style_nameInput__1udV8 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_lastnameInput__WgPu7 {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 200px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_emailInput__XiWtM {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_phoneInput__2un7Z {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    background: rgb(255, 248, 242);
}

.style_textareaInput__1cVrJ {
    border-top: none;
    border-right: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-image: initial;
    padding: 8px;
    margin-bottom: 20px;
    width: 444px;
    box-sizing: border-box;
    height: 250px;
    background: rgb(255, 248, 242);
}

.style_submitButton__SmBlV {
    margin-top: 10px;
    cursor: pointer;
    border: none;
    padding: 1em 5em;
    background: var(--first-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.style_contactTitle__2kN_U {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__3JQxc {
    position: relative;
    left: -145px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.style_contactSubtitle__3ht32 {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_checkboxTitle__P-8wk {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.style_checkboxStyle__3GNOt {
    color: #636363;
}

.style_inputDiv__Xgb5Z {
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__25sCB {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0 auto;
        padding: 0 2%;
        position: relative;
        top: 20px;
    }

    .style_contactBox__3qoEy {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 35px;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_contactMain__bYAMd {
        height: auto;
        width: 100%;
        margin: 0px auto;
        background: rgb(255, 248, 242);
        display: flex;
        flex-direction: column;
    }

    .style_contactContainer__9d1_H {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 120px;
        text-align: center;
        z-index: -1;
    }

    .style_contactTitle__2kN_U {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 10px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_contactSubtitle__3ht32 {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 0px;
        font-weight: 500;
        margin-top: 0px;
    }

    .style_ellipseTitle__3JQxc {
        position: relative;
        left: -95px;
        width: 25px;
        bottom: 20px;
        z-index: -1;
    }

    .style_formBox__19QWn {
        width: 100%;
        margin: 0px auto;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .style_nameInput__1udV8 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_lastnameInput__WgPu7 {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_emailInput__XiWtM {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_phoneInput__2un7Z {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_textareaInput__1cVrJ {
        border-top: none;
        border-right: none;
        border-bottom: 2px solid rgba(0, 0, 0, 0.5);
        border-left: none;
        border-image: initial;
        padding: 0px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
        height: 250px;
        background: rgb(255, 248, 242);
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_submitButton__SmBlV {
        margin-top: 10px;
        cursor: pointer;
        border: none;
        padding: 1em 5em;
        background: var(--first-color);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        cursor: pointer;
        color: #fff;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }

    .style_checkboxTitle__P-8wk {
        display: flex;
        flex-direction: row;
        text-align: left;
        font-size: 10px;
        font-family: "Poppins";
    }

    .style_checkboxStyle__3GNOt {
        color: #636363;
        font-size: 10px;
        font-family: "Poppins";
        font-weight: 500;
    }

    .style_inputDiv__Xgb5Z {
        text-align: left;
        width: 100%;
    }

}
.style_mainContainer__31eSt {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_needHelpBox__1Qw7l {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__1wXDi {
    display: flex;
    flex-direction: row;
    height: 540px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: rgb(255, 248, 242);
}

.style_leftBox__105W7 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 520px;
    width: 50%;
    padding-top: 4.5%;
    text-align: left;
}

.style_rightBox__261Q6 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 45%;
}

.style_title__RRHLA {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 40px;
    margin-top: 0px;
    z-index: 1;
}

.style_ellipseTitle__2BEZ5 {
    position: relative;
    left: -1%;
    top: -50px;
    width: 37px;
    z-index: -1;
}

.style_message__2xcCw {
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_instaTitle__36LPg {
    font-size: 22px;
    color: #2F2F2F;
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_instaTitle__36LPg:hover {
    color: #2F2F2F;
}

.style_instaMessage__2uU1M {
    font-size: 18px;
    color: #8AB0B1;
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_instaMessage__2uU1M:hover {
    color: #8AB0B1;
}

.style_arrowNeedHelp__-Q4aY {
    position: relative;
    left: 30%;
    top: -32px;
    width: 90px;
}

.style_instaProfImage__1bdEd {
    height: 420px;
    position: relative;
    right: 40px;
}

.style_callAction__2AkEl {
    height: 65px;
    width: 45.5%;
    z-index: 2;
    position: relative;
    bottom: 170px;
    left: 28.3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_buttonStyle__3b0Wk {
    font-family: "Poppins";
    font-weight: bolder;
    cursor: pointer;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__1wXDi {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        top: 80px;
        padding-left: 0px;
        padding-right: 0px;
        background: rgb(255, 248, 242);
        text-align: left;
    }

    .style_leftBox__105W7 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding-top: 4.5%;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .style_rightBox__261Q6 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_title__RRHLA {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 2;
        text-align: left;
    }

    .style_ellipseTitle__2BEZ5 {
        position: relative;
        left: -1%;
        top: -35px;
        width: 25px;
        z-index: 0;
    }

    .style_messageOne__2JQBU {
        font-size: 15px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: 500;
        text-align: left;
    }

    .style_message__2xcCw {
        font-size: 13px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        text-align: left;
    }

    .style_arrowNeedHelp__-Q4aY {
        position: relative;
        left: 30%;
        top: -32px;
        height: 80px;
        transform: rotate(-40deg);
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .style_instaProfImage__1bdEd {
        height: auto;
        width: 100%;
        position: relative;
        right: 0px;
        z-index: 1;
    }

    .style_callAction__2AkEl {
        height: auto;
        width: 70%;
        z-index: 2;
        position: relative;
        bottom: 60px;
        left: 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
    }

    .style_instaTitle__36LPg {
        font-size: 9px;
        color: #2F2F2F;
        font-family: "Poppins";
        line-height: 15px;
        font-weight: normal;
        text-align: left;
    }

    .style_instaMessage__2uU1M {
        font-size: 8px;
        color: #8AB0B1;
        font-family: "Poppins";
        line-height: 15px;
        font-weight: normal;
        text-align: left;
    }

    .style_buttonStyle__3b0Wk {
        font-size: 12px;
        font-family: "Poppins";
        font-weight: bolder;
        cursor: pointer;
    }

}
.style_mainContainer__DRqf0 {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 2%;
}

.style_needHelpBox__ZS1X4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__z2Hyc {
    display: flex;
    flex-direction: row;
    height: 540px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: rgb(255, 248, 242);
}

.style_leftBox__1T-JR {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 520px;
    width: 50%;
    padding-top: 2.5%;
    text-align: left;
}

.style_rightBox__2yF0K {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 45%;
}

.style_title__Lh9zV {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 60px;
    margin-top: 0px;
    z-index: 1;
    text-align: left;
}

.style_ellipseTitle__20eWH {
    position: relative;
    left: -1%;
    top: -180px;
    width: 37px;
    z-index: 0;
}

.style_buttonArrow__Zyp8z {
    position: relative;
    left: 30%;
    top: -32px;
    width: 90px;
}

.style_ellipseLines__14rRy {
    position: relative;
    left: -4%;
    top: -27%;
    width: 30px;
}

.style_ellipseImage__4lIKc {
    position: relative;
    right: 12%;
    top: -40%;
    width: 25px;
}

.style_message__m5Jvr {
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.style_quote__SZKC7 {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -455px;
    left: -34.5%;
}

.style_quote__SZKC7:hover {
    color: transparent;
}

@media only screen and (max-width: 680px) {

    .style_needHelpBox__ZS1X4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .style_mainBox__z2Hyc {
        display: flex;
        flex-direction: column;
        height: 770px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        top: 30px;
        padding-left: 0px;
        padding-right: 0px;
        background: rgb(255, 248, 242);
    }

    .style_leftBox__1T-JR {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .style_rightBox__2yF0K {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 0px;
    }

    .style_title__Lh9zV {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 2;
        text-align: left;
    }

    .style_ellipseTitle__20eWH {
        position: relative;
        left: -2%;
        top: -95px;
        width: 25px;
        z-index: 0;
    }

    .style_buttonArrow__Zyp8z {
        position: relative;
        left: 40%;
        top: -10px;
        width: 70px;
        transform: rotate(-28deg);
    }

    .style_ellipseLines__14rRy {
        position: relative;
        left: -5%;
        top: -22%;
        width: 25px;
    }

    .style_ellipseImage__4lIKc {
        position: relative;
        right: 7%;
        top: -40%;
        width: 15px;
    }

    .style_message__m5Jvr {
        font-size: 13px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        text-align: left;
    }

    .style_quote__SZKC7 {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -455px;
        left: -34.5%;
    }

}
.style_mainContainer__25zrv {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 40px auto;
    padding: 0 2%;
}

.style_plateformContainer__2PFff {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.style_mainBox__3Szkw {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: white;
}

.style_title__2W1-J {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTitle__1poku {
    position: relative;
    left: -24.5%;
    width: 35px;
    bottom: 45px;
    z-index: 0;
}

.style_videoBox__31Cfx {
    height: 575px;
    width: 95%;
    background-image: url(/static/media/video_bacground.d9687893.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 83px;
    position: relative;
    top: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media only screen and (max-width: 680px) {

    .style_mainContainer__25zrv {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 0px auto;
        padding: 0 2%;
    }

    .style_mainBox__3Szkw {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 30px;
        padding-left: 20px;
        padding-right: 20px;
        background: white;
    }

    .style_title__2W1-J {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTitle__1poku {
        position: relative;
        left: -17.5%;
        width: 20px;
        bottom: 70px;
        z-index: 0;
    }

    .style_videoBox__31Cfx {
        height: 177px;
        width: 95%;
        background-image: url(/static/media/video_bacground.d9687893.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 32px;
        position: relative;
        top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

}
.style_mainContainer__3EPXN {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_mainBox__1OlA3 {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.style_leftBox__3DMF1 {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.style_title__2zdkE {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 55px;
}

.style_traceTitle__3zVCC {
    height: 110px;
    position: relative;
    left: 8%;
    bottom: 5px;
}

.style_traceButton__2GWP_ {
    height: 170px;
    position: relative;
    left: 12%;
    bottom: 18px;
}

.style_titleBox__fdsK_ {
    display: flex;
    flex-direction: row;
}

.style_titleOne__2trH5 {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
    margin-right: 12px;
}

.style_titleTwo__37Krj {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
}

.style_message__Wsk4G {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

.style_infoBox__2ckoc {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.style_imagesBox__1Llps {
    width: 40%;
    display: flex;
    flex-direction: row;
}

.style_teacherOne__3hevD {
    height: 350px;
    margin-top: 80px;
    margin-right: 10px;
}

.style_teacherTwo__11kdm {
    height: 380px;
    margin-top: 80px;
}

.style_communityLines__3cG54 {
    height: 70px;
    position: relative;
    left: -57%;
    bottom: 0px;
}

.style_traceInstaProf__1Cwt7 {
    height: 70px;
    position: relative;
    left: -15%;
    bottom: 0px;
}

.style_todoBook__gYzER {
    height: 170px;
    position: relative;
    left: -150%;
    bottom: -62%;
}

.style_numbersBox__2qLbS {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    left: -30px;
}

.style_numberOne__12WdB {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 14px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.style_numberTwo__3MHci {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 12px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.style_box__21va2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 190px;
    width: 100%;
    background: white;
    border-radius: 44px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.style_subBox__1X45k {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.style_circleOne__2LV5H {
    height: 80px;
    width: 80px;
    background: #F5CC92;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleTwo__FZNtu {
    height: 80px;
    width: 80px;
    background: #8AB0B1;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleThree__2hU1e {
    height: 80px;
    width: 80px;
    background: #FFC8BB;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_circleOneNumber__3bm98 {
    color: white;
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTwoNumber__13nVO {
    color: white;
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 45px;
    line-height: 68px;
}

.style_circleThreeNumber__2iqiC {
    color: white;
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 45px;
    line-height: 68px;
}

.style_circleTitle__3GFmP {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 0px;
}

.style_circleMessage__1yLTa {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(47, 47, 47);
    line-height: 20px;
}

.style_boxTitle__1tKOE {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 10px;
}

.style_boxMessage__2UcHg {
    margin-top: 0px;
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .style_mainBox__1OlA3 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_leftBox__3DMF1 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 5%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_title__2zdkE {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
    }

    .style_traceTitle__3zVCC {
        height: 40px;
        position: relative;
        left: 8%;
        bottom: 5px;
    }

    .style_traceButton__2GWP_ {
        height: 80px;
        position: relative;
        left: 55%;
        bottom: -30px;
    }

    .style_titleBox__fdsK_ {
        display: flex;
        flex-direction: row;
    }

    .style_titleOne__2trH5 {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
        margin-right: 0px;
    }

    .style_titleTwo__37Krj {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
    }

    .style_message__Wsk4G {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

    .style_infoBox__2ckoc {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
    }

    .style_imagesBox__1Llps {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 50px;
    }

    .style_teacherOne__3hevD {
        height: 250px;
        width: 50%;
        margin-top: 0px;
        margin-right: 10px;
    }

    .style_teacherTwo__11kdm {
        height: 280px;
        width: 50%;
        margin-top: 0px;
    }

    .style_communityLines__3cG54 {
        height: 70px;
        position: relative;
        left: -60%;
        bottom: 70px;
    }

    .style_traceInstaProf__1Cwt7 {
        height: 70px;
        position: relative;
        left: -25%;
        bottom: 80px;
    }

    .style_todoBook__gYzER {
        height: 74px;
        position: relative;
        left: -100%;
        bottom: -270px;
    }

    .style_box__21va2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 516px;
        width: 85%;
        background: white;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 0px;
        padding-right: 0px;
        margin: 70px auto;
    }

    .style_subBox__1X45k {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .style_circleOne__2LV5H {
        height: 40px;
        width: 40px;
        background: #F5CC92;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleTwo__FZNtu {
        height: 40px;
        width: 40px;
        background: #8AB0B1;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleThree__2hU1e {
        height: 40px;
        width: 40px;
        background: #FFC8BB;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .style_circleOneNumber__3bm98 {
        color: white;
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 22px;
        line-height: 38px;
    }

    .style_circleTwoNumber__13nVO {
        color: white;
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 22px;
        line-height: 38px;
    }

    .style_circleThreeNumber__2iqiC {
        color: white;
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 22px;
        line-height: 38px;
    }

    .style_circleTitle__3GFmP {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 16px;
        color: rgb(47, 47, 47);
        line-height: 26px;
        margin-top: 0px;
    }

    .style_circleMessage__1yLTa {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
    }

    .style_boxTitle__1tKOE {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-top: 10px;
    }

    .style_boxMessage__2UcHg {
        margin-top: 0px;
        text-align: center;
    }

}
.style_mainContainer__2DCPJ {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.style_testimoniesBox__2MTGz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

.style_title__2kioI {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.style_ellipseTestimonies__3Tf4M {
    position: relative;
    left: -10.5%;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.style_subtitle__2NAXs {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: Poppins;
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
}

.style_testimonies__2Sb0T {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.style_currentTestimony__2hUpn {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    text-align: center;
}

.style_currentTestimonyMessage__3dl7x {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.style_currentTestimonyName__T7ubO {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 20px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_currentTestimonyUser__3tpOP {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 15px;
    margin-top: 20px;
}

.style_testimonyOneImage__34wAD {
    height: 92px;
    position: relative;
    top: -50px;
}

.style_testimonyTwoImage__yyCS6 {
    height: 124px;
    position: relative;
    left: -30px;
    top: 80px;
}

.style_ellipseOne__2wMlP {
    width: 10px;
    position: relative;
    top: 160px;
    left: -60px;
}

.style_testimonyThreeImage__3Yv5_ {
    height: 147px;
    position: relative;
    left: -30px;
    top: 120px;
}

.style_currentTestimonyImage__2KOSI {
    height: 200px;
}

.style_ellipseTwo__1zl1Z {
    position: relative;
    left: 30%;
    width: 15px;
    bottom: 375px;
}

.style_testimonyFourImage__2gU62 {
    height: 103px;
    position: relative;
    top: -120px;
}

.style_testimonyFiveImage__2yoMM {
    height: 109px;
    position: relative;
    top: 120px;
    right: -50px;
}

.style_ellipseThree__1NO4l {
    width: 15px;
    position: relative;
    top: 150px;
    right: 80px;
}

.style_testimonySixImage__2e4yq {
    height: 97px;
    position: relative;
    top: 150px;
    right: -30px;
}

@media only screen and (max-width: 680px) {

    .style_title__2kioI {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .style_ellipseTestimonies__3Tf4M {
        position: relative;
        left: -28%;
        width: 25px;
        bottom: 95px;
        z-index: -1;
    }

    .style_subtitle__2NAXs {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: Poppins;
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
    }

    .style_testimonies__2Sb0T {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 350px;
        width: 100%;
    }

    .style_currentTestimony__2hUpn {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        text-align: center;
        position: relative;
        top: -100%;
    }

    .style_currentTestimonyMessage__3dl7x {
        font-size: 12px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        position: relative;
        bottom: 20px;
    }

    .style_currentTestimonyName__T7ubO {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 20px;
    }

    .style_currentTestimonyUser__3tpOP {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
        margin-top: 0px;
    }

    .style_testimonyOneImage__34wAD {
        height: 102px;
        position: relative;
        top: 0px;
        left: -35%;
    }

    .style_testimonyTwoImage__yyCS6 {
        height: 104px;
        position: relative;
        left: -40px;
        top: 300px;
    }

    .style_ellipseOne__2wMlP {
        width: 10px;
        position: relative;
        top: 30px;
        left: -35%;
    }

    .style_testimonyThreeImage__3Yv5_ {
        height: 127px;
        position: relative;
        left: -35%;
        top: 120px;
    }

    .style_currentTestimonyImage__2KOSI {
        height: 120px;
        margin: 20px auto;
    }

    .style_ellipseTwo__1zl1Z {
        position: relative;
        left: 40%;
        width: 10px;
        bottom: 65px;
    }

    .style_testimonyFourImage__2gU62 {
        height: 113px;
        position: relative;
        top: -750px;
        left: 120px;
    }

    .style_testimonyFiveImage__2yoMM {
        height: 79px;
        position: relative;
        top: -780px;
        left: 150px;
    }

    .style_ellipseThree__1NO4l {
        width: 10px;
        position: relative;
        top: -820px;
        right: 70px;
    }

    .style_testimonySixImage__2e4yq {
        height: 97px;
        position: relative;
        top: -570px;
        left: 120px;
    }

}
.home_home__2cFvk {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 2%;
}

.home_home__row__3OApq {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
}

.home_title__1rd5P {
  text-transform: uppercase;
  font-size: var(--biggest-font-size);
  text-align: center;
  font-weight: 600;
}

.home___or__3Yhjf {
  text-align: center;
  font-size: 1.4em;
  font-family: var(--title-font);
  text-transform: uppercase;
}

.home___subtitle__3toHf {
  text-align: center;
  color: #535353;
  transition: all 0.3s ease-out;
  font-family: var(--title-font);
  font-size: 1.5em;
  text-transform: uppercase;
  background: #fafafa;
  border-radius: 15px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.2em 0.5em;
}

.home___subtitle__3toHf:hover {
  color: var(--first-color);
}

.typeProfile_title__3iQdz {
  margin-bottom: var(--mb-1);
}

.typeProfile_choice__profile__a6QKW {
  width: 50%;
  display: flex;
  justify-content: center;
  grid-gap: 2em;
  gap: 2em;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-2);
}

.typeProfile_choice__profile__a6QKW * a {
  display: block;
  text-align: center;
  color: var(--text-color);
  /* margin-top: var(--mb-2); */
}

.typeProfile_prof__1mE2b {
  width: 200px;
  height: 200px;
  border: 1px solid #70707054;
  border-radius: 100%;
}

.typeProfile_imageTypeProfile__niiw2 {
  width: 90px;
}

.typeProfile_imageTypeProfileParent__2HwhA {
  width: 70px;
  height: 70px;
}

.typeProfile_prof__1mE2b:hover {
  border: 1px solid var(--first-color);
}

@media only screen and (max-width: 767px) {
  .typeProfile_choice__profile__a6QKW {
    width: 100%;
    margin-top: var(--mb-2);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    grid-gap: 10px;
  }

  .typeProfile_prof__1mE2b {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .typeProfile_prof__1mE2b img {
    width: 100%;
    margin: auto;
  }

  .typeProfile_imageTypeProfile__niiw2 {
    width: 138px !important;
  }

  .typeProfile_imageTypeProfileParent__2HwhA {
    width: 150px !important;
  }
}
.CardQuiz_content__2JE4D {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 25px;
  padding-top: 10px;
  text-align: left;
}

.CardQuiz_button__S15hB {
  max-width: 150px;
  min-width: 150px;
  height: 30px !important;
  margin-top: 10px;
}

.CardQuiz_center__AcRNj h3 {
  width: 100%;
  max-width: 160px;
  font-family: var(--title-font);
  font-size: 1.3em;
  margin-top: var(--mb-2);
  text-transform: uppercase;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 50px;
  font-weight: 500;
  text-align: center;
}

/* .bottom {
  margin-left: var(--mb-6);
} */

.CardQuiz_bottom__31jqT .CardQuiz_team__3CkKR {
  font-style: italic;
}

.CardQuiz_number__2XTdg {
  position: absolute;
  display: flex;
  align-items: center;
  top: 1em;
  right: 30px;
  z-index: 1;
}

.CardQuiz_number__2XTdg span {
  font-weight: 500;
  margin-left: 0.3em;
  font-size: 1.2em;
}

.CardQuiz_level_quiz__1tBwy {
  margin-top: var(--mb-1);
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .CardQuiz_number__2XTdg {
    right: 75px;
  }
}
.style_headerComponents__2hvTI {
  width: 100%;
  margin: 0px auto;
}

.style___content__matiere__2uzVb {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 16px;
}

.style___content__matiere__2uzVb img {
  width: 40px;
}

@media only screen and (max-width: 500px) {
  .style___content__matiere__2uzVb {
    font-size: 14px;
  }

  .style___content__matiere__2uzVb img {
    width: 30px;
  }
}
.easyQuiz_easyQuiz__250rM {
  margin: 1em 0;
  min-height: 60vh;
}

.easyQuiz_header__Mbp0F {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  border-radius: 10px;
  margin: 10px 0;
  margin-bottom: 1em;
  padding: 0 2em;
}

.easyQuiz_center__2y3DX {
  text-align: right;
  width: 34%;
  font-size: 2em;
}

.easyQuiz_center__2y3DX h3 {
  color: var(--text-color);
}

.easyQuiz_select__2LA8i {
  width: 200px;
  height: 42px;
  border: 1px solid var(--text-color);
  padding-top: 4px;
  border-radius: 10px;
}


.easyQuiz___app__btn__mZNwh {
  position: relative;
  min-width: 370px;
  font-family: var(--title-font);
  font-size: 1em !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding-right: 10px !important;
  border-radius: unset !important;
}

.easyQuiz___content__quiz__1gU7t {
  position: relative;
  top: -2px;
  margin-left: 20px;
}

.easyQuiz___check__XFMhO {
  color: #fff;
  border-radius: 30px !important;
}

@media only screen and (max-width: 500px) {
  .easyQuiz_header__Mbp0F {
    padding: 0 0.5em;
  }

  .easyQuiz_select__2LA8i {
    width: 100px;
    height: 40px;
    padding-top: 2.5px;
  }

  .easyQuiz_left__1LxGJ span {
    font-size: 11px;
  }

}
.row_content__1cdVL {
  height: 100%;
  width: 100%;
}

.row___wrapper__3hoY9 {
  display: flex;
  flex-wrap: wrap;
  margin: -10px 0 0 -10px;
}

.row_grid__2PW1a {
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  .row_grid__2PW1a {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
    gap: 1em;
  }
}

.tabs___content__tabs__if_Al {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
}

.tabs_header__2KXxR {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: var(--mb-2);
  flex: 2 1;
}

.tabs_fullHeader__1e8HV {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.tabs_tabs__1OSr5 {
  width: 85px;
  height: 85px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs_fullTabs__yTFpT {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs_tabs__1OSr5:hover {
  background: var(--first-color);
}

.tabs_mobileTabs__T4xuB {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 0.3em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs_chapitre__content__1HyKG {
  width: 100%;
  background: #fafafa;
  padding: 3em;
  min-height: 80vh;
  margin-bottom: 5em;
}

.tabs_subtitle__1wxui {
  text-transform: uppercase;
  margin-bottom: var();
}

.tabs_row__3D7ij {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
  margin-top: 2em;
}

.tabs___links__3OxBB {
  color: var(--text-color);
}

.tabs___back__2npXc {
  width: 220px;
  text-align: right;
}

.tabs___back__2npXc span {
  font-family: var(--title-font);
  font-size: 1.1em;
  color: #333;
  text-transform: uppercase;
}

.tabs___back__2npXc span:hover {
  color: var(--first-color);
  cursor: pointer;
}

.tabs_tabButtons__3L3_r {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
}

.tabs_fullscreenButton__18Abt {
  width: 65px;
  margin-right: 12%;
}

.tabs_fullscreenButton__18Abt span {
  font-size: 12px;
  margin-left: 5px;
  margin-top: 2px;
}

@media only screen and (max-width: 500px) {
  .tabs___content__tabs__if_Al {
    flex-direction: column-reverse;
    width: 100%;
  }

  .tabs___back__2npXc {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {

  .tabs_header__2KXxR {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .tabs_fullscreenButton__18Abt {
    width: auto;
    margin-right: 0%;
    margin-bottom: 20px;
  }

  .tabs_fullscreenButton__18Abt span {
    font-size: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }

}

.tabs_mobileMenu__1s8tr {
  width: 105%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.tabs_mobileMenuFullscreen__1Vt6_ {
  width: 160%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .tabs_tabs__1OSr5 {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    margin: 0 0.9em;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
}
.customButton_customButtonStyle__jbWJH {
    height: auto;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: medium;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.customButton_customButtonStyle__jbWJH:hover {
    transform: scale(1.03) !important;
}

.customButton_customButtonStyle__jbWJH:active {
    transform: scale(0.99) !important;
}

@media only screen and (max-width: 680px) {
    .customButton_customButtonStyle__jbWJH {
        font-size: 12px;
        font-weight: 300;
    }
}
.room_header__VEmgI {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 8em 0 1em 0;
}

.room___btn__challenge__1O__W {
  position: relative;
  max-width: 350px;
  min-width: 350px;
}
.room___btn__challengeX__mF1wO {
  max-width: 350px;
  min-width: 350px;
  color: rgb(168 165 165) !important;
  background: #d5d5d5 !important;
  font-weight: 500 !important;
  font-size: 0.958rem !important;
  box-shadow: 3px 6px 1px -1px rgb(168 165 165) !important;
}
.room_main__1QrDd {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.room_center__1Aavd {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.room_left__3p3da .room_subtitle__16A2H {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.room_main__1QrDd {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.room_row__2-vN5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.room_main__left__3-b9c,
.room_main__right__2gsz_ {
  width: 100%;
  height: 100%;
}

.room_main__left__3-b9c {
  flex: 2 1;
}

.room_main__right__2gsz_ {
  flex: 0.8 1;
}

.room_main__row__3GNFB {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: var(--mb-2);
  gap: var(--mb-2);
}
.room_main__left__content__21l0f {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: var(--mb-6);
}

.room_graphe__right__IisC-,
.room_graphe__left__38Ufs {
  height: 400px;
  grid-gap: 20px;
  gap: 20px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.room_graphe__left__header__2aE8- {
  margin-left: var(--mb-2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.room___garphe__right__header__10RgL {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.4em;
  margin-top: 1.5em;
}

.room_select__2pDR5 {
  margin-top: 1.5em !important;
}

.room_custom__select__1Afci {
  margin-right: 10px;
}

.room_select__2pDR5 div {
  position: relative;
  height: 45px !important;
  width: 180px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.room_select__2pDR5 div span {
  padding-top: 7px !important;
  text-align: left;
}

.room___first__card__2QPFz,
.room___second__card__TKtNB {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background: #fafafa;
  border: 2px solid #dbdbdb;
}

.room___first__card__2QPFz h4 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: var(--text-color);
  margin-top: var(--mb-1);
}

.room___second__card__TKtNB {
  margin-top: var(--mb-3);
  height: 320px;
}

.room___second__card__TKtNB span {
  text-transform: uppercase;
  font-size: 1.1em;
}

.room___second__card__TKtNB .room_code__kMmVH {
  width: 200px;
  height: 50px;
  background: #fff;
  border: 1px dashed var(--text-color);
  display: flex;
  margin: var(--mb-4) auto;
  border-radius: 50px;
}

.room_code__kMmVH span {
  margin: auto;
  font-size: 1.6em;
  font-weight: 500;
}

.room___content__btn__1z6MK button:first-child {
  margin-bottom: var(--mb-2);
}

.room___btn__2agk9 {
  min-width: 200px !important;
  max-width: 200px !important;
  padding-top: 10px;
  height: 45px !important;
}

.room_array__conent__1jVdA {
  width: 100%;
  margin-top: var(--mb-4);
  max-height: 700px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-2) var(--mb-3);
}

/* Array */

.room_array__conent__header__2qHOy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--mb-2);
}

.room_array__conent__header__2qHOy * input::placeholder {
  color: var(--text-color);
}

.room___array__2WZ3c {
  width: 100% !important;
  max-height: 500px;
  margin-top: var(--mb-6);
  background: #fff;
}

/* Drawer */

.room_drawer__3bkax {
  position: relative;
  width: 100%;
  margin-top: var(--mb-6);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.room_drawer__content__343DN {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.room_drawer__content__343DN * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.room_field__text__czqRG,
.room_field__1iTET div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.room_field__1iTET div {
  padding-top: 10px !important;
}

.room_drawer___header__3f40r {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.room_drawer___header__3f40r h3 {
  font-family: var(--title-font);
  font-size: 3.2em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
}

.room___first_back__1GlWp {
  position: absolute;
  top: 6%;
  left: 5%;
  cursor: pointer;
}

.room_filter__23fjM .room_select__2pDR5 div {
  height: 45px !important;
  width: 250px !important;
}

.room___row__3k0cg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-4);
  grid-gap: 15px;
  gap: 15px;
}

.room_faq__2daJj {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5em;
  color: #707070;
}

.room_content__2S5hs {
  margin-bottom: var(--mb-6);
}

.room___overflow__Y__3Oxdz {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
}
.room_tableHeader__2H-pF {
  color: var(--first-color);
}
.room_filed__search__LQ9tQ {
  width: 300px;
  height: 45px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
  margin-top: 1.4em;
  overflow: hidden;
}

/*  */

.room_inputSearchContent__36ij1 * input::placeholder {
  color: var(--text-color);
}

.room_elt__1ot0s {
  margin-bottom: 50px !important;
}
.room_greenBackground__2LtLW {
  width: 350px;
  height: 80px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  top: 0;
  flex-direction: column;
  transform: translateY(-80%);
  justify-content: flex-start;
  padding-top: 18px;
  background-color: #80b1b2;
  color: white;
  font-family: "CartoonFont";
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transform: translateY(-80%);
  -moz-transform: translateY(-80%);
  -ms-transform: translateY(-80%);
  -o-transform: translateY(-80%);
}
.room_myArrangement__3LHJc {
  display: flex;
  align-items: center;
}

.style_grayBackground__3vpEf {
  width: 313px;
  height: 45px;
  background-color: #d5d5d5;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;

  justify-content: center;
  position: relative;
  transform: translateY(50%);
  z-index: 2;
}
.style_greenBackground__1JbXC {
  width: 313px;
  height: 120px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  flex-direction: column;
  transform: translateY(-50%);
  justify-content: flex-start;
  padding-top: 15px;
  background-color: #80b1b2;
  color: white;
  font-family: "CartoonFont";
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.style_myArrangement__2-042 {
  display: flex;
  align-items: center;
}
.style_subscribedStudents__ipomA {
  margin-right: 1em;
}
.style_bodyDropDow__2Pns2 {
  top: 20px;
  left: 238px;
  width: 365px;
  height: 280px;
  background: white;
  overflow-y: scroll;
  border: 1px solid #707070;
  border-radius: 5px 5px 10px 10px !important;
  z-index: 1 !important;
}
.style_bodyDropDow__subscribedStudents__38urO {
  width: 80%;
  height: 110px;
  background: white;
  overflow-y: scroll;
  border-radius: 5px 5px 10px 10px !important;
  z-index: 1 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0;
  right: 0;
}
.style_absolute__2N2kH {
  position: absolute !important;
  z-index: 0 !important;
}

thead[class*="ant-table-thead"] th {
    color: var(--first-color) !important;
}

@media only screen and (max-width: 767px) {
    thead[class*="ant-table-thead"] th {
        color: var(--first-color) !important;
        font-size: 0.75em;
    }
}
.calendar_calendrier__1LgQK {
  height: 600px;
  width: 100%;
  margin: 3em 0;
  border: 1px solid #ccc;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: #FAFAFA;
}

.calendar_calendar__content__3vKfz {
  width: 100%;
}

.calendar_profile__3AxUq {
  position: relative;
  width: 115px;
  height: 115px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  top: -3em;
}

.calendar___avatar__3wShd {
  width: 110px;
  height: 110px;
  border-radius: 100px;
  margin: auto;
}

.calendar___drawer_header__NjELS {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calendar___drawer_header__NjELS h3 {
  font-size: 3.5em;
  font-family: var(--title-font);
  color: var(--first-color);
}

.calendar_name__1DUvo {
  position: relative;
  color: var(--text-color);
  font-size: 1.7em;
  text-transform: uppercase;
  top: -1em;
}

.calendar___drawer__card__Lycxb {
  width: 65%;
  height: 400px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  margin-top: 6em;
}

.calendar_card__content__3EAi7 {
  text-align: center;
  margin-top: 1em;
  font-size: 16px;
}

.calendar_countDown__2seXt {
  font-size: 2em;
  color: var(--first-color);
  text-transform: uppercase;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

.calendar___button__2DaV2 {
  min-width: 300px;
}

.calendar___button__2DaV2 svg {
  position: relative;
  top: 2px;
  left: -10px;
}

/*  */

.calendar_calendar__SZnh5 {
  width: 100%;
  overflow: hidden;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #fafafa;
}

.calendar_weekly__2nPsS {
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 2% 4%;
  margin-bottom: 2em;
  border: 1px solid #ccc;
}

.calendar_date__1hEsv {
  width: 100%;
  min-height: 35px;
  border-radius: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  padding-top: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.calendar_date__1hEsv:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.calendar_date__1hEsv.calendar_active__1FcbO {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.calendar_creneaux__3W3Ys {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.calendar_creneau__3B99X {
  margin-bottom: 0.5em;
}

.calendar_range__3UjPy {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.calendar_range__3UjPy span {
  margin: auto;
}

.calendar_matiere__1zKGu {
  font-size: 1em;
}

.calendar_bar__1xufW {
  margin: 0 0.5em;
}

.calendar_angleIcon__1iUAp path {
  fill: #fe772e;
}

.calendar_slider__1_kKy>svg path {
  fill: #fe772e;
}

.calendar_card__2DmV_ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .calendar_content__action__reservation__gB3r8 {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.calendar_myReservation__20OxO {
  margin-bottom: 1em;
}

.calendar_card___CMTHj {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}

.calendar_button__2li8u {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 32px !important;
  color: #fff !important;
  text-transform: uppercase;
  border: unset !important;
  border-radius: 50px !important;
  background: var(--first-color) !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.calendar_button__2li8u:hover {
  transform: scale(1.03) !important;
}

.calendar_button__2li8u:active {
  transform: scale(0.99) !important;
}

@media only screen and (max-width: 390px) {
  .calendar_button__2li8u {
    min-width: 150px;
    height: 28px !important;
  }
}

/* New Css Styles */

.calendar_mainBox__2XyYF {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 480px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_headerBox__2mO1P {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendar_titleBox__2zm7s {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.calendar_titlePictureBox__jX05O {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #E4F1F2;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_buttonBox__3Cf_G {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.calendar_todayBox__mNQ89 {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_classeCard__14Wtd {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 20px 10px 20px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendar_classeCardDeleted__RPa9m {
  position: relative;
  width: 100%;
  height: 70px;
  background: #EBEBEB;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.calendar_classeCardCoupon__2I-ol {
  position: relative;
  width: 100%;
  height: 70px;
  background: #E7F8F8;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.calendar_cancelBox__3I6rH {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
  text-align: center;
}

.calendar_cardClassBox__2oE3O {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.calendar_cardClassBoxCoupon__2ML4K {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 10px;
  padding-right: 0px;
}

.calendar_historyClasseCard__gRgvN {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 10px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
}

.calendar_dateBox__jVD63 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.calendar_horizontalLine__1Kq15 {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.calendar_avatarNameBox__3Scq0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 25%;
  object-fit: contain;
}

.calendar_detailsBox__3x9Bj {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.calendar_historyDetailsBox__1ZfZk {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
  margin-right: 0px;
}

.calendar_timeBox__3091R {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
  margin-right: 10px;
  min-width: 25%;
}

.calendar_times__3BDgv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.calendar_time__1gwPg {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.calendar_timeDescription__1PNYq {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.calendar_amountBox__1f7DD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  width: auto !important;
}

.calendar_priceBox__aVB_K {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  font-size: 12px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
  display: block;
}

.calendar_historyPriceBox__2KaFB {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.calendar_newButton__1RLdz {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 30px;
  padding-right: 30px;
}

.calendar_meetButton__1-WEh {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_scheduleButton__1Jpw_ {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #80b1b2 !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: 1px solid #80b1b2 !important;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_cancelButton__CpoUj {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #ea4f44 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #e62214 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #e62214;
  -moz-box-shadow: 5px 7px 1px -1px #e62214;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_moreOptionsButton__3onsI {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #000 !important;
  margin-left: 0px;
  border: 1px solid #FFF !important;
}

.calendar_meetButton__1-WEh:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.calendar_scheduleButton__1Jpw_:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.calendar_cancelButton__CpoUj:hover {
  transform: scale(1.03) !important;
  background-color: #ea4f44;
  color: #fff;
}

.calendar_emptyClass__Xp4VB {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
}

.calendar_historyCalendar__3KEYo {
  height: 650px;
  width: 95%;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #FFF;
  margin: 0px auto;
}

.calendar_horizontalLineDeleted__d8WxP {
  height: 1px;
  width: 85%;
  background: #393E3E;
}

.calendar_rateBoxDetails__O3cFN {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 5px;
  gap: 5px;
  width: 35%;
  margin-left: 20px;
}

.calendar_dashedBox__3Jccu {
  font-size: 1em;
  display: flex;
  flex-direction: row;
  font-weight: 600;
}

.calendar_rateBoxSpan__13D__ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  margin-left: 5px;
  color: #707070;
}

.calendar_rateBoxStar__29iKk {
  position: relative;
  top: -1px;
  margin-left: 5px;
  margin-right: 5px;
}

.calendar_studentDetailsDrawer__3GDsQ {
  width: 90%;
  background: #FFF;
  box-shadow: 4px 9px 18px #FFF;
  border: 1px solid #DFDDDD;
  border-radius: 20px;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin: 40px auto;
  padding: 20px;
}

.calendar_drawerTimeBox__1FrKR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1em;
  width: 65%;
}

@media only screen and (max-width: 1050px) {
  .calendar_drawerTimeBox__1FrKR {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    font-size: 0.8em;
    width: 100%;
  }

  .calendar_studentDetailsDrawer__3GDsQ {
    width: 100%;
    background: none;
    box-shadow: 0px 9px 18px #FFF;
    border: 0px solid #DFDDDD;
    border-radius: 0px;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin: 0px auto;
    padding: 0px;
  }

  .calendar_rateBoxDetails__O3cFN {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    width: auto;
    margin-left: 0px;
  }

  .calendar_dashedBox__3Jccu {
    font-size: 0.75em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    font-weight: 600;
    width: 100%;
  }

  .calendar_headerBox__2mO1P {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .calendar_titleBox__2zm7s {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .calendar_titlePictureBox__jX05O {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar_buttonBox__3Cf_G {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .calendar_calendrier__1LgQK {
    width: 100%;
    margin: 2em 0;
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 16px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 60vh !important;
  }

  .calendar_newButton__1RLdz {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .calendar_calendar__SZnh5 {
    height: 60vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 16px !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FAFAFA;
  }

  .calendar_historyCalendar__3KEYo {
    height: 73vh !important;
    width: 95% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FFF;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 16px !important;
  }

  .calendar_dateBox__jVD63 {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .calendar_horizontalLine__1Kq15 {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .calendar_todayBox__mNQ89 {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .calendar_emptyClass__Xp4VB {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    font-family: Rubik;
    color: #B9B9B9;
    text-align: center;
  }

  .calendar_cardDisplay__1AU6g {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    gap: 5px;
  }

  .calendar_singleCourse__GOhJq {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
  }

  .calendar_classeCard__14Wtd {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .calendar_historyClasseCard__gRgvN {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
  }

  .calendar_cardClassBox__2oE3O {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .calendar_cardClassBoxCoupon__2ML4K {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .calendar_classeCardDeleted__RPa9m {
    position: relative;
    width: 100%;
    height: 220px;
    background: #EBEBEB;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .calendar_classeCardCoupon__2I-ol {
    position: relative;
    width: 100%;
    height: 220px;
    background: #E7F8F8;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .calendar_cancelBox__3I6rH {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
    position: absolute;
    top: 40px;
    opacity: 1;
    text-align: center;
  }

  .calendar_avatarNameBox__3Scq0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 90%;
    object-fit: contain;
  }

  .calendar_detailsBox__3x9Bj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .calendar_historyDetailsBox__1ZfZk {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .calendar_timeBox__3091R {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    width: auto !important;
  }

  .calendar_times__3BDgv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .calendar_time__1gwPg {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .calendar_timeDescription__1PNYq {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .calendar_amountBox__1f7DD {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    font-size: 0.7em;
    margin-top: 5px;
    width: auto !important;
  }

  .calendar_priceBox__aVB_K {
    width: auto !important;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .calendar_historyPriceBox__2KaFB {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .calendar_meetButton__1-WEh {
    width: auto !important;
    height: 25px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .calendar_moreOptionsButton__3onsI {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #000 !important;
    margin-left: 0px;
    border: 1px solid #FFF !important;
    margin-top: 10px;
  }

  .calendar_meetButton__1-WEh:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .calendar_scheduleButton__1Jpw_ {
    width: auto !important;
    height: 35px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #80b1b2 !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: 1px solid #80b1b2 !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .calendar_cancelButton__CpoUj {
    width: auto !important;
    height: 30px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #ea4f44 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #e62214 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #e62214;
    -moz-box-shadow: 5px 7px 1px -1px #e62214;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .calendar_horizontalLineDeleted__d8WxP {
    height: 150px;
    width: 1px;
    background: #393E3E;
  }
}
.grid_grid__56xNZ {
  display: grid;
  grid-row-gap: 0.8rem;
  row-gap: 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

}

@media only screen and (min-width: 768px) {
  .grid_grid__56xNZ {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.3em;
    gap: 1.3em;
  }
}
.cardChapter_cardChapter__33XBW {
  width: 100%;
  /* max-width: 450px; */
  /* margin-bottom: 1em; */
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
  padding: 1em 1.5em;
  transition: 0.3s;
  /* Chapitre 1 */
}

.cardChapter_title__chapter__36beN span {
  color: #CBCACD;
}
.chapitre_header__32Vyg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.chapitre_chapitre__content__2Tx2o {
  width: 100%;
  background: #fafafa;
  padding: 3em;
}

.chapitre_subtitle__g2Srb {
  text-transform: uppercase;
  position: relative;
  top: 2px;
}

.chapitre___title__3oi3F {
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-4);
}

.chapitre_card__content__3k6cf {
  margin-top: 2em;
}

.chapitre___links__eCMMk {
  color: var(--text-color);
}

.chapitre___content__level__matier__3ArwJ img {
  width: 80px;
}

.chapitre___content__level__matier__3ArwJ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--mb-3);
  font-family: var(--title-font);
  font-size: 1.7em;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 500;
}

.chapitre_left__1pAtd,
.chapitre___back__level__1nYrE {
  flex: 1 1;
}

.chapitre_content__left__1A9Ij {
  flex: 2 1;
}

.chapitre___matier__bottom__1aeCx {
  color: #333333;
}

.chapitre_content__left__1A9Ij {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chapitre___back__level__1nYrE {
  font-size: 15px;
  color: #333333;
  text-align: right;
}

.chapitre___back__level__1nYrE:hover {
  color: var(--first-color);
  cursor: pointer;
}

.chapitre_chapterDocsBox__3snie {
  align-items: center;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.chapitre_docsBox__1Zmfq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chapitre_docsButton__u-88V {
  width: 30px;
  font-size: 18px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .chapitre_chapterDocsBox__3snie {
    align-items: center;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .chapitre_docsBox__1Zmfq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .chapitre_docsButton__u-88V {
    margin-left: 0px;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 500px) {
  .chapitre_chapitre__content__2Tx2o {
    padding: 1em;
    min-height: initial !important;
  }

  .chapitre_center__2P82A {
    display: none;
  }

  .chapitre___content__level__matier__3ArwJ {
    width: 100%;
    align-items: flex-end;
    margin-bottom: var(--mb-2);
    font-size: 1em;
    line-height: 20px;
  }

  .chapitre___back__level__1nYrE {
    position: relative;
    top: -9px;
    font-size: 13px;
  }

  .chapitre___content__level__matier__3ArwJ img {
    width: 55px;
  }
}

.quiz_quiz__2qX51 {
  margin: 1em 0;
}

.quiz_quiz__content__1FaYa {
  width: 100%;
  background: #fafafa;
  padding: 3% 5% 7% 5%;
}

.quiz_left__OtTpU .quiz___avatarBoy__3EnFV {
  width: 100px;
}

.quiz_right__2qkar .quiz___iconTrophy__3KRNJ {
  width: 70px;
}

.quiz_center__2Ks2Q img {
  width: 35px;
}

.quiz_header__1PHEE {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.quiz_center__2Ks2Q {
  flex: 2 1;
  padding: 0 1%;
}

.quiz_center__2Ks2Q span {
  font-size: 1em;
}

.quiz___title__2C2X9 {
  width: 100%;
  max-width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.quiz_main__1wUPx {
  margin-top: 2em;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.quiz_main__1wUPx h3 {
  font-size: 1.2em;
  margin-left: 2%;
  color: var(--text-color);
  margin-bottom: var(--mb-3);
  /* #CBCACD */
}

.quiz_card__8yXNa {
  position: relative;
  width: 95%;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #cbcacd;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-3);
  padding: 0 3%;
}

.quiz_card__8yXNa input[type="radio"] + label {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  user-select: none;
}

.quiz_card__8yXNa input[type="radio"] + label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 20px;
  border-bottom: 5px solid var(--text-color);
  border-left: 5px solid var(--text-color);
  top: 25%;
  left: 45%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

.quiz_card__8yXNa input[type="radio"]:checked + label {
  border: 1px solid var(--text-color);
}

.quiz_card__8yXNa input[type="radio"]:checked + label:after {
  opacity: 1;
  width: 35px;
}

.quiz_chk__SOQfW {
  display: none;
}

.quiz___btn__back__eIXvg {
  max-width: 80px !important;
  min-width: 100px !important;
  background: #f64d5c !important;
  box-shadow: 3px 6px 1px -1px #f64d5bab !important;
}

.quiz____result__btn__3z1gF {
  border-radius: 15px;
  height: 40px;
}

/*  */
.quiz_show__counter__5T0DL {
  display: flex;
  min-width: 200px;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 1.2em;
}

.quiz_danger__2qeq- {
  color: red;
}

.quiz_expired__f8DYr {
  position: relative;
  color: red;
  text-align: center;
  font-size: 1.4em;
  left: -0.8em;
  animation: quiz_clignote__Jn12j 1.7s linear infinite;
}

@keyframes quiz_clignote__Jn12j {
  50% {
    opacity: 0;
  }
}

.quiz___sound__1cIxW {
  position: absolute;
  left: -100%;
  visibility: hidden;
  width: 100%;
}

.quiz___drawer__button__36QKx {
  min-width: 350px;
  height: 45px !important;
}

.quiz___result__title__-1hKV {
  text-align: center;
  margin-top: var(--mb-1);
}

/*  */

.quiz_proposition__1D1yN {
  margin-left: 10px;
}

.quiz_cb__2sW3L {
  border: 1px solid #555;
  display: inline-block;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.quiz_cb__2sW3L:before,
.quiz_cb__2sW3L:after {
  content: "";
  display: block;
  position: absolute;
}

.quiz_cb__2sW3L:before {
  animation: quiz_eraseA__2PuG3 0.5s steps(1) forwards;
  background: url("https://s22.postimg.cc/fi4blx4gx/checkmark.png") 0 100%/1.2em
    1.35em no-repeat;
  bottom: 0.2em;
  left: 0.3em;
  width: 0;
  height: 1.35em;
}

.quiz_cb__2sW3L:after {
  animation: quiz_eraseB__3E2MD 0.5s linear forwards;
  background: linear-gradient(
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 50%
      )
      11.2em 0,
    linear-gradient(#c8646e, #c8646e) 11.2em 0,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      9.8em 0/0.3em 100%,
    repeating-linear-gradient(
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em
      )
      10.2em 0/0.5em 100%,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      10.8em 0/0.3em 100%,
    linear-gradient(
        rgba(255, 255, 255, 0) 35%,
        rgba(255, 255, 255, 0.6) 35%,
        rgba(255, 255, 255, 0.6) 65%,
        rgba(255, 255, 255, 0) 65%
      )
      9.7em 0,
    linear-gradient(#b4b4b4, #b4b4b4) 9.7em 0,
    linear-gradient(
        rgba(0, 0, 0, 0.1) 27%,
        rgba(0, 0, 0, 0) 27%,
        rgba(0, 0, 0, 0) 73%,
        rgba(0, 0, 0, 0.2) 73%
      )
      2em 0,
    linear-gradient(#f0d000, #f0d000) 2em 0,
    linear-gradient(-14deg, #1e1e1e 18%, rgba(30, 30, 30, 0) 20%) 0 0/0.5em
      0.5em,
    linear-gradient(14deg, rgba(30, 30, 30, 0) 80%, #1e1e1e 83%) 0 0.5em/0.5em
      0.5em,
    linear-gradient(-14deg, #d2a078 48%, rgba(210, 160, 120, 0) 50%) 0 0/2em
      0.5em,
    linear-gradient(14deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.1) 50%) 0 0.5em/2em
      0.5em,
    linear-gradient(14deg, rgba(210, 160, 120, 0) 48%, #d2a078 50%) 0 0.5em/2em
      0.5em;
  background-repeat: no-repeat;
  border-radius: 0 0.1em 0.1em 0;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0 0.25em;
  width: 12em;
  height: 1em;
}

.quiz_cb__2sW3L:checked {
  background-color: transparent;
}

.quiz_cb__2sW3L:checked:before {
  animation: quiz_drawA__1td2u 0.5s linear forwards;
}

.quiz_cb__2sW3L:checked:after {
  animation: quiz_drawB__3Whvs 0.5s linear;
}

.quiz_cb__2sW3L:focus {
  outline: transparent;
}

.quiz_cb__2sW3L:focus + span {
  color: rgba(0, 0, 0, 0.75);
  outline: transparent;
}

.quiz_pristine__gxB1W:before,
.quiz_pristine__gxB1W:after {
  animation: none;
}

@keyframes quiz_drawA__1td2u {
  from,
  5% {
    width: 0;
    height: 1.35em;
  }

  90%,
  to {
    width: 1.2em;
    height: 1.35em;
  }
}

@keyframes quiz_drawB__3Whvs {
  from {
    opacity: 0;
    transform: translate(0em, -0.5em) rotate(-50deg);
    visibility: visible;
  }

  5% {
    opacity: 1;
    transform: translate(0em, 0.3em) rotate(-60deg);
  }

  17% {
    transform: translate(0.25em, 0.8em) rotate(-65deg);
  }

  79% {
    transform: translate(1.1em, -0.14em) rotate(-63deg);
  }

  90% {
    opacity: 1;
    transform: translate(1.2em, -0.15em) rotate(-63deg);
  }

  to {
    opacity: 0;
    transform: translate(1.25em, -1.7em) rotate(-63deg);
  }
}

@keyframes quiz_eraseA__2PuG3 {
  from {
    width: 1.2em;
    height: 1.35em;
  }

  10% {
    width: 1.2em;
    height: 1.08em;
  }

  27% {
    width: 1.2em;
    height: 0.81em;
  }

  36% {
    width: 1.2em;
    height: 0.7em;
  }

  45% {
    width: 1.2em;
    height: 0.55em;
  }

  55% {
    width: 1.2em;
    height: 0.35em;
  }

  80%,
  to {
    width: 1.2em;
    height: 0;
  }
}

@keyframes quiz_eraseB__3E2MD {
  from {
    opacity: 0;
    transform: translate(0.6em, -12.2em) rotate(90deg);
    visibility: visible;
  }

  10% {
    opacity: 1;
    transform: translate(1.2em, -12.05em) rotate(90deg);
  }

  20% {
    transform: translate(0.6em, -11.9em) rotate(90deg);
  }

  30% {
    transform: translate(1.2em, -11.75em) rotate(90deg);
  }

  40% {
    transform: translate(0.6em, -11.6em) rotate(90deg);
  }

  50% {
    transform: translate(1.2em, -11.45em) rotate(90deg);
  }

  60% {
    transform: translate(0.6em, -11.3em) rotate(90deg);
  }

  70% {
    transform: translate(1.2em, -11.15em) rotate(90deg);
  }

  80% {
    transform: translate(0.6em, -11em) rotate(90deg);
  }

  90% {
    opacity: 1;
    transform: translate(2em, -12.5em) rotate(100deg);
  }

  to {
    opacity: 0;
    transform: translate(2em, -12.5em) rotate(100deg);
  }
}

@media only screen and (max-width: 500px) {
  .quiz_quiz__content__1FaYa {
    padding: 3% 2%;
  }

  .quiz_left__OtTpU .quiz___avatarBoy__3EnFV {
    position: relative;
    width: 50px;
    top: -2px !important;
  }

  .quiz_right__2qkar .quiz___iconTrophy__3KRNJ {
    position: relative;
    width: 30px;
    top: -7px !important;
  }

  .quiz_show__counter__5T0DL {
    font-size: 1em;
  }

  .quiz_center__2Ks2Q img {
    width: 20px;
  }

  .quiz_main__1wUPx h3 {
    font-size: 1.1em;
    margin-left: 2%;
    color: var(--text-color);
  }

  .quiz_proposition__1D1yN {
    font-size: 12px;
  }

  .quiz_count__down__zlWkK {
    position: relative;
    left: -1.1em;
  }
}

.cardResult_body__2tHiU {
  width: 80%;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.cardResult_cardResult__3P1UE {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 0 1em;
  border: 1px solid #333333;
}

.cardResult_cardResponse__1fMva {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  background-color: #e1ede0;
  border-radius: 5px;
  padding: 0 1em;
  border: 0px solid #333333;
  margin-top: 10px;
  font-size: 16px;
  color: #333333;
  font-weight: 400;
}

.cardResult_body__2tHiU span {
  font-size: 18px;
}

.cardResult_body__2tHiU .cardResult_question__1e6c3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.cardResult_isCorrect__dy_-J {
  border: 1px solid #449F01;
}

.cardResult_isNoCorrect__1ndI4 {
  border: 1px solid #FF0000;
}
.cardTracked_cardTracked__2Gqt2 {
  width: 100%;
  /* max-width: 380px; */
  display: flex;
  line-height: 20px;
  align-items: center;
  margin-bottom: var(--mb-4);
}

.cardTracked_right__1WqlG .cardTracked_top__2YdmY {
  margin-bottom: 0em;
}

.cardTracked_right__1WqlG .cardTracked_top__2YdmY span {
  font-size: 1.5em;
  font-weight: 500;
}

.cardTracked_left__1kT9M {
  margin-right: 1em;
}

@media only screen and (max-width: 767px) {
  .cardTracked_cardTracked__2Gqt2 {
    width: 100%;
    max-width: 100%;
    margin-bottom: var(--mb-2);
    padding: 10px;
  }

  .cardTracked_cardTracked__2Gqt2 img {
    width: 45px;
  }

  .cardTracked_left__1kT9M {
    margin-right: 0;
  }

  .cardTracked_right__1WqlG .cardTracked_top__2YdmY {
    margin-bottom: 0em;
  }

  .cardTracked_right__1WqlG .cardTracked_top__2YdmY span {
    font-size: 1.1em;
  }
}
.dashboard_title__1D01i {
  font-size: 2em;
}

.dashboard_header__3IxT3 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.dashboard_main__1E1mZ {
  position: relative;
  max-width: 800px;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-1);
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dashboard_help__2tcSv {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 5%;
  top: 60%;
  width: 200px;
  height: 60px;
  background: #707070;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
}

.dashboard_help__2tcSv h4,
.dashboard_help__2tcSv p {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #fff;
}

.dashboard___help__images__ih5zs {
  margin-top: 5px;
}

/* .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
   flex-wrap: wrap;
  background: #000;
} */

.dashboard_row__1ezLh {
  display: grid;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  .dashboard_row__1ezLh {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    gap: 1em;
  }
}

.dashboard___skillbar__1Q0kq {
  min-width: 200px;
}

.dashboard___links__2l3kZ {
  color: #707070;
}

.dashboard___select__VJDqV {
  width: 500px;
}

.dashboard___select__VJDqV div {
  position: relative;
  text-align: center;
  font-size: 2.7em;
  line-height: 0 !important;
  font-family: var(--title-font);
  text-transform: uppercase;
  border: 1px solid black !important;
}

.dashboard___select__VJDqV div span :focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

@media only screen and (max-width: 500px) {
  .dashboard___select__VJDqV div {
    width: 300px !important;
    font-size: 1.8em;
  }

  .dashboard_iconArrowClass__20OZu {
    width: 20px;
    font-size: 12px !important;
    top: -1px !important;
    right: 20px !important;
  }

  .dashboard_header__3IxT3 {
    margin-top: var(--mb-1);
  }

  .dashboard_row__1ezLh {
    justify-content: center;
  }
}
.exercice_exercice__2HrsX {
  margin-bottom: 4em;
  height: 100%;
}

.exercice_title__6WuqZ {
  width: 100%;
  margin: 0.5em auto 1em auto;
  max-width: 370px;
  text-align: center;
}

.exercice_main__ocUEA {
  width: 100%;
  border-radius: 10px;
}

.exercice___ennnoncer__7hddN {
  margin-bottom: 1em;
}

.exercice___icon_drawer__2ULSH {
  position: absolute;
  top: 5%;
  right: 5%;
}

.exercice_title__drawer__1q0UY {
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.3em;
  text-transform: uppercase;
}

.exercice_letter__4n15z {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 90vh;
  padding: 5em;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em !important;
}

.exercice_letter__4n15z:before,
.exercice_letter__4n15z:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.exercice_letter__4n15z:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}

.exercice_letter__4n15z:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.exercice___button__indice__2AJ6W {
  margin-right: 1em;
}
.level_level__1JP2L {
  width: 100%;
  background: #FAFAFA;
  margin-bottom: 5em;
}

.level_header__2aXqh {
  margin-top: 2em;
  margin-bottom: 2em;
}

.level_level__content__17UIS {
  padding: 3em;
}

.level_title__content__2Lv0j h2 {
  text-align: center;
  font-size: 1.7em;
  color: var(--text-color);
}

.level_row__2dcRq {
  margin-top: 3em;
}

.level_card__8-MlF {
  width: 100%;
  max-width: 330px;
  height: 290px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition: all 0.3s;
  cursor: pointer;
  background: #fff;
}

.level_card__8-MlF h3 span {
  font-family: "CartoonFont";
  display: block;
  font-size: 2em;
  line-height: 50px;
  font-weight: 500;
  color: #CBCBCB;
  text-transform: uppercase;
}

.level_card__8-MlF:hover {
  border: 4px solid var(--first-color);
}
.myClasses_header__dQFge {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.myClasses_center__19DAx {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.myClasses_left__d9G59 .myClasses_subtitle__3vKmA {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.myClasses_main__34rKN {
  width: 100%;
  height: 100%;
  margin-top: 3em;
}

.myClasses_row__3OO35 {
  width: 100%;
  display: flex;
  grid-gap: 0.5em;
  gap: 0.5em;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 4em;
}

.myClasses___drawer__body__3IV0X p {
  margin-bottom: 10px;
}

.myClasses___drawer__body__3IV0X {
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.myClasses___join__class__fi37j {
  position: relative;
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #707070;
}

.myClasses___join__class__fi37j input {
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
}

.myClasses___join__class__fi37j input::placeholder {
  font-style: italic;
}

.myClasses___drawer__btn__a6x1X {
  margin-top: 2em;
  max-width: 300px;
}

.myClasses___close__3dYK4 {
  position: absolute;
  left: 5%;
  top: 3%;
  cursor: pointer;
}

/* .__key {
  position: absolute;
  top: -18px;
} */

.myClasses___error__3CL8G {
  position: absolute;
  top: 43px;
  color: #ff0000;
}

.myClasses___result__3fAz7 {
  text-align: center;
}

.myClasses___result__3fAz7 h3 {
  font-size: var(--h2-font-size);
  color: var(--text-color);
}

@media only screen and (max-width: 500px) {
  .myClasses___join__class__fi37j {
    width: 250px;
  }

  .myClasses_right__3NZFC {
    display: none;
  }
}

/* New Css Styles */

.myClasses_tabTitleBox__1w4HP {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.myClasses_tabTitleBoxActive__1vmfb {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.myClasses_informationBox__3eD-_ {
  width: 100%;
  height: 480px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  overflow-y: scroll;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  background: #FFF;
}

.myClasses_classesBox__1wYUX {
  width: 100%;
  height: 640px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #FFF;
}

.myClasses_classeCard__V5S9h {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.myClasses_avatarNameBox__3NfVM {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 300px;
  object-fit: contain;
}

.myClasses_detailsBox__rQ5zg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.myClasses_timeBox__1h_SZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 30px;
}

.myClasses_times__DnNwK {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.myClasses_time__2qiIV {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.myClasses_timeDescription__3KIeo {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.myClasses_amountBox__is4La {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  color: #707070;
}

.myClasses_priceBox__b2g9_ {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
}

.myClasses_meetButton__3OnXF {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.myClasses_newButton__3KNxK {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4bb980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
  -moz-box-shadow: 5px 7px 1px -1px #4bb980;
  padding-left: 20px;
  padding-right: 20px;
}

.myClasses_newButton__3KNxK:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.myClasses_meetButton__3OnXF:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.myClasses_emptyClass__2LlzT {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.myClasses_dateBox__12LF8 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.myClasses_horizontalLine__3tApW {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.myClasses_todayBox__297Gq {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myClasses_newClassBox__2laCG {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.myClasses_cardDisplay__3BO0w {
  display: flex;
  flex-direction: column;
}

.myClasses_cardClassBox__1PuMD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.myClasses_joinClassButtonBox__2LwlI {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
}

@media only screen and (max-width: 1050px) {
  .myClasses_headerBox__3_WWD {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .myClasses_titleBox__3f5mg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .myClasses_titlePictureBox__297r7 {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .myClasses_buttonBox__Ln92d {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .myClasses_newButton__3KNxK {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4bb980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
    -moz-box-shadow: 5px 7px 1px -1px #4bb980;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .myClasses_informationBox__3eD-_ {
    height: 73vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FFF;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 16px !important;
  }

  .myClasses_classesBox__1wYUX {
    width: 100%;
    height: 50vh;
    border: 1px solid #BFBCBC;
    border-radius: 18px;
    opacity: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #FFF;
  }

  .myClasses_dateBox__12LF8 {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .myClasses_horizontalLine__3tApW {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .myClasses_todayBox__297Gq {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .myClasses_emptyClass__2LlzT {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    font-family: Rubik;
    color: #B9B9B9;
    text-align: center;
  }

  .myClasses_cardDisplay__3BO0w {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    gap: 5px;
  }

  .myClasses_singleCourse__4VnID {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
  }

  .myClasses_classeCard__V5S9h {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .myClasses_historyClasseCard__vEivt {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
  }

  .myClasses_cardClassBox__1PuMD {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .myClasses_avatarNameBox__3NfVM {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 100%;
    object-fit: contain;
  }

  .myClasses_detailsBox__rQ5zg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .myClasses_historyDetailsBox__3n1kI {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .myClasses_timeBox__1h_SZ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
  }

  .myClasses_times__DnNwK {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .myClasses_time__2qiIV {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .myClasses_timeDescription__3KIeo {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .myClasses_amountBox__is4La {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    font-size: 0.7em;
    margin-top: 5px;
  }

  .myClasses_priceBox__b2g9_ {
    width: auto !important;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .myClasses_historyPriceBox__vgdas {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .myClasses_meetButton__3OnXF {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .myClasses_meetButton__3OnXF:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .myClasses___first_drawer__QvPBk {
    position: relative;
    width: 100%;
    padding: 0px;
  }

  .myClasses_tabTitleBox__1w4HP {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }

  .myClasses_tabTitleBoxActive__1vmfb {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }
}
.cardTeacher_content__2ryyu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: var(--mb-1);
  padding-bottom: var(--mb-6);
}

.cardTeacher___avatar__1ttDA {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.cardTeacher___top__Ha49s>div span {
  color: var(--text-color);
}

.cardTeacher___top__Ha49s h3 {
  width: 100%;
  max-width: 150px;
  text-align: center;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}


/* Select */
.cardTeacher_select__3ffez div {
  height: 32px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  /* margin-top: 15px; */
}

.cardTeacher_select__3ffez div span {
  position: relative;
  left: 10px;
  padding-top: 2px !important;
  font-size: 11px;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
}

.cardTeacher_select__3ffez * {
  color: #fff !important;
}

@media only screen and (max-width: 1050px) {
  .cardTeacher_content__2ryyu {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 25%;
    padding-bottom: 0;
  }

  .cardTeacher___top__Ha49s h3 {
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-size: 0.7em;
    text-transform: uppercase;
    font-weight: 500;
  }

  .cardTeacher_select__3ffez div {
    height: 22px !important;
    background: var(--first-color) !important;
    border: unset !important;
    border-radius: 50px !important;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .cardTeacher_select__3ffez div span {
    position: relative;
    left: 0px;
    padding-top: 2px !important;
    font-size: 0.75em;
    text-align: center;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
  }

  .cardTeacher___bottom__QsIE1 {
    height: 25px !important;
  }
}
.success_title__3yf7h {
  color: #88b04b;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.success_paragraph__1xIDs {
  color: #404f5e;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-size: 20px;
  margin: 0;
}

.success_italic__3TmSF {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.success_card__1SDMu {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--title-font);
}

.success_container__2RHHl {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 120px;
}
.register_register__2Rq9o {
  margin-top: 2%;
  margin-bottom: 200px;
}

.register_header__3Rdug {
  text-align: center;
}

.register___title_choice__avatar__1Y-bg {
  text-transform: uppercase;
  margin: var(--mb-1) 0;
  font-size: 1.4em;
  color: #707070;
  font-weight: 600;
}

.register_avatar__6qImt {
  position: relative;
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  border-radius: 100%;
}

.register_avatar__6qImt .register_btn__Upload__3l8yg {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  background: transparent;
  /* visibility: hidden; */
  cursor: pointer;
}

.register_avatar__6qImt img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.register___input_choice_pseudo__32pzQ {
  width: 100%;
  height: 40px;
  max-width: 200px;
  border: 1px solid #707070;
  margin: var(--mb-1) auto var(--mb-2) auto;
  border-radius: 10px;
  padding-top: 3px;
}

.register___input_choice_system__SV7o9 {
  width: 100%;
  height: 40px;
  max-width: 240px;
  border: 1px solid #707070;
  margin: var(--mb-1) auto var(--mb-2) auto;
  border-radius: 10px;
  padding-top: 3px;
}

.register___input_choice_school__2EfXZ {
  width: 100%;
  height: 40px;
  max-width: 270px;
  border: 1px solid #707070;
  margin: var(--mb-1) auto var(--mb-2) auto;
  border-radius: 10px;
  padding-top: 3px;
}

.register___input_choice_pseudo__32pzQ input {
  width: 95%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.register___input_choice_system__SV7o9 input {
  width: 95%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.register___input_choice_school__2EfXZ input {
  width: 95%;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}

.register_text__3i9E6 p strong {
  font-weight: 500;
}

.register_text__3i9E6 p a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_row__2il6V {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid red;
}

.register_form__content__3-Nev {
  text-align: center;
  margin-top: var(--mb-2);
}

.register_form__content__3-Nev h3 {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3em;
  color: var(--text-color);
}

.register_form__2oZWm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--mb-2);
}

.register_field__3tnpZ {
  width: 150px;
  margin: 0 var(--mb-1) !important;
  margin-bottom: var(--mb-2) !important;
  height: 45px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  padding-top: 0.4em !important;
  border-radius: 10px !important;
}

.register_radio__group__2Ruft {
  width: 100%;
  max-width: 700px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  flex-direction: column !important;
}

.register_button__273bL {
  width: 100%;
  height: 35px !important;
  min-width: 300px;
  margin-bottom: var(--mb-2);
  font-size: 1em !important;
  padding-top: 5px !important;
}

.register_content__action__2M-yu {
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.register_button__273bL svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.register_teacher__action__FqXkQ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--mb-6) 0;
}

.register_teacher__action__FqXkQ .register_avatar__6qImt {
  padding-right: var(--mb-2);
}

.register_text__3i9E6 strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register_register__teacher__7m4lx * a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_alreadyAccount__2Jef8 {
  padding-top: var(--mb-6);
}

.register_button__signin__FVkjG {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: var(--mb-2);
}

.register_youAreReadyAccount__1hxMO {
  margin-top: var(--mb-6);
  margin-bottom: var(--mb-6);
}

.register_youAreReadyAccount__1hxMO p a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_title__level__pWeY1 {
  font-weight: 500;
  margin-bottom: var(--mb-1);
}

@media only screen and (max-width: 500px) {
  .register_avatar__6qImt {
    width: 70px;
    height: 70px;
  }

  .register___title_choice__avatar__1Y-bg {
    font-size: 1em;
  }

  .register___input_choice_pseudo__32pzQ {
    height: 38px;
    font-size: 13px !important;
  }

  .register___input_choice_system__SV7o9 {
    height: 38px;
    font-size: 13px !important;
  }

  .register___input_choice_school__2EfXZ {
    height: 38px;
    font-size: 13px !important;
  }

  .register_form__content__3-Nev h3 {
    font-size: 1em;
  }

  .register_field__3tnpZ {
    width: 85px;
    margin: 0 0.3em !important;
    margin-bottom: var(--mb-2) !important;
    height: 40px !important;
    font-size: 11px !important;
    padding-top: 0.3em !important;
  }

  .register_button__273bL {
    height: 30px !important;
    margin-bottom: var(--mb-1);
    font-size: 0.9em !important;
  }

  .register___input_choice_pseudo__32pzQ input {
    font-size: 14px !important;
  }

  .register___input_choice_system__SV7o9 input {
    font-size: 14px !important;
  }

  .register___input_choice_school__2EfXZ input {
    font-size: 14px !important;
  }
}
.stepTwo_register__teacher__bbBVT {
  width: 100%;
  margin-bottom: 4em;
  text-align: center;
}

.stepTwo_title__1OBX5 {
  font-size: 3.8em;
}

.stepTwo_student__action__2MUGb {
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-2);
}

.stepTwo_avatar__2V3iN {
  position: relative;
  width: 100px;
  height: 100px;
  background: #f7f7f7;
  border-radius: 50%;
  margin-right: var(--mb-2);
  overflow: hidden;
}

.stepTwo_avatar__2V3iN img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}


.stepTwo_student__action__2MUGb p a {
  color: var(--text-color);
  text-decoration: underline;
}

.stepTwo_content__action__32b-8 {
  width: 100%;
  /* min-height: 10vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.stepTwo_button__2HovD svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.stepTwo_form__container__icXNS {
  width: 100%;
  max-width: 600px;
  padding: 0 3%;
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;
}

.stepTwo_field__2Ksr1 {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.stepTwo_field__2Ksr1::placeholder {
  font-style: italic !important;
}

.stepTwo_button__2HovD {
  width: 100%;
  height: 35px !important;
  max-width: none !important;
  margin-bottom: var(--mb-2);
  font-size: 1em !important;
  padding-top: 5px !important;
}


.stepTwo_termes__legals__39ZD7 {
  float: left;
  text-align: left;
  color: #636363;
}

.stepTwo_info__37V32 {
  margin-top: var(--mb-2);
}

.stepTwo_info__37V32 span {
  text-align: left;
  color: #9D9D9D;
  font-style: italic;
}

.stepTwo_info__37V32 p {
  text-align: center;
  color: var(--text-color);
}

.stepTwo_info__37V32 a {
  color: var(--first-color);
  text-decoration: underline;
}

.stepTwo_text__2mwhl p {
  text-align: left;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 1.3em;
  color: #9D9D9D;
}

@media only screen and (max-width: 390px) {
  .stepTwo_avatar__2V3iN {
    width: 80px;
    height: 80px;
  }

  .stepTwo_student__action__2MUGb p {
    line-height: 25px;
  }

  .stepTwo_field__2Ksr1 {
    height: 40px !important;
  }

  .stepTwo_info__37V32 span,
  .stepTwo_policy__1k1WE {
    font-size: 12px !important;
  }

  .stepTwo_policy__1k1WE br {
    display: none;
  }

}
.register_register__teacher__rF3va {
  width: 100%;
  text-align: left;
}

.register_step__33mg_ {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
}

/* JOFREY STYLE */

.register_accountFormBox__1EzNr {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  height: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.register_formBox__snePB {
  width: 100%;
  max-width: 100%;
  margin: 40px auto;
  border-radius: 5px;
  background-color: #fafafa;
  height: auto;
  padding: 30px 80px 120px 80px;
  display: flex;
  flex-direction: column;
}

.register_formElementBox__1WO8e {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.register_stepTitleBox__kTR5B {
  text-align: left;
  align-items: flex-start;
  align-content: start;
}

.register_boxTitle__FrVCT {
  font-size: 28px;
  font-weight: 600;
  color: #707070;
  font-family: "CartoonFont";
  text-align: left;
  text-transform: uppercase;
}

.register_boxSubtitle__3YecG {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.register_boxMessage__3FX7c {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 35px;
}

.register_selectInput__D286J {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #707070;
  font-style: italic !important;
  width: 100%;
}

.register_placeholderStyle__1gRav {
  color: #C0C0C0;
  font-size: 16px;
}

.register_nextButton__3soQI {
  font-size: 18px;
  padding: 20px;
}

.register_hideElement__1X1sm {
  display: none;
}

.register_uploadButtonStyle__3pwMB {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
}

.register_uploadVignetteButton__2zaOC {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 130px;
}

.register_startRecordingVideo__1z3wp {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.register_cardInfoBox__137Ef {
  background-image: url(/static/media/card.b30b4ea7.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 35px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxToDo__eDuhP {
  background-image: url(/static/media/cardLong.0806ab99.png);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxWarning__2ijEb {
  background-image: url(/static/media/cardAds.9ca57eea.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 0px;
  padding: 0;
}

.register_avatarExampleBox__10J9g {
  background-image: url(/static/media/pictureExample.bc58bf9e.png);
  background-size: cover;
  height: 120px;
  width: 380px;
  border-radius: 8px;
  margin: 5px 7px 0px 35px;
}

/* Drag & Drop Input CSS */

#register_formFileUpload__o87cy {
  height: 425px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#register_inputFileUpload__1m_cY {
  display: none;
}

#register_labelFileUpload__3vBho {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #707070;
  background-color: #fff;
}

#register_labelFileUpload__3vBho.register_dragActive__33ZEF {
  background-color: #ffffff;
}

.register_uploadButton__1Rryx {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.register_uploadButton__1Rryx:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#register_dragFileElement__RrH6E {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* Drag & Drop Input CSS */

.register_uploadImageBox__2zUpE {
  display: flex; 
  flex-direction: column; 
  margin: 0px auto;
}

.register_uploadImageBoxTitle__2-4gl {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_uploadImageBoxMessage__iwE1E {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_calendarBoxTitle__3UN9x {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
}

.register_calendarBoxSubtitle__1ejqm {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin: 30px auto;
  padding-left: 210px;
}

.register_calendareBoxMessage__235qg {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubMessage__3jU6o {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxTitle__1i1cE {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 45px;
  margin-left: 35px;
}

.register_uploadVideoBoxMessage__1pS2r {
  font-size: 14.5px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubtitle__CJmJS {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_addPictureButton__1OrOd {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 20px;
}

.register_addVideoButton__1tynB {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 20px;
}

.register_addVignetteButton__2scWP {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 30px;
}

.register_previewBox__3BRwh {
  margin-left: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #707070;
  border-style: dashed;
}

.register_previewBoxImage__3v9OC {
  max-width: 200px;
  height: auto;
}

.register_uploadBox__2MW6b {
  display: flex;
  flex-direction: column;
}

.register_uploadBoxTitle__2Zy9I {
  font-size: 20px;
  font-style: italic;
  color: #333;
}

.register_uploadBoxMessage__2FQBc {
  font-size: 20px;
  font-style: italic;
  color: #CBCACD;
}

.register_uploadBoxMessageError__1muB7 {
  font-size: 20px;
  font-style: italic;
  color: red;
}

.register_uploadBoxBorder__2iR7L {
  margin-left: 35px;
  margin-top: 25px;
  border: none;
  border-radius: 15px;
}

.register_uploadBoxBorderError__3ZjJs {
  margin-left: 35px;
  margin-top: 25px;
  border: 1px solid red;
  border-radius: 15px;
}

.register_exampleBox__1S9IH {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_exampleBoxLong__5OYaE {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 30px;
}

.register_exampleBoxTitle__3-0hk {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_videoExampleBoxTitle__MoK3W {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 0px;
}

.register_instructionBox__nVElS {
  padding-left: 65px;
  padding-right: 60px;
  margin-top: 42px;
}

.register_instructionBoxLong__1oiKg {
  padding: 60px 40px 10px 65px;
  margin-top: 42px;
}

.register_checkboxBox__1082z {
  display: flex;
  flex-direction: row;
}

.register_checkboxBoxItem__w0fwk {
  margin-left: 10px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxItemLong__22eq9 {
  margin-left: 5px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxLong__30L60 {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.register_customTextarea__2iz8j {
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

/* Placeholder color */
.register_customTextarea__2iz8j::placeholder {
  color: #C0C0C0;
}

/* Focus color */
.register_customTextarea__2iz8j:focus {
  outline: none;
  border-color: #707070;
  box-shadow: 0 0 2px green;
}

#register_videoFileUpload__2rsV_ {
  background-image: url(/static/media/videoExample.d39fc304.png);
  background-size: cover;
  height: 300px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-left: 35px;
  margin-top: 25px;
}

.register_vignetteBox__3tzNi {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0px 0px 35px;
  padding: 15px 20px 15px 20px;
  width: 540px;
  align-items: center;
  justify-content: left;
}

.register_stepsBox__358ZL {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  gap: 8px;
  margin: 0px auto;
  width: 100%;
}

.register_addCertificateDiplomaButton__34SBQ {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 50px;
}

.register_customButtonStyle__sBlP1 {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.register_customButtonStyle__sBlP1:hover {
  transform: scale(1.03) !important;
}

.register_customButtonStyle__sBlP1:active {
  transform: scale(0.99) !important;
}

.register_certificateDiplomaFormBox__RKqey {
  margin: 0px auto;
  height: auto;
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.register_pictureBoxRow__1c8EK {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.register_picturePreviewBox__bgnMK {
  height: 186px;
  width: 147px;
  border: 1px solid #333;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
}

.register_titleBox__3WHdl {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 74%;
}

.register_videoPreviewBox__1kw-q {
  height: 420px;
  width: 560px;
  border-radius: 5px;
  margin-left: 35px;
  margin-top: 25px;
}

.register_videoForm__orKiu {
  margin-left: 35px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: auto;
}

.register_videoFormatTitle__2GGiN {
  font-size: 13px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 10px;
}

.register_calendarTimezone__1JTKH {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: center;
}

.register_fillLaterButton__1yr5X {
  height: 50px;
  width: 65%;
  max-width: 40%;
  margin-left: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

/* JOFREY STYLE */

.register_teacher__2i8OE {
  display: flex;
  align-items: center;
  margin: 0px auto;
}

.register_teacher__2i8OE .register_avatar__1LZ9z {
  padding-right: var(--mb-4);
}

.register_header__text__a-U6Y {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.register_header__text__a-U6Y p {
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.register_header__text__a-U6Y p strong,
.register_text__3-i-3 strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register_text__3-i-3 a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_content__action__26JR_ {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0px auto;
}

.register_button__1qz4h svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.register_form__container__FO61E {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.register_field__3o8o6 {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.register_field__3o8o6::placeholder {
  font-style: italic !important;
}

.register_termes__legals__2Jlb_ {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.register_button__1qz4h {
  width: 100%;
  height: 40px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.register_text__legal__3bG9Z {
  text-align: left;
  font-size: var(--small-font-size);
}

@media only screen and (max-width: 680px) {
  .register_register__teacher__rF3va {
    padding: 0 3%;
  }

  .register_teacher__2i8OE .register_avatar__1LZ9z {
    padding-right: 0;
  }

  .register_teacher__2i8OE .register_avatar__1LZ9z img {
    position: relative;
    width: 30px;
    top: -3px;
    margin: 0;
  }

  .register_header__text__a-U6Y p {
    font-size: var(--normal-font-size);
    margin-top: 3px;
  }

  .register_title__step__3HjGn {
    font-size: 12px;
    white-space: nowrap;
    display: none;
  }

  .register_step__33mg_ {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
  }

  .register_field__3o8o6 {
    height: 40px !important;
  }

  .register_field__3o8o6::placeholder {
    font-size: 14px !important;
  }

  .register_policy__2c8eK {
    position: relative;
    font-size: 11px !important;
  }

  .register_policy__2c8eK br {
    display: none;
  }
}

@media only screen and (max-width: 680px) {
  .register_formBox__snePB {
    width: 100%;
    max-width: 100%;
    margin: 40px auto;
    border-radius: 5px;
    background-color: #fafafa;
    height: auto;
    padding: 30px 5px 120px 5px;
    display: flex;
    flex-direction: column;
  }

  .register_stepTitleBox__kTR5B {
    text-align: left;
    align-items: flex-start;
    align-content: start;
  }

  .register_boxTitle__FrVCT {
    font-size: 28px;
    font-weight: 600;
    color: #707070;
    font-family: "CartoonFont";
    text-align: left;
    text-transform: uppercase;
  }

  .register_uploadBoxBorder__2iR7L {
    margin: 20px auto;
    border: none;
    border-radius: 15px;
  }

  #register_formFileUpload__o87cy {
    height: 225px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  .register_cardInfoBox__137Ef {
    background-image: url(/static/media/card.b30b4ea7.svg);
    background-size: cover;
    height: 422px;
    width: 100%;
    margin-left: 0px;
    margin-top: 25px;
    padding: 0;
  }
  
  .register_exampleBoxTitle__3-0hk {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 0px;
  }
  
  .register_avatarExampleBox__10J9g {
    background-image: url(/static/media/pictureExample.bc58bf9e.png);
    background-size: cover;
    height: 120px;
    width: 100%;
    border-radius: 8px;
    margin: 5px 5px 0px 0px;
  }

  .register_previewBox__3BRwh {
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-radius: 1rem;
    border-color: #707070;
    border-style: dashed;
  }

  .register_customButtonStyle__sBlP1 {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-left: 30px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  }

  .register_certificateDiplomaFormBox__RKqey {
    margin: 0px auto;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
 
  .register_pictureBoxRow__1c8EK {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .register_picturePreviewBox__bgnMK {
    height: 186px;
    width: 50%;
    border: 1px solid #333;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    display: flex;
  }
  
  .register_titleBox__3WHdl {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    margin-top: 20px;
  }

  .register_videoPreviewBox__1kw-q {
    height: 420px;
    width: auto;
    border-radius: 5px;
    margin-left: 5px;
    margin-top: 25px;
  }

  #register_videoFileUpload__2rsV_ {
    background-image: url(/static/media/videoExample.d39fc304.png);
    background-size: contain;
    height: 300px;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 25px auto;
    background-repeat: no-repeat;
  }

  .register_exampleBoxLong__5OYaE {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .register_uploadImageBoxTitle__2-4gl {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 5px;
  }
  
  .register_uploadImageBoxMessage__iwE1E {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .register_vignetteBox__3tzNi {
    display: flex;
    flex-direction: row;
    border: 1px solid #333;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 20px auto;
    padding: 15px 20px 15px 20px;
    width: auto;
    align-items: center;
    justify-content: left;
  }

  .register_videoForm__orKiu {
    margin-left: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
    width: auto;
  }

  .register_uploadVideoBoxMessage__1pS2r {
    font-size: 14.5px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }
  
  .register_uploadVideoBoxSubtitle__CJmJS {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 5px;
  }

  .register_uploadVideoBoxTitle__1i1cE {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-left: 5px;
  }

  .register_addVideoButton__1tynB {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 20px;
  }

  .register_addVignetteButton__2scWP {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 30px;
  }

  .register_videoFormatTitle__2GGiN {
    font-size: 13px;
    color: #CBCACD;
    margin-left: 5px;
    margin-top: 10px;
  }

  .register_calendarBoxTitle__3UN9x {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 5px;
  }

  .register_calendareBoxMessage__235qg {
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .register_calendarBoxSubtitle__1ejqm {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 30px auto;
    padding-left: 5px;
  }

  .register_calendarTimezone__1JTKH {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
  }

  .register_fillLaterButton__1yr5X {
    height: 50px;
    width: 150px;
    margin-left: 0px;
  }

  .register_title__2KNgj {
    font-size: 14px;
  }

  .register_nextButton__3soQI {
    font-size: 14px;
    padding: 0px;
  }

}
.registerByMAil_register__teacher__2VSQx {
  width: 100%;
  text-align: center;
}

.registerByMAil_title__7Rbir {
  font-size: 3.8em;
}

.registerByMAil_student__action__1ATQ2 {
  display: flex;
  align-items: center;
  margin-top: var(--mb-3);
  margin-bottom: var(--mb-3);
}

.registerByMAil_student__action__1ATQ2 p {
  font-size: 1em;
}

.registerByMAil_student__action__1ATQ2 p a {
  color: var(--text-color);
  text-decoration: underline;
}

.registerByMAil_content__action__2h7nV {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.registerByMAil_button__14wSf svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.registerByMAil_form__container___d-fr {
  width: 100%;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;
}

.registerByMAil_field__2MqfA {
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.registerByMAil_field__2MqfA::placeholder {
  font-style: italic !important;
}

.registerByMAil_button__14wSf {
  width: 100%;
  height: 50px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.5em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.registerByMAil_select__34_QN {
  width: 33.33% !important;

}

.registerByMAil_select__34_QN div {
  height: 53px !important;
  border: 1px solid var(--text-color) !important;
}

.registerByMAil_select__34_QN div span {
  padding-top: 10px !important;
  text-align: left;
}

.registerByMAil_left__3XTE6 div {
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.registerByMAil_right__338uU div {
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.registerByMAil_termes__legals__3R0_e {
  margin-top: var(--mb-4);
  float: left;
  text-align: left;
  color: #636363;
  font-size: 1em !important;
}

.registerByMAil_info__XpzLI {
  margin-top: var(--mb-4);
}

.registerByMAil_info__XpzLI span {
  text-align: left;
  color: #9D9D9D;
  font-style: italic;
}

.registerByMAil_info__XpzLI p {
  text-align: center;
  color: var(--text-color);
  font-size: 1.1em !important;
}

.registerByMAil_info__XpzLI a {
  color: var(--text-color);
  text-decoration: underline;
}
.style_pageNumbers__2I7VR {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--mb-6);
  margin-bottom: var(--mb-6);
  grid-gap: 1em;
  gap: 1em;
}

.style_pageNumbers__2I7VR li {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--first-color);
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.style_pageNumbers__2I7VR li button {
  background: unset;
  border: unset;
  outline: none;
  color: var(--first-color);
  cursor: pointer;
}

.style_pageNumbers__2I7VR li:hover button {
  color: #fff !important;
}

.style_pageNumbers__2I7VR li:hover {
  background: var(--first-color);
  color: #fff !important;
}

.style_active__312lw {
  background: var(--first-color);
  /* border: 2px solid var(--first-color); */
  color: #fff;
}

/* New Css Styles */

.style_currentPagebox__x-Z3z {
  width: 60px;
  height: 30px;
  background: #FFF;
  border: 0.5px solid #707070;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.style_newButton__3prgq {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
  -moz-box-shadow: 2px 4px 1px -1px #5a8484;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

@media only screen and (max-width: 1050px) {
  .style_currentPagebox__x-Z3z {
    width: 30px;
    height: 20px;
    background: #FFF;
    border: 0.5px solid #707070;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .style_newButton__3prgq {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.searchTeacher_header__3XX6V {
  width: 100%;
  margin-bottom: 0.8em;
}

.searchTeacher_filter__hHijE {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-2);
  padding: 0 var(--mb-4);
}

.searchTeacher_filed__search__XNoky {
  width: 300px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.searchTeacher_filter__left__2eXs- * input::placeholder,
.searchTeacher_filter__right__39Lj- * input::placeholder {
  color: var(--text-color);
}

.searchTeacher_filter__right__39Lj- {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.searchTeacher_select__2MEan {
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
}

.searchTeacher_select__2MEan {
  padding-top: 3px !important;
  text-align: left;
}

.searchTeacher___card__Cg_g2 {
  width: 400px;
  height: 350px;
}

.searchTeacher_affix__2s3FJ {
  position: relative;
  width: 170px;
  height: 50px;
  background: url(/static/media/Instaprof_button.40aa807c.png) no-repeat center center;
  background-size: contain;
  cursor: pointer;
}

.searchTeacher_instaprof__mobile__vaqoS {
  display: none;
}

.searchTeacher_display__filter__mobile__27K0P {
  display: none;
}

@media only screen and (min-width: 768px) {
  .searchTeacher_grid__1fQhf {
    grid-gap: 2em;
    gap: 2em;
  }
}

@media only screen and (max-width: 700px) {
  .searchTeacher_filter__hHijE {
    height: auto;
    display: flex;
    flex-direction: column;

    padding: 3%;
  }

  .searchTeacher_filed__search__XNoky {
    width: 100%;
    padding: 0;

    margin-bottom: 0;
  }

  .searchTeacher_filter__left__2eXs- * input::placeholder,
  .searchTeacher_filter__right__39Lj- * input::placeholder {
    font-size: 13px;
  }

  .searchTeacher_filter__left__2eXs- {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
  }

  .searchTeacher_filter__right__39Lj- {
    width: 100%;
    justify-content: space-between;
  }

  .searchTeacher_display__filter__mobile__27K0P {
    display: block;
  }

  /* .filter__right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .searchTeacher_main__1QHhz {
    padding: 0 5%;
  }

  .searchTeacher_select__2MEan {
    width: 43vw;
    height: 38px;
    margin-top: 10px;
  }

  .searchTeacher_affix__2s3FJ {
    display: none;
  }

  .searchTeacher_instaprof__mobile__vaqoS {
    position: fixed;
    display: block;
    width: 75%;
    height: 50px;
    background: #fff;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 1px solid #BFBCBC;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    border-radius: 16px;
  }

  .searchTeacher_buttonInsta__X7PZb {
    background: #F56666 !important;
    height: 30px !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    font-size: 0.8em !important;
    text-transform: capitalize;
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: auto !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 13px !important;
    text-align: center !important;
    color: #FFF !important;
  }
}

/* New Css Styles */

.searchTeacher_lineBox__2YOtM {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
}

.searchTeacher_main__1QHhz {
  padding-top: 20px;
}

.searchTeacher_headerBox__35Ckt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.searchTeacher_titleBox__2w1rV {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.searchTeacher_titlePictureBox__1qorw {
  height: 55px;
  width: 55px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFDCAB80;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchTeacher_buttonBox__32WtD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.searchTeacher_newInstaButton__2sDVX {
  width: auto !important;
  height: 40px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
  -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.searchTeacher_newInstaButton__2sDVX:hover {
  transform: scale(1.03) !important;
  background-color: #F56666;
  color: #fff;
}

.searchTeacher_searchBox__3TRhB {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.searchTeacher_selectBox__Y_AS7 {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.searchTeacher_separation__29nsO {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.searchTeacher_inputBox__3jIqN {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.searchTeacher_filedSearch__1qIhx {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.searchTeacher_currentPagebox__14nEF {
  width: 60px;
  height: 30px;
  background: #FFF;
  border: 0.5px solid #707070;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.searchTeacher_newButton__1mgQi {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.searchTeacher_searchModalContentBox__2X5GX {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
  box-shadow: 4px 8px 16px #80808029;
  border: 1px solid #BFBCBC;
  border-radius: 20px;
  opacity: 1;
  width: 700px;
  padding-right: 12%;
  padding-left: 10%;
  z-index: 25;
}

.searchTeacher_searchContentBox__1lNoi {
  height: 100% !important;
  width: auto !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.searchTeacher_searchContentTitle__22qXB {
  color: #333333;
  font-size: 22px;
  font-family: Rubik;
  font-weight: 500;
}

.searchTeacher_modalImage__26ObJ {
  width: 150px;
  height: auto;
  margin-bottom: 40px;
  margin-top: 40px;
}

.searchTeacher_modalSubtitle__1LnIb {
  font-size: 16px;
  color: #707070;
  margin-bottom: 20px;
  position: relative;
  top: -25px;
}

.searchTeacher_modalForm__1J8d_ {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  top: -25px;
}

.searchTeacher_modalSelect__2luLS {
  width: 170px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.searchTeacher_searchArrow__3btad {
  position: relative;
  top: -25px;
  left: -50%;
}

.searchTeacher_searchButton__TGnyV {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 50px !important;
  padding-right: 50px !important;
  position: relative !important;
  top: -25px !important;
}

@media only screen and (max-width: 1050px) {
  .searchTeacher_searchModalContentBox__2X5GX {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
    box-shadow: 4px 8px 16px #80808029;
    border: 1px solid #BFBCBC;
    border-radius: 20px;
    opacity: 1;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 25;
  }

  .searchTeacher_searchContentTitle__22qXB {
    color: #333333;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
  }

  .searchTeacher_modalImage__26ObJ {
    width: auto;
    height: 170px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .searchTeacher_modalSubtitle__1LnIb {
    font-size: 0.7em;
    color: #707070;
    margin-bottom: 20px;
    position: relative;
    top: 0px;
  }

  .searchTeacher_modalForm__1J8d_ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 95%;
    position: relative;
    top: 0px;
  }

  .searchTeacher_modalSelect__2luLS {
    width: 95%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .searchTeacher_searchButton__TGnyV {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 40px !important;
    padding-right: 40px !important;
    position: relative !important;
    top: 0px !important;
  }

  .searchTeacher_headerBox__35Ckt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .searchTeacher_titleBox__2w1rV {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
  }

  .searchTeacher_titlePictureBox__1qorw {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .searchTeacher_buttonBox__32WtD {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }

  .searchTeacher_newInstaButton__2sDVX {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 13px !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
  }

  .searchTeacher_searchBox__3TRhB {
    width: 95%;
    height: auto;
    background: #FAFAFA;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 15px auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .searchTeacher_selectBox__Y_AS7 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 0.75em !important;
    text-align: left;
    opacity: 1;
    background: #FFF;
    border: 0.5px solid #BFBCBC;
    border-radius: 12px;
    height: 35px !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .searchTeacher_separation__29nsO {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 20px;
    display: none;
  }

  .searchTeacher_inputBox__3jIqN {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin-left: 0px;
    border: 0.5px solid #BFBCBC;
    border-radius: 16px;
    padding-right: 5px;
    background: #FFF;
  }

  .searchTeacher_filedSearch__1qIhx {
    width: 90%;
    height: 35px;
    background: #FFF;
    border: 0px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
  }

  .searchTeacher_searchInput__ztXCe::placeholder {
    font-size: 0.75em !important;
  }

  .searchTeacher_lineBox__2YOtM {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    padding: 0%;
    align-items: center;
    justify-content: center;
  }
}
.stats_stats__2LO_g {
  margin-top: 2em;
  margin-bottom: 5em;
}

.stats___select__3kEB3 {
  margin-top: 15px;
}

.stats___select__3kEB3 div {
  position: relative;
  text-align: center;
  font-size: 2.4em;
  margin-top: var(--mb-1);
  line-height: 0 !important;
  font-family: var(--title-font);
  text-transform: uppercase;
  border: 1px solid black !important;
}

.stats_iconArrowClass__2-Edy {
  width: 20px;
  font-size: 12px !important;
  top: 3px !important;
  right: 30px !important;
}

.stats_header__2q3b_ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1.5em;
  gap: 1.5em;
  margin-bottom: 4em;
  margin-top: 0.5em;
}

.stats_card__matier__1xf59 {
  position: relative;
  width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin-top: var(--mb-2);
  transition: all 0.7s;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  padding-left: 0px;
  padding-right: 5px;
}

.stats_card__matier__1xf59.stats_active__sy4V1,
.stats_card__matier__1xf59:hover {
  width: 200px;
  height: 76px;
  background: var(--first-color);
  box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.stats_card__matier__1xf59.stats_active__sy4V1 .stats_topic_name__2gShy,
.stats_card__matier__1xf59 .stats_topic_name__2gShy {
  position: absolute;
  transform: translateX(100%);
  visibility: hidden;
  transition: transform ease-out 0.7s;
  transform-origin: center right;
  transition: all 0.7s;
  -webkit-transition: transform ease-out 0.7s;
  -moz-transition: transform ease-out 0.7s;
  -ms-transition: transform ease-out 0.7s;
  -o-transition: transform ease-out 0.7s;
}

.stats_card__matier__1xf59.stats_active__sy4V1 .stats_topic_name__2gShy,
.stats_card__matier__1xf59:hover .stats_topic_name__2gShy {
  position: relative;
  visibility: visible;
  transform: translateX(0);
}

.stats_topic_name__2gShy h3 {
  color: #fff;
  font-family: var(--title-font);
  white-space: nowrap;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 600;
}

.stats_icon__card__matiers__18Psx {
  transform-origin: center left;
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
}

.stats_icon__card__matiers__18Psx img {
  width: 50px;
}

.stats_main__3Lms2 {
  width: 100%;
}

.stats_card__dashboard__3EAKH {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 2em;
  gap: 2em;
  padding: 1.5em;
  background: #fafafa;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.stats_infos_top__18dF1 {
  margin-bottom: var(--mb-1);
}

.stats_infos_top__18dF1 h2 {
  font-size: 1.3em;
}

.stats_right__12IR6 h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.stats_content__propositions__2Msuq {
  width: 100%;
  padding: 1em 4em;
}

.stats_propositions__card__34fS6 {
  margin-top: var(--mb-6);
}
.stats_content_card__26Hyz {
  margin-top: var(--mb-2);
}

.stats_card__YXqh- {
  width: 100%;
  min-height: 50px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 2em;
  gap: 2em;
  padding: 0 2em;
  margin-bottom: 1em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.stats_card__YXqh- h4 {
  margin-top: auto;
  margin-bottom: auto;
  color: var(--text-color);
}

.stats_isCorrect__r2JeA {
  border: 1.3px solid green;
}

.stats_propositionSelected__2ySmx {
  border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
  .stats_right__12IR6 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.stats_stats__dashboard__8roQc {
  width: 100%;
  background: #fafafa;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  margin-top: 20px;
}

.stats_backDiv__1aFno {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
  height: 35px;
  width: auto;
}

.stats_backButton__LhMDS {
  border-radius: 10px;
  border: 1px solid #C0C0C0;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.stats_statsContainer__3ZFTO {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stats_radarContainer__1bSxJ {
  height: auto;
  width: 40%;
  background: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.stats_boxTitle__3bUy3 {
  color: var(--title-color);
  font-size: var(--h1-font-size);
  margin-left: 20px;
  margin-top: 10px;
  font-family: "CartoonFontBold";
  font-weight: 600;
}

.stats_boxTitle__3bUy3:hover {
  color: #333;
}

.stats_radarBox__2B-HY {
  height: auto;
  width: 85%;
  margin-left: 10%;
  margin-top: 10px;
  background: white;
  border-radius: 15px;
}

.stats_radarTitle__1HH0B {
  color: #333;
  font-size: 1.5em;
  font-weight: 500;
  margin-left: 20px;
  margin-top: 20px;
}

.stats_radarTitle__1HH0B:hover {
  color: #333;
}

.stats_radarMarkBox__3zKxw {
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats_radarAVG__2wp1d {
  font-size: 35px;
  color: #333;
  font-weight: bold;
}

.stats_radarAVG__2wp1d:hover {
  color: #333;
}

.stats_radarMaxAVG__1Vk-l {
  margin-top: 10px;
  font-weight: bold;
}

.stats_radarMaxAVG__1Vk-l:hover {
  color: #333;
}

.stats_radarChartBox__3LvR2 {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.stats_lineContainer__3BDFi {
  height: auto;
  width: 58%;
  display: flex;
  flex-direction: column;
}

.stats_lineBox__KejH6 {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 10px;
}

.stats_lineChartCard__2v4om {
  height: auto;
  width: 100%;
  background: white;
  border-radius: 15px;
}

.stats_lineChartCard__2v4om:hover {
  background-color: #80b1b21a;
}

.stats_lineChartCardHeader__1kiVF {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.stats_lineChartCardAVG__2s7N6 {
  margin-top: 10px;
  margin-right: 30px;
  font-size: 20px;
  color: #333;
  font-weight: normal; 
}

.stats_lineTitle__18cBG {
  color: #333;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stats_backHeader__2Xe0O {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.stats_backLeft__3PHHt .stats_backTitle__3u7jy {
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color); 
}

.stats_center__7Zs1Y {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

/* .backRight {
  
} */

@media only screen and (max-width: 767px) {
  .stats_backLeft__3PHHt .stats_backTitle__3u7jy {
    text-transform: uppercase;
    font-size: 1.2em;
    color: var(--text-color); 
  }
  .stats_lineContainer__3BDFi {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .stats_lineContainer__3BDFi {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .stats_lineChartCard__2v4om {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
    margin-top: 10px;
  }
  .stats_lineBox__KejH6 {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .stats_radarContainer__1bSxJ {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .stats_statsContainer__3ZFTO {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .stats_card__dashboard__3EAKH {
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 0.5em;
  }

  .stats_stats__dashboard__8roQc {
    grid-gap: 0.5em;
    gap: 0.5em;
    padding: 0.5em;
  }

  .stats_topic_name__2gShy h3 {
    font-size: 0.7em;
  }
  .stats_icon__card__matiers__18Psx img {
    width: 30px;
  }

  .stats_card__matier__1xf59 {
    width: auto;
    height: 60px;
    margin-top: var(--mb-1);
  }

  .stats_card__matier__1xf59.stats_active__sy4V1,
  .stats_card__matier__1xf59:hover {
    width: auto;
    height: 50px;
  }

  .stats_content__propositions__2Msuq {
    padding: 0;
  }

  .stats_header__2q3b_ {
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-bottom: 2em;
    margin-top: 0;
  }

  .stats_right__12IR6 {
    margin-top: 1em;
  }
  .stats_card__YXqh- {
    padding: 0 1em;
  }

  .stats___select__3kEB3 div {
    font-size: 1.5em;
    margin-bottom: var(--mb-1);
  }
  .stats_iconArrowClass__2-Edy {
    width: 15px;
    top: -1px !important;
    right: 60px !important;
  }

  .stats_propositions__card__34fS6 {
    margin-top: var(--mb-4);
  }

  .stats_radarChartBox__3LvR2 {
    display: flex;
    justify-content: center;
    padding: 0px;
  }

  .stats_radarBox__2B-HY {
    height: auto;
    width: 100%;
    margin-left: 0%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
  }
}

.style_radarContainer__2_owc {
    cursor: pointer;
}
.quiz_quiz__k6g3u {
  margin: 1em 0;
}

.quiz_quiz__content__2QNOR {
  width: 100%;
  background: #fafafa;
  padding: 3% 5% 7% 5%;
}

.quiz_left__2HNAO .quiz___avatarBoy__19h-U {
  width: 100px;
}

.quiz_right__2-x-5 .quiz___iconTrophy__3E1i1 {
  width: 70px;
}

.quiz_center__9qUtQ img {
  width: 35px;
}

.quiz_header__2YeI6 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.quiz_center__9qUtQ {
  flex: 2 1;
  padding: 0 1%;
}

.quiz_center__9qUtQ span {
  font-size: 1em;
}

.quiz___title__3PHsf {
  width: 100%;
  max-width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.quiz_main__2ChPM {
  margin-top: 2em;
}

.quiz_main__2ChPM h3 {
  font-size: 1.2em;
  margin-left: 2%;
  color: var(--text-color);
  margin-bottom: var(--mb-3);
  /* #CBCACD */
}

.quiz_card__3OIPH {
  position: relative;
  width: 95%;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #cbcacd;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-3);
  padding: 0 3%;
}

.quiz_card__3OIPH input[type="radio"] + label {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  user-select: none;
}

.quiz_card__3OIPH input[type="radio"] + label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 20px;
  border-bottom: 5px solid var(--text-color);
  border-left: 5px solid var(--text-color);
  top: 25%;
  left: 45%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

.quiz_card__3OIPH input[type="radio"]:checked + label {
  border: 1px solid var(--text-color);
}

.quiz_card__3OIPH input[type="radio"]:checked + label:after {
  opacity: 1;
  width: 35px;
}

.quiz_chk__1hgzr {
  display: none;
}

.quiz___btn__back__3eoFn {
  max-width: 80px !important;
  min-width: 100px !important;
  background: #f64d5c !important;
  box-shadow: 3px 6px 1px -1px #f64d5bab !important;
}

.quiz____result__btn__t77S0 {
  border-radius: 15px;
  height: 40px;
}

/*  */
.quiz_show__counter__3nn9z {
  display: flex;
  min-width: 200px;
  grid-gap: 0.5em;
  gap: 0.5em;
  font-size: 1.2em;
}

.quiz_danger__2rR8W {
  color: red;
}

.quiz_expired__3xNwj {
  position: relative;
  color: red;
  text-align: center;
  font-size: 1.4em;
  left: -0.8em;
  animation: quiz_clignote__NLdpO 1.7s linear infinite;
}

@keyframes quiz_clignote__NLdpO {
  50% {
    opacity: 0;
  }
}

.quiz___sound__10Gop {
  position: absolute;
  left: -100%;
  visibility: hidden;
  width: 100%;
}

.quiz___drawer__button__2PkhJ {
  min-width: 350px;
  height: 45px !important;
}

.quiz___result__title__3zQNO {
  text-align: center;
  margin-top: var(--mb-1);
}

/*  */

.quiz_proposition__3eDvA {
  margin-left: 10px;
}

.quiz_cb__3M_MD {
  border: 1px solid #555;
  display: inline-block;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.quiz_cb__3M_MD:before,
.quiz_cb__3M_MD:after {
  content: "";
  display: block;
  position: absolute;
}

.quiz_cb__3M_MD:before {
  animation: quiz_eraseA__3GPTU 0.5s steps(1) forwards;
  background: url("https://s22.postimg.cc/fi4blx4gx/checkmark.png") 0 100%/1.2em
    1.35em no-repeat;
  bottom: 0.2em;
  left: 0.3em;
  width: 0;
  height: 1.35em;
}

.quiz_cb__3M_MD:after {
  animation: quiz_eraseB__1ISfv 0.5s linear forwards;
  background: linear-gradient(
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 50%
      )
      11.2em 0,
    linear-gradient(#c8646e, #c8646e) 11.2em 0,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      9.8em 0/0.3em 100%,
    repeating-linear-gradient(
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em
      )
      10.2em 0/0.5em 100%,
    linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.6) 0.1em,
        rgba(255, 255, 255, 0) 0.1em,
        rgba(255, 255, 255, 0) 0.2em,
        rgba(0, 0, 0, 0.2) 0.2em,
        rgba(0, 0, 0, 0.2) 0.3em,
        rgba(0, 0, 0, 0) 0.3em
      )
      10.8em 0/0.3em 100%,
    linear-gradient(
        rgba(255, 255, 255, 0) 35%,
        rgba(255, 255, 255, 0.6) 35%,
        rgba(255, 255, 255, 0.6) 65%,
        rgba(255, 255, 255, 0) 65%
      )
      9.7em 0,
    linear-gradient(#b4b4b4, #b4b4b4) 9.7em 0,
    linear-gradient(
        rgba(0, 0, 0, 0.1) 27%,
        rgba(0, 0, 0, 0) 27%,
        rgba(0, 0, 0, 0) 73%,
        rgba(0, 0, 0, 0.2) 73%
      )
      2em 0,
    linear-gradient(#f0d000, #f0d000) 2em 0,
    linear-gradient(-14deg, #1e1e1e 18%, rgba(30, 30, 30, 0) 20%) 0 0/0.5em
      0.5em,
    linear-gradient(14deg, rgba(30, 30, 30, 0) 80%, #1e1e1e 83%) 0 0.5em/0.5em
      0.5em,
    linear-gradient(-14deg, #d2a078 48%, rgba(210, 160, 120, 0) 50%) 0 0/2em
      0.5em,
    linear-gradient(14deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.1) 50%) 0 0.5em/2em
      0.5em,
    linear-gradient(14deg, rgba(210, 160, 120, 0) 48%, #d2a078 50%) 0 0.5em/2em
      0.5em;
  background-repeat: no-repeat;
  border-radius: 0 0.1em 0.1em 0;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0 0.25em;
  width: 12em;
  height: 1em;
}

.quiz_cb__3M_MD:checked {
  background-color: transparent;
}

.quiz_cb__3M_MD:checked:before {
  animation: quiz_drawA__HlbMs 0.5s linear forwards;
}

.quiz_cb__3M_MD:checked:after {
  animation: quiz_drawB__2pdyM 0.5s linear;
}

.quiz_cb__3M_MD:focus {
  outline: transparent;
}

.quiz_cb__3M_MD:focus + span {
  color: rgba(0, 0, 0, 0.75);
  outline: transparent;
}

.quiz_pristine__11CTP:before,
.quiz_pristine__11CTP:after {
  animation: none;
}

@keyframes quiz_drawA__HlbMs {
  from,
  5% {
    width: 0;
    height: 1.35em;
  }

  90%,
  to {
    width: 1.2em;
    height: 1.35em;
  }
}

@keyframes quiz_drawB__2pdyM {
  from {
    opacity: 0;
    transform: translate(0em, -0.5em) rotate(-50deg);
    visibility: visible;
  }

  5% {
    opacity: 1;
    transform: translate(0em, 0.3em) rotate(-60deg);
  }

  17% {
    transform: translate(0.25em, 0.8em) rotate(-65deg);
  }

  79% {
    transform: translate(1.1em, -0.14em) rotate(-63deg);
  }

  90% {
    opacity: 1;
    transform: translate(1.2em, -0.15em) rotate(-63deg);
  }

  to {
    opacity: 0;
    transform: translate(1.25em, -1.7em) rotate(-63deg);
  }
}

@keyframes quiz_eraseA__3GPTU {
  from {
    width: 1.2em;
    height: 1.35em;
  }

  10% {
    width: 1.2em;
    height: 1.08em;
  }

  27% {
    width: 1.2em;
    height: 0.81em;
  }

  36% {
    width: 1.2em;
    height: 0.7em;
  }

  45% {
    width: 1.2em;
    height: 0.55em;
  }

  55% {
    width: 1.2em;
    height: 0.35em;
  }

  80%,
  to {
    width: 1.2em;
    height: 0;
  }
}

@keyframes quiz_eraseB__1ISfv {
  from {
    opacity: 0;
    transform: translate(0.6em, -12.2em) rotate(90deg);
    visibility: visible;
  }

  10% {
    opacity: 1;
    transform: translate(1.2em, -12.05em) rotate(90deg);
  }

  20% {
    transform: translate(0.6em, -11.9em) rotate(90deg);
  }

  30% {
    transform: translate(1.2em, -11.75em) rotate(90deg);
  }

  40% {
    transform: translate(0.6em, -11.6em) rotate(90deg);
  }

  50% {
    transform: translate(1.2em, -11.45em) rotate(90deg);
  }

  60% {
    transform: translate(0.6em, -11.3em) rotate(90deg);
  }

  70% {
    transform: translate(1.2em, -11.15em) rotate(90deg);
  }

  80% {
    transform: translate(0.6em, -11em) rotate(90deg);
  }

  90% {
    opacity: 1;
    transform: translate(2em, -12.5em) rotate(100deg);
  }

  to {
    opacity: 0;
    transform: translate(2em, -12.5em) rotate(100deg);
  }
}

@media only screen and (max-width: 500px) {
  .quiz_quiz__content__2QNOR {
    padding: 3% 2%;
  }

  .quiz_left__2HNAO .quiz___avatarBoy__19h-U {
    position: relative;
    width: 50px;
    top: -2px !important;
  }

  .quiz_right__2-x-5 .quiz___iconTrophy__3E1i1 {
    position: relative;
    width: 30px;
    top: -7px !important;
  }

  .quiz_show__counter__3nn9z {
    font-size: 1em;
  }

  .quiz_center__9qUtQ img {
    width: 20px;
  }

  .quiz_main__2ChPM h3 {
    font-size: 1.1em;
    margin-left: 2%;
    color: var(--text-color);
  }

  .quiz_proposition__3eDvA {
    font-size: 12px;
  }

  .quiz_count__down__2R6iR {
    position: relative;
    left: -1.1em;
  }
}

.cardResult_body__3RXCP {
  width: 80%;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
}

.cardResult_cardResult__2KeN3 {
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;

  border-radius: 5px;
  padding: 0 1em;
  border: 1px solid #333333;
}

.cardResult_body__3RXCP span {
  font-size: 18px;
}

.cardResult_body__3RXCP .cardResult_question__2RuIB {
  font-size: 20px;
  margin-bottom: 10px;
}

.cardResult_isCorrect__1r0PB {
  border: 1px solid #449F01;
}

.cardResult_isNoCorrect__1Xceo {
  border: 1px solid #FF0000;
}
.styles_filter__3bgMi {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  border-radius: 10px;
  margin: 10px 0;
  padding: 0 2em;
}

.styles_main__3GlyO {
  width: 100%;
  height: 100%;
  margin-top: var(--mb-4);
  margin-bottom: 4em;
  /* background: #fafafa;
  padding: 2%; */
}

.styles_search__2bnd8 {
  width: 32%;
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.styles_search__2bnd8 * button {
  background: unset !important;
  border: none !important;
}


.styles_search__2bnd8 * svg {
  position: relative;
  top: 2px;
  fill: var(--text-color);
  font-size: 22px;
}

.styles_select__2l4sO {
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.styles____row__2flvT {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .styles_left__2sUud .styles_subtitle__2-kC8 {
    font-size: var(--h2-font-size);
    max-width: 100px;
  }

  .styles_filter__3bgMi {
    padding: 0 0.3em;
    grid-gap: 2%;
    gap: 2%;
  }

  .styles_search__2bnd8 {
    width: 50%;
    height: 40px;
  }

  .styles_search__2bnd8 * button {
    font-size: 12px;
  }

  .styles_select__2l4sO {
    width: 50%;
    height: 40px;
  }
}

@media only screen and (min-width: 390px) {}

@media only screen and (min-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.styles___reset__btn__1XnWp {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.styles___submit__hidden__1ZPVh {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}
.calendar_calendrier__phUfL {
  width: 100%;
  margin: 3em 0;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.calendar_calendar__content__1-MK5 {
  width: 100%;
}

/*  */

.calendar_calendar__35lc7 {
  height: 600px;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #FAFAFA;
}

.calendar_weekly__2rUOo {
  width: 100%;
  height: 100%;
  /*display: flex;
  justify-content: space-between;
  align-items: center;
  */
  padding: 2% 4%;
  margin-bottom: 2em;

  border: 1px solid #ccc;
}

.calendar_date__92KEI {
  width: 100%;
  min-height: 35px;
  border-radius: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  padding-top: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.calendar_date__92KEI:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.calendar_date__92KEI.calendar_active__3Xc7j {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.calendar_creneaux__9l3DP {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.calendar_creneau__3OzhY {
  margin-bottom: 0.5em;
}

.calendar_range__1fCWr {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.calendar_range__1fCWr span {
  margin: auto;
}

.calendar_matiere__2yhek {
  font-size: 1em;
}

.calendar_bar__G6WH0 {
  margin: 0 0.5em;
}

.calendar_angleIcon__10cfm path {
  fill: #fe772e;
}

.calendar_slider__3CiYV>svg path {
  fill: #fe772e;
}

.calendar_card__cgDcK {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: var(--mb-3);
}

@media only screen and (max-width: 500px) {
  .calendar_content__action__reservation__25k3M {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.calendar_myReservation__2dJ5X {
  margin-bottom: 1em;
}

.calendar_card___w1xDn {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}


/* New Css Styles */

.calendar_dashboard__2-0NL {
  margin-top: 1em;
}

.calendar_dashboardUserBox__RpTD_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
}

.calendar_dashboardUserMessage__2E3d2 {
  font-size: 1.8em;
  color: black;
  margin: 0px auto;
  padding-bottom: 30px;
}

.calendar_headerBox__2a1w0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendar_titleBox__2x1Hh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.calendar_titlePictureBox__6br63 {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #E4F1F2;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_buttonBox__O9nuj {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.calendar_todayBox__1amh4 {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar_classeCard__250s6 {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendar_classeCardDeleted__3QNpQ {
  position: relative;
  width: 100%;
  height: 70px;
  background: #EBEBEB;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.calendar_classeCardCoupon__2sLOl {
  position: relative;
  width: 100%;
  height: 70px;
  background: #E7F8F8;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.calendar_cancelBox__ZY3eJ {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 1;
  text-align: center;
}

.calendar_historyClasseCard__zd5nX {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
}

.calendar_dateBox__3vcKe {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.calendar_horizontalLine__2evro {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.calendar_avatarNameBox__Tuw-A {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 250px;
  object-fit: contain;
}

.calendar_detailsBox__3PS3a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.calendar_historyDetailsBox__2NIPq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
  margin-right: 0px;
}

.calendar_timeBox__1VqcH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
  margin-right: 10px;
  width: 25%;
}

.calendar_times__20jXq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.calendar_time__1q7TX {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.calendar_timeDescription__VCc0k {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.calendar_amountBox__3WzTR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  width: auto !important;
}

.calendar_priceBox__NmIEu {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
}

.calendar_historyPriceBox__3WH-N {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.calendar_newButton__2kt9y {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 30px;
  padding-right: 30px;
}

.calendar_meetButton__3Tehg {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_moreOptionsButton__1Zt1t {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #000 !important;
  margin-left: 0px;
  border: 1px solid #FFF !important;
}

.calendar_meetButton__3Tehg:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.calendar_emptyClass__1Jpwk {
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
}

.calendar_historyCalendar__iYsj0 {
  height: 650px;
  width: 95%;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #FFF;
  margin: 0px auto;
}

.calendar_cardDisplay__12tZu {
  display: flex;
  flex-direction: column;
}

.calendar_cardClassBox__-_I-9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_cardClassBoxCoupon__3r3nY {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 10px;
  padding-right: 0px;
}

.calendar_scheduleButton__3-320 {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #80b1b2 !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: 1px solid #80b1b2 !important;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_cancelButton__3Ksit {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #ea4f44 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #e62214 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #e62214;
  -moz-box-shadow: 5px 7px 1px -1px #e62214;
  padding-left: 20px;
  padding-right: 20px;
}

.calendar_scheduleButton__3-320:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.calendar_cancelButton__3Ksit:hover {
  transform: scale(1.03) !important;
  background-color: #ea4f44;
  color: #fff;
}

.calendar_horizontalLineDeleted__1fn8H {
  height: 1px;
  width: 85%;
  background: #393E3E;
}

@media only screen and (max-width: 1050px) {
  .calendar_dashboardUserMessage__2E3d2 {
    font-size: 1.2em;
    color: black;
  }

  .calendar_headerBox__2a1w0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .calendar_titleBox__2x1Hh {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .calendar_titlePictureBox__6br63 {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar_buttonBox__O9nuj {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .calendar_calendrier__phUfL {
    width: 100%;
    margin: 2em 0;
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 16px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 60vh !important;
  }

  .calendar_newButton__2kt9y {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .calendar_calendar__35lc7 {
    height: 60vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 16px !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FAFAFA;
  }

  .calendar_historyCalendar__iYsj0 {
    height: 73vh !important;
    width: 95% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FFF;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 16px !important;
  }

  .calendar_dateBox__3vcKe {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .calendar_horizontalLine__2evro {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .calendar_todayBox__1amh4 {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .calendar_emptyClass__1Jpwk {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    font-family: Rubik;
    color: #B9B9B9;
    text-align: center;
  }

  .calendar_cardDisplay__12tZu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    gap: 5px;
  }

  .calendar_singleCourse__3vrGN {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
  }

  .calendar_classeCard__250s6 {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .calendar_classeCardDeleted__3QNpQ {
    position: relative;
    width: 100%;
    height: 220px;
    background: #EBEBEB;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .calendar_classeCardCoupon__2sLOl {
    position: relative;
    width: 100%;
    height: 220px;
    background: #E7F8F8;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .calendar_cancelBox__ZY3eJ {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
    position: absolute;
    top: 40px;
    opacity: 1;
    text-align: center;
  }

  .calendar_historyClasseCard__zd5nX {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
  }

  .calendar_cardClassBox__-_I-9 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .calendar_cardClassBoxCoupon__3r3nY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .calendar_avatarNameBox__Tuw-A {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 90%;
    object-fit: contain;
  }

  .calendar_detailsBox__3PS3a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .calendar_historyDetailsBox__2NIPq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .calendar_timeBox__1VqcH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    width: auto !important;
  }

  .calendar_times__20jXq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .calendar_time__1q7TX {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .calendar_timeDescription__VCc0k {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .calendar_amountBox__3WzTR {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    font-size: 0.7em;
    margin-top: 5px;
    width: auto !important;
  }

  .calendar_priceBox__NmIEu {
    width: auto !important;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .calendar_historyPriceBox__3WH-N {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .calendar_meetButton__3Tehg {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .calendar_moreOptionsButton__1Zt1t {
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #000 !important;
    margin-left: 0px;
    border: 1px solid #FFF !important;
    margin-top: 10px;
    margin-left: 5px;
  }

  .calendar_meetButton__3Tehg:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .calendar_scheduleButton__3-320 {
    width: auto !important;
    height: 35px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #FFF !important;
    color: #80b1b2 !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: 1px solid #80b1b2 !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  .calendar_cancelButton__3Ksit {
    width: auto !important;
    height: 30px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #ea4f44 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #e62214 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #e62214;
    -moz-box-shadow: 5px 7px 1px -1px #e62214;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .calendar_horizontalLineDeleted__1fn8H {
    height: 150px;
    width: 1px;
    background: #393E3E;
  }
}
.contact___contact__3ghc7 {
  margin: 5em 2em;
}

.contact_main__contact__3p03P {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  margin-top: 1.5em;
  padding: 0 4em;
}

.contact_main__contact_mobile__32e6E {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  margin-top: 1.5em;
  padding: 0;
}

.contact___main_left__1DRNR,
.contact___right_main__XBSEr {
  width: 100%;

}

.contact___main_left__1DRNR {
  background: #FAFAFA;
  border-radius: 10px;
  padding: 2em;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
}

/* Paper */
.contact_paper__HwzdR {
  position: relative;
  width: 100%;
  min-width: 400px;
  height: 400px;
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
  margin: 1.5em 0;
  overflow: hidden;
}

.contact_paper_mobile__2S-o- {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 400px;
  background: #fafafa;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .3);
  margin: 1.5em 0;
  overflow: hidden;
}

.contact_paper__HwzdR:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  border-right: 3px solid #D44147;
  box-sizing: border-box;
}

.contact_paper_mobile__2S-o-:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  background: radial-gradient(#575450 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  border-right: 3px solid #D44147;
  box-sizing: border-box;
}

.contact_paper_content__VEDD1 {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 30px;
  left: 60px;
  background: linear-gradient(transparent, transparent 28px, #91D1D3 28px);
  background-size: 30px 30px;
}

.contact_paper_content__VEDD1 textarea {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  line-height: 30px;
  padding: 0 10px;
  border: 0;
  outline: 0;
  background: transparent;
  color: mediumblue;
  font-family: 'Handlee', cursive;
  font-weight: bold;
  font-size: 18px;
  box-sizing: border-box;
  z-index: 1;
}

.contact_field__cUDD3 {
  width: 95%;
  height: 40px;
  border: 1px solid var(--text-color);
  border-radius: 15px;
  margin-bottom: 1em;
  margin-top: 0.5em;
}

.contact___main_left__1DRNR label {
  font-size: 1.1em;
  margin-left: 5px;

}

.contact_field__cUDD3 input {
  width: 95%;
  height: 80%;
  border: none;
  outline: none;
  background: transparent;
  margin-left: 5px;
  margin-top: 5px;
  font-size: 0.95em !important;
}

.contact___submit__6cv6X {
  margin-top: 3em;
}
.course_cours__2cC1E {
  position: relative;
  margin: 4em 0;
  min-height: 70vh;
}

.course_teacher__maa__1MHCS {
  position: absolute;
  width: 350px;
  height: 600px;
  top: -12%;
  left: 0;
  background: url(/static/media/teacherMaa.068865cd.svg) no-repeat
    center center;
  background-size: cover;
  z-index: 1;
}

.course_button__3MFJc svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.course_form__container__AQ6TK {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.course_field__eHaxo {
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.course_field__eHaxo::placeholder {
  font-style: italic !important;
}

.course_select__3MxQ1 div {
  height: 50px !important;
  width: 600px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.course_select__3MxQ1 div span {
  padding-top: 10px !important;
  text-align: left;
  font-size: 1.2em;
}

.course_button__3MFJc {
  width: 250px !important;
  height: 47px !important;
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
}

.course_header__1Weap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.course_main__3i9xL {
  width: 100%;
  height: 100%;
  margin-top: 6em;
}

.course_center__1gNkJ {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.course_left__1ivAw .course_subtitle__3XUsE {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.course___add__input__button__1ikve {
  background: var(--text-color) !important;
  height: 45px !important;
  border: unset !important;
  border-radius: 50px !important;
  color: #fff !important;
  margin: 0 !important;
}

.course___input__type__3dFn5 {
  position: absolute;
  left: -100%;
}

.course___action__38XDb button button,
.course___action__38XDb button:first-child {
  max-width: 200px;
  min-width: 200px;
  margin-right: var(--mb-1);
}

.course___action__38XDb button button,
.course___action__38XDb button:last-child {
  max-width: 350px;
  min-width: 350px;
  margin-right: var(--mb-1);
}

.course___action__38XDb .course_appercue__1LJEH {
  max-width: 150px;
  min-width: 150px;
  /* box-shadow: unset !important;
  background: #fff !important; */

  /* color: var(--first-color) !important; */
  margin: 5px;
  border: 2px solid var(--first-color) !important;
}

.course_appercue__continute__dbE-3 {
  width: 100%;
  /* height: 100%; */
  height: 80vh;
  min-width: 600px;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 1.5em 2em;
}

.course_____left__1RTXg {
  width: 100%;
  height: 80vh;

  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2em;
}

.course_button__add_input__title__3SgMe {
  position: absolute;
  top: 0;
  right: -5px;
  width: 40px;
  height: 50px;
  top: 10px;
  /* border: 1px solid var(--first-color); */
  cursor: pointer;
}

.course_remove__input__2WSjO {
  position: absolute;
  left: -20px !important;
  top: 10px;
  width: 50px;
  cursor: pointer;
}

.course___field__nW7g3 {
  height: 40px !important;
  width: 100% !important;
}

.course___custom__ck_editor__2h9AV {
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 5px;
}

.course_add__chapters__cRjrk {
  position: relative;
  width: 100%;
  height: 400px;
  border: 1px dashed var(--first-color);
  margin-bottom: var(--mb-4);
}

.course_add__chapters__cRjrk h3 {
  margin: auto;
  color: var(--first-color);
  font-size: 2em;
  cursor: pointer;
  text-align: center;
}

.course___trigger__m5Qa4 {
  position: absolute;
  width: 256px;
  height: 100px;
  right: 0;
  bottom: 0;
  left: 55%;
  cursor: pointer;
  z-index: 1;
  transform: translate(-50%);
}

.course___add__input__1bdIz {
  height: 42px !important;
  border-radius: 50px !important;
  width: 260px !important;
  background: #707070 !important;
  color: #fff !important;

  border: unset !important;
}

.course___add__input__1bdIz:hover {
  color: #fff !important;
}

.course_appercue__continute__dbE-3 h3 {
  font-size: 18px;
  margin-bottom: var(--mb-2);
}

.course_section__content__172zq,
.course___big__title__content__3PJvS {
  width: 100%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.course_appercue__continute__content__2EbWI {
  margin-left: 5%;
}

.course___big__title__1EO8D {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.course_section__content__172zq {
  background: var(--first-color);
}

.course_section__content__172zq h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.course___custom__ck_editor__2h9AV {
  margin-bottom: var(--mb-4);
}

/*  */

.course___scaner__math__24TyF {
  width: 120px;
  height: 33px;
  border: 1px dashed #707070;
  display: flex;
  justify-content: center;
  align-items: center;
}

.course___upload__with__ck__content__x2uZR {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid #707070; */
  border-radius: 10px;
  padding: 0 15px;
}

.course___ck__scaner__2QzpU {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  right: 4%;
  bottom: 1em;
  cursor: pointer;
}

.course___ck__math__content__1P73j {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.course___ck__math__content__1P73j span {
  font-size: 13px;
}

.course___copy__3FCAG {
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  margin-right: 20px;
  overflow: hidden;
  overflow-y: scroll;
}

.createCours_cours__3vZSe {
  position: relative;
  margin: 3em 0;
}

.createCours_teacher__maa__A4TD9 {
  position: absolute;
  width: 350px;
  height: 400px;
  top: -2%;
  left: 0;
  background: url(/static/media/teacherMaa.068865cd.svg) no-repeat
    center center;
  background-size: contain;
  z-index: 1;
}

.createCours_button__1dHcT svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.createCours_form__container__1cBgN {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.createCours_field__2EOCI {
  height: 40px;
  padding-top: 3px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-3);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.createCours_select__1kRr5 {
  height: 40px;
  width: 500px;
  border-radius: 10px;
  padding-top: 4px;
  text-align: left;
  font-size: 1em;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.createCours_header__znn-9 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.createCours_main__18B3Q {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.createCours_center__28qMN {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.createCours_left__3mXeR .createCours_subtitle__XHXI9 {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.createCours___add__input__button__wqBHg {
  background: var(--text-color) !important;
  height: 45px !important;
  border: unset !important;
  border-radius: 50px !important;
  color: #fff !important;
  margin: 0 !important;
}

.createCours___input__type__SsRFe {
  position: absolute;
  left: -100%;
}

.createCours___action__32PS3 button button,
.createCours___action__32PS3 button:first-child {
  max-width: 150px;
  min-width: 150px;
  margin-right: var(--mb-1);
}

.createCours___action__32PS3 .createCours_appercue__2Dfgd {
  max-width: 150px;
  min-width: 150px;
  box-shadow: unset !important;
  background: #fff !important;
  color: var(--first-color) !important;
  border: 2px solid var(--first-color) !important;
}

.createCours_appercue__continute__2Alex {
  width: 100%;
  /* height: 100%; */
  height: 80vh;
  min-width: 600px;
  overflow-y: scroll;
  overflow-x: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 1.5em 2em;
}

.createCours_____left__1-2Qv {
  width: 100%;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2em;
}

.createCours_button__add_input__title__2HZK4 {
  position: absolute;
  top: 0;
  right: -5px;
  width: 40px;
  height: 50px;
  top: 10px;
  /* border: 1px solid var(--first-color); */
  cursor: pointer;
}

.createCours_remove__input__PeAQP {
  position: absolute;
  left: -20px !important;
  top: 10px;
  width: 50px;
  cursor: pointer;
}

.createCours___field__3yyX1 {
  height: 40px !important;
  width: 100% !important;
}

.createCours___custom__ck_editor__1QWeE {
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 5px;
}

.createCours_add__chapters__1FV1Z {
  position: relative;
  width: 100%;
  height: 400px;
  border: 1px dashed var(--first-color);
  margin-bottom: var(--mb-4);
}

.createCours_add__chapters__1FV1Z h3 {
  margin: auto;
  color: var(--first-color);
  font-size: 2em;
  cursor: pointer;
  text-align: center;
}

.createCours___trigger__3Adut {
  position: absolute;
  width: 256px;
  height: 100px;
  right: 0;
  bottom: 0;
  left: 55%;
  cursor: pointer;
  z-index: 1;
  transform: translate(-50%);
}

.createCours___add__input__3TbZ4 {
  height: 40px !important;
  border-radius: 50px !important;
  width: 260px !important;
  background: #707070 !important;
  color: #fff !important;

  border: unset !important;
}

.createCours___add__input__3TbZ4:hover {
  color: #fff !important;
}

.createCours_appercue__continute__2Alex h3 {
  font-size: 18px;
  margin-bottom: var(--mb-2);
}

.createCours_section__content__2mh1B,
.createCours___big__title__content__9d9xZ {
  width: 100%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.createCours_appercue__continute__content__13wMk {
  margin-left: 5%;
}

.createCours___big__title__3SaxW {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.createCours_section__content__2mh1B {
  background: var(--first-color);
}

.createCours_section__content__2mh1B h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

/* .__latext__content{
  width: 100% !important;
  max-width: 80% !important;
  border: 1px solid red !important;
} */

.createCours___custom__ck_editor__1QWeE {
  margin-bottom: var(--mb-4);
}

/* Button flotan */

.createCours_fixed_action_btn__1oBrV {
  position: fixed;
  margin: auto;
  width: 60px;
  height: 60px;
  display: flex;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  right: 4%;
  bottom: 1em;
  cursor: pointer;
}

.createCours___scaner__math__3MiCX {
  width: 120px;
  height: 33px;
  border: 1px dashed #707070;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createCours___upload__with__ck__content__Ve0Pv {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid #707070; */
  border-radius: 10px;
  padding: 0 15px;
}

.createCours___ck__scaner__3A44i {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  right: 4%;
  bottom: 1em;
  cursor: pointer;
}

.createCours___ck__math__content__1YsOw {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.createCours___ck__math__content__1YsOw span {
  font-size: 13px;
}

.createCours___copy__3h8P7 {
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */

  overflow: hidden;
  overflow-y: scroll;
}

.createCours___latext__content__pDQL1 * strong {
  font-weight: 600;
}

/*  */

.createCours___list__input__2xE30 {
  position: relative;
  border: 8px solid transparent;
}

.cardCours_content__22-NU {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.cardCours_user__group__1xQ9C {
  position: absolute;
  left: 3.5em;
  top: -2.3em;
}

.cardCours___matieres__xJ4Ou {
  width: 150px;
  height: 30px;
  background: #d5d5d5;
  border-radius: 50px;
  margin-top: var(--mb-1);
}

.cardCours___top__1gos0>div span {
  color: var(--text-color);
}

.cardCours___date__3jemo {
  font-size: 10px;
}

.cardCours___top__1gos0 h3 {
  width: 100%;
  max-width: 160px;
  font-family: var(--title-font);
  font-size: 1.2em;
  margin-top: var(--mb-2);
  text-transform: uppercase;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 75px;
  font-weight: 500;
}

.cardCours___row__RrkK5 {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 0.8em;
}

.cardCours___row__RrkK5>div span {
  color: var(--text-color);
  padding-left: 5px;
  font-size: 12px;
}

.cardCours___check__qFHi9 {
  position: relative;
  top: 2px;
}

.cardCours_button__pending__3JyQV {
  max-width: 160px;
  min-width: 160px;
  font-size: 10px !important;
  height: 30px !important;
  margin-bottom: 1em;
  background: #ea9610 !important;
  box-shadow: 3px 6px 1px -1px #c47b08 !important;
}

.cardCours_matiers__3JGVs {
  position: relative;
  left: -10px;
  width: 140px;
  height: 25px;
  background: #d5d5d5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 9.5px;
  color: var(--text-color);
  margin: var(--mb-1);
}

.cardCours_center__r2BBi {
  margin: 0 5px;
}

.cardCours___img__2j1Gz {
  position: relative;
  left: -5px;
  top: 0px;
}

.cardCours_appercue__continute__aD8x0 {
  width: 100%;
  /* height: 100%; */
  height: 80vh;
  min-width: 600px;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 1.5em 2em;
}

.cardCours_section__content__1XjG_,
.cardCours___big__title__content__3vuis {
  width: 100%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.cardCours_appercue__continute__content__1OWit {
  margin-left: 5%;
}

.cardCours___big__title__3SgcX {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.cardCours_section__content__1XjG_ {
  background: var(--first-color);
}

.cardCours_section__content__1XjG_ h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.cardCours___badge__2B5Ct {
  position: absolute;
  margin: auto;
  top: -30px;
  right: 10%;
  padding: 2px 10px;
  display: flex;
  border-radius: 15px;
}

.cardCours___badge__2B5Ct span {
  margin: auto;
  font-size: 9px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

.cardCours___title__link__1Q4ZI {
  color: var(--first-color);
  font-size: 13px;
  z-index: 1;
}

.cardCours___title__link__1Q4ZI:hover {
  color: var(--first-color);
}

.cardCours___latext__content__3wNsz * strong em,
.cardCours___latext__content__3wNsz * strong {
  font-weight: 600 !important;
}

/*  */
.cardCours_dropdown__29tWa {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.cardCours___down__3Kk-2 {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.cardCours___down__3Kk-2 *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.cardCours___down__3Kk-2 {
  color: var(--text-color);
}

@media only screen and (max-width: 767px) {

  .cardCours_user__group__1xQ9C {
    position: relative;
    left: -5em;
    top: -2em;
  }

  .cardCours___badge__2B5Ct {
    position: relative;
    top: -4em;
    right: -5em;
    padding: 2px 10px;
    display: flex;
    border-radius: 15px;
  }

  .cardCours___top__1gos0 {
    position: relative;
    top: -3em;
  }

  .cardCours___bottom__1fBXq {
    position: relative;
    top: 1em;
    display: flex;
    flex-direction: row;
  }

}


/* New Css Styles */

.cardCours_card__1cQBt {
  height: 285px;
  width: 100%;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cardCours_userGroup__3gSoU {
  height: 30px !important;
  width: 30px !important;
  border: 1.5px solid #FFF;
  border-radius: 50%;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.cardCours_publisherName__1Y2xv {
  color: #393E3E;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  margin-top: 5px;
  line-height: 18px;
}

.cardCours_rateBox__zGepQ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.cardCours_rateBox__zGepQ span {
  font-size: 12px;
  color: #707070;
  font-family: Rubik;
  font-weight: 500;
}

.cardCours_courseTitle__3xKHO {
  height: auto;
  width: 200px;
  font-size: 15px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  text-align: center !important;
  line-height: 18px !important;
  margin: 5px auto;
}

.cardCours_buttonsRow__C6bZV {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.cardCours_ribbon__3IHZF {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  top: 0px;
  right: -95px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 0px;
  height: 22px;
}

.cardCours_ribbon__3IHZF:after {
  height: 22px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 10px 14px;
  border-color: transparent transparent transparent white;
}


@media only screen and (max-width: 403px) {
  .cardCours_card__1cQBt {
    height: 160px !important;
    width: auto !important;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1.5px solid #707070;
    border-right: 1.5px solid #707070;
  }
}

@media only screen and (min-width: 404px) and (max-width: 1050px) {
  .cardCours_card__1cQBt {
    height: 190px !important;
    width: 100%;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1050px) {
  .cardCours_userGroup__3gSoU {
    height: 30px;
    width: 30px;
    border: 1.5px solid #FFF;
    border-radius: 50%;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .cardCours_publisherName__1Y2xv {
    color: #393E3E;
    font-size: 0.5em !important;
    font-family: Rubik;
    font-weight: 500;
    margin-top: 0px;
    line-height: 10px;
  }

  .cardCours_rateBox__zGepQ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.45em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .cardCours_rateBox__zGepQ span {
    font-size: 0.8em;
    color: #707070;
    font-family: Rubik;
    font-weight: 500;
  }

  .cardCours_courseTitle__3xKHO {
    height: auto;
    width: 100%;
    font-size: 0.65em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 10px !important;
    overflow: clip;
    margin: 5px auto;
  }

  .cardCours_buttonsRow__C6bZV {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }

  .cardCours_ribbon__3IHZF {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 0px;
    right: -50px;
    padding-left: 15px;
    padding-right: 10px;
    color: white;
    font-family: "Poppins";
    font-size: 0.6em;
    font-weight: 400;
    text-align: center;
    border-radius: 0px;
    height: 15px;
  }

  .cardCours_ribbon__3IHZF:after {
    height: 15px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 6px 9px;
    border-color: transparent transparent transparent white;
  }

  .cardCours_dropdown__29tWa {
    width: 100px !important;
    height: 20px !important;
    background: var(--first-color) !important;
    border: unset !important;
    border-radius: 50px !important;
    box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    color: #fff !important;
    text-transform: capitalize;
    font-weight: 500 !important;
    font-size: 0.75em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .cardCours___date__3jemo {
    font-size: 0.65em;
  }
}
.style_tabs__kBqxI {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.style_tab__UaW8s {
  width: 180px;
  height: 50px;
  margin: 0 var(--mb-1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  background: #FAFAFA;
  border: 1px solid #B9B9B9;
  border-radius: 24px;
  opacity: 1;
  color: #868686;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.style_tab__UaW8s:hover {
  background: var(--first-color);
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.style_tabActive__1G4qZ {
  background: #FFFFFF;
  box-shadow: 4px 8px 16px #7FB1B234;
  border: 1px solid #7FB1B2;
  opacity: 1;
  color: #7FB1B2;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

@media only screen and (max-width: 1050px) {
  .style_tabs__kBqxI {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }

  .style_tab__UaW8s {
    width: 100px;
    height: 35px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    background: #FAFAFA;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    opacity: 1;
    color: #868686;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .style_tab__UaW8s:hover {
    background: var(--first-color);
    color: #FFF;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .style_tabActive__1G4qZ {
    background: #FFFFFF;
    box-shadow: 4px 8px 16px #7FB1B234;
    border: 1px solid #7FB1B2;
    opacity: 1;
    color: #7FB1B2;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }
}
.course_header__3beJE {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0.5em 0;
}

.course_main__ujdyU {
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.course_center__2bIuk {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.course_left__oRrxX .course_subtitle__2j21Q {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.course_justify__center__3-PM5 {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.course_filter__231dC {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin: var(--mb-3) 0;
  padding: 0 var(--mb-4);
}

.course_filed__search__1C4OD {
  width: 250px;
  height: 40px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
}

.course_filed__search__1C4OD * input::placeholder {
  color: var(--text-color);
  font-size: 14.5px;
}

.course_filed__search__1C4OD * button {
  background: #fff;
  border: none;
  font-size: 0.5em;
}

.course_select__ePbsp {
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
}

.course_select__ePbsp {
  padding-top: 3px;
  text-align: left;
}

.course_row__2Cv0G {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  flex-wrap: wrap;
}

.course___reset__btn__2PS2_ {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.course___submit__hidden__2q_Yr {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}

@media only screen and (max-width: 767px) {

  .course_header__3beJE {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .course_center__2bIuk {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }

  .course_filed__search__1C4OD {
    width: 65%;
    height: 40px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
  }

  .course_select__ePbsp {
    height: 40px;
    width: 160px;
    border-radius: 10px;
    border: 1px solid var(--text-color);
  }

  .course___submit__hidden__2q_Yr {
    position: relative !important;
    top: 0px !important;
    margin: 0px auto !important;
  }

  .course___reset__btn__2PS2_ {
    position: relative !important;
    right: 0% !important;
    bottom: 0% !important;
    top: 10px !important;
  }

}

/* New Css Styles */

.course_headerBox__2MQpJ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.course_titleBox__MEhpH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.course_titlePictureBox__3WuHc {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.course_buttonBox__2X3zZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.course_newButton__9ZR7Z {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.course_searchBox__3qA5H {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.course_selectBox__2qUgo {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.course_separation__1qpiv {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.course_inputBox__jO7l8 {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.course_filedSearch__23JtR {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.course_myCoursesBox__2QjB9 {
  height: 40px;
  width: 160px;
  background: #60CD95;
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #FFF;
}

.course_myCoursesBoxActive__3G3yv {
  height: 40px;
  width: 160px;
  background: #FFF;
  color: #707070;
  opacity: 1;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #60CD95;
}

.course_coursesBox__uBJv7 {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
}

@media only screen and (max-width: 1050px) {
  .course_headerBox__2MQpJ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .course_titleBox__MEhpH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .course_titlePictureBox__3WuHc {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .course_buttonBox__2X3zZ {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .course_newButton__9ZR7Z {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .course_coursesBox__uBJv7 {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 0%;
    align-items: center;
    justify-content: center;
  }

  .course_myCoursesBox__2QjB9 {
    height: 32px;
    width: 100px;
    background: #60CD95;
    color: #FFF !important;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #FFF;
  }

  .course_myCoursesBoxActive__3G3yv {
    height: 32px;
    width: 100px;
    background: #FFF !important;
    color: #707070 !important;
    opacity: 1;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #60CD95;
  }

  .course_searchBox__3qA5H {
    width: 100% !important;
    height: 35px;
    background: #FFF;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 0px solid #BFBCBC;
    border-radius: 31px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .course_selectBox__2qUgo {
    width: 0%;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 12px;
    text-align: left;
    opacity: 1;
  }

  .course_separation__1qpiv {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 0px;
    display: none;
  }

  .course_inputBox__jO7l8 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 0px;
  }

  .course_filedSearch__23JtR {
    width: 100%;
    height: 35px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .course_searchField__1N0WI::placeholder {
    font-size: 0.75em !important;
  }
}
.exercices_header__3Eie_ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0.5em 0;
}

.exercices_header_mobile__F6qxD {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0.5em 0;
}

.exercices_main__2k2FX {
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.exercices_center__2qgtW {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.exercices_left__-KS29 .exercices_subtitle__2Bkfv {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.exercices_justify__center__HjuEK {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.exercices_filter__2UeOC {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin: var(--mb-3) 0;
  padding: 0 var(--mb-4);
}

.exercices_filter_mobile__3bmvR {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin: var(--mb-3) 0;
  padding: 0 var(--mb-4);
}

.exercices_filed__search__cxsWa {
  width: 250px;
  height: 40px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
}

.exercices_filed__search__cxsWa * input::placeholder {
  color: var(--text-color);
  font-size: 14.5px;
}

.exercices_filed__search__cxsWa * button {
  background: #fff;
  border: none;
  font-size: 0.5em;
}

.exercices_select__E0vLm {
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  padding-top: 3px;
  text-align: left;
}

.exercices_select_mobile__3gl_m {
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  padding-top: 3px;
  text-align: left;
  margin-top: 5px;
}

.exercices_row__1gpZz {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  flex-wrap: wrap;
}

.exercices___reset__btn__2BTc- {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.exercices___submit__hidden__39mSq {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}

/* New Css Styles */

.exercices_headerBox__3-lPg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.exercices_titleBox__1ZOtm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.exercices_titlePictureBox__yjEC4 {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exercices_buttonBox__2ODG3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.exercices_newButton__3220S {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.exercices_searchBox__3nmvp {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.exercices_selectBox__KRlD3 {
  width: 12%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.exercices_separation__2L22d {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.exercices_inputBox__ayqfd {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.exercices_filedSearch__1Du9L {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.exercices_myCoursesBox__1uPxL {
  height: 40px;
  width: 160px;
  background: #60CD95;
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #FFF;
}

.exercices_myCoursesBoxActive__2L0-e {
  height: 40px;
  width: 160px;
  background: #FFF;
  color: #707070;
  opacity: 1;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #60CD95;
}

.exercices_exerciseBox__6JOHw {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
}

.exercices_addExerciseButton__sfN3- {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 1050px) {
  .exercices_headerBox__3-lPg {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .exercices_titleBox__1ZOtm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .exercices_titlePictureBox__yjEC4 {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .exercices_buttonBox__2ODG3 {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .exercices_newButton__3220S {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .exercices_exerciseBox__6JOHw {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 0%;
    align-items: start;
    justify-content: start;
  }

  .exercices_myCoursesBox__1uPxL {
    height: 32px;
    width: 120px;
    background: #60CD95;
    color: #FFF !important;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #FFF;
  }

  .exercices_myCoursesBoxActive__2L0-e {
    height: 32px;
    width: 120px;
    background: #FFF !important;
    color: #707070 !important;
    opacity: 1;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #60CD95;
  }

  .exercices_searchBox__3nmvp {
    width: 100% !important;
    height: 35px;
    background: #FFF;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 0px solid #BFBCBC;
    border-radius: 31px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .exercices_selectBox__KRlD3 {
    width: 0%;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 12px;
    text-align: left;
    opacity: 1;
  }

  .exercices_separation__2L22d {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 0px;
    display: none;
  }

  .exercices_inputBox__ayqfd {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 0px;
  }

  .exercices_filedSearch__1Du9L {
    width: 100%;
    height: 35px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .exercices_searchField__1hgkT::placeholder {
    font-size: 0.75em !important;
  }

  .exercices_addExerciseButton__sfN3- {
    width: 180px !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.cardExo_content__6Fp1S {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin-top: var(--mb-1); */
  padding-top: var(--mb-2);
  padding-bottom: var(--mb-3);
}

.cardExo___matieres__2bITB {
  width: 150px;
  height: 30px;
  background: #d5d5d5;
  border-radius: 50px;
  margin-top: var(--mb-1);
}

.cardExo___top__2Csil>div span {
  color: var(--text-color);
}

.cardExo___date__hbwd8 {
  font-size: 10px;
  margin-top: 10px;
}

.cardExo___top__2Csil h3 {
  width: 100%;
  max-width: 160px;
  height: 50px;
  overflow-y: scroll;
  font-family: var(--title-font);
  font-size: 1.1em;
  margin-top: var(--mb-2);
  text-transform: uppercase;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  font-weight: 500;
}

.cardExo___top__2Csil .cardExo___chapter__2NQzy {
  width: 100%;
  color: #707070;
  max-width: 170px;
  font-size: 11px;
  margin-top: 5px;
  text-transform: uppercase;
}


.cardExo___exo__length__tuO86 {
  color: #333333 !important;
  font-weight: 500;
  font-size: 13px;
}

.cardExo___row__2oGQx {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 15px;
}

.cardExo___row__2oGQx>div span {
  color: var(--text-color);
  padding-left: 5px;
  font-size: 15px;
}

.cardExo___check__dYZba {
  position: relative;
  top: 2px;
}

.cardExo_button__pending__2cgfq {
  max-width: 160px;
  min-width: 160px;
  font-size: 10px !important;
  height: 30px !important;
  margin: 1em 0;
  background: #ea9610 !important;
  box-shadow: 3px 6px 1px -1px #c47b08 !important;
}

/* Select */



.cardExo_matiers__341O3 {
  position: relative;
  left: -10px;
  width: 140px;
  height: 25px;
  background: #d5d5d5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
  margin: var(--mb-1);
}

.cardExo_center__3Z4w9 {
  margin: 0 5px;
}

.cardExo___img__14dcf {
  position: relative;
  left: -5px;
  top: 0px;
}

.cardExo___exo__3nzMn {
  max-width: 150px !important;
  min-width: 150px !important;
  height: 30px !important;
  font-size: 0.8em !important;
}

.cardExo___badge__3AABX {
  position: absolute;
  margin: auto;
  top: -7px;
  right: 10%;
  padding: 2px 10px;
  display: flex;
  border-radius: 15px;
}

.cardExo___badge__3AABX span {
  margin: auto;
  font-size: 9px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}



/*  */
.cardExo_dropdown__1s6Ts {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.cardExo___down__3S238 {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}


.cardExo___down__3S238 *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.cardExo___down__3S238 {
  color: var(--text-color);
}

/* New Css Styles */

.cardExo_card__2I56b {
  height: 285px;
  width: 100%;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cardExo_userGroup__3K10W {
  height: 60px;
  width: 60px;
  border: 1.5px solid #FFF;
  border-radius: 50%;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.cardExo_publisherName__1oU6G {
  color: #393E3E;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  margin-top: 5px;
  line-height: 18px;
}

.cardExo_rateBox__1UStS {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.cardExo_rateBox__1UStS span {
  font-size: 12px;
  color: #707070;
  font-family: Rubik;
  font-weight: 500;
}

.cardExo_courseTitle__38TYQ {
  height: auto;
  width: 70%;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  line-height: 14px;
  overflow: clip;
}

.cardExo_courseTitle__38TYQ span {
  margin-top: 10px;
}

.cardExo_chapter__3xdVz {
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  line-height: 14px;
  overflow: clip;
}

.cardExo_buttonsRow__m4VNC {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.cardExo_ribbon__14j78 {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  top: 0px;
  right: -95px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 0px;
  height: 22px;
}

.cardExo_ribbon__14j78:after {
  height: 22px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 10px 14px;
  border-color: transparent transparent transparent white;
}

@media only screen and (max-width: 403px) {
  .cardExo_card__2I56b {
    height: 180px !important;
    width: 100%;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1.5px solid #707070;
    border-right: 1.5px solid #707070;
  }
}

@media only screen and (min-width: 404px) and (max-width: 1050px) {
  .cardExo_card__2I56b {
    height: 190px !important;
    width: 100%;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1050px) {
  .cardExo_userGroup__3K10W {
    height: 35px;
    width: 35px;
    border: 1.5px solid #FFF;
    border-radius: 50%;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .cardExo_publisherName__1oU6G {
    color: #393E3E;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 500;
    margin-top: 10px;
    line-height: 10px;
  }

  .cardExo_rateBox__1UStS {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.65em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .cardExo_rateBox__1UStS span {
    font-size: 0.8em;
    color: #707070;
    font-family: Rubik;
    font-weight: 500;
  }

  .cardExo_courseTitle__38TYQ {
    height: auto;
    width: 100%;
    font-size: 0.65em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 10px;
    overflow: clip;
  }

  .cardExo_buttonsRow__m4VNC {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }

  .cardExo_ribbon__14j78 {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 0px;
    right: -50px;
    padding-left: 15px;
    padding-right: 10px;
    color: white;
    font-family: "Poppins";
    font-size: 0.6em;
    font-weight: 400;
    text-align: center;
    border-radius: 0px;
    height: 15px;
  }

  .cardExo_ribbon__14j78:after {
    height: 15px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 6px 9px;
    border-color: transparent transparent transparent white;
  }

  .cardExo_dropdown__1s6Ts {
    width: 100px !important;
    height: 20px !important;
    background: var(--first-color) !important;
    border: unset !important;
    border-radius: 50px !important;
    box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    color: #fff !important;
    text-transform: capitalize;
    font-weight: 500 !important;
    font-size: 0.75em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .cardExo___date__hbwd8 {
    font-size: 0.65em;
  }
}
.faq_main__1IIT2 {
  width: 100%;
  min-height: 800px;
  background: #FAFAFA;
  overflow-y: hidden;
  scrollbar-width: 2px;
  border-radius: 24px;
  margin-top: 0em;
  margin-bottom: 200px;
  padding-top: 3em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  border: 1px solid #707070;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  z-index: 1;
}

::-webkit-scrollbar {
  height: 4px;
  margin-left: 2.5em;
}

.faq_title__1eMpk {
  font-family: "DM Serif Display";
  font-weight: bold;
  font-size: 40px;
  line-height: 40px;
  margin-top: 10px;
  z-index: 1;
}

.faq_actorBox__3StI9 {
  text-align: center;
  margin-bottom: 20px;
  border: 1.5px solid #8AB0B1;
  border-radius: 28px;
  background-color: #FFF;
  height: 100px;
  width: 180px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}

.faq_actorBox__3StI9:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
  color: #8AB0B1;
}

.faq_actorBoxTitle__1kf3L {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 13px;
  line-height: 30px;
}

.faq_actorBoxTitle__1kf3L:hover {
  color: #8AB0B1;
}

.faq_question__MRNtp {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  padding-left: 20px;
  margin-top: 15px;
}

.faq_answer__CBvI5 {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-right: 40px;
}

.faq_likeQuestion__RrvO7 {
  color: #393E3E;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-right: 40px;
}

.faq_collaps__1ckF4 {
  width: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.faq___header__panel__3GXUw {
  background: #fff;
  border-radius: 15px;
  margin-bottom: 2em;
  min-height: 70px;
}

.faq_panel__3FHAg {
  height: 100%;
  min-height: 100px;

  background: #fff;
  padding: 1em;
  border-radius: 15px;
}


.faq___header__panel__3GXUw h3 {
  font-size: 1.3em;
  font-weight: 500;
  color: var(--texte-font);
}

.faq___header__panel__3GXUw * div * svg {
  display: none;
}
.quiz_header__3BOuH {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0.5em 0;
}

.quiz_main__2iCHD {
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.quiz_center__2XV3_ {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.quiz_left__15fu2 .quiz_subtitle__2k4MI {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.quiz_justify__center__1kRKs {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.quiz_filter__3YiVt {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin: var(--mb-3) 0;
  padding: 0 var(--mb-4);
}

.quiz_filter_mobile__i6C1s {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin: var(--mb-3) 0;
  padding: 0 var(--mb-4);
}

.quiz_filed__search__hWZnU {
  width: 250px;
  height: 40px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
}

.quiz_filed__search__hWZnU * input::placeholder {
  color: var(--text-color);
  font-size: 14.5px;
}

.quiz_filed__search__hWZnU * button {
  background: #fff;
  border: none;
  font-size: 0.5em;
}

.quiz_select__2j-qb {
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  padding-top: 3px;
  text-align: left;
}

.quiz_select_mobile__1TmuI {
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  padding-top: 3px;
  text-align: left;
  margin-top: 5px;
}

.quiz_row__Gg9UP {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  flex-wrap: wrap;
}

.quiz___reset__btn__1NHPj {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.quiz___submit__hidden__sEvBV {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}

@media only screen and (max-width: 767px) {

  .quiz_quizCard__TVWdG {
    margin-top: 20px;
  }

  .quiz_select__2j-qb {
    height: 40px;
    width: 100px;
    border-radius: 10px;
    border: 1px solid var(--text-color);
    padding-top: 3px;
    text-align: left;
  }

}

/* New Css Styles */

.quiz_headerBox__3JQ8S {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.quiz_titleBox__2PE0l {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.quiz_titlePictureBox__3vig7 {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz_buttonBox__3p4yG {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.quiz_newButton__1zkMF {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.quiz_searchBox__2lSKl {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.quiz_selectBox__3ZDsu {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.quiz_separation__VD1T5 {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.quiz_inputBox__1G-bP {
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.quiz_filedSearch__2eyvy {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.quiz_myCoursesBox__1CNjL {
  height: 40px;
  width: 160px;
  background: #60CD95;
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #FFF;
}

.quiz_myCoursesBoxActive__2ta1R {
  height: 40px;
  width: 160px;
  background: #FFF;
  color: #707070;
  opacity: 1;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #60CD95;
}

.quiz_quizBox__3Kgd7 {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
}

@media only screen and (max-width: 1050px) {
  .quiz_headerBox__3JQ8S {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .quiz_titleBox__2PE0l {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .quiz_titlePictureBox__3vig7 {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quiz_buttonBox__3p4yG {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .quiz_newButton__1zkMF {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .quiz_quizBox__3Kgd7 {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 0%;
    align-items: start;
    justify-content: start;
  }

  .quiz_myCoursesBox__1CNjL {
    height: 32px;
    width: 100px;
    background: #60CD95;
    color: #FFF !important;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #FFF;
  }

  .quiz_myCoursesBoxActive__2ta1R {
    height: 32px;
    width: 100px;
    background: #FFF !important;
    color: #707070 !important;
    opacity: 1;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #60CD95;
  }

  .quiz_searchBox__2lSKl {
    width: 100% !important;
    height: 35px;
    background: #FFF;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 0px solid #BFBCBC;
    border-radius: 31px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .quiz_selectBox__3ZDsu {
    width: 0%;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 12px;
    text-align: left;
    opacity: 1;
  }

  .quiz_separation__VD1T5 {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 0px;
    display: none;
  }

  .quiz_inputBox__1G-bP {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 0px;
  }

  .quiz_filedSearch__2eyvy {
    width: 100%;
    height: 35px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .quiz_searchField__17N3F::placeholder {
    font-size: 0.75em !important;
  }
}
.style_Quiz__2ETvS {
  margin-bottom: 3em;
}

.style_Card__17olj {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 600px;
  height: 420px;

  background-color: var(--fafafa);
  border-radius: 10px;
}

.style_mainX__3lUFS {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.style_apercuQuiz__GtXIx {
  font-size: 16px;
}

.style_absolute__1Xsm_ {
  position: absolute !important;
}

.style_relative__1f4LY {
  width: 100%;
  position: relative !important;
}

.style_question__2yPhl {
  font-size: 17px;
  font-weight: 500;
}

.style_quiz__22eth {
  margin: 5em 0;
}

.style_quiz__content__2yFBL {
  width: 100%;
  background: #fafafa;
  padding: 5%;
}

.style_header__3D60v {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.style_center__1D-oZ {
  flex: 2 1;
  text-align: center;
}

.style_center__1D-oZ span {
  display: block;
  font-size: 1.2em;
}

.style_main__2jMZG {
  margin-top: 2em;
}

.style_main__2jMZG h3 {
  font-size: 1.3em;
  margin-left: 2%;
  color: var(--text-color);
  margin-bottom: var(--mb-6);
  /* #CBCACD */
}

.style_card__1l-CT {
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1px solid #cbcacd;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-3);
}

.style___label__content__2m5nX {
  position: relative;
  width: 100%;
}

.style___black__3xD3g {
  position: absolute;
  right: 5%;
  top: -10px;
}

.style_label__1oWbL {
  position: absolute;
  width: 30px;
  font-size: 16px;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 70px;
  white-space: nowrap;
  color: #555;
}

.cardQuizz_content__wJg5Z {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: var(--mb-3);
  padding-bottom: var(--mb-6);
}

.cardQuizz___matieres__2WXcn {
  width: 150px;
  height: 30px;
  background: #d5d5d5;
  border-radius: 50px;
  margin-top: var(--mb-1);
}

.cardQuizz___top__1EnvI>div span {
  color: var(--text-color);

}

.cardQuizz___date__27Dqe {
  font-size: 10px;
}

.cardQuizz___top__1EnvI h3 {
  width: 100%;
  max-width: 170px;
  font-family: var(--title-font);
  font-size: 1.110em;
  margin-top: var(--mb-2);
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}

.cardQuizz___chapter__dmweh {
  width: 100%;
  color: #707070;
  max-width: 170px;
  font-size: 11px;
  margin-top: 5px;
}

.cardQuizz___row__zI4YJ {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  margin-bottom: 0.8em;
}

.cardQuizz___row__zI4YJ>div span {
  color: var(--text-color);
  padding-left: 5px;
  font-size: 12px;
}

.cardQuizz___check__4bDAa {
  position: relative;
  top: 2px;
}

.cardQuizz_matiers__Mksgv {
  position: relative;
  left: -10px;
  width: 140px;
  height: 25px;
  background: #d5d5d5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 9.5px;
  color: var(--text-color);
  margin: var(--mb-1);
}

.cardQuizz_center__367qB {
  margin: 0 5px;
}

.cardQuizz___img__3q677 {
  position: relative;
  left: -5px;
  top: 0px;
}

/* Select */
.cardQuizz_select__27TDG div {
  height: 30px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  /* margin-top: 15px; */
}

.cardQuizz_select__draft__2S5ub div {
  background: #889d83 !important;
  box-shadow: 3px 6px 1px -1px #064205 !important;
}

.cardQuizz_select__27TDG div span {
  position: relative;
  left: 10px;
  padding-top: 1px !important;
  font-size: 11px;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
}

.cardQuizz_select__27TDG * {
  color: #fff !important;
}

.cardQuizz_button__pending__1qxEE {
  max-width: 160px;
  min-width: 160px;
  font-size: 10px !important;
  height: 30px !important;
  margin: 1em 0;
  background: #ea9610 !important;
  box-shadow: 3px 6px 1px -1px #c47b08 !important;
}

.cardQuizz_select__pending__3JjKF div {
  background: #ea9610 !important;
  box-shadow: 3px 6px 1px -1px #c47b08 !important;
}


.cardQuizz___badge__1_afF {
  position: absolute;
  margin: auto;
  top: -30px;
  right: 10%;
  padding: 2px 10px;
  display: flex;
  border-radius: 15px;
}

.cardQuizz___badge__1_afF span {
  margin: auto;
  font-size: 9px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}


/*  */
.cardQuizz_dropdown__2lM5r {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.cardQuizz___down__1whQg {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}


.cardQuizz___down__1whQg *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.cardQuizz___down__1whQg {
  color: var(--text-color);
}

@media only screen and (max-width: 767px) {

  .cardQuizz_quizCard__1H67_ {
    margin-top: 20px;
  }

  .cardQuizz___badge__1_afF {
    position: absolute;
    top: -30px;
    right: 20%;
  }

}

/* New Css Styles */

.cardQuizz_card__3szFe {
  height: 285px;
  width: 100%;
  background: #FAFAFA;
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cardQuizz_userGroup__qono2 {
  height: 30px !important;
  width: 30px !important;
  border: 1.5px solid #FFF;
  border-radius: 50%;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.cardQuizz_publisherName__XeNZk {
  color: #393E3E;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  margin-top: 0px;
  line-height: 18px;
}

.cardQuizz_rateBox__3jDRv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.cardQuizz_rateBox__3jDRv span {
  font-size: 12px;
  color: #707070;
  font-family: Rubik;
  font-weight: 500;
}

.cardQuizz_courseTitle__CCfmV {
  height: auto;
  width: 200px;
  font-size: 15px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  text-align: center !important;
  line-height: 18px !important;
  margin: 5px auto;
}

.cardQuizz_chapter__2lt4W {
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  line-height: 14px;
  overflow: clip;
}

.cardQuizz_buttonsRow__3rhKf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.cardQuizz_ribbon__3gcMd {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  top: 0px;
  right: -95px;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 0px;
  height: 22px;
}

.cardQuizz_ribbon__3gcMd:after {
  height: 22px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 0 10px 14px;
  border-color: transparent transparent transparent white;
}

@media only screen and (max-width: 403px) {
  .cardQuizz_card__3szFe {
    height: 160px !important;
    width: auto !important;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1.5px solid #707070;
    border-right: 1.5px solid #707070;
  }
}

@media only screen and (min-width: 404px) and (max-width: 1050px) {
  .cardQuizz_card__3szFe {
    height: 190px !important;
    width: 100%;
    background: #FAFAFA;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1050px) {
  .cardQuizz_userGroup__qono2 {
    height: 30px;
    width: 30px;
    border: 1.5px solid #FFF;
    border-radius: 50%;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  .cardQuizz_publisherName__XeNZk {
    color: #393E3E;
    font-size: 0.5em !important;
    font-family: Rubik;
    font-weight: 500;
    margin-top: 0px !important;
    line-height: 10px;
  }

  .cardQuizz_rateBox__3jDRv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.45em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .cardQuizz_rateBox__3jDRv span {
    font-size: 0.8em;
    color: #707070;
    font-family: Rubik;
    font-weight: 500;
  }

  .cardQuizz_courseTitle__CCfmV {
    height: auto;
    width: 100%;
    font-size: 0.65em;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 10px !important;
    overflow: clip;
    margin: 5px auto;
  }

  .cardQuizz_buttonsRow__3rhKf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    width: 100%;
  }

  .cardQuizz_ribbon__3gcMd {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 0px;
    right: -50px;
    padding-left: 15px;
    padding-right: 10px;
    color: white;
    font-family: "Poppins";
    font-size: 0.6em;
    font-weight: 400;
    text-align: center;
    border-radius: 0px;
    height: 15px;
  }

  .cardQuizz_ribbon__3gcMd:after {
    height: 15px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 6px 9px;
    border-color: transparent transparent transparent white;
  }

  .cardQuizz_dropdown__2lM5r {
    width: 100px !important;
    height: 20px !important;
    background: var(--first-color) !important;
    border: unset !important;
    border-radius: 50px !important;
    box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    color: #fff !important;
    text-transform: capitalize;
    font-weight: 500 !important;
    font-size: 0.75em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .cardQuizz___date__27Dqe {
    font-size: 0.65em;
  }
}
.style_flexGap__1W2L7 {
  display: inline-flex;
  flex-wrap: wrap;
}
.style_flexGap__1W2L7 > div {
  margin: 6px;
}

.room_header__1i3w0 {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center;
  margin: 5em 0 1em 0;
}

.room_left__LDmuE {
  width: 25% !important;
}

.room_right__2Uuza {
  width: 25% !important;
  margin-left: 4%;
}

.room___btn__challenge__QrOqK {
  width: 90% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.room___btn__challengeX__1v1RY {
  opacity: 0.75;
  background-color: #7d7d7d74 !important;
  color: var(--text-color) !important;
  box-shadow: 3px 6px 1px -1px var(--text-color) !important;
  cursor: not-allowed !important;
}

.room_center__mNzOp {
  width: 45% !important;
  /* flex: 1 !important; */
  height: 2px;
  border: 1px dashed var(--text-color);
  /* margin: 0 var(--mb-4); */
}

.room_left__LDmuE .room_subtitle__XOxGT {
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.room_main__2SssN {
  width: 100%;
  height: 100%;
  margin-top: 0em;
}

.room_main__row__3xhRL {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: var(--mb-2);
  gap: var(--mb-2);
}

/* .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .main__left,
.main__right {
  width: 70%;
  height: 25%;
}
 */

.room_main__left__3QLBi {
  /* flex: 2; */
  width: 70%;
}

.room_main__right__3izHI {
  /* flex: 0.8; */
  margin-top: 1.5em;
  width: 27%;
}

.room_mainLeftContentEmpty__2_Y-Y {
  width: 100% !important;
}

.room_main__left__content__2OmeV {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  grid-gap: 40px;
  gap: 40px;
  margin-bottom: var(--mb-6);
}

.room_graphe__right__3katn,
.room_graphe__left__1FmO2 {
  height: 400px;
  grid-gap: 20px;
  gap: 20px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.room_graphe__left__header__7dcGG {
  margin-left: var(--mb-2);
  padding-top: var(--mb-2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.room___garphe__right__header__2R0bj {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.4em;
  margin-top: 1.5em;
}

.room_select__IBnrD {
  position: relative;
  height: 45px;
  width: 100%;
  max-width: 320px;
  padding-top: 5px;
  text-align: left;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.room___first__card__3AU6T,
.room___second__card__127V0 {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background: #fafafa;
  border: 2px solid #dbdbdb;
}

.room___first__card__3AU6T h4 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: var(--text-color);
  margin-top: var(--mb-1);
}

.room___second__card__127V0 {
  margin-top: var(--mb-3);
  height: 255px;
}

.room___second__card__127V0 span {
  text-transform: uppercase;
  font-size: 1em;
}

.room___second__card__127V0 .room_code__15pu0 {
  width: 150px;
  height: 40px;
  background: #fff;
  border: 1px dashed var(--text-color);
  display: flex;
  margin: var(--mb-3) auto;
  border-radius: 50px;
}

.room_code__15pu0 span {
  margin: auto;
  font-size: 1.2em;
  font-weight: 500;
}

.room___content__btn__3qiwd button:first-child {
  margin-bottom: var(--mb-2);
}

.room___btn__JUe1u {
  min-width: 200px !important;
  max-width: 200px !important;
  padding-top: 10px;
  height: 38px !important;
}

.room_array__conent__1N9zE {
  width: 100%;
  margin-top: var(--mb-4);
  max-height: 700px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-2) var(--mb-3);
}

/* Array */

.room_array__conent__header__1rrFU {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--mb-2);
}

.room_filed__search__jJuVZ {
  width: 300px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.room_array__conent__header__1rrFU * input,
.room_array__conent__header__1rrFU * button {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

.room_array__conent__header__1rrFU * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.room_array__conent__header__1rrFU * input::placeholder {
  color: var(--text-color);
}

.room___array__NosAf {
  width: 100% !important;
  max-height: 500px;
  margin-top: var(--mb-6);
  background: #fff;
}

/* Drawer */

.room_drawer__3BPEe {
  position: relative;
  width: 100%;
  margin-top: var(--mb-6);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.room_drawer__content__2FH1s {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.room_drawer__content__2FH1s * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.room_field__text__2BXpW,
.room_field__a9QuC div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.room_field__a9QuC div {
  padding-top: 10px !important;
}

.room_drawer___header__22ZWt {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.room_drawer___header__22ZWt h3 {
  font-family: var(--title-font);
  font-size: 3.2em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
}

.room___row__3cE3Q {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-4);
  grid-gap: 15px;
  gap: 15px;
}

.room_faq__1Rx0l {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5em;
  color: #707070;
}

.room_content__1POPp {
  margin-bottom: var(--mb-6);
}

.room___overflow__Y__SFeJe {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
}

.room_tableHeader__3_alF {
  color: var(--first-color);
}

.room_filed__search__jJuVZ {
  width: 300px;
  padding: 0;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding-bottom: 2px;
  overflow: hidden;
}

/*  */
.room_inputSearchContent__22OdW * input,
.room_inputSearchContent__22OdW * button {
  background: transparent !important;
  border: unset !important;
}

.room_inputSearchContent__22OdW * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.room_inputSearchContent__22OdW * input::placeholder {
  color: var(--text-color);
}

/* .inputSearchContent * button {
  background: rgba(255, 255, 255, 0) !important;
} */

.room_elt__2IY5g {
  margin-bottom: 50px !important;
}

@media only screen and (max-width: 767px) {
  .room_header__1i3w0 {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
    margin: 0;
    text-align: center;
  }

  .room_left__LDmuE {
    width: 75% !important;
  }

  .room_right__2Uuza {
    width: 90% !important;
    margin-left: 0%;
    margin-top: 10px;
  }

  .room_array__conent__1N9zE {
    width: 100%;
    margin-top: var(--mb-4);
    max-height: 700px;
    background: #fafafa;
    border-radius: 10px;
    padding: 10px;
  }

  .room_array__conent__header__1rrFU {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0px;
  }

  .room_array__conent__header__1rrFU * input::placeholder {
    color: var(--text-color);
    font-size: 0.75em;
  }

  .room_filed__search__jJuVZ {
    width: 100%;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 2px;
  }

  .room___array__NosAf {
    width: auto !important;
    max-height: 500px;
    margin-top: var(--mb-6);
    background: #fff;
  }

  .room___btn__JUe1u {
    min-width: 150px !important;
    max-width: auto !important;
    padding-top: 4px;
    height: 30px !important;
  }

  .room_main__2SssN {
    width: 100% !important;
    height: auto !important;
    margin-top: 0em;
  }

  .room_main__row__3xhRL {
    width: 100% !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    justify-content: flex-start !important;
    grid-gap: 0px !important;
    gap: 0px !important;
  }

  .room_main__left__3QLBi {
    width: 100%;
  }

  .room_main__right__3izHI {
    width: 100%;
    margin-top: 1.5em;
  }

  .room_mainLeftContentEmpty__2_Y-Y {
    width: 100% !important;
    margin-top: 20px;
  }

  .room_drawer__content__2FH1s {
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }
}

.room_publications__3Xaio {
  margin-top: 40px;
}
.style_select__2L2x3 {
  height: 45px;
  width: 200px;
  border-radius: 10px;
  border: 1px solid #707070;
  margin-right: 8px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.style_select__2L2x3 div span {
  padding-top: 10px !important;
  text-align: left;
  font-size: 1.1em;
}

.style_easyQuiz_grid__V_qkS__3c9Cg {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.style___card__body__1nMRt,
.style___card__header__2kGO_ {
  text-align: center;
}

.style___card__body__1nMRt h3 {
  font-size: 20px;
  color: #000;
  font-family: var(--title-font);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: uppercase;
}

.style_Card__3Ugym {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 330px;
  padding: 7% 0;
  overflow: hidden;
  background-color: #fafafa;
  border-radius: 10px;
}

.style___matieres__-6ooX {
  margin-top: 1.5em;
}

.style___card__footer__3m7J4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
}

.style_mainX__1h_TP {
  margin-top: 56px;
  overflow-y: auto;
  height: 750px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.style_apercuQuiz__2fi7Z {
  font-size: 14px;
}

.style_absolute__1ncG7 {
  position: absolute !important;
}

.style_relative__JCwSf {
  position: relative !important;
}

.style_grid__11GvY {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
}

.style_elt__19GM7 {
  margin-bottom: 50px !important;
}
.style_customButon__3MJrR {
  position: relative;
  max-width: 260px;
  min-width: 260px;
  font-style: 12px !important;
}

.updateDate_update__dyZDr{
    font-size: 1em;
}
.updateDate_date__3xh5Q{
    font-weight: 600;
    color:var(--dark)
}

.style_field__238Ti {
  width: 100%;
  border: 1px solid #909090;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 3px 5px;
}

.style_form__2ZF4P {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.style_checkbox__11KGI {
  margin-bottom: var(--mb-2);
}

.style_customTextarea__2xb3n::placeholder {
  font-size: 16px;
}
.card_card__2T87l {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card_card__2T87l span {
  margin-top: var(--mb-6);
  font-size: var(--h3-font-size);
  color: var(--text-color);
  font-weight: 500;
}

.card_card__2T87l img {
  cursor: pointer;
}

.style_add-primary-btn__iZuWG {
  min-height: 150px;
  min-width: 100%;
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style___msg__not_cours__Ax-4Z {
  width: 100%;
  text-align: center;
  margin-top: 2em;
}

.style_header__3Tye8 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.style_main__3AoWQ {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.style_center__3EGGc {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.style_left__3fSLT {
  display: flex;
  flex-direction: row;
}

.style_left__3fSLT .style_subtitle__2gJxs {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.style_main__3AoWQ {
  width: 100%;
  height: 100%;
}

.style_row__3q7SJ {
  display: flex;
  grid-gap: 1.3em;
  gap: 1.3em;
  flex-wrap: wrap;
}

.style_card__3KaXC {
  width: 300px;
  height: 300px;
}

/*  */

.style___row__33r2_ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.3em;
  gap: 0.3em;
}

.style___first_drawer__2TTIB {
  position: relative;
  width: 100%;
  padding: 0 50px;
}

.style_drawer__ozkaW {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.style_drawer__content__W6Z8H {
  width: 100%;
}

.style_drawer__content__W6Z8H * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.style_drawer__submit__2mWkW {
  margin-top: var(--mb-2);
}

.style_field__text__1Ep2-,
.style_field__2IQG1 div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.style_field__2IQG1 div {
  padding-top: 10px !important;
}

.style_filter__1chFS {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  /* padding: 0 var(--mb-1); */
  margin-bottom: var(--mb-3);
}

.style_filed__search__dpcIT {
  width: 40%;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.style_filter__right__EHDRk * input,
.style_filter__right__EHDRk * button {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

.style_filter__right__EHDRk * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.style_filter__right__EHDRk * input::placeholder {
  color: var(--text-color);
}

.style_filter__right__EHDRk * button {
  background: #fff !important;
}

.style_filter__left__26EAQ {
  flex: 1 1;
}

.style_filter__right__EHDRk {
  flex: 1.5 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.style_custom__select__2-LKD {
  margin-right: 10px;
}

.style_select__NSjFv {
  position: relative;
  height: 45px;
  width: 200px;
  padding-top: 7px;
  text-align: left;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.style_card__3KaXC {
  width: 300px;
  height: 300px;
}

/* Modal */

.style___success__modal_container__2xM6k {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_modal__card__zvRQp {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.style_modal__card__zvRQp .style_modal__card__title__3Hepd {
  font-family: var(--title-font);
  font-size: 1.5em;
  text-transform: uppercase;
}

.style___matier__28TXo {
  width: 200px;
  height: 40px;
  margin: var(--mb-3) 0;
  border-radius: 10px;
  background: #d5d5d5;
  font-size: 0.8rem;
}

.style___icon_matiere__iPKPt {
  position: relative;
  top: 2px;
  left: -4px;
}

.style___modal__img__success__1NtMH {
  margin-bottom: var(--mb-4);
}

.style___code__access__title__1lw96 {
  text-transform: uppercase;
  font-size: 1em;
  color: #707070;
  margin-bottom: 10px;
  font-weight: 500;
}

.style___code__2jwZL {
  width: 250px;
  height: 45px;
  border: 1px dashed #707070;
  display: flex;
  border-radius: 50px;
  color: #707070;
  margin-bottom: var(--mb-6);
}

.style___code__2jwZL span {
  margin: auto;
  font-size: 1.5em;
  font-weight: 600;
}

.style_modal__card__zvRQp button:last-child {
  margin-left: 10px;
}

.style___btn__2Wk3V {
  max-width: 200px;
  min-width: 200px;
}

.style_close__1jUz4 {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {

  .style_dashboardUserMessage__1XfTS {
    font-size: 1.2em;
    color: black;
  }

  .style_header__3Tye8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .style_center__3EGGc {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }

  .style___btn__2Wk3V {
    max-width: 150px;
    min-width: 150px;
  }

  .style_modal__card__zvRQp .style_modal__card__title__3Hepd {
    font-family: var(--title-font);
    font-size: 1em;
    text-transform: uppercase;
  }

}

/* New Css Styles */

.style_tabTitleBox__2Xz-F {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.style_tabTitleBoxActive__ksJEY {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.style_informationBox__1rdGm {
  width: 100%;
  height: 480px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  overflow-y: scroll;
  scrollbar-width: thin;
  background: #FFF;
}

.style_classesBox__3KPha {
  width: 100%;
  min-height: 640px;
  height: auto;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #FFF;
}

.style_historyBox__2hPRh {
  width: 100%;
  height: 640px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.style_classeCard__1p9gL {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_avatarNameBox__ONXjG {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 300px;
  object-fit: contain;
}

.style_detailsBox__jtrVo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.style_timeBox__dCfHJ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 30px;
}

.style_times__1L9eN {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.style_time__315pW {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.style_timeDescription__1x_uw {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.style_amountBox__3jLtC {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  color: #707070;
}

.style_priceBox__1WP3w {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
}

.style_meetButton__3vizP {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.style_newButton__2YCLh {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4bb980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
  -moz-box-shadow: 5px 7px 1px -1px #4bb980;
  padding-left: 20px;
  padding-right: 20px;
}

.style_meetButton__3vizP:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.style_emptyClass__2lf0n {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.style_dateBox__1MiX5 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.style_horizontalLine__zop7m {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.style_todayBox__zPvyG {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_newClassBox__32J_n {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.style_searchBox__1yF8m {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.style_selectBox__53FEg {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.style_separation__LqcXL {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.style_inputBox__2E0Is {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.style_filedSearch__EcHWc {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.style_cardDisplay__1ijIx {
  display: flex;
  flex-direction: column;
}

.style_cardClassBox__1CGS4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 1050px) {
  .style_headerBox__2x6Mh {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .style_titleBox__2v3HV {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .style_titlePictureBox__WeZlD {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .style_buttonBox__850k1 {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .style_calendrier__2nmpi {
    width: 100%;
    margin: 2em 0;
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 16px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 60vh !important;
  }

  .style_newButton__2YCLh {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4bb980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
    -moz-box-shadow: 5px 7px 1px -1px #4bb980;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .style_calendar__2vw34 {
    height: 60vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 16px !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FAFAFA;
  }

  .style_historyBox__2hPRh {
    height: 73vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FFF;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 16px !important;
  }

  .style_dateBox__1MiX5 {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .style_horizontalLine__zop7m {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .style_todayBox__zPvyG {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .style_emptyClass__2lf0n {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    font-family: Rubik;
    color: #B9B9B9;
    text-align: center;
  }

  .style_cardDisplay__1ijIx {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    gap: 5px;
  }

  .style_singleCourse__3roOp {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
  }

  .style_classeCard__1p9gL {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .style_historyClasseCard__1ZyVP {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
  }

  .style_cardClassBox__1CGS4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .style_avatarNameBox__ONXjG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 90%;
    object-fit: contain;
  }

  .style_detailsBox__jtrVo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .style_historyDetailsBox__jag9P {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .style_timeBox__dCfHJ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
  }

  .style_times__1L9eN {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .style_time__315pW {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .style_timeDescription__1x_uw {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .style_amountBox__3jLtC {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    font-size: 0.7em;
    margin-top: 5px;
  }

  .style_priceBox__1WP3w {
    width: auto !important;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .style_historyPriceBox__1_W3s {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .style_meetButton__3vizP {
    width: auto !important;
    height: 25px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .style_meetButton__3vizP:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .style___first_drawer__2TTIB {
    position: relative;
    width: 100%;
    padding: 0px;
  }

  .style_tabTitleBox__2Xz-F {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }

  .style_tabTitleBoxActive__ksJEY {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }
}
.cardChallenge_card__77zQt {
  width: 50%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #FAFAFA;
  border-radius: 10px;
}

.cardChallenge___matier__1CCbX {
  width: 230px;
  height: 40px;
  margin: var(--mb-2) 0 var(--mb-4) 0;
  border-radius: 10px;
  background: #D5D5D5;
}

.cardChallenge_title__cours__3Xzn- h3 {
  font-size: 4em;
  font-family: var(--title-font);
  text-transform: uppercase;
}

.cardChallenge_title__cours__3Xzn- {
  text-align: center;
}

.cardChallenge_title__cours__3Xzn- span {
  text-align: center;
  font-weight: 500;
}

.cardChallenge_quizz__3aSFw {
  color: var(--first-color);
  font-size: 1.1em;
  font-weight: 500;
  text-transform: uppercase;
}

.cardChallenge_quizz__3aSFw span {
  cursor: pointer;
  transition: all 0.3s;
}

.cardChallenge_quizz__3aSFw span:hover {
  color: var(--first-color-alt);
}

.cardChallenge___btn__1Cy-d {
  max-width: 230px !important;
  min-width: 230px !important;
  font-size: 10px !important;
  margin-left: var(--mb-3);
}
.cardQuizz_card__JStmq {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #CBCACD;
  border-radius: 5px;
  /* margin-left: auto;
  margin-right: auto; */
  cursor: pointer;
  margin-top: var(--mb-3);
}



.cardQuizz_card__JStmq p {
  margin: auto 0;
  padding-left: var(--mb-3);
  font-size: 1.2em;
}

/* input[type=checkbox]+label {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 1px solid #555;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type=checkbox]+label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 20px;
  border-bottom: 5px solid var(--text-color);
  border-left: 5px solid var(--text-color);
  top: 25%;
  left: 45%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

input[type=checkbox]:checked+label {
  border: 1px solid var(--text-color);
}

input[type=checkbox]:checked+label:after {
  opacity: 1;
  width: 35px;
}

.chk {
  display: none;
} */
.card_card__hfv9u {
  position: relative;
  padding-top: 3em;
}

.card_card__content__bIKsl {
  width: 100%;
  height: 100%;
  max-height: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.card_card__hfv9u {
  font-size: var(--h3-font-size);
  color: #707070;
  font-weight: 500;
  opacity: 1; 
  transform: translateY(0px);
}

.card_card__hfv9u img {
  cursor: pointer;
}

.card_code__bd2Sk {
  width: 150px;
  height: 32px;
  border: #707070 1px dashed;
  text-align: center;
  border-radius: 50px;
  background: #fff;
  display: flex;
}

.card_code__bd2Sk span {
  font-size: var(--smaller-font-size);
  margin: auto;
}

.card_select__3X98c {
  width: 50% !important;
}

.card_select__3X98c div {
  height: 30px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
}

/* .select .ant-select-dropdown {
  border-radius: 10px !important;
} */

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: red !important;
} */

.card_states__167pC {
  position: absolute;
  top: var(--mb-3);
  right: var(--mb-2);
}

.card_states__167pC span {
  position: relative;
  top: -3px;
  padding-left: 5px;
  color: var(--first-color);
  font-weight: 600;
}

/*  */
.card_dropdown__23q3w {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.card___down__1m193 {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.card___down__1m193 *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.card___down__1m193 {
  color: var(--text-color);
}

.drawer_header__3nDrF {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 8em 0 1em 0;
}

.drawer___btn__challenge__24POw {
  position: relative;
  max-width: 350px;
  min-width: 350px;
}

.drawer_main__Rd5kY {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.drawer_center__1fE5A {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.drawer_left__2CGq_ .drawer_subtitle__2EjBr {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.drawer_main__Rd5kY {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.drawer_row__2JDzt {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawer_main__left__3bSJo,
.drawer_main__right__3WuRG {
  width: 100%;
  height: 100%;
}

.drawer_main__left__3bSJo {
  flex: 2 1;
}

.drawer_main__right__3WuRG {
  flex: 0.8 1;
}

.drawer_main__row__3LNyh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: var(--mb-2);
  gap: var(--mb-2);
}

.drawer_main__left__content__aTYVh {
  width: 100%;
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: var(--mb-6);
}

.drawer_graphe__right__uXDeE,
.drawer_graphe__left__3OBuH {
  width: 50%;
  height: 600px;
  background: #fafafa;
  border-radius: 10px;
}

.drawer_graphe__left__header__1EEWo {
  margin-left: var(--mb-2);
}

.drawer___garphe__right__header__kJ3jM {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.4em;
  margin-top: 1.5em;
}

.drawer_custom__select__1vB_Y {
  margin-right: 10px;
}

.drawer_select__2g7I7 {
  position: relative;
  height: 45px;
  width: 180px;
  border-radius: 10px;
  padding-top: 7px;
  border: 1px solid var(--text-color);
  display: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.drawer___first__card__3dEAZ,
.drawer___second__card__21x6K {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background: #fafafa;
  border: 2px solid #dbdbdb;
}

.drawer___first__card__3dEAZ h4 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: var(--text-color);
  margin-top: var(--mb-1);
}

.drawer___second__card__21x6K {
  margin-top: var(--mb-3);
  height: 320px;
}

.drawer___second__card__21x6K span {
  text-transform: uppercase;
  font-size: 1.1em;
}

.drawer___second__card__21x6K .drawer_code__3NiN9 {
  width: 200px;
  height: 50px;
  background: #fff;
  border: 1px dashed var(--text-color);
  display: flex;
  margin: var(--mb-4) auto;
  border-radius: 50px;
}

.drawer_code__3NiN9 span {
  margin: auto;
  font-size: 1.6em;
  font-weight: 500;
}

.drawer___content__btn__1UhW6 button:first-child {
  margin-bottom: var(--mb-2);
}

.drawer___btn__17-AU {
  min-width: 200px !important;
  max-width: 200px !important;
  padding-top: 10px;
  height: 45px !important;
}

.drawer_array__conent__R30Ik {
  width: 100%;
  margin-top: var(--mb-4);
  height: 700px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-2) var(--mb-3);
}

/* Array */

.drawer_array__conent__header__2J2M0 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--mb-2);
}

.drawer_filed__search__KZFb4 {
  width: 320px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
  margin-top: var(--mb-1);
}

.drawer_array__conent__header__2J2M0 * input,
.drawer_array__conent__header__2J2M0 * button {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

.drawer_array__conent__header__2J2M0 * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.drawer_array__conent__header__2J2M0 * input::placeholder {
  color: var(--text-color);
}

.drawer_array__conent__header__2J2M0 * button {
  background: #fff !important;
}

.drawer___array__39vdI {
  width: 100% !important;
  height: 500px;
  margin-top: var(--mb-6);
  background: #fff;
}

/* Drawer */

.drawer_drawer__UrSyq {
  position: relative;
  width: 100%;
  margin-top: var(--mb-6);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.drawer_drawer__content__kTxtz {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.drawer_drawer__content__kTxtz * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.drawer_field__text__1mEGk,
.drawer_field__2Mzcl div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.drawer_field__2Mzcl div {
  padding-top: 10px !important;
}

.drawer_drawer___header__2eT1M {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.drawer_drawer___header__2eT1M h3 {
  font-family: var(--title-font);
  font-size: 3.2em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
}

.drawer___first_back__1IioO {
  position: absolute;
  top: 6%;
  left: 5%;
  cursor: pointer;
}

.drawer_filter__5hC0E .drawer_select__2g7I7 div {
  height: 45px !important;
  width: 250px !important;
}

.drawer___row__35NMo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-4);
  grid-gap: 15px;
  gap: 15px;
}

.drawer_faq__1IXsk {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5em;
  color: #707070;
}

.drawer_content__2jY0v {
  margin-bottom: var(--mb-6);
}

.drawer___overflow__Y__TEAIq {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
}

/*  */
.drawer_dropdown__zKjSY {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.drawer___down__3xtvE {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.drawer___down__3xtvE *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.drawer___down__3xtvE {
  color: var(--text-color);
}

.style_drawer__3u1yC {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.style_drawer__content__3ceDv {
  width: 100%;
}

.style_drawer__content__3ceDv * label {
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;

  font-size: 1em;
  text-align: left;
}

.style_field__1LWCL {
  width: 42%;
  height: 45px;
  border: 1px solid var(--text-color);
  padding: 5px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.style_fieldSelect__3esnR {
  width: 42%;
  height: 45px;
  border: 1px solid var(--text-color);
  padding: 5px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.style_drawer___header__3gd_4 {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.style_drawer___header__3gd_4 h3 {
  font-family: var(--title-font);
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

.style_back__2ipzA {
  position: absolute;
  left: 0;
  top: 30px;
  left: -10%;
  cursor: pointer;
}

.style_fieldBox__31mQf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.style_newButton__3-Rj7 {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 30px;
  padding-right: 30px;
}

.style_selectPlaceholder__2HYgu {
  font-size: 14px !important;
}

@media only screen and (max-width: 1050px) {
  .style_fieldBox__31mQf {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    text-align: center;
    width: 95% !important;
    margin-bottom: 1em;
    margin-left: 10px;
    margin-right: 10px;
  }

  .style_field__1LWCL {
    width: 52% !important;
    height: 40px;
    border: 1px solid var(--text-color);
    padding: 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .style_fieldSelect__3esnR {
    width: 52% !important;
    height: 40px;
    border: 1px solid var(--text-color);
    padding: 2.5px 0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 0.8em !important;
    margin-right: 0px;
  }

  .style_drawer___header__3gd_4 {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--mb-6);
  }

  .style_drawer___header__3gd_4 h3 {
    font-family: var(--title-font);
    font-size: 1.5em;
    text-transform: uppercase;
  }

  .style_drawer__content__3ceDv * label {
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    color: #707070;
    font-size: 0.8em !important;
    text-align: left;
  }

  .style_fieldInput__eBq88::placeholder {
    font-size: 0.8em !important;
  }

  .style_newButton__3-Rj7 {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .style_selectPlaceholder__2HYgu {
    font-size: 0.8em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }

  .style_appSelect__j0a6R::placeholder {
    font-size: 0.8em !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }
}
.quiz_quiz__x2BMU {
  margin-bottom: 3em;
}

.quiz_header__1vVXp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.quiz_main__21O_h {
  width: 100%;
  height: 100%;
  margin-top: 3em;
}

.quiz_center__mdzos {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.quiz_left__2LlII .quiz_subtitle__1SxWl {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.quiz_main__21O_h {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 810px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-6) 0;
}

.quiz_content__n_xwk {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.quiz_content__header__1JrD7 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--mb-2);
}

.quiz_header__left__27bhq {
  flex: 1 1;
}

.quiz_header__left__27bhq span {
  font-family: "CartoonFont";
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

.quiz_header__right__2T3P- {
  text-align: left;
  flex: 1.6 1;
}

.quiz_header__right__2T3P- span {
  font-size: var(--h2-font-size);
  color: #333333;
}

.quiz_field__2qI1n {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  margin-bottom: var(--mb-3);
  padding-top: 5px;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.quiz_submit__2MGVV {
  width: 210px;
  border: none;
  font-size: 0.9em;
  background: var(--text-color);
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  height: 40px;
  margin-left: var(--mb-2);
  border-radius: 50px;
}

.quiz_response__img__1rFea {
  position: relative;
  top: 15px;
  margin-left: var(--mb-4);
}

.quiz_row__30OAu {
  position: relative;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-3);
}

.quiz_choices__1Miib {
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 0 var(--mb-4);
}

.quiz_option__cao-J img:nth-child(1) {
  padding-left: var(--mb-2);
}

/* Checkbox */

.quiz_checkbox__1xWXD {
  position: relative;
  margin-right: var(--mb-3);
}

.quiz_checkbox__1xWXD input[type="checkbox"] + label {
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid #555;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.quiz_checkbox__1xWXD input[type="checkbox"] + label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 10px;
  border-bottom: 3px solid #5d9c2d;
  border-left: 3px solid #5d9c2d;
  top: 40%;
  left: 40%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

.quiz_checkbox__1xWXD input[type="checkbox"]:checked + label {
  border: 1px solid var(--text-color);
}

.quiz_checkbox__1xWXD input[type="checkbox"]:checked + label:after {
  opacity: 1;
  width: 20px;
}

.quiz_chk__1rpL2 {
  display: none;
}

.quiz_save__15_mw {
  margin-top: var(--mb-4);
}

.quiz___remove__input__223nI,
.quiz___add__input__2PxZG {
  height: 40px !important;
  border-radius: 50px !important;
  background: var(--text-color) !important;
  color: #fff !important;
  border: unset !important;
}

.quiz___remove__input__223nI {
  background: #ff0000 !important;
}

.quiz___field__content__312GF {
  position: relative;
}

.quiz___second__3dHI1 {
  top: 6.5em;
  right: -24% !important;
}

.quiz___remove__3FgLR {
  position: relative;
  left: 5em;
  top: 0.95em;
}

.quiz___submit__validation__7QfCn {
  min-width: 300px;
}

.createQuiz_cours__bqrfU {
  position: relative;
  margin: 4em 0;
  /* min-height: 70vh; */
}

.createQuiz_teacher__maa__1je5M {
  position: absolute;
  width: 350px;
  height: 450px;
  top: -5%;
  left: 0;
  background: url(/static/media/teacherMaa.068865cd.svg) no-repeat
    center center;
  background-size: contain;
  z-index: 1;
}

.createQuiz_button__2f4AA svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.createQuiz_form__container__iatVK {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.createQuiz_select__1eYiv {
  height: 40px;
  width: 550px;
  border-radius: 10px;
  padding-top: 3px;
  text-align: left;
  margin-bottom: var(--mb-2);
  font-size: 0.9em;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.createQuiz_temps__1Df2t {
  width: 300px !important;
}

.createQuiz_button__2f4AA {
  width: 100% !important;
  height: 38px !important;
  max-width: unset !important;
  font-size: 1em !important;
  padding-top: 10px !important;
}

.cardAds_card__oG0wS {
  position: relative;
  width: 100%;
  height: 265px;
  /* min-height: 350px; */
  /* background: url('../../../../assets/images/teacher/cardAds.svg') no-repeat;
  background-size: contain; */
  background: #fafafa;
  margin-bottom: var(--mb-4);
  box-shadow: 3px 4px 0px 3px #cfd0d2;
}

.cardAds_card__oG0wS:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardAds_card__oG0wS::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardAds_container__54kXo {
  width: 100%;
  height: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-2);
}

.cardAds_header__HjniD {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cardAds_code__39ddZ {
  width: 80px;
  height: 30px;
  border: #707070 1px dashed;
  text-align: center;
  border-radius: 50px;
  display: flex;
  background: #fff;
}

.cardAds_code__39ddZ span {
  font-size: var(--normal-font-size);
  margin: auto;
  font-weight: 500;
}

.cardAds_left__1FyBp {
  display: flex;
  align-items: center;
}

/* .date {
  margin-left: var(--mb-3);
} */

.cardAds_date__3oEFN span strong {
  color: #519259;
  font-weight: 500;
}

.cardAds_date__3oEFN img {
  position: relative;
  top: -2px;
}

.cardAds_text__3zSLq {
  margin-top: var(--mb-2);
  height: 140px;
  overflow-y: scroll;
  scrollbar-width: none;
}

.cardAds_text__3zSLq::-webkit-scrollbar {
  display: none;
}

.cardAds_text__3zSLq p {
  margin-bottom: 10px;
}

.cardAds_text__3zSLq .cardAds___range__date__1P1DO span:last-child {
  display: none;
}

.cardAds_title__ads__yX1p1 {
  font-size: 1.2em;
  text-transform: uppercase;
  margin-bottom: var(--mb-2);
  color: var(--title-color);
}

.cardAds_text__3zSLq p {
  font-size: var(--small-font-size);
  color: #707070;
}

.cardAds_footer__3rdgr {
  width: 100%;
  /* margin-top: var(--mb-2); */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardAds_footer__3rdgr .cardAds_button__3GCnC {
  min-width: 180px;
  height: 40px !important;
}

.cardAds_reference__1jmQk span {
  font-size: var(--smaller-font-size);
}

.cardAds_dropdown__11G5u {
  width: 150px !important;
  height: 30px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.cardAds___down__2sV85 {
  border-radius: 10px !important;
}

.cardAds___down__2sV85 *:hover {
  color: var(--first-color) !important;
  background: #fff !important;
}

.adds_header__3SJnY {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.adds_main__2RjkW {
  width: 100%;
  height: 100%;
  margin-top: 3em;
}

.adds_center__3URHT {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.adds_left__2F1gQ .adds_subtitle__1MYuu {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.adds_justify__center__1L8zr {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.adds_main__2RjkW .adds_annonces__LwAa7 {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

/* Drawer */
.adds___drawer__2vvBa {
  position: relative;
}

.adds___teacher__MAA__24x1L {
  position: absolute;
  width: 160px;
  height: 480px;
  top: 19%;
  left: 0%;
  background: url(/static/media/teacherMAA.8fc06dd7.svg) center center no-repeat;
  background-size: contain;
  z-index: 1;
}

.adds___teacher__MAA__step_two__jVHvQ {
  position: absolute;
  width: 160px;
  height: 450px;
  top: 16%;
  left: 0%;
  background: url(/static/media/teacherStepTwo.f1586904.svg) center center no-repeat;
  background-size: contain;
  z-index: 1;
}

.adds___drawer__header__2rf-m {
  margin: var(--mb-1) 0 0 0;
  text-align: center;
}

.adds___drawer__header__2rf-m {
  font-family: var(--title-font);
  font-size: 2em;
  text-transform: uppercase;
}

.adds_drawer__form__content__gpS7F {
  width: 100%;
  max-width: 600px;
  margin: 1em auto;
}

.adds___form__34DFQ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  padding: 2em 2% !important;
  min-height: 450px;
  max-width: 80%;
  border-radius: 10px;
  overflow-y: scroll;
  margin-left: auto;
  margin-right: auto;
}

.adds___form__34DFQ>div {
  width: 13.8%;
}

.adds___form__34DFQ>div button {
  width: 100%;
  border: unset;
  height: 38px;
  font-weight: bold;
}

.adds_matiers__1M7BH {
  width: 100%;
  margin: 0;
  margin-top: 5px;
}

.adds_add_____2p56L {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-2);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: var(--first-color);
  box-shadow: 3px -1px 1px -1px rgba(90, 132, 132, 1);
}

.adds___final__step_form__380L1 {
  width: 100%;
  max-width: 700px;
  padding: 2em !important;
  background: #fafafa;
  border-radius: 10px;
  margin: 1em auto;
}

.adds___form__34DFQ .adds___available__1qf0n {
  font-size: 1.1em;
  color: #80b1b2;
  text-align: center;
}

.adds_button__submit__MpEXC {
  margin-top: var(--mb-2);
}

.adds_select__1tS0H div {
  height: 45px !important;
  width: 260px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.adds_select__1tS0H div span {
  padding-top: 7px !important;
  text-align: left;
}

.adds___input_price__Vd-vY,
.adds___text__area__2Pj8p {
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.adds___euro__icon__2LrsI {
  position: absolute;
  width: 30px;
  right: 0;
  top: 8px;
}

.adds___btn__planing___7gMe {
  height: 40px !important;
  min-width: 200px;
  max-width: 200px;
}

.adds_select__input__7KlPW>div {
  height: 45px;
  min-width: 260px;
  border-radius: 15px !important;
  border: 1px solid var(--text-color) !important;
}

.adds_select__input__7KlPW * input {
  height: 32px !important;
}

.adds___btn_creneau__nEJn4 {
  min-width: 300px;
  height: 40px !important;
}

/* .__custom__btn * button span {
  border: unset !important;
  background: #000 ;
} */

.adds___matiers__14SHS {
  position: relative;
  top: -13px;
}

.adds_stepTwo__1yOzO {
  width: 100%;
  background: #fafafa;
  padding: 2em;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.adds_delete_____7zif- {
  margin-top: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.adds_delete_____7zif-:hover {
  background: red;

  cursor: pointer;
}

.adds_delete_____7zif-:hover * {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .adds___teacher__MAA__24x1L {
    display: none;
  }

  .adds___teacher__MAA__step_two__jVHvQ {
    display: none;
  }

  .adds___drawer__header__2rf-m {
    font-family: var(--title-font);
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: flex-start;
  }

  .adds___form__34DFQ {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fafafa;
    padding: 2em 2% !important;
    min-height: 450px;
    max-width: 100%;
    border-radius: 10px;
    overflow-y: scroll;
    margin-left: auto;
    margin-right: auto;
  }

  .adds_drawer__form__content__gpS7F {
    width: 100%;
    max-width: 100%;
    margin: 1em auto;
  }

}

/* New Css Style */

.adds_drawerTitle__26DeP {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.adds_newMoveButton__3ipUd {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.adds_newDeleteButton__2iSRc {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #e04941 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #e8380c !important;
  -webkit-box-shadow: 2px 4px 1px -1px #e8380c !important;
  -moz-box-shadow: 2px 4px 1px -1px #e8380c !important;
  margin-right: 10px !important;
}

.adds_newMoveButton__3ipUd:hover {
  transform: scale(1.03) !important;
  background-color: #80b1b2;
  color: #fff;
}

.adds_formBox__nWw0C {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  padding-top: 2em;
  padding-bottom: 2em;
  overflow-y: scroll;
  margin-left: 20px;
  margin-right: 20px;
}

.adds_formDayBox__1ci4y {
  height: 700px;
  width: 160px;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  padding: 5px;
}

.adds_dayBox__2_LpR {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EAEAEA;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #575757;
  border-radius: 12px;
  margin-bottom: 30px;
}

.adds_addButton__9XFZz {
  width: 28px;
  height: 28px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-2);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: var(--first-color);
  box-shadow: 1px -1px 1px -1px rgba(90, 132, 132, 1);
}

.adds_detailsBox__2WOfH {
  height: 100px;
  width: 100%;
  background: #FFF;
  box-shadow: 2px 2px 10px #A2A2A239;
  border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 1em;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.adds_typeBox__3AX0C {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #8AB0B1;
}

.adds_typeIconBox__34wN_ {
  height: 25px;
  width: 25px;
  background: #DAE6E7;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.adds_levelsBox__Wa_Bm {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  text-align: center;
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 500;
  color: #868686;
}

.adds_timeRangeDeleteBox__vAtgD {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.adds_timeRangeBox__2lWy3 {
  height: 25px;
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #F7F7F7;
  padding: 7px;
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  border-radius: 24px;
}

.adds_timeRangeBox__2lWy3 span {
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  margin-left: 3px;
  margin-right: 3px;
}

@media only screen and (max-width: 1050px) {
  .adds_drawerTitle__26DeP {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .adds_formBox__nWw0C {
    height: 65vh !important;
    width: 400% !important;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 2%;
    padding-top: 2em;
    padding-bottom: 2em;
    overflow-y: scroll !important;
    margin-left: 20px;
    margin-right: 20px;
  }

  .adds_formDayBox__1ci4y {
    height: 100% !important;
    max-height: 100% !important;
    width: 200px !important;
    background: #FAFAFA;
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    opacity: 1;
    display: block !important;
    margin-right: 20px;
    padding: 5px;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .adds_dayBox__2_LpR {
    height: 30px !important;
    width: 90% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: #EAEAEA;
    text-align: center !important;
    font-size: 0.85em !important;
    font-family: "Poppins";
    font-weight: 600;
    color: #575757;
    border-radius: 8px;
    margin: 10px auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .adds_newMoveButton__3ipUd {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin: 0px auto !important;
  }

  .adds_newDeleteButton__2iSRc {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #e04941 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #e8380c !important;
    -webkit-box-shadow: 2px 4px 1px -1px #e8380c !important;
    -moz-box-shadow: 2px 4px 1px -1px #e8380c !important;
    margin-top: 10px !important;
    margin-right: 0px !important;
  }

  .adds_detailsBox__2WOfH {
    height: 100px;
    width: 85%;
    background: #FFF;
    box-shadow: 2px 2px 10px #A2A2A239;
    border-radius: 16px;
    margin: 1em auto;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .adds_typeBox__3AX0C {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
  }

  .adds_typeIconBox__34wN_ {
    height: 20px;
    width: 20px;
    background: #DAE6E7;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .adds_levelsBox__Wa_Bm {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    text-align: center;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #868686;
  }

  .adds_timeRangeDeleteBox__vAtgD {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .adds_timeRangeBox__2lWy3 {
    height: 20px;
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
    padding: 2px;
    font-size: 0.7em !important;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    border-radius: 24px;
  }

  .adds_timeRangeBox__2lWy3 span {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    color: #707070;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
.exercice_exercice__1kuBO {
  margin-bottom: 4em;
  height: 100%;
}

.exercice_header__2a675 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5em;
  margin-bottom: 4em;
}

.exercice_content__Gy0bb {
  overflow-y: scroll;
  padding: 5%;
  height: 600px;
}

.exercice___button__back__35nkZ {
  min-width: 300px;
}

.exercice_center__1OxRv {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}


.exercice_left__1HG_6 .exercice_subtitle__2DN8K {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.exercice_title__main__2a8yy {
  text-align: center;
  margin-bottom: 2em;
}

.exercice_title__1Dcon {
  margin: 0.5em 0 1em 0;
  font-size: 2.8em;
}

.exercice_main__1EoK4 {
  width: 100%;
  border-radius: 10px;
}

.exercice___ennnoncer__3LjA9 {
  margin-bottom: 1em;
}

.exercice___icon_drawer__2CUUP {
  position: absolute;
  top: 5%;
  right: 5%;
}

.exercice_title__drawer__sFYSl {
  text-align: center;
  margin-bottom: 2em;
  font-size: 1.3em;
  text-transform: uppercase;
}

.exercice_letter__3_yGc {
  position: relative;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 800px;
  /* height: 70vh; */
  padding: 4em;
  width: 65%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em !important;
}

.exercice_letter__3_yGc:before,
.exercice_letter__3_yGc:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.exercice_letter__3_yGc:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}

.exercice_letter__3_yGc:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}

.exercice___button__indice__6j6r4 {
  margin-right: 1em;
}
.style_sharingBox__1gUNK {
    height: 40px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    left: -37px;
}

.style_sharingButton__3Q3ui {
    height: 32px;
    width: 32px;
    margin-right: 5px;
    cursor: pointer;
}

.style_sharingButton__3Q3ui:hover:not(:active) {
    opacity: 0.75;
}
.style_card__50Sxa {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #FFF;
  margin-bottom: var(--mb-4);
  box-shadow: 3px 4px 0px 3px #cfd0d2;
}

.style_card__50Sxa:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.style_card__50Sxa::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.style_container__2eCQU {
  width: 100%;
  min-height: 240px;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-2);
  padding-left: var(--mb-1);
  padding-bottom: var(--mb-2);
}

.style_header__1uyRG {
  margin-bottom: var(--mb-2);
  display: flex;
  justify-content: space-between;
}

.style_user__infos__2RRO- h3 {
  text-transform: capitalize;
}

.style_body__2DCbh {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  margin-bottom: 3px;
  cursor: pointer;
  object-fit: contain !important;
  border-radius: 24px;
}

.style_body__2DCbh img {
  width: 90%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: cover;
  border-radius: 24px;
  margin-bottom: 1.5em;
}

.style_body__2DCbh video {
  width: 90%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: cover;
  border-radius: 24px;
  margin-bottom: 1.5em;
}

.style_body__2DCbh video source {
  width: 90%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: cover;
  border-radius: 24px !important;
  margin-bottom: 1.5em;
}

.style_button__9X9UR {
  padding: 5px auto;
  /* border: 1px dashed #707070; */
  border: none;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;
  font-size: 12px;
}

.style_button__9X9UR:hover {
  /* border: 1px dashed #7FB1B2; */
  border: none;
  color: #7FB1B2;
}

.style_articleDrawer__3ks27 {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}

.style_cardArticle__2PafY {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #fafafa;
  margin-bottom: var(--mb-4);
  box-shadow: 3px 4px 0px 3px #cfd0d2;
}

.style_cardArticleImage__1oEvv {
  position: relative;
  width: 100%;
  min-height: 150px;
  background: #fafafa;
  margin-bottom: var(--mb-4);
}

.style_cardArticle__2PafY:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.style_cardArticle__2PafY::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: auto;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

@media only screen and (max-width: 1050px) {

  .style_card__50Sxa {
    position: relative;
    width: 90%;
    min-height: 150px;
    background: #FFF;
    margin: 20px auto;
    box-shadow: 1px 2px 0px 1px #B7BABC;
  }

  .style_card__50Sxa:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 40px;
    background: radial-gradient(#B7BABC 4px, transparent 5px) repeat-y;
    background-size: 15px 15px;
    box-sizing: border-box;
  }

  .style_card__50Sxa::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 20px;
    background: radial-gradient(#fff 3px, transparent 4px) repeat-y;
    background-size: 15px 15px;
    box-sizing: border-box;
  }

  .style_user__infos__2RRO- h3 {
    text-transform: capitalize;
    font-size: 1em !important;
    color: #393E3E;
    line-height: 7px;
  }

  .style_user__infos__2RRO- span {
    font-size: 0.7em !important;
    color: #5D5D5D;
  }

  .style_postContent__26jGJ {
    font-size: 12px;
  }

  .style_body__2DCbh {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    margin-top: 0px;
  }

  .style_button__9X9UR {
    padding: 5px auto;
    /* border: 1px dashed #707070; */
    border: none;
    background: #fff;
    border-radius: 15px;
    cursor: pointer;
    font-size: 11px;
  }

  .style_buttonRow__1Uqsj {
    margin-left: 10px;
  }

  .style_articleDrawer__3ks27 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;
    scrollbar-width: thin;
  }
}

/* New Css Styles */

.style_customPopover__2QCfI .style_ant-popover-inner__3g4f6 {
  border-radius: 30px;
  /* Set desired border radius */
}
.style_post__2tLl4 {
  width: 100%;
  margin-bottom: var(--mb-3);
}

.style_input__YdTtJ {
  width: 100% !important;
  border: 1px solid #707070;
  padding: 3px 7px;
  border-radius: 30px;
  margin-bottom: var(--mb-2);
  display: flex;
  flex-direction: row;
}

.style_inputTextarea__1U74Y {
  border: 1px solid #707070;
  padding: 3px 7px;
  border-radius: 30px;
  margin-bottom: var(--mb-2);
}

.style_img__preview__1dn2o {
  width: 300px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: var(--mb-1);
  margin-left: auto;
  margin-right: auto;
}

.style_img__preview__1dn2o img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.style_postButton__2t51x {
  cursor: pointer;
  margin-right: 20px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.style_postButton__2t51x:hover {
  background-color: #FFF;
  border-radius: 25px;
  border: 1px solid #8AB0B1;
}

.style_postContainer__1CAqi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px auto;
  width: 100%;
  color: #393E3E;
}

@media only screen and (max-width: 1050px) {
  .style_post__2tLl4 {
    width: 100%;
    margin-bottom: var(--mb-3);
  }

  .style_input__YdTtJ {
    width: 100%;
    border: 1px solid #707070;
    padding: 3px 7px;
    border-radius: 30px;
    margin-bottom: var(--mb-2);
    display: flex;
    flex-direction: row;
  }

  .style_postButton__2t51x {
    cursor: pointer;
    font-size: 0.7em;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }

  .style_postButton__2t51x:hover {
    background-color: #FFF;
    border-radius: 25px;
    border: 1px solid #8AB0B1;
  }

  .style_postContainer__1CAqi {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px auto;
    width: 100%;
    position: relative;
    left: 0%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .style_commentBox__fOhjX {
    height: 100px !important;
    width: 90% !important;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 20px !important;
    opacity: 1;
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin: 0px auto !important;
  }

  .style_field__TEgUi::placeholder {
    font-size: 10px !important;
  }
}

/* New Css Styles */

.style_commentBox__fOhjX {
  height: 140px;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.style_field__TEgUi::placeholder {
  font-size: 12px !important;
}
.style_mainContent__2RIcz {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width: 1050px) {
    .style_mainContent__2RIcz {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 70px;
    }
}
.style_drawerBox__Kjq05 {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    padding: 40px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    font-size: 12px;
    width: 480px;
}

.style_blurOverlay__2Ycdb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.28);
    backdrop-filter: blur(24px) brightness(18%);
    -webkit-backdrop-filter: blur(24px) brightness(18%);
    z-index: 0;
    border-radius: 0px 62px 62px 0px;
}
.style_teacher__infos__uMori {
  width: 100%;
  height: auto;
  min-height: 300px;
  text-align: center;
  background: #fafafa;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 2em;
}

.style_student__infos__3PbyH {
  width: 100%;
  height: auto;
  text-align: center;
  background: #fafafa;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 2em;
}

.style_top__3JgIu {
  width: 100%;
  height: 80px;
  background: var(--first-color);
  margin-bottom: var(--mb-4);
  display: flex;
}

.style_avatar__3ts0y {
  position: relative;
  margin: auto;
  top: 1.8em;
  background: #fff2e0;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #FFF;
}

.style_profile__2ZUU7 {
  background: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.style_content__3szvp {
  width: 100%;
  padding: 0 1em;
}

.style_content__3szvp h2 {
  color: #8AB0B1;
  font-size: 1.05em;
  text-transform: uppercase;
  margin-bottom: 0.8em;
}

.style_follow__Xfgqm {
  margin-bottom: 3px;
}

.style_text_stats__VW049 {
  min-width: 150px;
  text-align: left;
  text-decoration: underline;
}

.style_readMore__1HpE8 {
  text-align: left;
  text-decoration: underline;
  font-size: 12px;
  color: #707070;
  margin-top: 5px;
  cursor: pointer;
}

.style_readMore__1HpE8:hover {
  color: #7FB1B2;
}

@media only screen and (max-width: 1050px) {
  .style_teacher__infos__uMori {
    display: none;
  }

  .style_student__infos__3PbyH {
    display: none;
  }
}

/* New CSS Styles */

.style_teacherInfos__2cS9B {
  width: 100%;
  height: auto;
  min-height: 300px;
  text-align: center;
  background: #FFF;
  border-radius: 24px;
  overflow: hidden;
  padding-bottom: 2em;
  box-shadow: 4px 8px 21px #B7B7B729;
}

.style_studentInfos__1VcFh {
  width: 100%;
  height: auto;
  min-height: 150px;
  text-align: center;
  background: #FFF;
  border-radius: 24px;
  overflow: hidden;
  padding-bottom: 2em;
  box-shadow: 4px 8px 21px #B7B7B729;
}

.style_topAvatar__3O-fr {
  width: 100%;
  height: 80px;
  background: #FFF;
  margin-bottom: var(--mb-4);
  display: flex;
}

.style_newButton__1vTXN {
  width: 130px !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  margin: 0px auto;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}
.style_teacherEvents__2yZ6U {
  width: 100%;
  height: auto;
  min-height: 300px;
  background: #fafafa;
  border-radius: 5px;
  overflow: hidden;
  padding: 0em 0em;
}

.style_bottom__1RfT1 h2,
.style_top__2izen h2 {
  text-transform: unset;
}

.style_scheduled__course__2c7IJ {
  margin-top: var(--mb-2);
}

.style_bottom__1RfT1 {
  margin-bottom: 1em;
}

/* CARD */
.style_card__3Btou {
  position: relative;
  width: 100%;
  min-height: 40px;
  background: #fff;
  border: 1px solid #bfbfbf;
  padding: 10px 5px 15px 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.style_tag__2KSo4 {
  width: 120px;
  height: 28px;
  background: #d5d5d5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 8px;
  color: var(--text-color);
  margin: 0;
}

.style_tagIcon__2A9O9 {
  width: 15px;
}

.style_challenge__1fZFX {
  position: absolute;
  width: 130px;
  height: 20px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  background: #0ed290;
  border-radius: 5px;
  color: #fff;
  font-size: 10px;
  left: 10px;
  bottom: -10px;
}

.style_round__3OSTl {
  width: 10px;
  height: 10px;
  background: #0a9b6a;
  border-radius: 50px;
}

@media only screen and (max-width: 1050px) {
  .style_teacherEvents__2yZ6U {
    display: none;
  }
}

/* New Css Styles */

.style_top__2izen {
  height: auto;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.style_title__1bR9W {
  font-size: 12px;
  font-family: "Poppins";
  color: #393E3E;
  font-weight: 600;
  margin-top: 0px;
  margin-left: 10px;
}

.style_titleIconBox__1hmsH {
  width: 40px;
  height: 40px;
  background: #FFF;
  box-shadow: 0px 3px 13px #69696929;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.style_emptyClass__2PL9B {
  width: 80%;
  height: 160px;
  background: #FAFAFA;
  border-radius: 34px;
  opacity: 1;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.style_newButton__1n5rx {
  width: auto !important;
  height: 25px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 2px !important;
  font-weight: 400 !important;
  border: none !important;
  margin: 0px auto;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.style_myClasses__2Tl7V {
  height: 300px;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin-top: 20px;
}

.style_myChallengeClasses__1peBH {
  height: auto;
  width: 100%;
  background: #FFF;
  border: 1px solid #0AD290;
  border-radius: 24px;
  opacity: 1;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
  margin-top: 20px;
}

.style_classeCard__1R7Xr {
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_challengeClasseCard__1hzjj {
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #0AD290;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_todayBox__3l_1p {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.style_challengeMatiers__O8Fty {
  width: auto;
  height: 30px;
  background: #e6faf4;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
  margin: var(--mb-1);
  padding-left: 0px;
  padding-right: 0px;
}

.style_avatarNameBox__KTMbk {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  object-fit: contain;
}
.style_flex__232KC {
  width: 100%;
  display: flex;
  grid-gap: 1.5em;
  gap: 1.5em;
}

.style_left__2RcJL {
  flex: 0.5 1;
}

.style_main__2SgMa {
  flex: 1 1;
}

.style_right__3qP1X {
  flex: 0.5 1;
}

.style_mainBox__18HaN {
  background-color: #FAFAFA;
  padding-top: 20px;
  padding-left: 0%;
  padding-right: 0%;
  margin-bottom: 80px;
  width: 60%;
  margin: 0px auto;
}

.style_containerBox__K7Nfc {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
}

@media only screen and (max-width: 1050px) {
  .style_flex__232KC {
    width: 100%;
    display: flex;
    grid-gap: 0;
    gap: 0;
  }

  .style_left__2RcJL {
    flex: 0 1;
    display: none;
  }

  .style_right__3qP1X {
    flex: 0 1;
    display: none;
  }

  .style_containerBox__K7Nfc {
    height: 100%;
    width: 100%;
    background-color: #FAFAFA;
  }

  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 100%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1051px) and (max-width: 1151px) {
  .style_flex__232KC {
    width: 100%;
    display: flex;
    grid-gap: 0;
    gap: 0;
  }

  .style_left__2RcJL {
    flex: 0 1;
    display: none;
  }

  .style_right__3qP1X {
    flex: 0 1;
    display: none;
  }

  .style_containerBox__K7Nfc {
    height: 100%;
    width: 100%;
    background-color: #FAFAFA;
  }

  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 70%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1152px) and (max-width: 1340px) {
  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 90%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1341px) and (max-width: 1429px) {
  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 85%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1430px) and (max-width: 1479px) {
  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 82%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1480px) and (max-width: 1599px) {
  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 75%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 70%;
    margin: 0px auto;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1850px) {
  .style_mainBox__18HaN {
    background-color: #FAFAFA;
    padding-top: 20px;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 80px;
    width: 66%;
    margin: 0px auto;
  }
}
.style_dashboard__3xGeS {
  margin-top: 1em;
}

.style_add-primary-btn__3-vxm {
  min-height: 150px;
  min-width: 100%;
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style_header__1HCYZ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5em;
}

.style_main__RUaRj {
  width: 100%;
  height: 100%;
  margin-top: 4em;
  margin-bottom: 200px;
}

.style_center__1ZDIM {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.style_left___LBAJ .style_subtitle__1T3y7 {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.style___flex__3l5VB {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.style_justify__center__1Uk7G {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.style_main__RUaRj .style_annonces__btn__2IJje {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin: 3.5em 0;
}

.style_drawer__3vppd {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.style_drawer__content__1O4Ay {
  width: 100%;
}

.style_drawer__content__1O4Ay * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.style_field__3uqlY {
  height: 45px;
  border: 1px solid var(--text-color);
  padding: 5px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.style_drawer___header__WSNHi {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.style_drawer___header__WSNHi h3 {
  font-family: var(--title-font);
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

.style_back__1EoTu {
  position: absolute;
  left: 0;
  top: 30px;
  left: -10%;
  cursor: pointer;
}

/* Modal */

.style___success__modal_container__1pbzT {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_modal__card__xWDU5 {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.style_modal__card__xWDU5 .style_modal__card__title__15XzI {
  font-family: var(--title-font);
  font-size: 3em;
  text-transform: uppercase;
}

.style___matier__11oWo {
  width: 200px;
  height: 40px;
  margin: var(--mb-3) 0;
  border-radius: 10px;
  background: #d5d5d5;
  font-size: 0.8rem;
}

.style___icon_matiere__1eOTR {
  position: relative;
  top: 2px;
  left: -4px;
}

.style___modal__img__success__254WM {
  margin-bottom: var(--mb-4);
}

.style___code__access__title__1pm2T {
  text-transform: uppercase;
  font-size: 1em;
  color: #707070;
  margin-bottom: 10px;
  font-weight: 500;
}

.style___code__UVbWS {
  width: 250px;
  height: 45px;
  border: 1px dashed #707070;
  display: flex;
  border-radius: 50px;
  color: #707070;
  margin-bottom: var(--mb-6);
}

.style___code__UVbWS span {
  margin: auto;
  font-size: 1.5em;
  font-weight: 600;
}

.style_modal__card__xWDU5 button:last-child {
  margin-left: 10px;
}

.style___btn__64vd4 {
  max-width: 200px;
  min-width: 200px;
}

.style_close__RefYe {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.style_dashboardUserBox__GGzPu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
}

.style_dashboardUserMessage__2nR-h {
  font-size: 1.8em;
  color: black;
  margin: 0px auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {

  .style_dashboardUserMessage__2nR-h {
    font-size: 1.2em;
    color: black;
  }

  .style_header__1HCYZ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .style_center__1ZDIM {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }

  .style_filed__search__1Qt9L {
    width: 65%;
    height: 40px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
  }

  .style_select__LgJ56 {
    height: 40px;
    width: 160px;
    border-radius: 10px;
    border: 1px solid var(--text-color);
  }

  .style___submit__hidden__1Fo1p {
    position: relative !important;
    top: 0px !important;
    margin: 0px auto !important;
  }

  .style___reset__btn__3zjuT {
    position: relative !important;
    right: 0% !important;
    bottom: 0% !important;
    top: 10px !important;
  }

  .style_modal__card__xWDU5 .style_modal__card__title__15XzI {
    font-size: 2em;
  }

  .style___btn__64vd4 {
    max-width: 150px;
    min-width: 150px;
  }
}

@media only screen and (max-width: 1050px) {
  .style_dashboard__3xGeS {
    margin-top: 0em !important;
  }
}
.style___drawer__header__3XmCX {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  margin: var(--mb-1) 0 var(--mb-2) 0;
}

.style_horaire__q1XeO,
.style___drawer__header__3XmCX h3 {
  font-family: var(--title-font);
  font-size: 1.7em;
  text-transform: uppercase;
}

.style___drawer__header__3XmCX button {
  border: none;
  font-weight: 500;
  min-width: 130px;
}

.style_time__picker__1zGgO {
  background: #fff;
  padding: 0.5em 0;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 5px;
}

.style_full__content__1h0dc {
  width: 100%;
  height: 100%;
  /* max-width: 80%; */
  background: #f8f8f8;
  /* margin-left: auto;
  margin-right: auto; */
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  padding: 2%;
  border-radius: 5px;
  margin-bottom: var(--mb-4);
}

.style_field__content__1O4Ki {
  max-width: 60%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.style_title__1v1LH {
  font-size: 1.5em;
}

.style_forms__23NSD {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 1em;
  gap: 1em;
  width: 100%;
  margin-top: var(--mb-2);
}

.style_field__Y7Udz {
  background: #fff;
  padding: 0.35em;
  /*width: 20vw;
  */
  width: 100%;
  margin-bottom: 1em;
  border-radius: 5px;
  border: 1px solid #707070;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.style_button__2Fof_ {
  width: 100% !important;
  background: #636363 !important;
  color: #fff !important;
  height: 42px !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

.style_button__2Fof_:hover {
  background: var(--first-color) !important;
  color: #fff !important;
}

.style___new__creneaux__3Hyz4 {
  width: 100% !important;
  background: var(--first-color) !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 0.5em;
}

.style___new__creneaux__3Hyz4:hover {
  background: #636363 !important;
  color: #fff !important;
}

.style_flex__qYyc3 {
  display: flex;
  grid-gap: 0.8em;
  gap: 0.8em;
  flex-wrap: wrap;
}

.style_groupbox__3M393 {
  margin-bottom: var(--mb-4);
}

.style_checkbox__ZixSo {
  position: absolute;
  margin: auto;
  right: 10px;
  top: 5px;
}

.style_card__11W-u {
  position: relative;
  width: 180px;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  padding: 1em;
  background: #fff;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.style_hoursBox__2lpY_ {
  margin-bottom: 2em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-gap: 16px;
  gap: 16px;
}

@media only screen and (max-width: 767px) {

  .style_hoursBox__2lpY_ {
    margin-bottom: 2em;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
  }

  .style_horaire__q1XeO,
  .style___drawer__header__3XmCX h3 {
    font-family: var(--title-font);
    font-size: 0.9em;
    text-transform: uppercase;
  }

}

/* New Css Styles */

.style_drawerBody__2YKqw {
  height: auto !important;
  width: 580px !important;
  background: #FAFAFA !important;
  box-shadow: 4px 8px 16px #80808029 !important;
  border: 1px solid #BFBCBC !important;
  border-radius: 42px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 40px !important;
  margin-left: 7% !important;
  margin-right: 7% !important;
}

.style_dateBox__R-O0P {
  height: auto !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.style_dateBox__R-O0P span {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
}

.style_datePickerBox__3d7m_ {
  height: auto;
  width: 350px;
}

.style_datePicker__22Wbo {
  background: #fff;
  padding: 0.5em 0;
  border: 1px solid #707070;
  border-radius: 8px;
  margin-left: 20px;
}

.style_datePicker__22Wbo::placeholder {
  color: #868686;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
}

.style_timeBox__3yODU {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.style_timeBox__3yODU span {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  margin-left: 10px;
  margin-right: 10px;
}

.style_timeTitle__2J1LC {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
}

.style_timePicker__2nbvN {
  height: 40px;
  width: 160px;
  background: #fff;
  padding: 0.25em 0;
  border: 1px solid #707070;
  border-radius: 8px;
}

.style_selectBox__3GYx6 {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.style_levelsBox__1RyDC {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #7FB1B2;
  margin-top: 20px;
}

.style_levelsButtons__wkjrA {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.style_levelButton__1VIeM {
  width: 100px;
  height: 40px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.style_levelButtonActive__3V7ZX {
  width: 100px;
  height: 40px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.style_addButtonBox__25Nfi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
}

.style_albertBox__46wxi {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.style_newMoveButton__1FVVm {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.style_newMoveButton__1FVVm {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

@media only screen and (max-width: 1050px) {
  .style_drawerBody__2YKqw {
    height: auto !important;
    width: 95% !important;
    background: #FAFAFA !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 20px !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .style_dateBox__R-O0P {
    height: auto !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .style_dateBox__R-O0P span {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
  }

  .style_datePickerBox__3d7m_ {
    height: 30px !important;
    width: 75%;
  }

  .style_datePicker__22Wbo {
    height: 30px !important;
    background: #fff;
    padding: 0em;
    border: 1px solid #707070;
    border-radius: 8px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .style_datePicker__22Wbo::placeholder {
    color: #868686;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
  }

  .style_timeBox__3yODU {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .style_timeBox__3yODU span {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-left: 10px;
    margin-right: 10px;
  }

  .style_timeTitle__2J1LC {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
  }

  .style_timePicker__2nbvN {
    height: 30px;
    width: 50%;
    background: #fff;
    padding: 0em 0;
    border: 1px solid #707070;
    border-radius: 8px;
  }

  .style_selectBox__3GYx6 {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .style_levelsBox__1RyDC {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #7FB1B2;
    margin-top: 20px;
  }

  .style_levelsButtons__wkjrA {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    gap: 10px;
  }

  .style_levelButton__1VIeM {
    width: 70px;
    height: 25px;
    background: #FFF;
    border: 1px solid #707070;
    border-radius: 8px;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .style_levelButtonActive__3V7ZX {
    width: 70px;
    height: 25px;
    background: #E4F8F8;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #7FB1B2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .style_addButtonBox__25Nfi {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    text-transform: capitalize !important;
  }

  .style_albertBox__46wxi {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .style_newMoveButton__1FVVm {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin: 0px auto !important;
  }

}
.style_calendar__iPi-k {
  width: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #fafafa;
  margin-top: 1em;
}

.style_weekly__ZqWrC {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  margin-bottom: 2em;
  border: 1px solid #ccc;
}

.style_date__2Iwzl {
  width: auto !important;
  height: 45px !important;
  border-radius: 12px !important;
  text-align: center !important;
  border: 1px solid #ccc !important;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  transition: 0.3s !important;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  font-size: 13px !important;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  text-transform: capitalize;
}

.style_date__2Iwzl:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.style_date__2Iwzl.style_active__H7MyV {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.style_creneaux__28v-a {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.style_creneau__3s0ex {
  margin-bottom: 0.5em;
}

.style_range__39bdy {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.style_range__39bdy span {
  margin: auto;
}

.style_matiere__3w-2h {
  font-size: 1em;
}

.style_bar__8CuwR {
  margin: 0 0.5em;
}

.style_angleIcon__3flI- path {
  fill: #fe772e;
}

.style_slider__1dfm6>svg path {
  fill: #fe772e;
}

.style_card__13lIT {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .style_content__action__reservation__aDd-_ {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.style_myReservation__r43Ox {
  margin-bottom: 1em;
}

.style_card___3weOR {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}

/* New Css Styles */

.style_headerBox__TfEpp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_titleBox__2CHlH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.style_titlePictureBox__yegSF {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_buttonBox__1MCSU {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.style_addButton__2Uy8L {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
}

.style_gestionButton__22rm7 {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-left: 20px;
  padding-right: 20px;
}

.style_optionButton__2KrKP {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  padding-top: 0px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-left: 20px;
  padding-right: 20px;
}

.style_addButton__2Uy8L:hover {
  transform: scale(1.03) !important;
  background-color: #60CD95;
  color: #fff;
}

.style_gestionButton__22rm7:hover {
  transform: scale(1.03) !important;
  background-color: #80b1b2;
  color: #fff;
}

.style_calendarBox__1lPnq {
  width: 100%;
  overflow: hidden;
  border: 1px solid #BFBCBC;
  padding-top: 7%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 2%;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  background: #FAFAFA;
  margin-top: 0em;
}

.style_weekBox__2dk7i {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  margin: opx auto;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  background: #FFF;
  position: relative;
  top: 50px;
  left: 2.5%;
  z-index: 2;
}

.style_dateBox__1mXve {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.style_todayBox__nxXkK {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_horizontalLine__207LO {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.style_classeCard__3t5Lq {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.style_classeCardDeleted__3QK4q {
  position: relative;
  width: 100%;
  height: 70px;
  background: #EBEBEB;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.style_classeCardReserved__3BZHN {
  position: relative;
  width: 100%;
  height: 70px;
  background: #F1FFF8;
  border: 1px dashed #4BB980;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.75;
}

.style_avatarNameBox__6qY5W {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 300px;
  object-fit: contain;
}

.style_detailsBox__1aBpq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.style_timeBox__3CycH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 30px;
}

.style_times__zSkPB {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.style_time__3ZLVf {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.style_timeDescription__2DTFd {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.style_levelsBox__G4j55 {
  width: auto;
  height: auto;
  background: #EBEAEA;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #707070;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
}

.style_cancelBox__Pg_fU {
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
  position: absolute;
  opacity: 1;
  text-align: center;
}

.style_horizontalLineDeleted__16vyv {
  height: 1px;
  width: 75%;
  background: #393E3E;
}

@media only screen and (max-width: 1050px) {
  .style_headerBox__TfEpp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    top: -50px;
  }

  .style_titleBox__2CHlH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
  }

  .style_titlePictureBox__yegSF {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .style_buttonBox__1MCSU {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }

  .style_addButton__2Uy8L {
    width: 210px !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 10px !important;
  }

  .style_gestionButton__22rm7 {
    width: 210px !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .style_optionButton__2KrKP {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    padding-top: 0px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    bottom: 10px;
  }

  .style_weekBox__2dk7i {
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    margin: 0px auto;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    background: #FFF;
    position: relative;
    top: -40px;
    left: 0%;
    z-index: 2;
  }

  .style_calendarBox__1lPnq {
    width: 100%;
    overflow: hidden;
    border: 1px solid #BFBCBC;
    padding-top: 10%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    background: #FAFAFA;
    margin-top: 0em;
    position: relative;
    top: -65px;
  }

  .style_date__2Iwzl {
    width: auto !important;
    height: 25px !important;
    border-radius: 0px !important;
    text-align: center !important;
    border: 1px solid #ccc !important;
    cursor: pointer !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;
    transition: 0.3s !important;
    -webkit-transition: 0.3s !important;
    -moz-transition: 0.3s !important;
    -ms-transition: 0.3s !important;
    -o-transition: 0.3s !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 0.6em !important;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    text-transform: capitalize;
  }

  .style_dateBox__1mXve {
    font-size: 0.7em !important;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .style_todayBox__nxXkK {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .style_horizontalLine__207LO {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .style_classeCard__3t5Lq {
    position: relative;
    width: 100%;
    height: auto;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .style_classeCardDeleted__3QK4q {
    position: relative;
    width: 100%;
    height: auto;
    background: #EBEBEB;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .style_classeCardReserved__3BZHN {
    position: relative;
    width: 100%;
    height: auto;
    background: #F1FFF8;
    border: 1px dashed #4BB980;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
  }

  .style_avatarNameBox__6qY5W {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 100%;
    object-fit: contain;
    text-align: center;
    margin-top: 5px;
  }

  .style_detailsBox__1aBpq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-right: 0px;
  }

  .style_timeBox__3CycH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    margin-top: 5px;
  }

  .style_times__zSkPB {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .style_time__3ZLVf {
    font-size: 0.68em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .style_timeDescription__2DTFd {
    font-size: 0.58em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .style_levelsBox__G4j55 {
    width: auto;
    height: auto;
    background: #EBEAEA;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #707070;
    font-size: 0.68em;
    font-family: Rubik;
    font-weight: 500;
    margin-top: 5px;
  }

  .style_cancelBox__Pg_fU {
    height: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
    position: absolute;
    bottom: 45px;
    opacity: 1;
    text-align: center;
  }

  .style_cardTime__3rOA3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    gap: 5px;
  }

  .style_horizontalLineDeleted__16vyv {
    height: 100px;
    width: 1px;
    background: #393E3E;
  }
}
.tabs___content__tabs__Nqg5m {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px auto;
}

.tabs_header__az_7a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 2em;
}

.tabs_fullHeader__2VvvC {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.tabs_tabs__3OeHb {
  width: 85px;
  height: 85px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs_fullTabs__1vcLR {
  width: 65px;
  height: 65px;
  border-radius: 10px;
  margin: 0 0.9em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs_tabs__3OeHb:hover {
  background: var(--first-color);
}

.tabs_mobileTabs__1r8iZ {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-left: 0.3em;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.tabs_chapitre__content__vLubN {
  width: 100%;
  background: #fafafa;
  padding: 3em;
  min-height: 80vh;
  margin-bottom: 5em;
}

.tabs_subtitle__2Mmos {
  text-transform: uppercase;
  margin-bottom: var();
}

.tabs_row__beVue {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
  margin-top: 2em;
}

.tabs___links__3hqFp {
  color: var(--text-color);
}

.tabs_tabButtons__3hFxS {
  display: flex;
  flex-direction: row;
  margin: 0px auto;
}

.tabs_fullscreenButton__1PW-U {
  width: 65px;
  margin-right: 12%;
}

@media only screen and (max-width: 390px) {
  .tabs_tabs__3OeHb {
    width: 90px;
  }
}

.tabs___back__3vEbK {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tabs___back__3vEbK span {
  font-family: var(--title-font);
  font-size: 1.1em;
  color: #333;
  text-transform: uppercase;
}

.tabs___back__3vEbK span:hover {
  color: var(--first-color);
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .tabs___content__tabs__Nqg5m {
    flex-direction: column-reverse;
    width: 100%;
  }

  .tabs___back__3vEbK {
    text-align: center;
  }
}

.tabs_fullscreenButton__1PW-U span {
  font-size: 12px;
  margin-left: 5px;
  margin-top: 2px;
}

@media only screen and (max-width: 767px) {

  .tabs_header__az_7a {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }

  .tabs_fullscreenButton__1PW-U {
    width: auto;
    margin-right: 0%;
    margin-bottom: 20px;
  }

  .tabs_fullscreenButton__1PW-U span {
    font-size: 10px;
    margin-left: 5px;
    margin-top: 2px;
  }

  .tabs_newButton__3Dedm {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50% !important;
    font-size: 13px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .tabs_tab__37oGk {
    width: 100px !important;
    height: 35px !important;
    margin: 5px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    background: #FAFAFA;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    opacity: 1;
    color: #868686;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
  }

  .tabs_tab__37oGk:hover {
    background: var(--first-color);
    color: #FFF;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .tabs_tabActive__259b7 {
    background: #FFFFFF;
    box-shadow: 4px 8px 16px #7FB1B234;
    border: 1px solid #7FB1B2;
    opacity: 1;
    color: #7FB1B2;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

}

.tabs_mobileMenu__ju8F6 {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.tabs_mobileMenuFullscreen__1Tz3r {
  width: 100% !important;
  height: 100%;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .tabs_fullTabs__1vcLR {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    margin: 0 0.9em;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
  }
}

/* New Css Styles */

.tabs_tab__37oGk {
  width: 180px;
  height: 50px;
  margin: 0 var(--mb-1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  background: #FAFAFA;
  border: 1px solid #B9B9B9;
  border-radius: 24px;
  opacity: 1;
  color: #868686;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.tabs_tab__37oGk:hover {
  background: var(--first-color);
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.tabs_tabActive__259b7 {
  background: #FFFFFF;
  box-shadow: 4px 8px 16px #7FB1B234;
  border: 1px solid #7FB1B2;
  opacity: 1;
  color: #7FB1B2;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.tabs_newButton__3Dedm {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.tabs_newBackButton__ysxG- {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #000 !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  text-transform: capitalize !important;
}
.cours_cours__2osG4 {
  position: relative;
  margin: 4em 0;
}

.cours_appercue__continute__1UtcV {
  width: 100%;
  height: 600px;
  margin-top: var(--mb-1);
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.cours_full_appercue__continute__1HrhJ {
  width: 70%;
  margin: var(--mb-1) auto;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 2.5em 4em;
  position: fixed;
  bottom: 0px;
  top: 12%;
  left: 0%;
  right: 0%;
}

.cours___add__input__11o3T:hover {
  color: #fff !important;
}

.cours_appercue__continute__1UtcV h3 {
  font-size: 18px;
  margin-bottom: var(--mb-2);
}

.cours_section__content__1irPh,
.cours___big__title__content__3uGAR {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 3%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.cours_appercue__continute__content__29zVo {
  margin-left: 5%;
  font-size: 0.8em;
}

.cours___big__title__31phG {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.cours_section__content__1irPh {
  background: var(--first-color);
  width: 30%;
  padding-right: 3%;
}

.cours_section__content__1irPh h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.cours___content__matiere__184Nf {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 16px;
  margin-bottom: var(--mb-2);
}

.cours_header__2L0HF {
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.cours_menu__1Y-qk {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cours_logo__2XldV {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .cours_appercue__continute__1UtcV {
    min-width: initial;
    padding: 1.5em 1em;
  }
}

.cours_spaceButton__1M37r {
  margin-top: 0px;
}

@media only screen and (max-width: 767px) {

  .cours_logo__2XldV {
    display: none;
  }

  .cours_full_appercue__continute__1HrhJ {
    width: 98%;
    margin: 40px auto;
    overflow-y: scroll;
    background: #fafafa;
    border-radius: 10px;
    padding: 0.5em 1em;
    position: fixed;
    bottom: 0px;
    top: 12%;
    left: 0%;
    right: 0%;
    font-size: 12px;
  }

  .cours___big__title__31phG {
    padding-left: 1em;
    font-size: 13px !important;
    color: #fff;
    line-height: 45px;
  }

  .cours___content__matiere__184Nf {
    text-transform: uppercase;
    font-family: var(--title-font);
    font-size: 12px;
    margin-bottom: var(--mb-2);
  }

  .cours_appercue__continute__1UtcV h3 {
    font-size: 14px;
    margin-bottom: var(--mb-2);
  }

  .cours_spaceButton__1M37r {
    margin-top: 20px;
  }

  .cours_menu__1Y-qk {
    width: auto;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cours_section__content__1irPh {
    background: var(--first-color);
    width: auto;
    padding-right: 0%;
    padding-top: 0em;
  }

  .cours_section__content__1irPh h4 {
    color: #fff;
    line-height: 45px;
    padding-left: 1em;
  }

}

.cours_newButton__2Zdc5 {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.chapitre_header__2M9ay {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.chapitre_chapitre__content__3h-rn {
  width: 100%;
  background: #fafafa;
  padding: 3em;
}

.chapitre_subtitle__2baNz {
  text-transform: uppercase;
}

.chapitre___title__uaq8V {
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-4);
}

.chapitre_card__content__2AzLr {

  margin-top: 2em;
}

.chapitre___links__2je-b {
  color: var(--text-color);
}

@media only screen and (max-width: 390px) {
  .chapitre_chapitre__content__3h-rn {
    padding: 1em;
    min-height: initial !important;
  }

  .chapitre_row__1ZKp1 {
    align-items: center;
    justify-content: center;
  }

  .chapitre_center__1HBZc {
    display: none;
  }
}

.chapitre___content__level__matier__1AyHs {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--mb-3);
  font-family: var(--title-font);
  font-size: 1.7em;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: auto;
}

.chapitre___matier__bottom__1yUTz {
  color: #333333;
}

.chapitre_content__left__1S8t4 {
  display: flex;
  align-items: center;
}

.chapitre___back__level__1wk1F {
  float: right;
}

.chapitre___back__level__1wk1F {
  font-size: 15px;
  color: #333333;
}

.chapitre___back__level__1wk1F:hover {
  color: var(--first-color);
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .chapitre___content__level__matier__1AyHs {
    width: initial;
    flex-direction: column;
  }
}
.style_title__3v3Ky {
  font-size: 2em;
}

.style_header__2nF41 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.style_main__gsXXx {
  position: relative;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-1);
  padding: 1%;
}

.style_help__33fWF {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 5%;
  top: 60%;
  width: 200px;
  height: 60px;
  background: #707070;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
}

.style_help__33fWF h4,
.style_help__33fWF p {
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #fff;
}

.style___help__images__3goJq {
  margin-top: 5px;
}

/* .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
   flex-wrap: wrap;
  background: #000;
} */

.style_row__g8FTx {
  display: grid;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  .style_row__g8FTx {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    gap: 1em;
  }
}

.style___skillbar__2HgoK {
  min-width: 200px;
}

.style___links__1BWLi {
  color: #707070;
}

.style___select__2HWeV {
  width: 500px;
}

.style___select__2HWeV div {
  position: relative;
  text-align: center;
  font-size: 2.7em;
  line-height: 0 !important;
  font-family: var(--title-font);
  text-transform: uppercase;
  border: 1px solid black !important;
}

.style___select__2HWeV div span :focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

@media only screen and (max-width: 500px) {
  .style___select__2HWeV div {
    width: 300px !important;
    font-size: 1.8em;
  }

  .style_iconArrowClass__2ovqR {
    width: 20px;
    font-size: 12px !important;
    top: -1px !important;
    right: 20px !important;
  }

  .style_header__2nF41 {
    margin-top: var(--mb-1);
  }

  .style_row__g8FTx {
    justify-content: center;
  }
}

.cerateExo_exo__2qFMW {
  position: relative;
  margin-top: 4em;
}

.cerateExo_center__3s0n_ {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.cerateExo_left__EwW6Y .cerateExo_subtitle__awdio {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.cerateExo_header__2Jt-F {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.cerateExo_teacher__maa__3Dq9g {
  position: absolute;
  width: 300px;
  height: 420px;
  top: -9%;
  left: 5%;
  background: url(/static/media/teacherMaa.068865cd.svg) no-repeat
    center center;
  background-size: contain;
  z-index: 1;
}

.cerateExo_button__2LnJo svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.cerateExo_form__container__1obX5 {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.cerateExo_select__sEpcl {
  height: 40px;
  width: 500px;
  border-radius: 10px;
  padding-top: 4px;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cerateExo_field__1Mg3s {
  width: 100%;
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.cerateExo_field__1Mg3s::placeholder {
  font-style: italic !important;
}

.cerateExo_main__2G2EB {
  width: 100%;
  height: 100%;
  margin: 2em 0;
  padding: var(--mb-4) var(--mb-3);
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  grid-gap: 2em;
  gap: 2em;
  border-radius: 10px;
}

.cerateExo_main__exo__edit__3cEiP {
  background: #fafafa;
  padding: 0 2%;
}

.cerateExo_form__2Ghj5,
.cerateExo_preview__1Zuip {
  flex: 1 1;
}

.cerateExo_timline__exo__edit__3zaeH {
  padding-left: 2rem;
}

.cerateExo_label__zEIZc {
  margin-bottom: var(--mb-1);
}

.cerateExo_input__yVbA7 {
  width: 100%;
  height: 40px;
  padding-top: 3px;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-3);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cerateExo_main__title__3I_wg {
  text-align: center;
  font-size: 18px;
  margin-bottom: var(--mb-6);
  margin-top: var(--mb-5);
}

.cerateExo___custom__ck_editor__2IcMy {
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 5px;
  min-height: 503px;
  margin-bottom: 26px;
}

.cerateExo___submit__validation__281_P {
  min-width: 270px;
  max-width: 270px;
}

.style_container__1donp {
  max-width: 650px;
  margin-top: 3.5em;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.style___title__2YiDl {
  text-align: left;
  margin-left: 15%;
  margin-bottom: var(--mb-6);
}

.style_timeline_title__1UhX- {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: var(--h2-font-size);
}

.style_timeline__2PZiw {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 30px;
  color: var(--text-color);
}

.style_timeline__2PZiw:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: var(--first-color);
}

.style_timeline__2PZiw .style_timeline_container__1MPsi {
  position: relative;
  margin-bottom: 3.5rem;
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_icon__1eUw5 {
  position: absolute;
  left: -77px;
  top: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  /* background: #4f537b; */
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_icon__1eUw5 i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_icon__1eUw5 img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_body__3q1WG {
  background: #fff;
  border-radius: 10px;
  padding: 20px 20px 15px;
  /* margin-top: 4em; */
  height: 505px;
  overflow-y: scroll;
  overflow-x: scroll;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_body__3q1WG:before {
  content: "";
  background: inherit;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: -10px;
  transform: rotate(45deg);
  border-radius: 0 0 0 2px;
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_body__3q1WG .style_timeline-title__1SmZc {
  margin-bottom: 1.4rem;
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_body__3q1WG .style_timeline-title__1SmZc .style_badge__2FOHV {
  background: #4f537b;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
}

.style_timeline__2PZiw .style_timeline_container__1MPsi .style_timeline_body__3q1WG .style_timeline-subtitle__310Ki {
  font-weight: 300;
  font-style: italic;
  opacity: 0.4;
  margin-top: 16px;
  font-size: 11px;
}

/* .timeline .timeline_container.primary .timeline_icon {
  background: var(--first-color) !important;
}

.timeline .timeline_container.info .timeline_icon {
  background: #11cdef !important;
}

.timeline .timeline_container.success .timeline_icon {
  background: #00bf9a !important;
}

.timeline .timeline_container.warning .timeline_icon {
  background: #ff8d72 !important;
}

.timeline .timeline_container.danger .timeline_icon {
  background: #fd5d93 !important;
} */

.style_dashboard__1n-xZ {
  margin-top: 1em;
}

.style_add-primary-btn__vf2_k {
  min-height: 150px;
  min-width: 100%;
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style_header__Stlf3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0.5em;
}

.style_main__3kErM {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.style_center__2AONH {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.style_left__3Td79 .style_subtitle__21i0B {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.style___flex__39p10 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.style_justify__center__3wvRx {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.style_main__3kErM .style_annonces__btn__y5Mjh {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin: 3.5em 0;
}

.style_drawer__KHpFI {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.style_drawer__content__3Ys8O {
  width: 100%;
}

.style_drawer__content__3Ys8O * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.style_field__245s7 {
  height: 45px;
  border: 1px solid var(--text-color);
  padding: 5px 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.style_drawer___header__3XpYW {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.style_drawer___header__3XpYW h3 {
  font-family: var(--title-font);
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

.style_back__2ihy8 {
  position: absolute;
  left: 0;
  top: 30px;
  left: -10%;
  cursor: pointer;
}

/* Modal */

.style___success__modal_container__1F0_D {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_modal__card__ud-XH {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.style_modal__card__ud-XH .style_modal__card__title__1pukc {
  font-family: var(--title-font);
  font-size: 3em;
  text-transform: uppercase;
}

.style___matier__4XwHd {
  width: 200px;
  height: 40px;
  margin: var(--mb-3) 0;
  border-radius: 10px;
  background: #d5d5d5;
  font-size: 0.8rem;
}

.style___icon_matiere__VQRUG {
  position: relative;
  top: 2px;
  left: -4px;
}

.style___modal__img__success__20Itt {
  margin-bottom: var(--mb-4);
}

.style___code__access__title__16qj1 {
  text-transform: uppercase;
  font-size: 1em;
  color: #707070;
  margin-bottom: 10px;
  font-weight: 500;
}

.style___code__n8G3f {
  width: 250px;
  height: 45px;
  border: 1px dashed #707070;
  display: flex;
  border-radius: 50px;
  color: #707070;
  margin-bottom: var(--mb-6);
}

.style___code__n8G3f span {
  margin: auto;
  font-size: 1.5em;
  font-weight: 600;
}

.style_modal__card__ud-XH button:last-child {
  margin-left: 10px;
}

.style___btn__1WF71 {
  max-width: 200px;
  min-width: 200px;
}

.style_close__1HEpu {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.style_dashboardUserBox__Tt2vq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
}

.style_dashboardUserMessage__3q81K {
  font-size: 1.8em;
  color: black;
  margin: 0px auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 767px) {

  .style_dashboardUserMessage__3q81K {
    font-size: 1.2em;
    color: black;
  }

  .style_header__Stlf3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .style_center__2AONH {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }

  .style_filed__search__2-18q {
    width: 65%;
    height: 40px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
  }

  .style_select__OfgsS {
    height: 40px;
    width: 160px;
    border-radius: 10px;
    border: 1px solid var(--text-color);
  }

  .style___submit__hidden__2wqoX {
    position: relative !important;
    top: 0px !important;
    margin: 0px auto !important;
  }

  .style___reset__btn__39NPo {
    position: relative !important;
    right: 0% !important;
    bottom: 0% !important;
    top: 10px !important;
  }

  .style_modal__card__ud-XH .style_modal__card__title__1pukc {
    font-size: 2em;
  }

  .style___btn__1WF71 {
    max-width: 150px;
    min-width: 150px;
  }

}

.style_publications__3DTDb {
  margin: 4em 0;
}

.style_center__1dERn {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin-left: var(--mb-4);
}

.style_header__sZtaF {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.style_left__3pnNi .style_subtitle__24PFF {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.style_main__ZkdsT {
  margin-top: 4em;
}

.style_grid__16hD3 {
  display: grid;
  grid-row-gap: 0.8rem;
  row-gap: 0.8rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media only screen and (min-width: 768px) {
  
  .style_grid__16hD3 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.3em;
    gap: 1.3em;
  }

}

@media only screen and (max-width: 767px) {

  .style_header__sZtaF {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .style_center__1dERn {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }
}

.card_card__--tyu {
  width: 280px;
  height: 260px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  padding-top: 1.5em;
  padding-bottom: var(--mb-6);
  margin-right: 1em !important;
  background: url(/static/media/card.b30b4ea7.svg) center center no-repeat;
  background-size: contain;
}

.card_card__--tyu {
  font-size: var(--h3-font-size);
  color: #707070;
  font-weight: 500;
}

.card_card__--tyu img {
  cursor: pointer;
  /* width: 50px; */
  margin: auto;
}

.card_type__content__M1ZmC {
  display: flex;
  margin-top: 10px;
}


.card_code__1PdJG span {
  font-size: var(--smaller-font-size);
}

.card_cours__number__2bNAm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
}

.card_cours__number__2bNAm span {
  font-size: 2.3em;
  line-height: 30px;
}

.card____state__my__content__kJXow span {
  font-size: 12px;
  line-height: 30px;
}

.card____state__my__content__kJXow strong {
  font-size: 13px;
}

.card_matiers__2ay5- {
  width: 200px;
  height: 38px;
  background: #D5D5D5;
  border-radius: 10px;
  padding-left: 15px;
}

.card_matiers__2ay5- span {
  position: relative;
  margin-top: 0;
  font-size: var(--smaller-font-size);
  text-transform: uppercase;
  top: -10px;
}

.card_matiers__2ay5- .card_cours__17FFC {
  padding-right: 10px;
  border-right: 2px solid var(--text-color);
}

.card_matiers__2ay5- .card_classes__2N-pl {
  padding-left: 6px;
}

.card_states__17jIU {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: var(--mb-3);
  padding-top: var(--mb-2);
}

.card_states__17jIU span {
  position: relative;
  top: 0;
  padding-left: 5px;
  color: var(--first-color);
  font-weight: 600;
  font-size: 1em;
}

.card_card__--tyu .card_button__3gQ6I {
  max-width: 170px;
  min-width: 170px;
  height: 30px !important;
  font-size: 10px !important;
}
.content_center__1upX7 {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin-left: var(--mb-4);
}

.content_header__2Xl2E {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.content_left__1_NWe .content_subtitle__IAJZt {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/*
.row {
  width: 75%;
  margin-top: ;
  display: flex;
  justify-content: space-around;
  margin-right: auto;
} */

.content_row__1CcJY {
  width: 100%;
  display: flex;
  margin-top: 4em;
  /* grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); */
}

.content_button__2gvBy {
  max-width: 240px !important;
  min-width: 240px !important;
}

@media only screen and (max-width: 768px) {

  .content_row__1CcJY {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4em;
  }

}
.styles_exo__1Ebb_ {
  position: relative;
  margin-top: 4em;
}

.styles_center__t7OqY {
  width: 100%;
  flex: 2 1;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.styles_left__2O_tJ .styles_subtitle__3TeyV {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.styles_header__1AAc4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.styles_button__14peT svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.styles_form__container__3dIEH {
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.styles_select__2JvsQ {
  height: 40px;
  width: 500px;
  border-radius: 10px;
  padding-top: 4px;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-2);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.styles_field__14dCc {
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.styles_field__14dCc::placeholder {
  font-style: italic !important;
}

.styles_main__2HVxE {
  width: 100%;
  height: 100%;
  margin: 2em 0;
  padding: var(--mb-4) var(--mb-3);
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  grid-gap: 2em;
  gap: 2em;
  border-radius: 10px;
}

.styles_main__exo__edit__2n6cy {
  background: #fafafa;
  padding: 0 2%;
}

.styles_form__repVA,
.styles_preview__KMVwS {
  flex: 1 1;
}

.styles_timline__exo__edit__2NUKo {
  padding-left: 2rem;
  margin-top: 19em;
  padding-top: 0;
}

.styles_container__timeline__1uR2O {
  margin-bottom: 6.5em !important;
}

.styles_label__2b9C4 {
  margin-bottom: var(--mb-1);
}

.styles_input__3n-lm {
  width: 100%;
  height: 40px;
  padding-top: 3px;
  border: 1px solid var(--text-color);
  margin-bottom: var(--mb-3);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.styles_main__title__1y4FA {
  text-align: center;
  font-size: 18px;
  margin-bottom: var(--mb-6);
  margin-top: var(--mb-5);
}

.styles___custom__ck_editor__2KFY9 {
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 5px;
  min-height: 503px;
  margin-bottom: 26px;
}

.styles___submit__validation__3dMzc {
  min-width: 270px;
  max-width: 270px;
}

.styles_filter__3IyLc {
  position: relative;
  width: 100% !important;
  height: 80px;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  background: #FAFAFA;
  border-radius: 10px;
  padding: 0 2em;
}

.styles_main__Gxtd8 {
  width: 100%;
  height: 100%;
  margin-top: var(--mb-4);
  margin-bottom: 4em;
  /* background: #fafafa;
  padding: 2%; */
}

.styles_search__1BASW {
  width: 32%;
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.styles_search__1BASW * button {
  background: unset !important;
  border: none !important;
}


.styles_search__1BASW * svg {
  position: relative;
  top: 2px;
  fill: var(--text-color);
  font-size: 22px;
}

.styles_select__2KuRi {
  border: 1px solid #636363;
  border-radius: 10px;
  height: 42px;
}

.styles____row__RhELd {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
}

@media only screen and (max-width: 390px) {
  .styles_left__2VwTq .styles_subtitle__28Tj6 {
    font-size: var(--h2-font-size);
    max-width: 100px;
  }
}

@media only screen and (min-width: 390px) {}

@media only screen and (min-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 767px and up) */
@media only screen and (max-width: 767px) {
  .styles_filter__3IyLc {
    position: relative;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    border-radius: 10px;
    padding: 0 2em;
  }
  .styles_search__1BASW {
    width: 100%;
    border: 1px solid #636363;
    border-radius: 10px;
    height: 42px;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .styles_select__2KuRi {
    width: 100%;
    border: 1px solid #636363;
    border-radius: 10px;
    height: 42px;
  }
  .styles_resetButton__385UH {
    margin-top: 10px;
  }
  .styles_main__Gxtd8 {
    width: 100%;
    height: 100%;
    margin-top: 70px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

.styles___reset__btn__86kr- {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.styles___submit__hidden__24gNX {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}
.easyQuiz_header__BO2Mo {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFAFA;
  margin: 1em 0;
  border-radius: 10px;
  padding: 0 2em;
}

.easyQuiz_center__3CtBu {
  text-align: right;
  width: 34%;
  font-size: 2em;
}

.easyQuiz_center__3CtBu h3 {
  color: var(--text-color);
}

.easyQuiz_select__H--8m {
  width: 200px;
  height: 42px;
  padding-top: 5px;
  text-align: left;
  border: 1px solid var(--text-color);
  border-radius: 10px;
}

.easyQuiz_grid__26rKt {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.easyQuiz___row__4wIR9 {
  width: 100%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
}

.easyQuiz___app__btn__2D4s3 {
  position: relative;
  min-width: 370px;
  font-family: var(--title-font);
  font-size: 1em !important;
  font-weight: 600 !important;
  color: #fff !important;
  padding-right: 10px !important;
  border-radius: unset !important;
}

.easyQuiz___content__quiz__1FrIe {
  position: relative;
  top: -2px;
  margin-left: 20px;
}

.easyQuiz___check__3mSDh {
  color: #fff;
  border-radius: 30px !important;
}

@media only screen and (max-width: 767px) {

  .easyQuiz_header__BO2Mo {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    margin: 1em 0;
    border-radius: 10px;
    padding: 0 2em;
    margin-bottom: 70px;
  }

  .easyQuiz_select__H--8m {
    width: 100%;
    min-width: 320px;
    height: 42px;
    padding-top: 5px;
    text-align: left;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .easyQuiz_formElement__1EgaQ {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

/* New Css Styles */

.easyQuiz_main__3tnLl {
  margin-top: 10px;
  margin-bottom: 80px;
}

.easyQuiz_quizBox__16w74 {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
  margin-top: 40px;
}
.login_title__1WNFk {
  margin-bottom: var(--mb-2);
  cursor: pointer;
}

.login_titleLogin__2SiDT {
  color: var(--first-color);
}

.login_separator__2Oqrr {
  position: relative;
  display: block;
  width: 1px;
  height: 40px;
  background: var(--text-color);
  top: 2px;
}

.login_info__DFXD2 {
  text-align: center;
  margin: var(--mb-1) 0;
}

.login_info__DFXD2 a {
  text-decoration: underline;
  color: var(--text-color);
}

.login_info__DFXD2 strong {
  font-weight: 500;
  font-size: 14px;
}

.login_title__1WNFk:hover {
  color: var(--first-color);
}

.login_login__6Vv5l {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
}

.login_content__action__3IcAn {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.login_button__2fPnc svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.login_form__container__3pyeh {
  width: 100% !important;
  max-width: 400px !important;
  margin: 10px auto !important;
}

.login_field__1wo5E {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.login_field__1wo5E::placeholder {
  font-style: italic !important;
}

.login_submit__3CHtE {
  width: 100%;
  height: 40px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 1em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

/*  */

@media only screen and (max-width: 500px) {
  .login_separator__2Oqrr {
    height: 25px;
  }

  .login_field__1wo5E {
    height: 40px !important;
  }

  .login_submit__3CHtE {
    height: 35px !important;
    font-size: 0.9em !important;
  }

  .login_field__1wo5E::placeholder {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 767px) {

  .login_title__1WNFk {
    font-size: 1.45em;
  }

}

.login_forgotPasswordButton__2uG9Q {
  font-size: 18px;
  color: #333;
}

.login_forgotPasswordButton__2uG9Q:hover {
  color: rgba(90, 132, 132, 1);
}


/* New CSS Style */

.login_mainContainer__FH4NO {
  position: relative;
  top: 0px;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 2%;
}

.login_loginBox__1OqRg {
  width: auto;
  height: auto;
  background: #FAFAFA;
  box-shadow: 4px 8px 16px #80808029;
  border: 1px solid #BFBCBC;
  border-radius: 42px;
  opacity: 1;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
}

.login_avatarBox__3Q1vv {
  margin-top: 25px;
  height: 100px;
  width: 100px;
  background-color: #ffedd5;
  border: 2px solid #FFF;
  border-radius: 50%;
  box-shadow: 4px 8px 16px #80808029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_infos__3pqWF {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.login_infos__3pqWF a {
  text-decoration: underline;
  color: var(--text-color);
}

.login_infos__3pqWF strong {
  font-weight: 500;
  font-size: 14px;
}

.login_actionTitle__3EzTQ {
  font-size: 30px;
  font-family: rubik;
  font-weight: 500;
  color: #8AB0B1;
  margin-top: 5px;
}

.login_arrowDown__3HCYr {
  position: relative;
  left: -10%;
  top: -20px;
}

.login_formContainer__2gRP7 {
  width: 100% !important;
  max-width: 740px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  top: -15px;
}

.login_inoutField__1j41x {
  height: 60px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.login_inoutField__1j41x::placeholder {
  font-style: italic !important;
}

.login_submitButtonBox__2A14O {
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_submitButton__2YND3 {
  width: 264px !important;
  height: 60px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 18px !important;
  text-transform: none;
  text-align: center;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 0px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.login_newAccountBox__2X-ju {
  width: 950px;
  height: auto;
  background-color: #edfff6;
  border: 1px solid #4BB980;
  border-radius: 42px;
  opacity: 1;
  margin: 50px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 2;
}

.login_newAccountButton__GY7iA {
  width: 290px !important;
  height: 56px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 18px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  padding-top: 0px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
  -moz-box-shadow: 5px 7px 1px -1px #4BB980;
}

.login_noAccountMessage__2-fc2 {
  font-size: 22px;
  font-family: rubik;
  font-weight: 500;
  color: #4BB980;
  margin-left: 15px;
}

.login_arrowRight__3LtrL {
  position: relative;
  top: 10px;
}

.login_lines__1J82a {
  position: absolute;
  left: 20px;
  top: -7%;
  z-index: -1;
}

.login_ellipseOne__1lfob {
  position: absolute;
  top: -30px;
  right: 2%;
  z-index: -1;
}

.login_ellipseTwo__2xD2W {
  position: absolute;
  top: 20%;
  left: 20px;
  z-index: -1;
}

.login_ellipseThree__z_It6 {
  position: absolute;
  top: 220px;
  right: 4%;
  z-index: -1;
}

.login_ellipseFour___vhSR {
  position: absolute;
  top: 77%;
  right: 8%;
  z-index: -1;
}

.login_ellipseFive__3UH1a {
  position: absolute;
  top: 85%;
  left: 7%;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .login_mainContainer__FH4NO {
    padding: 0 4%;
    margin: 20px auto;
  }

  .login_loginBox__1OqRg {
    width: 95%;
    height: auto;
    background: #FAFAFA;
    box-shadow: 4px 8px 16px #80808029;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    z-index: 2;
  }

  .login_avatarBox__3Q1vv {
    margin-top: 0px;
  }

  .login_infos__3pqWF {
    text-align: center;
    margin-top: 20px;
    font-size: 0.85em;
  }

  .login_infos__3pqWF a {
    text-decoration: underline;
    color: var(--text-color);
  }

  .login_infos__3pqWF strong {
    font-weight: 500;
    font-size: 0.85em;
  }

  .login_actionTitle__3EzTQ {
    font-size: 14px;
    font-family: rubik;
    font-weight: 500;
    color: #8AB0B1;
    margin-top: 5px;
  }

  .login_formContainer__2gRP7 {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    position: relative;
    top: 20px;
  }

  .login_inoutField__1j41x {
    height: 38px !important;
    border-radius: 8px !important;
    border: 1px solid var(--text-color) !important;
    font-size: 12px !important;
  }

  .login_inoutField__1j41x::placeholder {
    font-style: italic !important;
    font-size: 12px !important;
  }

  .login_submitButton__2YND3 {
    width: 160px !important;
    height: 35px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 12px !important;
    text-transform: none;
    text-align: center;
    background: var(--first-color) !important;
    color: #fff !important;
    padding-top: 0px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  }

  .login_newAccountBox__2X-ju {
    width: 80%;
    height: auto;
    background-color: #edfff6;
    border: 1px solid #4BB980;
    border-radius: 24px;
    opacity: 1;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    z-index: 2;
  }

  .login_newAccountButton__GY7iA {
    width: 150px !important;
    height: 35px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 12px !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    padding-top: 0px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
    -moz-box-shadow: 5px 7px 1px -1px #4BB980;
  }

  .login_noAccountMessage__2-fc2 {
    font-size: 14px;
    font-family: rubik;
    font-weight: 500;
    color: #4BB980;
    margin-left: 15px;
  }

  .login_albertHead__aLoul {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.style_selectInput__N3DJZ {
    color: #707070;
    border-radius: 8px;
    border: 1px solid #707070;
    font-style: italic !important;
    width: 100%;
}

.style_placeholderStyle__1FHMC {
    color: #C0C0C0;
    font-size: 14px;
}

.style_card__37Fl5 {
    position: relative;
    overflow: hidden;
    width: 100% !important;
    height: 300px !important;
    padding: 15px;
    background-size: contain;
    background: #fafafa;
    box-shadow: 3px 2px 0px 2px #cfd0d2;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.style_card__37Fl5:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 70px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.style_card__37Fl5::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 50px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.style_filedSearch__3CUoe {
    width: 300px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 2px;
    margin-top: 20px;
}

.style_studentCard__1BwUq {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 12px;
    border: 1px solid #C0C0C0;
    cursor: pointer;
    background: #FFF;
}

.style_studentSelectedCard__9sOE- {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 12px;
    border: 1px solid #C0C0C0;
    cursor: pointer;
    background: #5a8484;
}

.style_studentCard__1BwUq:hover {
    background: #cbf2eb;
}

.style_inoutField__1ocnE {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
}

.style_submitButton__1SQKE {
    width: 120px !important;
    height: 40px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 16px !important;
    text-transform: none;
    text-align: center;
    background: var(--first-color) !important;
    color: #fff !important;
    padding-top: 0px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

@media only screen and (max-width: 767px) {
    .style_placeholderStyle__1FHMC {
        color: #C0C0C0;
        font-size: 12px;
    }
}

.style_levels__1Rngg div:last-child {
    display: none;
}
/**************************************** CSS ****************************************/

.register_step_container__XdxiU {
  display: none;
}

.register_register__teacher__3BVFf {
  width: 100%;
  text-align: left;
}

.register_step__2rNG_ {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
}

/* JOFREY STYLE */

.register_accountFormBox__3k2aC {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  height: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.register_formBox__18_vz {
  width: 100%;
  max-width: 100%;
  margin: 70px auto;
  box-shadow: 4px 8px 16px #80808029;
  border: 1px solid #BFBCBC;
  border-radius: 32px;
  background-color: #fafafa;
  height: auto;
  padding: 30px 40px 120px 40px;
  display: flex;
  flex-direction: column;
}

.register_formElementBox__1RfYl {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.register_stepTitleBox__3LYjq {
  text-align: left;
  align-items: flex-start;
  align-content: start;
}

.register_boxTitle__2nSkX {
  font-size: 28px;
  font-weight: 600;
  color: #707070;
  font-family: "CartoonFont";
  text-align: left;
  text-transform: uppercase;
}

.register_boxSubtitle__2tny_ {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.register_boxMessage__2P3kO {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 35px;
}

.register_selectInput__9lfk6 {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #707070;
  font-style: italic !important;
  width: 100%;
}

.register_placeholderStyle__2Gfjy {
  color: #C0C0C0;
  font-size: 16px;
}

.register_nextButton__3Mvi1 {
  font-size: 18px;
  padding: 20px;
}

.register_hideElement__3y-Ft {
  display: none;
}

.register_uploadButtonStyle__1K77P {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
}

.register_uploadVignetteButton__9Qz7r {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 130px;
}

.register_startRecordingVideo__1yN7f {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.register_cardInfoBox__3dObv {
  background-image: url(/static/media/card.b30b4ea7.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 35px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxToDo__2H4kK {
  background-image: url(/static/media/cardLong.0806ab99.png);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxWarning__1tabw {
  background-image: url(/static/media/cardAds.9ca57eea.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 0px;
  padding: 0;
}

.register_avatarExampleBox__-Mgfg {
  background-image: url(/static/media/pictureExample.bc58bf9e.png);
  background-size: cover;
  height: 120px;
  width: 380px;
  border-radius: 8px;
  margin: 5px 7px 0px 35px;
}

/* Drag & Drop Input CSS */

#register_formFileUpload__1Ex9U {
  height: 425px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#register_inputFileUpload__1i8FM {
  visibility: collapse;
}

#register_labelFileUpload__3SfR- {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #707070;
  background-color: #fff;
}

#register_labelFileUpload__3SfR-.register_dragActive__1SpCT {
  background-color: #ffffff;
}

.register_uploadButton__SlVGZ {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.register_uploadButton__SlVGZ:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#register_dragFileElement__2F34y {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* Drag & Drop Input CSS */

.register_uploadImageBox__2mbjE {
  width: 45% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.register_uploadImageBoxTitle__35mBG {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_uploadImageBoxMessage__5vC9D {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_calendarBoxTitle__3V_3P {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
}

.register_calendarBoxSubtitle__2p0qY {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin: 30px auto;
  padding-left: 210px;
}

.register_calendareBoxMessage__2HEAL {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubMessage__SMQ_V {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxTitle__2439m {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 45px;
  margin-left: 35px;
}

.register_uploadVideoBoxMessage__1EYPn {
  font-size: 14.5px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubtitle__FfDbt {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_addPictureButton__3JgPh {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.register_addDocPictureButton__Y1Qdl {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.register_addVideoButton__2FL44 {
  height: 32px;
  width: auto;
  margin-left: 35px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.register_trashIcon__1EVC2 {
  filter: invert(39%) sepia(100%) saturate(7463%) hue-rotate(359deg) brightness(106%) contrast(113%);
  height: 30px;
  margin-left: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.register_addVignetteButton__32Qcn {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 30px;
}

.register_previewBox__3bE2q {
  margin-left: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #707070;
  border-style: dashed;
}

.register_previewBoxImage__qOkAG {
  max-width: 200px;
  height: auto;
  border-radius: 15px;
}

.register_uploadBox__2CXa5 {
  display: flex;
  flex-direction: column;
}

.register_uploadBoxTitle__2QLA7 {
  font-size: 20px;
  font-style: italic;
  color: #333;
}

.register_uploadBoxMessage__WfoLB {
  font-size: 20px;
  font-style: italic;
  color: #CBCACD;
}

.register_uploadBoxMessageError__2Xdy_ {
  font-size: 20px;
  font-style: italic;
  color: red;
}

.register_uploadBoxBorder__1ot8U {
  margin-left: 35px;
  margin-top: 25px;
  border: none;
  border-radius: 15px;
}

.register_uploadBoxBorderError__2foaB {
  margin-left: 35px;
  margin-top: 25px;
  border: 1px solid red;
  border-radius: 15px;
}

.register_exampleBox__Jj376 {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_exampleBoxLong__26m2A {
  width: 40% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-right: 40px;
}

.register_exampleBoxTitle__1O8dy {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_videoExampleBoxTitle__3CYnf {
  font-size: 15px;
  font-weight: 500;
  color: #7FB1B2;
  margin-top: 30px;
  margin-left: 0px;
}

.register_instructionBox__oTsHG {
  padding-left: 65px;
  padding-right: 60px;
  margin-top: 42px;
}

.register_instructionBoxLong__p0Tvs {
  padding: 60px 40px 10px 65px;
  margin-top: 42px;
}

.register_checkboxBox__60AD0 {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.register_checkboxBoxItem__1qpGp {
  margin-left: 10px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxItemLong__2LdmI {
  margin-left: 5px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxLong__2Tegy {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.register_customTextarea__2S2Sr {
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

/* Placeholder color */
.register_customTextarea__2S2Sr::placeholder {
  color: #C0C0C0;
}

/* Focus color */
.register_customTextarea__2S2Sr:focus {
  outline: none;
  border-color: #707070;
  box-shadow: 0 0 2px green;
}

#register_videoFileUpload__2uFKZ {
  background-image: url(/static/media/videoExample.d39fc304.png);
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 440px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-left: 35px;
  margin-top: 30px;
  border-radius: 24px;
}

.register_vignetteBox__1QZZa {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0px 0px 35px;
  padding: 15px 20px 45px 20px;
  width: 95%;
  align-items: center;
  justify-content: left;
}

/* .step .stepsBox {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
} */

.register_addCertificateDiplomaButton__2m5J2 {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 50px;
}

.register_customButtonStyle__oOT92 {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.register_customButtonStyle__oOT92:hover {
  transform: scale(1.03) !important;
}

.register_customButtonStyle__oOT92:active {
  transform: scale(0.99) !important;
}

.register_stopButtonStyle__21JjT {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: red;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgb(182, 85, 85) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
  -moz-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
}

.register_stopButtonStyle__21JjT:hover {
  transform: scale(1.03) !important;
}

.register_stopButtonStyle__21JjT:active {
  transform: scale(0.99) !important;
}

.register_certificateDiplomaFormBox__30X3B {
  margin: 0px auto;
  height: auto;
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.register_pictureBoxRow__o8mmF {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.register_picturePreviewBox__1uEwp {
  height: auto;
  width: auto;
  border: 1px solid #333;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
}

.register_titleBox__PalBu {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 74%;
}

.register_videoPreviewBox__YUgce {
  height: auto;
  width: 560px;
  border-radius: 5px;
  margin-left: 35px;
  margin-top: 25px;
}

.register_videoLinkInput__tU3EB {
  margin-left: 35px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 95%;
}

.register_videoFormatTitle__1TcrL {
  font-size: 13px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 10px;
}

.register_calendarTimezone__12HOe {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.register_fillLaterButton__1KZlI {
  height: 50px;
  width: 65%;
  max-width: 40%;
  margin-left: 0px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.register_removeButton__2ClDe {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
  padding: 5px;
  border-radius: 50px;
  border: none;
  background-color: red;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.register_removeButton__2ClDe:hover {
  padding: 7px;
  background-color: #fff;
  border: 1px solid red;
  color: red;
  cursor: pointer;
}

.register_previewCertificateDiploma__3_Lz3 {
  height: 110px;
  width: 110px;
  position: relative;
  bottom: 40px;
  margin-top: 10px;
}

/* JOFREY STYLE */

.register_teacher__3t7Eg {
  display: flex;
  align-items: center;
  margin: 0px auto;
}

.register_teacher__3t7Eg .register_avatar__20lhg {
  padding-right: var(--mb-4);
}

.register_header__text__sGKnU {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.register_header__text__sGKnU p {
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.register_header__text__sGKnU p strong,
.register_text__3zbU- strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register_text__3zbU- a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_content__action__3t2bN {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0px auto;
}

.register_button__zw2hV svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.register_form__container__3yykO {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.register_field__2TajY {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
}

.register_field__2TajY::placeholder {
  font-style: italic !important;
}

.register_termes__legals__2XFi- {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.register_termesToAccept__1-TfR {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.register_button__zw2hV {
  width: 100%;
  height: 40px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.register_text__legal__k6Y9P {
  text-align: left;
  font-size: var(--small-font-size);
}

.register_inputRow__zC5ir {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.register_inputRowChild__2qxwA {
  width: 49%;
}

.register_avatarContainer__2saVL {
  display: flex;
  flex-direction: row;
}

.register_rowButtons__2dWJt {
  display: flex;
  width: 100%;
  margin: 40px auto;
  align-items: center;
  justify-content: center;
}

.register_rowsButtons__1WP5K {
  display: flex;
  width: 100%;
  margin: 40px auto;
  align-items: center;
  justify-content: center;
}

.register_rowButtonChild__Ry1aM {
  max-width: auto;
  margin: 0 10px;
}

.register_formContainer__1BLk4 {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_docBox__SS0fL {
  height: auto;
  width: 100%;
  border: 1px solid #707070;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.register_docBoxImage__2KaFC {
  margin: 20px 0px 0px 15px;
  display: flex;
  flex-direction: row;
}

.register_docBoxTitle__191m4 {
  font-size: 15px;
  color: #111;
  font-weight: 700;
  margin-left: 7px;
}

.register_docBoxMessage__2uENJ {
  margin: 0px 0px 0px 40px;
  font-size: 14px;
  color: #707070;
}

.register_docBoxMessageFormat__1fsmB {
  font-size: 12px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 20px;
}

.register_uploadInputForm__3K-L6 {
  width: 100%;
  visibility: visible;
  margin-left: 35px;
}

.register_uploadFileInput__2P3A5 {
  height: 0px;
  visibility: collapse;
  margin-left: 35px;
}

.register_uploadDocBox__1KE4e {
  height: 30px;
  position: relative;
  top: -30px;
  left: 5px;
}

.register_teacherDescriptionTitle__3ogvi {
  font-size: 20px;
  color: #333;
  font-weight: 500;
}

.register_teacherDescriptionBox__Jg9Jr {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin: 35px 0px 20px 50px;
}

.register_stepFiveContent__1bLOk {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_textareaMaxText__3BODd {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  font-style: italic;
  color: #636363;
}

.register_textareaMessageFull__1xJvw {
  display: inline;
  font-size: 12px;
  font-style: italic;
  color: #CBCACD;
}

.register_textareaMessageSmall__2UTcT {
  display: none;
}

.register_customTextarea__2S2Sr {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.register_customDescriptionTextarea__3mK4E {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.register_customLinkedTextarea___vmo1 {
  display: none;
}

.register_linkedInput__3u0-N {
  display: block;
}

.register_linkedError__3x8jJ {
  font-size: 12px;
  color: red;
}

.register_thumbnailMessage__36qms {
  font-size: 12px;
  color: #707070;
  margin-left: 25px;
}

.register_explanationBox__25nVE {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
}

.register_explanationBoxTitle__id5_s {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
}

.register_questionMark__3mo6Q {
  margin-top: 0px;
  margin-right: 5px;
}

.register_calendarBox__2TCmW {
  background-color: #fff;
  padding: 20px;
}

.register_stopButton__36jku {
  position: fixed;
  bottom: 20%;
  right: 20%;
  width: 70px;
  height: 70px;
  background-color: red;
  cursor: pointer;
}

.register_videoLoading__hCg_G {
  position: fixed;
  left: 46%;
  top: 43%;
  font-family: "CartoonFont";
  font-size: 34px;
  color: #fff;
}

.register_videoRecorderBox__1bM4I {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 12.9%;
  right: 12.9%;
  background: black;
}

.register_videoRecorderClearBox__3CKXD {
  display: flex;
  flex-direction: column;
  margin-top: 4.5%;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 0px;
  right: 0px;
  bottom: 2%;
}

.register_stopButtonBox__1sxL1 {
  position: fixed;
  bottom: 25%;
  right: 20%;
}

/* New Css Style */

.register_avatarBox__3Z5FX {
  width: 144px;
  height: 146px;
  background: #ffedd5;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #FFF;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.register_pageTitle__1IPN2 {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  opacity: 0.7;
}

.register_descriptionTitle__1yLLQ {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 400;
  color: #707070;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.register_newButton__2EIsN {
  width: auto !important;
  height: 45px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
  -moz-box-shadow: 5px 7px 1px -1px #4BB980;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 30px !important;
}

.register_newActionButton__2fJJC {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
  -moz-box-shadow: 5px 7px 1px -1px #4BB980;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 0px !important;
}

.register_newMoveButton__2i9KH {
  width: 160px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.register_newPictureButton__16ogU {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none !important;
  text-align: center !important;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 0px !important;
}

.register_calendarButton__39-by {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none !important;
  text-align: center !important;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 0px !important;
}

.register_newButton__2EIsN:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.register_newActionButton__2fJJC:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.register_newPictureButton__16ogU:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.register_calendarButton__39-by:hover {
  transform: scale(1.03) !important;
  background-color: #80b1b2;
  color: #fff;
}

.register_levelsBox__L22kl {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-top: 30px;
}

.register_levelsBox__L22kl span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  margin-top: 10px;
}

.register_levelsButtons__1_pht {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.register_levelButton__3dR3N {
  width: 156px;
  height: 50px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
}

.register_levelButtonActive__hjnN7 {
  width: 156px;
  height: 50px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
  margin-right: 20px;
}

.register_topicButtons__2QP40 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.register_topicButton__1udrH {
  width: 156px;
  height: 50px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
}

.register_topicButtonActive__3GTBw {
  width: 156px;
  height: 50px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
  margin-right: 20px;
}

.register_pricesBox__3_f-1 {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-top: 30px;
}

.register_pricesBox__3_f-1 span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
}

.register_priceBox__2Clea {
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.register_price__3Dve3 {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  text-align: center;
}

.register_priceLevel__2xezU {
  position: relative;
  bottom: 10px;
}

.register_HoursBox__HRHWC {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  margin-top: 30px;
}

.register_titleDescriptionBox__3Rfh- {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  text-align: left;
}

.register_titleDescriptionBox__3Rfh- span {
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  color: #868686;
}

.register_calendarAvailability__16ux0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.register_newDocumentbuttonBox__3u8Gx {
  margin-top: 20px;
  margin-left: 7%;
}

/************************************** MediaQuery **************************************/

@media only screen and (max-width: 767px) {

  .register_step__2rNG_ {
    display: none;
  }

  .register_step_container__XdxiU {
    display: inline-flex;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    grid-gap: 20px;
    gap: 20px;
  }

  .register_formsteps__2c_zK {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 10px;
    width: 70px;
  }

  .register_calendarBox__2TCmW {
    background-color: #fff;
    padding: 10px;
  }

  .register_questionMark__3mo6Q {
    margin-top: -1px;
    margin-right: 5px;
    margin-left: 15px;
  }

  .register_explanationBoxTitle__id5_s {
    font-size: 12px;
    font-family: Rubik;
    font-weight: 500;
    color: #7FB1B2;
  }

  .register_explanationBox__25nVE {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: start;
    align-items: center;
  }

  .register_checkboxBoxItemLong__2LdmI {
    margin-left: 5px;
    color: #707070;
    font-size: 10px;
  }

  .register_instructionBoxLong__p0Tvs {
    padding: 50px 20px 0px 30px;
    margin-top: 0px;
  }

  .register_cardInfoBoxToDo__2H4kK {
    background-image: url(/static/media/cardLong.0806ab99.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    margin-left: 5px;
    margin-top: 15px;
    padding: 0;
  }

  .register_uploadVideoBoxSubMessage__SMQ_V {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .register_videoExampleBoxTitle__3CYnf {
    font-size: 13px;
    font-weight: 500;
    color: #7FB1B2;
    margin-top: 0px;
    margin-left: 0px;
  }

  .register_thumbnailMessage__36qms {
    font-size: 12px;
    color: #707070;
    margin-left: 25px;
  }

  .register_startRecordingVideo__1yN7f {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .register_linkedInput__3u0-N {
    display: none;
  }

  .register_textareaMessageFull__1xJvw {
    display: none;
  }

  .register_textareaMessageSmall__2UTcT {
    display: inline;
    font-size: 11px;
    font-style: italic;
    color: #CBCACD;
    margin-left: 5px;
  }

  .register_textareaMaxText__3BODd {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
    font-style: italic;
    color: #636363;
  }

  .register_customTextarea__2S2Sr {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .register_customLinkedTextarea___vmo1 {
    display: block;
    width: 100%;
    padding: 5px 10px 5px 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .register_customDescriptionTextarea__3mK4E {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 10px;
  }

  .register_stepFiveContent__1bLOk {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .register_teacherDescriptionBox__Jg9Jr {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 20px auto;
  }

  .register_teacherDescriptionTitle__3ogvi {
    font-size: 12px;
    color: #333;
    font-weight: 500;
  }

  /* .rowButtonChild {
    max-width: auto;
    margin-right: 5px;
  } */

  .register_uploadBoxTitle__2QLA7 {
    font-size: 0.8em;
    font-style: italic;
    color: #333;
  }

  .register_uploadBoxMessage__WfoLB {
    font-size: 0.8em;
    font-style: italic;
    color: #CBCACD;
  }

  .register_uploadBoxMessageError__2Xdy_ {
    font-size: 0.8em;
    font-style: italic;
    color: red;
  }

  .register_uploadDocBox__1KE4e {
    height: 30px;
    position: relative;
    top: -30px;
    left: 35px;
  }

  .register_uploadInputForm__3K-L6 {
    width: 100%;
    visibility: visible;
    margin-left: 5px;
    font-size: 10px;
  }

  .register_uploadFileInput__2P3A5 {
    height: 0px;
    visibility: collapse;
    margin-left: 5px;
  }

  .register_uploadFileButton__3lCMP {
    margin-left: 0px;
    width: 2% !important;
  }

  .register_uploadButtonStyle__1K77P {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
  }

  .register_docBoxMessageFormat__1fsmB {
    font-size: 0.75em;
    color: #707070;
    margin-left: 17px;
    margin-top: 20px;
  }

  .register_docBoxMessage__2uENJ {
    margin: 0px 0px 0px 40px;
    font-size: 0.75em;
    margin-right: 5px;
    color: #707070;
  }

  .register_docBoxTitle__191m4 {
    font-size: 0.75em;
    color: #111;
    font-weight: 700;
    margin-left: 7px;
  }

  .register_docBoxImage__2KaFC {
    margin: 20px 0px 0px 15px;
    display: flex;
    flex-direction: row;
  }

  .register_docBox__SS0fL {
    height: auto;
    width: 100%;
    border: 1px solid #707070;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }

  .register_form__container__3yykO {
    width: 95%;
    max-width: 600px;
    margin: 40px auto;
  }

  .register_formContainer__1BLk4 {
    width: 95%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding: 0px auto;
  }

  .register_termesToAccept__1-TfR {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    max-width: 95%;
  }

  .register_register__teacher__3BVFf {
    padding: 0 3%;
  }

  .register_teacher__3t7Eg .register_avatar__20lhg {
    padding-right: 0;
  }

  .register_teacher__3t7Eg .register_avatar__20lhg img {
    position: relative;
    width: 30px;
    top: -3px;
    margin: 0;
  }

  .register_header__text__sGKnU p {
    font-size: var(--normal-font-size);
    margin-top: 3px;
  }

  /* .title__step {
    font-size: 8px;
    white-space: nowrap;
    display: block;
  } */

  .register_placeholderStyle__2Gfjy {
    color: #C0C0C0;
    font-size: 12px;
  }

  .register_inputRow__zC5ir {
    display: flex;
    flex-direction: column;
  }

  .register_inputRowChild__2qxwA {
    width: 100%;
  }

  /* .step {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    overflow: auto;
    scrollbar-width: thin;
  } */

  .register_field__2TajY {
    height: 40px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
    font-size: 0.8em !important;
  }

  .register_field__2TajY::placeholder {
    font-size: 8px !important;
  }

  .register_policy__3FWuh {
    position: relative;
    font-size: 0.75em !important;
  }

  .register_policy__3FWuh br {
    display: none;
  }

  .register_uploadImageBoxTitle__35mBG {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
    margin-left: 35px;
  }

  .register_avatarContainer__2saVL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .register_formBox__18_vz {
    width: 100%;
    max-width: 100%;
    margin: 40px auto;
    border-radius: 5px;
    background-color: #fafafa;
    height: auto;
    padding: 30px 5px 20px 5px;
    display: flex;
    flex-direction: column;
  }

  .register_stepTitleBox__3LYjq {
    text-align: center;
    align-items: center;
    align-content: center;
  }

  .register_boxTitle__2nSkX {
    font-size: 18px;
    font-weight: 600;
    color: #707070;
    font-family: "CartoonFont";
    text-align: center;
    text-transform: uppercase;
  }

  .register_boxMessage__2P3kO {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .register_uploadBoxBorder__1ot8U {
    margin: 20px auto;
    border: none;
    border-radius: 15px;
  }

  #register_formFileUpload__1Ex9U {
    height: 120px;
    width: 90%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  .register_instructionBox__oTsHG {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 8px;
  }

  .register_checkboxBox__60AD0 {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .register_checkboxBoxItem__1qpGp {
    margin-left: 10px;
    color: #707070;
    font-size: 0.75em;
  }

  .register_cardInfoBox__3dObv {
    background-image: url(/static/media/card.b30b4ea7.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 320px;
    width: auto;
    margin-left: 0px;
    margin-right: 15px;
    margin-top: 15px;
    padding-top: 25px;
    padding-left: 25px;
  }

  .register_exampleBoxTitle__1O8dy {
    font-size: 0.8em;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 15px;
  }

  .register_avatarExampleBox__-Mgfg {
    background-image: url(/static/media/pictureExample.bc58bf9e.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70px;
    width: 90%;
    border-radius: 8px;
    margin: 5px auto !important;
  }

  .register_previewBoxImage__qOkAG {
    width: 100px;
    height: auto;
    border-radius: 15px;
  }

  .register_previewBox__3bE2q {
    display: flex;
    align-items: center;
    justify-items: center;
    border-width: 1px;
    border-radius: 1rem;
    border-color: #707070;
    border-style: dashed;
    width: auto;
    margin: 0px auto;
  }

  .register_customButtonStyle__oOT92 {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-left: 40px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  }

  .register_certificateDiplomaFormBox__30X3B {
    margin: 0px auto;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .register_pictureBoxRow__o8mmF {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    align-items: center;
  }

  .register_picturePreviewBox__1uEwp {
    height: auto;
    width: auto;
    border: 1px solid #333;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    display: flex;
  }

  .register_titleBox__PalBu {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    margin-top: 20px;
  }

  .register_videoPreviewBox__YUgce {
    height: auto;
    width: auto;
    border-radius: 5px;
    margin-left: 0px;
    margin-top: 25px;
  }

  #register_videoFileUpload__2uFKZ {
    background-image: url(/static/media/videoExample.d39fc304.png);
    background-size: cover;
    height: 150px;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 25px auto;
    background-repeat: no-repeat;
  }

  .register_exampleBoxLong__26m2A {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    position: relative;
    top: -70px;
    width: 100% !important;
    padding-top: 10px;
  }

  .register_uploadImageBox__2mbjE {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 100% !important;
  }

  .register_uploadImageBoxTitle__35mBG {
    font-size: 0.8em;
    font-weight: 500;
    color: #333;
    margin-top: 15px;
    margin-left: 0px;
  }

  .register_uploadImageBoxMessage__5vC9D {
    font-size: 0.75em;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 0px;
    color: #707070;
  }

  .register_vignetteBox__1QZZa {
    display: flex;
    flex-direction: row;
    border: 1px solid #333;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 20px auto;
    padding: 15px 20px 15px 20px;
    width: auto;
    align-items: center;
    justify-content: left;
  }

  .register_videoLinkInput__tU3EB {
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .register_uploadVideoBoxMessage__1EYPn {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .register_uploadVideoBoxSubtitle__FfDbt {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 0px;
  }

  .register_uploadVideoBoxTitle__2439m {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-left: 0px;
  }

  .register_addVideoButton__2FL44 {
    height: 32px;
    width: auto;
    margin-left: 5px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .register_trashIcon__1EVC2 {
    filter: invert(39%) sepia(100%) saturate(7463%) hue-rotate(359deg) brightness(106%) contrast(113%);
    height: 30px;
    margin-left: 20px;
    margin-top: 5px;
    cursor: pointer;
  }

  .register_addVignetteButton__32Qcn {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 30px;
  }

  .register_videoFormatTitle__1TcrL {
    font-size: 13px;
    color: #CBCACD;
    margin-left: 5px;
    margin-top: 10px;
  }

  .register_calendarBoxTitle__3V_3P {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 5px;
  }

  .register_calendareBoxMessage__2HEAL {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .register_calendarBoxSubtitle__2p0qY {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 30px auto;
    padding-left: 5px;
  }

  .register_calendarTimezone__12HOe {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px auto;
    align-items: center;
    justify-content: center;
  }

  .register_fillLaterButton__1KZlI {
    height: 50px;
    width: 150px;
    margin-left: 0px;
  }

  .register_addPictureButton__3JgPh {
    width: 100%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .register_addDocPictureButton__Y1Qdl {
    height: auto;
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .register_addPictureButtonBox__2jhFh {
    width: auto;
  }

  .register_previewCertificateDiploma__3_Lz3 {
    height: auto;
    width: 100%;
    position: relative;
    bottom: 0px;
    margin-left: 17px;
  }

  .register_nextButton__3Mvi1 {
    font-size: 12px;
    padding: 0px;
  }

  .register_title__2Gpa2 {
    font-size: 14px;
  }

  .register_rowButtons__2dWJt {
    width: 90% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin: 40px auto !important;
  }

  .register_rowsButtons__1WP5K {
    width: 90% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 40px auto !important;
  }

  .register_rowButtonChild__Ry1aM {
    max-width: 30%;
    margin: 0 10px;
  }

  .register_stopButtonBox__1sxL1 {
    position: relative;
    bottom: 12%;
    left: -6%;
    margin: 0px auto;
  }

  .register_videoLoading__hCg_G {
    position: relative;
    left: 0%;
    margin: 0px auto;
    font-family: "CartoonFont";
    font-size: 34px;
    color: #fff;
  }

  .register_videoRecorderBox__1bM4I {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    background: black;
  }

  .register_videoRecorderClearBox__3CKXD {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    bottom: 2%;
  }

  .register_newButton__2EIsN {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px !important;
  }

  .register_newMoveButton__2i9KH {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
  }

  .register_newPictureButton__16ogU {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 0px !important;
  }

  .register_newActionButton__2fJJC {
    width: auto !important;
    height: 30px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
    -moz-box-shadow: 5px 7px 1px -1px #4BB980;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 0px !important;
  }

  .register_calendarButton__39-by {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 0px !important;
  }

  .register_newDocumentbuttonBox__3u8Gx {
    margin: 20px auto;
  }

  .register_levelsBox__L22kl {
    width: 100%;
    height: auto;
    background: #F0F0F0;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 30px;
  }

  .register_levelsBox__L22kl span {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #707070;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .register_levelsButtons__1_pht {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
  }

  .register_topicButtons__2QP40 {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    gap: 10px;
  }

  .register_levelButton__3dR3N {
    width: 100px;
    height: 30px;
    background: #FFF;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
  }

  .register_topicButton__1udrH {
    width: 160px;
    height: 30px;
    background: #FFF;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
  }

  .register_levelButtonActive__hjnN7 {
    width: 100px;
    height: 30px;
    background: #E4F8F8;
    border: 1px solid #7FB1B2;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #7FB1B2;
    margin-right: 0px;
  }

  .register_topicButtonActive__3GTBw {
    width: 160px;
    height: 30px;
    background: #E4F8F8;
    border: 1px solid #7FB1B2;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #7FB1B2;
    margin-right: 0px;
  }

  .register_pricesBox__3_f-1 {
    width: 100%;
    height: auto;
    background: #F0F0F0;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    margin-top: 30px;
  }

  .register_pricesBox__3_f-1 span {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #707070;
  }

  .register_priceBox__2Clea {
    margin-left: 0px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
    gap: 0px;
  }

  .register_price__3Dve3 {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 500;
    color: #707070;
    text-align: center;
  }

  .register_priceLevel__2xezU {
    position: relative;
    bottom: 10px;
  }

  .register_HoursBox__HRHWC {
    width: 85%;
    height: auto;
    background: #F0F0F0;
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    margin: 30px auto;
  }

  .register_titleDescriptionBox__3Rfh- {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
    text-align: left;
    margin-bottom: 10px;
  }

  .register_titleDescriptionBox__3Rfh- span {
    font-size: 0.85em;
    font-family: Rubik;
    font-weight: 500;
    color: #868686;
  }

  .register_calendarAvailability__16ux0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}
.step_step_container__1K0LH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    grid-gap: 20px;
    gap: 20px;
}

.step_formsteps__2Gu59 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 10px;
    width: 70px;
}

.step_step_number__UdACX {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    margin: 0px auto;
    color: #C0C0C0;
    border: 1px solid #C0C0C0;
}

.step_step_number_active__3XGLD {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #7FB1B2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    margin: 0px auto;
    color: white;
}

.step_step_title__2-XDi {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    font-size: 8px;
}

.step_step_title_active__4j01x {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px auto;
    font-size: 8px;
    color: #000;
}

.step_spacer__1SSID {
    height: 1px;
    width: 20px;
    background: #707070;
    position: relative;
    top: -10px;
    left: 0px;
}
.stepTwo_full__content__1fZSW {
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: #F8F8F8;
  text-align: left;
  padding: 2% 5% 2% 5%;
  border: 1px solid #707070;
  border-radius: 5px;
  /* margin-bottom: var(--mb-3); */
  margin-left: auto;
  margin-right: auto;
}

.stepTwo_title__2KJ8F {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.stepTwo_level__bjBjC h2 {
  font-size: 1.5em;
  text-align: center;
}

.stepTwo___content__2DdAK {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.stepTwo_level__bjBjC {
  flex: 0.9 1;
}

.stepTwo_forms__1fMnJ {
  flex: 1.1 1;
}

.stepTwo_forms__1fMnJ {
  width: 100%;
  display: flex;
  align-items: flex-start;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin-bottom: 1em;
}

.stepTwo_field__2Jg8u {
  background: #fff;
  padding: 0.35em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #707070;
}

.stepTwo_text__area__33XUQ {
  border: 1px solid #707070;
  padding: 0.35em;
  width: 100%;
  margin-top: 1em;
  background: #fff;
  border-radius: 5px;
}

.stepTwo_select__1SslO *::placeholder {
  color: red !important;
}

.stepTwo_button__2Wxz8 {
  width: 100% !important;
  background: #636363 !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 1em;
}

.stepTwo_button__2Wxz8:hover {
  background: var(--first-color) !important;
  color: #fff !important;
}

.stepTwo___new__creneaux__TWxoa {
  width: 100% !important;
  background: var(--first-color) !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 0.5em;
}

.stepTwo___new__creneaux__TWxoa:hover {
  background: #636363 !important;
  color: #fff !important;
}

@media only screen and (max-width: 390px) {
  .stepTwo_full__content__1fZSW {
    max-width: 100%;
    padding: 4%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .stepTwo_forms__1fMnJ {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .stepTwo_forms__1fMnJ {
    flex: 1 1;
  }

  .stepTwo___content__2DdAK {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }

  .stepTwo_level__bjBjC {
    flex: 1 1;
    width: 100%;
  }

}
.checkbox_validCheckbox__31CNZ {
    height: 18px;
    width: 18px;
    background-color: white;
    border: 1px solid #333;
    border-radius: 3px;
}

.checkbox_checkIcon__34quE {
    position: relative;
    top: -5px;
}

.checkbox_closeIcon__1GstO {
    position: relative;
    right: 2px;
    bottom: 3px;
}
.register_register__teacher__2u6UP {
  width: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 1em; */
  text-align: center;
}

.register_button__a7RRz {
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 45px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: var(--normal-font-size) !important;
  text-transform: uppercase;
  background: #fff !important;
  color: var(--text) !important;
  border: 2px solid var(--text-color) !important;
}

.register_content__action__wuB6q {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.register_button__a7RRz svg {
  position: absolute;
  left: 25px;
  top: 8px;
}

button.register_button__fb__2YyzI {
  background: #3E519A !important;
  color: #fff !important;
  border: unset !important;
}

.register_button__fb__2YyzI svg {
  position: absolute;
  width: 28px;
  left: 25px;
  top: 10px;
  height: 28px;
}

.register_button__a7RRz.register_gmail__u27xq {
  background: var(--first-color) !important;
  height: 42px !important;
  border: unset !important;
  color: #fff !important;
  font-weight: 500 !important;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
}

.register_teacher__HgN4B {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--mb-4) 0;
}

.register_teacher__HgN4B .register_avatar__tUYrE {
  padding-right: var(--mb-4);
}

.register_text__1zAzz p {
  font-size: 1em;
}

.register_text__1zAzz strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register_register__teacher__2u6UP * a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_alreadyAccount__3A_Q1 {
  padding-top: var(--mb-3);
}

.register_button__a7RRz.register_google__2FYDw {
  background: #fff !important;
}

@media only screen and (max-width: 500px) {
  .register_teacher__HgN4B {
    flex-direction: column;
    margin: 1.5em 0;
  }

  .register_teacher__HgN4B .register_avatar__tUYrE {
    width: 120px;
    height: 120px;
    border: 50px;
    padding-right: 0;
    border: 1px solid #70707054;
    display: flex;
    align-items: flex-end;
    border-radius: 100px;
    margin-bottom: var(--mb-4);
  }

  .register_teacher__HgN4B .register_avatar__tUYrE img {
    width: 100px;
    margin: auto;
  }

  .register_button__a7RRz {
    position: relative;
    width: 100%;
    max-width: 320px;
    height: 45px !important;
  }
}
.style_stats__2Rlve {
    margin-top: 2em;
    margin-bottom: 5em;
}

.style___select__O3HGx {
    margin-top: 15px;
}

.style___select__O3HGx div {
    position: relative;
    text-align: center;
    font-size: 2.4em;
    margin-top: var(--mb-1);
    line-height: 0 !important;
    font-family: var(--title-font);
    text-transform: uppercase;
    border: 1px solid black !important;
}

.style_iconArrowClass__2LrF4 {
    width: 20px;
    font-size: 12px !important;
    top: 3px !important;
    right: 30px !important;
}

.style_header__3zXJ8 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 4em;
    margin-top: 0.5em;
}

.style_card__matier__1Zi9X {
    position: relative;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin-top: var(--mb-2);
    transition: all 0.7s;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    padding-left: 0px;
    padding-right: 5px;
}

.style_card__matier__1Zi9X.style_active__3XiLO,
.style_card__matier__1Zi9X:hover {
    width: 200px;
    height: 76px;
    background: var(--first-color);
    box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.style_card__matier__1Zi9X.style_active__3XiLO .style_topic_name__3649p,
.style_card__matier__1Zi9X .style_topic_name__3649p {
    position: absolute;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform ease-out 0.7s;
    transform-origin: center right;
    transition: all 0.7s;
    -webkit-transition: transform ease-out 0.7s;
    -moz-transition: transform ease-out 0.7s;
    -ms-transition: transform ease-out 0.7s;
    -o-transition: transform ease-out 0.7s;
}

.style_card__matier__1Zi9X.style_active__3XiLO .style_topic_name__3649p,
.style_card__matier__1Zi9X:hover .style_topic_name__3649p {
    position: relative;
    visibility: visible;
    transform: translateX(0);
}

.style_topic_name__3649p h3 {
    color: #fff;
    font-family: var(--title-font);
    white-space: nowrap;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.style_icon__card__matiers__3uC3j {
    transform-origin: center left;
    transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.style_icon__card__matiers__3uC3j img {
    width: 50px;
}

.style_main__3DMtM {
    width: 100%;
}

.style_card__dashboard__1HTiz {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    padding: 1.5em;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.style_infos_top__1aRDY {
    margin-bottom: var(--mb-1);
}

.style_infos_top__1aRDY h2 {
    font-size: 1.3em;
}

.style_right__3AkxA h3 {
    text-transform: uppercase;
    font-size: 1em;
}

.style_content__propositions__wXEeg {
    width: 100%;
    padding: 1em 4em;
}

.style_propositions__card__3RS8X {
    margin-top: var(--mb-6);
}

.style_content_card__2vqnP {
    margin-top: var(--mb-2);
}

.style_card__1AKnW {
    width: 100%;
    min-height: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 2em;
    gap: 2em;
    padding: 0 2em;
    margin-bottom: 1em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.style_card__1AKnW h4 {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-color);
}

.style_isCorrect__2uQUX {
    border: 1.3px solid green;
}

.style_propositionSelected__cg7zX {
    border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
    .style_right__3AkxA {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.style_stats__dashboard__3MZT2 {
    width: 100%;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-top: 20px;
}

.style_backDiv__gkhCw {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    height: 35px;
    width: auto;
}

.style_backButton__22FAt {
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.style_statsContainer__ETwN8 {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_radarContainer__Ha6bF {
    height: auto;
    width: 40%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.style_boxTitle__2dtBT {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    margin-left: 20px;
    margin-top: 10px;
    font-family: "CartoonFontBold";
    font-weight: 600;
}

.style_boxTitle__2dtBT:hover {
    color: #333;
}

.style_radarBox__2Of7N {
    height: auto;
    width: 85%;
    margin-left: 10%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
}

.style_radarTitle__2aZS6 {
    color: #333;
    font-size: 1.5em;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 20px;
}

.style_radarTitle__2aZS6:hover {
    color: #333;
}

.style_radarMarkBox__1FZcS {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_radarAVG__RFANv {
    font-size: 35px;
    color: #333;
    font-weight: bold;
}

.style_radarAVG__RFANv:hover {
    color: #333;
}

.style_radarMaxAVG__1jOMT {
    margin-top: 10px;
    font-weight: bold;
}

.style_radarMaxAVG__1jOMT:hover {
    color: #333;
}

.style_radarChartBox__2vYkP {
    display: flex;
    justify-content: center;
    padding: 0px;
}

.style_lineContainer__2hFLg {
    height: auto;
    width: 58%;
    display: flex;
    flex-direction: column;
}

.style_lineBox__w-qEG {
    height: 430px !important;
    width: 100%;
    display: block;
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    padding: 2.5%;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.style_lineChartCard__3weZ9 {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
}

.style_lineChartCard__3weZ9:hover {
    background-color: #80b1b21a;
}

.style_lineChartCardHeader__3qSY5 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_lineChartCardAVG__2cUCO {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 20px;
    color: #333;
    font-weight: normal;
}

.style_lineTitle__2y1KX {
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.style_backHeader__2oo7E {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.style_backLeft__3IDh5 .style_backTitle__NLm60 {
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.style_center__1lnr3 {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.style_arrowRightButton__1UtMh {
    border-radius: 50px;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: #fff 0px solid;
}

.style_arrowRightButton__1UtMh:hover {
    background: #80B1B2;
    color: white;
}

@media only screen and (max-width: 767px) {
    .style_backLeft__3IDh5 .style_backTitle__NLm60 {
        text-transform: uppercase;
        font-size: 1.2em;
        color: var(--text-color);
    }

    .style_lineContainer__2hFLg {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_lineContainer__2hFLg {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .style_lineChartCard__3weZ9 {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        margin-top: 10px;
    }

    .style_radarContainer__Ha6bF {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .style_statsContainer__ETwN8 {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .style_card__dashboard__1HTiz {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_stats__dashboard__3MZT2 {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_topic_name__3649p h3 {
        font-size: 0.7em;
    }

    .style_icon__card__matiers__3uC3j img {
        width: 30px;
    }

    .style_card__matier__1Zi9X {
        width: auto;
        height: 60px;
        margin-top: var(--mb-1);
    }

    .style_card__matier__1Zi9X.style_active__3XiLO,
    .style_card__matier__1Zi9X:hover {
        width: auto;
        height: 50px;
    }

    .style_content__propositions__wXEeg {
        padding: 0;
    }

    .style_header__3zXJ8 {
        grid-gap: 0.5em;
        gap: 0.5em;
        margin-bottom: 2em;
        margin-top: 0;
    }

    .style_right__3AkxA {
        margin-top: 1em;
    }

    .style_card__1AKnW {
        padding: 0 1em;
    }

    .style___select__O3HGx div {
        font-size: 1.5em;
        margin-bottom: var(--mb-1);
    }

    .style_iconArrowClass__2LrF4 {
        width: 15px;
        top: -1px !important;
        right: 60px !important;
    }

    .style_propositions__card__3RS8X {
        margin-top: var(--mb-4);
    }

    .style_radarChartBox__2vYkP {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .style_radarBox__2Of7N {
        height: auto;
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
        background: white;
        border-radius: 15px;
    }
}

.style_dashedBox__1s9SW {
    /* padding: 10px;
    border: 1px dashed #707070;
    background: #fff;
    border-radius: 15px; */
    font-size: 1em;
    display: flex;
    flex-direction: row;
    font-weight: 600;
}

.style_rateBox__x7Bkj {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.style_rateBoxDetails__3wWNF {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px;
    width: 35%;
}

.style_rateBoxSpan__5AQWq {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
    margin-left: 5px;
    color: #707070;
}

.style_rateBoxStar__2aRfk {
    position: relative;
    top: -1px;
    margin-left: 5px;
    margin-right: 5px;
}

.style_card__1AKnW {
    position: relative;
    width: 100%;
    background: #fafafa;
    margin-bottom: var(--mb-4);
    box-shadow: 3px 4px 0px 3px #cfd0d2;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 80px;
    padding-right: 40px;
    padding-bottom: 40px;
}

.style_card__1AKnW:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 70px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.style_card__1AKnW::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 50px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.style_defaultButton__YkC3D {
    position: relative;
    bottom: 12.5px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.style_defaultButton__YkC3D:hover {
    transform: scale(1.03) !important;
}

.style_defaultButton__YkC3D:active {
    transform: scale(0.99) !important;
}

.style_currentTab__2mQhK {
    display: inline-block;
    padding: 8px 16px;
    border: 1px solid #7FB1B2;
    border-radius: 4px;
    cursor: pointer;
    background-color: #7FB1B2;
    color: #fff;
    font-weight: bold;
    font-family: "CartoonFont";
    font-size: 1.2em;
}

.style_defaultTab__2mEOo {
    display: inline-block;
    padding: 8px 16px;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    cursor: pointer;
    color: var(--title-color);
    background-color: #D5D5D5;
    font-weight: bold;
    font-family: "CartoonFont";
    font-size: 1.2em;
}

.style_header__3zXJ8 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 5em 0 1em 0;
}

.style___btn__challenge__cQK4U {
    position: relative;
    max-width: 350px;
    min-width: 350px;
}

.style___btn__challengeX__1l0wr {
    opacity: 0.75;
    background-color: #7d7d7d74 !important;
    color: var(--text-color) !important;
    box-shadow: 3px 6px 1px -1px var(--text-color) !important;
    cursor: not-allowed !important;
}

.style_main__3DMtM {
    width: 100%;
    height: 100%;
    margin-top: 4em;
}

.style_center__1lnr3 {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.style_left__1VEgM .style_subtitle__3EDwe {
    position: relative;
    top: 15px;
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.style_main__3DMtM {
    width: 100%;
    height: 100%;
    margin-top: 4em;
}

.style_row__2wN6o {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.style_main__left__B7Ulc,
.style_main__right__3s84_ {
    width: 100%;
    height: 100%;
}

.style_main__left__B7Ulc {
    flex: 2 1;
}

.style_main__right__3s84_ {
    flex: 0.8 1;
}

.style_main__row__24Q1P {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-gap: var(--mb-2);
    gap: var(--mb-2);
}

.style_main__left__content__3FLoy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-gap: 40px;
    gap: 40px;
    margin-bottom: var(--mb-6);
}

.style_graphe__right__AJkR5,
.style_graphe__left__3AJPf {
    height: 400px;
    grid-gap: 20px;
    gap: 20px;
    background: #fafafa;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.style_graphe__left__header__1ow6e {
    margin-left: var(--mb-2);
    padding-top: var(--mb-2);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style___garphe__right__header__Kqz4C {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.4em;
    margin-top: 1.5em;
}

.style_select__2citC {
    position: relative;
    height: 45px;
    width: 100%;
    max-width: 200px;
    padding-top: 5px;
    text-align: left;
    border-radius: 10px;
    border: 1px solid var(--text-color);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.style___first__card__3hhDj,
.style___second__card__1kr3N {
    width: 100%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    background: #fafafa;
    border: 2px solid #dbdbdb;
}

.style___first__card__3hhDj h4 {
    font-size: 1.5em;
    text-transform: uppercase;
    color: var(--text-color);
    margin-top: var(--mb-1);
}

.style___second__card__1kr3N {
    margin-top: var(--mb-3);
    height: 255px;
}

.style___second__card__1kr3N span {
    text-transform: uppercase;
    font-size: 1em;
}

.style___second__card__1kr3N .style_code__GY8sX {
    width: 150px;
    height: 40px;
    background: #fff;
    border: 1px dashed var(--text-color);
    display: flex;
    margin: var(--mb-3) auto;
    border-radius: 50px;
}

.style_code__GY8sX span {
    margin: auto;
    font-size: 1.2em;
    font-weight: 500;
}

.style___content__btn__yJXsL button:first-child {
    margin-bottom: var(--mb-2);
}

.style___btn__E4oQ1 {
    min-width: 200px !important;
    max-width: 200px !important;
    padding-top: 10px;
    height: 38px !important;
}

.style_array__conent__yrEfw {
    width: 100%;
    margin-top: var(--mb-4);
    max-height: 700px;
    background: #fafafa;
    border-radius: 10px;
    padding: var(--mb-2) var(--mb-3);
}

/* Array */

.style_array__conent__header__2SrtV {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: var(--mb-2);
}

.style_filed__search__3wstW {
    width: 300px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 2px;
}

.style_array__conent__header__2SrtV * input,
.style_array__conent__header__2SrtV * button {
    background: transparent !important;
    border: unset !important;
    box-shadow: unset !important;
}

.style_array__conent__header__2SrtV * input:focus {
    box-shadow: unset !important;
    border: unset !important;
    outline: none !important;
}

.style_array__conent__header__2SrtV * input::placeholder {
    color: var(--text-color);
}

.style___array__2ERIM {
    width: 100% !important;
    max-height: 500px;
    margin-top: var(--mb-6);
    background: #fff;
}

/* Drawer */

.style_drawer__wXO8y {
    position: relative;
    width: 100%;
    margin-top: var(--mb-6);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.style_drawer__content__2Jrrn {
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.style_drawer__content__2Jrrn * label {
    position: relative;
    width: 100%;
    text-transform: uppercase;
    font-weight: 500;
    color: #707070;
    top: 10px;
    font-size: 1em;
    text-align: left;
}

.style_field__text__2H1ig,
.style_field__3LUaZ div {
    height: 50px !important;
    border: 1px solid var(--text-color) !important;
    border-radius: 10px !important;
}

.style_field__3LUaZ div {
    padding-top: 10px !important;
}

.style_drawer___header__2J_ds {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--mb-6);
}

.style_drawer___header__2J_ds h3 {
    font-family: var(--title-font);
    font-size: 3.2em;
    text-transform: uppercase;
    margin-top: var(--mb-2);
}

.style___row__1nH8Y {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--mb-4);
    grid-gap: 15px;
    gap: 15px;
}

.style_faq__BFFu_ {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.5em;
    color: #707070;
}

.style_content__1QMl9 {
    margin-bottom: var(--mb-6);
}

.style___overflow__Y__232VR {
    width: 100%;
    height: 700px;
    overflow-y: scroll;
}

.style_tableHeader__3HXTG {
    color: var(--first-color);
}

.style_filed__search__3wstW {
    width: 300px;
    padding: 0;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding-bottom: 2px;
    overflow: hidden;
}

/*  */
.style_inputSearchContent__1gl9l * input,
.style_inputSearchContent__1gl9l * button {
    background: transparent !important;
    border: unset !important;
}

.style_inputSearchContent__1gl9l * input:focus {
    box-shadow: unset !important;
    border: unset !important;
    outline: none !important;
}

.style_inputSearchContent__1gl9l * input::placeholder {
    color: var(--text-color);
}

/* .inputSearchContent * button {
    background: rgba(255, 255, 255, 0) !important;
  } */

.style_elt__zucjf {
    margin-bottom: 50px !important;
}

/* New Css Styles */

.style_tabTitleBox__1CEy8 {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.style_tabTitleBoxActive__36pHl {
    height: 40px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 16px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.style_classeCard__2JvO7 {
    position: relative;
    width: 100% !important;
    height: 70px;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 10px 0px 10px 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_cardClassBox__pSuyn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.style_detailsBox__scGPm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 35% !important;
    margin-right: 20px;
}

.style_timeBox__3scKf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 30px;
}

.style_times__tLLSg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.style_time__2nD7M {
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
}

.style_timeDescription__h72Nd {
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
}

.style_newButton__hQIbJ {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 13px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
}

.style_newButton__hQIbJ:hover {
    transform: scale(1.03) !important;
    background-color: #80b1b2;
    color: #fff;
}

.style_cardDisplay__2uvFT {
    display: flex;
    flex-direction: column;
}

.style_studentDetailsDrawer__21P4U {
    width: 90%;
    background: #FFF;
    box-shadow: 4px 9px 18px #FFF;
    border: 1px solid #DFDDDD;
    border-radius: 20px;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin: 40px auto;
    padding: 20px;
}

.style_formBox__3S6dk {
    width: 90%;
    background: #FFF;
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    margin: 40px auto;
    padding: 20px;
}

.style_formTitle__20xHF {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    margin-bottom: 20px;
    margin-left: 20px;
}

.style_inputField__1Pb1z {
    border-radius: 10px !important;
    font-size: 12px !important;
}

.style_inputField__1Pb1z::placeholder {
    font-size: 12px !important;
}

.style_statsBox__18RbU {
    box-shadow: 2px 4px 8px #CECECE29;
    border: 1px solid #BFBCBC81;
    border-radius: 20px;
    padding-top: 3%;
    background: #FAFAFA;
}

.style_drawerTimeBox__qqqRp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
}

@media only screen and (max-width: 1050px) {

    .style_lineBox__w-qEG {
        height: 38vh !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        background: #fff;
        box-shadow: 2px 4px 8px #CECECE29;
        border: 1px solid #BFBCBC81;
        border-radius: 20px;
        padding: 1%;
    }

    .style_statsBox__18RbU {
        margin-top: 3%;
        height: 38vh !important;
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: thin;
        background: #fff;
        box-shadow: 2px 4px 8px #CECECE29;
        border: 1px solid #BFBCBC81;
        border-radius: 20px;
        padding-top: 20px;
    }

    .style_tabTitleBox__1CEy8 {
        height: 35px;
        background: #FFF;
        box-shadow: 6px 8px 14px #CECECE29;
        border: 2px solid #BFBCBC82;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 0.8em !important;
    }

    .style_tabTitleBoxActive__36pHl {
        height: 35px;
        background: #FFF;
        box-shadow: 6px 8px 14px #7FB1B238;
        border: 2px solid #7FB1B2;
        border-radius: 10px;
        opacity: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 0.8em !important;
    }

    .style_classeCard__2JvO7 {
        position: relative;
        width: 100% !important;
        height: auto !important;
        background: #FAFAFA;
        box-shadow: 0px 3px 10px #BCBCBC29;
        border: 1px solid #BFBCBC;
        padding-top: 10px;
        padding-bottom: 20px;
        border-radius: 18px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .style_cardClassBox__pSuyn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .style_detailsBox__scGPm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin-right: 0px;
    }

    .style_timeBox__3scKf {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .style_times__tLLSg {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .style_time__2nD7M {
        font-size: 0.7em;
        font-family: "Poppins";
        font-weight: 500;
        color: #393E3E;
    }

    .style_timeDescription__h72Nd {
        font-size: 0.7em;
        font-family: "Poppins";
        font-weight: 400;
        color: #868686;
    }

    .style_newButton__hQIbJ {
        width: auto !important;
        height: 30px !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 50px !important;
        font-size: 0.8em !important;
        text-transform: none;
        text-align: center;
        background: #80b1b2 !important;
        color: #fff !important;
        padding-top: 5px !important;
        font-weight: 400 !important;
        border: none !important;
        box-shadow: 3px 5px 1px -1px #5a8484 !important;
        -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
        -moz-box-shadow: 5px 7px 1px -1px #5a8484;
        padding-left: 20px;
        padding-right: 20px;
    }

    .style_cardDisplay__2uvFT {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 5px;
        gap: 5px;
        width: 70%;
        margin: 0px auto;
    }

    .style_formTitle__20xHF {
        font-size: 0.7em;
        font-family: "Poppins";
        font-weight: 500;
        color: #393E3E;
        margin-bottom: 20px;
    }

    .style_studentDetailsDrawer__21P4U {
        width: 100%;
        background: none;
        box-shadow: 0px 9px 18px #FFF;
        border: 0px solid #DFDDDD;
        border-radius: 0px;
        display: flex !important;
        flex-direction: row !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin: 0px auto;
        padding: 0px;
    }

    .style_inputField__1Pb1z {
        border-radius: 10px !important;
        font-size: 0.8em !important;
    }

    .style_inputField__1Pb1z::placeholder {
        font-size: 0.8em !important;
    }

    .style_rateBox__x7Bkj {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-gap: 10px;
        gap: 10px;
    }

    .style_rateBoxDetails__3wWNF {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        grid-gap: 10px;
        gap: 10px;
        width: auto;
    }

    .style_dashedBox__1s9SW {
        font-size: 0.75em;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        font-weight: 600;
        width: 100%;
    }

    .style_drawerTimeBox__qqqRp {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        justify-content: space-between !important;
        font-size: 0.8em;
        width: 100%;
    }

    .style_formBox__3S6dk {
        width: 90%;
        background: #FFF;
        box-shadow: 2px 4px 8px #CECECE29;
        border: 1px solid #BFBCBC81;
        border-radius: 20px;
        margin: 20px auto;
        padding: 20px;
    }
}
.style_stats__3FJ6r {
    margin-top: 2em;
    margin-bottom: 5em;
}

.style___select__1yru_ {
    margin-top: 15px;
}

.style___select__1yru_ div {
    position: relative;
    text-align: center;
    font-size: 2.4em;
    margin-top: var(--mb-1);
    line-height: 0 !important;
    font-family: var(--title-font);
    text-transform: uppercase;
    border: 1px solid black !important;
}

.style_iconArrowClass__jPpB- {
    width: 20px;
    font-size: 12px !important;
    top: 3px !important;
    right: 30px !important;
}

.style_header__2FK21 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 4em;
    margin-top: 0.5em;
}

.style_card__matier__1mkWW {
    position: relative;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin-top: var(--mb-2);
    transition: all 0.7s;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    padding-left: 0px;
    padding-right: 5px;
}

.style_card__matier__1mkWW.style_active__1M3qi,
.style_card__matier__1mkWW:hover {
    width: 200px;
    height: 76px;
    background: var(--first-color);
    box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.style_card__matier__1mkWW.style_active__1M3qi .style_topic_name__o-A4T,
.style_card__matier__1mkWW .style_topic_name__o-A4T {
    position: absolute;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform ease-out 0.7s;
    transform-origin: center right;
    transition: all 0.7s;
    -webkit-transition: transform ease-out 0.7s;
    -moz-transition: transform ease-out 0.7s;
    -ms-transition: transform ease-out 0.7s;
    -o-transition: transform ease-out 0.7s;
}

.style_card__matier__1mkWW.style_active__1M3qi .style_topic_name__o-A4T,
.style_card__matier__1mkWW:hover .style_topic_name__o-A4T {
    position: relative;
    visibility: visible;
    transform: translateX(0);
}

.style_topic_name__o-A4T h3 {
    color: #fff;
    font-family: var(--title-font);
    white-space: nowrap;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.style_icon__card__matiers__29SLC {
    transform-origin: center left;
    transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.style_icon__card__matiers__29SLC img {
    width: 50px;
}

.style_main__BcId- {
    width: 100%;
}

.style_card__dashboard__4zKwF {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    padding: 1.5em;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.style_infos_top__3Ri8E {
    margin-bottom: var(--mb-1);
}

.style_infos_top__3Ri8E h2 {
    font-size: 1.3em;
}

.style_right__1nAO9 h3 {
    text-transform: uppercase;
    font-size: 1em;
}

.style_content__propositions__1NXf- {
    width: 100%;
    padding: 1em 4em;
}

.style_propositions__card__3U-Ju {
    margin-top: var(--mb-6);
}

.style_content_card__1aFFM {
    margin-top: var(--mb-2);
}

.style_card__5YAdS {
    width: 100%;
    min-height: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2em;
    gap: 2em;
    padding: 0 2em;
    margin-bottom: 1em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.style_card__5YAdS h4 {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-color);
}

.style_isCorrect__3ezFb {
    border: 1.3px solid green;
}

.style_propositionSelected__3VDQK {
    border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
    .style_right__1nAO9 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.style_stats__dashboard__1Tfrx {
    width: 100%;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-top: 20px;
}

.style_backDiv__1PVwH {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    height: 35px;
    width: auto;
}

.style_backButton__11LtY {
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.style_statsContainer__1g-2y {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_radarContainer__1XGcH {
    height: auto;
    width: 40%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.style_boxTitle__1kbAM {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    margin-left: 20px;
    margin-top: 10px;
    font-family: "CartoonFontBold";
    font-weight: 600;
}

.style_boxTitle__1kbAM:hover {
    color: #333;
}

.style_radarBox__1XPvQ {
    height: auto;
    width: 85%;
    margin-left: 10%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
}

.style_radarTitle__3FZLA {
    color: #333;
    font-size: 1.5em;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 20px;
}

.style_radarTitle__3FZLA:hover {
    color: #333;
}

.style_radarMarkBox__2omP0 {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_radarAVG__2DhtG {
    font-size: 35px;
    color: #333;
    font-weight: bold;
}

.style_radarAVG__2DhtG:hover {
    color: #333;
}

.style_radarMaxAVG__3-DgS {
    margin-top: 10px;
    font-weight: bold;
}

.style_radarMaxAVG__3-DgS:hover {
    color: #333;
}

.style_radarChartBox__eSZkn {
    display: flex;
    justify-content: center;
    padding: 0px;
}

.style_lineContainer__2e2Mu {
    height: auto;
    width: 58%;
    display: flex;
    flex-direction: column;
}

.style_lineBox__2NLLA {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    grid-gap: 10px;
}

.style_lineChartCard__1tKvO {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
}

.style_lineChartCard__1tKvO:hover {
    background-color: #80b1b21a;
}

.style_lineChartCardHeader__1yx8d {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_lineChartCardAVG__1LZ_J {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 20px;
    color: #333;
    font-weight: normal;
}

.style_lineTitle__1_3Hc {
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.style_backHeader__u5Ihj {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.style_backLeft__dHUsn .style_backTitle__3wFTH {
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.style_center__3XuxN {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.style_arrowRightButton__1zSDU {
    border-radius: 50px;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: #fff 0px solid;
}

.style_arrowRightButton__1zSDU:hover {
    background: #80B1B2;
    color: white;
}

@media only screen and (max-width: 767px) {
    .style_backLeft__dHUsn .style_backTitle__3wFTH {
        text-transform: uppercase;
        font-size: 1.2em;
        color: var(--text-color);
    }

    .style_lineContainer__2e2Mu {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .style_lineBox__2NLLA {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        background: #FAFAFA;
        border-radius: 15px;
        padding: 1%;
    }

    .style_lineBoxTeacher__1UVuR {
        height: auto;
        width: 100%;
        display: grid !important;
        margin-bottom: 10px;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 10px;
        gap: 10px;
        background: #FAFAFA;
        border-radius: 15px;
    }

    .style_lineChartCard__1tKvO {
        height: auto;
        width: 100%;
        background: #FFF;
        border-radius: 15px;
        margin-top: 10px;
    }

    .style_radarContainer__1XGcH {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .style_statsContainer__1g-2y {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .style_card__dashboard__4zKwF {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_stats__dashboard__1Tfrx {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_topic_name__o-A4T h3 {
        font-size: 0.7em;
    }

    .style_icon__card__matiers__29SLC img {
        width: 30px;
    }

    .style_card__matier__1mkWW {
        width: auto;
        height: 60px;
        margin-top: var(--mb-1);
    }

    .style_card__matier__1mkWW.style_active__1M3qi,
    .style_card__matier__1mkWW:hover {
        width: auto;
        height: 50px;
    }

    .style_content__propositions__1NXf- {
        padding: 0;
    }

    .style_header__2FK21 {
        grid-gap: 0.5em;
        gap: 0.5em;
        margin-bottom: 2em;
        margin-top: 0;
    }

    .style_right__1nAO9 {
        margin-top: 1em;
    }

    .style_card__5YAdS {
        padding: 0 1em;
    }

    .style___select__1yru_ div {
        font-size: 1.5em;
        margin-bottom: var(--mb-1);
    }

    .style_iconArrowClass__jPpB- {
        width: 15px;
        top: -1px !important;
        right: 60px !important;
    }

    .style_propositions__card__3U-Ju {
        margin-top: var(--mb-4);
    }

    .style_radarChartBox__eSZkn {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .style_radarBox__1XPvQ {
        height: auto;
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
        background: white;
        border-radius: 15px;
    }
}

.style_lineBox__2NLLA {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    grid-gap: 10px;
    background: #fafafa;
    border-radius: 15px;
    padding: 1%;
}

.style_lineBoxTeacher__1UVuR {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(auto-fit, minmax(35%, 1fr));
    grid-gap: 10px;
    background: #fafafa;
    border-radius: 15px;
}
.style_navbar__yEIrY {
    width: 390px;
    background: #80B1B2;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
    -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
}

.style_userNavInfoBox__1PpqL {
    height: auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.style_userNavBox__lV17M {
    height: 48%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_infoBox__19J3V {
    height: 100%;
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_infoBoxHoverSchool__3KMft {
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_infoBoxHoverNat__37pq3 {
    width: 49%;
    background: #A3E2E3;
    border-radius: 4px;
    padding: 2px;
}

.style_info__3sVx0 {
    height: 100%;
    width: 100%;
    background: #669E9D;
    border-radius: 4px;
    color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2px;
    padding-right: 2px;
    align-items: center;
    object-fit: contain;
}

.style_info__3sVx0 .style_left__1FcMp {
    font-size: 10px;
    object-fit: contain;
}

.style_info__3sVx0 .style_leftText__2Jjuu {
    position: relative;
    top: -1px;
    object-fit: contain;
}


.style_info__3sVx0 .style_leftTextHover__2uklL {
    position: relative;
    top: -1px;
    object-fit: contain;
}

/* .infoBoxHoverSchool:hover::after {
    content: 'Etablissement';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
} */

/* .infoBoxHoverNat:hover::after {
    content: 'National';
    background-color: #80B1B2;
    color: white;
    padding: 5px;
    border-radius: 5px;
} */

.style_info__3sVx0 .style_right__3RpF- {
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    object-fit: contain;
}

.style_coinsInfo__1P2KK {
    font-size: 7px;
    margin-left: 2px;
    position: relative;
    top: -2px;
}

.style_classementInfo__FDgih {
    font-size: 7px;
    margin-left: 0px;
    position: relative;
    top: -2px;
}

.style_infoPic__pzLoo {
    margin-left: 7px;
}

@media only screen and (max-width: 767px) {
    .style_navbar__yEIrY {
        height: 80%;
        width: 100%;
        background: #80B1B2;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
        -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
    }

    .style_userNavInfoBox__1PpqL {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .style_userNavBox__lV17M {
        height: 85%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 1px;
    }

    .style_infoBox__19J3V {
        height: 100%;
        width: 54px;
        background: #A3E2E3;
        border-radius: 4px;
        padding: 2px;
        margin-left: 4px;
    }

    .style_info__3sVx0 {
        height: 100%;
        width: 100%;
        background: #669E9D;
        border-radius: 4px;
        color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
        align-items: flex-start;
        object-fit: contain;
    }

    .style_info__3sVx0 .style_left__1FcMp {
        font-size: 0.6em;
        object-fit: contain;
    }

    .style_info__3sVx0 .style_leftText__2Jjuu {
        position: relative;
        top: -1px;
        object-fit: contain;
    }

    .style_info__3sVx0 .style_right__3RpF- {
        font-weight: 500;
        font-size: 0.6em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        object-fit: contain;
    }

    .style_coinsInfo__1P2KK {
        font-size: 7px;
        margin-left: 2px;
        position: relative;
        top: -2px;
    }

    .style_classementInfo__FDgih {
        font-size: 7px;
        margin-left: 0px;
        position: relative;
        top: -2px;
    }

    .style_infoPic__pzLoo {
        margin-left: 4px;
        margin-bottom: 3px;
        margin-right: 4px;
    }
}
.style___topBlock__2UeTL {
  width: 100%;
  grid-column-gap: 12px;
  column-gap: 12px;
  height: calc(100% - 200px);
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.style___topBlock__2UeTL>* {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 25px;
  font-family: "CartoonFont";
}

.style___topBlock__2UeTL .style___topBlockEntry__3vh_K {
  display: flex;
  width: 100%;
  grid-column-gap: 12px;
  column-gap: 12px;
  margin-top: 28px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.style___columnArrangement__1o4nb {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.style___title__1cveV {
  font-size: 2em;
  margin-left: 15px;
}

.style___header__26ofO {
  display: flex;
}

.style_convertedAmount__fF_7f {
  font-size: 25px;
  text-align: center;
  font-family: var(--title-font);
}

.style_boxTitle__3SAPo {
  color: var(--title-color);
  font-size: var(--h1-font-size);
  margin-left: 20px;
  margin-top: 10px;
  font-family: "CartoonFontBold";
  font-weight: 600;
}

.style_boxTitle__3SAPo:hover {
  color: #333;
}

.style_field__1VSxo {
  height: 35px !important;
  border-radius: 16px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
  font-size: 12px;
}
.calendar_calendrier__3c_6X {
  width: 100%;
  margin: 2em 0;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.calendar_calendar__content__14_oM {
  width: 100%;
}

.calendar_profile__aYA2C {
  position: relative;
  width: 115px;
  height: 115px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  top: -3em;
}

.calendar___avatar__31ERH {
  width: 110px;
  height: 110px;
  border-radius: 100px;
  margin: auto;
}

.calendar___drawer_header__3cweW {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.calendar___drawer_header__3cweW h3 {
  font-size: 3.5em;
  font-family: var(--title-font);
  color: var(--first-color);
}

.calendar_name__3_1Z7 {
  position: relative;
  color: var(--text-color);
  font-size: 1.7em;
  text-transform: uppercase;
  top: -1em;
}

.calendar___drawer__card__3lcFO {
  width: 65%;
  height: 400px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  margin-top: 6em;
}

.calendar_card__content__w5lg5 {
  text-align: center;
  margin-top: 1em;
  font-size: 16px;
}

.calendar_countDown__1Njek {
  font-size: 2em;
  color: var(--first-color);
  text-transform: uppercase;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

.calendar___button__3QkOL {
  min-width: 300px;
}

.calendar___button__3QkOL svg {
  position: relative;
  top: 2px;
  left: -10px;
}

/*  */

.calendar_calendar__2yCmg {
  width: 100%;
  overflow: hidden;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #fafafa;
}

.calendar_weekly__2brcz {
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 2% 4%;
  margin-bottom: 2em;
  border: 1px solid #ccc;
}

.calendar_date__1fAP0 {
  width: 100%;
  min-height: 35px;
  border-radius: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  padding-top: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.calendar_date__1fAP0:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}
.calendar_date__1fAP0.calendar_active__3xEz2 {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.calendar_creneaux__2J8cQ {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.calendar_creneau__1qKc7 {
  margin-bottom: 0.5em;
}

.calendar_range__3CjvH {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.calendar_range__3CjvH span {
  margin: auto;
}

.calendar_matiere__2gjV7 {
  font-size: 1em;
}

.calendar_bar__3VP-0 {
  margin: 0 0.5em;
}

.calendar_angleIcon__Xg9DG path {
  fill: #fe772e;
}

.calendar_slider__AA2Vd > svg path {
  fill: #fe772e;
}

.calendar_card__3A2zj {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .calendar_content__action__reservation__2QqK8 {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.calendar_myReservation__27TyI {
  margin-bottom: 1em;
}

.calendar_card___2_1xW {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}

.calendar_boxTitle__jQo0v {
  color: var(--title-color);
  font-size: var(--h1-font-size);
  margin-left: 20px;
  margin-top: 10px;
  font-family: "CartoonFontBold";
  font-weight: 600;
}

.calendar_boxTitle__jQo0v:hover {
  color: #333;
}

.style_lineBox__3rn-s {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  border-radius: 15px;
  padding: 1%;
  align-items: start;
  justify-content: start;
}

.style_card__19Ema {
  height: auto;
  width: 265px;
  background: #fafafa;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 60px;
  padding-right: 40px;
  padding-bottom: 15px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.style_cardSingle__3XQSB {
  position: relative;
  width: 32% !important;
  background: #fafafa;
  margin-bottom: var(--mb-2);
  box-shadow: 3px 4px 0px 3px #cfd0d2;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 60px;
  padding-right: 40px;
  padding-bottom: 0px;
  cursor: pointer;
}

.style_cardSingle__3XQSB:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.style_cardSingle__3XQSB::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.style_dashedBox__2txe1 {
  padding: 0px;
  background: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  color: #5C5858;
}

.style_rateBox__smKmE {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.style_rateBoxSpan__m5m3Y {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
}

.style_rateBoxStar__1lzej {
  position: relative;
  top: -1px;
  margin-left: 2.5px;
}

.style_rateBoxStarTooltip__2C_8e {
  position: relative;
  top: -2px;
  margin-left: 5px;
}

.style_defaultButton__1doon {
  width: 160px !important;
  position: relative;
  bottom: 12.5px;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  font-size: 14px;
  margin-top: 25px;
}

.style_defaultButton__1doon:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.style_defaultButton__1doon:active {
  transform: scale(0.99) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.style_studentbox__18Th0 {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.style_titleBox__1I8Yt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.style_avatarBox__3yGNi {
  height: 60px;
  width: 60px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
}

.style_detailsAvatarBox__3fZnD {
  height: 70px;
  width: 70px;
  border: 1px solid #FFF;
  border-radius: 50%;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_searchBox__2rqwI {
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D5D5D5;
  border-radius: 24px;
  margin-top: 30px;
  position: relative;
  bottom: 12.5px;
  width: 30%;
  padding-right: 10px;
}

.style_placeholderStyle__3tXYT {
  font-size: 12px;
  color: #000000;
  font-family: "Poppins";
  font-weight: 400;
  opacity: 0.3;
}

.style_placeholderStyleSelect__732qV {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.style_searchIconBox__2U3q3 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #7FB1B2;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style_filterMainBox__3e38H {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.style_selectInput__3fsN3 {
  color: #707070;
  border-radius: 24px;
  border: 1px solid #E3E2E2;
  font-style: normal !important;
  width: 15%;
}

.style_studentName__2EE-l {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--title-color);
  margin-top: 10px;
}

.style_levelBox__1boFz {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EBEAEA;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  border-radius: 8px;
  width: 100px;
  margin-top: 5px;
}

.style_studentAvgBox__23V6j {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  color: #707070;
  margin-top: 5px;
}

.style_studentAvg__3e3Qv {
  font-size: 13px;
  font-weight: 400;
  color: #707070;
  margin-left: 5px;
}

.style_tooltipBox__3mOtK {
  display: flex;
  flex-direction: column;
  width: 140px;
  background: #FFF;
  padding: 10px;
}

.style_emptyData__2gY0T {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  text-align: center;
}

.style_dashboard__1F9RF {
  margin-top: 1em;
}

.style_dashboardUserBox__1MeXs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
}

.style_dashboardUserMessage__2OtDP {
  font-size: 1.8em;
  color: black;
  margin: 0px auto;
  padding-bottom: 30px;
}

@media only screen and (max-width: 1050px) {

  .style_dashboardUserMessage__2OtDP {
    font-size: 1.2em;
    color: black;
  }

  .style_lineBox__3rn-s {
    height: auto;
    width: 100% !important;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    border-radius: 15px;
    padding: 0%;
    align-items: start;
    justify-content: start;
  }

  .style_card__19Ema {
    height: 170px;
    width: 100%;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }

  .style_cardSingle__3XQSB {
    position: relative;
    width: 32% !important;
    background: #fafafa;
    margin-bottom: var(--mb-2);
    box-shadow: 3px 4px 0px 3px #cfd0d2;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 40px;
    padding-bottom: 0px;
    cursor: pointer;
  }

  .style_cardSingle__3XQSB:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 70px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .style_cardSingle__3XQSB::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 50px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
  }

  .style_dashedBox__2txe1 {
    padding: 0px;
    background: #fff;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    color: #5C5858;
  }

  .style_rateBox__smKmE {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .style_rateBoxSpan__m5m3Y {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
  }

  .style_rateBoxStar__1lzej {
    position: relative;
    top: 0px;
    margin-left: 2.5px;
  }

  .style_rateBoxStarTooltip__2C_8e {
    position: relative;
    top: -2px;
    margin-left: 5px;
  }

  .style_defaultButton__1doon {
    height: 35px !important;
    width: auto !important;
    position: relative !important;
    bottom: 12px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    box-shadow: 1px 3px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 1px 3px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 1px 3px 1px -2px rgba(90, 132, 132, 1);
    font-size: 0.65em !important;
    margin-top: 10px !important;
  }

  .style_defaultButton__1doon:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .style_defaultButton__1doon:active {
    transform: scale(0.99) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .style_studentbox__18Th0 {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    width: 100% !important;
  }

  .style_titleBox__1I8Yt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .style_avatarBox__3yGNi {
    height: 30px;
    width: 30px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
  }

  .style_detailsAvatarBox__3fZnD {
    height: 40px;
    width: 40px;
    border: 1px solid #FFF;
    border-radius: 50%;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .style_searchBox__2rqwI {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #D5D5D5;
    border-radius: 24px;
    margin-top: 30px;
    position: relative;
    bottom: 12.5px;
    width: 52%;
    padding-right: 5px;
  }

  .style_placeholderStyle__3tXYT {
    font-size: 0.75em;
    color: #000000;
    font-family: "Poppins";
    font-weight: 400;
    opacity: 0.3;
  }

  .style_placeholderStyleSelect__732qV {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .style_searchIconBox__2U3q3 {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #7FB1B2;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .style_filterMainBox__3e38H {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100% !important;
  }

  .style_selectInput__3fsN3 {
    color: #707070;
    border-radius: 24px;
    border: 1px solid #E3E2E2;
    font-style: normal !important;
    width: 35%;
    height: 30px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .style_studentName__2EE-l {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: 500;
    color: var(--title-color);
    margin-top: 5px;
  }

  .style_levelBox__1boFz {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EBEAEA;
    padding-top: 2px;
    padding-bottom: 2.5px;
    border-radius: 5px;
    width: 70px;
    margin-top: 5px;
    font-size: 0.7em;
    font-weight: 400;
  }

  .style_studentAvgBox__23V6j {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.7em;
    font-weight: 500;
    font-family: "Poppins";
    color: #707070;
    margin-top: 0px;
  }

  .style_studentAvg__3e3Qv {
    font-size: 0.8em;
    font-weight: 500;
    font-family: "Poppins";
    color: #707070;
    margin-left: 5px;
    position: relative;
    top: 1px;
  }

  .style_tooltipBox__3mOtK {
    display: flex;
    flex-direction: column;
    width: 140px;
    background: #FFF;
    padding: 10px;
  }

  .style_emptyData__2gY0T {
    height: 480px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    text-align: center;
  }
}
.style_field__3DbDv {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
}

.style_field__3DbDv::placeholder {
    font-style: italic !important;
}

.style_nextButton__q1xNa {
    font-size: 18px;
    padding: 20px;
}

@media only screen and (max-width: 767px) {
    .style_field__3DbDv {
        height: 40px !important;
        border-radius: 10px !important;
        border: 1px solid var(--text-color) !important;
        font-style: italic !important;
        color: #707070;
        font-size: 12px;
    }

    .style_field__3DbDv::placeholder {
        font-size: 12px !important;
    }

    .style_nextButton__q1xNa {
        font-size: 12px;
        padding: 0px;
    }
}
.style_stats__eloG_ {
    margin-top: 2em;
    margin-bottom: 5em;
}

.style___select__3C3ov {
    margin-top: 15px;
}

.style___select__3C3ov div {
    position: relative;
    text-align: center;
    font-size: 2.4em;
    margin-top: var(--mb-1);
    line-height: 0 !important;
    font-family: var(--title-font);
    text-transform: uppercase;
    border: 1px solid black !important;
}

.style_iconArrowClass__VSrKH {
    width: 20px;
    font-size: 12px !important;
    top: 3px !important;
    right: 30px !important;
}

.style_header__1oFTL {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 4em;
    margin-top: 0.5em;
}

.style_card__matier__3R3xm {
    position: relative;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin-top: var(--mb-2);
    transition: all 0.7s;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    padding-left: 0px;
    padding-right: 5px;
}

.style_card__matier__3R3xm.style_active__2nrt2,
.style_card__matier__3R3xm:hover {
    width: 200px;
    height: 76px;
    background: var(--first-color);
    box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.style_card__matier__3R3xm.style_active__2nrt2 .style_topic_name__2pkVG,
.style_card__matier__3R3xm .style_topic_name__2pkVG {
    position: absolute;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform ease-out 0.7s;
    transform-origin: center right;
    transition: all 0.7s;
    -webkit-transition: transform ease-out 0.7s;
    -moz-transition: transform ease-out 0.7s;
    -ms-transition: transform ease-out 0.7s;
    -o-transition: transform ease-out 0.7s;
}

.style_card__matier__3R3xm.style_active__2nrt2 .style_topic_name__2pkVG,
.style_card__matier__3R3xm:hover .style_topic_name__2pkVG {
    position: relative;
    visibility: visible;
    transform: translateX(0);
}

.style_topic_name__2pkVG h3 {
    color: #fff;
    font-family: var(--title-font);
    white-space: nowrap;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.style_icon__card__matiers__1lVUc {
    transform-origin: center left;
    transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.style_icon__card__matiers__1lVUc img {
    width: 50px;
}

.style_main__3OTpa {
    width: 100%;
}

.style_card__dashboard__2wCQy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    padding: 1.5em;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.style_infos_top__1p3Ja {
    margin-bottom: var(--mb-1);
}

.style_infos_top__1p3Ja h2 {
    font-size: 1.3em;
}

.style_right__q6_VM h3 {
    text-transform: uppercase;
    font-size: 1em;
}

.style_content__propositions__10o8h {
    width: 100%;
    padding: 1em 4em;
}

.style_propositions__card__2tKdS {
    margin-top: var(--mb-6);
}

.style_content_card__s5gOX {
    margin-top: var(--mb-2);
}

.style_card__3nB_4 {
    width: 100%;
    min-height: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2em;
    gap: 2em;
    padding: 0 2em;
    margin-bottom: 1em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.style_card__3nB_4 h4 {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-color);
}

.style_isCorrect__E6ZKR {
    border: 1.3px solid green;
}

.style_propositionSelected__29_GB {
    border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
    .style_right__q6_VM {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.style_stats__dashboard__166Og {
    width: 100%;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-top: 20px;
}

.style_backDiv__3RltW {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    height: 35px;
    width: auto;
}

.style_backButton__sgAiP {
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.style_statsContainer__wq5sd {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_radarContainer__QsNLD {
    height: auto;
    width: 40%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    cursor: pointer;
}

.style_radarContainer__QsNLD:hover {
    background: #80b1b21a;
}

.style_radarsContainer__1JANC {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.style_radarsContainer__1JANC:hover {
    background: #80b1b21a;
}

.style_boxTitle__1DhPv {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    margin-left: 20px;
    margin-top: 10px;
    font-weight: 600;
}

.style_boxTitle__1DhPv:hover {
    color: #333;
}

.style_radarBox__3Rz5d {
    height: auto;
    width: 100%;
    margin-top: 10px;
    background: transparent;
    border-radius: 15px;
    cursor: pointer;
}

.style_radarTitle__24s-k {
    color: #333;
    font-size: 1.2em;
    font-weight: 500;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_radarTitle__24s-k:hover {
    color: #333;
}

.style_radarMarkBox__18lqy {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_radarAVG__1Rx9P {
    font-size: 35px;
    color: #333;
    font-weight: bold;
}

.style_radarAVG__1Rx9P:hover {
    color: #333;
}

.style_radarMaxAVG__16ohW {
    margin-top: 10px;
    font-weight: bold;
}

.style_radarMaxAVG__16ohW:hover {
    color: #333;
}

.style_radarChartBox__3_pzT {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 100%;
}

.style_lineContainer__19PX8 {
    height: auto;
    width: 58%;
    display: flex;
    flex-direction: column;
}

.style_lineBox__34nPu {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    grid-gap: 10px;
}

.style_lineChartCard__xAXME {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
}

.style_lineChartCard__xAXME:hover {
    background-color: #80b1b21a;
}

.style_lineChartCardHeader__TBP7a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_lineChartCardAVG__3_gIg {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 20px;
    color: #333;
    font-weight: normal;
}

.style_lineTitle__11qtY {
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.style_backHeader__1fTUp {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.style_backLeft__2Bre7 .style_backTitle__2byJD {
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.style_center__2yCiq {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.style_arrowRightButton__3MfDW {
    border-radius: 50px;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: #fff 0px solid;
}

.style_arrowRightButton__3MfDW:hover {
    background: #80B1B2;
    color: white;
}

@media only screen and (max-width: 767px) {
    .style_backLeft__2Bre7 .style_backTitle__2byJD {
        text-transform: uppercase;
        font-size: 1.2em;
        color: var(--text-color);
    }

    .style_lineContainer__19PX8 {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_lineContainer__19PX8 {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .style_lineChartCard__xAXME {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        margin-top: 10px;
    }

    .style_lineBox__34nPu {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .style_radarContainer__QsNLD {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .style_statsContainer__wq5sd {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .style_card__dashboard__2wCQy {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_stats__dashboard__166Og {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_topic_name__2pkVG h3 {
        font-size: 0.7em;
    }

    .style_icon__card__matiers__1lVUc img {
        width: 30px;
    }

    .style_card__matier__3R3xm {
        width: auto;
        height: 60px;
        margin-top: var(--mb-1);
    }

    .style_card__matier__3R3xm.style_active__2nrt2,
    .style_card__matier__3R3xm:hover {
        width: auto;
        height: 50px;
    }

    .style_content__propositions__10o8h {
        padding: 0;
    }

    .style_header__1oFTL {
        grid-gap: 0.5em;
        gap: 0.5em;
        margin-bottom: 2em;
        margin-top: 0;
    }

    .style_right__q6_VM {
        margin-top: 1em;
    }

    .style_card__3nB_4 {
        padding: 0 1em;
    }

    .style___select__3C3ov div {
        font-size: 1.5em;
        margin-bottom: var(--mb-1);
    }

    .style_iconArrowClass__VSrKH {
        width: 15px;
        top: -1px !important;
        right: 60px !important;
    }

    .style_propositions__card__2tKdS {
        margin-top: var(--mb-4);
    }

    .style_radarChartBox__3_pzT {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .style_radarBox__3Rz5d {
        height: auto;
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
        background: white;
        border-radius: 15px;
    }
}

.style_defaultButtonPositionned__19_sv {
    position: relative;
    bottom: 12.5px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.style_defaultButtonPositionned__19_sv:hover {
    transform: scale(1.03) !important;
}

.style_defaultButtonPositionned__19_sv:active {
    transform: scale(0.99) !important;
}

.style_studentsBox__2p7vu {
    width: 100%;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    grid-gap: 20px;
    background: #fafafa;
    border-radius: 15px;
    padding: 1%;
}

.style_studentBox__2OODD {
    background: #fafafa;
    border-radius: 15px;
    padding: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.style_dashedBox__1woBk {
    padding: 10px;
    border: 1px dashed #707070;
    background: #fff;
    border-radius: 15px;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    font-weight: 600;
}

.style_rateBox__2vFH9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    margin-top: 15px;
}

.style_rateBoxSpan__12WCN {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
}

.style_rateBoxStar__3hTKi {
    position: relative;
    top: -1px; 
    margin-right: 5px;
}
/**************************************** CSS ****************************************/

.register_step_container__57KO7 {
  display: none;
}

.register_register__teacher__29040 {
  width: 100%;
  text-align: left;
}

.register_title__step__3LS5U {
  line-height: 22px;
  width: 220px;
  position: relative;
  left: -50px;
}

.register_step__2Erng {
  width: 45%;
  max-width: 45%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
}

/* JOFREY STYLE */

.register_addStudentButton__1N8i5 {
  position: relative;
  bottom: 12.5px;
  border-radius: 50px;
  color: white;
  background-color: #80B1B2;
}

.register_subtitle__olPQV {
  font-size: 1.5em;
  color: #000;
}

.register_accountFormBox__3d35n {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  height: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.register_formBox__2CdIm {
  width: 100%;
  max-width: 100%;
  margin: 40px auto;
  border-radius: 5px;
  background-color: #fafafa;
  height: auto;
  padding: 30px 80px 120px 80px;
  display: flex;
  flex-direction: column;
}

.register_formElementBox__LevYf {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.register_stepTitleBox__36cN7 {
  text-align: left;
  align-items: flex-start;
  align-content: start;
}

.register_boxTitle__hqrDD {
  font-size: 28px;
  font-weight: 600;
  color: #707070;
  font-family: "CartoonFont";
  text-align: left;
  text-transform: uppercase;
}

.register_boxSubtitle__zNETL {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.register_boxMessage__3ZPYf {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 35px;
}

.register_selectInput__lyZQ4 {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #707070;
  font-style: italic !important;
  width: 100%;
}

.register_placeholderStyle__AJbOZ {
  color: #C0C0C0;
  font-size: 16px;
}

.register_nextButton__1T0rg {
  font-size: 18px;
  padding: 20px;
}

.register_hideElement__2ZRcO {
  display: none;
}

.register_uploadButtonStyle__35ky9 {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
}

.register_uploadVignetteButton__2glP5 {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 130px;
}

.register_startRecordingVideo__1LZOi {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.register_cardInfoBox__1FvU6 {
  background-image: url(/static/media/card.b30b4ea7.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 35px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxToDo__16ul6 {
  background-image: url(/static/media/cardLong.0806ab99.png);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxWarning__M9Q0w {
  background-image: url(/static/media/cardAds.9ca57eea.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 0px;
  padding: 0;
}

.register_avatarExampleBox__36qq0 {
  background-image: url(/static/media/pictureExample.bc58bf9e.png);
  background-size: cover;
  height: 120px;
  width: 380px;
  border-radius: 8px;
  margin: 5px 7px 0px 35px;
}

/* Drag & Drop Input CSS */

#register_formFileUpload__vP2DQ {
  height: 425px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#register_inputFileUpload__2JNxU {
  visibility: collapse;
}

#register_labelFileUpload__Ucqxx {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #707070;
  background-color: #fff;
}

#register_labelFileUpload__Ucqxx.register_dragActive__3WpgE {
  background-color: #ffffff;
}

.register_uploadButton__3QQIb {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.register_uploadButton__3QQIb:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#register_dragFileElement__2nMdl {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* Drag & Drop Input CSS */

.register_uploadImageBox__2FVtb {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_uploadImageBoxTitle__fK8RT {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_uploadImageBoxMessage__1L4ID {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_calendarBoxTitle__3lIgf {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
}

.register_calendarBoxSubtitle__r7XW5 {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin: 30px auto;
  padding-left: 210px;
}

.register_calendareBoxMessage__14bFS {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubMessage__KhOXw {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxTitle__3211w {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 45px;
  margin-left: 35px;
}

.register_uploadVideoBoxMessage__3WbtY {
  font-size: 14.5px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubtitle__2h_-d {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_addPictureButton__1QJIw {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.register_addDocPictureButton__3J64y {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.register_addVideoButton__1Wttj {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 20px;
}

.register_addVignetteButton__3CxZe {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 30px;
}

.register_previewBox__1DZP5 {
  margin-left: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #707070;
  border-style: dashed;
}

.register_previewBoxImage__3NPa0 {
  max-width: 200px;
  height: auto;
  border-radius: 15px;
}

.register_uploadBox__3o0LD {
  display: flex;
  flex-direction: column;
}

.register_uploadBoxTitle__2HROT {
  font-size: 20px;
  font-style: italic;
  color: #333;
}

.register_uploadBoxMessage__3rieZ {
  font-size: 20px;
  font-style: italic;
  color: #CBCACD;
}

.register_uploadBoxMessageError__1q71E {
  font-size: 20px;
  font-style: italic;
  color: red;
}

.register_uploadBoxBorder__k-bvm {
  margin-left: 35px;
  margin-top: 25px;
  border: none;
  border-radius: 15px;
}

.register_uploadBoxBorderError__1lwHX {
  margin-left: 35px;
  margin-top: 25px;
  border: 1px solid red;
  border-radius: 15px;
}

.register_exampleBox__vSIjW {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_exampleBoxLong__BtYW6 {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 30px;
}

.register_exampleBoxTitle__29CeA {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_videoExampleBoxTitle__1-8ou {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 0px;
}

.register_instructionBox__3vnjm {
  padding-left: 65px;
  padding-right: 60px;
  margin-top: 42px;
}

.register_instructionBoxLong__35xup {
  padding: 60px 40px 10px 65px;
  margin-top: 42px;
}

.register_checkboxBox__3Pdim {
  display: flex;
  flex-direction: row;
}

.register_checkboxBoxItem__14SYh {
  margin-left: 10px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxItemLong__2LnoT {
  margin-left: 5px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxLong__TVtBR {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.register_customTextarea__2DnS8 {
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

/* Placeholder color */
.register_customTextarea__2DnS8::placeholder {
  color: #C0C0C0;
}

/* Focus color */
.register_customTextarea__2DnS8:focus {
  outline: none;
  border-color: #707070;
  box-shadow: 0 0 2px green;
}

#register_videoFileUpload__3axI- {
  background-image: url(/static/media/videoExample.d39fc304.png);
  background-size: cover;
  height: 300px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-left: 35px;
  margin-top: 25px;
}

.register_vignetteBox__3YGHh {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0px 0px 35px;
  padding: 15px 20px 15px 20px;
  width: 540px;
  align-items: center;
  justify-content: left;
}

/* .step .stepsBox {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
} */

.register_addCertificateDiplomaButton__b1Rmx {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 50px;
}

.register_customButtonStyle__38dvh {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.register_customButtonStyle__38dvh:hover {
  transform: scale(1.03) !important;
}

.register_customButtonStyle__38dvh:active {
  transform: scale(0.99) !important;
}

.register_stopButtonStyle__3rEos {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: red;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgb(182, 85, 85) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
  -moz-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
}

.register_stopButtonStyle__3rEos:hover {
  transform: scale(1.03) !important;
}

.register_stopButtonStyle__3rEos:active {
  transform: scale(0.99) !important;
}

.register_certificateDiplomaFormBox__13pWr {
  margin: 0px auto;
  height: auto;
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.register_pictureBoxRow__2aw_n {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.register_picturePreviewBox__2Ry8k {
  height: auto;
  width: auto;
  border: 1px solid #333;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
}

.register_titleBox__2q4U6 {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 74%;
}

.register_videoPreviewBox__2Ybd1 {
  height: auto;
  width: 560px;
  border-radius: 5px;
  margin-left: 35px;
  margin-top: 25px;
}

.register_videoLinkInput__1ggKO {
  margin-left: 35px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.register_videoFormatTitle__o92-J {
  font-size: 13px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 10px;
}

.register_calendarTimezone__3N6PH {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.register_fillLaterButton__3Cbfk {
  height: 50px;
  width: 65%;
  max-width: 40%;
  margin-left: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.register_removeButton__3A-iw {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
  padding: 5px;
  border-radius: 50px;
  border: none;
  background-color: red;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.register_removeButton__3A-iw:hover {
  padding: 7px;
  background-color: #fff;
  border: 1px solid red;
  color: red;
  cursor: pointer;
}

.register_previewCertificateDiploma__tMWZJ {
  height: 110px;
  width: 110px;
  position: relative;
  bottom: 40px;
  margin-top: 10px;
}

/* JOFREY STYLE */

.register_teacher__B9i7j {
  display: flex;
  align-items: center;
  margin: 0px auto;
}

.register_teacher__B9i7j .register_avatar__llXjj {
  padding-right: var(--mb-4);
}

.register_header__text__KwOP3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.register_header__text__KwOP3 p {
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.register_header__text__KwOP3 p strong,
.register_text__VAiY5 strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register_text__VAiY5 a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_content__action__2a0wJ {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0px auto;
}

.register_button__2H3wQ svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.register_form__container__2plvC {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.register_field__xsuEf {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
}

.register_field__xsuEf::placeholder {
  font-style: italic !important;
}

.register_termes__legals__1mgjL {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.register_termesToAccept__17vEa {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.register_button__2H3wQ {
  width: 100%;
  height: 40px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.register_text__legal__1cooq {
  text-align: left;
  font-size: var(--small-font-size);
}

.register_inputRow__1zY9m {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.register_inputRowChild__3O2p_ {
  width: 49%;
}

.register_avatarContainer__2bzEO {
  display: flex;
  flex-direction: row;
}

.register_rowButtons__2goIx {
  display: flex;
  width: 100%;
  margin: 40px auto;
  align-items: center;
  justify-content: center;
}

.register_rowButtonChild__aq-0f {
  max-width: auto;
  margin: 0 10px;
}

.register_formContainer__1OYiY {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_docBox__27GFf {
  height: auto;
  width: 100%;
  border: 1px solid #707070;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.register_docBoxImage__27GEP {
  margin: 20px 0px 0px 15px;
  display: flex;
  flex-direction: row;
}

.register_docBoxTitle__3NUnx {
  font-size: 15px;
  color: #111;
  font-weight: 700;
  margin-left: 7px;
}

.register_docBoxMessage__2AcBl {
  margin: 0px 0px 0px 40px;
  font-size: 14px;
  color: #707070;
}

.register_docBoxMessageFormat__3jfyZ {
  font-size: 12px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 20px;
}

.register_uploadInputForm__1JvHf {
  width: 100%;
  visibility: visible;
  margin-left: 35px;
}

.register_uploadFileInput__1c13t {
  height: 0px;
  visibility: collapse;
  margin-left: 35px;
}

.register_uploadDocBox__33FBZ {
  height: 30px;
}

.register_teacherDescriptionTitle__19rxQ {
  font-size: 20px;
  color: #333;
  font-weight: 500;
}

.register_teacherDescriptionBox__VoDOH {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin: 35px 0px 20px 50px;
}

.register_stepFiveContent__2S4nr {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_textareaMaxText__37i2y {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  font-style: italic;
  color: #636363;
}

.register_textareaMessageFull__2dSHj {
  display: inline;
  font-size: 18px;
  font-style: italic;
  color: #CBCACD;
}

.register_textareaMessageSmall__2JRgm {
  display: none;
}

.register_customTextarea__2DnS8 {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.register_customDescriptionTextarea__2D-Bo {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.register_customLinkedTextarea__3Yeps {
  display: none;
}

.register_linkedInput__29trM {
  display: block;
}

.register_linkedError__2OTag {
  font-size: 12px;
  color: red;
}

.register_thumbnailMessage__1LCk_ {
  font-size: 14px;
  color: #707070;
  margin-left: 25px;
}

.register_explanationBox__2P10N {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: end;
  align-items: start;
}

.register_explanationBoxTitle__xNmHU {
  font-size: 28px;
  color: #5A8484;
  text-transform: uppercase;
  font-weight: 600;
}

.register_questionMark__1ZMAO {
  margin-top: 7px;
  margin-right: 5px;
}

.register_calendarBox__14OeI {
  background-color: #fff;
  padding: 20px;
}

.register_stopButton__UNq6y {
  position: fixed;
  bottom: 20%;
  right: 20%;
  width: 70px;
  height: 70px;
  background-color: red;
  cursor: pointer;
}

.register_videoLoading__1WN_V {
  position: fixed;
  left: 46%;
  top: 43%;
  font-family: "CartoonFont";
  font-size: 34px;
  color: #fff;
}

.register_videoRecorderBox__3tEWY {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 12.9%;
  right: 12.9%;
  background: black;
}

.register_videoRecorderClearBox__3G_qI {
  display: flex;
  flex-direction: column;
  margin-top: 4.5%;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 0px;
  right: 0px;
  bottom: 2%;
}

.register_stopButtonBox__1FH69 {
  position: fixed;
  bottom: 25%;
  right: 20%;
}

/************************************** MediaQuery **************************************/

@media only screen and (max-width: 767px) {

  .register_step__2Erng {
    display: none;
  }

  .register_step_container__57KO7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    grid-gap: 20px;
    gap: 20px;
    margin: 20px auto;
    font-size: 1.2em;
  }

  .register_formsteps__14REa {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 10px;
    width: 70px;
  }

  .register_calendarBox__14OeI {
    background-color: #fff;
    padding: 10px;
  }

  .register_questionMark__1ZMAO {
    margin-top: -1px;
    margin-right: 5px;
    margin-left: 15px;
  }

  .register_explanationBoxTitle__xNmHU {
    font-size: 18px;
    color: #5A8484;
    text-transform: uppercase;
    font-weight: bold;
  }

  .register_explanationBox__2P10N {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: start;
    align-items: center;
  }

  .register_checkboxBoxItemLong__2LnoT {
    margin-left: 5px;
    color: #707070;
    font-size: 10px;
  }

  .register_instructionBoxLong__35xup {
    padding: 50px 20px 0px 30px;
    margin-top: 0px;
  }

  .register_cardInfoBoxToDo__16ul6 {
    background-image: url(/static/media/cardLong.0806ab99.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    margin-left: 5px;
    margin-top: 15px;
    padding: 0;
  }

  .register_uploadVideoBoxSubMessage__KhOXw {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .register_videoExampleBoxTitle__1-8ou {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 0px;
  }

  .register_thumbnailMessage__1LCk_ {
    font-size: 12px;
    color: #707070;
    margin-left: 25px;
  }

  .register_startRecordingVideo__1LZOi {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .register_linkedInput__29trM {
    display: none;
  }

  .register_textareaMessageFull__2dSHj {
    display: none;
  }

  .register_textareaMessageSmall__2JRgm {
    display: inline;
    font-size: 11px;
    font-style: italic;
    color: #CBCACD;
    margin-left: 5px;
  }

  .register_textareaMaxText__37i2y {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
    font-style: italic;
    color: #636363;
  }

  .register_customTextarea__2DnS8 {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .register_customLinkedTextarea__3Yeps {
    display: block;
    width: 100%;
    padding: 5px 10px 5px 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .register_customDescriptionTextarea__2D-Bo {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 10px;
  }

  .register_stepFiveContent__2S4nr {
    width: 78%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .register_teacherDescriptionBox__VoDOH {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 20px auto;
  }

  .register_teacherDescriptionTitle__19rxQ {
    font-size: 12px;
    color: #333;
    font-weight: 500;
  }

  /* .rowButtonChild {
    max-width: auto;
    margin-right: 5px;
  } */

  .register_uploadDocBox__33FBZ {
    height: 30px;
    position: relative;
    top: -30px;
    left: 35px;
  }

  .register_uploadInputForm__1JvHf {
    width: 100%;
    visibility: visible;
    margin-left: 5px;
    font-size: 10px;
  }

  .register_uploadFileInput__1c13t {
    height: 0px;
    visibility: collapse;
    margin-left: 5px;
  }

  .register_uploadFileButton__3a6Yd {
    margin-left: 17px;
    width: 20%;
  }

  .register_uploadButtonStyle__35ky9 {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
  }

  .register_docBoxMessageFormat__3jfyZ {
    font-size: 11px;
    color: #707070;
    margin-left: 17px;
    margin-top: 20px;
  }

  .register_docBoxMessage__2AcBl {
    margin: 0px 0px 0px 40px;
    font-size: 11px;
    margin-right: 5px;
    color: #707070;
  }

  .register_docBoxTitle__3NUnx {
    font-size: 12px;
    color: #111;
    font-weight: 700;
    margin-left: 7px;
  }

  .register_docBoxImage__27GEP {
    margin: 20px 0px 0px 15px;
    display: flex;
    flex-direction: row;
  }

  .register_docBox__27GFf {
    height: auto;
    width: 100%;
    border: 1px solid #707070;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }

  .register_form__container__2plvC {
    width: 95%;
    max-width: 600px;
    margin: 40px auto;
  }

  .register_formContainer__1OYiY {
    width: 78%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding: 0px auto;
  }

  .register_termesToAccept__17vEa {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    max-width: 78%;
  }

  .register_register__teacher__29040 {
    padding: 0 3%;
  }

  .register_teacher__B9i7j .register_avatar__llXjj {
    padding-right: 0;
  }

  .register_teacher__B9i7j .register_avatar__llXjj img {
    position: relative;
    width: 30px;
    top: -3px;
    margin: 0;
  }

  .register_header__text__KwOP3 p {
    font-size: var(--normal-font-size);
    margin-top: 3px;
  }

  .register_title__step__3LS5U {
    font-size: 8px;
    white-space: nowrap;
    display: block;
  }

  .register_placeholderStyle__AJbOZ {
    color: #C0C0C0;
    font-size: 12px;
  }

  .register_inputRow__1zY9m {
    display: flex;
    flex-direction: column;
  }

  .register_inputRowChild__3O2p_ {
    width: 100%;
  }

  /* .step {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    overflow: auto;
    scrollbar-width: thin;
  } */

  .register_field__xsuEf {
    height: 40px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
    font-size: 12px;
  }

  .register_field__xsuEf::placeholder {
    font-size: 12px !important;
  }

  .register_policy__2IUBJ {
    position: relative;
    font-size: 11px !important;
  }

  .register_policy__2IUBJ br {
    display: none;
  }

  .register_uploadImageBoxTitle__fK8RT {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
    margin-left: 35px;
  }

  .register_avatarContainer__2bzEO {
    display: block;
  }

  .register_formBox__2CdIm {
    width: 100%;
    max-width: 100%;
    margin: 40px auto;
    border-radius: 5px;
    background-color: #fafafa;
    height: auto;
    padding: 30px 5px 120px 5px;
    display: flex;
    flex-direction: column;
  }

  .register_stepTitleBox__36cN7 {
    text-align: center;
    align-items: center;
    align-content: center;
  }

  .register_boxTitle__hqrDD {
    font-size: 18px;
    font-weight: 600;
    color: #707070;
    font-family: "CartoonFont";
    text-align: center;
    text-transform: uppercase;
  }

  .register_boxMessage__3ZPYf {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .register_uploadBoxBorder__k-bvm {
    margin: 20px auto;
    border: none;
    border-radius: 15px;
  }

  #register_formFileUpload__vP2DQ {
    height: 225px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  .register_instructionBox__3vnjm {
    padding-left: 35px;
    padding-right: 15px;
    margin-top: 28px;
  }

  .register_checkboxBoxItem__14SYh {
    margin-left: 10px;
    color: #707070;
    font-size: 10px;
  }

  .register_cardInfoBox__1FvU6 {
    background-image: url(/static/media/card.b30b4ea7.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 347px;
    width: auto;
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 15px;
  }

  .register_exampleBoxTitle__29CeA {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 15px;
  }

  .register_avatarExampleBox__36qq0 {
    background-image: url(/static/media/pictureExample.bc58bf9e.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 120px;
    width: 90%;
    border-radius: 8px;
    margin: 5px auto;
  }

  .register_previewBoxImage__3NPa0 {
    width: 170px;
    height: auto;
    border-radius: 15px;
  }

  .register_previewBox__1DZP5 {
    display: flex;
    align-items: center;
    justify-items: center;
    border-width: 1px;
    border-radius: 1rem;
    border-color: #707070;
    border-style: dashed;
    width: auto;
    margin: 10px auto;
  }

  .register_customButtonStyle__38dvh {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-left: 40px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  }

  .register_certificateDiplomaFormBox__13pWr {
    margin: 0px auto;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .register_pictureBoxRow__2aw_n {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    align-items: center;
  }

  .register_picturePreviewBox__2Ry8k {
    height: auto;
    width: auto;
    border: 1px solid #333;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    display: flex;
  }

  .register_titleBox__2q4U6 {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    margin-top: 20px;
  }

  .register_videoPreviewBox__2Ybd1 {
    height: auto;
    width: auto;
    border-radius: 5px;
    margin-left: 0px;
    margin-top: 25px;
  }

  #register_videoFileUpload__3axI- {
    background-image: url(/static/media/videoExample.d39fc304.png);
    background-size: cover;
    height: 150px;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 25px auto;
    background-repeat: no-repeat;
  }

  .register_exampleBoxLong__BtYW6 {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    position: relative;
    top: -70px;
  }

  .register_uploadImageBox__2FVtb {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .register_uploadImageBoxTitle__fK8RT {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 15px;
    margin-left: 0px;
  }

  .register_uploadImageBoxMessage__1L4ID {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 0px;
    color: #707070;
  }

  .register_vignetteBox__3YGHh {
    display: flex;
    flex-direction: row;
    border: 1px solid #333;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 20px auto;
    padding: 15px 20px 15px 20px;
    width: auto;
    align-items: center;
    justify-content: left;
  }

  .register_videoLinkInput__1ggKO {
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .register_uploadVideoBoxMessage__3WbtY {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .register_uploadVideoBoxSubtitle__2h_-d {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 0px;
  }

  .register_uploadVideoBoxTitle__3211w {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-left: 0px;
  }

  .register_addVideoButton__1Wttj {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 10px;
  }

  .register_addVignetteButton__3CxZe {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 30px;
  }

  .register_videoFormatTitle__o92-J {
    font-size: 13px;
    color: #CBCACD;
    margin-left: 5px;
    margin-top: 10px;
  }

  .register_calendarBoxTitle__3lIgf {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 5px;
  }

  .register_calendareBoxMessage__14bFS {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .register_calendarBoxSubtitle__r7XW5 {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 30px auto;
    padding-left: 5px;
  }

  .register_calendarTimezone__3N6PH {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
  }

  .register_fillLaterButton__3Cbfk {
    height: 50px;
    width: 150px;
    margin-left: 0px;
  }

  .register_addPictureButton__1QJIw {
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .register_addDocPictureButton__3J64y {
    height: auto;
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .register_addPictureButtonBox__23b4c {
    width: auto;
  }

  .register_previewCertificateDiploma__tMWZJ {
    height: auto;
    width: 100%;
    position: relative;
    bottom: 0px;
    margin-left: 17px;
  }

  .register_nextButton__1T0rg {
    font-size: 12px;
    padding: 0px;
  }

  .register_title__1jEiS {
    font-size: 14px;
  }

  .register_rowButtons__2goIx {
    display: flex;
    width: 100%;
    margin: 40px auto;
    align-items: center;
    justify-content: center;
  }

  .register_rowButtonChild__aq-0f {
    max-width: auto;
    margin: 0 10px;
  }

  .register_stopButtonBox__1FH69 {
    position: relative;
    bottom: 12%;
    left: -6%;
    margin: 0px auto;
  }

  .register_videoLoading__1WN_V {
    position: relative;
    left: 0%;
    margin: 0px auto;
    font-family: "CartoonFont";
    font-size: 34px;
    color: #fff;
  }

  .register_videoRecorderBox__3tEWY {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    background: black;
  }

  .register_videoRecorderClearBox__3G_qI {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    bottom: 2%;
  }

}

.register_invitationForm__3pBlE {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.register_invitationFormMobile__YrUCG {
  text-align: center;
}
.style_stats__2rwOR {
    margin-top: 2em;
    margin-bottom: 5em;
}

.style___select__2HQL- {
    margin-top: 15px;
}

.style___select__2HQL- div {
    position: relative;
    text-align: center;
    font-size: 2.4em;
    margin-top: var(--mb-1);
    line-height: 0 !important;
    font-family: var(--title-font);
    text-transform: uppercase;
    border: 1px solid black !important;
}

.style_iconArrowClass__1-C0i {
    width: 20px;
    font-size: 12px !important;
    top: 3px !important;
    right: 30px !important;
}

.style_header__2XcoW {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 4em;
    margin-top: 0.5em;
}

.style_card__matier__37AXf {
    position: relative;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin-top: var(--mb-2);
    transition: all 0.7s;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    padding-left: 0px;
    padding-right: 5px;
}

.style_card__matier__37AXf.style_active__1rs-R,
.style_card__matier__37AXf:hover {
    width: 200px;
    height: 76px;
    background: var(--first-color);
    box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.style_card__matier__37AXf.style_active__1rs-R .style_topic_name__jfYf3,
.style_card__matier__37AXf .style_topic_name__jfYf3 {
    position: absolute;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform ease-out 0.7s;
    transform-origin: center right;
    transition: all 0.7s;
    -webkit-transition: transform ease-out 0.7s;
    -moz-transition: transform ease-out 0.7s;
    -ms-transition: transform ease-out 0.7s;
    -o-transition: transform ease-out 0.7s;
}

.style_card__matier__37AXf.style_active__1rs-R .style_topic_name__jfYf3,
.style_card__matier__37AXf:hover .style_topic_name__jfYf3 {
    position: relative;
    visibility: visible;
    transform: translateX(0);
}

.style_topic_name__jfYf3 h3 {
    color: #fff;
    font-family: var(--title-font);
    white-space: nowrap;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.style_icon__card__matiers__33GJ2 {
    transform-origin: center left;
    transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.style_icon__card__matiers__33GJ2 img {
    width: 50px;
}

.style_main__tQmjV {
    width: 100%;
}

.style_card__dashboard__9wg9I {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    padding: 1.5em;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.style_infos_top___b-mI {
    margin-bottom: var(--mb-1);
}

.style_infos_top___b-mI h2 {
    font-size: 1.3em;
}

.style_right__SeHOQ h3 {
    text-transform: uppercase;
    font-size: 1em;
}

.style_content__propositions__3IXne {
    width: 100%;
    padding: 1em 4em;
}

.style_propositions__card__3n0hp {
    margin-top: var(--mb-6);
}

.style_content_card__BmU-W {
    margin-top: var(--mb-2);
}

.style_card__3XYq6 {
    width: 100%;
    min-height: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: 2em;
    gap: 2em;
    padding: 0 2em;
    margin-bottom: 1em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.style_card__3XYq6 h4 {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-color);
}

.style_isCorrect__2HS5c {
    border: 1.3px solid green;
}

.style_propositionSelected__127cb {
    border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
    .style_right__SeHOQ {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.style_stats__dashboard__26CGa {
    width: 100%;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-top: 20px;
}

.style_backDiv__3dgkA {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    height: 35px;
    width: auto;
}

.style_backButton__7LGmV {
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.style_statsContainer__34u7x {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_radarContainer__30yLE {
    height: auto;
    width: 40%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.style_boxTitle__wKeqv {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    margin-left: 20px;
    margin-top: 10px;
    font-family: "CartoonFontBold";
    font-weight: 600;
}

.style_boxTitle__wKeqv:hover {
    color: #333;
}

.style_radarBox__3tTgE {
    height: auto;
    width: 85%;
    margin-left: 10%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
}

.style_radarTitle__1hL1S {
    color: #333;
    font-size: 1.5em;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 20px;
}

.style_radarTitle__1hL1S:hover {
    color: #333;
}

.style_radarMarkBox__YX6e4 {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_radarAVG__1IOPI {
    font-size: 35px;
    color: #333;
    font-weight: bold;
}

.style_radarAVG__1IOPI:hover {
    color: #333;
}

.style_radarMaxAVG__3i7I3 {
    margin-top: 10px;
    font-weight: bold;
}

.style_radarMaxAVG__3i7I3:hover {
    color: #333;
}

.style_radarChartBox__3La1r {
    display: flex;
    justify-content: center;
    padding: 0px;
}

.style_lineContainer__22u_U {
    height: auto;
    width: 58%;
    display: flex;
    flex-direction: column;
}

.style_lineBox__2K8Ye {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.style_lineChartCard__1aCWl {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
}

.style_lineChartCard__1aCWl:hover {
    background-color: #80b1b21a;
}

.style_lineChartCardHeader__y-blN {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_lineChartCardAVG__2Wtmi {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 20px;
    color: #333;
    font-weight: normal;
}

.style_lineTitle__31Gr3 {
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.style_backHeader__3YUZ5 {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.style_backLeft__3i73L .style_backTitle__2uejD {
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.style_center__3F2Wg {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.style_arrowRightButton__3Sj1m {
    border-radius: 50px;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: #fff 0px solid;
}

.style_arrowRightButton__3Sj1m:hover {
    background: #80B1B2;
    color: white;
}

@media only screen and (max-width: 767px) {
    .style_backLeft__3i73L .style_backTitle__2uejD {
        text-transform: uppercase;
        font-size: 1.2em;
        color: var(--text-color);
    }

    .style_lineContainer__22u_U {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_lineContainer__22u_U {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .style_lineChartCard__1aCWl {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        margin-top: 10px;
    }

    .style_lineBox__2K8Ye {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .style_radarContainer__30yLE {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .style_statsContainer__34u7x {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .style_card__dashboard__9wg9I {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_stats__dashboard__26CGa {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_topic_name__jfYf3 h3 {
        font-size: 0.7em;
    }

    .style_icon__card__matiers__33GJ2 img {
        width: 30px;
    }

    .style_card__matier__37AXf {
        width: auto;
        height: 60px;
        margin-top: var(--mb-1);
    }

    .style_card__matier__37AXf.style_active__1rs-R,
    .style_card__matier__37AXf:hover {
        width: auto;
        height: 50px;
    }

    .style_content__propositions__3IXne {
        padding: 0;
    }

    .style_header__2XcoW {
        grid-gap: 0.5em;
        gap: 0.5em;
        margin-bottom: 2em;
        margin-top: 0;
    }

    .style_right__SeHOQ {
        margin-top: 1em;
    }

    .style_card__3XYq6 {
        padding: 0 1em;
    }

    .style___select__2HQL- div {
        font-size: 1.5em;
        margin-bottom: var(--mb-1);
    }

    .style_iconArrowClass__1-C0i {
        width: 15px;
        top: -1px !important;
        right: 60px !important;
    }

    .style_propositions__card__3n0hp {
        margin-top: var(--mb-4);
    }

    .style_radarChartBox__3La1r {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .style_radarBox__3tTgE {
        height: auto;
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
        background: white;
        border-radius: 15px;
    }

    .style_lineBox__2K8Ye {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
        background: #fafafa;
        border-radius: 15px;
        padding: 1%;
    }
}

.style_dashedBox__UxPgG {
    padding: 10px;
    border: 1px dashed #707070;
    background: #fff;
    border-radius: 15px;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    font-weight: 600;
}

.style_rateBox__1swAs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.style_rateBoxSpan__3zw3o {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1em;
}

.style_rateBoxStar__2nfc4 {
    position: relative;
    top: -1px;
    margin-right: 5px;
}

.style_card__3XYq6 {
    position: relative;
    width: 100%;
    background: #fafafa;
    margin-bottom: var(--mb-4);
    box-shadow: 3px 4px 0px 3px #cfd0d2;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 80px;
    padding-right: 40px;
    padding-bottom: 40px;
}

.style_card__3XYq6:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 70px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.style_card__3XYq6::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 50px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}
.cardTeacher_card__T2YiA {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* max-width: 350px; */
  height: 355px;
  padding: 15px;
  background-size: contain;
  background: #fafafa;
  box-shadow: 3px 2px 0px 2px #cfd0d2;
  transition: 0.5s;
}

.cardTeacher_card__T2YiA:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardTeacher_card__T2YiA::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardTeacher___matiers__1pX08 {
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.3em;
  gap: 0.3em;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-1);
}

.cardTeacher___tag__topic__EPFHG {
  background: #d5d5d5;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
}

.cardTeacher_card__header__1jE2x {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.cardTeacher_content__3OeFN {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1% 2%;
}

.cardTeacher_round__user__2_pOq {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cardTeacher_round__user__2_pOq img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 100px;
}

.cardTeacher_content__3OeFN h3 {
  width: 150px;
  text-transform: uppercase;
  color: #333;
  font-size: 1.2em;
  margin-top: var(--mb-1);
}

.cardTeacher_tarif__VaEnw {
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
  margin-top: var(--mb-1);
}

.cardTeacher_tarif__VaEnw span {
  font-weight: 300;
}

.cardTeacher_btn__profil__prof__3JWSH:hover {
  background: #5a8484 !important;
}

.cardTeacher___description__oMceP {
  width: 100%;
  height: 35px;
  font-size: 12px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 10px;
  margin-top: 5px;
}

.cardTeacher___start__3RrdN {
  position: absolute;
  margin: auto;
  top: -4%;
  right: 8%;
}

.cardTeacher___row__31qrC {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
}

.cardTeacher___row__31qrC button {
  position: relative;
  border: unset;
  padding: 4px 11px;
  border-radius: 8px;
  background: var(--first-color);
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.cardTeacher_calendrier__khWAG {
  width: 1200px;
  height: 850px;
  margin-top: 2em;
  background: url(/static/media/calendarScreen.284fd573.svg) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 1s ease;
}

.cardTeacher_calendar__content__1xn1Z {
  width: 95%;
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: auto;
}

.cardTeacher___drawer__teacher__1PC5e {
  width: 100%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.cardTeacher___drawer__teacher__header__3Xp2f {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cardTeacher___infos__1oSVC h3 {
  font-size: 1.6em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-1);
}

.cardTeacher___drawer__teacher__main__1XQqP {
  margin-top: 2em;
}

.cardTeacher___main__infos__2o9I0 {
  font-size: 1.6em;
  margin-bottom: 1em;
  display: flex;
}

.cardTeacher___main__infos__2o9I0 span {
  text-transform: uppercase;
}

.cardTeacher___drawer_matiere__6E8wh {
  position: relative;
  top: -0.6em;
  margin-left: 1.5em;
}

.cardTeacher___main__infos__2o9I0 p {
  position: relative;
  top: 5px;
  font-size: 0.7em;
  margin-left: 1em;
}

.cardTeacher___plus__1UKEn {
  width: 100%;
  height: 400px;
  background: #fafafa;
}

.cardTeacher___reservation__rhFh7 {
  /* background: var(--first-color); */
  width: 350px;
  height: 350px !important;
}

.cardTeacher_calendarPopupContent__2q8Qi * .cardTeacher_ant-popover-inner-content__2Ehdk {
  padding: 0 !important;
}

.cardTeacher___reservation_content__3Akd- {
  position: relative;
  width: 100%;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* padding-top: 2%; */
}

.cardTeacher___reservation__row__2tQCE {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cardTeacher___reservation_avatar__2aiUX {
  position: relative;
  width: 80px;
  height: 80px;
  top: -10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 100px;
}

.cardTeacher___reservation_avatar__2aiUX img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.cardTeacher___reservation_content__3Akd- h2 {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 1.5em;
}

.cardTeacher_reservation__infos__3a1zK {
  width: 100%;
  padding-left: 3%;
}

.cardTeacher_reservation__infos__3a1zK h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.cardTeacher_reservation__infos__3a1zK span {
  font-size: 11px;
}

.cardTeacher_tag__2XOw4 {
  width: 100px;
  height: 32px;
  border-radius: 20px;
  background: #8ab0b1ca;
  display: flex;
  margin-bottom: 0.5em;
}

.cardTeacher_tag__2XOw4 span {
  margin: auto;
  text-transform: uppercase;
  font-size: 11px;
  color: #fff;
}

.cardTeacher___drawer_button__3Fvhy {
  border-radius: 15px !important;
  height: 40px !important;
  color: var(--first-color);
  text-transform: uppercase;
  min-width: 200px;
}

.cardTeacher___custom__container__3kllK {
  width: 100%;
  height: 100%;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.cardTeacher___liste__card__3FW69 {
  margin-top: var(--mb-4);
}

.cardTeacher___calendar__title__3SJzS,
.cardTeacher___calendar__title__creneau__36BA8 {
  font-size: 12px !important;
  line-height: 14px;
}

.cardTeacher___calendar__title__3SJzS {
  font-size: 11px !important;
}

/* .__open__className {
  min-width: 400px !important;
  max-width: 400px !important;
} */

.cardTeacher___solde_content__2BGJT h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.cardTeacher___prof__description__card__reservation__1dvIk {
  font-size: 12px;
  text-align: center;
}

.cardTeacher___matiersAds__3YZpR * span {
  padding-left: 2px;
}

.cardTeacher___matiersAds__3YZpR {
  margin-bottom: 1.5em;
}

/* ====== */
.cardTeacher_month__1o-sw {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: -0.7em;
}

.cardTeacher_month__1o-sw span {
  text-transform: uppercase;
  font-family: "CartoonFont";
}

.cardTeacher_date__3gKEa {
  font-size: 2em;
}

.cardTeacher_month__1o-sw span:nth-child(2) {
  position: relative;
  font-size: 1.2em;
}

.cardTeacher_month__1o-sw span:nth-child(3) {
  position: relative;
  font-size: 1.2em;
}

.cardTeacher_month__1o-sw span:nth-child(2)::after,
.cardTeacher_month__1o-sw span:nth-child(3)::after {
  content: "";
  position: absolute;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  left: -18px;
  top: 10px;
  background: var(--first-color);
}

.cardTeacher_month__1o-sw span:nth-child(3)::after {
  background: #faad13;
}

/* ======== */

.cardTeacher_card__teacher__reservation__13DUD {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid #fafafabe;
  border-radius: 5px;
  padding: 3% 5%;
}

.cardTeacher_teacher__reservation__avatar__bPpj1 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}

.cardTeacher_teacher__reservation__avatar__bPpj1 img {
  width: 100%;
  height: 100%;
}

.cardTeacher_open__messagerie__3cz1a span {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s all;
}

.cardTeacher_open__messagerie__3cz1a span:hover {
  color: var(--first-color);
}

.cardTeacher_levelGroup__qRhpW div:last-child {
  display: none;
}
.searchTeacher_header__1m08m {
  width: 100%;
  margin-bottom: 0.8em;
}

.searchTeacher_filter__1k2JU {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-2);
  padding: 0 var(--mb-4);
}

.searchTeacher_filed__search__3ZV4P {
  width: 300px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.searchTeacher_filter__left__3IAU9 * input::placeholder,
.searchTeacher_filter__right__3ZY0b * input::placeholder {
  color: var(--text-color);
}

.searchTeacher_filter__right__3ZY0b {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.searchTeacher_select__pMPKF {
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
}

.searchTeacher_select__pMPKF {
  padding-top: 3px !important;
  text-align: left;
}

.searchTeacher___card__32RpJ {
  width: 400px;
  height: 350px;
}

.searchTeacher_affix__1XnUH {
  position: relative;
  width: 170px;
  height: 50px;
  background: url(/static/media/Instaprof_button.40aa807c.png) no-repeat center center;
  background-size: contain;
  cursor: pointer;
}

.searchTeacher_instaprof__mobile__1ebhs {
  display: none;
}

.searchTeacher_display__filter__mobile__2ADuD {
  display: none;
}

@media only screen and (min-width: 768px) {
  .searchTeacher_grid__3gYS1 {
    grid-gap: 2em;
    gap: 2em;
  }
}

@media only screen and (max-width: 700px) {
  .searchTeacher_filter__1k2JU {
    height: auto;
    display: flex;
    flex-direction: column;

    padding: 3%;
  }

  .searchTeacher_filed__search__3ZV4P {
    width: 100%;
    padding: 0;

    margin-bottom: 0;
  }

  .searchTeacher_filter__left__3IAU9 * input::placeholder,
  .searchTeacher_filter__right__3ZY0b * input::placeholder {
    font-size: 13px;
  }

  .searchTeacher_filter__left__3IAU9 {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
  }

  .searchTeacher_filter__right__3ZY0b {
    width: 100%;
    justify-content: space-between;
  }

  .searchTeacher_display__filter__mobile__2ADuD {
    display: block;
  }

  /* .filter__right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .searchTeacher_main__ewvBS {
    padding: 0 5%;
  }

  .searchTeacher_select__pMPKF {
    width: 43vw;
    height: 38px;
    margin-top: 10px;
  }

  .searchTeacher_affix__1XnUH {
    display: none;
  }

  .searchTeacher_instaprof__mobile__1ebhs {
    position: fixed;
    display: block;
    width: 90%;
    height: 50px;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    border-radius: 5px;
  }

  .searchTeacher_buttonInsta__V3L3z {
    background: #0ed290 !important;
    height: 30px !important;
    box-shadow: 3px 6px 1px -1px #0ed29198 !important;
    max-width: 70px !important;
  }
}
.style_stats__2TPuI {
    margin-top: 2em;
    margin-bottom: 5em;
}

.style___select__ggjEB {
    margin-top: 15px;
}

.style___select__ggjEB div {
    position: relative;
    text-align: center;
    font-size: 2.4em;
    margin-top: var(--mb-1);
    line-height: 0 !important;
    font-family: var(--title-font);
    text-transform: uppercase;
    border: 1px solid black !important;
}

.style_iconArrowClass__2TcJz {
    width: 20px;
    font-size: 12px !important;
    top: 3px !important;
    right: 30px !important;
}

.style_header__3pgYZ {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 4em;
    margin-top: 0.5em;
}

.style_card__matier__2BgT4 {
    position: relative;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin-top: var(--mb-2);
    transition: all 0.7s;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    padding-left: 0px;
    padding-right: 5px;
}

.style_card__matier__2BgT4.style_active__Vrjx3,
.style_card__matier__2BgT4:hover {
    width: 200px;
    height: 76px;
    background: var(--first-color);
    box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.style_card__matier__2BgT4.style_active__Vrjx3 .style_topic_name__2xS-V,
.style_card__matier__2BgT4 .style_topic_name__2xS-V {
    position: absolute;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform ease-out 0.7s;
    transform-origin: center right;
    transition: all 0.7s;
    -webkit-transition: transform ease-out 0.7s;
    -moz-transition: transform ease-out 0.7s;
    -ms-transition: transform ease-out 0.7s;
    -o-transition: transform ease-out 0.7s;
}

.style_card__matier__2BgT4.style_active__Vrjx3 .style_topic_name__2xS-V,
.style_card__matier__2BgT4:hover .style_topic_name__2xS-V {
    position: relative;
    visibility: visible;
    transform: translateX(0);
}

.style_topic_name__2xS-V h3 {
    color: #fff;
    font-family: var(--title-font);
    white-space: nowrap;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.style_icon__card__matiers__13Pdp {
    transform-origin: center left;
    transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.style_icon__card__matiers__13Pdp img {
    width: 50px;
}

.style_main__2Le36 {
    width: 100%;
}

.style_card__dashboard__2QOtL {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    padding: 1.5em;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.style_infos_top__1m3A9 {
    margin-bottom: var(--mb-1);
}

.style_infos_top__1m3A9 h2 {
    font-size: 1.3em;
}

.style_right__28ZpR h3 {
    text-transform: uppercase;
    font-size: 1em;
}

.style_content__propositions__2Pe4b {
    width: 100%;
    padding: 1em 4em;
}

.style_propositions__card__c5t5x {
    margin-top: var(--mb-6);
}

.style_content_card__2qhHg {
    margin-top: var(--mb-2);
}

.style_card__35Oz_ {
    width: 100%;
    min-height: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2em;
    gap: 2em;
    padding: 0 2em;
    margin-bottom: 1em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.style_card__35Oz_ h4 {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-color);
}

.style_isCorrect__1Yl3S {
    border: 1.3px solid green;
}

.style_propositionSelected__2vOBM {
    border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
    .style_right__28ZpR {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.style_stats__dashboard__2i_1T {
    width: 100%;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-top: 20px;
}

.style_backDiv__1Vb0N {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    height: 35px;
    width: auto;
}

.style_backButton__2skBp {
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.style_statsContainer__1lf6w {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_radarContainer__3QfkC {
    height: auto;
    width: 40%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.style_boxTitle__2jSwx {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    margin-left: 20px;
    margin-top: 10px;
    font-family: "CartoonFontBold";
    font-weight: 600;
}

.style_boxTitle__2jSwx:hover {
    color: #333;
}

.style_radarBox__1RFM5 {
    height: auto;
    width: 85%;
    margin-left: 10%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
}

.style_radarTitle__1nz8s {
    color: #333;
    font-size: 1.5em;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 20px;
}

.style_radarTitle__1nz8s:hover {
    color: #333;
}

.style_radarMarkBox__LPIDm {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_radarAVG__2_MiY {
    font-size: 35px;
    color: #333;
    font-weight: bold;
}

.style_radarAVG__2_MiY:hover {
    color: #333;
}

.style_radarMaxAVG__2D43X {
    margin-top: 10px;
    font-weight: bold;
}

.style_radarMaxAVG__2D43X:hover {
    color: #333;
}

.style_radarChartBox__18wIM {
    display: flex;
    justify-content: center;
    padding: 0px;
}

.style_lineContainer__13W09 {
    height: auto;
    width: 58%;
    display: flex;
    flex-direction: column;
}

.style_lineBox__3OWRq {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    grid-gap: 10px;
}

.style_lineChartCard__1M4nH {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
}

.style_lineChartCard__1M4nH:hover {
    background-color: #80b1b21a;
}

.style_lineChartCardHeader__1MMsW {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_lineChartCardAVG__3x5Be {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 20px;
    color: #333;
    font-weight: normal;
}

.style_lineTitle__mv5Dh {
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.style_backHeader__KTYZa {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.style_backLeft__3geDK .style_backTitle__1UpMZ {
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.style_center__L8_0V {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.style_arrowRightButton__v5C6v {
    border-radius: 50px;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: #fff 0px solid;
}

.style_arrowRightButton__v5C6v:hover {
    background: #80B1B2;
    color: white;
}

@media only screen and (max-width: 767px) {
    .style_backLeft__3geDK .style_backTitle__1UpMZ {
        text-transform: uppercase;
        font-size: 1.2em;
        color: var(--text-color);
    }

    .style_lineContainer__13W09 {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_lineContainer__13W09 {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .style_lineChartCard__1M4nH {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        margin-top: 10px;
    }

    .style_lineBox__3OWRq {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .style_radarContainer__3QfkC {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .style_statsContainer__1lf6w {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .style_card__dashboard__2QOtL {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_stats__dashboard__2i_1T {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_topic_name__2xS-V h3 {
        font-size: 0.7em;
    }

    .style_icon__card__matiers__13Pdp img {
        width: 30px;
    }

    .style_card__matier__2BgT4 {
        width: auto;
        height: 60px;
        margin-top: var(--mb-1);
    }

    .style_card__matier__2BgT4.style_active__Vrjx3,
    .style_card__matier__2BgT4:hover {
        width: auto;
        height: 50px;
    }

    .style_content__propositions__2Pe4b {
        padding: 0;
    }

    .style_header__3pgYZ {
        grid-gap: 0.5em;
        gap: 0.5em;
        margin-bottom: 2em;
        margin-top: 0;
    }

    .style_right__28ZpR {
        margin-top: 1em;
    }

    .style_card__35Oz_ {
        padding: 0 1em;
    }

    .style___select__ggjEB div {
        font-size: 1.5em;
        margin-bottom: var(--mb-1);
    }

    .style_iconArrowClass__2TcJz {
        width: 15px;
        top: -1px !important;
        right: 60px !important;
    }

    .style_propositions__card__c5t5x {
        margin-top: var(--mb-4);
    }

    .style_radarChartBox__18wIM {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .style_radarBox__1RFM5 {
        height: auto;
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
        background: white;
        border-radius: 15px;
    }
}
/**************************************** CSS ****************************************/

.register_step_container__2Mb14 {
  display: none;
}

.register_register__teacher__DMcD2 {
  width: 100%;
  text-align: left;
}

.register_title__step__OHsW4 {
  line-height: 22px;
  width: 220px;
  position: relative;
  left: -50px;
}

.register_step__3u-GV {
  width: 45%;
  max-width: 45%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
}

/* JOFREY STYLE */

.register_addStudentButton__3Tn8h {
  position: relative;
  bottom: 12.5px;
  border-radius: 50px;
  color: white;
  background-color: #80B1B2;
}

.register_subtitle__3A87B {
  font-size: 1.5em;
  color: #000;
}

.register_accountFormBox__19rUB {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  height: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.register_formBox__241J5 {
  width: 100%;
  max-width: 100%;
  margin: 40px auto;
  border-radius: 5px;
  background-color: #fafafa;
  height: auto;
  padding: 30px 80px 120px 80px;
  display: flex;
  flex-direction: column;
}

.register_formElementBox__jgHZ2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.register_stepTitleBox__2jZJW {
  text-align: left;
  align-items: flex-start;
  align-content: start;
}

.register_boxTitle__za3YL {
  font-size: 28px;
  font-weight: 600;
  color: #707070;
  font-family: "CartoonFont";
  text-align: left;
  text-transform: uppercase;
}

.register_boxSubtitle__3rA6Q {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.register_boxMessage__3MYz- {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 35px;
}

.register_selectInput__1K_ud {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #707070;
  font-style: italic !important;
  width: 100%;
}

.register_placeholderStyle__3qrQ- {
  color: #C0C0C0;
  font-size: 16px;
}

.register_nextButton__3vtgD {
  font-size: 18px;
  padding: 20px;
}

.register_hideElement__1ovXg {
  display: none;
}

.register_uploadButtonStyle__KuQye {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
}

.register_uploadVignetteButton__1zveG {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 130px;
}

.register_startRecordingVideo__1MHTx {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.register_cardInfoBox__184yv {
  background-image: url(/static/media/card.b30b4ea7.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 35px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxToDo__LyWIU {
  background-image: url(/static/media/cardLong.0806ab99.png);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 15px;
  padding: 0;
}

.register_cardInfoBoxWarning__2clpA {
  background-image: url(/static/media/cardAds.9ca57eea.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 0px;
  padding: 0;
}

.register_avatarExampleBox__sq_XD {
  background-image: url(/static/media/pictureExample.bc58bf9e.png);
  background-size: cover;
  height: 120px;
  width: 380px;
  border-radius: 8px;
  margin: 5px 7px 0px 35px;
}

/* Drag & Drop Input CSS */

#register_formFileUpload__1o2O- {
  height: 425px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#register_inputFileUpload__1fdV2 {
  visibility: collapse;
}

#register_labelFileUpload__35vq1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #707070;
  background-color: #fff;
}

#register_labelFileUpload__35vq1.register_dragActive__3XvP1 {
  background-color: #ffffff;
}

.register_uploadButton__2fVVh {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.register_uploadButton__2fVVh:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

#register_dragFileElement__3KzYx {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* Drag & Drop Input CSS */

.register_uploadImageBox__hylq1 {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_uploadImageBoxTitle__2i2Mo {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_uploadImageBoxMessage__3D5O2 {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_calendarBoxTitle__e95_r {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
}

.register_calendarBoxSubtitle__2K6FS {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin: 30px auto;
  padding-left: 210px;
}

.register_calendareBoxMessage__3VHYz {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubMessage__3vrnH {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxTitle__3iRT6 {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 45px;
  margin-left: 35px;
}

.register_uploadVideoBoxMessage__3R3pl {
  font-size: 14.5px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.register_uploadVideoBoxSubtitle__3GRl- {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_addPictureButton__3cExz {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.register_addDocPictureButton__1OiIB {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.register_addVideoButton__hrdF- {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 20px;
}

.register_addVignetteButton__2qC1S {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 30px;
}

.register_previewBox__eq1t- {
  margin-left: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #707070;
  border-style: dashed;
}

.register_previewBoxImage__UDD-C {
  max-width: 200px;
  height: auto;
  border-radius: 15px;
}

.register_uploadBox__2BoR6 {
  display: flex;
  flex-direction: column;
}

.register_uploadBoxTitle__1Hhj0 {
  font-size: 20px;
  font-style: italic;
  color: #333;
}

.register_uploadBoxMessage__OvYb9 {
  font-size: 20px;
  font-style: italic;
  color: #CBCACD;
}

.register_uploadBoxMessageError__12CQE {
  font-size: 20px;
  font-style: italic;
  color: red;
}

.register_uploadBoxBorder__D9e6z {
  margin-left: 35px;
  margin-top: 25px;
  border: none;
  border-radius: 15px;
}

.register_uploadBoxBorderError__15Eo3 {
  margin-left: 35px;
  margin-top: 25px;
  border: 1px solid red;
  border-radius: 15px;
}

.register_exampleBox__1_UJN {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_exampleBoxLong__3LBGa {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 30px;
}

.register_exampleBoxTitle__3dwAf {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.register_videoExampleBoxTitle__1V7xI {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 0px;
}

.register_instructionBox__1ZBLO {
  padding-left: 65px;
  padding-right: 60px;
  margin-top: 42px;
}

.register_instructionBoxLong__1Te3W {
  padding: 60px 40px 10px 65px;
  margin-top: 42px;
}

.register_checkboxBox__1f97o {
  display: flex;
  flex-direction: row;
}

.register_checkboxBoxItem__3IkjR {
  margin-left: 10px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxItemLong__17TQL {
  margin-left: 5px;
  color: #707070;
  font-size: 12.5px;
}

.register_checkboxBoxLong__18s8T {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.register_customTextarea__h_mtj {
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

/* Placeholder color */
.register_customTextarea__h_mtj::placeholder {
  color: #C0C0C0;
}

/* Focus color */
.register_customTextarea__h_mtj:focus {
  outline: none;
  border-color: #707070;
  box-shadow: 0 0 2px green;
}

#register_videoFileUpload__2CIpl {
  background-image: url(/static/media/videoExample.d39fc304.png);
  background-size: cover;
  height: 300px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-left: 35px;
  margin-top: 25px;
}

.register_vignetteBox__m6lYB {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0px 0px 35px;
  padding: 15px 20px 15px 20px;
  width: 540px;
  align-items: center;
  justify-content: left;
}

/* .step .stepsBox {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
} */

.register_addCertificateDiplomaButton__1B1cu {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 50px;
}

.register_customButtonStyle__1bDw- {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.register_customButtonStyle__1bDw-:hover {
  transform: scale(1.03) !important;
}

.register_customButtonStyle__1bDw-:active {
  transform: scale(0.99) !important;
}

.register_stopButtonStyle__iM3YG {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: red;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgb(182, 85, 85) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
  -moz-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
}

.register_stopButtonStyle__iM3YG:hover {
  transform: scale(1.03) !important;
}

.register_stopButtonStyle__iM3YG:active {
  transform: scale(0.99) !important;
}

.register_certificateDiplomaFormBox__2uJdP {
  margin: 0px auto;
  height: auto;
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.register_pictureBoxRow__2Q1ab {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.register_picturePreviewBox__23VyE {
  height: auto;
  width: auto;
  border: 1px solid #333;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
}

.register_titleBox__p63A2 {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 74%;
}

.register_videoPreviewBox__lYdjL {
  height: auto;
  width: 560px;
  border-radius: 5px;
  margin-left: 35px;
  margin-top: 25px;
}

.register_videoLinkInput__3f81I {
  margin-left: 35px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.register_videoFormatTitle__2qqaz {
  font-size: 13px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 10px;
}

.register_calendarTimezone__3hmA2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.register_fillLaterButton__3F_b2 {
  height: 50px;
  width: 65%;
  max-width: 40%;
  margin-left: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.register_removeButton__3GSX6 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
  padding: 5px;
  border-radius: 50px;
  border: none;
  background-color: red;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.register_removeButton__3GSX6:hover {
  padding: 7px;
  background-color: #fff;
  border: 1px solid red;
  color: red;
  cursor: pointer;
}

.register_previewCertificateDiploma__1JCCB {
  height: 110px;
  width: 110px;
  position: relative;
  bottom: 40px;
  margin-top: 10px;
}

/* JOFREY STYLE */

.register_teacher__3woep {
  display: flex;
  align-items: center;
  margin: 0px auto;
}

.register_teacher__3woep .register_avatar__1IAfS {
  padding-right: var(--mb-4);
}

.register_header__text__lEHPB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.register_header__text__lEHPB p {
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.register_header__text__lEHPB p strong,
.register_text__32_eL strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.register_text__32_eL a {
  color: var(--text-color);
  text-decoration: underline;
}

.register_content__action__1IR0_ {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0px auto;
}

.register_button__p16MV svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.register_form__container__3Gi1D {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.register_field__2CLsi {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
}

.register_field__2CLsi::placeholder {
  font-style: italic !important;
}

.register_termes__legals__11GFw {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.register_termesToAccept__1RFF5 {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.register_button__p16MV {
  width: 100%;
  height: 40px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.register_text__legal__34t63 {
  text-align: left;
  font-size: var(--small-font-size);
}

.register_inputRow__351Mc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.register_inputRowChild__22AQO {
  width: 49%;
}

.register_avatarContainer__2sJ5u {
  display: flex;
  flex-direction: row;
}

.register_rowButtons__3VRWi {
  display: flex;
  width: 100%;
  margin: 40px auto;
  align-items: center;
  justify-content: center;
}

.register_rowButtonChild__2wwDV {
  max-width: auto;
  margin: 0 10px;
}

.register_formContainer__3lO2T {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_docBox__3p-0w {
  height: auto;
  width: 100%;
  border: 1px solid #707070;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.register_docBoxImage__ghIvq {
  margin: 20px 0px 0px 15px;
  display: flex;
  flex-direction: row;
}

.register_docBoxTitle__3y3Jn {
  font-size: 15px;
  color: #111;
  font-weight: 700;
  margin-left: 7px;
}

.register_docBoxMessage__xE1kR {
  margin: 0px 0px 0px 40px;
  font-size: 14px;
  color: #707070;
}

.register_docBoxMessageFormat__1K5El {
  font-size: 12px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 20px;
}

.register_uploadInputForm__2cPbk {
  width: 100%;
  visibility: visible;
  margin-left: 35px;
}

.register_uploadFileInput__4j8Wz {
  height: 0px;
  visibility: collapse;
  margin-left: 35px;
}

.register_uploadDocBox__1xZNo {
  height: 30px;
}

.register_teacherDescriptionTitle__XcyE6 {
  font-size: 20px;
  color: #333;
  font-weight: 500;
}

.register_teacherDescriptionBox__ZaWsw {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin: 35px 0px 20px 50px;
}

.register_stepFiveContent__3VIZO {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.register_textareaMaxText__3IXSf {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  font-style: italic;
  color: #636363;
}

.register_textareaMessageFull__1i99s {
  display: inline;
  font-size: 18px;
  font-style: italic;
  color: #CBCACD;
}

.register_textareaMessageSmall__1edbk {
  display: none;
}

.register_customTextarea__h_mtj {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.register_customDescriptionTextarea__2U63s {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.register_customLinkedTextarea__3yGxz {
  display: none;
}

.register_linkedInput__1oXWi {
  display: block;
}

.register_linkedError__2xfXx {
  font-size: 12px;
  color: red;
}

.register_thumbnailMessage__1kgzD {
  font-size: 14px;
  color: #707070;
  margin-left: 25px;
}

.register_explanationBox__1aDU7 {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: start;
  justify-content: end;
  align-items: start;
}

.register_explanationBoxTitle__3oTpl {
  font-size: 28px;
  color: #5A8484;
  text-transform: uppercase;
  font-weight: 600;
}

.register_questionMark__3-0pa {
  margin-top: 7px;
  margin-right: 5px;
}

.register_calendarBox__2Cjwy {
  background-color: #fff;
  padding: 20px;
}

.register_stopButton__25FGI {
  position: fixed;
  bottom: 20%;
  right: 20%;
  width: 70px;
  height: 70px;
  background-color: red;
  cursor: pointer;
}

.register_videoLoading__2ibH6 {
  position: fixed;
  left: 46%;
  top: 43%;
  font-family: "CartoonFont";
  font-size: 34px;
  color: #fff;
}

.register_videoRecorderBox__3ZJhF {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 12.9%;
  right: 12.9%;
  background: black;
}

.register_videoRecorderClearBox__t4nsb {
  display: flex;
  flex-direction: column;
  margin-top: 4.5%;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 0px;
  right: 0px;
  bottom: 2%;
}

.register_stopButtonBox__2H2L4 {
  position: fixed;
  bottom: 25%;
  right: 20%;
}

/************************************** MediaQuery **************************************/

@media only screen and (max-width: 767px) {

  .register_step__3u-GV {
    display: none;
  }

  .register_step_container__2Mb14 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    grid-gap: 20px;
    gap: 20px;
    margin: 20px auto;
    font-size: 1.2em;
  }

  .register_formsteps__1btJI {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 10px;
    width: 70px;
  }

  .register_calendarBox__2Cjwy {
    background-color: #fff;
    padding: 10px;
  }

  .register_questionMark__3-0pa {
    margin-top: -1px;
    margin-right: 5px;
    margin-left: 15px;
  }

  .register_explanationBoxTitle__3oTpl {
    font-size: 18px;
    color: #5A8484;
    text-transform: uppercase;
    font-weight: bold;
  }

  .register_explanationBox__1aDU7 {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: start;
    align-items: center;
  }

  .register_checkboxBoxItemLong__17TQL {
    margin-left: 5px;
    color: #707070;
    font-size: 10px;
  }

  .register_instructionBoxLong__1Te3W {
    padding: 50px 20px 0px 30px;
    margin-top: 0px;
  }

  .register_cardInfoBoxToDo__LyWIU {
    background-image: url(/static/media/cardLong.0806ab99.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    margin-left: 5px;
    margin-top: 15px;
    padding: 0;
  }

  .register_uploadVideoBoxSubMessage__3vrnH {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .register_videoExampleBoxTitle__1V7xI {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 0px;
  }

  .register_thumbnailMessage__1kgzD {
    font-size: 12px;
    color: #707070;
    margin-left: 25px;
  }

  .register_startRecordingVideo__1MHTx {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .register_linkedInput__1oXWi {
    display: none;
  }

  .register_textareaMessageFull__1i99s {
    display: none;
  }

  .register_textareaMessageSmall__1edbk {
    display: inline;
    font-size: 11px;
    font-style: italic;
    color: #CBCACD;
    margin-left: 5px;
  }

  .register_textareaMaxText__3IXSf {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
    font-style: italic;
    color: #636363;
  }

  .register_customTextarea__h_mtj {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .register_customLinkedTextarea__3yGxz {
    display: block;
    width: 100%;
    padding: 5px 10px 5px 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .register_customDescriptionTextarea__2U63s {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 10px;
  }

  .register_stepFiveContent__3VIZO {
    width: 78%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .register_teacherDescriptionBox__ZaWsw {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 20px auto;
  }

  .register_teacherDescriptionTitle__XcyE6 {
    font-size: 12px;
    color: #333;
    font-weight: 500;
  }

  /* .rowButtonChild {
    max-width: auto;
    margin-right: 5px;
  } */

  .register_uploadDocBox__1xZNo {
    height: 30px;
    position: relative;
    top: -30px;
    left: 35px;
  }

  .register_uploadInputForm__2cPbk {
    width: 100%;
    visibility: visible;
    margin-left: 5px;
    font-size: 10px;
  }

  .register_uploadFileInput__4j8Wz {
    height: 0px;
    visibility: collapse;
    margin-left: 5px;
  }

  .register_uploadFileButton__1K6tt {
    margin-left: 17px;
    width: 20%;
  }

  .register_uploadButtonStyle__KuQye {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
  }

  .register_docBoxMessageFormat__1K5El {
    font-size: 11px;
    color: #707070;
    margin-left: 17px;
    margin-top: 20px;
  }

  .register_docBoxMessage__xE1kR {
    margin: 0px 0px 0px 40px;
    font-size: 11px;
    margin-right: 5px;
    color: #707070;
  }

  .register_docBoxTitle__3y3Jn {
    font-size: 12px;
    color: #111;
    font-weight: 700;
    margin-left: 7px;
  }

  .register_docBoxImage__ghIvq {
    margin: 20px 0px 0px 15px;
    display: flex;
    flex-direction: row;
  }

  .register_docBox__3p-0w {
    height: auto;
    width: 100%;
    border: 1px solid #707070;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }

  .register_form__container__3Gi1D {
    width: 95%;
    max-width: 600px;
    margin: 40px auto;
  }

  .register_formContainer__3lO2T {
    width: 78%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding: 0px auto;
  }

  .register_termesToAccept__1RFF5 {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    max-width: 78%;
  }

  .register_register__teacher__DMcD2 {
    padding: 0 3%;
  }

  .register_teacher__3woep .register_avatar__1IAfS {
    padding-right: 0;
  }

  .register_teacher__3woep .register_avatar__1IAfS img {
    position: relative;
    width: 30px;
    top: -3px;
    margin: 0;
  }

  .register_header__text__lEHPB p {
    font-size: var(--normal-font-size);
    margin-top: 3px;
  }

  .register_title__step__OHsW4 {
    font-size: 8px;
    white-space: nowrap;
    display: block;
  }

  .register_placeholderStyle__3qrQ- {
    color: #C0C0C0;
    font-size: 12px;
  }

  .register_inputRow__351Mc {
    display: flex;
    flex-direction: column;
  }

  .register_inputRowChild__22AQO {
    width: 100%;
  }

  /* .step {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    overflow: auto;
    scrollbar-width: thin;
  } */

  .register_field__2CLsi {
    height: 40px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
    font-size: 12px;
  }

  .register_field__2CLsi::placeholder {
    font-size: 12px !important;
  }

  .register_policy__2srBC {
    position: relative;
    font-size: 11px !important;
  }

  .register_policy__2srBC br {
    display: none;
  }

  .register_uploadImageBoxTitle__2i2Mo {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
    margin-left: 35px;
  }

  .register_avatarContainer__2sJ5u {
    display: block;
  }

  .register_formBox__241J5 {
    width: 100%;
    max-width: 100%;
    margin: 40px auto;
    border-radius: 5px;
    background-color: #fafafa;
    height: auto;
    padding: 30px 5px 120px 5px;
    display: flex;
    flex-direction: column;
  }

  .register_stepTitleBox__2jZJW {
    text-align: center;
    align-items: center;
    align-content: center;
  }

  .register_boxTitle__za3YL {
    font-size: 18px;
    font-weight: 600;
    color: #707070;
    font-family: "CartoonFont";
    text-align: center;
    text-transform: uppercase;
  }

  .register_boxMessage__3MYz- {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .register_uploadBoxBorder__D9e6z {
    margin: 20px auto;
    border: none;
    border-radius: 15px;
  }

  #register_formFileUpload__1o2O- {
    height: 225px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  .register_instructionBox__1ZBLO {
    padding-left: 35px;
    padding-right: 15px;
    margin-top: 28px;
  }

  .register_checkboxBoxItem__3IkjR {
    margin-left: 10px;
    color: #707070;
    font-size: 10px;
  }

  .register_cardInfoBox__184yv {
    background-image: url(/static/media/card.b30b4ea7.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 347px;
    width: auto;
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 15px;
  }

  .register_exampleBoxTitle__3dwAf {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 15px;
  }

  .register_avatarExampleBox__sq_XD {
    background-image: url(/static/media/pictureExample.bc58bf9e.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 120px;
    width: 90%;
    border-radius: 8px;
    margin: 5px auto;
  }

  .register_previewBoxImage__UDD-C {
    width: 170px;
    height: auto;
    border-radius: 15px;
  }

  .register_previewBox__eq1t- {
    display: flex;
    align-items: center;
    justify-items: center;
    border-width: 1px;
    border-radius: 1rem;
    border-color: #707070;
    border-style: dashed;
    width: auto;
    margin: 10px auto;
  }

  .register_customButtonStyle__1bDw- {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-left: 40px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  }

  .register_certificateDiplomaFormBox__2uJdP {
    margin: 0px auto;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .register_pictureBoxRow__2Q1ab {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    align-items: center;
  }

  .register_picturePreviewBox__23VyE {
    height: auto;
    width: auto;
    border: 1px solid #333;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    display: flex;
  }

  .register_titleBox__p63A2 {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    margin-top: 20px;
  }

  .register_videoPreviewBox__lYdjL {
    height: auto;
    width: auto;
    border-radius: 5px;
    margin-left: 0px;
    margin-top: 25px;
  }

  #register_videoFileUpload__2CIpl {
    background-image: url(/static/media/videoExample.d39fc304.png);
    background-size: cover;
    height: 150px;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 25px auto;
    background-repeat: no-repeat;
  }

  .register_exampleBoxLong__3LBGa {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    position: relative;
    top: -70px;
  }

  .register_uploadImageBox__hylq1 {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .register_uploadImageBoxTitle__2i2Mo {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 15px;
    margin-left: 0px;
  }

  .register_uploadImageBoxMessage__3D5O2 {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 0px;
    color: #707070;
  }

  .register_vignetteBox__m6lYB {
    display: flex;
    flex-direction: row;
    border: 1px solid #333;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 20px auto;
    padding: 15px 20px 15px 20px;
    width: auto;
    align-items: center;
    justify-content: left;
  }

  .register_videoLinkInput__3f81I {
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .register_uploadVideoBoxMessage__3R3pl {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .register_uploadVideoBoxSubtitle__3GRl- {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 0px;
  }

  .register_uploadVideoBoxTitle__3iRT6 {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-left: 0px;
  }

  .register_addVideoButton__hrdF- {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 10px;
  }

  .register_addVignetteButton__2qC1S {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 30px;
  }

  .register_videoFormatTitle__2qqaz {
    font-size: 13px;
    color: #CBCACD;
    margin-left: 5px;
    margin-top: 10px;
  }

  .register_calendarBoxTitle__e95_r {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 5px;
  }

  .register_calendareBoxMessage__3VHYz {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .register_calendarBoxSubtitle__2K6FS {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 30px auto;
    padding-left: 5px;
  }

  .register_calendarTimezone__3hmA2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
  }

  .register_fillLaterButton__3F_b2 {
    height: 50px;
    width: 150px;
    margin-left: 0px;
  }

  .register_addPictureButton__3cExz {
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .register_addDocPictureButton__1OiIB {
    height: auto;
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .register_addPictureButtonBox__VqP6b {
    width: auto;
  }

  .register_previewCertificateDiploma__1JCCB {
    height: auto;
    width: 100%;
    position: relative;
    bottom: 0px;
    margin-left: 17px;
  }

  .register_nextButton__3vtgD {
    font-size: 12px;
    padding: 0px;
  }

  .register_title__T_bcI {
    font-size: 14px;
  }

  .register_rowButtons__3VRWi {
    display: flex;
    width: 100%;
    margin: 40px auto;
    align-items: center;
    justify-content: center;
  }

  .register_rowButtonChild__2wwDV {
    max-width: auto;
    margin: 0 10px;
  }

  .register_stopButtonBox__2H2L4 {
    position: relative;
    bottom: 12%;
    left: -6%;
    margin: 0px auto;
  }

  .register_videoLoading__2ibH6 {
    position: relative;
    left: 0%;
    margin: 0px auto;
    font-family: "CartoonFont";
    font-size: 34px;
    color: #fff;
  }

  .register_videoRecorderBox__3ZJhF {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    background: black;
  }

  .register_videoRecorderClearBox__t4nsb {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    bottom: 2%;
  }

}

.register_invitationForm__3RReb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.register_invitationFormMobile__36T2u {
  text-align: center;
}
.cardTeacher_card__1j-ly {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* max-width: 350px; */
  height: 355px;
  padding: 15px;
  background-size: contain;
  background: #fafafa;
  box-shadow: 3px 2px 0px 2px #cfd0d2;
  transition: 0.5s;
}

.cardTeacher_card__1j-ly:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardTeacher_card__1j-ly::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.cardTeacher___matiers___THnn {
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.3em;
  gap: 0.3em;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-1);
}

.cardTeacher___tag__topic__32NnU {
  background: #d5d5d5;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
}

.cardTeacher_card__header__1jQDl {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 1em;
  gap: 1em;
}

.cardTeacher_content__1Grvh {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1% 2%;
}

.cardTeacher_round__user__2oM9Y {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cardTeacher_round__user__2oM9Y img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 100px;
}

.cardTeacher_content__1Grvh h3 {
  width: 150px;
  text-transform: uppercase;
  color: #333;
  font-size: 1.2em;
  margin-top: var(--mb-1);
}

.cardTeacher_tarif__1wbdM {
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
  margin-top: var(--mb-1);
}

.cardTeacher_tarif__1wbdM span {
  font-weight: 300;
}

.cardTeacher_btn__profil__prof__1fBth:hover {
  background: #5a8484 !important;
}

.cardTeacher___description__10Qmc {
  width: 100%;
  height: 35px;
  font-size: 12px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 10px;
  margin-top: 5px;
}

.cardTeacher___start__OJOX6 {
  position: absolute;
  margin: auto;
  top: -4%;
  right: 8%;
}

.cardTeacher___row__3t3Wk {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
}

.cardTeacher___row__3t3Wk button {
  position: relative;
  border: unset;
  padding: 4px 11px;
  border-radius: 8px;
  background: var(--first-color);
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.cardTeacher_calendrier__28HYt {
  width: 1200px;
  height: 850px;
  margin-top: 2em;
  background: url(/static/media/calendarScreen.284fd573.svg) no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 1s ease;
}

.cardTeacher_calendar__content__3dt0G {
  width: 95%;
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: auto;
}

.cardTeacher___drawer__teacher__3G3xJ {
  width: 100%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.cardTeacher___drawer__teacher__header__W98o0 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cardTeacher___infos__2FTq1 h3 {
  font-size: 1.6em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-1);
}

.cardTeacher___drawer__teacher__main__1Na-l {
  margin-top: 2em;
}

.cardTeacher___main__infos__2mYB3 {
  font-size: 1.6em;
  margin-bottom: 1em;
  display: flex;
}

.cardTeacher___main__infos__2mYB3 span {
  text-transform: uppercase;
}

.cardTeacher___drawer_matiere__11sMp {
  position: relative;
  top: -0.6em;
  margin-left: 1.5em;
}

.cardTeacher___main__infos__2mYB3 p {
  position: relative;
  top: 5px;
  font-size: 0.7em;
  margin-left: 1em;
}

.cardTeacher___plus__2mp4- {
  width: 100%;
  height: 400px;
  background: #fafafa;
}

.cardTeacher___reservation__8ISFB {
  /* background: var(--first-color); */
  width: 350px;
  height: 350px !important;
}

.cardTeacher_calendarPopupContent__24NEW * .cardTeacher_ant-popover-inner-content__2sR-a {
  padding: 0 !important;
}

.cardTeacher___reservation_content__1xa_x {
  position: relative;
  width: 100%;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* padding-top: 2%; */
}

.cardTeacher___reservation__row__1imNx {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cardTeacher___reservation_avatar__7vmiA {
  position: relative;
  width: 80px;
  height: 80px;
  top: -10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 100px;
}

.cardTeacher___reservation_avatar__7vmiA img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.cardTeacher___reservation_content__1xa_x h2 {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 1.5em;
}

.cardTeacher_reservation__infos__1kTRN {
  width: 100%;
  padding-left: 3%;
}

.cardTeacher_reservation__infos__1kTRN h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.cardTeacher_reservation__infos__1kTRN span {
  font-size: 11px;
}

.cardTeacher_tag__BxUEo {
  width: 100px;
  height: 32px;
  border-radius: 20px;
  background: #8ab0b1ca;
  display: flex;
  margin-bottom: 0.5em;
}

.cardTeacher_tag__BxUEo span {
  margin: auto;
  text-transform: uppercase;
  font-size: 11px;
  color: #fff;
}

.cardTeacher___drawer_button__3u3Dv {
  border-radius: 15px !important;
  height: 40px !important;
  color: var(--first-color);
  text-transform: uppercase;
  min-width: 200px;
}

.cardTeacher___custom__container__30-jm {
  width: 100%;
  height: 100%;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.cardTeacher___liste__card__1dObh {
  margin-top: var(--mb-4);
}

.cardTeacher___calendar__title__2BWi5,
.cardTeacher___calendar__title__creneau__1gOEl {
  font-size: 12px !important;
  line-height: 14px;
}

.cardTeacher___calendar__title__2BWi5 {
  font-size: 11px !important;
}

/* .__open__className {
  min-width: 400px !important;
  max-width: 400px !important;
} */

.cardTeacher___solde_content__10Gn7 h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.cardTeacher___prof__description__card__reservation__5DQSr {
  font-size: 12px;
  text-align: center;
}

.cardTeacher___matiersAds__1eRdv * span {
  padding-left: 2px;
}

.cardTeacher___matiersAds__1eRdv {
  margin-bottom: 1.5em;
}

/* ====== */
.cardTeacher_month__JP6JP {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: -0.7em;
}

.cardTeacher_month__JP6JP span {
  text-transform: uppercase;
  font-family: "CartoonFont";
}

.cardTeacher_date__1hCqz {
  font-size: 2em;
}

.cardTeacher_month__JP6JP span:nth-child(2) {
  position: relative;
  font-size: 1.2em;
}

.cardTeacher_month__JP6JP span:nth-child(3) {
  position: relative;
  font-size: 1.2em;
}

.cardTeacher_month__JP6JP span:nth-child(2)::after,
.cardTeacher_month__JP6JP span:nth-child(3)::after {
  content: "";
  position: absolute;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  left: -18px;
  top: 10px;
  background: var(--first-color);
}

.cardTeacher_month__JP6JP span:nth-child(3)::after {
  background: #faad13;
}

/* ======== */

.cardTeacher_card__teacher__reservation__2zm45 {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid #fafafabe;
  border-radius: 5px;
  padding: 3% 5%;
}

.cardTeacher_teacher__reservation__avatar__2Z9ja {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}

.cardTeacher_teacher__reservation__avatar__2Z9ja img {
  width: 100%;
  height: 100%;
}

.cardTeacher_open__messagerie__3JbLI span {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s all;
}

.cardTeacher_open__messagerie__3JbLI span:hover {
  color: var(--first-color);
}

.cardTeacher_levelGroup__1wkNo div:last-child {
  display: none;
}
.searchTeacher_header__3MDBh {
  width: 100%;
  margin-bottom: 0.8em;
}

.searchTeacher_filter__1kfnn {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-2);
  padding: 0 var(--mb-4);
}

.searchTeacher_filed__search__1u1m1 {
  width: 300px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.searchTeacher_filter__left__3StOd * input::placeholder,
.searchTeacher_filter__right__3hyMp * input::placeholder {
  color: var(--text-color);
}

.searchTeacher_filter__right__3hyMp {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.searchTeacher_select__3rZPI {
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
}

.searchTeacher_select__3rZPI {
  padding-top: 3px !important;
  text-align: left;
}

.searchTeacher___card__Z3SH- {
  width: 400px;
  height: 350px;
}

.searchTeacher_affix__3ShUl {
  position: relative;
  width: 170px;
  height: 50px;
  background: url(/static/media/Instaprof_button.40aa807c.png) no-repeat
    center center;
  background-size: contain;
  cursor: pointer;
}

.searchTeacher_instaprof__mobile__37wcg {
  display: none;
}

.searchTeacher_display__filter__mobile__1i16y {
  display: none;
}

@media only screen and (min-width: 768px) {
  .searchTeacher_grid__1M2hV {
    grid-gap: 2em;
    gap: 2em;
  }
}

@media only screen and (max-width: 700px) {
  .searchTeacher_filter__1kfnn {
    height: auto;
    display: flex;
    flex-direction: column;

    padding: 3%;
  }

  .searchTeacher_filed__search__1u1m1 {
    width: 100%;
    padding: 0;

    margin-bottom: 0;
  }

  .searchTeacher_filter__left__3StOd * input::placeholder,
  .searchTeacher_filter__right__3hyMp * input::placeholder {
    font-size: 13px;
  }

  .searchTeacher_filter__left__3StOd {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
  }

  .searchTeacher_filter__right__3hyMp {
    width: 100%;
    justify-content: space-between;
  }

  .searchTeacher_display__filter__mobile__1i16y {
    display: block;
  }

  /* .filter__right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .searchTeacher_main__3j6t0 {
    padding: 0 5%;
  }

  .searchTeacher_select__3rZPI {
    width: 43vw;
    height: 38px;
    margin-top: 10px;
  }

  .searchTeacher_affix__3ShUl {
    display: none;
  }

  .searchTeacher_instaprof__mobile__37wcg {
    position: fixed;
    display: block;
    width: 90%;
    height: 50px;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    border-radius: 5px;
  }

  .searchTeacher_buttonInsta__2gD2r {
    background: #0ed290 !important;
    height: 30px !important;
    box-shadow: 3px 6px 1px -1px #0ed29198 !important;
    max-width: 70px !important;
  }
}

.style_stats__2Jxdt {
    margin-top: 2em;
    margin-bottom: 5em;
}

.style___select__3LJcR {
    margin-top: 15px;
}

.style___select__3LJcR div {
    position: relative;
    text-align: center;
    font-size: 2.4em;
    margin-top: var(--mb-1);
    line-height: 0 !important;
    font-family: var(--title-font);
    text-transform: uppercase;
    border: 1px solid black !important;
}

.style_iconArrowClass__17goc {
    width: 20px;
    font-size: 12px !important;
    top: 3px !important;
    right: 30px !important;
}

.style_header__1pjTN {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1.5em;
    gap: 1.5em;
    margin-bottom: 4em;
    margin-top: 0.5em;
}

.style_card__matier__27csS {
    position: relative;
    width: 90px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin-top: var(--mb-2);
    transition: all 0.7s;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
    padding-left: 0px;
    padding-right: 5px;
}

.style_card__matier__27csS.style_active__3fcfc,
.style_card__matier__27csS:hover {
    width: 200px;
    height: 76px;
    background: var(--first-color);
    box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.style_card__matier__27csS.style_active__3fcfc .style_topic_name__1V10w,
.style_card__matier__27csS .style_topic_name__1V10w {
    position: absolute;
    transform: translateX(100%);
    visibility: hidden;
    transition: transform ease-out 0.7s;
    transform-origin: center right;
    transition: all 0.7s;
    -webkit-transition: transform ease-out 0.7s;
    -moz-transition: transform ease-out 0.7s;
    -ms-transition: transform ease-out 0.7s;
    -o-transition: transform ease-out 0.7s;
}

.style_card__matier__27csS.style_active__3fcfc .style_topic_name__1V10w,
.style_card__matier__27csS:hover .style_topic_name__1V10w {
    position: relative;
    visibility: visible;
    transform: translateX(0);
}

.style_topic_name__1V10w h3 {
    color: #fff;
    font-family: var(--title-font);
    white-space: nowrap;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.style_icon__card__matiers__AeeXj {
    transform-origin: center left;
    transition: all 0.7s;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -ms-transition: all 0.7s;
    -o-transition: all 0.7s;
}

.style_icon__card__matiers__AeeXj img {
    width: 50px;
}

.style_main__3n0Fe {
    width: 100%;
}

.style_card__dashboard__UlIjG {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 2em;
    gap: 2em;
    padding: 1.5em;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.style_infos_top__1MXMb {
    margin-bottom: var(--mb-1);
}

.style_infos_top__1MXMb h2 {
    font-size: 1.3em;
}

.style_right__3mCh9 h3 {
    text-transform: uppercase;
    font-size: 1em;
}

.style_content__propositions__3xmTB {
    width: 100%;
    padding: 1em 4em;
}

.style_propositions__card__1Ccxs {
    margin-top: var(--mb-6);
}

.style_content_card__3Zorq {
    margin-top: var(--mb-2);
}

.style_card__2YfoZ {
    width: 100%;
    min-height: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2em;
    gap: 2em;
    padding: 0 2em;
    margin-bottom: 1em;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.style_card__2YfoZ h4 {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--text-color);
}

.style_isCorrect__1y6Ts {
    border: 1.3px solid green;
}

.style_propositionSelected__2yHVX {
    border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
    .style_right__3mCh9 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.style_stats__dashboard__Xm-wA {
    width: 100%;
    background: #fafafa;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    margin-top: 20px;
}

.style_backDiv__3Gth0 {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 20px;
    height: 35px;
    width: auto;
}

.style_backButton__1EueV {
    border-radius: 10px;
    border: 1px solid #C0C0C0;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.style_statsContainer__1Psns {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.style_radarContainer__15NmI {
    height: auto;
    width: 40%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.style_boxTitle__2O7TF {
    color: var(--title-color);
    font-size: var(--h1-font-size);
    margin-left: 20px;
    margin-top: 10px;
    font-family: "CartoonFontBold";
    font-weight: 600;
}

.style_boxTitle__2O7TF:hover {
    color: #333;
}

.style_radarBox__3TMrR {
    height: auto;
    width: 85%;
    margin-left: 10%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
}

.style_radarTitle__37SJY {
    color: #333;
    font-size: 1.5em;
    font-weight: 500;
    margin-left: 20px;
    margin-top: 20px;
}

.style_radarTitle__37SJY:hover {
    color: #333;
}

.style_radarMarkBox__3N2kX {
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_radarAVG__38exh {
    font-size: 35px;
    color: #333;
    font-weight: bold;
}

.style_radarAVG__38exh:hover {
    color: #333;
}

.style_radarMaxAVG__2DlWi {
    margin-top: 10px;
    font-weight: bold;
}

.style_radarMaxAVG__2DlWi:hover {
    color: #333;
}

.style_radarChartBox__1-Pgp {
    display: flex;
    justify-content: center;
    padding: 0px;
}

.style_lineContainer__2MzHF {
    height: auto;
    width: 58%;
    display: flex;
    flex-direction: column;
}

.style_lineBox__3JItU {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    grid-gap: 10px;
}

.style_lineChartCard__152fb {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
}

.style_lineChartCard__152fb:hover {
    background-color: #80b1b21a;
}

.style_lineChartCardHeader__39_AS {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.style_lineChartCardAVG__3PP1_ {
    margin-top: 10px;
    margin-right: 30px;
    font-size: 20px;
    color: #333;
    font-weight: normal;
}

.style_lineTitle__3E9Z0 {
    color: #333;
    font-size: 16px;
    margin-left: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.style_backHeader__3GFNv {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.style_backLeft__3kXtg .style_backTitle__3Pznd {
    text-transform: uppercase;
    font-size: var(--h1-font-size);
    color: var(--text-color);
}

.style_center__2YrO7 {
    width: 100%;
    flex: 2 1;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 0 var(--mb-4);
}

.style_arrowRightButton__2JkJ_ {
    border-radius: 50px;
    background: white;
    height: 45px;
    width: 45px;
    border-radius: 50px;
    border: #fff 0px solid;
}

.style_arrowRightButton__2JkJ_:hover {
    background: #80B1B2;
    color: white;
}

@media only screen and (max-width: 767px) {
    .style_backLeft__3kXtg .style_backTitle__3Pznd {
        text-transform: uppercase;
        font-size: 1.2em;
        color: var(--text-color);
    }

    .style_lineContainer__2MzHF {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .style_lineContainer__2MzHF {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
    }

    .style_lineChartCard__152fb {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        margin-top: 10px;
    }

    .style_lineBox__3JItU {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .style_radarContainer__15NmI {
        height: auto;
        width: 100%;
        background: white;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .style_statsContainer__1Psns {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .style_card__dashboard__UlIjG {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_stats__dashboard__Xm-wA {
        grid-gap: 0.5em;
        gap: 0.5em;
        padding: 0.5em;
    }

    .style_topic_name__1V10w h3 {
        font-size: 0.7em;
    }

    .style_icon__card__matiers__AeeXj img {
        width: 30px;
    }

    .style_card__matier__27csS {
        width: auto;
        height: 60px;
        margin-top: var(--mb-1);
    }

    .style_card__matier__27csS.style_active__3fcfc,
    .style_card__matier__27csS:hover {
        width: auto;
        height: 50px;
    }

    .style_content__propositions__3xmTB {
        padding: 0;
    }

    .style_header__1pjTN {
        grid-gap: 0.5em;
        gap: 0.5em;
        margin-bottom: 2em;
        margin-top: 0;
    }

    .style_right__3mCh9 {
        margin-top: 1em;
    }

    .style_card__2YfoZ {
        padding: 0 1em;
    }

    .style___select__3LJcR div {
        font-size: 1.5em;
        margin-bottom: var(--mb-1);
    }

    .style_iconArrowClass__17goc {
        width: 15px;
        top: -1px !important;
        right: 60px !important;
    }

    .style_propositions__card__1Ccxs {
        margin-top: var(--mb-4);
    }

    .style_radarChartBox__1-Pgp {
        display: flex;
        justify-content: center;
        padding: 0px;
    }

    .style_radarBox__3TMrR {
        height: auto;
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
        background: white;
        border-radius: 15px;
    }
}

.style_field__beXyk {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
}

.style_field__beXyk::placeholder {
    font-style: italic !important;
}
.success_title__262X7 {
  color: #88b04b;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.success_paragraph__IMgtL {
  color: #404f5e;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-size: 20px;
  margin: 0;
}

.success_italic__1QEZl {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.success_card__3QSTx {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--title-font);
}

.success_container__1Rs1p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 120px;
}
.success_title__2Rv59 {
  color: #88b04b;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.success_paragraph__2lCtx {
  color: #404f5e;
  /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
  font-size: 20px;
  margin: 0;
}

.success_italic__kI2cO {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.success_card__23uuz {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: var(--title-font);
}

.success_container__1cOmy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 120px;
}
.wizard_title__1Z4GN {
  text-align: center;
}

.wizard_title__1Z4GN h2 {
  color: var(--first-color);
  margin: var(--mb-1) 0;
}

/* .info svg {
  font-size: 17px;
  width: 50px;
  height: 50px;
} */

.wizard_arrow__JUgaL {
  cursor: pointer;
}
.wizard_info__2Eeu_ .wizard_arrow__JUgaL:hover svg path {
  fill: var(--first-color);
}
.wizard_info__2Eeu_ {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.wizard_logo__2-OLY {
  margin-bottom: var(--mb-1);
}

[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #80b1b2;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #a8bfbf;
}
a:active {
  color: #5e8a8c;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #80b1b2;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #80b1b2;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #80b1b2;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #80b1b2;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 2px;
}
.ant-alert-content {
  flex: 1 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e4f2f0;
  border: 1px solid #ccd9d8;
}
.ant-alert-info .ant-alert-icon {
  color: #80b1b2;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description {
  padding-right: 24px;
  padding-left: 15px;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: transparent;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #80b1b2;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor-fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 4px 0 4px 16px;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 3px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #80b1b2;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 2px;
  padding-bottom: 2px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 4px 16px 4px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 2px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
  .ant-back-top-rtl {
    right: auto;
    left: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #ff4d4f;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #ff4d4f;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-dot.ant-scroll-number {
  transition: background 1.5s;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-count.anticon-spin,
.ant-badge-dot.anticon-spin,
.ant-badge .ant-scroll-number-custom-component.anticon-spin {
  animation: antBadgeLoadingCircle 1s infinite linear;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #ff4d4f;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
  direction: ltr;
}
.ant-scroll-number-only {
  position: relative;
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  /* stylelint-enable property-no-vendor-prefix */
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%;
  }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #80b1b2;
  border-radius: 2px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentcolor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-count,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-badge-dot,
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge:not(.ant-badge-not-a-wrapper) .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.ant-badge:not(.ant-badge-not-a-wrapper).ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentcolor currentcolor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentcolor transparent transparent currentcolor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentcolor transparent transparent currentcolor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb li:last-child a {
  color: rgba(0, 0, 0, 0.85);
}
li:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 2px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #80b1b2;
  background: #80b1b2;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #a8bfbf;
  background: #a8bfbf;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #5e8a8c;
  background: #5e8a8c;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #a8bfbf;
  border-left-color: #a8bfbf;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #a8bfbf;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #a8bfbf;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #80b1b2;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #ff7875;
  background: #ff7875;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #d9363e;
  background: #d9363e;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #ff4d4f;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #ff7875;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #d9363e;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}
.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 2px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 2px 2px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 2px 0 0 2px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #80b1b2;
  border-color: #80b1b2;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #a8bfbf;
  border-color: #a8bfbf;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #5e8a8c;
  border-color: #5e8a8c;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #ff4d4f;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #ff7875;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #d9363e;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 30px;
}
a.ant-btn-disabled {
  cursor: not-allowed;
}
a.ant-btn-disabled > * {
  pointer-events: none;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.ant-btn-disabled > a:only-child,
a.ant-btn-disabled:hover > a:only-child,
a.ant-btn-disabled:focus > a:only-child,
a.ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.ant-btn-disabled > a:only-child::after,
a.ant-btn-disabled:hover > a:only-child::after,
a.ant-btn-disabled:focus > a:only-child::after,
a.ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #a8bfbf;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #a8bfbf;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 2px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 2px 2px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-mini .ant-picker-cell::before {
  pointer-events: none;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #e4f2f0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #80b1b2;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #80b1b2;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs-top {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-top-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid .ant-card-body {
  display: flex;
  flex-wrap: wrap;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 2px 2px 0 0;
}
.ant-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #80b1b2;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #80b1b2;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-rtl .ant-card-actions > li:not(:last-child) {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  display: flex;
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
          user-select: none;
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
  bottom: auto;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-cascader-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox:hover .ant-cascader-checkbox-inner,
.ant-cascader-checkbox-input:focus + .ant-cascader-checkbox-inner {
  border-color: #80b1b2;
}
.ant-cascader-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #80b1b2;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-cascader-checkbox:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox::after {
  visibility: visible;
}
.ant-cascader-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-cascader-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner {
  background-color: #80b1b2;
  border-color: #80b1b2;
}
.ant-cascader-checkbox-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled.ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-cascader-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-checkbox-disabled:hover::after,
.ant-cascader-checkbox-wrapper:hover .ant-cascader-checkbox-disabled::after {
  visibility: hidden;
}
.ant-cascader-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-cascader-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-cascader-checkbox-wrapper + .ant-cascader-checkbox-wrapper {
  margin-left: 8px;
}
.ant-cascader-checkbox-wrapper.ant-cascader-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-cascader-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-cascader-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-cascader-checkbox-group-item {
  margin-right: 8px;
}
.ant-cascader-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 0;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #80b1b2;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-cascader-checkbox-indeterminate.ant-cascader-checkbox-disabled .ant-cascader-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-checkbox-rtl {
  direction: rtl;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-cascader-checkbox-group-rtl .ant-cascader-checkbox-group-item + .ant-cascader-checkbox-group-item {
  margin-left: 8px;
}
.ant-cascader {
  width: 184px;
}
.ant-cascader-checkbox {
  top: 0;
  margin-right: 8px;
}
.ant-cascader-menus {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.ant-cascader-menus.ant-cascader-menu-empty .ant-cascader-menu {
  width: 100%;
  height: auto;
}
.ant-cascader-menu {
  flex-grow: 1;
  min-width: 111px;
  height: 180px;
  margin: 0;
  margin: -4px 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #e4f2f0;
}
.ant-cascader-menu-item-content {
  flex: auto;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item-keyword {
  color: #ff4d4f;
}
.ant-cascader-rtl .ant-cascader-menu-item-expand-icon,
.ant-cascader-rtl .ant-cascader-menu-item-loading-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-cascader-rtl .ant-cascader-checkbox {
  top: 0;
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #80b1b2;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #80b1b2;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #80b1b2;
  border-color: #80b1b2;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #80b1b2;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 2px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 2px 2px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: auto;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  left: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 2px 2px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  border-bottom: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
}
.ant-collapse-rtl.ant-collapse.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 50%;
  right: auto;
  left: 16px;
  margin: 0;
  transform: translateY(-50%);
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 0;
  margin-left: 12px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 0px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 0px 7px 0px;
}
.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #80b1b2;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #80b1b2;
  background: #e4f2f0;
  border-color: #ccd9d8;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #80b1b2;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #80b1b2;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #80b1b2;
  border-radius: 2px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e4f2f0;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #80b1b2;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e4f2f0;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #c0d8d9;
  border-bottom: 1px dashed #c0d8d9;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #eff5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #eff5f5;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #c0d8d9;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #c0d8d9;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: -webkit-min-content;
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #80b1b2;
}
.ant-picker-today-btn:hover {
  color: #a8bfbf;
}
.ant-picker-today-btn:active {
  color: #5e8a8c;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #c0d8d9;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #c0d8d9;
  border-radius: 0 2px 2px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #c0d8d9;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #c0d8d9;
  border-radius: 2px 0 0 2px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #80b1b2;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(228, 242, 240, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e4f2f0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #c0d8d9;
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #c0d8d9;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #c0d8d9;
  border-left: 1px dashed #c0d8d9;
  border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  border-radius: 2px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
  border-collapse: collapse;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left::after {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-no-default-orientation-margin-left .ant-divider-inner-text {
  padding-left: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::before {
  width: 100%;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right::after {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-no-default-orientation-margin-right .ant-divider-inner-text {
  padding-right: 0;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: width 0s ease 0.3s, height 0s ease 0.3s;
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  flex: 1 1;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  display: inline-block;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-drawer-header-title {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: space-between;
}
.ant-drawer-header-close-only {
  padding-bottom: 0;
  border: none;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 12px;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden,
.ant-dropdown-menu-submenu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-top,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-dropdown-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-dropdown-placement-top > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 10px;
  box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}
.ant-dropdown-placement-top > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 9.41421356px;
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
  transform: rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottom > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item {
  position: relative;
  display: flex;
  align-items: center;
}
.ant-dropdown-menu-item-icon {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-title-content {
  flex: auto;
}
.ant-dropdown-menu-title-content > a {
  color: inherit;
  transition: all 0.3s;
}
.ant-dropdown-menu-title-content > a:hover {
  color: inherit;
}
.ant-dropdown-menu-title-content > a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: #80b1b2;
  background-color: #e4f2f0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled a,
.ant-dropdown-menu-submenu-title-disabled a {
  pointer-events: none;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #80b1b2;
}
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-enter.ant-slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.ant-slide-down-appear.ant-slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottom,
.ant-dropdown.ant-slide-down-leave.ant-slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-top,
.ant-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading,
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn {
  cursor: default;
  pointer-events: none;
}
.ant-dropdown-button.ant-btn-group > .ant-btn-loading + .ant-btn::before {
  display: block;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #80b1b2;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title,
.ant-dropdown-menu-submenu-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill: #f5f5f5;
  fill-opacity: 0.8;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url('#linearGradient-1');
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: 0;
}
.ant-form-horizontal .ant-form-item-label[class$='-24'] + .ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*='-24 '] + .ant-form-item-control {
  min-width: unset;
}
.ant-form-vertical .ant-form-item {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain-error {
  color: #ff4d4f;
}
.ant-form-item-explain-warning {
  color: #faad14;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}
.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 24px;
  vertical-align: top;
  transition: margin-bottom 0.3s 0.017s linear;
}
.ant-form-item-with-help {
  margin-bottom: 0;
  transition: none;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  color: rgba(0, 0, 0, 0.45);
  cursor: help;
  writing-mode: horizontal-tb;
  -webkit-margin-start: 4px;
          margin-inline-start: 4px;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item-explain-connected {
  height: 0;
  min-height: 0;
  opacity: 0;
}
.ant-form-item-extra {
  min-height: 24px;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  min-height: 24px;
  opacity: 1;
}
.ant-form-item-feedback-icon {
  font-size: 14px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-feedback-icon-success {
  color: #52c41a;
}
.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}
.ant-form-item-feedback-icon-warning {
  color: #faad14;
}
.ant-form-item-feedback-icon-validating {
  color: #80b1b2;
}
.ant-show-help {
  transition: height 0.3s linear, min-height 0.3s linear, margin-bottom 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-show-help-leave {
  min-height: 24px;
}
.ant-show-help-leave-active {
  min-height: 0;
}
.ant-show-help-item {
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-5px);
  opacity: 0;
}
.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}
.ant-show-help-item-leave-active {
  transform: translateY(-5px);
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-space-evenly {
  justify-content: space-evenly;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
  color: rgba(255, 255, 255, 0.25);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 10px;
}
.ant-image-preview-switch-right {
  right: 10px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none !important;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix > *:not(:last-child),
.ant-input-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-input-show-count-suffix {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-show-count-has-suffix {
  margin-right: 2px;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  margin: 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.anticon.ant-input-clear-icon:hover,
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.anticon.ant-input-clear-icon:active,
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.anticon.ant-input-clear-icon-hidden,
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.anticon.ant-input-clear-icon-has-suffix,
.ant-input-clear-icon-has-suffix {
  margin: 0 4px;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-textarea-status-error.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-warning.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-success.ant-input-textarea-has-feedback .ant-input,
.ant-input-textarea-status-validating.ant-input-textarea-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-input-group-wrapper-status-error .ant-input-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-group-wrapper-status-warning .ant-input-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 0px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #80b1b2;
}
.ant-input-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-group > .ant-input-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon.anticon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon.anticon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 32px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count > .ant-input {
  height: 100%;
}
.ant-input-textarea-show-count::after {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  white-space: nowrap;
  content: attr(data-count);
  pointer-events: none;
}
.ant-input-textarea-show-count.ant-input-textarea-in-form-item::after {
  margin-bottom: -22px;
}
.ant-input-textarea-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #a8bfbf;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #a8bfbf;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon-has-suffix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #a8bfbf;
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #a8bfbf;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 2px 0 0 2px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number-affix-wrapper {
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  width: 90px;
  padding: 0;
  -webkit-padding-start: 11px;
          padding-inline-start: 11px;
}
.ant-input-number-affix-wrapper::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input-number-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-affix-wrapper:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-affix-wrapper:focus,
.ant-input-rtl .ant-input-number-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-affix-wrapper[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-affix-wrapper-borderless,
.ant-input-number-affix-wrapper-borderless:hover,
.ant-input-number-affix-wrapper-borderless:focus,
.ant-input-number-affix-wrapper-borderless-focused,
.ant-input-number-affix-wrapper-borderless-disabled,
.ant-input-number-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-number-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
  z-index: 1;
}
.ant-input-rtl .ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-affix-wrapper-focused,
.ant-input-number-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-number-affix-wrapper-disabled .ant-input-number[disabled] {
  background: transparent;
}
.ant-input-number-affix-wrapper > div.ant-input-number {
  width: 100%;
  border: none;
  outline: none;
}
.ant-input-number-affix-wrapper > div.ant-input-number.ant-input-number-focused {
  box-shadow: none !important;
}
.ant-input-number-affix-wrapper input.ant-input-number-input {
  padding: 0;
}
.ant-input-number-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-input-number-prefix,
.ant-input-number-suffix {
  display: flex;
  flex: none;
  align-items: center;
  pointer-events: none;
}
.ant-input-number-prefix {
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
}
.ant-input-number-suffix {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100%;
  margin-right: 11px;
  margin-left: 4px;
}
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  width: 100%;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-input-number-group-wrapper-status-warning .ant-input-number-group-addon {
  color: #faad14;
  border-color: #faad14;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 0px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-number-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-number-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-number-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap,
.ant-input-number-group > .ant-input-number {
  display: table-cell;
}
.ant-input-number-group-addon:not(:first-child):not(:last-child),
.ant-input-number-group-wrap:not(:first-child):not(:last-child),
.ant-input-number-group > .ant-input-number:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-number-group-addon,
.ant-input-number-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-number-group-wrap > * {
  display: block !important;
}
.ant-input-number-group .ant-input-number {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-number-group .ant-input-number:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-number-group .ant-input-number:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-number-group .ant-input-number:hover {
  z-index: 0;
}
.ant-input-number-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input-number-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-number-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-number-group-addon .ant-select-open .ant-select-selector,
.ant-input-number-group-addon .ant-select-focused .ant-select-selector {
  color: #80b1b2;
}
.ant-input-number-group-addon .ant-cascader-picker {
  margin: -9px -12px;
  background-color: transparent;
}
.ant-input-number-group-addon .ant-cascader-picker .ant-cascader-input {
  text-align: left;
  border: 0;
  box-shadow: none;
}
.ant-input-number-group > .ant-input-number:first-child,
.ant-input-number-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number:first-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:first-child) .ant-input-number {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number-affix-wrapper:not(:last-child) .ant-input-number {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-number-group-addon:first-child {
  border-right: 0;
}
.ant-input-number-group-addon:last-child {
  border-left: 0;
}
.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group > .ant-input-number:last-child .ant-select .ant-select-selector,
.ant-input-number-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group-lg .ant-input-number,
.ant-input-number-group-lg > .ant-input-number-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-group-sm .ant-input-number,
.ant-input-number-group-sm > .ant-input-number-group-addon {
  padding: 0px 7px;
}
.ant-input-number-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-number-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child),
.ant-input-search .ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact {
  display: block;
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::before {
  display: table;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-input-number-affix-wrapper {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-number {
  float: none;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-number-group.ant-input-number-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group > .ant-input-number-rtl:first-child,
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group > .ant-input-number:last-child,
.ant-input-number-group-rtl.ant-input-number-group-addon:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:first-child) {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl.ant-input-number-group .ant-input-number-affix-wrapper:not(:last-child) {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:first-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > *:last-child,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-number-group-rtl.ant-input-number-group.ant-input-number-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-number-group.ant-input-number-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:first-child {
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group > .ant-input-number-rtl:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:first-child {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 0 2px 2px 0;
}
.ant-input-number-group-rtl .ant-input-number-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-input-number-group-wrapper {
  display: inline-block;
  text-align: start;
  vertical-align: top;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  border-left: 1px solid #d9d9d9;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #a8bfbf;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: 0;
  transition: all 0.3s linear;
  -webkit-appearance: textfield !important;
          appearance: textfield !important;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-radius: 0 2px 2px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-left-width: 0;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap,
.ant-input-number-focused .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 2px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-borderless .ant-input-number-handler-down {
  border-top-width: 0;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-borderless {
  box-shadow: none;
}
.ant-input-number-out-of-range input {
  color: #ff4d4f;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler {
  border-right: 1px solid #d9d9d9;
  border-left: 0;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
}
.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
  border-right-width: 0;
}
.ant-input-number-rtl .ant-input-number-handler-up {
  border-top-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-handler-down {
  border-bottom-right-radius: 0;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: auto;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 2px 0 0 2px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #80b1b2;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-error:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions:focus,
.ant-mentions-status-warning:not(.ant-mentions-disabled):not(.ant-mentions-borderless).ant-mentions-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-mentions-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 0px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-mentions-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 2px 2px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 2px 2px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-suffix {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: auto;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #ff4d4f;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #ff4d4f;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #ff4d4f;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #ff4d4f;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #ff4d4f;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #d8e6e4;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-overflow {
  display: flex;
}
.ant-menu-overflow-item {
  flex: none;
}
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #80b1b2;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e4f2f0;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-title-content {
  transition: color 0.3s;
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
  color: #80b1b2;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item > .ant-badge a:hover {
  color: #80b1b2;
}
.ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: #f0f0f0;
  border-style: solid;
  border-width: 1px 0 0;
}
.ant-menu-item-divider-dashed {
  border-style: dashed;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #80b1b2;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #80b1b2;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e4f2f0;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}
.ant-menu-item .ant-menu-item-icon.svg,
.ant-menu-submenu-title .ant-menu-item-icon.svg {
  vertical-align: -0.125em;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu-item:focus-visible,
.ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #d8e6e4;
}
.ant-menu > .ant-menu-item-divider {
  margin: 1px 0;
  padding: 0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 2px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 2px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #80b1b2;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #80b1b2;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #80b1b2;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #80b1b2;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  position: absolute;
  right: 20px;
  bottom: 0;
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #80b1b2;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #80b1b2;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #80b1b2;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > *,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
  flex: none;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  opacity: 0;
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: #fafafa;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  cursor: not-allowed;
}
.ant-menu-item-disabled::after,
.ant-menu-submenu-disabled::after {
  border-color: transparent !important;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu-inline-collapsed-tooltip a,
.ant-menu-inline-collapsed-tooltip a:hover {
  color: #fff;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #80b1b2;
}
.ant-menu.ant-menu-root:focus-visible {
  box-shadow: 0 0 0 2px #5e8a8c;
}
.ant-menu-dark .ant-menu-item:focus-visible,
.ant-menu-dark .ant-menu-submenu-title:focus-visible {
  box-shadow: 0 0 0 2px #5e8a8c;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #80b1b2;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #80b1b2;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #80b1b2;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #ff4d4f;
}
.ant-message-warning .anticon {
  color: #faad14;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #80b1b2;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #80b1b2;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder {
  position: relative;
}
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-top .ant-notification-notice,
.ant-notification-bottom .ant-notification-notice {
  margin-right: auto;
  margin-left: auto;
}
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #80b1b2;
}
.anticon.ant-notification-notice-icon-warning {
  color: #faad14;
}
.anticon.ant-notification-notice-icon-error {
  color: #ff4d4f;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  opacity: 0;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-notification-top,
.ant-notification-bottom {
  margin-right: 0;
  margin-left: 0;
}
.ant-notification-top .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-top .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationTopFadeIn;
}
.ant-notification-bottom .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottom .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationBottomFadeIn;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
@keyframes NotificationTopFadeIn {
  0% {
    margin-top: -100%;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
@keyframes NotificationBottomFadeIn {
  0% {
    margin-bottom: -100%;
    opacity: 0;
  }
  100% {
    margin-bottom: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #80b1b2;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #a8bfbf;
}
.ant-page-header-back-button:active {
  color: #5e8a8c;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  white-space: unset;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:hover {
  border-color: #80b1b2;
  transition: all 0.3s;
}
.ant-pagination-item:hover a {
  color: #80b1b2;
}
.ant-pagination-item:focus-visible {
  border-color: #80b1b2;
  transition: all 0.3s;
}
.ant-pagination-item:focus-visible a {
  color: #80b1b2;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #80b1b2;
}
.ant-pagination-item-active a {
  color: #80b1b2;
}
.ant-pagination-item-active:hover {
  border-color: #a8bfbf;
}
.ant-pagination-item-active:focus-visible {
  border-color: #a8bfbf;
}
.ant-pagination-item-active:hover a {
  color: #a8bfbf;
}
.ant-pagination-item-active:focus-visible a {
  color: #a8bfbf;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #80b1b2;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #a8bfbf;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-next:focus-visible .ant-pagination-item-link {
  color: #80b1b2;
  border-color: #80b1b2;
}
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #80b1b2;
  border-color: #80b1b2;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible {
  cursor: not-allowed;
}
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 50px;
  height: 32px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
  -webkit-user-select: none;
          user-select: none;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #80b1b2;
}
.ant-pagination-simple .ant-pagination-simple-pager input:focus {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-mini .ant-pagination-total-text,
.ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.ant-pagination-mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
  height: 24px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #e6e6e6;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: rgba(0, 0, 0, 0.25);
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
          user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #ff4d4f;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@keyframes ant-progress-active {
  0% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.1;
  }
  20% {
    transform: translateX(-100%) scaleX(0);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0) scaleX(1);
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #80b1b2;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #e4f2f0;
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #80b1b2;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #80b1b2;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #80b1b2;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #80b1b2;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px #e4f2f0;
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #80b1b2;
  background: #fff;
  border-color: #80b1b2;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #80b1b2;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #80b1b2;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #a8bfbf;
  border-color: #a8bfbf;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #a8bfbf;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #5e8a8c;
  border-color: #5e8a8c;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #5e8a8c;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #e4f2f0;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #80b1b2;
  border-color: #80b1b2;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #a8bfbf;
  border-color: #a8bfbf;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #5e8a8c;
  border-color: #5e8a8c;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #e4f2f0;
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #a8bfbf;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star > div:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div {
  transition: all 0.3s, outline 0s;
}
.ant-rate-star > div:hover {
  transform: scale(1.1);
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:focus-visible {
  outline: 1px dashed #fadb14;
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #ff4d4f;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #80b1b2;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #faad14;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}
.segmented-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  padding: 2px;
  color: rgba(0, 0, 0, 0.65);
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-group {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-items: flex-start;
  width: 100%;
}
.ant-segmented.ant-segmented-block {
  display: flex;
}
.ant-segmented.ant-segmented-block .ant-segmented-item {
  flex: 1 1;
  min-width: 0;
}
.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: rgba(0, 0, 0, 0.06);
}
.ant-segmented-item {
  position: relative;
  text-align: center;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #262626;
}
.ant-segmented-item:hover,
.ant-segmented-item:focus {
  color: #262626;
}
.ant-segmented-item-label {
  min-height: 28px;
  padding: 0 11px;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-segmented-item-icon + * {
  margin-left: 6px;
}
.ant-segmented-item-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 36px;
  padding: 0 11px;
  font-size: 16px;
  line-height: 36px;
}
.ant-segmented.ant-segmented-sm .ant-segmented-item-label {
  min-height: 20px;
  padding: 0 7px;
  line-height: 20px;
}
.ant-segmented-item-disabled,
.ant-segmented-item-disabled:hover,
.ant-segmented-item-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-segmented-thumb {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  padding: 4px 0;
}
.ant-segmented-thumb-motion-appear-active {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, width;
}
.ant-segmented.ant-segmented-rtl {
  direction: rtl;
}
.ant-segmented.ant-segmented-rtl .ant-segmented-item-icon {
  margin-right: 0;
  margin-left: 6px;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  transition: none;
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
          user-select: none;
  -webkit-margin-end: 4px;
          margin-inline-end: 4px;
  -webkit-padding-start: 8px;
          padding-inline-start: 8px;
  -webkit-padding-end: 4px;
          padding-inline-end: 4px;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: middle;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  -webkit-margin-start: 0;
          margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  -webkit-margin-start: 7px;
          margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  -webkit-margin-start: 3px;
          margin-inline-start: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #ff4d4f !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #faad14 !important;
}
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-select-status-warning.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-clear,
.ant-select-status-warning.ant-select-has-feedback .ant-select-clear,
.ant-select-status-success.ant-select-has-feedback .ant-select-clear,
.ant-select-status-validating.ant-select-has-feedback .ant-select-clear {
  right: 32px;
}
.ant-select-status-error.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-warning.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-success.ant-select-has-feedback .ant-select-selection-selected-value,
.ant-select-status-validating.ant-select-has-feedback .ant-select-selection-selected-value {
  padding-right: 42px;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  display: flex;
  align-items: center;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-arrow > *:not(:last-child) {
  -webkit-margin-end: 8px;
          margin-inline-end: 8px;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e4f2f0;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #80b1b2;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select.ant-select-in-form-item {
  width: 100%;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  position: relative;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title::after,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  position: relative;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  position: relative;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  position: relative;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  position: relative;
  overflow: hidden;
  background: transparent;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image::after {
  position: absolute;
  top: 0;
  right: -150%;
  bottom: 0;
  left: -150%;
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  animation: ant-skeleton-loading 1.4s ease infinite;
  content: '';
}
.ant-skeleton.ant-skeleton-block {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  width: 100%;
}
.ant-skeleton.ant-skeleton-block .ant-skeleton-input {
  width: 100%;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 2px;
  width: 64px;
  min-width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  min-width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  min-width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 160px;
  min-width: 160px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 200px;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 120px;
  min-width: 120px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    transform: translateX(-37.5%);
  }
  100% {
    transform: translateX(37.5%);
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  margin-left: -2px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #ccd9d8;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #ccd9d8;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging {
  z-index: 1;
}
.ant-slider-handle:focus {
  border-color: #99c1c1;
  outline: none;
  box-shadow: 0 0 0 5px rgba(128, 177, 178, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #80b1b2;
}
.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #c0cccb;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #c0cccb;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-color: #c0d8d9;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #80b1b2;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  opacity: 0.5;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #80b1b2;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(0deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-skeleton {
  padding-top: 16px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #80b1b2;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #80b1b2;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #80b1b2;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #80b1b2;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #80b1b2;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #80b1b2;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #80b1b2;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #80b1b2;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #80b1b2;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #80b1b2;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #80b1b2;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #80b1b2;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #80b1b2;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #80b1b2;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #80b1b2;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-right: 4px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
  top: 4px !important;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(to right, #fff, #fff);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #e4f2f0;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background: #80b1b2;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #80b1b2;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 40px;
}
.ant-table.ant-table-middle .ant-table-selection-column {
  -webkit-padding-start: 2px;
          padding-inline-start: 2px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 40px;
}
.ant-table.ant-table-small .ant-table-selection-column {
  -webkit-padding-start: 2px;
          padding-inline-start: 2px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th::before,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: '';
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 32px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child,
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #e4f2f0;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #ddefec;
}
.ant-table-summary {
  position: relative;
  z-index: 2;
  background: #fff;
}
div.ant-table-summary {
  box-shadow: 0 -1px 0 #f0f0f0;
}
.ant-table-summary > tr > th,
.ant-table-summary > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
  flex-wrap: wrap;
  grid-row-gap: 8px;
  row-gap: 8px;
}
.ant-table-pagination > * {
  flex: none;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead th.ant-table-column-has-sorters:hover::before {
  background-color: transparent !important;
}
.ant-table-thead th.ant-table-column-has-sorters:focus-visible {
  color: #80b1b2;
}
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover,
.ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-right:hover {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-thead th.ant-table-column-sort::before {
  background-color: transparent !important;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: 1 1;
}
.ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: space-between;
}
.ant-table-column-sorters::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.ant-table-column-sorter {
  margin-left: 4px;
  color: #bfbfbf;
  font-size: 0;
  transition: color 0.3s;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #80b1b2;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #a6a6a6;
}
.ant-table-filter-column {
  display: flex;
  justify-content: space-between;
}
.ant-table-filter-trigger {
  position: relative;
  display: flex;
  align-items: center;
  margin: -4px -8px -4px 4px;
  padding: 0 4px;
  color: #bfbfbf;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-filter-trigger.active {
  color: #80b1b2;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  min-width: 120px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu:empty::after {
  display: block;
  padding: 8px 0;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  text-align: center;
  content: 'Not Found';
}
.ant-table-filter-dropdown-tree {
  padding: 8px 8px 0;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper,
.ant-table-filter-dropdown-tree .ant-tree-treenode-checkbox-checked .ant-tree-node-content-wrapper:hover {
  background-color: #d8e6e4;
}
.ant-table-filter-dropdown-search {
  padding: 8px;
  border-bottom: 1px #f0f0f0 solid;
}
.ant-table-filter-dropdown-search-input input {
  min-width: 140px;
}
.ant-table-filter-dropdown-search-input .anticon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-filter-dropdown-checkall {
  width: 100%;
  margin-bottom: 4px;
  margin-left: 4px;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table-selection-col {
  width: 32px;
}
.ant-table-bordered .ant-table-selection-col {
  width: 50px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
table tr th.ant-table-selection-column.ant-table-cell-fix-left {
  z-index: 3;
}
table tr th.ant-table-selection-column::after {
  background-color: transparent !important;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-margin-start: 100%;
          margin-inline-start: 100%;
  -webkit-padding-start: 4px;
          padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #80b1b2;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  vertical-align: -3px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
  transform: scale(0.94117647);
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #a8bfbf;
}
.ant-table-row-expand-icon:active {
  color: #5e8a8c;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentcolor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view {
  display: flex;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  flex: auto;
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) > .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-last::before {
  background-color: transparent !important;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) > .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-holder {
  position: -webkit-sticky;
  position: sticky;
  z-index: calc(2 + 1);
  background: #fff;
}
.ant-table-sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 2px 2px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}
.ant-table-footer {
  border-radius: 0 0 2px 2px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  right: auto;
  left: 0;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 4px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger {
  margin: -4px 4px -4px -8px;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-add {
  margin-right: 2px;
  margin-left: 0;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 16px 0 0 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex: 1 0 auto;
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item > span {
  flex: 1 1;
  white-space: nowrap;
}
.ant-tabs-dropdown-menu-item-remove {
  flex: none;
  margin-left: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: 0;
  cursor: pointer;
}
.ant-tabs-dropdown-menu-item-remove:hover {
  color: #a8bfbf;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #80b1b2;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 2px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 2px 2px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin-top: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 2px 0 0 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 2px 2px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: flex;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  margin-left: 2px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #a8bfbf;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #5e8a8c;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #80b1b2;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #5e8a8c;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab:hover {
  color: #a8bfbf;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #80b1b2;
  text-shadow: 0 0 0.25px currentcolor;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-content {
  display: flex;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-content-animated {
  transition: margin 0.3s;
}
.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #80b1b2;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #80b1b2;
}
.ant-tag-checkable:active {
  background-color: #5e8a8c;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #80b1b2;
  background: #e4f2f0;
  border-color: #ccd9d8;
}
.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}
.ant-tag-warning {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #80b1b2;
  border-color: #80b1b2;
}
.ant-timeline-item-head-red {
  color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  width: -webkit-max-content;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-content {
  position: relative;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: inset(33% 33%);
          clip-path: inset(33% 33%);
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-selection-column {
  width: 40px;
  min-width: 40px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer-status-error .ant-transfer-list {
  border-color: #ff4d4f;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list {
  border-color: #faad14;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-color: #a8bfbf;
  border-right-width: 1px;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-color: #a8bfbf;
  box-shadow: 0 0 0 2px rgba(128, 177, 178, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-rtl .ant-transfer-status-warning .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search .anticon-search {
  color: rgba(0, 0, 0, 0.25);
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  color: #80b1b2;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #d9d9d9;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: #a8bfbf;
}
.ant-transfer-list-content-item-remove:active {
  color: #5e8a8c;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item-remove:hover {
  color: #a8bfbf;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #ddefec;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #e4f2f0;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
@keyframes ant-tree-node-fx-do-not-use {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #80b1b2;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #80b1b2;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #80b1b2;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #80b1b2;
  border-color: #80b1b2;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #80b1b2;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #e4f2f0;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging {
  position: relative;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #80b1b2;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
          user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-tree-treenode:hover .ant-tree-draggable-icon {
  opacity: 0.45;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #80b1b2;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #d8e6e4;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
          user-select: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #80b1b2;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #80b1b2;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #80b1b2;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-tree .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl .ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #80b1b2;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #80b1b2;
  border-radius: 2px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #80b1b2;
  border-color: #80b1b2;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
  pointer-events: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-in-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #80b1b2;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background: #fff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #e4f2f0;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging {
  position: relative;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-treenode.dragging::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  border: 1px solid #80b1b2;
  opacity: 0;
  animation: ant-tree-node-fx-do-not-use 0.3s;
  animation-play-state: running;
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
  color: inherit;
  font-weight: 500;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  -webkit-user-select: none;
          user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-draggable-icon {
  width: 24px;
  line-height: 24px;
  text-align: center;
  opacity: 0.2;
  transition: opacity 0.3s;
}
.ant-select-tree-treenode:hover .ant-select-tree-draggable-icon {
  opacity: 0.45;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #80b1b2;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  margin-left: -1px;
  border-right: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  border-bottom: 1px solid #d9d9d9;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #d8e6e4;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper {
  line-height: 24px;
  -webkit-user-select: none;
          user-select: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #80b1b2;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #80b1b2;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #80b1b2;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.15em;
}
.ant-select-tree .ant-select-tree-treenode-leaf-last .ant-select-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
  color: #52c41a;
}
.ant-typography.ant-typography-warning {
  color: #faad14;
}
.ant-typography.ant-typography-danger {
  color: #ff4d4f;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus {
  color: #d9363e;
}
a.ant-typography.ant-typography-danger:hover {
  color: #ff7875;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1 > textarea,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2 > textarea,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3 > textarea,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4 > textarea,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5 > textarea,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
  max-width: 100%;
}
a.ant-typography,
.ant-typography a {
  color: #80b1b2;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #a8bfbf;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #5e8a8c;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #80b1b2;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #a8bfbf;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #5e8a8c;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  height: 1em;
  margin: 0 !important;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-single-line {
  white-space: nowrap;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #80b1b2;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #5e8a8c;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #a8bfbf;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #a8bfbf;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 22.001px;
  line-height: 1;
  vertical-align: top;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-upload-list-item-card-actions:hover .anticon {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #ff4d4f;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 60px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #ff4d4f;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  margin-bottom: 12px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  margin: 0;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UA.ttf) format('truetype');
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "CartoonFont";
  src: url(/static/media/cartoon.3e9d50f3.woff2) format("woff2");
}
@font-face {
  font-family: "CartoonFontBold";
  src: url(/static/media/cartoonBold.1cddd146.woff2) format("woff2");
}
.ant-steps-item-title:after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 100vw !important;
  height: 5px;
  background: #f0f0f0;
  content: "";
  border-radius: 10px;
}
.ant-segmented-item-selected:hover {
  color: #fff;
}
.ant-segmented-item {
  border-radius: 15px;
  color: #fff;
}
.ant-segmented-item-selected {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  color: #fff;
  background: #023334;
}
.ant-segmented-item-selected * svg {
  color: #eec13e;
  stroke: #eec13e;
  fill: #eec13e !important;
}
.ant-segmented-item:hover {
  border-radius: 15px;
  color: #faea89;
}
.ant-select-item-group {
  font-size: 20px;
  font-family: var(--title-font) !important;
  text-transform: uppercase;
}
.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0 !important;
}
.ant-notification-notice {
  border-radius: 10px !important;
}
@media (max-width: 768px) {
  .ant-drawer-body {
    padding: 6px;
  }
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: block;
  padding: 0;
  padding-right: 12px;
}
.ant-modal-footer {
  border-top: 1px solid transparent !important;
}
@font-face {
  font-family: 'Margin DEMO';
  src: url(/static/media/MarginDemo.5da5c7ad.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}
.ant-tabs-ink-bar {
  background: none;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 12px;
}
.ant-tabs,
.ant-tabs-nav,
.ant-tabs-nav-wrap {
  border-bottom: 1px solid #FFF;
}
.drawer .ant-drawer-content {
  background-color: transparent;
  background-clip: padding-box;
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 55%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-list {
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: 95%;
}
.searchTeacher .ant-modal-close {
  right: auto;
  left: 16px;
}
.searchTeacher .ant-modal-content {
  border-radius: 20px !important;
  padding: 40px;
  background-color: #FFF;
  text-align: center;
  position: absolute;
  left: -25%;
  top: -250px;
  width: auto !important;
  z-index: 1;
}
.ant-list-split .ant-list-item {
  border-bottom: 0px solid #f0f0f0;
}
@media only screen and (max-width: 1050px) {
  .ant-result {
    padding: 0px 32px;
  }
  .ant-result-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 1.2em;
    line-height: 1.8;
    text-align: center;
  }
  .datePickerInput .ant-picker-input > input::placeholder {
    font-size: 0.8em;
  }
  .datePickerInput .ant-picker-input > input {
    position: relative;
    top: -2px;
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 55%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-list {
    margin-left: 0px !important;
    margin-right: 10px !important;
    width: 100%;
  }
  .searchTeacher .ant-modal-content {
    border-radius: 20px !important;
    padding: 20px;
    background-color: #FFF;
    text-align: center;
    position: relative;
    left: 0%;
    right: 0%;
    top: 0px;
    bottom: 0px;
    height: auto;
    width: auto !important;
    z-index: 1;
  }
  .searchField,
  .ant-input,
  .ant-input-borderless::placeholder {
    font-size: 1.1em !important;
  }
  .searchField,
  .ant-input,
  .ant-input-borderless {
    font-size: 1.1em !important;
  }
}
.custom-collapse .ant-collapse-header {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 16px !important;
  height: 50px !important;
  font-size: 1.2em;
  font-weight: 600;
}
.custom-collapse .ant-collapse-header .ant-collapse-arrow {
  order: 2;
}
.custom-notification {
  background-color: #85b2b5 !important;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  min-width: 350px;
}
.custom-notification-content {
  display: flex;
  align-items: center;
}
.custom-notification-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.custom-notification-text {
  color: white;
  font-size: 14px;
}
.custom-notification-text p {
  margin: 0;
  line-height: 1.5;
}
.custom-notification .ant-notification-close-x {
  color: white !important;
}
.ant-input-number-handler-wrap {
  display: block !important;
}
.ant-input-number-handler {
  display: block !important;
  visibility: visible !important;
}
.notificationImageBox {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
}

/*===== GOOGLE FONTS =====*/
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"); */

/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;
  --loading-grey: #ededed;

  /*========== Colors ==========*/
  --first-color: #80b1b2;
  --first-color-alt: #5a8484;
  --title-color: #333333;
  --text-color: #707070;
  --text-color-light: #a6a6a6;
  /* --body-color: #FBFEFD; */
  --body-color: #fff;
  --container-color: #ffffff;

  /*========== Font and typography ==========*/
  --body-font: "Rubik", sans-serif;
  --title-font: "CartoonFont";
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --big-para: 1.2rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes ==========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

.ant-select-dropdown {
  border-radius: 10px;
}

.ant-modal-content {
  border-radius: 5px !important;
  /* border-bottom: unset !important; */
}

[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 12px;
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-color: #faea89 transparent transparent #faea89;
  box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
}

.ant-collapse {
  border-radius: 20px;
}

/* Placholder */
.ant-select-selection-placeholder {
  color: var(--text-color);
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 2.2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1rem;
    --h3-font-size: 0.9rem;
    --big-para: 1rem;
    --normal-font-size: 0.8rem;
    --small-font-size: 0.7rem;
    --smaller-font-size: 0.65rem;
  }
}

/* OVERIDE */
.calendarPopupContent div {
  padding: 0 !important;
}

/* @font-face {
  font-family: 'gilroy';
  src: url('/fonts/gilroy/gilroy-light-webfont.woff2') format('woff2'),
    url('/fonts/gilroy/gilroy-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

/*========== BASE ==========*/
*,
::before,
::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/*========== Variables Dark theme ==========*/
body.dark-theme {
  --title-color: #f1f3f2;
  --text-color: #c7d1cc;
  --body-color: #1d2521;
  --container-color: #27302c;
}

/*========== Button Dark/Light ==========*/
.change-theme {
  position: absolute;
  right: 1rem;
  top: 1.8rem;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
}

body {
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: 1.6;
  font-weight: 300;
  /* overflow: hidden !important; */
  overflow-x: hidden !important;
}

input {
  font-size: 16px !important;
  font-family: var(--body-font) !important;
}

h1,
h2,
h3 {
  font-weight: 500;
  margin: 0;
  line-height: 1.3;
}

ul,
p {
  margin: 0;
}

p {
  margin-bottom: var(--mb-2);
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  font-weight: 300 !important;
}

strong {
  font-weight: 600 !important;
}

/* img {
  max-width: 100%;
  height: auto;
} */

.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*========== CLASS CSS ==========*/
.section {
  padding: 4rem 0 2rem;
}

.section-title,
.section-subtitle {
  text-align: center;
}

.section-title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-3);
}

.section-subtitle {
  display: block;
  color: var(--first-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

/*========== LAYOUT ==========*/
.bd-container {
  max-width: 1100px;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

/* Show menu */
/* .show-menu{
  top: var(--header-height);
} */

/* Active menu */
.active-link {
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Scroll top */
.scrolltop {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  background: rgba(6, 156, 84, 0.5);
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
  visibility: hidden;
}

.scrolltop:hover {
  background-color: var(--first-color-alt);
}

.scrolltop__icon {
  font-size: 1.8rem;
  color: var(--body-color);
}

/* Show scrolltop */
.show-scroll {
  visibility: visible;
  bottom: 1.5rem;
}

.iframe-maps {
  width: auto !important;
}

/*========== BUTTONS ==========*/
.button {
  display: inline-block;
  border: 1px solid var(--first-color);
  color: var(--first-color);
  padding: 0.3rem 1.3rem;
  border-radius: 0.5rem;
  transition: 0.3s;
  font-weight: 400 !important;
}

button {
  transition: 0.3s;
}
/* 
button:hover {
  transform: scale(1.03) !important;
}

button:active {
  transform: scale(0.99) !important;
} */

button {
  font-family: var(--body-font);
}

.ant-card-body {
  padding: 0 !important;
}

.slick-slide > div {
  margin: 0 3px;
}
.slick-list {
  margin: 0 -3px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--first-color);
}

/* .slick-prev {
  left: -18px !important;
}
.slick-next {
  right: -23px !important;
} */

/*========== MEDIA QUERIES ==========*/
@media screen and (min-width: 576px) {
  .app__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .fakeDiv {
    display: none;
  }

  .app__initial {
    text-align: initial;
  }
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }

  .section {
    padding-top: 4rem;
  }

  .change-theme {
    position: initial;
    margin-left: var(--mb-2);
  }
}

@media screen and (min-width: 960px) {
  .bd-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 500px) {
  .footerDisplay {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--first-color);
  border-radius: 5px;
}

/* New Css Styles */

.style_drawerBody__iEQ-J {
    height: 100% !important;
    width: 34vw !important;
    background: #FFF !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 40px !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    overflow: auto;
    scrollbar-width: thin;
}

.style_drawerCouponBody__3zsja {
    height: 100% !important;
    width: 34vw !important;
    background: #FAFAFA !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 40px !important;
    margin-left: 7% !important;
    margin-right: 7% !important;
    overflow: auto;
    scrollbar-width: thin;
}

.style_newCouponButton__1FTH7 {
    width: auto !important;
    height: 35px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 14px !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #669e9d !important;
    -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
    -moz-box-shadow: 5px 7px 1px -1px #669e9d;
    margin: 0px auto;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.style_ticket__2HCm2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #FFF7E5;
    border: 1px dashed #CEAF7A;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #B88F4B;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.style_ticket__2HCm2::before,
.style_ticket__2HCm2::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #CEAF7A;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.style_ticket__2HCm2::before {
    left: -10px;
}

.style_ticket__2HCm2::after {
    right: -10px;
}

.style_ticketSessions__JqMjq {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #E6FCFF;
    border: 1px dashed #4BA8B9;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #4BA8B9;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.style_ticketSessions__JqMjq::before,
.style_ticketSessions__JqMjq::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #4BA8B9;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.style_ticketSessions__JqMjq::before {
    left: -10px;
}

.style_ticketSessions__JqMjq::after {
    right: -10px;
}

.style_ticketDiscount__3bS20 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #F2FFF8;
    border: 1px dashed #4BB980;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #4BB980;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.style_ticketDiscount__3bS20::before,
.style_ticketDiscount__3bS20::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #4BB980;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.style_ticketDiscount__3bS20::before {
    left: -10px;
}

.style_ticketDiscount__3bS20::after {
    right: -10px;
}

.style_ticketDisabled__2juqu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #FAFAFA;
    border: 1px dashed #AFAFAF;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    color: #AFAFAF;
    position: relative;
    height: 70px;
    width: 100% !important;
    margin: 20px auto;
}

.style_ticketDisabled__2juqu::before,
.style_ticketDisabled__2juqu::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    border: 1px dashed #AFAFAF;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.style_ticketDisabled__2juqu::before {
    left: -10px;
}

.style_ticketDisabled__2juqu::after {
    right: -10px;
}

.style_type__2JB1p {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}

.style_details__3Ecms {
    font-size: 14px;
}

.style_discount__ANJc5 {
    font-size: 18px;
    font-weight: bold;
}


@media only screen and (max-width: 767px) {
    .style_drawerBody__iEQ-J {
        height: 80vh !important;
        width: 95% !important;
        background: #FFF !important;
        box-shadow: 4px 8px 16px #80808029 !important;
        border: 1px solid #BFBCBC !important;
        border-radius: 18px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: flex-start !important;
        padding: 10px !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
        overflow: auto;
        scrollbar-width: thin;
    }

    .style_drawerCouponBody__3zsja {
        height: 80vh !important;
        width: 95% !important;
        background: #FAFAFA !important;
        box-shadow: 4px 8px 16px #80808029 !important;
        border: 1px solid #BFBCBC !important;
        border-radius: 18px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: flex-start !important;
        padding: 20px !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
        overflow: auto;
        scrollbar-width: thin;
    }

    .style_newCouponButton__1FTH7 {
        width: auto !important;
        height: 30px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 50px !important;
        font-size: 0.8em !important;
        text-transform: none;
        text-align: center;
        background: #80b1b2 !important;
        color: #fff !important;
        padding-top: 5px !important;
        font-weight: 400 !important;
        border: none !important;
        box-shadow: 3px 5px 1px -1px #669e9d !important;
        -webkit-box-shadow: 5px 7px 1px -1px #669e9d;
        -moz-box-shadow: 5px 7px 1px -1px #669e9d;
        margin: 0px auto;
        padding-left: 20px !important;
        padding-right: 20px !important;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .style_type__2JB1p {
        font-size: 0.7em;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
    }

    .style_details__3Ecms {
        font-size: 0.7em;
    }

    .style_discount__ANJc5 {
        font-size: 0.8em;
        font-weight: bold;
    }

    .style_drawerBody__iEQ-J p {
        font-size: 0.8em !important;
    }

    .style_drawerCouponBody__3zsja p {
        font-size: 0.8em !important;
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cke_notification {
  display: none !important;
}

.cke_notification_warning {
  display: none !important;
}
