.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 8em 0 1em 0;
}

.__btn__challenge {
  position: relative;
  max-width: 350px;
  min-width: 350px;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main__left,
.main__right {
  width: 100%;
  height: 100%;
}

.main__left {
  flex: 2;
}

.main__right {
  flex: 0.8;
}

.main__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--mb-2);
}

.main__left__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: var(--mb-6);
}

.graphe__right,
.graphe__left {
  width: 50%;
  height: 600px;
  background: #fafafa;
  border-radius: 10px;
}

.graphe__left__header {
  margin-left: var(--mb-2);
}

.__garphe__right__header {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.4em;
  margin-top: 1.5em;
}

.custom__select {
  margin-right: 10px;
}

.select {
  position: relative;
  height: 45px;
  width: 180px;
  border-radius: 10px;
  padding-top: 7px;
  border: 1px solid var(--text-color);
  display: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.__first__card,
.__second__card {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background: #fafafa;
  border: 2px solid #dbdbdb;
}

.__first__card h4 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: var(--text-color);
  margin-top: var(--mb-1);
}

.__second__card {
  margin-top: var(--mb-3);
  height: 320px;
}

.__second__card span {
  text-transform: uppercase;
  font-size: 1.1em;
}

.__second__card .code {
  width: 200px;
  height: 50px;
  background: #fff;
  border: 1px dashed var(--text-color);
  display: flex;
  margin: var(--mb-4) auto;
  border-radius: 50px;
}

.code span {
  margin: auto;
  font-size: 1.6em;
  font-weight: 500;
}

.__content__btn button:first-child {
  margin-bottom: var(--mb-2);
}

.__btn {
  min-width: 200px !important;
  max-width: 200px !important;
  padding-top: 10px;
  height: 45px !important;
}

.array__conent {
  width: 100%;
  margin-top: var(--mb-4);
  height: 700px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-2) var(--mb-3);
}

/* Array */

.array__conent__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--mb-2);
}

.filed__search {
  width: 320px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
  margin-top: var(--mb-1);
}

.array__conent__header * input,
.array__conent__header * button {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

.array__conent__header * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.array__conent__header * input::placeholder {
  color: var(--text-color);
}

.array__conent__header * button {
  background: #fff !important;
}

.__array {
  width: 100% !important;
  height: 500px;
  margin-top: var(--mb-6);
  background: #fff;
}

/* Drawer */

.drawer {
  position: relative;
  width: 100%;
  margin-top: var(--mb-6);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.field__text,
.field div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.field div {
  padding-top: 10px !important;
}

.drawer___header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.drawer___header h3 {
  font-family: var(--title-font);
  font-size: 3.2em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
}

.__first_back {
  position: absolute;
  top: 6%;
  left: 5%;
  cursor: pointer;
}

.filter .select div {
  height: 45px !important;
  width: 250px !important;
}

.__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-4);
  gap: 15px;
}

.faq {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5em;
  color: #707070;
}

.content {
  margin-bottom: var(--mb-6);
}

.__overflow__Y {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
}

/*  */
.dropdown {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.__down {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.__down *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.__down {
  color: var(--text-color);
}
