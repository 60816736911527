.container {
  position: relative;
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 2%;
}

@media only screen and (max-width: 767px) {
  .container {
    padding: 0 4%;
    margin: 20px auto;
  }
}