.title {
  font-family: "CartoonFont";
  font-size: var(--biggest-font-size);
  color: var(--title-color);
  text-transform: uppercase;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .title {
    font-size: 1.7em;
  }
}
