.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.ourHistoryBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.mainBox {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 100px;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 40px;
    background: rgb(255, 248, 242);
    text-align: left;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -5px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.subtitle {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
    text-align: left;
}

.secondaryBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
}

.studentHistoryImage {
    height: 627px;
    width: 620px;
}

.directorImageBox {
    height: 186px;
    width: 186px;
    background: white;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
    position: relative;
    left: -6%;
    top: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.directorImage {
    height: 166px;
}

.directorMessageBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 420px;
    width: 620px;
    background: white;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    position: relative;
    top: 170px;
    left: -19%;
}

.directorName {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 25px;
    color: rgb(8, 7, 7);
    line-height: 40px;
    position: relative;
    left: 37%;
}

.directorFunction {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 25px;
    color: rgb(138, 176, 177);
    line-height: 35px;
    position: relative;
    left: 37%;
}

.directorMessage {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 20px;
}

.quote {
    font-size: 280px;
    color: rgba(162, 159, 159, 0.08);
    font-family: Helvetica;
    line-height: 160px;
    font-weight: normal;
    position: relative;
    left: 65%;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        display: flex;
        flex-direction: column;
        height: 1042px;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 100px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 40px;
        background: rgb(255, 248, 242);
        text-align: left;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -5px;
        width: 25px;
        bottom: 30px;
        z-index: 0;
    }

    .subtitle {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
    }

    .studentHistoryImage {
        height: auto;
        width: 90%;
        position: relative;
        top: -40px;
    }

    .secondaryBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
    }

    .directorImageBox {
        height: 80px;
        width: 80px;
        background: white;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
        position: relative;
        left: 4%;
        top: 0px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .directorImage {
        height: 65px;
    }

    .directorMessageBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 320px;
        width: 100%;
        background: white;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 15px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 15px;
        position: relative;
        top: -50px;
        left: 0%;
    }

    .directorName {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 13px;
        color: rgb(8, 7, 7);
        line-height: 20px;
        position: relative;
        left: 37%;
    }

    .directorFunction {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 11px;
        color: rgb(138, 176, 177);
        line-height: 10px;
        position: relative;
        left: 37%;
    }

    .directorMessage {
        font-size: 11px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 20px;
    }

    .quote {
        font-size: 160px;
        color: rgba(162, 159, 159, 0.08);
        font-family: Helvetica;
        line-height: 140px;
        font-weight: normal;
        position: relative;
        left: 65%;
        position: relative;
        bottom: 30px;
    }

}