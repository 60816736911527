.row {
  width: 100%;
  display: flex;
  align-items: center;
}

.skillbar {
  width: 100%;
  height: 5px;
  border-radius: 50px;
  border: 1px solid #ccc;
  min-width: 250px;
}

.skillbar .bar {
  display: block;
  width: 0%;
  background: var(--first-color);
  height: 3px;
  border-radius: 50px;
  transition: 1s ease-out;
}

.count {
  margin-left: 1em;
}

.count span {
  font-size: 1.3em;
}

@media only screen and (max-width: 500px) {
  .skillbar {
    min-width: 175px;
    height: initial;
  }

  .count span {
    font-size: 1.1em;
  }
}

@media only screen and (min-width: 500px) {}

@media only screen and (min-width: 600px) {}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}