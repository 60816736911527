.about {
  width: 100%;
  margin-bottom: 80px;
  position: relative;
  top: 7px;
  z-index: 1;
}

.tabs {
  width: 100%;
  display: flex;
  gap: 1em;
}

.tab {
  width: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1%;
  background: #FAFAFA;
  border: 1px solid #F0F0F0;
  text-transform: uppercase;
  font-family: var(--title-font);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

.tab img {
  position: relative;
  width: 30px;
  left: -2em;
}

.redirect .tab span {
  font-size: 1.3em;
  color: var(--text-color);
}

.tab:hover {
  background: var(--first-color);
}

.tab:hover span {
  color: #fff;
}

.active {
  background: var(--first-color);
}

.tab.active span {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .tabs {
    gap: 0.5em;
    overflow: scroll;
    background: white;
  }

  .tab span {
    font-size: 1em;
  }

  .tab img {
    width: 22px;
    left: -0.5em;
  }

}

/* New Css Styles */

.tabTitleBox {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.tabTitleBoxActive {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.informationBox {
  background: #FFF;
  width: 100%;
  height: auto;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  z-index: 1;
}

@media only screen and (max-width: 1050px) {
  .tabTitleBox {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }

  .tabTitleBoxActive {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }
}