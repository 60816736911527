.title {
  margin-bottom: var(--mb-1);
}

.choice__profile {
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 2em;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-2);
}

.choice__profile * a {
  display: block;
  text-align: center;
  color: var(--text-color);
  /* margin-top: var(--mb-2); */
}

.prof {
  width: 200px;
  height: 200px;
  border: 1px solid #70707054;
  border-radius: 100%;
}

.imageTypeProfile {
  width: 90px;
}

.imageTypeProfileParent {
  width: 70px;
  height: 70px;
}

.prof:hover {
  border: 1px solid var(--first-color);
}

@media only screen and (max-width: 767px) {
  .choice__profile {
    width: 100%;
    margin-top: var(--mb-2);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    grid-gap: 10px;
  }

  .prof {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .prof img {
    width: 100%;
    margin: auto;
  }

  .imageTypeProfile {
    width: 138px !important;
  }

  .imageTypeProfileParent {
    width: 150px !important;
  }
}