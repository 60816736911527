.selectInput {
    color: #707070;
    border-radius: 8px;
    border: 1px solid #707070;
    font-style: italic !important;
    width: 100%;
}

.placeholderStyle {
    color: #C0C0C0;
    font-size: 14px;
}

.card {
    position: relative;
    overflow: hidden;
    width: 100% !important;
    height: 300px !important;
    padding: 15px;
    background-size: contain;
    background: #fafafa;
    box-shadow: 3px 2px 0px 2px #cfd0d2;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.card:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 70px;
    background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.card::after {
    content: "";
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 1px;
    overflow: hidden;
    width: 50px;
    background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
    background-size: 30px 30px;
    box-sizing: border-box;
}

.filedSearch {
    width: 300px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 2px;
    margin-top: 20px;
}

.studentCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 12px;
    border: 1px solid #C0C0C0;
    cursor: pointer;
    background: #FFF;
}

.studentSelectedCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 12px;
    border: 1px solid #C0C0C0;
    cursor: pointer;
    background: #5a8484;
}

.studentCard:hover {
    background: #cbf2eb;
}

.inoutField {
    height: 45px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
}

.submitButton {
    width: 120px !important;
    height: 40px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 16px !important;
    text-transform: none;
    text-align: center;
    background: var(--first-color) !important;
    color: #fff !important;
    padding-top: 0px !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
    -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

@media only screen and (max-width: 767px) {
    .placeholderStyle {
        color: #C0C0C0;
        font-size: 12px;
    }
}

.levels div:last-child {
    display: none;
}