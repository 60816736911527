.card {
  width: 100%;
  position: relative;
  margin-bottom: 2em;
}

.avatar {
  width: 35px;
  height: 35px;
  background: #fff;

  border-radius: 50%;
}

.student {
  background: #f2f2f2;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  text-transform: uppercase;
}

.tagAvis {
  background: #f2f2f2;
  padding: 10px 15px;
  border-radius: 20px;
  color: var(--title-color);
}

.tagAvis span strong {
  font-weight: 500;
}

.description {
  margin-top: 1em;
}

.button {
  border: none;
  min-width: 150px;
  padding: 0.3em 1em;
  text-transform: uppercase;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
}

.ignorer {
  background: #ff4846;
  box-shadow: 3px 6px 1px -1px #b40502;
  /* -webkit-box-shadow: 3px 6px 1px -1px #059c67;
   -moz-box-shadow: 3px 6px 1px -1px #059c67; */
}

.accepter {
  background: #10e29b;
  box-shadow: 3px 6px 1px -1px #059c67;
  -webkit-box-shadow: 3px 6px 1px -1px #059c67;
  -moz-box-shadow: 3px 6px 1px -1px #059c67;
}
