.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0%;
}

.advantageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mainBox {
    display: flex;
    flex-direction: column;
    width: 120%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 10px;
    padding-left: 0%;
    padding-right: 0%;
    background: white;
    z-index: 1;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 60px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -25.5%;
    width: 35px;
    bottom: 120px;
    z-index: 0;
}

.advantageBox {
    min-width: 304px;
    height: 304px;
    background: white;
    border: 0.5px solid #707070;
    border-radius: 43px;
    opacity: 1;
    margin-right: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
}

.advantageBox:hover {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
}

.sildeBox {
    height: 383px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    position: relative;
    top: -120px;
    padding-left: 10%;
    padding-right: 20px;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sildeBox::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 10px;
        padding-left: 0%;
        padding-right: 0%;
        background: white;
        z-index: 1;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -17%;
        width: 25px;
        bottom: 90px;
        z-index: 0;
    }

    .advantageBox {
        min-width: 340px;
        height: 304px;
        background: white;
        border: none;
        border-radius: 43px;
        opacity: 1;
        margin-right: 40px;
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    }

    .advantageBox:hover {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    }

    .sildeBox {
        height: 383px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        position: relative;
        top: -20px;
        padding-left: 14%;
        padding-right: 20px;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

}