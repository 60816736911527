.Quiz {
  margin-bottom: 3em;
}

.Card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 600px;
  height: 420px;

  background-color: var(--fafafa);
  border-radius: 10px;
}

.mainX {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.apercuQuiz {
  font-size: 16px;
}

.absolute {
  position: absolute !important;
}

.relative {
  width: 100%;
  position: relative !important;
}

.question {
  font-size: 17px;
  font-weight: 500;
}

.quiz {
  margin: 5em 0;
}

.quiz__content {
  width: 100%;
  background: #fafafa;
  padding: 5%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.center {
  flex: 2;
  text-align: center;
}

.center span {
  display: block;
  font-size: 1.2em;
}

.main {
  margin-top: 2em;
}

.main h3 {
  font-size: 1.3em;
  margin-left: 2%;
  color: var(--text-color);
  margin-bottom: var(--mb-6);
  /* #CBCACD */
}

.card {
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  border: 1px solid #cbcacd;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-3);
}

.__label__content {
  position: relative;
  width: 100%;
}

.__black {
  position: absolute;
  right: 5%;
  top: -10px;
}

.label {
  position: absolute;
  width: 30px;
  font-size: 16px;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 70px;
  white-space: nowrap;
  color: #555;
}
