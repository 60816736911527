.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em 0;
}

.chapitre__content {
  width: 100%;
  background: #fafafa;
  padding: 3em;
}

.subtitle {
  text-transform: uppercase;
  position: relative;
  top: 2px;
}

.__title {
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-4);
}

.card__content {
  margin-top: 2em;
}

.__links {
  color: var(--text-color);
}

.__content__level__matier img {
  width: 80px;
}

.__content__level__matier {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--mb-3);
  font-family: var(--title-font);
  font-size: 1.7em;
  line-height: 28px;
  text-transform: uppercase;
  font-weight: 500;
}

.left,
.__back__level {
  flex: 1;
}

.content__left {
  flex: 2;
}

.__matier__bottom {
  color: #333333;
}

.content__left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.__back__level {
  font-size: 15px;
  color: #333333;
  text-align: right;
}

.__back__level:hover {
  color: var(--first-color);
  cursor: pointer;
}

.chapterDocsBox {
  align-items: center;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.docsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.docsButton {
  width: 30px;
  font-size: 18px;
  margin-left: 10px;
}

@media only screen and (max-width: 767px) {
  .chapterDocsBox {
    align-items: center;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .docsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .docsButton {
    margin-left: 0px;
    margin: 10px auto;
  }
}

@media only screen and (max-width: 500px) {
  .chapitre__content {
    padding: 1em;
    min-height: initial !important;
  }

  .center {
    display: none;
  }

  .__content__level__matier {
    width: 100%;
    align-items: flex-end;
    margin-bottom: var(--mb-2);
    font-size: 1em;
    line-height: 20px;
  }

  .__back__level {
    position: relative;
    top: -9px;
    font-size: 13px;
  }

  .__content__level__matier img {
    width: 55px;
  }
}
