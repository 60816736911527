.footerFooter {
  width: 100%;
  height: 100%;
  min-height: 150px;
  background: rgb(47, 47, 47);
  margin-top: 0em;
  z-index: 99 !important;
}

.row {
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--mb-3);
  padding-bottom: var(--mb-3);
  display: grid;
  row-gap: 1rem;
  gap: 0;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  text-transform: uppercase;
  align-items: center;
}

.row a {
  color: #fff;
  text-align: center;
  font-size: 12px;
  width: 100%;
  min-width: 100px;
}

.socials__media {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.socials__media img {
  cursor: pointer;
}

.topic-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.topic-button.selected {
  background-color: #1890ff;
  color: white;
}

.footerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  text-align: center;
  z-index: 0;
}

.footerBoxTop {
  height: 398px;
  width: 100%;
  background: rgb(47, 47, 47);
  padding-left: 0%;
  padding-right: 0%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.footerBoxBottom {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 0%;
  padding-right: 0%;
  background: rgb(47, 47, 47);
  align-items: flex-start;
  justify-content: flex-start;
}

.footerBoxTopMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
}

.logoContainer {
  height: 90px;
  width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.logoBox {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-right: 10px;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logoTitle {
  font-size: 28px;
  color: rgb(255, 255, 255);
  font-family: "Margin DEMO";
  line-height: 28px;
  font-weight: normal;
  margin-top: 0px;
}

.footerMessage {
  font-size: 1.2em;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 30px;
  font-weight: normal;
  margin-top: 30px;
}

.followUsTitle {
  font-size: 1.3em;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 30px;
  font-weight: normal;
  margin-top: 30px;
}

.socialBox {
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  align-items: flex-start;
  justify-content: flex-start;
}

.menuTitle {
  font-size: 1.4em;
  color: rgb(255, 255, 255);
  font-family: "Poppins";
  line-height: 40px;
  font-weight: normal;
  margin-top: 10px;
}

.menuBox {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: rgb(204, 204, 204);
  font-size: 1em;
  font-family: "Poppins";
  line-height: 34px;
  font-weight: normal;
}

.formBox {
  height: auto;
  width: 100%;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50px;
  margin-top: 10px;
}

.newslettersForm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.newslettersFormEmail {
  border: none;
  padding-left: 20px;
  width: 60%;
  background: transparent;
  border-radius: 50px;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
}

.newslettersButton {
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  bottom: 0px;
  right: -1px;
  height: 50px;
  border: none;
  padding: 1em;
  background: var(--first-color);
  font-size: 1em;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
  color: #fff;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 0;
  -o-border-radius: 50px;
  width: auto;
  display: inline-flex;
  align-items: center;
}

.footerSeparator {
  height: 2px;
  width: 100%;
  background: rgb(133, 133, 132);
}

.footerBottomBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footerCopyrights {
  font-size: 15px;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 25px;
  font-weight: normal;
  margin-top: 20px;
}

.copyrightsSeparator {
  height: 15px;
  width: 1px;
  background: rgb(204, 204, 204);
  position: relative;
  top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.legalMention {
  font-size: 15px;
  color: rgb(204, 204, 204);
  font-family: "Poppins";
  line-height: 25px;
  font-weight: normal;
  margin-top: 20px;
}

@media only screen and (max-width: 680px) {

  .footerBoxTop {
    height: 598px;
    width: 100%;
    background: rgb(47, 47, 47);
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footerBoxBottom {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    background: rgb(47, 47, 47);
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footerBoxTopMain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: left;
  }

  .logoContainer {
    height: 90px;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .logoBox {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logoTitle {
    font-size: 19px;
    color: rgb(255, 255, 255);
    font-family: "Margin DEMO";
    line-height: 20px;
    font-weight: normal;
    margin-top: 0px;
  }

  .footerMessage {
    font-size: 13px;
    color: #CCCCCC;
    font-family: "Poppins";
    line-height: 20px;
    font-weight: normal;
    margin-top: 0px;
  }

  .followUsTitle {
    font-size: 16px;
    color: rgb(204, 204, 204);
    font-family: "Poppins";
    line-height: 25px;
    font-weight: normal;
    margin-top: 10px;
  }

  .socialBox {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .menuTitle {
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-family: "Poppins";
    line-height: 20px;
    font-weight: normal;
    margin-top: 0px;
  }

  .menuBox {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    color: rgb(204, 204, 204);
    font-size: 13px;
    font-family: "Poppins";
    line-height: 20px;
    font-weight: normal;
    width: 100%;
    justify-content: space-between;
  }

  .formBox {
    height: 40px;
    width: 90%;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50px;
    margin-top: 10px;
    font-size: 8px;
  }

  .newslettersForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 8px;
  }

  .newslettersFormEmail {
    border: none;
    padding: 20px;
    width: 55%;
    background: transparent;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    font-size: 8px;
    height: 40px;
  }

  .newslettersButton {
    margin-bottom: 0px;
    cursor: pointer;
    position: relative;
    bottom: 1px;
    right: -1px;
    height: 40px;
    border: none;
    padding: .5em 2.5em;
    background: var(--first-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
    color: #fff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 0;
    -o-border-radius: 50px;
  }

  .footerSeparator {
    height: 2px;
    width: 100%;
    background: rgb(133, 133, 132);
  }

  .footerBottomBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .footerCopyrights {
    font-size: 15px;
    color: rgb(204, 204, 204);
    font-family: "Poppins";
    line-height: 25px;
    font-weight: normal;
    margin-top: 20px;
  }

  .copyrightsSeparator {
    height: 15px;
    width: 1px;
    background: rgb(204, 204, 204);
    position: relative;
    top: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .legalMention {
    font-size: 15px;
    color: rgb(204, 204, 204);
    font-family: "Poppins";
    line-height: 25px;
    font-weight: normal;
    margin-top: 20px;
  }

  .newslettersFormEmail::placeholder {
    font-size: 10px;
    position: relative;
    bottom: 5px;
    color: white;
  }

}

@media only screen and (max-width: 1050px) {
  .footerFooter {
    display: none;
  }
}