.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 3em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.justify__center {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.main .annonces {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

/* Drawer */
.__drawer {
  position: relative;
}

.__teacher__MAA {
  position: absolute;
  width: 160px;
  height: 480px;
  top: 19%;
  left: 0%;
  background: url("../assets/images/teacherMAA.svg") center center no-repeat;
  background-size: contain;
  z-index: 1;
}

.__teacher__MAA__step_two {
  position: absolute;
  width: 160px;
  height: 450px;
  top: 16%;
  left: 0%;
  background: url("../assets/images/teacherStepTwo.svg") center center no-repeat;
  background-size: contain;
  z-index: 1;
}

.__drawer__header {
  margin: var(--mb-1) 0 0 0;
  text-align: center;
}

.__drawer__header {
  font-family: var(--title-font);
  font-size: 2em;
  text-transform: uppercase;
}

.drawer__form__content {
  width: 100%;
  max-width: 600px;
  margin: 1em auto;
}

.__form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  padding: 2em 2% !important;
  min-height: 450px;
  max-width: 80%;
  border-radius: 10px;
  overflow-y: scroll;
  margin-left: auto;
  margin-right: auto;
}

.__form>div {
  width: 13.8%;
}

.__form>div button {
  width: 100%;
  border: unset;
  height: 38px;
  font-weight: bold;
}

.matiers {
  width: 100%;
  margin: 0;
  margin-top: 5px;
}

.add___ {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-2);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: var(--first-color);
  box-shadow: 3px -1px 1px -1px rgba(90, 132, 132, 1);
}

.__final__step_form {
  width: 100%;
  max-width: 700px;
  padding: 2em !important;
  background: #fafafa;
  border-radius: 10px;
  margin: 1em auto;
}

.__form .__available {
  font-size: 1.1em;
  color: #80b1b2;
  text-align: center;
}

.button__submit {
  margin-top: var(--mb-2);
}

.select div {
  height: 45px !important;
  width: 260px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.select div span {
  padding-top: 7px !important;
  text-align: left;
}

.__input_price,
.__text__area {
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.__euro__icon {
  position: absolute;
  width: 30px;
  right: 0;
  top: 8px;
}

.__btn__planing {
  height: 40px !important;
  min-width: 200px;
  max-width: 200px;
}

.select__input>div {
  height: 45px;
  min-width: 260px;
  border-radius: 15px !important;
  border: 1px solid var(--text-color) !important;
}

.select__input * input {
  height: 32px !important;
}

.__btn_creneau {
  min-width: 300px;
  height: 40px !important;
}

/* .__custom__btn * button span {
  border: unset !important;
  background: #000 ;
} */

.__matiers {
  position: relative;
  top: -13px;
}

.stepTwo {
  width: 100%;
  background: #fafafa;
  padding: 2em;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.delete___ {
  margin-top: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.delete___:hover {
  background: red;

  cursor: pointer;
}

.delete___:hover * {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .__teacher__MAA {
    display: none;
  }

  .__teacher__MAA__step_two {
    display: none;
  }

  .__drawer__header {
    font-family: var(--title-font);
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: flex-start;
  }

  .__form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #fafafa;
    padding: 2em 2% !important;
    min-height: 450px;
    max-width: 100%;
    border-radius: 10px;
    overflow-y: scroll;
    margin-left: auto;
    margin-right: auto;
  }

  .drawer__form__content {
    width: 100%;
    max-width: 100%;
    margin: 1em auto;
  }

}

/* New Css Style */

.drawerTitle {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.newMoveButton {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.newDeleteButton {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #e04941 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #e8380c !important;
  -webkit-box-shadow: 2px 4px 1px -1px #e8380c !important;
  -moz-box-shadow: 2px 4px 1px -1px #e8380c !important;
  margin-right: 10px !important;
}

.newMoveButton:hover {
  transform: scale(1.03) !important;
  background-color: #80b1b2;
  color: #fff;
}

.formBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  padding-top: 2em;
  padding-bottom: 2em;
  overflow-y: scroll;
  margin-left: 20px;
  margin-right: 20px;
}

.formDayBox {
  height: 700px;
  width: 160px;
  background: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  padding: 5px;
}

.dayBox {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EAEAEA;
  text-align: center;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #575757;
  border-radius: 12px;
  margin-bottom: 30px;
}

.addButton {
  width: 28px;
  height: 28px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-2);
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: var(--first-color);
  box-shadow: 1px -1px 1px -1px rgba(90, 132, 132, 1);
}

.detailsBox {
  height: 100px;
  width: 100%;
  background: #FFF;
  box-shadow: 2px 2px 10px #A2A2A239;
  border-radius: 16px;
  margin-top: 10px;
  margin-bottom: 1em;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.typeBox {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #8AB0B1;
}

.typeIconBox {
  height: 25px;
  width: 25px;
  background: #DAE6E7;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.levelsBox {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  text-align: center;
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 500;
  color: #868686;
}

.timeRangeDeleteBox {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.timeRangeBox {
  height: 25px;
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #F7F7F7;
  padding: 7px;
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  border-radius: 24px;
}

.timeRangeBox span {
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 600;
  color: #707070;
  margin-left: 3px;
  margin-right: 3px;
}

@media only screen and (max-width: 1050px) {
  .drawerTitle {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .formBox {
    height: 65vh !important;
    width: 400% !important;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 2%;
    padding-top: 2em;
    padding-bottom: 2em;
    overflow-y: scroll !important;
    margin-left: 20px;
    margin-right: 20px;
  }

  .formDayBox {
    height: 100% !important;
    max-height: 100% !important;
    width: 200px !important;
    background: #FAFAFA;
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    opacity: 1;
    display: block !important;
    margin-right: 20px;
    padding: 5px;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .dayBox {
    height: 30px !important;
    width: 90% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: #EAEAEA;
    text-align: center !important;
    font-size: 0.85em !important;
    font-family: "Poppins";
    font-weight: 600;
    color: #575757;
    border-radius: 8px;
    margin: 10px auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .newMoveButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin: 0px auto !important;
  }

  .newDeleteButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #e04941 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #e8380c !important;
    -webkit-box-shadow: 2px 4px 1px -1px #e8380c !important;
    -moz-box-shadow: 2px 4px 1px -1px #e8380c !important;
    margin-top: 10px !important;
    margin-right: 0px !important;
  }

  .detailsBox {
    height: 100px;
    width: 85%;
    background: #FFF;
    box-shadow: 2px 2px 10px #A2A2A239;
    border-radius: 16px;
    margin: 1em auto;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .typeBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
  }

  .typeIconBox {
    height: 20px;
    width: 20px;
    background: #DAE6E7;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .levelsBox {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
    text-align: center;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #868686;
  }

  .timeRangeDeleteBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .timeRangeBox {
    height: 20px;
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
    padding: 2px;
    font-size: 0.7em !important;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    border-radius: 24px;
  }

  .timeRangeBox span {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    color: #707070;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}