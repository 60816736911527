.footer {
  width: 100%;
  height: 100%;
  /* background-color: #FAFAFA; */
  /* margin-top: var(--mb-6); */
  /* padding-top: 3em; */
}

.title__footer {
  text-transform: uppercase;
  text-align: center;
  font-size: var(--biggest-font-size);
}
