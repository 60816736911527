.card {
  position: relative;
  padding-top: 3em;
}

.card__content {
  width: 100%;
  height: 100%;
  max-height: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.card {
  font-size: var(--h3-font-size);
  color: #707070;
  font-weight: 500;
  opacity: 1; 
  transform: translateY(0px);
}

.card img {
  cursor: pointer;
}

.code {
  width: 150px;
  height: 32px;
  border: #707070 1px dashed;
  text-align: center;
  border-radius: 50px;
  background: #fff;
  display: flex;
}

.code span {
  font-size: var(--smaller-font-size);
  margin: auto;
}

.select {
  width: 50% !important;
}

.select div {
  height: 30px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
}

/* .select .ant-select-dropdown {
  border-radius: 10px !important;
} */

/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: red !important;
} */

.states {
  position: absolute;
  top: var(--mb-3);
  right: var(--mb-2);
}

.states span {
  position: relative;
  top: -3px;
  padding-left: 5px;
  color: var(--first-color);
  font-weight: 600;
}

/*  */
.dropdown {
  width: 130px !important;
  height: 28px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.__down {
  border-radius: 10px !important;
  background: #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.__down *:hover {
  color: var(--first-color);
  /* background: #fff; */
}

.__down {
  color: var(--text-color);
}
