.add-primary-btn {
  min-height: 150px;
  min-width: 100%;
  height: 100% !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.__msg__not_cours {
  width: 100%;
  text-align: center;
  margin-top: 2em;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left {
  display: flex;
  flex-direction: row;
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.main {
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  gap: 1.3em;
  flex-wrap: wrap;
}

.card {
  width: 300px;
  height: 300px;
}

/*  */

.__row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
}

.__first_drawer {
  position: relative;
  width: 100%;
  padding: 0 50px;
}

.drawer {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content {
  width: 100%;
}

.drawer__content * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.drawer__submit {
  margin-top: var(--mb-2);
}

.field__text,
.field div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.field div {
  padding-top: 10px !important;
}

.filter {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  /* padding: 0 var(--mb-1); */
  margin-bottom: var(--mb-3);
}

.filed__search {
  width: 40%;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.filter__right * input,
.filter__right * button {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
}

.filter__right * input:focus {
  box-shadow: unset !important;
  border: unset !important;
  outline: none !important;
}

.filter__right * input::placeholder {
  color: var(--text-color);
}

.filter__right * button {
  background: #fff !important;
}

.filter__left {
  flex: 1;
}

.filter__right {
  flex: 1.5;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.custom__select {
  margin-right: 10px;
}

.select {
  position: relative;
  height: 45px;
  width: 200px;
  padding-top: 7px;
  text-align: left;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.card {
  width: 300px;
  height: 300px;
}

/* Modal */

.__success__modal_container {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__card {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal__card .modal__card__title {
  font-family: var(--title-font);
  font-size: 1.5em;
  text-transform: uppercase;
}

.__matier {
  width: 200px;
  height: 40px;
  margin: var(--mb-3) 0;
  border-radius: 10px;
  background: #d5d5d5;
  font-size: 0.8rem;
}

.__icon_matiere {
  position: relative;
  top: 2px;
  left: -4px;
}

.__modal__img__success {
  margin-bottom: var(--mb-4);
}

.__code__access__title {
  text-transform: uppercase;
  font-size: 1em;
  color: #707070;
  margin-bottom: 10px;
  font-weight: 500;
}

.__code {
  width: 250px;
  height: 45px;
  border: 1px dashed #707070;
  display: flex;
  border-radius: 50px;
  color: #707070;
  margin-bottom: var(--mb-6);
}

.__code span {
  margin: auto;
  font-size: 1.5em;
  font-weight: 600;
}

.modal__card button:last-child {
  margin-left: 10px;
}

.__btn {
  max-width: 200px;
  min-width: 200px;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {

  .dashboardUserMessage {
    font-size: 1.2em;
    color: black;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .center {
    width: 100%;
    flex: 2;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }

  .__btn {
    max-width: 150px;
    min-width: 150px;
  }

  .modal__card .modal__card__title {
    font-family: var(--title-font);
    font-size: 1em;
    text-transform: uppercase;
  }

}

/* New Css Styles */

.tabTitleBox {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #CECECE29;
  border: 2px solid #BFBCBC82;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.tabTitleBoxActive {
  height: 40px;
  background: #FFF;
  box-shadow: 6px 8px 14px #7FB1B238;
  border: 2px solid #7FB1B2;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.informationBox {
  width: 100%;
  height: 480px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  overflow-y: scroll;
  scrollbar-width: thin;
  background: #FFF;
}

.classesBox {
  width: 100%;
  min-height: 640px;
  height: auto;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #FFF;
}

.historyBox {
  width: 100%;
  height: 640px;
  border: 1px solid #BFBCBC;
  border-radius: 18px;
  opacity: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.classeCard {
  position: relative;
  width: 100%;
  height: 70px;
  background: #fff;
  box-shadow: 0px 3px 10px #BCBCBC29;
  border: 1px solid #BFBCBC;
  padding: 10px 0px 10px 7px;
  border-radius: 18px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avatarNameBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  width: 300px;
  object-fit: contain;
}

.detailsBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 60%;
  margin-right: 20px;
}

.timeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 30px;
}

.times {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.time {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
}

.timeDescription {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #868686;
}

.amountBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  color: #707070;
}

.priceBox {
  width: auto;
  height: 25px;
  background: #FFF;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: #7FB1B2;
  text-align: center;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 15px;
}

.meetButton {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.newButton {
  width: auto !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4bb980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
  -moz-box-shadow: 5px 7px 1px -1px #4bb980;
  padding-left: 20px;
  padding-right: 20px;
}

.meetButton:hover {
  transform: scale(1.03) !important;
  background-color: #7fb1b2;
  color: #fff;
}

.emptyClass {
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  font-family: Rubik;
  color: #B9B9B9;
  text-align: center;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dateBox {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 60px;
}

.horizontalLine {
  height: 1px;
  width: 70%;
  background: #D8D8D8;
}

.todayBox {
  width: 70px;
  height: 23px;
  background: #EAFFF4;
  border: 1px solid #60CD95;
  border-radius: 8px;
  color: #60CD95;
  opacity: 1;
  font-size: 10px;
  font-family: rubik;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newClassBox {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.searchBox {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selectBox {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.separation {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.inputBox {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.filedSearch {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.cardDisplay {
  display: flex;
  flex-direction: column;
}

.cardClassBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 1050px) {
  .headerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .titleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .titlePictureBox {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonBox {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .calendrier {
    width: 100%;
    margin: 2em 0;
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 16px !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 60vh !important;
  }

  .newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4bb980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4bb980;
    -moz-box-shadow: 5px 7px 1px -1px #4bb980;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .calendar {
    height: 60vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 16px !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FAFAFA;
  }

  .historyBox {
    height: 73vh !important;
    width: 100% !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    padding: 2%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    background: #FFF;
    margin: 0px auto;
    border: 1px solid #ccc;
    border-radius: 16px !important;
  }

  .dateBox {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .horizontalLine {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .todayBox {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .emptyClass {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    font-weight: 500;
    font-family: Rubik;
    color: #B9B9B9;
    text-align: center;
  }

  .cardDisplay {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .singleCourse {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns: none;
  }

  .classeCard {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .historyClasseCard {
    position: relative;
    width: 100% !important;
    height: auto !important;
    background: #fff;
    box-shadow: 0px 3px 10px #BCBCBC29;
    border: 1px solid #BFBCBC;
    padding: 7px;
    border-radius: 18px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
  }

  .cardClassBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .avatarNameBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    width: 90%;
    object-fit: contain;
  }

  .detailsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .historyDetailsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-right: 0px;
  }

  .timeBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
  }

  .times {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .time {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
  }

  .timeDescription {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 400;
    color: #868686;
  }

  .amountBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    font-size: 0.7em;
    margin-top: 5px;
  }

  .priceBox {
    width: auto !important;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .historyPriceBox {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    opacity: 1;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.8em !important;
    font-weight: 600;
    color: #7FB1B2;
    text-align: center;
    padding-top: 2px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0px;
  }

  .meetButton {
    width: auto !important;
    height: 25px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
  }

  .meetButton:hover {
    transform: scale(1.03) !important;
    background-color: #7fb1b2;
    color: #fff;
  }

  .__first_drawer {
    position: relative;
    width: 100%;
    padding: 0px;
  }

  .tabTitleBox {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #CECECE29;
    border: 2px solid #BFBCBC82;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }

  .tabTitleBoxActive {
    height: 35px;
    background: #FFF;
    box-shadow: 6px 8px 14px #7FB1B238;
    border: 2px solid #7FB1B2;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.8em !important;
  }
}