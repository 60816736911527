.matiers {
  width: auto;
  height: 32px;
  padding: 0 20px;
  background: #D5D5D5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
  margin: var(--mb-1);
}

.center {
  margin: 0 5px;
}

.__cours *:last-child span {
  display: none;
}

.right span *:last-child span {
  display: none;
}