.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 8em 0 1em 0;
}

.__btn__challenge {
  position: relative;
  max-width: 350px;
  min-width: 350px;
}
.__btn__challengeX {
  max-width: 350px;
  min-width: 350px;
  color: rgb(168 165 165) !important;
  background: #d5d5d5 !important;
  font-weight: 500 !important;
  font-size: 0.958rem !important;
  box-shadow: 3px 6px 1px -1px rgb(168 165 165) !important;
}
.main {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 4em;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main__left,
.main__right {
  width: 100%;
  height: 100%;
}

.main__left {
  flex: 2;
}

.main__right {
  flex: 0.8;
}

.main__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: var(--mb-2);
}
.main__left__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: var(--mb-6);
}

.graphe__right,
.graphe__left {
  height: 400px;
  gap: 20px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.graphe__left__header {
  margin-left: var(--mb-2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.__garphe__right__header {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.4em;
  margin-top: 1.5em;
}

.select {
  margin-top: 1.5em !important;
}

.custom__select {
  margin-right: 10px;
}

.select div {
  position: relative;
  height: 45px !important;
  width: 180px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.select div span {
  padding-top: 7px !important;
  text-align: left;
}

.__first__card,
.__second__card {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
  background: #fafafa;
  border: 2px solid #dbdbdb;
}

.__first__card h4 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: var(--text-color);
  margin-top: var(--mb-1);
}

.__second__card {
  margin-top: var(--mb-3);
  height: 320px;
}

.__second__card span {
  text-transform: uppercase;
  font-size: 1.1em;
}

.__second__card .code {
  width: 200px;
  height: 50px;
  background: #fff;
  border: 1px dashed var(--text-color);
  display: flex;
  margin: var(--mb-4) auto;
  border-radius: 50px;
}

.code span {
  margin: auto;
  font-size: 1.6em;
  font-weight: 500;
}

.__content__btn button:first-child {
  margin-bottom: var(--mb-2);
}

.__btn {
  min-width: 200px !important;
  max-width: 200px !important;
  padding-top: 10px;
  height: 45px !important;
}

.array__conent {
  width: 100%;
  margin-top: var(--mb-4);
  max-height: 700px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-2) var(--mb-3);
}

/* Array */

.array__conent__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: var(--mb-2);
}

.array__conent__header * input::placeholder {
  color: var(--text-color);
}

.__array {
  width: 100% !important;
  max-height: 500px;
  margin-top: var(--mb-6);
  background: #fff;
}

/* Drawer */

.drawer {
  position: relative;
  width: 100%;
  margin-top: var(--mb-6);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.drawer__content * label {
  position: relative;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  color: #707070;
  top: 10px;
  font-size: 1em;
  text-align: left;
}

.field__text,
.field div {
  height: 50px !important;
  border: 1px solid var(--text-color) !important;
  border-radius: 10px !important;
}

.field div {
  padding-top: 10px !important;
}

.drawer___header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--mb-6);
}

.drawer___header h3 {
  font-family: var(--title-font);
  font-size: 3.2em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
}

.__first_back {
  position: absolute;
  top: 6%;
  left: 5%;
  cursor: pointer;
}

.filter .select div {
  height: 45px !important;
  width: 250px !important;
}

.__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-4);
  gap: 15px;
}

.faq {
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.5em;
  color: #707070;
}

.content {
  margin-bottom: var(--mb-6);
}

.__overflow__Y {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
}
.tableHeader {
  color: var(--first-color);
}
.filed__search {
  width: 300px;
  height: 45px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
  margin-top: 1.4em;
  overflow: hidden;
}

/*  */

.inputSearchContent * input::placeholder {
  color: var(--text-color);
}

.elt {
  margin-bottom: 50px !important;
}
.greenBackground {
  width: 350px;
  height: 80px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  top: 0;
  flex-direction: column;
  transform: translateY(-80%);
  justify-content: flex-start;
  padding-top: 18px;
  background-color: #80b1b2;
  color: white;
  font-family: "CartoonFont";
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transform: translateY(-80%);
  -moz-transform: translateY(-80%);
  -ms-transform: translateY(-80%);
  -o-transform: translateY(-80%);
}
.myArrangement {
  display: flex;
  align-items: center;
}
