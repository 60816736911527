.__drawer__header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin: var(--mb-1) 0 var(--mb-2) 0;
}

.horaire,
.__drawer__header h3 {
  font-family: var(--title-font);
  font-size: 1.7em;
  text-transform: uppercase;
}

.__drawer__header button {
  border: none;
  font-weight: 500;
  min-width: 130px;
}

.time__picker {
  background: #fff;
  padding: 0.5em 0;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border-radius: 5px;
}

.full__content {
  width: 100%;
  height: 100%;
  /* max-width: 80%; */
  background: #f8f8f8;
  /* margin-left: auto;
  margin-right: auto; */
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  padding: 2%;
  border-radius: 5px;
  margin-bottom: var(--mb-4);
}

.field__content {
  max-width: 60%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-size: 1.5em;
}

.forms {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
  margin-top: var(--mb-2);
}

.field {
  background: #fff;
  padding: 0.35em;
  /*width: 20vw;
  */
  width: 100%;
  margin-bottom: 1em;
  border-radius: 5px;
  border: 1px solid #707070;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.button {
  width: 100% !important;
  background: #636363 !important;
  color: #fff !important;
  height: 42px !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

.button:hover {
  background: var(--first-color) !important;
  color: #fff !important;
}

.__new__creneaux {
  width: 100% !important;
  background: var(--first-color) !important;
  color: #fff !important;
  height: 42px !important;
  margin-bottom: 0.5em;
}

.__new__creneaux:hover {
  background: #636363 !important;
  color: #fff !important;
}

.flex {
  display: flex;
  gap: 0.8em;
  flex-wrap: wrap;
}

.groupbox {
  margin-bottom: var(--mb-4);
}

.checkbox {
  position: absolute;
  margin: auto;
  right: 10px;
  top: 5px;
}

.card {
  position: relative;
  width: 180px;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  padding: 1em;
  background: #fff;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.hoursBox {
  margin-bottom: 2em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media only screen and (max-width: 767px) {

  .hoursBox {
    margin-bottom: 2em;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .horaire,
  .__drawer__header h3 {
    font-family: var(--title-font);
    font-size: 0.9em;
    text-transform: uppercase;
  }

}

/* New Css Styles */

.drawerBody {
  height: auto !important;
  width: 580px !important;
  background: #FAFAFA !important;
  box-shadow: 4px 8px 16px #80808029 !important;
  border: 1px solid #BFBCBC !important;
  border-radius: 42px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 40px !important;
  margin-left: 7% !important;
  margin-right: 7% !important;
}

.dateBox {
  height: auto !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.dateBox span {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
}

.datePickerBox {
  height: auto;
  width: 350px;
}

.datePicker {
  background: #fff;
  padding: 0.5em 0;
  border: 1px solid #707070;
  border-radius: 8px;
  margin-left: 20px;
}

.datePicker::placeholder {
  color: #868686;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
}

.timeBox {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.timeBox span {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  margin-left: 10px;
  margin-right: 10px;
}

.timeTitle {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
}

.timePicker {
  height: 40px;
  width: 160px;
  background: #fff;
  padding: 0.25em 0;
  border: 1px solid #707070;
  border-radius: 8px;
}

.selectBox {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.levelsBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 600;
  color: #7FB1B2;
  margin-top: 20px;
}

.levelsButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.levelButton {
  width: 100px;
  height: 40px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.levelButtonActive {
  width: 100px;
  height: 40px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.addButtonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
}

.albertBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.newMoveButton {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.newMoveButton {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

@media only screen and (max-width: 1050px) {
  .drawerBody {
    height: auto !important;
    width: 95% !important;
    background: #FAFAFA !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 20px !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .dateBox {
    height: auto !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .dateBox span {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
  }

  .datePickerBox {
    height: 30px !important;
    width: 75%;
  }

  .datePicker {
    height: 30px !important;
    background: #fff;
    padding: 0em;
    border: 1px solid #707070;
    border-radius: 8px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .datePicker::placeholder {
    color: #868686;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
  }

  .timeBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  .timeBox span {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-left: 10px;
    margin-right: 10px;
  }

  .timeTitle {
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
  }

  .timePicker {
    height: 30px;
    width: 50%;
    background: #fff;
    padding: 0em 0;
    border: 1px solid #707070;
    border-radius: 8px;
  }

  .selectBox {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .levelsBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #7FB1B2;
    margin-top: 20px;
  }

  .levelsButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .levelButton {
    width: 70px;
    height: 25px;
    background: #FFF;
    border: 1px solid #707070;
    border-radius: 8px;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .levelButtonActive {
    width: 70px;
    height: 25px;
    background: #E4F8F8;
    border: 1px solid #7FB1B2;
    border-radius: 8px;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #7FB1B2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .addButtonBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    text-transform: capitalize !important;
  }

  .albertBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .newMoveButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin: 0px auto !important;
  }

}