.header {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 14px #70707038;
}

.headerTeacher {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 14px #70707038;
}

.menu {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menuTeacher {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav__bar .nav__item {
  display: flex;
  align-items: center;
}

.nav__bar .nav__item li {
  text-align: center;
  padding: 0 0.5em;
  line-height: 20px;
  cursor: pointer;
}

.nav__bar .nav__item li a {
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 500 !important;
  transition: all 0.3s;
  font-size: 0.85em;
}

.nav__bar .nav__item li a:hover {
  color: var(--first-color);
}

.user {
  display: flex;
  padding: 0.5em 1em !important;
  height: 55px;
  align-items: center;
  background: #f7f6f2;
  margin-right: 8px;
  border-radius: 50px;
}

.user .user__info {
  text-align: left;
}

.user .user__info span {
  margin-left: 6px;
  font-weight: 400;
  font-size: 12px;
}

.user .user__info span:nth-child(2) {
  color: var(--first-color);
}

.span__list {
  width: 200px;
  /* overflow-y: scroll; */
  display: block;
}

/* .__header__notif {
  position: relative;
  top: -55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.__header__notif {
  position: absolute;
  top: -16px;
  right: 0;
  font-size: 8px !important;
}

.nav__bar_mobile,
.mobile_menu {
  display: none;
}

/* Prof */

.__prof {
  background: var(--first-color);
  padding-left: 10px !important;
  padding-right: 15px !important;
  line-height: 15px !important;
  box-shadow: 3px 6px 1px -1px #b0e0e1;
}

.__prof * {
  color: #fff !important;
}

.__bar {
  width: 100%;
  height: 10px;
  border-radius: 15px;
  background: #b0e0e1fa;
  box-shadow: 2px 3px 0px -1px #356672;
  /* box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3); */
}

.progress {
  height: 10px;
  border: 2px solid #b0e0e1fa;
  background: #c5101f;
  border-radius: 15px;
  margin-left: 2%;
}

.__user__right {
  line-height: 5px;
  text-align: right;
}

.__frend {
  font-size: 10px;
  padding-left: 5px;
  text-transform: uppercase;
  /* fontStyle: "11px" InfoIcon, */
}

.cercle {
  position: relative;
  width: 22px;
  height: 22px;
  background: url("./images/cercle.svg") center center no-repeat;
  background-size: cover;
  left: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.header__menu {
  display: none;
}

.userPointSmall {
  position: relative;
  top: 2px;
  display: block;
  padding-left: 6px;
  text-align: right;
  font-style: 8px;
}

.topBar {
  height: 50px;
  width: 100%;
  background: #00AAB4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 400;
}

.topBarStudent {
  height: 50px;
  width: 100%;
  background: #00AAB4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 400;
}

.parentOrganismeName {
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
}

.parentOrganismeName {
  font-size: 1em;
  color: white;
  font-family: "Poppins";
  font-weight: 600;
}

.lottieBox {
  height: 50px;
  width: 50px;
}

.__logout {
  position: absolute;
  top: 1em;
  right: 10%;
  cursor: pointer;
}

.albertBox {
  background: #FFF;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7;
}

@media only screen and (max-width: 408px) {

  .user {
    width: 100% !important;
    margin: 0px auto;
  }

  .user .user__info {
    display: none;
  }

}

.newNavBar {
  display: flex;
  height: 75px;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}

.navbarItemBox {
  height: 36px;
  width: 36px;
  background: #F7F6F2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.navbarItemMenu {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.menuItem {
  width: 19% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuItemStudent {
  width: 19% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {

  .user {
    width: 100%;
    height: 50px;
    padding: 0.5em !important;
    position: relative;
    top: -6px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .userPointSmall {
    position: relative;
    top: 2px;
    left: -15px;
    display: block;
    padding-left: 6px;
    text-align: right;
    font-style: 8px;
  }

  .__frend {
    font-size: 10px;
    padding-left: 5px;
    text-transform: uppercase;
    position: relative;
    left: -15px;
  }

  .nav__bar_mobile .nav__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav__bar_mobile .nav__item li {
    text-align: center;
    padding: 0 0.45em;
    line-height: 10px;
    cursor: pointer;
    position: relative !important;
    bottom: 0px;
  }

  .nav__bar_mobile .nav__item li a {
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 500 !important;
    transition: all 0.3s;
    font-size: 0.55em;
    white-space: wrap;
    text-overflow: ellipsis;
  }

  .nav__bar_mobile .nav__item li a:hover {
    color: var(--first-color);
  }

  .__logout {
    position: absolute;
    top: 1em;
    right: 10%;
    cursor: pointer;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1017px) {

  .user {
    width: 185px;
    height: 50px;
    padding: 0.5em !important;
    position: relative;
    top: -6px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .nav__bar_mobile .nav__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav__bar_mobile .nav__item li {
    text-align: center;
    padding: 0 0.5em;
    line-height: 20px;
    cursor: pointer;
  }

  .nav__bar_mobile .nav__item li a {
    color: var(--text-color);
    text-transform: uppercase;
    font-weight: 500 !important;
    transition: all 0.3s;
    font-size: 0.6em;
    white-space: wrap;
    text-overflow: ellipsis;
  }

  .nav__bar_mobile .nav__item li a:hover {
    color: var(--first-color);
  }

}

@media only screen and (max-width: 1050px) {
  .nav__bar_mobile {
    height: 65px !important;
    width: 100% !important;
    background-color: white;
    display: block !important;
    box-sizing: border-box;
    position: fixed;
    padding: 0;
    padding-top: 0.5em;
    bottom: 0;
    border-radius: 4px;
    left: 0;
    box-shadow: 0px 3px 14px #70707038 !important;
    z-index: 30000;
  }
}

@media only screen and (max-width: 1151px) {

  .header {
    width: 100% !important;
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 14px #70707038;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    background: #FFF;
  }

  .headerTeacher {
    width: 100% !important;
    height: 65px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 14px #70707038;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    background: #FFF;
  }

  .topBar {
    height: 50px;
    width: 100%;
    background: #00AAB4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    font-family: "Poppins";
  }

  .topBarStudent {
    height: 50px;
    width: 100%;
    background: #00AAB4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.7em;
    color: white;
    font-family: "Poppins";
  }

  .menu {
    width: 100%;
    height: 40px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-top: 0em;
  }

  .pseudo__student {
    line-height: 10px;
  }

  .mobile_menu {
    display: block;
  }

  .nav__bar .__isMobile {
    display: none;
  }

  .user .user__info span {
    font-size: 11px;
  }

  .avatar {
    padding-left: 5px;
  }

  .albertBox {
    background: #FFF;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
  }

  .nav__bar .nav__item li a {
    font-size: 0.55em;
  }

  .nav__bar .nav__item {
    justify-content: space-between;
    transform: translateY(5px);
  }

  .nav__bar .nav__item li {
    padding: 0 0.2em;
  }

  .notifIcon {
    margin-right: 20px;
  }

  .logoContainer {
    height: 40px !important;
    width: auto !important;
    display: block;
  }

}

.menuButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #00AAB4 !important;
  color: #FFF !important;
  font-weight: 400 !important;
  border: 1px solid #FFF !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin-left: 10px;
  cursor: pointer;
}

.logoContainer {
  height: 90px;
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.logoTitleHeader {
  font-size: 18px;
  color: #393E3E;
  font-family: "Margin DEMO";
  line-height: 18px;
  font-weight: normal;
  margin-top: 0px;
}