.card__content {
  width: 100%;
  height: auto;
  padding: 10px;
  border-radius: 10px;

}

.card__content h3 {
  color: var(--title-color);
  text-transform: none;
  margin-bottom: var(--mb-1);
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 1em;
}

.card {
  width: 240px;
  height: 250px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  padding-top: 2em;
  padding-bottom: var(--mb-4);
  background: url('../../../../../../assets/images/teacher/card.svg') center center no-repeat;
  background-size: contain;
}

.card {
  font-size: var(--h3-font-size);
  color: #707070;
  font-weight: 500;
}

.card img {
  margin: auto;
}

.type__content {
  display: flex;
  margin-top: 20px;
}

.cours__number {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
}

.cours__number span {
  font-size: 3em;
  line-height: 30px;
}


.card .button {
  max-width: 170px;
  min-width: 170px;
  height: 30px !important;
  font-size: 10px !important;
}

@media only screen and (max-width: 768px) {
  .row {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 1050px) {
  .card__content h3 {
    color: var(--title-color);
    text-transform: none;
    margin-bottom: var(--mb-1);
    font-size: 1em;
  }

  .card__content p {
    font-size: 0.8em;
  }
}