.stats {
  margin-top: 2em;
  margin-bottom: 5em;
}

.__select {
  margin-top: 15px;
}

.__select div {
  position: relative;
  text-align: center;
  font-size: 2.4em;
  margin-top: var(--mb-1);
  line-height: 0 !important;
  font-family: var(--title-font);
  text-transform: uppercase;
  border: 1px solid black !important;
}

.iconArrowClass {
  width: 20px;
  font-size: 12px !important;
  top: 3px !important;
  right: 30px !important;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  margin-bottom: 4em;
  margin-top: 0.5em;
}

.card__matier {
  position: relative;
  width: 90px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  margin-top: var(--mb-2);
  transition: all 0.7s;
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  padding-left: 0px;
  padding-right: 5px;
}

.card__matier.active,
.card__matier:hover {
  width: 200px;
  height: 76px;
  background: var(--first-color);
  box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.card__matier.active .topic_name,
.card__matier .topic_name {
  position: absolute;
  transform: translateX(100%);
  visibility: hidden;
  transition: transform ease-out 0.7s;
  transform-origin: center right;
  transition: all 0.7s;
  -webkit-transition: transform ease-out 0.7s;
  -moz-transition: transform ease-out 0.7s;
  -ms-transition: transform ease-out 0.7s;
  -o-transition: transform ease-out 0.7s;
}

.card__matier.active .topic_name,
.card__matier:hover .topic_name {
  position: relative;
  visibility: visible;
  transform: translateX(0);
}

.topic_name h3 {
  color: #fff;
  font-family: var(--title-font);
  white-space: nowrap;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 600;
}

.icon__card__matiers {
  transform-origin: center left;
  transition: all 0.7s;
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
}

.icon__card__matiers img {
  width: 50px;
}

.main {
  width: 100%;
}

.card__dashboard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
  padding: 1.5em;
  background: #fafafa;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.infos_top {
  margin-bottom: var(--mb-1);
}

.infos_top h2 {
  font-size: 1.3em;
}

.right h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.content__propositions {
  width: 100%;
  padding: 1em 4em;
}

.propositions__card {
  margin-top: var(--mb-6);
}
.content_card {
  margin-top: var(--mb-2);
}

.card {
  width: 100%;
  min-height: 50px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
  padding: 0 2em;
  margin-bottom: 1em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.card h4 {
  margin-top: auto;
  margin-bottom: auto;
  color: var(--text-color);
}

.isCorrect {
  border: 1.3px solid green;
}

.propositionSelected {
  border: 1.3px solid red;
}

@media only screen and (max-width: 889px) {
  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.stats__dashboard {
  width: 100%;
  background: #fafafa;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  margin-top: 20px;
}

.backDiv {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
  height: 35px;
  width: auto;
}

.backButton {
  border-radius: 10px;
  border: 1px solid #C0C0C0;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.statsContainer {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.radarContainer {
  height: auto;
  width: 40%;
  background: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.boxTitle {
  color: var(--title-color);
  font-size: var(--h1-font-size);
  margin-left: 20px;
  margin-top: 10px;
  font-family: "CartoonFontBold";
  font-weight: 600;
}

.boxTitle:hover {
  color: #333;
}

.radarBox {
  height: auto;
  width: 85%;
  margin-left: 10%;
  margin-top: 10px;
  background: white;
  border-radius: 15px;
}

.radarTitle {
  color: #333;
  font-size: 1.5em;
  font-weight: 500;
  margin-left: 20px;
  margin-top: 20px;
}

.radarTitle:hover {
  color: #333;
}

.radarMarkBox {
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radarAVG {
  font-size: 35px;
  color: #333;
  font-weight: bold;
}

.radarAVG:hover {
  color: #333;
}

.radarMaxAVG {
  margin-top: 10px;
  font-weight: bold;
}

.radarMaxAVG:hover {
  color: #333;
}

.radarChartBox {
  display: flex;
  justify-content: center;
  padding: 0px;
}

.lineContainer {
  height: auto;
  width: 58%;
  display: flex;
  flex-direction: column;
}

.lineBox {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 10px;
}

.lineChartCard {
  height: auto;
  width: 100%;
  background: white;
  border-radius: 15px;
}

.lineChartCard:hover {
  background-color: #80b1b21a;
}

.lineChartCardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lineChartCardAVG {
  margin-top: 10px;
  margin-right: 30px;
  font-size: 20px;
  color: #333;
  font-weight: normal; 
}

.lineTitle {
  color: #333;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.backHeader {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.backLeft .backTitle {
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color); 
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

/* .backRight {
  
} */

@media only screen and (max-width: 767px) {
  .backLeft .backTitle {
    text-transform: uppercase;
    font-size: 1.2em;
    color: var(--text-color); 
  }
  .lineContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .lineContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .lineChartCard {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
    margin-top: 10px;
  }
  .lineBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .radarContainer {
    height: auto;
    width: 100%;
    background: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .statsContainer {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card__dashboard {
    gap: 0.5em;
    padding: 0.5em;
  }

  .stats__dashboard {
    gap: 0.5em;
    padding: 0.5em;
  }

  .topic_name h3 {
    font-size: 0.7em;
  }
  .icon__card__matiers img {
    width: 30px;
  }

  .card__matier {
    width: auto;
    height: 60px;
    margin-top: var(--mb-1);
  }

  .card__matier.active,
  .card__matier:hover {
    width: auto;
    height: 50px;
  }

  .content__propositions {
    padding: 0;
  }

  .header {
    gap: 0.5em;
    margin-bottom: 2em;
    margin-top: 0;
  }

  .right {
    margin-top: 1em;
  }
  .card {
    padding: 0 1em;
  }

  .__select div {
    font-size: 1.5em;
    margin-bottom: var(--mb-1);
  }
  .iconArrowClass {
    width: 15px;
    top: -1px !important;
    right: 60px !important;
  }

  .propositions__card {
    margin-top: var(--mb-4);
  }

  .radarChartBox {
    display: flex;
    justify-content: center;
    padding: 0px;
  }

  .radarBox {
    height: auto;
    width: 100%;
    margin-left: 0%;
    margin-top: 10px;
    background: white;
    border-radius: 15px;
  }
}
