.post {
  width: 100%;
  margin-bottom: var(--mb-3);
}

.input {
  width: 100% !important;
  border: 1px solid #707070;
  padding: 3px 7px;
  border-radius: 30px;
  margin-bottom: var(--mb-2);
  display: flex;
  flex-direction: row;
}

.inputTextarea {
  border: 1px solid #707070;
  padding: 3px 7px;
  border-radius: 30px;
  margin-bottom: var(--mb-2);
}

.img__preview {
  width: 300px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: var(--mb-1);
  margin-left: auto;
  margin-right: auto;
}

.img__preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.postButton {
  cursor: pointer;
  margin-right: 20px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3.5px;
  padding-bottom: 3.5px;
}

.postButton:hover {
  background-color: #FFF;
  border-radius: 25px;
  border: 1px solid #8AB0B1;
}

.postContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px auto;
  width: 100%;
  color: #393E3E;
}

@media only screen and (max-width: 1050px) {
  .post {
    width: 100%;
    margin-bottom: var(--mb-3);
  }

  .input {
    width: 100%;
    border: 1px solid #707070;
    padding: 3px 7px;
    border-radius: 30px;
    margin-bottom: var(--mb-2);
    display: flex;
    flex-direction: row;
  }

  .postButton {
    cursor: pointer;
    font-size: 0.7em;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
  }

  .postButton:hover {
    background-color: #FFF;
    border-radius: 25px;
    border: 1px solid #8AB0B1;
  }

  .postContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px auto;
    width: 100%;
    position: relative;
    left: 0%;
    padding-left: 0%;
    padding-right: 0%;
  }

  .commentBox {
    height: 100px !important;
    width: 90% !important;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 20px !important;
    opacity: 1;
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin: 0px auto !important;
  }

  .field::placeholder {
    font-size: 10px !important;
  }
}

/* New Css Styles */

.commentBox {
  height: 140px;
  width: 100%;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.field::placeholder {
  font-size: 12px !important;
}