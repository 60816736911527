.cardTracked {
  width: 100%;
  /* max-width: 380px; */
  display: flex;
  line-height: 20px;
  align-items: center;
  margin-bottom: var(--mb-4);
}

.right .top {
  margin-bottom: 0em;
}

.right .top span {
  font-size: 1.5em;
  font-weight: 500;
}

.left {
  margin-right: 1em;
}

@media only screen and (max-width: 767px) {
  .cardTracked {
    width: 100%;
    max-width: 100%;
    margin-bottom: var(--mb-2);
    padding: 10px;
  }

  .cardTracked img {
    width: 45px;
  }

  .left {
    margin-right: 0;
  }

  .right .top {
    margin-bottom: 0em;
  }

  .right .top span {
    font-size: 1.1em;
  }
}