.calendar {
  width: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
  padding: 2%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  background: #fafafa;
}

.weekly {
  width: 100%;
  height: 100%;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  padding: 2% 4%;
  margin-bottom: 2em;
  border: 1px solid #ccc;
}

.date {
  width: auto !important;
  height: 45px !important;
  border-radius: 12px !important;
  text-align: center !important;
  border: 1px solid #ccc !important;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
  transition: 0.3s !important;
  -webkit-transition: 0.3s !important;
  -moz-transition: 0.3s !important;
  -ms-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  font-size: 13px !important;
  font-family: Rubik;
  font-weight: 500;
  color: #393E3E;
  text-transform: capitalize;
  background: #FFF;
}

.date:hover {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.date.active {
  background: var(--first-color);
  border: 1px solid var(--first-color);
  color: #fff;
}

.creneaux {
  width: 100%;
  height: 100%;
  /* border: 1px solid #ccc; */
  margin-top: 10px;
  padding: 1% 2%;
}

.creneau {
  margin-bottom: 0.5em;
}

.range {
  width: 120px;
  height: 40px;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.range span {
  margin: auto;
}

.matiere {
  font-size: 1em;
}

.bar {
  margin: 0 0.5em;
}

.angleIcon path {
  fill: #fe772e;
}

.slider>svg path {
  fill: #fe772e;
}

.card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 500px) {
  .content__action__reservation {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 1em;
  }
}

.myReservation {
  margin-bottom: 1em;
}

.card_ {
  /* max: 250px; */
  flex: 0 0 25.333333%;
  opacity: 1 !important;
}

/* New Css Style */

.drawerBody {
  height: 100% !important;
  width: 34vw !important;
  background: #FFF !important;
  box-shadow: 4px 8px 16px #80808029 !important;
  border: 1px solid #BFBCBC !important;
  border-radius: 24px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 40px !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  overflow: auto;
  scrollbar-width: thin;
}

.drawerTitle {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calendarBox {
  height: 800px;
  width: 100%;
  overflow: scroll;
  border: 1px solid #707070;
  padding: 2%;
  border-radius: 30px 0px 0px 30px;
  -webkit-border-radius: 30px 0px 0px 30px;
  -moz-border-radius: 30px 0px 0px 30px;
  -ms-border-radius: 30px 0px 0px 30px;
  -o-border-radius: 30px 0px 0px 30px;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-left: 40px;
}

.mainContainer {
  height: 100%;
  width: 95%;
  overflow: auto;
  border: 1px solid #707070;
  padding: 2%;
  border-radius: 30px 0px 0px 30px;
  -webkit-border-radius: 30px 0px 0px 30px;
  -moz-border-radius: 30px 0px 0px 30px;
  -ms-border-radius: 30px 0px 0px 30px;
  -o-border-radius: 30px 0px 0px 30px;
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px;
  margin-left: 40px;
}

.headerBox {
  height: 380px;
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  left: 0;
  top: -25px;
  right: 0;
  z-index: 77;
  background: #FAFAFA;
}

.headerBoxFull {
  height: 220px !important;
  width: 100% !important;
  opacity: 1;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  position: sticky;
  left: 0;
  top: -25px;
  right: 0;
  z-index: 77;
  background: #FAFAFA;
}

.headerBoxFullCouponExist {
  height: 320px !important;
  width: 100% !important;
  opacity: 1;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  position: sticky;
  left: 0;
  top: -25px;
  right: 0;
  z-index: 77;
  background: #FAFAFA;
}

.lineSeparator {
  height: 1px;
  width: 92%;
  margin: 7px auto;
  background: #D8D8D8;
}

.detailsBox {
  height: 100%;
  width: 37% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 24px;
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 25px;
  padding-right: 40px;
}

.detailsBoxFull {
  height: 70px !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 24px;
  padding: 15px;
  position: absolute;
  left: 0;
  top: 7px;
  right: 0;
  z-index: 77;
}

.descriptionPriceBox {
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background: #FFF;
  box-shadow: 4px 8px 18px #98989829;
  border: 1px solid #DFDDDD;
  border-radius: 24px;
  padding-left: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: 40px;
  margin-left: 7px;
}

.avatarBox {
  height: 100%;
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15%;
}

.nameBox {
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 20px;
}

.avatarPicture {
  height: 85px;
  width: 85px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.nameDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.name {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  color: #8AB0B1;
}

.rateLanguages {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.title {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: #393E3E;
  margin-top: 5px;
}

.levels {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #7FB1B2;
  margin-bottom: 10px;
}

.levels div:last-child {
  display: none;
}

.newMessageButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.newMessageButton:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1;
  color: #fff;
}

.newProfileButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.newMoveButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
}

.newProfileButton:hover {
  transform: scale(1.03) !important;
  background-color: #60CD95;
  color: #fff;
}

.newMoveButton:hover {
  transform: scale(1.03) !important;
  background-color: #80b1b2;
  color: #fff;
}

.descriptionBox {
  height: 100%;
  width: 60% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.coursesPicesBox {
  height: 100% !important;
  width: 40% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.coursesBox {
  width: auto !important;
  max-width: 100% !important;
  display: flex;
  flex-direction: row;
  /* align-items: flex-start;
  justify-content: flex-start; */
  overflow: scroll;
  scrollbar-width: thin;
}

.coursesBoxSticky {
  width: auto !important;
  max-width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  /* align-items: flex-end !important;
  justify-content: flex-end !important; */
  overflow-y: auto !important;
  scrollbar-width: thin;
  margin-left: 20px;
  padding-right: 10px;
}

.courses {
  width: auto;
  height: 35px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 12px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
  gap: 7px;
}

.priceBox {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.priceTitle {
  font-size: 8px;
  font-family: "Poppins";
  font-weight: 600;
  color: #868686;
}

.price {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 700;
  color: #7FB1B2;
}

.textStats {
  min-width: 150px;
  text-align: left;
  text-decoration: underline;
}

.docsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.description {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 600;
  color: #393E3E;
}

.weekBox {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  background: #FFF;
}

.weekBoxSticky {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 85px;
  right: 0;
  z-index: 77;
}

.weekBoxStickyCouponExist {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  border: 1px solid #BFBCBC;
  border-radius: 16px;
  background: #FFF;
  position: absolute;
  left: 0;
  top: 150px;
  right: 0;
  z-index: 77;
}

.daysBox {
  width: 100%;
  height: 470px;
  background: transparent;
  margin-top: 30px;
}

.daysBoxCouponExist {
  width: 100%;
  height: 400px;
  background: transparent;
  margin-top: 70px;
}

.dayBox {
  width: 100%;
  height: auto;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 23px;
  padding: 30px;
  margin-bottom: 7px;
}

.calendarDay {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #393E3E;
  text-transform: capitalize;
}

.reservationBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.reservationMessage {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  margin-right: 20px;
}

.reservationMessageCoupon {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
  color: #707070;
  margin-right: 20px;
  text-decoration: underline !important;
}

.dayTimeBox {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.momentBox {
  height: auto;
  width: 10% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #868686;
}

.momentBoxCoupon {
  height: auto;
  width: 10% !important;
  display: flex;
  flex: auto;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  color: #868686;
}

.timeBox {
  height: auto;
  width: auto;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.timeBoxCoupon {
  height: auto;
  width: 85% !important;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.timeButton {
  width: 80px;
  height: 34px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 8px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.timeButtonDisabled {
  width: 80px;
  height: 34px;
  background: #E5E5E5;
  border: 1px solid #707070;
  border-radius: 8px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.timeButtonActive {
  width: 80px;
  height: 34px;
  background: #7FB1B2;
  border: 1px solid #7FB1B2;
  border-radius: 8px;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 600;
  color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 7px;
}

.timeButtonNextTime {
  font-size: 10px;
  font-family: Rubik;
  font-weight: 400;
  color: #707070;
}

.timeButtonNextTimeActive {
  font-size: 10px;
  font-family: Rubik;
  font-weight: 400;
  color: #FFF;
}

.ribbon {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 20px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  border-radius: 0px;
  height: 20px;
  background: #60CD95;
}

.ribbon:after {
  height: 20px;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent white;
}

.credit {
  font-size: 8px;
  position: relative;
  bottom: 4px;
}

.confirmButtonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
}

.confirmButtonBoxSticky {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
  width: 100%;
  position: absolute;
  top: 235px;
}

.couponBox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  border: 1px solid #707070;
  border-radius: 12px;
  height: 34px;
  width: auto;
}

.couponInput {
  font-size: 12px !important;
}

.couponInput::placeholder {
  font-size: 12px !important;
}

.couponButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 12px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 2%;
  margin-bottom: 2px !important;
}

.cancelcouponButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 12px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-left: 14%;
  margin-bottom: 2px !important;
}

.confirmButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.reservationAddedBox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 13px !important;
  color: #60CD95 !important;
  font-weight: 500 !important;
  font-family: "Poppins";
}

.verticalSeparator {
  height: 25px;
  width: 1px;
  background: #707070;
  margin-left: 10px;
  margin-right: 5px;
}

.headerFixed {
  height: 180px !important;
  width: 100% !important;
  background: blueviolet;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  /* display: flex !important; */
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0px;
  margin-bottom: 0px;
  position: absolute;
  top: -10px !important;
  left: -20px !important;
  right: -20px !important;
  z-index: 77;
  display: none;
}

.smallHeader {
  height: 70px !important;
  width: 100% !important;
  background: #FFF;
  border-radius: 16px;
  border: 1px solid #DFDDDD;
  /* display: flex !important; */
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 10px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 77;
  display: none;
}

.smallHeaderleft {
  width: 50% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.smallHeaderRight {
  width: 50% !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.smallHeaderNameBox {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-left: 10px;
}

.avatarImage {
  height: 40px;
  width: 40px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1.5px solid #FFF;
  border-radius: 50%;
}

.couponSection {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  height: 50px !important;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px !important;
  color: #707070;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}

.BackCalendarBox {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 20%;
  margin-top: 0px;
  position: absolute;
  left: 0;
}

.BackCalendarBoxSticky {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 20%;
  position: absolute;
  left: 0;
  top: 7px !important;
}

.couponSectionSticky {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  margin-top: 0px;
  color: #707070;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  position: absolute;
  top: 90px !important;
}

.couponSectionBox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 30% !important;
  background: "#F1FFF8";
  border: 0.5px dashed #4BB980;
  border-radius: 12px;
  padding-left: 20px;
}

.couponSectionMessage {
  width: 70% !important;
  color: #339F67;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
}

.couponDetailsBox {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  background: "#FFFFFF";
  border: 0.5px dashed #7EB0B1;
  border-radius: 12px;
  padding-left: 20px;
  margin-top: 10px;
}

.couponDetailsBoxMessage {
  width: 100% !important;
  color: #3A5353;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 500;
}

@media only screen and (max-width: 1340px) {
  .newProfileButton {
    font-size: 0.8em !important;
  }

  .newMoveButton {
    font-size: 0.8em !important;
  }
}

@media only screen and (max-width: 1050px) {
  .couponSection {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: auto !important;
    width: 100% !important;
    margin: 0px auto;
    color: #707070;
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .couponSectionSticky {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    margin-top: 0px;
    color: #707070;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    position: absolute;
    top: 90px !important;
    margin: 0px auto;
  }

  .couponSectionBox {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 85% !important;
    background: "#F1FFF8";
    border: 0.5px dashed #4BB980;
    border-radius: 12px;
    padding-left: 20px;
    margin: 20px auto !important;
  }

  .couponSectionMessage {
    width: 70% !important;
    color: #339F67;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
  }

  .BackCalendarBox {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    margin: 15px auto;
    position: relative;

  }

  .BackCalendarBoxSticky {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    position: absolute;
    top: 90px !important;
    margin: 0px auto;
  }

  .confirmButtonBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-right: 0px;
    width: 100%;
  }

  .confirmButtonBoxSticky {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .verticalSeparator {
    height: 15px;
    width: 1px;
    background: #707070;
    margin-left: 10px;
    margin-right: 5px;
  }

  .confirmButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin: 10px auto;
  }

  .couponBox {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    border: 1px solid #707070;
    border-radius: 16px;
    height: 34px;
    width: 90% !important;
    margin: 0px auto !important;
  }

  .reservationAddedBox {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1em !important;
    color: #60CD95 !important;
    font-weight: 500 !important;
    font-family: "Poppins";
  }

  .reservationBox {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
  }

  .drawerTitle {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .mainContainer {
    height: auto;
    width: 95%;
    overflow: hidden;
    border: 1px solid #707070;
    padding: 0px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0px auto;
    margin-bottom: 40px;
  }

  .headerBox {
    height: auto;
    min-height: auto;
    width: 95%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    background: #FFF;
    box-shadow: 4px 8px 18px #98989829;
    border: 1px solid #DFDDDD;
    border-radius: 24px;
  }

  .detailsBox {
    height: auto;
    width: 95% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    box-shadow: none;
    border: 0px solid #DFDDDD;
    border-radius: 24px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    text-align: center;
    margin: 0px auto;
  }

  .detailsBoxScroll {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #FFF;
    box-shadow: 4px 8px 18px #98989829;
    border: 1px solid #DFDDDD;
    border-radius: 24px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    text-align: center;
  }

  .avatarBox {
    height: auto;
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5%;
  }

  .nameBox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px;
    margin-top: 0px;
  }

  .avatarPicture {
    height: 85px;
    width: 85px;
    box-shadow: 0px 3px 6px #00000029;
    border: 1.5px solid #FFF;
    border-radius: 50%;
  }

  .nameDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .name {
    font-size: 1em;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
  }

  .rateLanguages {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .title {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 400;
    color: #393E3E;
    margin-top: 5px;
  }

  .levels {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 600;
    color: #7FB1B2;
    margin-bottom: 10px;
  }

  .descriptionPriceBox {
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: transparent;
    box-shadow: none;
    border: 0px solid #DFDDDD;
    border-radius: 24px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    margin-left: 0px;
    text-align: left;
  }

  .descriptionBox {
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .description {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 600;
    color: #707070;
  }

  .docsBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0px;
  }

  .docsBox span {
    font-size: 0.75em;
  }

  .coursesPicesBox {
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .coursesBox {
    width: 100% !important;
    max-width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .priceBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
  }

  .courses {
    width: auto;
    height: 25px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-left: 0px;
    margin-right: 7px;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    gap: 7px;
  }

  .price {
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 700;
    color: #7FB1B2;
  }

  .descriptionLineSeparator {
    height: 1px;
    width: 92% !important;
    margin: 10px auto !important;
    background: #D8D8D8;
  }

  .newProfileButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .newMoveButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 2px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
  }

  .couponButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 12px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 0px;
    margin-bottom: 1px;
    margin-top: 5px;
  }

  .cancelcouponButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 12px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #60CD95 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 14%;
    margin-bottom: 1px;
  }

  .lineSeparator {
    height: 1px;
    width: 92%;
    margin: 10px auto;
    background: #D8D8D8;
  }

  .weekBox {
    width: 95%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2%;
    margin: 0px auto;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    background: #FFF;
    position: relative;
    top: 10px;
    left: 0%;
    z-index: 2;
  }

  .date {
    width: auto !important;
    height: 25px !important;
    border-radius: 0px !important;
    text-align: center !important;
    border: 1px solid #ccc !important;
    cursor: pointer !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    -webkit-border-radius: 12px !important;
    -moz-border-radius: 12px !important;
    -ms-border-radius: 12px !important;
    -o-border-radius: 12px !important;
    transition: 0.3s !important;
    -webkit-transition: 0.3s !important;
    -moz-transition: 0.3s !important;
    -ms-transition: 0.3s !important;
    -o-transition: 0.3s !important;
    margin-left: 0px !important;
    margin-right: 5px !important;
    font-size: 0.55em !important;
    font-family: Rubik;
    font-weight: 500;
    color: #393E3E;
    text-transform: capitalize;
    background: #FFF;
  }

  .dateBox {
    font-size: 0.7em !important;
    font-family: "Poppins";
    font-weight: 600;
    color: #393E3E;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    margin-left: 10px;
    margin-right: 0px;
  }

  .todayBox {
    width: 70px;
    height: 25px;
    background: #EAFFF4;
    border: 1px solid #60CD95;
    border-radius: 8px;
    color: #60CD95;
    opacity: 1;
    font-size: 0.8em;
    font-family: rubik;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
  }

  .horizontalLine {
    height: 1px;
    width: 30%;
    background: #D8D8D8;
    visibility: hidden;
  }

  .daysBox {
    width: 95%;
    height: auto;
    background: transparent;
    margin: 30px auto;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .dayBox {
    width: 100%;
    height: auto;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 7px;
  }

  .calendarDay {
    font-size: 0.7em;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    text-transform: capitalize;
  }

  .reservationMessage {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-right: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0%;
  }

  .reservationMessageCoupon {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-right: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0%;
    text-decoration: underline;
  }

  .reservationMessageConfirm {
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #707070;
    margin-right: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: -10%;
  }

  .dayTimeBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .momentBox {
    height: auto;
    width: auto !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.75em;
    font-family: "Poppins";
    font-weight: 500;
    color: #868686;
    margin-bottom: 10px;
  }

  .momentBoxCoupon {
    height: auto;
    width: auto !important;
    display: flex;
    flex: auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #868686;
  }

  .timeBox {
    height: auto;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .timeBoxCoupon {
    height: auto;
    width: 90% !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }

  .ribbon {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    padding-right: 20px;
    color: white;
    font-family: Arial, sans-serif;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
    border-radius: 0px;
    height: 20px;
    background: #60CD95;
  }

  .ribbon:after {
    height: 20px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 12px;
    border-color: transparent transparent transparent white;
  }

  .credit {
    font-size: 0.65em;
    position: relative;
    bottom: 4px;
  }

  .couponInput {
    font-size: 0.8em !important;
  }

  .couponInput::placeholder {
    font-size: 0.8em !important;
  }

  .drawerBody {
    height: 100% !important;
    width: 95% !important;
    background: #FFF !important;
    box-shadow: 4px 8px 16px #80808029 !important;
    border: 1px solid #BFBCBC !important;
    border-radius: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 10px !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    overflow: auto;
    scrollbar-width: thin;
  }

  .couponDetailsBox {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
    background: "#FFFFFF";
    border: 0.5px dashed #7EB0B1;
    border-radius: 12px;
    padding-left: 0px;
    margin-top: 10px;
    text-align: center;
  }
}