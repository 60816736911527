.card {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.titleBox h3 {
  color: var(--title-color);
  font-weight: 400;
}

.tagAvis {
  background: #FFF;
  box-shadow: 2px 4px 8px #CECECE29;
  border: 1px solid #BFBCBC81;
  padding: 5px 15px;
  border-radius: 10px;
  color: var(--title-color);
  margin-left: 10px;
}


.tagAvis span strong {
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .titleBox h3 {
    font-size: 13px;
  }

  .tagAvis {
    padding: 5px 10px;
    font-size: 12px;
  }

  .tagAvis img {
    width: 15px;
  }

  .btn {
    font-size: 10px;
    padding: 1px 8px;
    display: none !important;
  }
}

/* New Css Styles */

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 40px;
}

.emptyContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
  width: 100%;
}

@media only screen and (max-width: 1050px) {
  .content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    margin-top: 20px;
  }
}