.button {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 32px !important;
  color: #fff !important;
  text-transform: uppercase;
  border: unset !important;
  border-radius: 50px !important;
  background: var(--first-color) !important;
  font-weight: 500 !important;
  font-size: var(--smaller-font-size) !important;
  box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1);
}

.button:hover {
  transform: scale(1.03) !important;
}

.button:active {
  transform: scale(0.99) !important;
}

@media only screen and (max-width: 390px) {
  .button {
    min-width: 150px;
    height: 28px !important;
  }
}