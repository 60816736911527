.matiers {
  width: auto;
  height: 30px;
  background: #EBEAEA;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  color: var(--text-color);
  margin: var(--mb-1);
  padding-left: 0px;
  padding-right: 0px;
}

.center {
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.left {
  margin-left: 10px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.right {
  margin-left: 0px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .matiers {
    width: auto;
    height: 20px;
    background: #EBEAEA;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 8px;
    color: var(--text-color);
    margin: var(--mb-1);
    padding-left: 0px;
    padding-right: 0px;
  }

  .left {
    margin-left: 5px;
    margin-right: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .right {
    margin-left: 0px;
    margin-right: 5px;
  }
}