.breadcrumbs {
  width: 100%;
  height: 40px;
  background: #fafafa;
  line-height: 40px;
  margin-bottom: var(--mb-3);
}

.arrow {
  color: var(--first-color);
  font-weight: 600;
  font-size: 16px;
}

.__item {
  cursor: pointer;
  transition: all 1s ease-in-out;
  font-weight: 500;
  color: var(--text-color);
}

.__item *:hover {
  color: var(--first-color);
}

.__bread__content {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 6.5px;
}

@media only screen and (max-width: 500px) {
  .__listes {
    overflow-x: scroll;
  }

  .__item {
    font-size: 12px;
  }

  .breadcrumbs {
    margin-top: 75px;
    margin-left: 7px;
    margin-bottom: 0px;
  }
}