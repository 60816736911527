.teacher__infos {
  width: 100%;
  height: auto;
  min-height: 300px;
  text-align: center;
  background: #fafafa;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 2em;
}

.student__infos {
  width: 100%;
  height: auto;
  text-align: center;
  background: #fafafa;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 2em;
}

.top {
  width: 100%;
  height: 80px;
  background: var(--first-color);
  margin-bottom: var(--mb-4);
  display: flex;
}

.avatar {
  position: relative;
  margin: auto;
  top: 1.8em;
  background: #fff2e0;
  border-radius: 50%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #FFF;
}

.profile {
  background: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.content {
  width: 100%;
  padding: 0 1em;
}

.content h2 {
  color: #8AB0B1;
  font-size: 1.05em;
  text-transform: uppercase;
  margin-bottom: 0.8em;
}

.follow {
  margin-bottom: 3px;
}

.text_stats {
  min-width: 150px;
  text-align: left;
  text-decoration: underline;
}

.readMore {
  text-align: left;
  text-decoration: underline;
  font-size: 12px;
  color: #707070;
  margin-top: 5px;
  cursor: pointer;
}

.readMore:hover {
  color: #7FB1B2;
}

@media only screen and (max-width: 1050px) {
  .teacher__infos {
    display: none;
  }

  .student__infos {
    display: none;
  }
}

/* New CSS Styles */

.teacherInfos {
  width: 100%;
  height: auto;
  min-height: 300px;
  text-align: center;
  background: #FFF;
  border-radius: 24px;
  overflow: hidden;
  padding-bottom: 2em;
  box-shadow: 4px 8px 21px #B7B7B729;
}

.studentInfos {
  width: 100%;
  height: auto;
  min-height: 150px;
  text-align: center;
  background: #FFF;
  border-radius: 24px;
  overflow: hidden;
  padding-bottom: 2em;
  box-shadow: 4px 8px 21px #B7B7B729;
}

.topAvatar {
  width: 100%;
  height: 80px;
  background: #FFF;
  margin-bottom: var(--mb-4);
  display: flex;
}

.newButton {
  width: 130px !important;
  height: 30px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  margin: 0px auto;
  box-shadow: 3px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}