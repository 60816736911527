/**************************************** CSS ****************************************/

.step_container {
  display: none;
}

.register__teacher {
  width: 100%;
  text-align: left;
}

.step {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
}

/* JOFREY STYLE */

.accountFormBox {
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  height: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.formBox {
  width: 100%;
  max-width: 100%;
  margin: 70px auto;
  box-shadow: 4px 8px 16px #80808029;
  border: 1px solid #BFBCBC;
  border-radius: 32px;
  background-color: #fafafa;
  height: auto;
  padding: 30px 40px 120px 40px;
  display: flex;
  flex-direction: column;
}

.formElementBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.stepTitleBox {
  text-align: left;
  align-items: flex-start;
  align-content: start;
}

.boxTitle {
  font-size: 28px;
  font-weight: 600;
  color: #707070;
  font-family: "CartoonFont";
  text-align: left;
  text-transform: uppercase;
}

.boxSubtitle {
  font-size: 28px;
  font-weight: bold;
  color: black;
}

.boxMessage {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 35px;
  margin-right: 35px;
}

.selectInput {
  color: #707070;
  border-radius: 8px;
  border: 1px solid #707070;
  font-style: italic !important;
  width: 100%;
}

.placeholderStyle {
  color: #C0C0C0;
  font-size: 16px;
}

.nextButton {
  font-size: 18px;
  padding: 20px;
}

.hideElement {
  display: none;
}

.uploadButtonStyle {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
}

.uploadVignetteButton {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: 130px;
}

.startRecordingVideo {
  font-size: 14px;
  margin: 0;
  padding: 0;
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.cardInfoBox {
  background-image: url(assets/images/teacher/card.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 35px;
  margin-top: 15px;
  padding: 0;
}

.cardInfoBoxToDo {
  background-image: url(assets/images/teacher/cardLong.png);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 15px;
  padding: 0;
}

.cardInfoBoxWarning {
  background-image: url(assets/images/teacher/cardAds.svg);
  background-size: cover;
  height: auto;
  width: 380px;
  margin-left: 5px;
  margin-top: 0px;
  padding: 0;
}

.avatarExampleBox {
  background-image: url(assets/images/app/pictureExample.png);
  background-size: cover;
  height: 120px;
  width: 380px;
  border-radius: 8px;
  margin: 5px 7px 0px 35px;
}

/* Drag & Drop Input CSS */

#formFileUpload {
  height: 425px;
  width: 540px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#inputFileUpload {
  visibility: collapse;
}

#labelFileUpload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #707070;
  background-color: #fff;
}

#labelFileUpload.dragActive {
  background-color: #ffffff;
}

.uploadButton {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.uploadButton:hover {
  text-decoration-line: underline;
}

#dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* Drag & Drop Input CSS */

.uploadImageBox {
  width: 45% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.uploadImageBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.uploadImageBoxMessage {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.calendarBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
}

.calendarBoxSubtitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0px;
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  margin: 30px auto;
  padding-left: 210px;
}

.calendareBoxMessage {
  font-size: 14px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.uploadVideoBoxSubMessage {
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 5px;
  color: #707070;
}

.uploadVideoBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 45px;
  margin-left: 35px;
}

.uploadVideoBoxMessage {
  font-size: 14.5px;
  font-weight: 400;
  margin-left: 35px;
  margin-top: 5px;
  color: #707070;
}

.uploadVideoBoxSubtitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.addPictureButton {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.addDocPictureButton {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
}

.addVideoButton {
  height: 32px;
  width: auto;
  margin-left: 35px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.trashIcon {
  filter: invert(39%) sepia(100%) saturate(7463%) hue-rotate(359deg) brightness(106%) contrast(113%);
  height: 30px;
  margin-left: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.addVignetteButton {
  height: 32px;
  width: 100px;
  margin-left: 35px;
  margin-top: 30px;
}

.previewBox {
  margin-left: 35px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #707070;
  border-style: dashed;
}

.previewBoxImage {
  max-width: 200px;
  height: auto;
  border-radius: 15px;
}

.uploadBox {
  display: flex;
  flex-direction: column;
}

.uploadBoxTitle {
  font-size: 20px;
  font-style: italic;
  color: #333;
}

.uploadBoxMessage {
  font-size: 20px;
  font-style: italic;
  color: #CBCACD;
}

.uploadBoxMessageError {
  font-size: 20px;
  font-style: italic;
  color: red;
}

.uploadBoxBorder {
  margin-left: 35px;
  margin-top: 25px;
  border: none;
  border-radius: 15px;
}

.uploadBoxBorderError {
  margin-left: 35px;
  margin-top: 25px;
  border: 1px solid red;
  border-radius: 15px;
}

.exampleBox {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.exampleBoxLong {
  width: 40% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  margin-top: 30px;
  margin-right: 40px;
}

.exampleBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 30px;
  margin-left: 35px;
}

.videoExampleBoxTitle {
  font-size: 15px;
  font-weight: 500;
  color: #7FB1B2;
  margin-top: 30px;
  margin-left: 0px;
}

.instructionBox {
  padding-left: 65px;
  padding-right: 60px;
  margin-top: 42px;
}

.instructionBoxLong {
  padding: 60px 40px 10px 65px;
  margin-top: 42px;
}

.checkboxBox {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.checkboxBoxItem {
  margin-left: 10px;
  color: #707070;
  font-size: 12.5px;
}

.checkboxBoxItemLong {
  margin-left: 5px;
  color: #707070;
  font-size: 12.5px;
}

.checkboxBoxLong {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.customTextarea {
  width: 100%;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

/* Placeholder color */
.customTextarea::placeholder {
  color: #C0C0C0;
}

/* Focus color */
.customTextarea:focus {
  outline: none;
  border-color: #707070;
  box-shadow: 0 0 2px green;
}

#videoFileUpload {
  background-image: url(assets/images/app/videoExample.png);
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 440px;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-left: 35px;
  margin-top: 30px;
  border-radius: 24px;
}

.vignetteBox {
  display: flex;
  flex-direction: row;
  border: 1px solid #333;
  background: #FFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin: 20px 0px 0px 35px;
  padding: 15px 20px 45px 20px;
  width: 95%;
  align-items: center;
  justify-content: left;
}

/* .step .stepsBox {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
} */

.addCertificateDiplomaButton {
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  margin-left: 50px;
}

.customButtonStyle {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: #7fb1b2;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
}

.customButtonStyle:hover {
  transform: scale(1.03) !important;
}

.customButtonStyle:active {
  transform: scale(0.99) !important;
}

.stopButtonStyle {
  margin-top: 20px;
  margin-left: 50px;
  height: auto;
  border-radius: 50px;
  background-color: red;
  color: #fff;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 3px 6px 1px -2px rgb(182, 85, 85) !important;
  -webkit-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
  -moz-box-shadow: 3px 6px 1px -2px rgb(182, 85, 85);
}

.stopButtonStyle:hover {
  transform: scale(1.03) !important;
}

.stopButtonStyle:active {
  transform: scale(0.99) !important;
}

.certificateDiplomaFormBox {
  margin: 0px auto;
  height: auto;
  width: 680px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.pictureBoxRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.picturePreviewBox {
  height: auto;
  width: auto;
  border: 1px solid #333;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  display: flex;
}

.titleBox {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 74%;
}

.videoPreviewBox {
  height: auto;
  width: 560px;
  border-radius: 5px;
  margin-left: 35px;
  margin-top: 25px;
}

.videoLinkInput {
  margin-left: 35px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 95%;
}

.videoFormatTitle {
  font-size: 13px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 10px;
}

.calendarTimezone {
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.fillLaterButton {
  height: 50px;
  width: 65%;
  max-width: 40%;
  margin-left: 0px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.removeButton {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
  padding: 5px;
  border-radius: 50px;
  border: none;
  background-color: red;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.removeButton:hover {
  padding: 7px;
  background-color: #fff;
  border: 1px solid red;
  color: red;
  cursor: pointer;
}

.previewCertificateDiploma {
  height: 110px;
  width: 110px;
  position: relative;
  bottom: 40px;
  margin-top: 10px;
}

/* JOFREY STYLE */

.teacher {
  display: flex;
  align-items: center;
  margin: 0px auto;
}

.teacher .avatar {
  padding-right: var(--mb-4);
}

.header__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.header__text p {
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.header__text p strong,
.text strong {
  font-size: var(--normal-font-size);
  font-weight: 500;
  color: #636363;
}

.text a {
  color: var(--text-color);
  text-decoration: underline;
}

.content__action {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0px auto;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.field {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
}

.field::placeholder {
  font-style: italic !important;
}

.termes__legals {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.termesToAccept {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.button {
  width: 100%;
  height: 40px !important;
  display: block;
  margin-bottom: var(--mb-2);
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1) !important;
  -webkit-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
  -moz-box-shadow: 5px 7px 1px -1px rgba(90, 132, 132, 1);
}

.text__legal {
  text-align: left;
  font-size: var(--small-font-size);
}

.inputRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputRowChild {
  width: 49%;
}

.avatarContainer {
  display: flex;
  flex-direction: row;
}

.rowButtons {
  display: flex;
  width: 100%;
  margin: 40px auto;
  align-items: center;
  justify-content: center;
}

.rowsButtons {
  display: flex;
  width: 100%;
  margin: 40px auto;
  align-items: center;
  justify-content: center;
}

.rowButtonChild {
  max-width: auto;
  margin: 0 10px;
}

.formContainer {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.docBox {
  height: auto;
  width: 100%;
  border: 1px solid #707070;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.docBoxImage {
  margin: 20px 0px 0px 15px;
  display: flex;
  flex-direction: row;
}

.docBoxTitle {
  font-size: 15px;
  color: #111;
  font-weight: 700;
  margin-left: 7px;
}

.docBoxMessage {
  margin: 0px 0px 0px 40px;
  font-size: 14px;
  color: #707070;
}

.docBoxMessageFormat {
  font-size: 12px;
  color: #CBCACD;
  margin-left: 40px;
  margin-top: 20px;
}

.uploadInputForm {
  width: 100%;
  visibility: visible;
  margin-left: 35px;
}

.uploadFileInput {
  height: 0px;
  visibility: collapse;
  margin-left: 35px;
}

.uploadDocBox {
  height: 30px;
  position: relative;
  top: -30px;
  left: 5px;
}

.teacherDescriptionTitle {
  font-size: 20px;
  color: #333;
  font-weight: 500;
}

.teacherDescriptionBox {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin: 35px 0px 20px 50px;
}

.stepFiveContent {
  width: 85%;
  display: flex;
  flex-direction: column;
  margin: 0px auto;
}

.textareaMaxText {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 12px;
  font-style: italic;
  color: #636363;
}

.textareaMessageFull {
  display: inline;
  font-size: 12px;
  font-style: italic;
  color: #CBCACD;
}

.textareaMessageSmall {
  display: none;
}

.customTextarea {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.customDescriptionTextarea {
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-style: italic;
  border: 1px solid #707070;
  border-radius: 10px;
  color: "#CBCACD";
  margin-top: 20px;
}

.customLinkedTextarea {
  display: none;
}

.linkedInput {
  display: block;
}

.linkedError {
  font-size: 12px;
  color: red;
}

.thumbnailMessage {
  font-size: 12px;
  color: #707070;
  margin-left: 25px;
}

.explanationBox {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-right: 10px;
}

.explanationBoxTitle {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
}

.questionMark {
  margin-top: 0px;
  margin-right: 5px;
}

.calendarBox {
  background-color: #fff;
  padding: 20px;
}

.stopButton {
  position: fixed;
  bottom: 20%;
  right: 20%;
  width: 70px;
  height: 70px;
  background-color: red;
  cursor: pointer;
}

.videoLoading {
  position: fixed;
  left: 46%;
  top: 43%;
  font-family: "CartoonFont";
  font-size: 34px;
  color: #fff;
}

.videoRecorderBox {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 12.9%;
  right: 12.9%;
  background: black;
}

.videoRecorderClearBox {
  display: flex;
  flex-direction: column;
  margin-top: 4.5%;
  align-items: center;
  height: 70%;
  position: fixed;
  top: 7%;
  left: 0px;
  right: 0px;
  bottom: 2%;
}

.stopButtonBox {
  position: fixed;
  bottom: 25%;
  right: 20%;
}

/* New Css Style */

.avatarBox {
  width: 144px;
  height: 146px;
  background: #ffedd5;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #FFF;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.pageTitle {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  opacity: 0.7;
}

.descriptionTitle {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 400;
  color: #707070;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.newButton {
  width: auto !important;
  height: 45px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
  -moz-box-shadow: 5px 7px 1px -1px #4BB980;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 30px !important;
}

.newActionButton {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60cd95 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
  -moz-box-shadow: 5px 7px 1px -1px #4BB980;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 0px !important;
}

.newMoveButton {
  width: 160px !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 16px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 2px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  margin-top: 0px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.newPictureButton {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none !important;
  text-align: center !important;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 0px !important;
}

.calendarButton {
  width: auto !important;
  height: 35px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none !important;
  text-align: center !important;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #669e9d !important;
  -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
  -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 0px !important;
}

.newButton:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.newActionButton:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.newPictureButton:hover {
  transform: scale(1.03) !important;
  background-color: #60cd95;
  color: #fff;
}

.calendarButton:hover {
  transform: scale(1.03) !important;
  background-color: #80b1b2;
  color: #fff;
}

.levelsBox {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-top: 30px;
}

.levelsBox span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  margin-top: 10px;
}

.levelsButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.levelButton {
  width: 156px;
  height: 50px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
}

.levelButtonActive {
  width: 156px;
  height: 50px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
  margin-right: 20px;
}

.topicButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.topicButton {
  width: 156px;
  height: 50px;
  background: #FFF;
  border: 1px solid #707070;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
}

.topicButtonActive {
  width: 156px;
  height: 50px;
  background: #E4F8F8;
  border: 1px solid #7FB1B2;
  border-radius: 10px;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  color: #7FB1B2;
  margin-right: 20px;
}

.pricesBox {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  margin-top: 30px;
}

.pricesBox span {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
}

.priceBox {
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.price {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  color: #707070;
  text-align: center;
}

.priceLevel {
  position: relative;
  bottom: 10px;
}

.HoursBox {
  width: 100%;
  height: auto;
  background: #F0F0F0;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  margin-top: 30px;
}

.titleDescriptionBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #707070;
  text-align: left;
}

.titleDescriptionBox span {
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  color: #868686;
}

.calendarAvailability {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.newDocumentbuttonBox {
  margin-top: 20px;
  margin-left: 7%;
}

/************************************** MediaQuery **************************************/

@media only screen and (max-width: 767px) {

  .step {
    display: none;
  }

  .step_container {
    display: inline-flex;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: thin;
    gap: 20px;
  }

  .formsteps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    margin-bottom: 10px;
    width: 70px;
  }

  .calendarBox {
    background-color: #fff;
    padding: 10px;
  }

  .questionMark {
    margin-top: -1px;
    margin-right: 5px;
    margin-left: 15px;
  }

  .explanationBoxTitle {
    font-size: 12px;
    font-family: Rubik;
    font-weight: 500;
    color: #7FB1B2;
  }

  .explanationBox {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: start;
    align-items: center;
  }

  .checkboxBoxItemLong {
    margin-left: 5px;
    color: #707070;
    font-size: 10px;
  }

  .instructionBoxLong {
    padding: 50px 20px 0px 30px;
    margin-top: 0px;
  }

  .cardInfoBoxToDo {
    background-image: url(assets/images/teacher/cardLong.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: auto;
    margin-left: 5px;
    margin-top: 15px;
    padding: 0;
  }

  .uploadVideoBoxSubMessage {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .videoExampleBoxTitle {
    font-size: 13px;
    font-weight: 500;
    color: #7FB1B2;
    margin-top: 0px;
    margin-left: 0px;
  }

  .thumbnailMessage {
    font-size: 12px;
    color: #707070;
    margin-left: 25px;
  }

  .startRecordingVideo {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .linkedInput {
    display: none;
  }

  .textareaMessageFull {
    display: none;
  }

  .textareaMessageSmall {
    display: inline;
    font-size: 11px;
    font-style: italic;
    color: #CBCACD;
    margin-left: 5px;
  }

  .textareaMaxText {
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 10px;
    font-style: italic;
    color: #636363;
  }

  .customTextarea {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .customLinkedTextarea {
    display: block;
    width: 100%;
    padding: 5px 10px 5px 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 20px;
  }

  .customDescriptionTextarea {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    font-style: italic;
    border: 1px solid #707070;
    border-radius: 10px;
    color: "#CBCACD";
    margin-top: 10px;
  }

  .stepFiveContent {
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
  }

  .teacherDescriptionBox {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 20px auto;
  }

  .teacherDescriptionTitle {
    font-size: 12px;
    color: #333;
    font-weight: 500;
  }

  /* .rowButtonChild {
    max-width: auto;
    margin-right: 5px;
  } */

  .uploadBoxTitle {
    font-size: 0.8em;
    font-style: italic;
    color: #333;
  }

  .uploadBoxMessage {
    font-size: 0.8em;
    font-style: italic;
    color: #CBCACD;
  }

  .uploadBoxMessageError {
    font-size: 0.8em;
    font-style: italic;
    color: red;
  }

  .uploadDocBox {
    height: 30px;
    position: relative;
    top: -30px;
    left: 35px;
  }

  .uploadInputForm {
    width: 100%;
    visibility: visible;
    margin-left: 5px;
    font-size: 10px;
  }

  .uploadFileInput {
    height: 0px;
    visibility: collapse;
    margin-left: 5px;
  }

  .uploadFileButton {
    margin-left: 0px;
    width: 2% !important;
  }

  .uploadButtonStyle {
    font-size: 12px;
    margin: 0;
    padding: 0;
    width: auto;
  }

  .docBoxMessageFormat {
    font-size: 0.75em;
    color: #707070;
    margin-left: 17px;
    margin-top: 20px;
  }

  .docBoxMessage {
    margin: 0px 0px 0px 40px;
    font-size: 0.75em;
    margin-right: 5px;
    color: #707070;
  }

  .docBoxTitle {
    font-size: 0.75em;
    color: #111;
    font-weight: 700;
    margin-left: 7px;
  }

  .docBoxImage {
    margin: 20px 0px 0px 15px;
    display: flex;
    flex-direction: row;
  }

  .docBox {
    height: auto;
    width: 100%;
    border: 1px solid #707070;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }

  .form__container {
    width: 95%;
    max-width: 600px;
    margin: 40px auto;
  }

  .formContainer {
    width: 95%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding: 0px auto;
  }

  .termesToAccept {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    max-width: 95%;
  }

  .register__teacher {
    padding: 0 3%;
  }

  .teacher .avatar {
    padding-right: 0;
  }

  .teacher .avatar img {
    position: relative;
    width: 30px;
    top: -3px;
    margin: 0;
  }

  .header__text p {
    font-size: var(--normal-font-size);
    margin-top: 3px;
  }

  /* .title__step {
    font-size: 8px;
    white-space: nowrap;
    display: block;
  } */

  .placeholderStyle {
    color: #C0C0C0;
    font-size: 12px;
  }

  .inputRow {
    display: flex;
    flex-direction: column;
  }

  .inputRowChild {
    width: 100%;
  }

  /* .step {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    overflow: auto;
    scrollbar-width: thin;
  } */

  .field {
    height: 40px !important;
    border-radius: 10px !important;
    border: 1px solid var(--text-color) !important;
    font-style: italic !important;
    color: #707070;
    font-size: 0.8em !important;
  }

  .field::placeholder {
    font-size: 8px !important;
  }

  .policy {
    position: relative;
    font-size: 0.75em !important;
  }

  .policy br {
    display: none;
  }

  .uploadImageBoxTitle {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    margin-top: 20px;
    margin-left: 35px;
  }

  .avatarContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .formBox {
    width: 100%;
    max-width: 100%;
    margin: 40px auto;
    border-radius: 5px;
    background-color: #fafafa;
    height: auto;
    padding: 30px 5px 20px 5px;
    display: flex;
    flex-direction: column;
  }

  .stepTitleBox {
    text-align: center;
    align-items: center;
    align-content: center;
  }

  .boxTitle {
    font-size: 18px;
    font-weight: 600;
    color: #707070;
    font-family: "CartoonFont";
    text-align: center;
    text-transform: uppercase;
  }

  .boxMessage {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
  }

  .uploadBoxBorder {
    margin: 20px auto;
    border: none;
    border-radius: 15px;
  }

  #formFileUpload {
    height: 120px;
    width: 90%;
    max-width: 100%;
    text-align: center;
    position: relative;
  }

  .instructionBox {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 8px;
  }

  .checkboxBox {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .checkboxBoxItem {
    margin-left: 10px;
    color: #707070;
    font-size: 0.75em;
  }

  .cardInfoBox {
    background-image: url(assets/images/teacher/card.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 320px;
    width: auto;
    margin-left: 0px;
    margin-right: 15px;
    margin-top: 15px;
    padding-top: 25px;
    padding-left: 25px;
  }

  .exampleBoxTitle {
    font-size: 0.8em;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 15px;
  }

  .avatarExampleBox {
    background-image: url(assets/images/app/pictureExample.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 70px;
    width: 90%;
    border-radius: 8px;
    margin: 5px auto !important;
  }

  .previewBoxImage {
    width: 100px;
    height: auto;
    border-radius: 15px;
  }

  .previewBox {
    display: flex;
    align-items: center;
    justify-items: center;
    border-width: 1px;
    border-radius: 1rem;
    border-color: #707070;
    border-style: dashed;
    width: auto;
    margin: 0px auto;
  }

  .customButtonStyle {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-left: 40px;
    border-radius: 50px;
    background-color: #7fb1b2;
    color: #fff;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1) !important;
    -webkit-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
    -moz-box-shadow: 3px 6px 1px -2px rgba(90, 132, 132, 1);
  }

  .certificateDiplomaFormBox {
    margin: 0px auto;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .pictureBoxRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    align-items: center;
  }

  .picturePreviewBox {
    height: auto;
    width: auto;
    border: 1px solid #333;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    display: flex;
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    margin-top: 20px;
  }

  .videoPreviewBox {
    height: auto;
    width: auto;
    border-radius: 5px;
    margin-left: 0px;
    margin-top: 25px;
  }

  #videoFileUpload {
    background-image: url(assets/images/app/videoExample.png);
    background-size: cover;
    height: 150px;
    width: 100%;
    text-align: center;
    position: relative;
    margin: 25px auto;
    background-repeat: no-repeat;
  }

  .exampleBoxLong {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    position: relative;
    top: -70px;
    width: 100% !important;
    padding-top: 10px;
  }

  .uploadImageBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px auto;
    padding-left: 10px;
    padding-right: 10px;
    width: 100% !important;
  }

  .uploadImageBoxTitle {
    font-size: 0.8em;
    font-weight: 500;
    color: #333;
    margin-top: 15px;
    margin-left: 0px;
  }

  .uploadImageBoxMessage {
    font-size: 0.75em;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 0px;
    color: #707070;
  }

  .vignetteBox {
    display: flex;
    flex-direction: row;
    border: 1px solid #333;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    margin: 20px auto;
    padding: 15px 20px 15px 20px;
    width: auto;
    align-items: center;
    justify-content: left;
  }

  .videoLinkInput {
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .uploadVideoBoxMessage {
    font-size: 12px;
    font-weight: 400;
    margin-left: 0px;
    margin-top: 5px;
    color: #707070;
  }

  .uploadVideoBoxSubtitle {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 30px;
    margin-left: 0px;
  }

  .uploadVideoBoxTitle {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-left: 0px;
  }

  .addVideoButton {
    height: 32px;
    width: auto;
    margin-left: 5px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .trashIcon {
    filter: invert(39%) sepia(100%) saturate(7463%) hue-rotate(359deg) brightness(106%) contrast(113%);
    height: 30px;
    margin-left: 20px;
    margin-top: 5px;
    cursor: pointer;
  }

  .addVignetteButton {
    height: 32px;
    width: 100px;
    margin-left: 5px;
    margin-top: 30px;
  }

  .videoFormatTitle {
    font-size: 13px;
    color: #CBCACD;
    margin-left: 5px;
    margin-top: 10px;
  }

  .calendarBoxTitle {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 5px;
  }

  .calendareBoxMessage {
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    margin-top: 5px;
    color: #707070;
  }

  .calendarBoxSubtitle {
    font-size: 13px;
    font-weight: 500;
    color: #333;
    margin-top: 0px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    margin: 30px auto;
    padding-left: 5px;
  }

  .calendarTimezone {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px auto;
    align-items: center;
    justify-content: center;
  }

  .fillLaterButton {
    height: 50px;
    width: 150px;
    margin-left: 0px;
  }

  .addPictureButton {
    width: 100%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .addDocPictureButton {
    height: auto;
    width: 100%;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .addPictureButtonBox {
    width: auto;
  }

  .previewCertificateDiploma {
    height: auto;
    width: 100%;
    position: relative;
    bottom: 0px;
    margin-left: 17px;
  }

  .nextButton {
    font-size: 12px;
    padding: 0px;
  }

  .title {
    font-size: 14px;
  }

  .rowButtons {
    width: 90% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin: 40px auto !important;
  }

  .rowsButtons {
    width: 90% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 40px auto !important;
  }

  .rowButtonChild {
    max-width: 30%;
    margin: 0 10px;
  }

  .stopButtonBox {
    position: relative;
    bottom: 12%;
    left: -6%;
    margin: 0px auto;
  }

  .videoLoading {
    position: relative;
    left: 0%;
    margin: 0px auto;
    font-family: "CartoonFont";
    font-size: 34px;
    color: #fff;
  }

  .videoRecorderBox {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    background: black;
  }

  .videoRecorderClearBox {
    display: flex;
    flex-direction: column;
    margin-top: 4.5%;
    align-items: center;
    height: 70%;
    position: fixed;
    top: 4%;
    left: 0px;
    right: 0px;
    bottom: 2%;
  }

  .newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
    -moz-box-shadow: 2px 4px 1px -1px #4BB980;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px !important;
  }

  .newMoveButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    margin-top: 0px !important;
  }

  .newPictureButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 0px !important;
  }

  .newActionButton {
    width: auto !important;
    height: 30px !important;
    display: block;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #60cd95 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #4BB980 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #4BB980;
    -moz-box-shadow: 5px 7px 1px -1px #4BB980;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 0px !important;
  }

  .calendarButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none !important;
    text-align: center !important;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #669e9d !important;
    -webkit-box-shadow: 2px 4px 1px -1px #669e9d !important;
    -moz-box-shadow: 2px 4px 1px -1px #669e9d !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 0px !important;
  }

  .newDocumentbuttonBox {
    margin: 20px auto;
  }

  .levelsBox {
    width: 100%;
    height: auto;
    background: #F0F0F0;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 30px;
  }

  .levelsBox span {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #707070;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .levelsButtons {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .topicButtons {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .levelButton {
    width: 100px;
    height: 30px;
    background: #FFF;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
  }

  .topicButton {
    width: 160px;
    height: 30px;
    background: #FFF;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
  }

  .levelButtonActive {
    width: 100px;
    height: 30px;
    background: #E4F8F8;
    border: 1px solid #7FB1B2;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #7FB1B2;
    margin-right: 0px;
  }

  .topicButtonActive {
    width: 160px;
    height: 30px;
    background: #E4F8F8;
    border: 1px solid #7FB1B2;
    border-radius: 5px;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 600;
    color: #7FB1B2;
    margin-right: 0px;
  }

  .pricesBox {
    width: 100%;
    height: auto;
    background: #F0F0F0;
    border: 1px solid #707070;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    margin-top: 30px;
  }

  .pricesBox span {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
    color: #707070;
  }

  .priceBox {
    margin-left: 0px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }

  .price {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-family: Rubik;
    font-weight: 500;
    color: #707070;
    text-align: center;
  }

  .priceLevel {
    position: relative;
    bottom: 10px;
  }

  .HoursBox {
    width: 85%;
    height: auto;
    background: #F0F0F0;
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    margin: 30px auto;
  }

  .titleDescriptionBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1em;
    font-family: Rubik;
    font-weight: 600;
    color: #707070;
    text-align: left;
    margin-bottom: 10px;
  }

  .titleDescriptionBox span {
    font-size: 0.85em;
    font-family: Rubik;
    font-weight: 500;
    color: #868686;
  }

  .calendarAvailability {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}