.details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* Badge style */
.badge {
  background-color: #40a9ff;
  color: #fff;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  position: absolute;
  top: -10px;
  right: 0;
}

/* Name and details */
.name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 10px;
}

.rate {
  font-size: 16px;
  color: #555;
  font-weight: 700;
}

.subjects,
.languages {
  font-size: 14px;
  color: #777;
  text-transform: uppercase;
}

/* Description text */
.description {
  font-size: 16px;
  color: #888;
  line-height: 1.4;
  max-height: 45px;
  /* 3 lines limit */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
}

/* Buttons section */
.actions {
  margin-top: 15px;
}

.actions .antBtn {
  margin-right: 10px;
  border-radius: 20px;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .back__icon {
    position: absolute;
    top: 1.7em;
    cursor: pointer;
  }

  .logoutButton {
    margin-right: 0px;
  }
}

.rowButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}

.newMessageButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.newMessageButton:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1;
  color: #fff;
}

.newInstaButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  margin-left: 0px;
  cursor: pointer;
}

.newInstaButton:hover {
  transform: scale(1.03) !important;
  background-color: #F56666 !important;
  color: #fff;
}

.newPlanningButton {
  width: auto !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-left: 0px;
  cursor: pointer;
}

.newPlanningButton:hover {
  transform: scale(1.03) !important;
  background-color: #8AB0B1 !important;
  color: #fff;
}

@media only screen and (max-width: 1050px) {
  .name {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .rate {
    font-size: 0.8em;
    color: #555;
    font-weight: 700;
  }

  .subjects,
  .languages {
    font-size: 0.75em;
    color: #777;
    text-transform: uppercase;
  }

  /* Description text */
  .description {
    font-size: 0.8em;
    color: #888;
    line-height: 1.2;
    max-height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 10px;
  }

  /* Buttons section */
  .actions {
    margin-top: 15px;
  }

  .actions .antBtn {
    margin-right: 10px;
    border-radius: 20px;
    font-size: 0.75em;
  }

  .rowButtons {
    width: 90%;
    display: flex;
    justify-content: center;
    gap: 5px;
    align-items: center;
  }

  .newMessageButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .newMessageButton:hover {
    transform: scale(1.03) !important;
    background-color: #8AB0B1;
    color: #fff;
  }

  .newInstaButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    cursor: pointer;
  }

  .newInstaButton:hover {
    transform: scale(1.03) !important;
    background-color: #F56666 !important;
    color: #fff;
  }

  .newPlanningButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.75em !important;
    text-transform: none;
    text-align: center;
    background: #8AB0B1 !important;
    color: #fff !important;
    font-weight: 600 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #6A989A !important;
    -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
    -moz-box-shadow: 2px 4px 1px -1px #6A989A;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-left: 0px;
    cursor: pointer;
  }
}

.levels div:last-child {
  display: none;
}

.cardDisplay {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
}

.singleCourse {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-template-columns: none;
}