.articleContainer {
    position: relative;
}

.articleContent {
    margin: 0;
    padding: 10px;
    border: 1px solid #ddd;
    position: relative;
}

.articleContent.hovered {
    background-color: #f5f5f5;
}

.editButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
    color: var(--first-color);
    border: 1px solid var(--first-color);
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50px;
    text-align: center;
}

.editButton:hover {
    background-color: var(--first-color);
    border: 1px solid white;
    color: white;
}

.buttonIcon {
    position: relative;
    top: -2px;
    margin-right: 5px;
}

.__custom__ck_editor {
    border: 1px solid var(--text-color);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: var(--mb-4);
}

.appercue__continute {
    width: 100%;
    height: 80vh;
    min-width: 600px;
    overflow-y: scroll;
    overflow-x: scroll;
    background: #fafafa;
    border-radius: 10px;
    padding: 1.5em 2em;
}

.appercue__continute h3 {
    font-size: 18px;
    margin-bottom: var(--mb-2);
}

.__latext__content * strong {
    font-weight: 600;
}