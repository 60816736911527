.countdown {
  color: var(--first-color);
  font-size: 25px;
  font-weight: 500;
}

.countdown.danger {
  color: #ff0000 !important;
  font-size: 25px;
  font-weight: 500;
}
