.font {
  font-family: "Rubik";
}
.time {
  font-size: 20px;
  font-weight: 600;
}
.label {
  font-size: 17px;
  font-weight: 200;
}
