.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: var(--mb-1);
  padding-bottom: var(--mb-6);
}

.__avatar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.__top>div span {
  color: var(--text-color);
}

.__top h3 {
  width: 100%;
  max-width: 150px;
  text-align: center;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}


/* Select */
.select div {
  height: 32px !important;
  background: var(--first-color) !important;
  border: unset !important;
  border-radius: 50px !important;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
  /* margin-top: 15px; */
}

.select div span {
  position: relative;
  left: 10px;
  padding-top: 2px !important;
  font-size: 11px;
  text-align: center;
  color: #fff !important;
  text-transform: uppercase !important;
  font-weight: 500 !important;
}

.select * {
  color: #fff !important;
}

@media only screen and (max-width: 1050px) {
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 25%;
    padding-bottom: 0;
  }

  .__top h3 {
    width: 100%;
    max-width: 100%;
    text-align: center;
    font-size: 0.7em;
    text-transform: uppercase;
    font-weight: 500;
  }

  .select div {
    height: 22px !important;
    background: var(--first-color) !important;
    border: unset !important;
    border-radius: 50px !important;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 2px 5px 1px -1px rgba(90, 132, 132, 1) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .select div span {
    position: relative;
    left: 0px;
    padding-top: 2px !important;
    font-size: 0.75em;
    text-align: center;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 500 !important;
  }

  .__bottom {
    height: 25px !important;
  }
}