.validCheckbox {
    height: 18px;
    width: 18px;
    background-color: white;
    border: 1px solid #333;
    border-radius: 3px;
}

.checkIcon {
    position: relative;
    top: -5px;
}

.closeIcon {
    position: relative;
    right: 2px;
    bottom: 3px;
}