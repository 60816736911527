.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.leftBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 55px;
}

.ellipseTitle {
    height: 110px;
    position: relative;
    left: 8%;
    bottom: 5px;
}

.message {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .leftBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 5%;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
    }

    .ellipseTitle {
        height: 40px;
        position: relative;
        left: 0%;
        bottom: 5px;
    }

    .message {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

}