.drawer__title {
  margin-top: 5px;
}

.drawer__search {
  width: 85%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-bottom: 30px;
  padding-right: 50px;
  padding-top: 30px;
  background: #FFF8F7;
  border: 1px solid #F56666;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.drawer__title h1 {
  font-size: 1.4em;
}

.drawer__title p {
  font-weight: 400;
  text-align: center;
  font-family: var(--body-font);
  text-transform: initial;
  margin-bottom: 0;
  font-size: 13px;
}

.field {
  background: #fff;
  padding: 0.2em;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #707070;
  margin-bottom: 1em;
}

.fullField {
  background: #fff;
  padding: 0.2em;
  width: 100%;
  border-radius: 24px;
  border: 1px solid #707070;
  margin-bottom: 1em;
}

.button {
  background: #0ed290 !important;
  height: 35px !important;
  box-shadow: 3px 6px 1px -1px #0ed29198 !important;
}

.instaButton {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
  -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 30px !important;
}

.instaTeacherButton {
  width: auto !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  border-radius: 50px !important;
  font-size: 14px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
  -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  text-align: center !important;
}

.main {
  width: 100%;
  height: 700px;
  overflow-y: scroll;
  background: #fafafa;
  padding: 2%;
  border-radius: 10px;
}

.title__alert {
  color: #0ed290;
  font-size: 20px;
}

.wait {
  text-align: center;
}

.wait h3 {
  font-size: 25px;
  font-family: var(--title-font);
  margin-top: 0.5em;
}

@media only screen and (min-width: 768px) {
  .grid {
    gap: 1em;
  }
}

@media only screen and (max-width: 767px) {
  .drawer__title h1 {
    font-size: 1em;
  }

  .drawer__title p {
    font-size: 11px;
  }

  .drawer__title p br {
    display: none;
  }

  .field {
    padding: 0.2em;
    width: 150px;
  }

  .fifullFieldeld {
    padding: 0.2em;
    width: 100%;
  }

  .button {
    margin-top: 1em;
  }

  .wait h3 {
    font-size: 20px;
  }

  .main {
    margin-top: 2%;
  }
}

/* New Css Styles */

.titleBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.detailsBoxTitle {
  font-size: 16px;
  font-family: Rubik;
  font-weight: 600;
  color: #393E3E;
}

.detailsBoxMessage {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #707070;
}

.searchBox {
  width: 100%;
  height: 65px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 24px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selectBox {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.separation {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.amountField {
  width: 205px;
  position: relative;
  left: -12px;
}

.amountField input::placeholder {
  font-size: 12px !important;
  color: #000 !important;
  opacity: 0.8 !important;
}

.amountField::-webkit-input-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

.amountField:-moz-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

.amountField::-moz-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

.amountField:-ms-input-placeholder {
  font-size: 12px;
  color: #000;
  opacity: 1;
}

@media only screen and (max-width: 1050px) {
  .drawer__search {
    width: 90%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    padding-top: 20px;
    background: #FFF8F7;
    border: 1px solid #F56666;
    border-radius: 24px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .detailsBoxTitle {
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 600;
    color: #393E3E;
  }

  .detailsBoxMessage {
    font-size: 0.65em;
    font-family: "Poppins";
    font-weight: 400;
    color: #707070;
  }

  .searchBox {
    width: 100%;
    height: auto;
    background: #FFF8F7;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 0px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 15px auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .selectBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 0.75em !important;
    text-align: left;
    opacity: 1;
    background: #FFF;
    border: 0.5px solid #BFBCBC;
    border-radius: 12px;
    height: 35px !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .amountBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 0.75em !important;
    text-align: left;
    opacity: 1;
    background: #FFF;
    border: 0.5px solid #BFBCBC;
    border-radius: 12px;
    height: 35px !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .separation {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 20px;
    display: none;
  }

  .amountField {
    width: 100%;
    position: relative;
    left: 0px;
  }

  .textareaField::placeholder {
    font-size: 0.65em !important;
  }

  .amountField input::placeholder {
    font-size: 0.65em !important;
    color: #000 !important;
    opacity: 0.8 !important;
  }

  .amountField::-webkit-input-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .amountField:-moz-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .amountField::-moz-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .amountField:-ms-input-placeholder {
    font-size: 0.75em;
    color: #000;
    opacity: 1;
  }

  .instaButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
    -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 30px !important;
  }

  .instaTeacherButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
    -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    text-align: center !important;
  }
}