.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.faqContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    text-align: center;
    z-index: -1;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 10px;
    z-index: 1;
}

.ellipseTitle {
    position: relative;
    left: -50px;
    width: 35px;
    bottom: 45px;
    z-index: -1;
}

.mainBox {
    display: flex;
    flex-direction: column;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 10px;
    padding-left: 20%;
    padding-right: 20%;
    background: white;
    z-index: 1;
}

.faqBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    width: 45%;
    border-radius: 45px;
}

.faqBox:hover {
    box-shadow: 14px 30px 34px #BFBCBC2F;
}

.faqMainBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 95%;
    margin-top: 30px;
}

.arrowBox {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
}

.faqTitle {
    font-size: 20px;
    font-family: "Poppins";
    font-weight: bold;
    color: #393E3E;
    line-height: 30px;
    text-align: left;
    position: relative;
    top: -10px;
}

.faqContent {
    font-size: 15px;
    font-family: "Poppins";
    font-weight: bold;
    color: #6A6564;
    line-height: 27px;
    text-align: left;
}

.quote {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -375px;
    left: -34.5%;
}

.quote:hover {
    color: rgba(162, 159, 159, 0.08);
}

@media only screen and (max-width: 680px) {

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 10px;
        z-index: 1;
    }

    .ellipseTitle {
        position: relative;
        left: -30px;
        width: 25px;
        bottom: 35px;
        z-index: -1;
    }

    .mainBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 40px;
        padding-left: 25px;
        padding-right: 25px;
        background: white;
        z-index: 1;
    }

    .faqBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px;
        width: 45%;
        border-radius: 45px;
    }

    .faqBox:hover {
        box-shadow: 14px 30px 34px #BFBCBC2F;
    }

    .faqMainBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 0px;
        height: auto;
    }

    .arrowBox {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        width: 100%;
    }

    .faqTitle {
        font-size: 15px;
        font-family: "Poppins";
        font-weight: bold;
        color: #393E3E;
        line-height: 20px;
        text-align: left;
        position: relative;
        top: -10px;
    }

    .faqContent {
        font-size: 13px;
        font-family: "Poppins";
        font-weight: bold;
        color: #6A6564;
        line-height: 20px;
        text-align: left;
    }

    .quote {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -375px;
        left: -34.5%;
    }

}