.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    margin-top: 0px;
    z-index: 1;
}

.subtitle {
    font-size: 18px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 0px;
}

.titleEllipse {
    position: relative;
    left: -128px;
    width: 35px;
    bottom: 50px;
    z-index: -1;
}

.boxes {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.boxOneTwo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 32%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 30px;
}

.boxThree {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    width: 32%;
    background: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 15px;
}

.boxTitle {
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 20px;
    color: rgb(8, 7, 7);
    line-height: 20px;
}

.boxSubtitle {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(8, 7, 7);
    line-height: 20px;
}

.messageBox {
    display: flex;
    flex-direction: row;
    height: 580px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -55px;
    left: 0px;
    right: 0px;
}

.secondBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 380px;
    width: 100%;
    background: rgb(255, 248, 242);
    position: relative;
    top: -50px;
    text-align: left;
}

.textBox {
    width: 27.5%;
    font-family: "Poppins";
    font-weight: normal;
    font-size: 20px;
    color: rgb(8, 7, 7);
    padding-top: 5%;
}

.textBoxDescription {
    line-height: 44px;
    position: relative;
    left: 72%;
}

.imagesBox {
    height: 560px;
    width: 470px;
    position: relative;
    top: -15%;
    right: 18%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mainImageBox {
    height: 275px;
    width: 470px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.secondaryImageBox {
    height: 275px;
    width: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.studentOneBox {
    height: 275px;
    width: 470px;
}

.studentOneBoxImage {
    height: 275px;
    width: 470px;
}

.studentTwoBox {
    height: 155px;
    width: 215px;
}

.studentTwoBoxImage {
    height: 155px;
    width: 215px;
}

.studentThreeBox {
    height: 110px;
    width: 215px;
}

.studentThreeBoxImage {
    height: 110px;
    width: 215px;
}

.studentFourBox {
    height: 275px;
    width: 245px;
}

.studentFourBoxImage {
    height: 275px;
    width: 245px;
}

.flecheVerte {
    position: relative;
    left: -32%;
    width: 150px;
    top: 80px;
    z-index: 1;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
        margin-top: 0px;
        margin-left: 30px;
        z-index: 1;
    }

    .subtitle {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 0px;
        margin-left: 30px;
        text-align: left;
    }

    .titleEllipse {
        position: relative;
        left: 30px;
        width: 18px;
        bottom: 30px;
        z-index: -1;
    }

    .boxes {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 0px auto;
        padding-left: 30px;
        padding-right: 30px;
    }

    .boxOneTwo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 104px;
        width: 100%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 15px auto;
    }

    .boxThree {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 104px;
        width: 100%;
        background: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 30px;
        padding-right: 30px;
        margin: 15px auto;
    }

    .messageBox {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .secondBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 250px;
        width: 100%;
        background: rgb(255, 248, 242);
        text-align: left;
        padding: 20px;
        margin-top: 100px;
        margin-bottom: 370px;
    }

    .textBox {
        width: 100%;
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(8, 7, 7);
        padding-top: 5%;
    }

    .textBoxDescription {
        line-height: 24px;
        position: relative;
        left: 0%;
    }

    .imagesBox {
        height: 560px;
        width: 100%;
        position: relative;
        top: 20px;
        right: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .studentOneBox {
        height: 225px;
        width: 100%;
        margin-bottom: 10px;
    }

    .studentOneBoxImage {
        height: 225px;
        width: 100%;
    }

    .mainImageBox {
        height: 275px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .secondaryImageBox {
        height: 275px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 10px;
    }

    .studentTwoBox {
        height: 115px;
        width: 100%;
        margin-bottom: 10px;
    }

    .studentTwoBoxImage {
        height: 115px;
        width: 100%;
    }

    .studentThreeBox {
        height: 110px;
        width: 100%;
    }

    .studentThreeBoxImage {
        height: 110px;
        width: 100%;
    }

    .studentFourBox {
        height: 235px;
        width: 100%;
    }

    .studentFourBoxImage {
        height: 235px;
        width: 100%;
    }

    .flecheVerte {
        position: relative;
        left: -2%;
        height: 50px;
        top: 20px;
        z-index: 1;
        transform: rotate(20deg);
    }

}