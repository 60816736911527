.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 25px;
  padding-top: 10px;
  text-align: left;
}

.button {
  max-width: 150px;
  min-width: 150px;
  height: 30px !important;
  margin-top: 10px;
}

.center h3 {
  width: 100%;
  max-width: 160px;
  font-family: var(--title-font);
  font-size: 1.3em;
  margin-top: var(--mb-2);
  text-transform: uppercase;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 50px;
  font-weight: 500;
  text-align: center;
}

/* .bottom {
  margin-left: var(--mb-6);
} */

.bottom .team {
  font-style: italic;
}

.number {
  position: absolute;
  display: flex;
  align-items: center;
  top: 1em;
  right: 30px;
  z-index: 1;
}

.number span {
  font-weight: 500;
  margin-left: 0.3em;
  font-size: 1.2em;
}

.level_quiz {
  margin-top: var(--mb-1);
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .number {
    right: 75px;
  }
}