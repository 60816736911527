.menuButton {
    background: #FAFAFA !important;
    margin-bottom: 25px;
    border: 1px solid #FAFAFA !important;
    padding-left: 0px !important;
}

.newConversationButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 1em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-bottom: 10px !important;
    cursor: pointer;
}

.actionButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 50px !important;
    font-size: 1em !important;
    text-transform: none;
    text-align: center;
    background: #FAFAFA !important;
    color: #707070 !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FAFAFA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FAFAFA;
    -moz-box-shadow: 2px 4px 1px -1px #FAFAFA;
    padding-left: 0px !important;
    padding-right: 20px !important;
    margin-bottom: 10px !important;
    cursor: pointer;
}

.actionButton:hover {
    color: #80b1b2;
}

.lineSeparator {
    height: 1px;
    width: 100%;
    background: #707070;
    margin-top: 15px;
    margin-bottom: 10px;
    opacity: 0.7;
}

.userAvatarBox {
    width: 100%;
    height: 65px;
    background: transparent;
    border: 1px solid #868686;
    border-radius: 20px;
    padding: 10px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    margin-top: 40px;
}

.userAvatarBoxMenuClosed {
    width: 65px;
    height: 65px;
    background: transparent;
    border: 1px solid #868686;
    border-radius: 50%;
    padding: 10px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    margin-top: 30px;
}

.userNameBox {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    color: #393E3E;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
}

.userNameBox span {
    color: #393E3E;
    font-size: 12px;
    font-family: "Poppins";
    font-weight: 400;
}

.chatBox {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B9B9B9;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    height: 89vh;
    width: 75%
}

.chatBoxMenuClosed {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B9B9B9;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    height: 89vh;
    width: 110%
}

.emptyChat {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-bottom: 7%;
    margin-top: 7%;
    padding-left: 7%;
    padding-right: 7%;
    width: 100% !important;
}

.emptyChatAlbert {
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
}

.emptyChatAlbertMessage {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-left: 5%;
    font-size: 30px;
    font-family: "Poppins";
    font-weight: 600;
    color: #8AB0B1;
    width: 100%;
}

.messageSubtitle {
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #393E3E;
    width: 40vw !important;
}

.topicsBox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    width: 100%;
    margin-top: 5%;
}

.topicButton {
    height: 65px;
    width: 210px;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    box-shadow: 0px 3px 10px #BFBCBC3D;
    border: 1px solid #868686;
    border-radius: 18px;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
}

.messagesBox {
    padding: 10px;
    overflow-y: scroll;
    scrollbar-width: thin;
    height: 73vh !important;
}

.messagesBoxImagePreview {
    padding: 10px;
    overflow-y: scroll;
    scrollbar-width: thin;
    height: 60vh !important;
}

.inputBox {
    padding: 10px;
    position: absolute;
    bottom: 100px;
    left: 27%;
    right: 3%;
    z-index: 77;
}

.inputBoxClosedMenu {
    padding: 10px;
    position: absolute;
    bottom: 120px;
    left: 12%;
    right: 3%;
    z-index: 77;
}

.inputTextarea {
    /* height: 100px !important; */
    width: 100%;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    padding: 10px;
    z-index: 77;
}

.inputTextareaClosedMenu {
    /* height: 100px !important; */
    width: 100%;
    border: 1px solid #B9B9B9;
    border-radius: 24px;
    padding: 10px;
    z-index: 77;
}

.messageButtonRow {
    width: 24vw;
    height: 40px;
    position: relative;
    top: -20px;
    left: 10%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.messageButtonRowAI {
    width: 24vw;
    height: 40px;
    position: relative;
    top: -20px;
    left: 10%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.albertAvatar {
    height: 52px;
    width: 52px;
    border: 1.5px solid #FFDCAB;
    background: #FFF;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 5px;
}

.userAvatar {
    height: 52px;
    width: 52px;
    border: 1.5px solid #FFDCAB;
    background: #FFF;
    border-radius: 50%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute;
    right: 1.5%;
    margin-top: 5.5%;
}

.messageActionButton {
    height: 25px;
    width: auto;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border: 0.5px solid #B9B9B9;
    border-radius: 5px;
    font-size: 10px;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    background: #FFF;
    margin-left: 10px;
}

.footer-content {
    white-space: pre-wrap;
    line-height: 1.5;
    padding: 1rem;
}