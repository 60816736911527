.userBox {
    height: 55px;
    width: 90%;
    background: #A3E2E3;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #80B1B2 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #80B1B2;
    -moz-box-shadow: 3px 3px 1px 3px #80B1B2;
    margin: 10px auto;
}

.currentUserBox {
    height: 55px;
    width: 90%;
    background: #80B1B2;
    border-radius: 12px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
    -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
    -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
    margin: 10px auto;
}

.userNavInfoLeft {
    height: auto;
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 5px;
}

.userNavInfoRight {
    height: auto;
    width: 35%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.currentUserRight {
    font-weight: 500;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: white;
}

.right {
    font-weight: 500;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #707070;
}

.currentUserClassementInfo {
    font-size: 0.4em;
    margin-left: 0px;
    position: relative;
    top: -5px;
    color: white;
}

.classementInfo {
    font-size: 0.4em;
    margin-left: 0px;
    position: relative;
    top: -5px;
    color: #707070;
}

.currentUserName {
    font-size: 0.85em;
    font-weight: 500;
}

.userName {
    font-size: 0.85em;
    font-weight: 500;
    color: #707070;
}

.currentUserLastname {
    font-size: 0.9em;
    font-weight: 600;
}

.userLastname {
    font-size: 0.9em;
    font-weight: 600;
    color: #707070;
}

.schoolUserName {
    width: 250px;
    height: 35px;
    overflow: hidden;
    font-size: 1em;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.schoolUserName:hover {
    overflow: visible;
}

@media only screen and (max-width: 767px) {

    .userBox {
        height: 55px;
        width: 90%;
        background: #A3E2E3;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #80B1B2 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #80B1B2;
        -moz-box-shadow: 3px 3px 1px 3px #80B1B2;
        margin: 10px auto;
    }

    .currentUserBox {
        height: 55px;
        width: 90%;
        background: #80B1B2;
        border-radius: 12px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-shadow: 3px 3px 1px 3px #A3E2E3 !important;
        -webkit-box-shadow: 3px 3px 1px 3px #A3E2E3;
        -moz-box-shadow: 3px 3px 1px 3px #A3E2E3;
        margin: 10px auto;
    }

    .userNavInfoLeft {
        height: auto;
        width: 15%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .userNavInfoRight {
        height: auto;
        width: 35%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .currentUserRight {
        font-weight: 500;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
    }

    .right {
        font-weight: 500;
        font-size: 1.2em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #707070;
    }

    .currentUserClassementInfo {
        font-size: 0.4em;
        margin-left: 0px;
        position: relative;
        top: -5px;
        color: white;
    }

    .classementInfo {
        font-size: 0.4em;
        margin-left: 0px;
        position: relative;
        top: -5px;
        color: #707070;
    }

    .schoolUserName {
        width: 250px;
        height: 35px;
        overflow: hidden;
        font-size: 1.2em;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .schoolUserName:hover {
        overflow: visible;
    }
}