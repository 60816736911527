.quiz {
  margin-bottom: 3em;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 3em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 810px;
  background: #fafafa;
  border-radius: 10px;
  padding: var(--mb-6) 0;
}

.content {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.content__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--mb-2);
}

.header__left {
  flex: 1;
}

.header__left span {
  font-family: "CartoonFont";
  font-size: var(--h1-font-size);
  text-transform: uppercase;
}

.header__right {
  text-align: left;
  flex: 1.6;
}

.header__right span {
  font-size: var(--h2-font-size);
  color: #333333;
}

.field {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  margin-bottom: var(--mb-3);
  padding-top: 5px;
  border: 1px solid var(--text-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.submit {
  width: 210px;
  border: none;
  font-size: 0.9em;
  background: var(--text-color);
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  height: 40px;
  margin-left: var(--mb-2);
  border-radius: 50px;
}

.response__img {
  position: relative;
  top: 15px;
  margin-left: var(--mb-4);
}

.row {
  position: relative;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--mb-3);
}

.choices {
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 0 var(--mb-4);
}

.option img:nth-child(1) {
  padding-left: var(--mb-2);
}

/* Checkbox */

.checkbox {
  position: relative;
  margin-right: var(--mb-3);
}

.checkbox input[type="checkbox"] + label {
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid #555;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-out 200ms;
  text-indent: 50px;
  white-space: nowrap;
  color: #555;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.checkbox input[type="checkbox"] + label:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 10px;
  border-bottom: 3px solid #5d9c2d;
  border-left: 3px solid #5d9c2d;
  top: 40%;
  left: 40%;
  transform-origin: bottom left;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all ease-out 200ms;
}

.checkbox input[type="checkbox"]:checked + label {
  border: 1px solid var(--text-color);
}

.checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
  width: 20px;
}

.chk {
  display: none;
}

.save {
  margin-top: var(--mb-4);
}

.__remove__input,
.__add__input {
  height: 40px !important;
  border-radius: 50px !important;
  background: var(--text-color) !important;
  color: #fff !important;
  border: unset !important;
}

.__remove__input {
  background: #ff0000 !important;
}

.__field__content {
  position: relative;
}

.__second {
  top: 6.5em;
  right: -24% !important;
}

.__remove {
  position: relative;
  left: 5em;
  top: 0.95em;
}

.__submit__validation {
  min-width: 300px;
}
