.drawerBox {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    padding: 40px;
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    font-size: 12px;
    width: 480px;
}

.blurOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.28);
    backdrop-filter: blur(24px) brightness(18%);
    -webkit-backdrop-filter: blur(24px) brightness(18%);
    z-index: 0;
    border-radius: 0px 62px 62px 0px;
}