.header {
  width: 100%;
  margin-bottom: 0.8em;
}

.filter {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-2);
  padding: 0 var(--mb-4);
}

.filed__search {
  width: 300px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 2px;
}

.filter__left * input::placeholder,
.filter__right * input::placeholder {
  color: var(--text-color);
}

.filter__right {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.select {
  height: 40px;
  width: 140px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
}

.select {
  padding-top: 3px !important;
  text-align: left;
}

.__card {
  width: 400px;
  height: 350px;
}

.affix {
  position: relative;
  width: 170px;
  height: 50px;
  background: url("/src/assets/images/app/Instaprof_button.png") no-repeat center center;
  background-size: contain;
  cursor: pointer;
}

.instaprof__mobile {
  display: none;
}

.display__filter__mobile {
  display: none;
}

@media only screen and (min-width: 768px) {
  .grid {
    gap: 2em;
  }
}

@media only screen and (max-width: 700px) {
  .filter {
    height: auto;
    display: flex;
    flex-direction: column;

    padding: 3%;
  }

  .filed__search {
    width: 100%;
    padding: 0;

    margin-bottom: 0;
  }

  .filter__left * input::placeholder,
  .filter__right * input::placeholder {
    font-size: 13px;
  }

  .filter__left {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .filter__right {
    width: 100%;
    justify-content: space-between;
  }

  .display__filter__mobile {
    display: block;
  }

  /* .filter__right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

  .main {
    padding: 0 5%;
  }

  .select {
    width: 43vw;
    height: 38px;
    margin-top: 10px;
  }

  .affix {
    display: none;
  }

  .instaprof__mobile {
    position: fixed;
    display: block;
    width: 75%;
    height: 50px;
    background: #fff;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 1px solid #BFBCBC;
    bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2%;
    border-radius: 16px;
  }

  .buttonInsta {
    background: #F56666 !important;
    height: 30px !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    font-size: 0.8em !important;
    text-transform: capitalize;
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: auto !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    font-size: 13px !important;
    text-align: center !important;
    color: #FFF !important;
  }
}

/* New Css Styles */

.lineBox {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
}

.main {
  padding-top: 20px;
}

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.titlePictureBox {
  height: 55px;
  width: 55px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFDCAB80;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.newInstaButton {
  width: auto !important;
  height: 40px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 5px 7px 1px -1px #FFC8BA;
  -moz-box-shadow: 5px 7px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.newInstaButton:hover {
  transform: scale(1.03) !important;
  background-color: #F56666;
  color: #fff;
}

.searchBox {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selectBox {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.separation {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.inputBox {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.filedSearch {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.currentPagebox {
  width: 60px;
  height: 30px;
  background: #FFF;
  border: 0.5px solid #707070;
  border-radius: 9px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.newButton {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 20px;
  padding-right: 20px;
}

.searchModalContentBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #FAFAFA;
  box-shadow: 4px 8px 16px #80808029;
  border: 1px solid #BFBCBC;
  border-radius: 20px;
  opacity: 1;
  width: 700px;
  padding-right: 12%;
  padding-left: 10%;
  z-index: 25;
}

.searchContentBox {
  height: 100% !important;
  width: auto !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.searchContentTitle {
  color: #333333;
  font-size: 22px;
  font-family: Rubik;
  font-weight: 500;
}

.modalImage {
  width: 150px;
  height: auto;
  margin-bottom: 40px;
  margin-top: 40px;
}

.modalSubtitle {
  font-size: 16px;
  color: #707070;
  margin-bottom: 20px;
  position: relative;
  top: -25px;
}

.modalForm {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  top: -25px;
}

.modalSelect {
  width: 170px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.searchArrow {
  position: relative;
  top: -25px;
  left: -50%;
}

.searchButton {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 50px !important;
  padding-right: 50px !important;
  position: relative !important;
  top: -25px !important;
}

@media only screen and (max-width: 1050px) {
  .searchModalContentBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
    box-shadow: 4px 8px 16px #80808029;
    border: 1px solid #BFBCBC;
    border-radius: 20px;
    opacity: 1;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 25;
  }

  .searchContentTitle {
    color: #333333;
    font-size: 0.8em;
    font-family: Rubik;
    font-weight: 500;
  }

  .modalImage {
    width: auto;
    height: 170px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .modalSubtitle {
    font-size: 0.7em;
    color: #707070;
    margin-bottom: 20px;
    position: relative;
    top: 0px;
  }

  .modalForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 95%;
    position: relative;
    top: 0px;
  }

  .modalSelect {
    width: 95%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .searchButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.8em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 2px 4px 1px -1px #5a8484;
    -moz-box-shadow: 2px 4px 1px -1px #5a8484;
    padding-left: 40px !important;
    padding-right: 40px !important;
    position: relative !important;
    top: 0px !important;
  }

  .headerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .titleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.85em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
  }

  .titlePictureBox {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonBox {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }

  .newInstaButton {
    width: auto !important;
    height: 25px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 13px !important;
    text-transform: none;
    text-align: center;
    background: #F56666 !important;
    color: #fff !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 1px 3px 1px -1px #FFC8BA !important;
    -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
    -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 5px !important;
    padding-right: 10px !important;
  }

  .searchBox {
    width: 95%;
    height: auto;
    background: #FAFAFA;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 15px auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .selectBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 0.75em !important;
    text-align: left;
    opacity: 1;
    background: #FFF;
    border: 0.5px solid #BFBCBC;
    border-radius: 12px;
    height: 35px !important;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
  }

  .separation {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 20px;
    display: none;
  }

  .inputBox {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin-left: 0px;
    border: 0.5px solid #BFBCBC;
    border-radius: 16px;
    padding-right: 5px;
    background: #FFF;
  }

  .filedSearch {
    width: 90%;
    height: 35px;
    background: #FFF;
    border: 0px solid #BFBCBC;
    border-radius: 16px;
    opacity: 1;
  }

  .searchInput::placeholder {
    font-size: 0.75em !important;
  }

  .lineBox {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 15px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    padding: 0%;
    align-items: center;
    justify-content: center;
  }
}