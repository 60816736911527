.top_righ {
  position: absolute;
  width: 550px;
  height: 550px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transform: translate3d(25px, -25px, 0);
  color: #fff;
  top: 12em;
  transition: transform 0.5s cubic-bezier(0.3, 1.4, 0.5, 0.9);
}

.top_righ.open {
  transform: translate3d(0, 0, 0);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  background: #FFF;
  padding: 2.5px;
}

.disc {
  position: absolute;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font: 25px Alegreya Sans, sans-serif;
  line-height: 40px;
  padding-top: 10px;
  border-radius: 285px;
  transform: scale3d(0.5, 0.5, 0.5) rotate3d(0, 0, 1, 0deg);
  pointer-events: none;
  opacity: 0;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.3, 1.4, 0.5, 0.9), opacity 0.5s;
}

.disc div {
  transform: rotate(0deg);
}

.open .disc {
  pointer-events: auto;
  opacity: 1;
  color: #fff;
}

.l1 {
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: #5f9b9cae;
  transition-delay: 0s;
}

.open .l1 {
  transition-delay: 0s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.open .l1:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.open .l1:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.open .l1.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.l2 {
  top: 50px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  background: #337e7f70;
  transition-delay: 0.1s;
}

.open .l2 {
  transition-delay: 0.1s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.open .l2:hover {
  background: shade(#5f9b9c, 90%);
  color: #fff;
  transition-delay: 0s;
}

.open .l2:active {
  background: shade(#5F9B9C, 50%);
  color: #fff;
}

.open .l2.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.l3 {
  top: 115px;
  left: 115px;
  right: 115px;
  bottom: 115px;
  background: #337e7f8a;
  transition-delay: 0.2s;
}

.open .l3 {
  transition-delay: 0.2s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.open .l3:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.open .l3:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.open .l3.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.l4 {
  top: 175px;
  left: 175px;
  right: 175px;
  bottom: 175px;
  background: #337e7f9c;
  transition-delay: 0.3s;
  /* border: 2px solid red; */
}

.open .l4 {
  transition-delay: 0.3s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.open .l4:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.open .l4:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.open .l4.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 10deg);
}

.l5 {
  top: 225px;
  left: 225px;
  right: 225px;
  bottom: 225px;
  background: #337E7F;
  transition-delay: 0.4s;
}

.open .l5 {
  transition-delay: 0.4s;
  transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg);
  opacity: 1;
}

.open .l5:hover {
  background: shade(#fff, 90%);
  color: #fff;
  transition-delay: 0s;
}

.open .l5:active {
  background: shade(#fff, 50%);
  color: #fff;
}

.open .l5.toggle {
  transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, 0deg);
}

.toggle {
  line-height: 100px;
  padding: 0;
  width: 100px;
  background: #337e7fed;
  opacity: 1;
  transform: none;
  pointer-events: auto;
  transition-delay: 0s;
}

.open .toggle {
  transform: rotate3d(0, 0, 1, 0deg);
}

.toggle:hover {
  background: shade(#fff, 90%);
  /* text-decoration: underline; */
}

.toggle:active {
  background: shade(#fff, 50%);
  color: rgba(255, 215, 0, 0.5);
  transform: scale(0.9);
  transition-duration: 0s;
}

.content {
  position: relative;
  width: auto;
  text-align: left;
  right: -60%;
  line-height: 8px;
  z-index: 99 !important;
}

.content h3 {
  font-family: var(--title-font);
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  padding: 0;
  margin: 0;
  line-height: 30px;
}



.content span {
  font-size: 13px;
  font-weight: 600;
}

.__tag {
  padding: 6px 12px;
  background: #FAEB89;
  margin-left: 10px;
  border-radius: 15px;
  color: #337E7F;
  display: flex;
}

.__tag span {
  margin: auto;
  font-size: 12px;
}

.__info {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background: #337E7F;
  margin-left: 4px;
  display: flex;
}

.__info span {
  margin: auto;
  font-size: 7px;
  color: #fff;
}

.__row {
  display: flex;
  width: 300px;
  height: 25px;
}

/*  */
.l4 .content {
  position: relative;
  top: 65px;
  margin-left: 55px;
}

.l3 .content {
  position: relative;
  top: 40px;
  margin-left: 40px;
}

.l2 .content {
  position: relative;
  top: 20px;
  margin-left: 25px;
}

.l1 .content {
  position: relative;
  top: 5px;
  margin-left: 15px;
}