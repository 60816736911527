.cardChapter {
  width: 100%;
  /* max-width: 450px; */
  /* margin-bottom: 1em; */
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
  padding: 1em 1.5em;
  transition: 0.3s;
  /* Chapitre 1 */
}

.title__chapter span {
  color: #CBCACD;
}