.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin-left: var(--mb-4);
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

/*
.row {
  width: 75%;
  margin-top: ;
  display: flex;
  justify-content: space-around;
  margin-right: auto;
} */

.row {
  width: 100%;
  display: flex;
  margin-top: 4em;
  /* grid-template-columns: repeat(auto-fit, minmax(260px, 1fr)); */
}

.button {
  max-width: 240px !important;
  min-width: 240px !important;
}

@media only screen and (max-width: 768px) {

  .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4em;
  }

}