.paragraphe {
  width: 100%;
  height: 100%;
  max-width: 80%;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

}

.title {
  color: var(--first-color);
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1em;
  color: #FE3232;
}


.field {
  width: 100%;
  height: 40px;
  border: 1px solid #707070;
  border-radius: 8px;
}

.input ::placeholder {
  font-style: italic;
  font-size: 14px;
}

.modal {
  border-radius: 5px;
  box-shadow: 8px -8px 0px -1px #D6D6D6;
}

.button {
  border: none;
  width: 150px;
  padding: 0.7em;
  text-transform: uppercase;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
}

.ignorer {
  background: #FF4846;
  box-shadow: 3px 6px 1px -1px #b40502;
  /* -webkit-box-shadow: 3px 6px 1px -1px #059c67;
   -moz-box-shadow: 3px 6px 1px -1px #059c67; */
}

.accepter {
  background: #10E29B;
  box-shadow: 3px 6px 1px -1px #059c67;
  -webkit-box-shadow: 3px 6px 1px -1px #059c67;
  -moz-box-shadow: 3px 6px 1px -1px #059c67;
}


.img__albert {
  width: 50px;
  height: 50px;
}

.img__albert img {
  width: 100%;
  height: 100%;
}