.card {
  width: 280px;
  height: 260px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-size: contain;
  padding-top: 1.5em;
  padding-bottom: var(--mb-6);
  margin-right: 1em !important;
  background: url('../../../../assets/images/teacher/card.svg') center center no-repeat;
  background-size: contain;
}

.card {
  font-size: var(--h3-font-size);
  color: #707070;
  font-weight: 500;
}

.card img {
  cursor: pointer;
  /* width: 50px; */
  margin: auto;
}

.type__content {
  display: flex;
  margin-top: 10px;
}


.code span {
  font-size: var(--smaller-font-size);
}

.cours__number {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
}

.cours__number span {
  font-size: 2.3em;
  line-height: 30px;
}

.___state__my__content span {
  font-size: 12px;
  line-height: 30px;
}

.___state__my__content strong {
  font-size: 13px;
}

.matiers {
  width: 200px;
  height: 38px;
  background: #D5D5D5;
  border-radius: 10px;
  padding-left: 15px;
}

.matiers span {
  position: relative;
  margin-top: 0;
  font-size: var(--smaller-font-size);
  text-transform: uppercase;
  top: -10px;
}

.matiers .cours {
  padding-right: 10px;
  border-right: 2px solid var(--text-color);
}

.matiers .classes {
  padding-left: 6px;
}

.states {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: var(--mb-3);
  padding-top: var(--mb-2);
}

.states span {
  position: relative;
  top: 0;
  padding-left: 5px;
  color: var(--first-color);
  font-weight: 600;
  font-size: 1em;
}

.card .button {
  max-width: 170px;
  min-width: 170px;
  height: 30px !important;
  font-size: 10px !important;
}