.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 2%;
}

.mainBox {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.leftBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5%;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    line-height: 55px;
}

.traceTitle {
    height: 110px;
    position: relative;
    left: 8%;
    bottom: 5px;
}

.traceButton {
    height: 170px;
    position: relative;
    left: 12%;
    bottom: 18px;
}

.titleBox {
    display: flex;
    flex-direction: row;
}

.titleOne {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
    margin-right: 12px;
}

.titleTwo {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 53px;
    z-index: 1;
}

.message {
    font-size: 20px;
    color: rgb(108, 108, 108);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    margin-top: 10px;
    text-align: left;
}

.infoBox {
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}

.imagesBox {
    width: 40%;
    display: flex;
    flex-direction: row;
}

.teacherOne {
    height: 350px;
    margin-top: 80px;
    margin-right: 10px;
}

.teacherTwo {
    height: 380px;
    margin-top: 80px;
}

.communityLines {
    height: 70px;
    position: relative;
    left: -57%;
    bottom: 0px;
}

.traceInstaProf {
    height: 70px;
    position: relative;
    left: -15%;
    bottom: 0px;
}

.todoBook {
    height: 170px;
    position: relative;
    left: -150%;
    bottom: -62%;
}

.numbersBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    left: -30px;
}

.numberOne {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 14px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.numberTwo {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 12px;
    color: rgb(8, 7, 7);
    line-height: 15px;
}

.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 190px;
    width: 100%;
    background: white;
    border-radius: 44px;
    box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.subBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.circleOne {
    height: 80px;
    width: 80px;
    background: #F5CC92;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleTwo {
    height: 80px;
    width: 80px;
    background: #8AB0B1;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleThree {
    height: 80px;
    width: 80px;
    background: #FFC8BB;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleOneNumber {
    color: white;
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 45px;
    line-height: 68px;
}

.circleTwoNumber {
    color: white;
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 45px;
    line-height: 68px;
}

.circleThreeNumber {
    color: white;
    font-family: "Poppins";
    font-weight: bolder;
    font-size: 45px;
    line-height: 68px;
}

.circleTitle {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
    color: rgb(47, 47, 47);
    line-height: 30px;
    margin-top: 0px;
}

.circleMessage {
    font-family: "Poppins";
    font-weight: normal;
    font-size: 14px;
    color: rgb(47, 47, 47);
    line-height: 20px;
}

.boxTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 10px;
}

.boxMessage {
    margin-top: 0px;
    text-align: left;
}

@media only screen and (max-width: 680px) {

    .mainBox {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .leftBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 5%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        line-height: 30px;
    }

    .traceTitle {
        height: 40px;
        position: relative;
        left: 8%;
        bottom: 5px;
    }

    .traceButton {
        height: 80px;
        position: relative;
        left: 55%;
        bottom: -30px;
    }

    .titleBox {
        display: flex;
        flex-direction: row;
    }

    .titleOne {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
        margin-right: 0px;
    }

    .titleTwo {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 30px;
        z-index: 1;
    }

    .message {
        font-size: 13px;
        color: rgb(108, 108, 108);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        margin-top: 10px;
        text-align: left;
    }

    .infoBox {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
    }

    .imagesBox {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 50px;
    }

    .teacherOne {
        height: 250px;
        width: 50%;
        margin-top: 0px;
        margin-right: 10px;
    }

    .teacherTwo {
        height: 280px;
        width: 50%;
        margin-top: 0px;
    }

    .communityLines {
        height: 70px;
        position: relative;
        left: -60%;
        bottom: 70px;
    }

    .traceInstaProf {
        height: 70px;
        position: relative;
        left: -25%;
        bottom: 80px;
    }

    .todoBook {
        height: 74px;
        position: relative;
        left: -100%;
        bottom: -270px;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 516px;
        width: 85%;
        background: white;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.2) 5px 9px 10px;
        padding-left: 0px;
        padding-right: 0px;
        margin: 70px auto;
    }

    .subBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .circleOne {
        height: 40px;
        width: 40px;
        background: #F5CC92;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleTwo {
        height: 40px;
        width: 40px;
        background: #8AB0B1;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleThree {
        height: 40px;
        width: 40px;
        background: #FFC8BB;
        border-radius: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleOneNumber {
        color: white;
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 22px;
        line-height: 38px;
    }

    .circleTwoNumber {
        color: white;
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 22px;
        line-height: 38px;
    }

    .circleThreeNumber {
        color: white;
        font-family: "Poppins";
        font-weight: bolder;
        font-size: 22px;
        line-height: 38px;
    }

    .circleTitle {
        font-family: "Poppins";
        font-weight: bold;
        font-size: 16px;
        color: rgb(47, 47, 47);
        line-height: 26px;
        margin-top: 0px;
    }

    .circleMessage {
        font-family: "Poppins";
        font-weight: normal;
        font-size: 13px;
        color: rgb(47, 47, 47);
        line-height: 20px;
    }

    .boxTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-top: 10px;
    }

    .boxMessage {
        margin-top: 0px;
        text-align: center;
    }

}