.chat__space {
  padding: 2%;
  height: 58vh;
  background: #FFF;
  border-radius: 16px;
}

.chat__content {
  height: 85%;
  overflow-y: scroll;
  scrollbar-width: thin;
  padding-top: 25px;
}

.isInsta {
  height: 50vh;
}

.header {
  position: relative;
  width: 100%;
  text-align: center;
  height: auto;
  background: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  padding-bottom: 20px;
}

.header>span {
  font-size: 1.3em;
  text-transform: uppercase;
  color: #333333;
  font-weight: 500;
}

.chat__msg__profile {
  height: 45px;
  width: 45px;
  margin-bottom: 0px;
  position: relative;
  background: #FFF2E0;
  border: 1.5px solid #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 40px;
  box-shadow: 0px 3px 6px #00000029;
}

.chat__msg__img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.chat__msg__date {
  font-size: 12px;
  font-weight: 600;
  color: #868686;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat__msg {
  display: flex;
  flex-direction: row;
  padding: 0 20px 45px;
  margin-top: 20px;
}

.chat__msg__content {
  margin-left: 12px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat__msg__text {
  background-color: #FAFAFA;
  padding: 15px;
  border-radius: 4px 20px 20px 20px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  color: #393E3E;
}

.chat__msg__text_search {
  background-color: #FAFAFA;
  padding: 15px;
  border-radius: 4px 20px 20px 20px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  color: #393E3E;
  border: 1px solid #EAEAEA;
}

.chat__msg__text+.chat__msg__text {
  margin-top: 10px;
}

.chat__msg__text {
  /* color: var(--chat-text-color); */
}

.owner {
  flex-direction: row-reverse;

}

.owner .chat__msg__content {
  margin-left: 0;
  margin-right: 12px;
  align-items: flex-start;
}

.owner .chat__msg__text {
  background-color: #FAFAFA;
  color: #393E3E;
  border-radius: 20px 5px 20px 20px;
  border: 1px solid #EAEAEA;
}

.owner .chat__msg__date {
  color: #868686;
}

.chat__msg__text img {
  max-width: 300px;
  width: 100%;
}

.chat__msg.owner .chat__msg__content .chat__msg__text a {
  color: #fff;
  text-decoration: underline;
}

.chat__msg .chat__msg__content .chat__msg__text a {
  color: var(--first-color);
  text-decoration: underline;
}

/* --- */
.back {
  display: none;
}

.Albert {
  width: 300px;
  height: 300px;
}

@media only screen and (max-width: 700px) {
  /*
  .chat__space {

    height: 80vh;
  } */

  .chat__msg {
    padding: 0 20px 30px;
  }

  .chat__msg__text {
    background-color: #F4F4F4;
    padding: 8px;
    line-height: 1.3;
    font-size: 0.8em;
  }

  .header>span {
    font-size: 0.9em;
  }

  .back {
    position: absolute;
    display: block;
    left: 10px;
    top: 10px;
  }

  .Albert {
    width: 200px;
    height: 200px;
  }

}

/* New Css Style */

.newPlanningButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #8AB0B1 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #6A989A !important;
  -webkit-box-shadow: 2px 4px 1px -1px #6A989A;
  -moz-box-shadow: 2px 4px 1px -1px #6A989A;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.newInstaButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #F56666 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #FFC8BA !important;
  -webkit-box-shadow: 2px 4px 1px -1px #FFC8BA;
  -moz-box-shadow: 2px 4px 1px -1px #FFC8BA;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.reportButton {
  width: auto !important;
  height: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #60CD95 !important;
  color: #fff !important;
  font-weight: 600 !important;
  border: none !important;
  box-shadow: 1px 3px 1px -1px #4BB980 !important;
  -webkit-box-shadow: 2px 4px 1px -1px #4BB980;
  -moz-box-shadow: 2px 4px 1px -1px #4BB980;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.receiverDetails {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100%;
}

.receiverNameAvatarBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1em;
  width: 40%;
}

.receiverButtonsBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.optionButton {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #FFF !important;
  color: #fff !important;
  padding-top: 0px !important;
  font-weight: 400 !important;
  border: none !important;
  margin-left: 10px;
  margin-right: 0px;
}

.emptyChatTitle {
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #707070 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0% auto;
}

.emptyChatSubtitle {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #AFAFAF !important;
}

.nameStatusBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #393E3E !important;
  margin-left: 10px;
  width: auto;
}

.activeUser {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #4BB980 !important;
}

.inactiveUser {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  color: #cd301f !important;
}

.searchBoxButtons {
  height: 45px;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.searchBox {
  height: 40px;
  width: 80%;
  background: #FFF;
  border: 1px solid #DEDEDE;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.searchAnswersBox {
  height: 300px;
  width: 28%;
  background: #FAFAFA;
  border: 1px solid #707070;
  position: absolute;
  top: 19%;
  left: 63%;
  border-radius: 16px;
  overflow: scroll;
  scrollbar-width: thin;
  padding: 25px 10px;
  z-index: 77;
}

@media only screen and (max-width: 1050px) {
  .chat__content {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .chat__space {
    padding: 2%;
    height: 60vh;
    background: #FFF;
    border-radius: 16px;
  }

  .receiverName {
    display: block;
  }

  .emptyChatTitle {
    font-size: 1em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #707070 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0% auto;
  }

  .emptyChatSubtitle {
    font-size: 0.65em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #AFAFAF !important;
    margin-bottom: 20px;
  }

  .nameStatusBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 0.8em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #393E3E !important;
    margin-left: 10px;
    width: auto;
  }

  .activeUser {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 0.65em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #4BB980 !important;
  }

  .inactiveUser {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 0.65em !important;
    font-weight: 600 !important;
    font-family: "Poppins";
    color: #cd301f !important;
  }

  .receiverDetails {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    width: 100%;
  }

  .receiverNameAvatarBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1em;
    width: 80%;
  }

  .searchBoxButtons {
    height: 45px;
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .searchBox {
    height: 35px;
    width: 95%;
    background: #FFF;
    border: 1px solid #DEDEDE;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    font-size: 0.8em !important;
  }

  .searchField {
    font-size: 0.8em !important;
  }

  .searchField::placeholder {
    font-size: 0.8em !important;
  }

  .chat__msg__profile {
    height: 35px;
    width: 35px;
    margin-bottom: 0px;
    position: relative;
    background: #FFF2E0;
    border: 1.5px solid #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 5px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .chat__msg__img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .chat__msg {
    display: flex;
    flex-direction: column;
    padding: 0 10px 20px;
    margin-top: 10px;
  }

  .chat__msg__content {
    margin-left: 2px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .chat__msg__text {
    background-color: #FAFAFA;
    padding: 7px;
    border-radius: 2px 10px 10px 10px;
    line-height: 1.5;
    font-size: 0.8em;
    font-weight: 500;
    color: #393E3E;
    border: 1px solid #EAEAEA;
  }

  .chat__msg__text_search {
    background-color: #FAFAFA;
    padding: 7px;
    border-radius: 2px 10px 10px 10px;
    line-height: 1.5;
    font-size: 0.8em;
    font-weight: 500;
    color: #393E3E;
    border: 1px solid #EAEAEA;
  }

  .owner .chat__msg__text {
    background-color: #FAFAFA;
    color: #393E3E;
    border-radius: 10px 2px 10px 10px;
    border: 1px solid #EAEAEA;
  }

  .owner {
    flex-direction: column;
    align-items: flex-end;

  }

  .owner .chat__msg__content {
    margin-left: 0;
    margin-right: 2px;
    align-items: flex-start;
  }

  .chat__msg__date {
    font-size: 0.65em;
    font-weight: 600;
    color: #868686;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .searchAnswersBox {
    height: 300px;
    width: 90%;
    background: #FAFAFA;
    border: 1px solid #707070;
    position: absolute;
    top: 15%;
    left: 5%;
    border-radius: 16px;
    overflow: scroll;
    scrollbar-width: thin;
    padding: 25px 10px;
    z-index: 77;
  }
}