.sharingBox {
    height: 40px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    left: -37px;
}

.sharingButton {
    height: 32px;
    width: 32px;
    margin-right: 5px;
    cursor: pointer;
}

.sharingButton:hover:not(:active) {
    opacity: 0.75;
}