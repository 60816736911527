.bodyDropDow {
  width: 400px;
  height: 450px;
  padding: 1.5em 1em !important;
  background: #faeb89 !important;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px !important;
}

.dropdow__item {
  width: 100%;
  padding: 10px !important;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 0.9em !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* overflow-y: scroll; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.dropdown__row {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 1em;
}

.title h3 {
  font-size: 12px;
}

.msg {
  width: auto;
  margin-bottom: 0;
  white-space: normal;
  /* Ensure the text can wrap */
  word-wrap: break-word;
  /* Break long words if necessary */
  overflow: hidden;
  /* Hide overflow if text is too long */
  text-overflow: ellipsis;
  /* Add ellipsis if the text overflows */
}

.notif__btn button {
  border: unset;
  font-size: 11px;
  /* margin-top: 10px; */
  padding: 3px 7px;
  border-radius: 5px;
  cursor: pointer;
  background: #439f017d;
  color: #fff;
  font-weight: 500;
}

.menu__dropdow__list:hover {
  background: #dedede !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.seen {
  background: #dedede;
  z-index: 1;
}

.dropdown__text {
  margin-left: 10px;
}

.dropdown__text span {
  font-size: 12px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown__text .span__list {
  font-weight: 500;
}

.dropdown__text .__notif__description {
  font-weight: 400;
}

.buttonRow {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}