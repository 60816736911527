.cours {
  position: relative;
  margin: 4em 0;
  min-height: 70vh;
}

.teacher__maa {
  position: absolute;
  width: 350px;
  height: 600px;
  top: -12%;
  left: 0;
  background: url("../../../../assets/images/teacher/teacherMaa.svg") no-repeat
    center center;
  background-size: cover;
  z-index: 1;
}

.button svg {
  position: absolute;
  left: 25px;
  top: 10px;
  top: 12px;
}

.form__container {
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--mb-4);
}

.field {
  height: 50px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.field::placeholder {
  font-style: italic !important;
}

.select div {
  height: 50px !important;
  width: 600px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
}

.select div span {
  padding-top: 10px !important;
  text-align: left;
  font-size: 1.2em;
}

.button {
  width: 250px !important;
  height: 47px !important;
  border-radius: 50px !important;
  font-size: 1.2em !important;
  text-transform: uppercase;
  background: var(--first-color) !important;
  color: #fff !important;
  padding-top: 10px !important;
  font-weight: 500 !important;
  border: none !important;
  box-shadow: 3px 6px 1px -1px rgba(90, 132, 132, 1) !important;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 1em 0;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 6em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.__add__input__button {
  background: var(--text-color) !important;
  height: 45px !important;
  border: unset !important;
  border-radius: 50px !important;
  color: #fff !important;
  margin: 0 !important;
}

.__input__type {
  position: absolute;
  left: -100%;
}

.__action button button,
.__action button:first-child {
  max-width: 200px;
  min-width: 200px;
  margin-right: var(--mb-1);
}

.__action button button,
.__action button:last-child {
  max-width: 350px;
  min-width: 350px;
  margin-right: var(--mb-1);
}

.__action .appercue {
  max-width: 150px;
  min-width: 150px;
  /* box-shadow: unset !important;
  background: #fff !important; */

  /* color: var(--first-color) !important; */
  margin: 5px;
  border: 2px solid var(--first-color) !important;
}

.appercue__continute {
  width: 100%;
  /* height: 100%; */
  height: 80vh;
  min-width: 600px;
  overflow-y: scroll;
  background: #fafafa;
  border-radius: 10px;
  padding: 1.5em 2em;
}

.____left {
  width: 100%;
  height: 80vh;

  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 2em;
}

.button__add_input__title {
  position: absolute;
  top: 0;
  right: -5px;
  width: 40px;
  height: 50px;
  top: 10px;
  /* border: 1px solid var(--first-color); */
  cursor: pointer;
}

.remove__input {
  position: absolute;
  left: -20px !important;
  top: 10px;
  width: 50px;
  cursor: pointer;
}

.__field {
  height: 40px !important;
  width: 100% !important;
}

.__custom__ck_editor {
  border: 1px solid var(--text-color);
  border-radius: 10px;
  padding: 5px;
}

.add__chapters {
  position: relative;
  width: 100%;
  height: 400px;
  border: 1px dashed var(--first-color);
  margin-bottom: var(--mb-4);
}

.add__chapters h3 {
  margin: auto;
  color: var(--first-color);
  font-size: 2em;
  cursor: pointer;
  text-align: center;
}

.__trigger {
  position: absolute;
  width: 256px;
  height: 100px;
  right: 0;
  bottom: 0;
  left: 55%;
  cursor: pointer;
  z-index: 1;
  transform: translate(-50%);
}

.__add__input {
  height: 42px !important;
  border-radius: 50px !important;
  width: 260px !important;
  background: #707070 !important;
  color: #fff !important;

  border: unset !important;
}

.__add__input:hover {
  color: #fff !important;
}

.appercue__continute h3 {
  font-size: 18px;
  margin-bottom: var(--mb-2);
}

.section__content,
.__big__title__content {
  width: 100%;
  height: 45px;
  background: var(--text-color);
  border-radius: 15px;
  margin-bottom: var(--mb-2);
}

.appercue__continute__content {
  margin-left: 5%;
}

.__big__title {
  padding-left: 1em;
  font-size: 16px !important;
  color: #fff;
  line-height: 45px;
}

.section__content {
  background: var(--first-color);
}

.section__content h4 {
  color: #fff;
  line-height: 45px;
  padding-left: 1em;
}

.__custom__ck_editor {
  margin-bottom: var(--mb-4);
}

/*  */

.__scaner__math {
  width: 120px;
  height: 33px;
  border: 1px dashed #707070;
  display: flex;
  justify-content: center;
  align-items: center;
}

.__upload__with__ck__content {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid #707070; */
  border-radius: 10px;
  padding: 0 15px;
}

.__ck__scaner {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 50px;
  right: 4%;
  bottom: 1em;
  cursor: pointer;
}

.__ck__math__content {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.__ck__math__content span {
  font-size: 13px;
}

.__copy {
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #bfbfbf;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  margin-right: 20px;
  overflow: hidden;
  overflow-y: scroll;
}
