/*===== GOOGLE FONTS =====*/
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"); */

/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;
  --loading-grey: #ededed;

  /*========== Colors ==========*/
  --first-color: #80b1b2;
  --first-color-alt: #5a8484;
  --title-color: #333333;
  --text-color: #707070;
  --text-color-light: #a6a6a6;
  /* --body-color: #FBFEFD; */
  --body-color: #fff;
  --container-color: #ffffff;

  /*========== Font and typography ==========*/
  --body-font: "Rubik", sans-serif;
  --title-font: "CartoonFont";
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --big-para: 1.2rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes ==========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

.ant-select-dropdown {
  border-radius: 10px;
}

.ant-modal-content {
  border-radius: 5px !important;
  /* border-bottom: unset !important; */
}

[data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 12px;
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-color: #faea89 transparent transparent #faea89;
  box-shadow: -2px -2px 5px rgb(0 0 0 / 6%);
}

.ant-collapse {
  border-radius: 20px;
}

/* Placholder */
.ant-select-selection-placeholder {
  color: var(--text-color);
}

@media screen and (min-width: 768px) {
  :root {
    --biggest-font-size: 2.2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1rem;
    --h3-font-size: 0.9rem;
    --big-para: 1rem;
    --normal-font-size: 0.8rem;
    --small-font-size: 0.7rem;
    --smaller-font-size: 0.65rem;
  }
}

/* OVERIDE */
.calendarPopupContent div {
  padding: 0 !important;
}

/* @font-face {
  font-family: 'gilroy';
  src: url('/fonts/gilroy/gilroy-light-webfont.woff2') format('woff2'),
    url('/fonts/gilroy/gilroy-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

/*========== BASE ==========*/
*,
::before,
::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/*========== Variables Dark theme ==========*/
body.dark-theme {
  --title-color: #f1f3f2;
  --text-color: #c7d1cc;
  --body-color: #1d2521;
  --container-color: #27302c;
}

/*========== Button Dark/Light ==========*/
.change-theme {
  position: absolute;
  right: 1rem;
  top: 1.8rem;
  color: var(--text-color);
  font-size: 1rem;
  cursor: pointer;
}

body {
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: 1.6;
  font-weight: 300;
  /* overflow: hidden !important; */
  overflow-x: hidden !important;
}

input {
  font-size: 16px !important;
  font-family: var(--body-font) !important;
}

h1,
h2,
h3 {
  font-weight: 500;
  margin: 0;
  line-height: 1.3;
}

ul,
p {
  margin: 0;
}

p {
  margin-bottom: var(--mb-2);
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  font-weight: 300 !important;
}

strong {
  font-weight: 600 !important;
}

/* img {
  max-width: 100%;
  height: auto;
} */

.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/*========== CLASS CSS ==========*/
.section {
  padding: 4rem 0 2rem;
}

.section-title,
.section-subtitle {
  text-align: center;
}

.section-title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-3);
}

.section-subtitle {
  display: block;
  color: var(--first-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

/*========== LAYOUT ==========*/
.bd-container {
  max-width: 1100px;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  gap: 1.5rem;
}

/* Show menu */
/* .show-menu{
  top: var(--header-height);
} */

/* Active menu */
.active-link {
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Scroll top */
.scrolltop {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  background: rgba(6, 156, 84, 0.5);
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
  visibility: hidden;
}

.scrolltop:hover {
  background-color: var(--first-color-alt);
}

.scrolltop__icon {
  font-size: 1.8rem;
  color: var(--body-color);
}

/* Show scrolltop */
.show-scroll {
  visibility: visible;
  bottom: 1.5rem;
}

.iframe-maps {
  width: auto !important;
}

/*========== BUTTONS ==========*/
.button {
  display: inline-block;
  border: 1px solid var(--first-color);
  color: var(--first-color);
  padding: 0.3rem 1.3rem;
  border-radius: 0.5rem;
  transition: 0.3s;
  font-weight: 400 !important;
}

button {
  transition: 0.3s;
}
/* 
button:hover {
  transform: scale(1.03) !important;
}

button:active {
  transform: scale(0.99) !important;
} */

button {
  font-family: var(--body-font);
}

.ant-card-body {
  padding: 0 !important;
}

.slick-slide > div {
  margin: 0 3px;
}
.slick-list {
  margin: 0 -3px;
}

.slick-prev:before,
.slick-next:before {
  color: var(--first-color);
}

/* .slick-prev {
  left: -18px !important;
}
.slick-next {
  right: -23px !important;
} */

/*========== MEDIA QUERIES ==========*/
@media screen and (min-width: 576px) {
  .app__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .fakeDiv {
    display: none;
  }

  .app__initial {
    text-align: initial;
  }
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }

  .section {
    padding-top: 4rem;
  }

  .change-theme {
    position: initial;
    margin-left: var(--mb-2);
  }
}

@media screen and (min-width: 960px) {
  .bd-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 500px) {
  .footerDisplay {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--first-color);
  border-radius: 5px;
}
