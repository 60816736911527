.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0.5em 0;
}

.main {
  width: 100%;
  height: 100%;
  margin-top: 1em;
}

.center {
  width: 100%;
  flex: 2;
  height: 2px;
  border: 1px dashed var(--text-color);
  margin: 0 var(--mb-4);
}

.left .subtitle {
  position: relative;
  top: 15px;
  text-transform: uppercase;
  font-size: var(--h1-font-size);
  color: var(--text-color);
}

.justify__center {
  width: 100%;
  margin: 3.5em 0;
  display: flex;
}

.filter {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
  margin: var(--mb-3) 0;
  padding: 0 var(--mb-4);
}

.filed__search {
  width: 250px;
  height: 40px;
  background: #fff;
  border: 1px solid var(--text-color);
  border-radius: 10px;
}

.filed__search * input::placeholder {
  color: var(--text-color);
  font-size: 14.5px;
}

.filed__search * button {
  background: #fff;
  border: none;
  font-size: 0.5em;
}

.select {
  height: 40px;
  width: 150px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
}

.select {
  padding-top: 3px;
  text-align: left;
}

.row {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
}

.__reset__btn {
  position: absolute !important;
  right: -1% !important;
  bottom: -20% !important;
}

.__submit__hidden {
  position: absolute !important;
  left: -100000000% !important;
  margin: 0 !important;
}

@media only screen and (max-width: 767px) {

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .center {
    width: 100%;
    flex: 2;
    height: 2px;
    border: 1px dashed var(--text-color);
    margin: 15px 0px 5px 0px;
  }

  .filed__search {
    width: 65%;
    height: 40px;
    background: #fff;
    border: 1px solid var(--text-color);
    border-radius: 10px;
  }

  .select {
    height: 40px;
    width: 160px;
    border-radius: 10px;
    border: 1px solid var(--text-color);
  }

  .__submit__hidden {
    position: relative !important;
    top: 0px !important;
    margin: 0px auto !important;
  }

  .__reset__btn {
    position: relative !important;
    right: 0% !important;
    bottom: 0% !important;
    top: 10px !important;
  }

}

/* New Css Styles */

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #393E3E;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 500;
}

.titlePictureBox {
  height: 50px;
  width: 50px;
  border: 1px solid #FFF;
  border-radius: 50%;
  margin-right: 20px;
  background: #FFF2E0;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.newButton {
  width: auto !important;
  height: 35px !important;
  display: block;
  border-radius: 50px !important;
  font-size: 13px !important;
  text-transform: none;
  text-align: center;
  background: #80b1b2 !important;
  color: #fff !important;
  padding-top: 5px !important;
  font-weight: 400 !important;
  border: none !important;
  box-shadow: 3px 5px 1px -1px #5a8484 !important;
  -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
  -moz-box-shadow: 5px 7px 1px -1px #5a8484;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.searchBox {
  width: 100%;
  height: 85px;
  background: #FFF;
  box-shadow: 4px 9px 18px #CBCBCB29;
  border: 1px solid #BFBCBC;
  border-radius: 31px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 30px auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.selectBox {
  width: 18%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #707070;
  font-size: 12px;
  text-align: left;
  opacity: 1;
}

.separation {
  height: 90%;
  width: 1px;
  background: #D8D8D8;
  margin-left: 2%;
  margin-right: 20px;
}

.inputBox {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 40px;
}

.filedSearch {
  width: 97%;
  height: 50px;
  background: #FFF;
  border: 1px solid #BFBCBC;
  border-radius: 41px;
  opacity: 1;
}

.myCoursesBox {
  height: 40px;
  width: 160px;
  background: #60CD95;
  color: #FFF;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #FFF;
}

.myCoursesBoxActive {
  height: 40px;
  width: 160px;
  background: #FFF;
  color: #707070;
  opacity: 1;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  text-align: center;
  padding-left: 15px;
  box-shadow: 4px 9px 18px #7FB1B234;
  border: 1px solid #60CD95;
}

.coursesBox {
  height: auto;
  width: 100%;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0%;
  align-items: start;
  justify-content: start;
}

@media only screen and (max-width: 1050px) {
  .headerBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: 0px;
  }

  .titleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #393E3E;
    font-size: 0.8em;
    font-family: "Poppins";
    font-weight: 500;
    padding-left: 5px;
    width: 55%;
  }

  .titlePictureBox {
    height: 20px;
    width: 20px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF2E0;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonBox {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px auto;
  }

  .newButton {
    width: auto !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50px !important;
    font-size: 0.7em !important;
    text-transform: none;
    text-align: center;
    background: #80b1b2 !important;
    color: #fff !important;
    padding-top: 5px !important;
    font-weight: 400 !important;
    border: none !important;
    box-shadow: 3px 5px 1px -1px #5a8484 !important;
    -webkit-box-shadow: 5px 7px 1px -1px #5a8484;
    -moz-box-shadow: 5px 7px 1px -1px #5a8484;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .coursesBox {
    height: auto;
    width: 100%;
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    padding: 0%;
    align-items: center;
    justify-content: center;
  }

  .myCoursesBox {
    height: 32px;
    width: 100px;
    background: #60CD95;
    color: #FFF !important;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #FFF;
  }

  .myCoursesBoxActive {
    height: 32px;
    width: 100px;
    background: #FFF !important;
    color: #707070 !important;
    opacity: 1;
    font-size: 0.75em !important;
    font-family: "Poppins";
    font-weight: 500;
    display: inline-flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 24px;
    text-align: center !important;
    padding-left: 15px;
    box-shadow: 4px 9px 18px #7FB1B234;
    border: 1px solid #60CD95;
  }

  .searchBox {
    width: 100% !important;
    height: 35px;
    background: #FFF;
    box-shadow: 4px 9px 18px #CBCBCB29;
    border: 0px solid #BFBCBC;
    border-radius: 31px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .selectBox {
    width: 0%;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #707070;
    font-size: 12px;
    text-align: left;
    opacity: 1;
  }

  .separation {
    height: 90%;
    width: 1px;
    background: #D8D8D8;
    margin-left: 2%;
    margin-right: 0px;
    display: none;
  }

  .inputBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 0px;
  }

  .filedSearch {
    width: 100%;
    height: 35px;
    background: #FFF;
    border: 1px solid #BFBCBC;
    border-radius: 24px;
    opacity: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .searchField::placeholder {
    font-size: 0.75em !important;
  }
}