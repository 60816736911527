.card {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* max-width: 350px; */
  height: 355px;
  padding: 15px;
  background-size: contain;
  background: #fafafa;
  box-shadow: 3px 2px 0px 2px #cfd0d2;
  transition: 0.5s;
}

.card:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 70px;
  background: radial-gradient(#cfd0d2 6px, transparent 7px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.card::after {
  content: "";
  position: absolute;
  top: 4px;
  bottom: 0;
  left: 1px;
  overflow: hidden;
  width: 50px;
  background: radial-gradient(#fff 5px, transparent 6px) repeat-y;
  background-size: 30px 30px;
  box-sizing: border-box;
}

.__matiers {
  width: 100%;
  margin-top: 6px;
  max-width: 80%;
  text-transform: uppercase;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3em;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--mb-1);
}

.__tag__topic {
  background: #d5d5d5;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
}

.card__header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1em;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 1% 2%;
}

.round__user {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 100px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.round__user img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  border-radius: 100px;
}

.content h3 {
  width: 150px;
  text-transform: uppercase;
  color: #333;
  font-size: 1.2em;
  margin-top: var(--mb-1);
}

.tarif {
  font-size: 0.9em;
  font-weight: 600;
  color: #333;
  margin-top: var(--mb-1);
}

.tarif span {
  font-weight: 300;
}

.btn__profil__prof:hover {
  background: #5a8484 !important;
}

.__description {
  width: 100%;
  height: 35px;
  font-size: 12px;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 10px;
  margin-top: 5px;
}

.__start {
  position: absolute;
  margin: auto;
  top: -4%;
  right: 8%;
}

.__row {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  margin-left: 15px;
}

.__row button {
  position: relative;
  border: unset;
  padding: 4px 11px;
  border-radius: 8px;
  background: var(--first-color);
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.calendrier {
  width: 1200px;
  height: 850px;
  margin-top: 2em;
  background: url("../../../../../assets/images/teacher/calendarScreen.svg") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 1s ease;
}

.calendar__content {
  width: 95%;
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: auto;
}

.__drawer__teacher {
  width: 100%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.__drawer__teacher__header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.__infos h3 {
  font-size: 1.6em;
  text-transform: uppercase;
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-1);
}

.__drawer__teacher__main {
  margin-top: 2em;
}

.__main__infos {
  font-size: 1.6em;
  margin-bottom: 1em;
  display: flex;
}

.__main__infos span {
  text-transform: uppercase;
}

.__drawer_matiere {
  position: relative;
  top: -0.6em;
  margin-left: 1.5em;
}

.__main__infos p {
  position: relative;
  top: 5px;
  font-size: 0.7em;
  margin-left: 1em;
}

.__plus {
  width: 100%;
  height: 400px;
  background: #fafafa;
}

.__reservation {
  /* background: var(--first-color); */
  width: 350px;
  height: 350px !important;
}

.calendarPopupContent * .ant-popover-inner-content {
  padding: 0 !important;
}

.__reservation_content {
  position: relative;
  width: 100%;
  top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* padding-top: 2%; */
}

.__reservation__row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.__reservation_avatar {
  position: relative;
  width: 80px;
  height: 80px;
  top: -10px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 100px;
}

.__reservation_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.__reservation_content h2 {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 1.5em;
}

.reservation__infos {
  width: 100%;
  padding-left: 3%;
}

.reservation__infos h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.reservation__infos span {
  font-size: 11px;
}

.tag {
  width: 100px;
  height: 32px;
  border-radius: 20px;
  background: #8ab0b1ca;
  display: flex;
  margin-bottom: 0.5em;
}

.tag span {
  margin: auto;
  text-transform: uppercase;
  font-size: 11px;
  color: #fff;
}

.__drawer_button {
  border-radius: 15px !important;
  height: 40px !important;
  color: var(--first-color);
  text-transform: uppercase;
  min-width: 200px;
}

.__custom__container {
  width: 100%;
  height: 100%;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.__liste__card {
  margin-top: var(--mb-4);
}

.__calendar__title,
.__calendar__title__creneau {
  font-size: 12px !important;
  line-height: 14px;
}

.__calendar__title {
  font-size: 11px !important;
}

/* .__open__className {
  min-width: 400px !important;
  max-width: 400px !important;
} */

.__solde_content h3 {
  text-transform: uppercase;
  font-size: 1em;
}

.__prof__description__card__reservation {
  font-size: 12px;
  text-align: center;
}

.__matiersAds * span {
  padding-left: 2px;
}

.__matiersAds {
  margin-bottom: 1.5em;
}

/* ====== */
.month {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: -0.7em;
}

.month span {
  text-transform: uppercase;
  font-family: "CartoonFont";
}

.date {
  font-size: 2em;
}

.month span:nth-child(2) {
  position: relative;
  font-size: 1.2em;
}

.month span:nth-child(3) {
  position: relative;
  font-size: 1.2em;
}

.month span:nth-child(2)::after,
.month span:nth-child(3)::after {
  content: "";
  position: absolute;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  left: -18px;
  top: 10px;
  background: var(--first-color);
}

.month span:nth-child(3)::after {
  background: #faad13;
}

/* ======== */

.card__teacher__reservation {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid #fafafabe;
  border-radius: 5px;
  padding: 3% 5%;
}

.teacher__reservation__avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}

.teacher__reservation__avatar img {
  width: 100%;
  height: 100%;
}

.open__messagerie span {
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s all;
}

.open__messagerie span:hover {
  color: var(--first-color);
}

.levelGroup div:last-child {
  display: none;
}