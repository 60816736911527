.app__header {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__links {
  display: flex;
  align-items: center;
}

.nav__links .nav__item a span {
  text-align: center;
}

.nav__links .nav__item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
}

.nav__links .nav__item a {
  padding: 0 var(--mb-3);
  color: #455a64;
  font-size: 1.5em;
  font-family: var(--title-font);
  font-weight: 500 !important;
  line-height: 18px;
}

.nav__links .nav__item a:hover {
  color: var(--first-color);
}

.nav__links .nav__item a img {
  position: relative;
  width: 15px;
  left: -15px;
  top: 1px;
}

.button {
  border: none;
  padding: 0.5em 2em;
  background: var(--first-color);
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

@media only screen and (max-width: 974px) {
  .nav__links {
    display: none;
  }

  .app__header {
    position: relative;
    padding: 0;
    height: 60px;
    margin-bottom: var(--mb-2);
  }
}

@media only screen and (max-width: 500px) {

  .nav__links li:nth-child(3),
  .nav__links li:first-child {
    display: none;
  }

  .nav__links .nav__item a {
    font-size: var(--smaller-font-size);
    padding: 0 6px;
  }

  .nav .logo a img {
    width: 150px;
  }
}

.field {
  height: 45px !important;
  border-radius: 10px !important;
  border: 1px solid var(--text-color) !important;
  font-style: italic !important;
  color: #707070;
}

.field::placeholder {
  font-style: italic !important;
}