.mainContainer {
    position: relative;
    width: 100%;
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 2%;
}

.needHelpBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mainBox {
    display: flex;
    flex-direction: row;
    height: 670px;
    width: 155%;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 30px;
    padding-left: 25%;
    padding-right: 25%;
    background: rgb(255, 248, 242);
}

.leftBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 520px;
    width: 50%;
    padding-top: 2.5%;
    text-align: left;
}

.rightBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px;
    width: 45%;
}

.title {
    font-family: "DM Serif Display";
    font-weight: bold;
    font-size: 52px;
    line-height: 60px;
    margin-top: 0px;
    z-index: 1;
    text-align: left;
}

.ellipseTitle {
    position: relative;
    left: -1%;
    top: -180px;
    width: 37px;
    z-index: 0;
}

.buttonArrow {
    position: relative;
    left: 27%;
    top: -22px;
    width: 90px;
}

.ellipseLines {
    position: relative;
    left: -4%;
    top: -27%;
    width: 30px;
}

.ellipseImage {
    position: relative;
    right: 12%;
    top: -40%;
    width: 25px;
}

.backgroundParent {
    height: 440px;
    position: relative;
    right: 12%;
    top: 50px;
    z-index: 0;
}

.parentImage {
    height: 640px;
    cursor: pointer;
    z-index: 1;
    position: relative;
    bottom: -16px;
    left: 30px;
}

.ellipseCommunity {
    position: relative;
    right: 12%;
    top: -25%;
    width: 15px;
}

.ellipseInstaProf {
    position: relative;
    left: 24%;
    top: -28%;
    width: 25px;
}

.message {
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-family: "Poppins";
    line-height: 30px;
    font-weight: normal;
    text-align: left;
}

.quote {
    font-family: Helvetica;
    font-weight: normal;
    font-size: 800px;
    line-height: 0px;
    color: rgba(162, 159, 159, 0.08);
    position: relative;
    bottom: -455px;
    left: -34.5%;
}

.quote:hover {
    color: transparent;
}

@media only screen and (max-width: 680px) {

    .mainContainer {
        position: relative;
        width: 100%;
        max-width: 1250px;
        margin: 20px auto;
        padding: 0 2%;
    }

    .needHelpBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .mainBox {
        display: flex;
        flex-direction: column;
        height: 670px;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        position: relative;
        top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        background: rgb(255, 248, 242);
    }

    .leftBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        width: 100%;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
    }

    .rightBox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 0px;
    }

    .title {
        font-family: "DM Serif Display";
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        margin-top: 0px;
        z-index: 2;
        text-align: left;
    }

    .ellipseTitle {
        position: relative;
        left: -2%;
        top: -95px;
        width: 25px;
        z-index: 0;
    }

    .buttonArrow {
        position: relative;
        left: 40%;
        top: -10px;
        width: 70px;
        transform: rotate(-28deg);
    }

    .ellipseLines {
        position: relative;
        left: -5%;
        top: -22%;
        width: 25px;
    }

    .ellipseImage {
        position: relative;
        left: 25%;
        top: -40%;
        width: 20px;
        z-index: 1;
    }

    .backgroundParent {
        height: 200px;
        position: relative;
        right: 24%;
        top: -60px;
        z-index: 0;
    }

    .parentImage {
        height: 260px;
        cursor: pointer;
        z-index: 1;
        position: relative;
        left: 30%;
        top: -90px;
    }

    .ellipseCommunity {
        position: relative;
        right: -82%;
        top: -190%;
        width: 15px;
    }

    .ellipseInstaProf {
        position: relative;
        left: 84%;
        top: -175%;
        width: 15px;
        z-index: 1;
    }

    .message {
        font-size: 13px;
        color: rgb(47, 47, 47);
        font-family: "Poppins";
        line-height: 20px;
        font-weight: normal;
        text-align: left;
    }

    .quote {
        font-family: Helvetica;
        font-weight: normal;
        font-size: 800px;
        line-height: 0px;
        color: rgba(162, 159, 159, 0.08);
        position: relative;
        bottom: -455px;
        left: -34.5%;
    }

}