.grayBackground {
  width: 313px;
  height: 45px;
  background-color: #d5d5d5;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 500;

  justify-content: center;
  position: relative;
  transform: translateY(50%);
  z-index: 2;
}
.greenBackground {
  width: 313px;
  height: 120px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  flex-direction: column;
  transform: translateY(-50%);
  justify-content: flex-start;
  padding-top: 15px;
  background-color: #80b1b2;
  color: white;
  font-family: "CartoonFont";
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.myArrangement {
  display: flex;
  align-items: center;
}
.subscribedStudents {
  margin-right: 1em;
}
.bodyDropDow {
  top: 20px;
  left: 238px;
  width: 365px;
  height: 280px;
  background: white;
  overflow-y: scroll;
  border: 1px solid #707070;
  border-radius: 5px 5px 10px 10px !important;
  z-index: 1 !important;
}
.bodyDropDow__subscribedStudents {
  width: 80%;
  height: 110px;
  background: white;
  overflow-y: scroll;
  border-radius: 5px 5px 10px 10px !important;
  z-index: 1 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  left: 0;
  right: 0;
}
.absolute {
  position: absolute !important;
  z-index: 0 !important;
}
