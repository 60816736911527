.faqBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    width: 45%;
    border-radius: 45px;
}

.faqFullBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px;
    width: 75%;
    border-radius: 45px;
}

.faqBox:hover {
    box-shadow: 14px 30px 34px #BFBCBC2F;
}

.arrowBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    height: 40px;
    width: 10%;
    cursor: pointer;
}

.arrowTitleBox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
    font-family: "Poppins";
    font-weight: bold;
    color: #393E3E;
    line-height: 30px;
    text-align: left;
    cursor: pointer;
}

.faqTitle {
    font-size: 20px;
    font-family: "Poppins";
    font-weight: bold;
    color: #393E3E;
    line-height: 30px;
    text-align: left;
    width: 85%;
}

.faqContent {
    font-size: 15px;
    font-family: "Poppins";
    font-weight: bold;
    color: #6A6500;
    line-height: 27px;
    text-align: left;
    margin-top: 20px;
}

@media only screen and (max-width: 680px) {

    .faqBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px;
        width: 100%;
        border-radius: 28px;
    }

    .faqFullBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 40px;
        width: 100%;
        border-radius: 28px;
    }

    .faqBox:hover {
        box-shadow: 14px 30px 34px #BFBCBC2F;
    }

    .arrowBox {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        height: 20px;
        width: 5%;
        cursor: pointer;
    }

    .arrowBox img {
        height: 10px;
    }

    .arrowTitleBox {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        font-size: 15px;
        font-family: "Poppins";
        font-weight: bold;
        color: #393E3E;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
    }

    .faqTitle {
        font-size: 15px;
        font-family: "Poppins";
        font-weight: bold;
        color: #393E3E;
        line-height: 20px;
        text-align: left;
        width: 85%;
    }

    .faqContent {
        font-size: 13px;
        font-family: "Poppins";
        font-weight: bold;
        color: #6A6564;
        line-height: 20px;
        text-align: left;
        margin-top: 20px;
    }

}